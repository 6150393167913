export default {
  'Cumple': 'green darken-3',
  'No Cumple': 'red darken-3',
  'No Aplica': 'blue-grey lighten-3',
  'Observacion': 'blue darken-3',
  'Necesita Mejora': 'orange darken-3',
  'Default': 'brown',
  'cumple': 'green darken-3',
  'no_cumple': 'red darken-3',
  'no_aplica': 'blue-grey lighten-3',
  'observacion': 'blue darken-3',
  'necesita_mejora': 'orange darken-3',
  'default': 'brown',
}