<template>
  <div>
    <v-form ref="form" lazy-validation v-model="valid">
    <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-3">
            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="4" class="pb-0">
                    <select-auditores-object
                      class="mb-6"
                      v-model="item.auditor1"
                      dense
                      required
                      outlined
                      title="Firma en el certificado"
                      hide-details="none"
                      :rules="[v => !!v || 'Debes seleccionar un auditor']"
                      />

                    <v-text-field v-if="item.auditor1"
                      dense
                      outlined
                      label="En calidad de"
                      v-model="item.auditor1.en_calidad_de"
                    ></v-text-field>

                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <select-auditores-object
                      class="mb-6"
                      v-model="item.auditor2"
                      dense
                      outlined
                      hide-details="none"
                      title="2º Auditor"/>

                    <v-text-field v-if="item.auditor2"
                      dense
                      outlined
                      hide-details="none"
                      label="En calidad de"
                      v-model="item.auditor2.en_calidad_de"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" class="pb-0">
                    <v-textarea
                      label="Observaciones"
                      v-model="item.motivo"
                      outlined
                      rows="4"
                      auto-grow
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>

                  <v-col>
                    <v-btn
                      v-if="auth.canEdit"
                      depressed
                      color="success"
                      @click="submit">
                      Guardar lote
                    </v-btn>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="mb-2">
          <v-card outlined class="row mx-0">
            <v-col cols="2" class="d-flex align-center text-center">
              <h3>
                {{ batch.length || 0 }}
              </h3>

              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title>Seleccionadas</v-list-item-title>
                <v-list-item-subtitle>Modificar estados</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>

            <v-col cols="2" class="py-2 d-flex align-center">
              <v-select
                v-model="apply.proposicion"
                label="Proposición"
                :items="[
                  'APTO',
                  'NO APTO',
                ]"
                :disabled="!batch.length"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col cols="2" class="py-2 d-flex align-center">
              <v-btn
                  block
                  small
                  depressed
                  color="success"
                  @click="applyState">
                  Aplicar estados
                </v-btn>
            </v-col>

          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined class="row mx-0">
            <v-col cols="2" class="text-center">

              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-subtitle>Seleccionar y añadir <br>una nueva revisión al lote</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col cols="4" class="py-2 d-flex align-center">
              <v-select
                v-model="apply.selected"
                label="Seleccionar una revisión"
                :items="db.revisiones"
                :item-text="(item) => item.codigo_expediente + ' (' + item.nombre_cliente + ')'"
                item-value="uuid"
                outlined
                dense
                hide-details="auto"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="2" class="py-2 d-flex align-center">
              <v-btn
                  block
                  small
                  depressed
                  color="success"
                  @click="addToLote">
                  Añadir al lote
                </v-btn>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined>
            <!-- { text: 'Expediente', value: 'expediente.codigo', cellClass: 'subData', width: 150 }, -->
            <v-data-table
              ref="datatable"
              v-model="batch"
              item-key="uuid"
              show-select

              :items="selected"

              :headers="[
                { text: 'Estado', value: 'estado', align: 'left', cellClass: 'subData', width: 75 },
                {
                  text: 'Fecha creación',
                  value: 'dates',
                  cellClass: 'subData',
                  width: 75,
                  sortable: false,
                },
                { text: 'Alcance', value: 'alcance', cellClass: 'subData', width: 150 },
                { text: 'Cliente', value: 'client', width: 200 },
                {
                  text: 'Proposición',
                  value: 'proposicion',
                  cellClass: 'subData',
                  width: 150,
                  sortable: false,
                }
              ]"
              :items-per-page="50"

              :search="table.search"
              :class="{'disabled': ui.loading}"
              :loading="ui.loading">
              <template v-slot:item.alcance="{ item }">
                <span v-if="item.nombre_alcance">
                  {{ item.nombre_alcance }}
                </span>
                <span v-else-if="item.alcance">
                  {{ item.alcance.nombre }}
                </span>
                <span v-else-if="item.auditoria">
                  {{ item.auditoria.alcance }}
                </span>
              </template>

              <template v-slot:item.client="{ item }">
                <span v-if="item.nombre_cliente">
                  {{ item.nombre_cliente }}
                </span>
                <span v-else-if="item.cliente">
                  {{ item.cliente.nombre }} {{ item.cliente.apellidos }}
                </span>
              </template>

              <template v-slot:item.dates="{ item }">
                {{ $moment(item.created_at).format("DD [de] MMMM, YYYY") }}
              </template>

              <template v-slot:item.proposicion="{ item }">
                {{ (item.proposicion) ? item.proposicion : '...' }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>

    </v-row>
    </v-form>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\revisiones\lotes\data.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 2nd June 2022
 * Last Modified: Tue Jul 04 2023
 **/

import { uuid } from 'vue-uuid'
import SelectAuditoresObject from '~/components/forms/SelectAuditoresObject'

import notifications from '~/util/notificationHelper'

export default {
  props: ['readonly', '_lote', '_batch', '_selected', 'is_revision', 'type'],
  components: {
    SelectAuditoresObject,
  },
  data: () => ({
    valid: false,
    batch: [],
    selected: [],
    item: {
      action: 'create',

      auditor1: {},
      auditor2: {},
      fecha_decision: new Date().toISOString().substr(0, 10),
      fecha_proposicion: new Date().toISOString().substr(0, 10),
      motivo: '',
    },

    apply: {
      proposicion: null,
    },

    table: {
      search: '',
      headers: [{ text: 'Estado', value: 'status', cellClass: 'subData', width: 100 }],
    },

    db: {
      dictamenes: [],
      revisiones: [],
    },

    auth: {},

    ui: {
      loading: true,
    },
  }),
  methods: {
    //+-------------------------------------------------
    // populateData()
    // Sets data from props
    // -----
    // Created on Fri Jun 10 2022
    //+-------------------------------------------------
    populateData() {
      if (this._lote) {
        this.item = { ...this._lote }

        if (this.item.en_calidad_de_auditor1)
          this.item.auditor1.en_calidad_de = this.item.en_calidad_de_auditor1

        if (this.item.en_calidad_de_auditor2)
          this.item.auditor2.en_calidad_de = this.item.en_calidad_de_auditor2
      }

      if (this._batch) this.batch = [...this._batch]
      if (this._selected) this.selected = [...this._selected]
      if (this._lote?.revisiones) this.selected = [...this._lote.revisiones]
    },

    addToLote() {
      this.item.revisiones.push(this.apply.selected)
      this.selected.push(this.apply.selected)
      this.apply.selected = null
    },

    applyState() {
      this.batch.forEach((item) => {
        let index = this.selected.findIndex((selected) => selected.uuid == item.uuid)
        if (index > -1) {
          this.selected[index].updated = true
          this.selected[index].proposicion = this.apply.proposicion
        }
      })

      this.apply.proposicion = null
      if (this.item.action !== 'create') this.item.action = 'update'
      this.batch = []
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    validate() {
      if (!this.$refs.form.validate()) return false
      if (!this.item.auditor1?.uuid) return false
      return true
    },

    async submit() {
      let step = false

      // 1 . Form validations
      if (this.validate()) step = 'valid'
      // step = 'valid'

      // 2. Do api requests
      if (step == 'valid') {
        await this.store()
        step = 'stored'
      } else
        notifications.show({
          text: 'Debes revisar los campos antes de continuar',
          color: 'warning',
        })

      // 3. do post-request actions
      if (step == 'stored') {
        this.$emit('lotes:reset', {})
        $nuxt.$emit('lotes:stored', {})
        notifications.show({ text: 'Todos los cambios se han guardado correctamente' })
        this.$router.push('/revisiones/lotes')
      }
    },

    async store() {
      this.ui.loading = true

      let action = this.item.action
      if (action == 'create') this.item.uuid = uuid.v4()

      let type = this.type
      if (!type) type = this.item.revisiones?.length > 0 ? 'revisiones' : 'auditorias'

      let payload = { ...this.item }
      payload[type] = []

      this.selected.forEach((item) => {
        let uuid = item.uuid

        // we will send every selected item
        // if (!item.updated) return

        payload[type].push({
          uuid: uuid,
          proposicion: item.proposicion,
        })
      })

      payload.fecha_decision = this.$moment(payload.fecha_decision).format('YYYY-MM-DD')
      payload.fecha_proposicion = this.$moment(payload.fecha_proposicion).format('YYYY-MM-DD')

      console.warn(action, payload, this.selected)

      try {
        payload.group = 'revisiones'
        await this.$store.dispatch('lotes/' + action, payload)
      } catch (e) {
        console.info(e)
        notifications.show({
          text: 'Ha ocurrido un error guardando los cambios',
          color: 'error',
        })
      } finally {
        this.ui.loading = false
      }
    },

    //+-------------------------------------------------
    // Async API store requests
    //+-------------------------------------------------

    // async getData() {
    //   const xhr = await this.$store.dispatch('dictamenes-lotes/get', this.active)
    //   if (xhr) {
    //     this.db.item = xhr
    //     this.item = xhr
    //   }
    // },

    async getRevisiones() {
      const res = await this.$store.dispatch('revisiones/list')
      this.db.revisiones = res.filter((item) => item.estado == 'PENDIENTE')
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        submodule: 'lotes',
        return: 'all',
      })
    },

    async init() {
      this.checkRoles()
      this.populateData()
      this.getRevisiones()

      this.ui.refresh = Date.now()
      this.ui.loading = false

      if (this.readonly) this.item.action = 'update'
    },
  },

  mounted() {
    this.init()
    notifications.onSaved(this)
  },
}
</script>
