<template>
  <div class="mt-0 pt-0">

    <loading v-model="$store.getters.loading" text="Espere por favor..."/>

    <!-- Confirm -->
      <confirmation
        v-model="confirm"
        :text="`¿Desea crear una toma de muestras?`"
        textButton="Crear"
        :valid="valid"
        @confirm="createTomaMuestra()"
        @close="close()"
      >
        <v-form ref="form" lazy-validation v-model="valid">
          <v-container>
            <v-row align="center" justify="space-around">
              <v-col cols="8">
                <v-select
                  label="Emplazamiento"
                  v-model="site"
                  :rules="rules"
                  :items="sitesAvailable"
                  item-text="nombre"
                  return-object
                  required
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </confirmation>

    <v-card-title class="mt-0 pt-0 mb-2">
      <v-btn small @click="expandirFilas(1)" class="mr-3">
        <v-icon left dark>mdi-arrow-expand-down</v-icon>
        Expandir todo
      </v-btn>
      <v-btn small @click="expandirFilas(0)">
        <v-icon left dark>mdi-arrow-expand-up</v-icon>
        Contraer todo
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search" @keyup="updateFilter" @input="updateFilter"
        append-icon="search" label="Búsqueda rápida"
        single-line hide-details clearable
        style="margin-top: 0px !important; padding-top: 0px !important"
      ></v-text-field>
      <v-tooltip bottom v-if="!isDisabled">
        <template v-slot:activator="{ on }"
          >
          <v-btn
            small fab depressed
            color="success" class="ml-4 white--text"
            v-on="on" @click="confirm = true"
            :disabled="auditoriaCerrada">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Crear</span>
      </v-tooltip>
      <!-- <v-menu offset-y offset-x left>
        <template v-slot:activator="{ on }">
          <v-btn small fab depressed color="secondary2" class="ml-4 white--text" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="item in sitesAvailable">
            <v-divider :key="item.id" v-if="item.divider"/>
            <v-subheader :key="item.id" v-else-if="item.header">
              {{ item.header }}
            </v-subheader>
            <v-list-item :key="item.id" @click="showConfirm(item)" v-else>
              <v-list-item-title>{{ item.nombre }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu> -->
    </v-card-title>

    <div style="height: 100%">
      <div class="ag-container">
        <ag-grid-vue
          style="width: 100%; height: 80vh;"
          :suppressCellSelection="true"
          class="ag-theme-material ag-grid"
          id="ag-grid"
          :columnDefs="headers"
          :rowData="items"
          :animateRows="true"
          :pagination="true"
          :sideBar="sideBar"
          :paginationAutoPageSize="true"
          :quickFilter="search"
          :singleClickEdit="true"
          rowGroupPanelShow="always"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :frameworkComponents="frameworkComponents"
          @grid-ready="onGridReady"
          @rowClicked="clickRow"
        ></ag-grid-vue>
      </div>
    </div>

  </div>
</template>

<script>
import AgDatatable from '~/components/AgDatatable'
import Confirmation from '~/components/Confirmation'
import Loading from '~/components/Loading'
import Estados from '~/models/estados'
import moment from 'moment'
import { uuid } from 'vue-uuid'

export default {
  name: 'Muestras',
  props: {
    auditoria: { type: Object, default: () => {} },
    sitesAvailable: { type: Array, default: () => [] },
  },
  components: {
    Loading,
    AgDatatable,
    Confirmation,
  },
  beforeMount() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Por favor, espere mientras se cargan los datos...</span>'
    this.frameworkComponents = {}
    this.sideBar = {
      toolPanels: ['filters', 'columns'],
    }
  },
  data: () => ({
    valid: true,
    confirm: false,
    site: null,
    items: [],
    rules: [(v) => !!v || 'Obligatorio'],
    headers: [
      {
        headerName: 'Código',
        field: 'codigo',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Site',
        field: 'site.nombre',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Personal Nombre',
        field: 'personal_nombre',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Responsable',
        field: 'responsable',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Estado',
        field: 'estado',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value === Estados.MUESTRAS_ESTADO.NUEVA) return { color: '#2196F3' }
          else if (params.value === Estados.MUESTRAS_ESTADO.PENDIENTE) return { color: '#43A047' }
          else if (params.value === Estados.MUESTRAS_ESTADO.FINALIZADA) return { color: '#EF5350' }
          else return null
        },
      },
      {
        headerName: 'Cumple',
        field: 'cumple',
        sortable: true,
        enableRowGroup: true,
        filter: true,
        cellRenderer: (params) => {
          const item = params.data
          const value = item && item.cumple
          return value
            ? '<i class="v-icon mr-1 mdi mdi-check theme--light" style="font-size: 17px;"></i> Cumple'
            : '<i class="v-icon mr-1 mdi mdi-close theme--light" style="font-size: 17px;"></i> No cumple'
        },
        // cellRendererFramework: 'SwitchRenderer', cellRendererParams: { label: " " }
      },
      {
        headerName: 'Fecha',
        field: 'created_at',
        sortable: true,
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.created_at
          return value ? moment(value).format('L') : ''
        },
      },
    ],
    search: '',
    options: null,
    loading: true,
    page: 1,
    pageCount: 0,
    gridApi: null,
    columnApi: null,
    sideBar: {},

    auth: {},
  }),
  computed: {
    //+-------------------------------------------------
    // isDisabled()
    // Casos en los que no se permite la edición de campos
    // -----
    // Created on Thu Oct 13 2022
    //+-------------------------------------------------
    isDisabled() {
      if (!this.auth?.canEdit) return true
      if (this.auditoriaCerrada) return true

      let isAuditor = this.$auth.user.roles.includes('ROLE_AUDITOR')
      let isTheAuditor = this.$auth.user.uuid === this.auditoria.responsable.uuid
      let isAuditorAdmin = this.$auth.user.roles.includes('ROLE_AUDITOR_ADMIN')
      let isAuditorInAuditores = this.auditoria.auditores.some((auditor) => {
        return this.$auth.user.uuid === auditor.uuid
      })

      if (isAuditor && !isTheAuditor && !isAuditorInAuditores && !isAuditorAdmin) return true

      return false
    },

    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    auditoria_uuid() {
      return this.auditoria?.uuid ? this.auditoria.uuid : this.$route.params.uuid
      return this.$route.params.uuid
    },
    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },

    auditoriaCerrada() {
      return (
        (this.auditoria &&
          this.auditoria.estado?.toUpperCase() === Estados.AUDITORIA_ESTADO.CERRADA) ||
        (this.auditoria && this.auditoria.estado?.toUpperCase() == 'REALIZADA')
      )
    },
  },
  methods: {
    flagCumple() {
      if (this.items) {
        let cumple = false
        this.items.forEach((item) => {
          this.auditoria.toma_muestras.forEach((aud) => {
            if (aud.codigo == item.codigo) {
              let state = aud.muestras.map((a) => {
                a.estado
              })
              let params = aud.muestras.map((a) => {
                if (a.estado == 'NO_CUMPLE' && a.params.length > 0) {
                  return false
                }
                /* if (a.estado == undefined && a.params.length > 0) {

                  return false
                } */
                if (a.estado == 'CUMPLE' && a.params.length > 0) {
                  return true
                }
              })
              console.log(
                'state muestra',
                state,
                state.indexOf('CUMPLE'),
                state.indexOf(undefined),
                state.indexOf('NO_CUMPLE')
              )
              console.log('params=>', params, params.includes(0), params.includes(true))
              if (this.isOceGlobal) {
                if (state.indexOf('CUMPLE') == -1 && params.includes(true)) {
                  cumple = true
                }
                if (state.indexOf('NO_CUMPLE') == -1 && params.includes(false)) {
                  cumple = false
                }
              } else {
                if (state.indexOf(undefined) == -1 && state.indexOf('NO_CUMPLE') == -1) {
                  cumple = true
                } else {
                  cumple = false
                }
              }
            }
          })
          console.log('cumple', cumple)
          item.cumple = cumple
        })
      }
    },

    showConfirm(item) {
      this.site = item
      this.confirm = true
    },
    close() {
      this.site = {}
      this.$refs.form.reset()
    },
    async createTomaMuestra() {
      if (this.$refs.form.validate()) {
        const body = {
          auditoria: this.auditoria,
          site: this.site,
          uuid: uuid.v4(),
        }
        body.codigo = this.auditoria.expediente.codigo
        body.responsable = this.auditoria.responsable.uuid
        await this.$store.dispatch('tomas_muestra/create', body)
        this.$router.push({
          path: `/auditorias/tabs/muestras/${body.uuid}`,
          query: { auditoria_uuid: this.auditoria_uuid },
        })
        this.confirm = false
      }
    },
    onGridReady(params) {
      this.gridApi = params.api
      // if (!this.offlineMode) this.gridApi.showLoadingOverlay()
      this.gridApi.sizeColumnsToFit()
    },
    clickRow(params) {
      if (params.data && params.data !== undefined)
        this.$router.push({
          path: `/auditorias/tabs/muestras/${params.data.uuid}`,
          query: { auditoria_uuid: this.auditoria_uuid },
        })
      else params.node.setExpanded(!params.node.expanded)
    },
    expandirFilas(on) {
      if (on === 1)
        this.gridApi.forEachNode((node) => {
          if (node.group) node.setExpanded(true)
        })
      else
        this.gridApi.forEachNode((node) => {
          if (node.group) node.setExpanded(false)
        })
      this.gridApi.sizeColumnsToFit()
    },
    updateFilter() {
      this.gridApi.setQuickFilter(this.search)
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
      })
    },

    async init() {
      this.checkRoles()

//      const res = await this.$store.dispatch('tomas_muestra/list', this.auditoria_uuid)
      console.warn(res)
      if (res) this.items = res

      this.flagCumple()

      if (this.isDisabled) return
      this.headers.push({
        headerName: 'Acciones',
        sortable: false,
        pinned: 'right',
        width: 150,
        cellRendererFramework: 'DeleteRenderer',
        valueGetter: (params) => {
          const estado = this.auditoria.estado
          return estado == Estados.AUDITORIA_ESTADO.CERRADA
        },
        cellRendererParams: {
          text: '¿Eliminar la muestra seleccionada?',
          dispatch: 'tomas_muestra/deleteTomaMuestra',
          init: this.init.bind(this),
        },
      })
    },
  },
  created() {
    this.init()
  },
}
</script>

<style>
.ag-theme-material .ag-icon-checkbox-checked {
  color: #2e3444 !important;
}
</style>
