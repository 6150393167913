<template>
  <v-dialog id="documents-downloader" v-model="ui.dialog" :max-width="1200">
    <v-card>
      <v-card-title>
        <v-avatar color="grey lighten-4" size="36" class="mr-2">
          <v-icon color="grey">mdi-cloud</v-icon>
        </v-avatar>
        Archivos multimedia

        <v-spacer></v-spacer>
        <v-btn icon text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-7 pb-0">
        <p>
          <!-- This text might be defined by props for the caller -->
          Se han encontrado los siguientes documentos asociados.<br>
          Descarga los documentos que necesites
        </p>

        <v-divider></v-divider>
      </v-card-text>

      <v-row class="mt-4">
        <v-col cols="12" class="px-9 pt-0 pb-0">
          <v-text-field
            v-model="table.search"
            label="Buscar"
            placeholder=""
            append-icon="mdi-magnify"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-data-table
            ref="datatable"
            :headers="table.headers"
            :items="items"
            :items-per-page="10"
            :search="table.search"
            sort-by="name"
            loading-text="Cargando archivos, por favor espera"

            :class="{'disabled': ui.loading}"
            :loading="ui.loading">

            <template v-slot:item.name="{ item }">
              <v-icon class="mr-2">{{ fileIcon(item) }}</v-icon>
              {{ item.name }}
            </template>

            <template v-slot:item.dates="{ item }">
              <v-chip small label style="background: transparent; transform: translateX(-10px); color: #929292;">
                {{ $moment(item.date).format("DD [de] MMMM, YYYY") }}
              </v-chip>
            </template>

            <template v-slot:item.filetype="{ item }">
              {{ item.type }}
            </template>

            <template v-slot:item.filesize="{ item }">
              {{ item.size }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    dense
                    text
                    color="primary darken-3"
                    v-bind="attrs"
                    v-on="on"
                    style="min-widht: 40px;"
                    @click="download(item)">
                    <v-icon style="font-size: 20px">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Descargar archivo</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-card-actions v-if="!ui.loading && !ui.saving">
        <v-spacer></v-spacer>
        <v-btn
          small text outlined
          color="success"
          @click="close">
          Cerrar
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    downloader.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 16th November 2021
 * Last Modified: Fri Nov 19 2021
 **/

import {mapGetters, mapState} from 'vuex'
import notifications from '~/util/notificationHelper'
import files from '~/util/filesHelper'

export default {
  name: 'documentsDownloader',

  data: () => ({
    filters: {
      search: '',
    },

    table: {
      headers: [
        {text: 'Nombre', value: 'name', width: 200, sortable: false},
        {text: 'Módulo', value: 'data.modulo', cellClass: 'subData', width: 100, sortable: false},
        {text: 'Tamaño', value: 'filesize', cellClass: 'subData', width: 100, sortable: false},
        {
          text: 'Última modificación',
          value: 'dates',
          cellClass: 'subData',
          width: 100,
          sortable: false,
        },

        {text: '', align: 'right', value: 'actions', width: 120, sortable: false},
      ],
    },

    db: {
      files: [],
    },

    ui: {
      valid: false,
      dialog: false,
      saving: false,
      loading: false,
    },
  }),

  computed: {
    isOffline: function () {
      return this.$nuxt.isOffline
    },

    isClient() {
      return this.$store.getters.isCliente
    },

    items() {
      let items = []
      if (this.db.files.length == 0) return []

      this.db.files.forEach((item) => {
        let type = files.fileType(item)

        items.push({
          name: item.titulo, //.toLowerCase(),
          date: item.created_at || '--',
          type: type,
          size: files.fileSize(item),
          data: item,
        })
      })

      return items
    },
  },

  methods: {
    open(payload) {
      this.getFiles(payload)
      this.ui.dialog = true
    },

    close() {
      this.ui.dialog = false
      this.ui.saving = false
      this.ui.loading = false
    },

    fileIcon(item) {
      return files.fileIcon(item)
    },

    //+-------------------------------------------------
    // getFiles()
    // Reads files from data and assign to this
    // In the future could detect if files are given
    // by event, prop or this component can make the call for files
    // -----
    // Created on Wed Nov 17 2021
    //+-------------------------------------------------
    getFiles(data) {
      if (data.files.length > 0) {
        this.db.files = data.files
      }
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async download(doc) {
      await this.$store.dispatch('documentos/download', doc.data)
    },

    async init() {
      this.$nuxt.$on('documents:openDownloader', (payload) => {
        this.open(payload)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('documents:openDownloader')
  },
}
</script>

<style>
.v-dialog {
  overflow-x: hidden;
}

.v-dialog.v-dialog--active::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-thumb {
  background-color: #00000069;
  border-radius: 15px;
  border: 1px solid #a5a5a5;
}
</style>
