/**
 * @project: certiapp-nuxt
 * @file:    sites.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Thu Jul 13 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async create({ rootState }, payload) {
    console.info('store create', payload)
    rootState.loading = true

    const body = {
      uuid: payload.uuid,
      // estado: payload.estado, // Se define en la api a 15/09/2021
      nombre: payload.nombre,
      tipo: payload.tipo,
      subtipo: payload.subtipo,
      certificado: payload.certificado,
      planificable: payload.planificable,

      iteracion: payload.iteracion_uuid,
      parent: payload.parent_uuid,

      iteracion_uuid: payload.source_iteracion_uuid
        ? payload.source_iteracion_uuid
        : payload.iteracion_uuid,
      parent_uuid: payload.source_parent_uuid,

      data: payload.data,
    }

    if (payload.instalacion?.uuid) body.instalacion = payload.instalacion.uuid

    console.info('body', body)
    await this.$axios.post(`sites`, body)
    rootState.loading = false
  },

  async update({ rootState, state, commit }, site) {
    rootState.loading = true

    const body = {
      ...site,
      site: site.uuid,
    }

    delete body.uuid
    const keys = Object.keys(body)
    let key = ''
    keys.forEach((e) => {
      if (e !== 'site') key = e
    })
    try {
      await this.$axios.patch(`sites/${site.uuid}`, body)
    } catch (e) {
      const alert = {
        text: `Ha ocurrido un error actualizando: ${key}.`,
        color: 'error',
        timeout: 3000,
      }
      this.commit('notification/show', alert)
    } finally {
      rootState.loading = false
    }
  },
}
