<template>
    <div>
        <template v-for="(no_conformidad, idx) in no_conformidades" :key="">
            <table style="    border: double;    border-width: thick;">
                <tbody>
                    <tr>
                        <td colspan="2" style="text-align: center; background-color:PaleGoldenrod;">ACCION
                            CORRECTIVA&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="background-color:PaleGoldenrod;">EMPRESA:</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            {{no_conformidad.site.nombre}}
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">NO CONFORMIDAD N&ordm;:</td>
                        <td style="background-color:PaleGoldenrod;">FECHA DE LA AUDITORIA :</td>
                    </tr>
                    <tr>
                        <td>{{no_conformidad.codigo}}</td>
                        <td>{{ formatDate(datos.desde) }}</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">DESVIACI&Oacute;N N&ordm;:</td>
                        <td rowspan="6"></td>
                    </tr>
                    <tr>
                        <td>{{idx + 1}}</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">OBSERVACI&Oacute;N N&ordm;:</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">TIPO (si aplica: Muy Grave, Grave, Leve):</td>
                    </tr>
                    <tr>
                        <td>{{no_conformidad.peso | capitalize}}</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;" colspan="2">DESCRIPCION DE LA NO CONFORMIDAD /
                            DESVIACI&Oacute;N / OBSERVACION</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="height:120px;" ><span v-html="no_conformidad.titulo"></span></td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;" colspan="2">ANALISIS DE LA CAUSA (&iquest;Qu&eacute;
                            ha fallado en el sistema para que este
                            incumplimiento haya aparecido?</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height:180px;"><span v-html="no_conformidad.no_conformidad"></span></td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;" colspan="2">CORRECCION (&iquest;Qu&eacute;
                            acci&oacute;n inmediata se hace para resolver el
                            incumplimiento?</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height:180px;"><span v-html="no_conformidad.observaciones"></span></td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">PLAZO DE IMPLANTACION:&nbsp;</td>
                        <td>{{ formatDate(no_conformidad.accion_correctiva.fecha_implantacion) }}</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;" colspan="2">DOCUMENTACION JUSTIFICATIVA ADJUNTA
                            (&iquest;Qu&eacute; registros se han
                            generado para el cierre de la No Confomidad?)</td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height:180px;">{{no_conformidad.accion_correctiva.accion_propuesta}}</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">Fecha emisi&oacute;n acci&oacute;n correctiva:</td>
                        <td>{{ formatDate(no_conformidad.accion_correctiva.fecha_implantacion) }}</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">Realizado por:</td>
                        <td style="background-color:PaleGoldenrod;">Supervisado por:</td>
                    </tr>
                    <tr>
                        <td>{{no_conformidad.accion_correctiva.responsable_implantacion}}</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="background-color:PaleGoldenrod;">Firma:</td>
                        <td style="background-color:PaleGoldenrod;">Firma:</td>
                    </tr>
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>

<style scoped>
    table {
        border-collapse: collapse;
    }

    table,
    th,
    td {
        border: 0.5px solid black;
    }

    thead {
        display: table-row-group;
    }

    tr td {
        page-break-inside: avoid;
    }

    .no-border {
        border: none !important;
    }
</style>

<script>
    export default {
        props: {
            datos: {
                type: Object,
                default: () => {}
            }
        },
        data: function () {
            return {
                ...this.datos
            }
        },
        methods: {
            formatDate(date) {
                return date ? this.$moment(date).format('DD/MM/YYYY') : ''
            },
        }
    }
</script>
