import { uuid } from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: [],
  tab: 0,
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  CHANGE_TAB(state, tab) {
    state.tab = tab
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, uuid) {
    rootState.loading = true
    const body = {}
    if (uuid) body.expediente = uuid
    const res = await this.$axios.get('revisiones', body)
    rootState.loading = false
    if (res) {
      const data = res.data['hydra:member']
      commit('SET_LIST', data)
      return data
    }
  },
  async listByIteracion({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`revisiones?iteracion.uuid=${uuid}`)
    rootState.loading = false
    if (res) {
      const data = res.data['hydra:member']
      commit('SET_LIST', data)
      return data
    }
  },

  async listBySubalcance({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`revisiones?subalcance.uuid=${uuid}`)
    rootState.loading = false
    if (res) {
      const data = res.data['hydra:member']
      commit('SET_LIST', data)
      return data
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`revisiones/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, revision) {
    rootState.loading = true
    // const body = {
    //   uuid: uuid.v4(),
    //   auditoria: revision.auditoria,
    //   auditor: revision.auditor,
    // }
    await this.$axios.post('revisiones', revision)
    rootState.loading = false
  },

  async update({ rootState }, revision) {
    rootState.loading = true

    // const body = {
    //   revision: revision.uuid,
    //   auditoria: revision.auditoria,
    //   auditor: revision.auditor,
    // }
    revision.revision = revision.uuid // hack to make the API happy

    await this.$axios.patch(`revisiones/${revision.uuid}`, revision, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async proponer({ rootState }, revision) {
    rootState.loading = true
    const body = {
      proposicion: revision.proposicion,
      fechaProposicion: revision.fechaProposicion,
      auditor1: revision.auditor1,
      enCalidadDeAuditor1: revision.enCalidadDeAuditor1,
      observacionesAuditor1: revision.observacionesAuditor1,
      auditor2: revision.auditor2,
      enCalidadDeAuditor2: revision.enCalidadDeAuditor2,
      observacionesAuditor2: revision.observacionesAuditor2,
      revision: revision.uuid,
    }
    await this.$axios.patch(`revisiones/${revision.uuid}/proponer`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async cambiarEstado({ rootState }, revision) {
    rootState.loading = true
    const body = {
      revision: revision.uuid,
    }
    await this.$axios.patch(`revisiones/${revision.uuid}/revisada`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async delete({ rootState }, revision) {
    rootState.loading = true
    await this.$axios.delete(`revisiones/${revision.uuid}`)
    rootState.loading = false
  },
}
