import {uuid} from 'vue-uuid'

export const state = () => ({
  list: [],
  fichero: {
    datos: []
  }
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  SET_FICHERO(state, fichero) {
    state.fichero = fichero
  },
  INIT_FICHERO(state, fichero) {
    state.fichero = {
      datos: []
    }
  },
};
//dispatch actions
export const actions = {

  async list({rootState, commit}, params) {
    rootState.loading = true
    const res = await this.$axios.get('cargas')
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`cargas/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, carga) {
    rootState.loading = true
    carga.uuid = uuid.v4()
    const res = await this.$axios.post('cargas', carga)
    rootState.loading = false
    if (res) return res
  },

  async update({rootState}, carga) {
    rootState.loading = true
    carga.carga = carga.uuid
    await this.$axios.patch(`cargas/${carga.uuid}`, carga)
    rootState.loading = false
  },

  async delete({rootState}, carga) {
    rootState.loading = true
    await this.$axios.delete(`cargas/${carga.uuid}`)
    rootState.loading = false
  },

};
