<template>
  <v-dialog v-model="ui.show" width="auto" max-width="890px" min-width="450px" persistent>
    <v-form ref="form" v-model="ui.isValid">
      <v-card>
        <v-card-title v-if="!show_toggles" class="headline"> Datos de la Instalación </v-card-title>
        <v-card-title v-else class="headline"> Datos de la Dirección </v-card-title>

        <v-card-text class="px-10">
          <v-row>
            <v-col cols="4" class="pt-0 pb-2">
              <v-switch
                inset
                v-model="item.principal"
                hide-details="auto"
                color="green"
                :label="
                  item.principal ? 'Es la dirección principal' : 'No es la dirección principal'
                ">
              </v-switch>
            </v-col>

            <v-col cols="4" class="pt-0 pb-2">
              <v-switch
                inset
                v-model="item.active"
                hide-details="auto"
                color="green"
                :label="item.active ? 'Activa' : 'Desactivada'">
              </v-switch>
            </v-col>

            <!-- <v-col cols="12" v-if="!item.is_active && !isPortal">
              <v-alert
                
                :icon="false"
                type="warning"
                border="left">
                Una instalación desactivada no se podrá utilizar en algunos lugares de la aplicación
              </v-alert>
            </v-col> -->

            <!-- <v-col cols="4" class="pl-5" style="display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;">
              <v-switch
                v-model="item.is_active"
                color="success"
                inset
                :label="item.is_active ? 'Activa' : 'Desactivada'"
                :disabled="isPortal"
                class="pa-0 ma-0"
              ></v-switch>
            </v-col> -->

            <v-col cols="12" class="mt-2">
              <v-text-field
                label="Nombre"
                density="comfortable"
                v-model="item.nombre"
                hide-details="auto"
                :rules="rules"
                dense>
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                label="Código"
                density="comfortable"
                v-model="item.codigo"
                hide-details="auto"
                :rules="rules"
                dense>
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <SelectEstaticos
                class="mt-2"
                dense
                :version="1"
                label="Tipo de dirección"
                v-model="item.tipo"
                :codigo="'tipos-de-direccion'"
                required
                @change="showMoreItems"
                :returnObject="true" />
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="9">
              <v-text-field
                label="Dirección"
                density="comfortable"
                hide-details="auto"
                v-model="item.direccion"
                :rules="rules"
                dense></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                label="C. Postal"
                density="comfortable"
                hide-details="auto"
                v-model="item.codigoPostal"
                :rules="rules"
                dense></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-left:-20px;">
              <SelectLocalidad v-model="item.localidad" :required="true" :empresa="empresa" />
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="4">
              <v-text-field
                density="comfortable"
                hide-details="auto"
                label="Email"
                v-model="item.email"
                dense></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                density="comfortable"
                hide-details="auto"
                label="Teléfono"
                v-model="item.telefono"
                dense></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                density="comfortable"
                hide-details="auto"
                label="Teléfono (2)"
                v-model="item.telefono2"
                dense></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12">
              <v-textarea
                density="comfortable"
                rows="3"
                hide-details="auto"
                label="Descripción"
                v-model="item.descripcion"
                dense></v-textarea>
            </v-col>

            <!-- <v-col cols="4">
              <v-dialog ref="datepicker" v-model="ui.showPicker" :return-value.sync="item.fechaValidez" persistent
                width="290px">

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="d-none" v-model="item.fechaValidez" label="Picker in dialog"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                </template>

                <v-date-picker v-model="item.fechaValidez" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="ui.showPicker = false">
                    Cancelar
                  </v-btn>

                  <v-btn text color="primary" @click="syncDate()" nopeclick="$refs.datepicker.save(item.fechaValidez);">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col> -->

            <v-col cols="11" class="mx-auto mt-0 mb-3">
              <v-divider></v-divider>
            </v-col>
            <template v-if="ui.showMoreItems">
              <v-col cols="12" class="pt-0 pb-2">
                <v-switch
                  inset
                  v-model="item.empresaGrupo"
                  hide-details="auto"
                  color="green"
                  label="Empresa grupo">
                </v-switch>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Razón social"
                  v-model="item.razonSocial"
                  :rules="rules"
                  required
                  dense></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="NIF" v-model="item.nif" maxlength="11" dense></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="N° RGSEAA" v-model="item.rgseaa" dense></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  density="comfortable"
                  hide-details="auto"
                  label="RIA"
                  v-model="item.ria"
                  dense
                  required></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  density="comfortable"
                  hide-details="auto"
                  label="RSI"
                  v-model="item.rsi"
                  dense
                  required></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Fecha validez"
                      :value="formatDate(item.fechaValidez)"
                      clearable
                      dense
                      readonly
                      v-on="on"
                      @click:clear="item.fechaValidez = ''"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.fechaValidez"
                    locale="es"
                    color="primary"
                    :first-day-of-week="1"
                    no-title
                    scrollable
                    @change="menu = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="11" class="mx-auto mt-0 mb-3">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <SelectTipoInstalacion
                  dense
                  v-model="item.tipo_instalacions"
                  :rules="rules"
                  autoSelectField="codigo"
                  autoSelectValue="propia"
                  required />
              </v-col>

              <!-- <v-col cols="12">
              <SelectTipoInstalacion dense v-model="item.tipo_instalacions" :rules="rules" autoSelectField="codigo"
                autoSelectValue="propia" required />
            </v-col>-->

              <!-- <v-col cols="6"> -->
              <!-- <SelectPais
                v-model="item.pais.uuid"
                required
                dense
              /> -->
              <!-- </v-col> -->

              <!-- <v-col cols="6"> -->
              <!-- <SelectComunidad
                v-if="item.comunidad"
                v-model="item.comunidad.uuid"
                :pais_uuid="item.pais.uuid"
                :disabled="!item.pais.uuid"
                dense
              /> -->
              <!-- </v-col> -->

              <!-- <v-col cols="6"> -->
              <!-- <SelectProvincia
                v-if="item.provincia"
                v-model="item.provincia.uuid"
                :pais_uuid="item.pais.uuid"
                :disabled="!item.pais.uuid"
                dense
              /> -->
              <!-- </v-col> -->

              <!-- <v-col cols="6"> -->
              <!-- <SelectMunicipio
                v-if="item.municipio"
                v-model="item.municipio.uuid"
                :provincia_uuid="item.provincia.uuid"
                :disabled="!item.provincia.uuid"
                dense
              /> -->
              <!-- </v-col> -->

              <!-- <v-col cols="12"> -->
              <!-- <SelectLocalidad
                v-model="item.localidad"
                dense
                required
              /> -->
              <!-- <div style="display: none;">

              <v-text-field
                v-model="item.localidad.uuid"
                required
                :rules="rules">
              </v-text-field>
              </div> -->
              <!-- </v-col> -->

              <!-- <v-col cols="4">
              <v-text-field
                  label="Código Postal"
                  v-model="item.cp"
                  dense
              ></v-text-field>
            </v-col> -->

              <!-- <v-col cols="12">
              <v-text-field
                label="Dirección"
                v-model="item.direccion"
                dense
              ></v-text-field>
            </v-col> -->

              <v-col cols="12">
                <v-textarea
                  label="Actividades"
                  v-model="item.tramites"
                  dense
                  auto-grow></v-textarea>
                <!-- <select-actividades
                  v-model="item.tramites"
                  :label="isCerticalidad ? 'Actividades Norma' : 'Actividades'"
                  dense
                  multiple
                  returnObject></select-actividades> -->
              </v-col>

              <v-col cols="12" v-if="isCerticalidad">
                <v-select
                  v-model="item.actividades_rgseaa"
                  :items="[
                    'Matadero de porcino',
                    'Sala de despiece de carne de ungulados',
                    'Fabricación, elaboración o transformación de productos cárnicos',
                    'Salado y secado de jamones',
                    'Salado y secado de carne y derivados cárnicos',
                    'Envasado de productos cárnicos',
                    'Reenvasador de productos de origen animal',
                    'Almacenamiento frigorífico y en congelación',
                    'Almacenamiento sin refrigeración',
                    'Almacenamiento polivalente',
                    'Distribución de productos cárnicos',
                    'Distribución de carne de ungulados',
                  ]"
                  label="Actividades RGSEAA"
                  hide-details="auto"
                  dense
                  multiple></v-select>
              </v-col>
              <v-col cols="11" class="mx-auto mt-0 mb-3">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  class="pa-0"
                  dense
                  v-model="item.certificada"
                  color="primary"
                  label="Certificada" />
              </v-col>

              <!-- <v-col cols="8"> -->
              <!-- <v-select
                v-model="item.contacto.uuid"
                label="Contacto asociadox"
                hide-details="auto"
                items="db.contactos"
                dense
              >

              </v-select> -->
              <!-- <SelectContactos
                label="Contacto asociado"
                v-model="item.contacto.uuid"
                :cliente='cliente'
                :create="true"
                dense
              /> -->
              <!-- </v-col> -->

              <v-col cols="6">
                <v-text-field
                  label="Entidad Certificadora"
                  v-model="item.entidadCertificadora"
                  dense></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Numero de certificado"
                  v-model="item.numeroCertificado"
                  dense></v-text-field>
              </v-col>

              <!-- <v-col cols="12">
              <v-textarea
                label="Observaciones"
                v-model="item.observaciones"
                
                auto-grow
              ></v-textarea>
            </v-col> -->
              <!-- Campos Dinamicos -->
              <v-col
                class="row pa-3 mx-3"
                cols="12"
                style="border: 1px solid #ccc; border-radius: 3px">
                <h3 class="pb-2 col col-12">Campos personalizados</h3>
                <template v-if="!item.instalacionData || item.instalacionData.length == 0">
                  <v-btn @click="addCamposPersonalizados" outlined> Añadir campos </v-btn>
                </template>
                <template v-else>
                  <v-col cols="6" v-for="(c, i) in item.instalacionData" :key="i">
                    <v-text-field
                      :label="c.keyname"
                      v-model="c.value"
                      outlined
                      dense
                      hide-details="auto"></v-text-field>
                  </v-col>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!ui.loading" class="pa-4">
          <v-btn text @click="close" :disabled="ui.loading"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="submit" :disabled="ui.loading || !ui.isValid">
            Guardar</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else style="flex-direction: column" class="px-8">
          <div class="text-disabled d-block">Guardando dirección...</div>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="4"></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\clientes\instalaciones\GestionInstalacionesDefaultDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Jul 24 2023
 **/

import notifications from '~/util/notificationHelper'

import CamposDinamicos from '~/components/clientes/instalaciones/araporcei/CamposDinamicos'
import CamposDinamicosManchengo from '~/components/clientes/instalaciones/manchengo/CamposDinamicos'
import SelectComunidad from '~/components/forms/SelectComunidad'
import SelectActividades from '~/components/forms/SelectActividades'
import SelectProvincia from '~/components/forms/SelectProvincia'
import SelectMunicipio from '~/components/forms/SelectMunicipio'
import SelectPais from '~/components/forms/SelectPais'
import SelectLocalidad from '../../forms/SelectLocalidades'
import SelectTipoInstalacion from '../../forms/SelectTipoInstalacion'
import SelectContactos from '../../forms/SelectContactos'
import SelectEstaticos from '~/components/forms/SelectEstaticos.vue'

import fieldsCerticalidad from '~/components/instalaciones/customFields/certicalidad'

export default {
  name: 'GestionInstalacionesDefaultDialog',
  props: {
    value: Boolean,
    cliente: { type: Object, default: null },
    instalacion: { type: Object, default: null },
    isPortal: { type: Boolean, default: false },
    location: { type: Object, default: null },
    show_toggles: { type: Boolean, default: true },
    is_contacto: { type: Boolean, default: false },
    is_empresa: { type: Boolean, default: false },
    empresa: { type: Object, default: null },
  },

  components: {
    SelectTipoInstalacion,
    SelectLocalidad,
    CamposDinamicos,
    SelectPais,
    SelectMunicipio,
    SelectProvincia,
    SelectComunidad,
    SelectActividades,
    SelectContactos,
    CamposDinamicosManchengo,
    fieldsCerticalidad,
    SelectEstaticos,
  },
  data: () => ({
    item: {
      action: 'create',
    },

    dummy: {
      principal: false,
    },
    menu: false,

    rules: [(v) => !!v || 'Obligatorio'],

    datos: [],
    fields: [],

    ui: {
      show: false,
      loading: false,
      isValid: false,
      showPicker: false,
      showMoreItems: false,
    },
  }),

  watch: {
    value(val) {
      if (val) {
        this.ui.show = true
        this.item = val
        this.item.action = 'update'
      }
    },

    instalacion(val) {
      console.info('🔴 instalacion', val)
      if (val) {
        this.ui.show = true
        this.item = val
        this.item.action = 'update'
        if (this.item.tipo == 'Instalación') this.ui.showMoreItems = true
        else this.ui.showMoreItems = false
      }
      //else this.resetForm()
    },
    show_toggles(val) {
      if (!val) this.item.principal = true
    },

    //'ui.show': function (value) {
    //  this.reset()
    //},
  },

  computed: {
    isNew() {
      return this.instalacion === null
    },
    empresa() {
      return this.is_empresa ? this.cliente : null
    },
    isNewCliente() {
      return this.$route.params.uuid === '_' && !this.cliente
    },
    isCerticalidad() {
      return this.$auth.user.empresa.codigo == 'certicalidad'
    },
    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },
  },

  methods: {
    close() {
      this.ui.show = false
      this.$emit('close')
    },
    showMoreItems() {
      console.log('showMoreItems', this.item.tipo)
      if (this.item.tipo.emplazamiento){ 
        this.ui.showMoreItems = true
      }
      else {
        this.ui.showMoreItems = false
        delete(this.item.tipo_instalacions)
      }
    },

    newItem() {
      this.reset()
      this.item = { ...this.dummy }
      this.ui.showMoreItems = false
      this.item.action = 'create'

      this.ui.show = true
    },

    nifResponsableErrors() {
      const errors = []
      if (!this.$v.item.nif.$dirty) return errors
      !this.$v.item.nif.minLength && errors.push('Documento inválido')
      !this.nifResponsableValid && errors.push('Documento inválido')
      return errors
    },

    checkResponsableDocument() {
      let document = 'OTRO'
      const passport = new RegExp('^[P](?:-)*[0-9]+$')
      const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
      const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
      const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
      const other1 = new RegExp('^[0-9]{7}$')
      const other2 = new RegExp('^[0-9]{8}$')
      const other3 = new RegExp('^[0-9]{9}$')
      const other4 = new RegExp('^[0-9]{11}$')
      if (passport.test(this.cliente.responsable_nif)) document = 'PASAPORTE'
      if (dni.test(this.cliente.responsable_nif)) document = 'DNI'
      if (nie.test(this.cliente.responsable_nif)) document = 'NIE'
      if (nif.test(this.cliente.responsable_nif)) document = 'NIF'
      return document
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    formatDateToApi(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : ''
    },

    addCamposPersonalizados(data) {
      this.item.instalacionData = [
        { keyname: 'superficie_total', value: data.superficie_total || '' },
        { keyname: 'edificado', value: data.edificado || '' },
        { keyname: 'cinicial', value: data.cinicial || '' },
        { keyname: 'ampliaciones', value: data.ampliaciones || '' },
        { keyname: 'situacion', value: data.situacion || '' },
        { keyname: 'curbano', value: data.curbano || '' },
        { keyname: 'pindustrial', value: data.pindustrial || '' },
        { keyname: 'trustico', value: data.trustico || '' },
      ]
      this.$forceUpdate()
    },

    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
    },

    // //+-------------------------------------------------
    // // updateLocation()
    // // -----
    // // Created on Fri Sep 24 2021
    // // Updated on Mon Oct 25 2021
    // //+-------------------------------------------------
    updateLocation(localidadUUID, object) {
      //debugger;
      /* if (object.pais?.uuid) this.item.pais.uuid = object.pais?.uuid
      if (object.comunidad?.uuid) this.item.comunidad.uuid = object.comunidad?.uuid
      if (object.provincia?.uuid) this.item.provincia.uuid = object.provincia?.uuid
      if (object.municipio?.uuid) this.item.municipio.uuid = object.municipio?.uuid */
      // if (object.codigo) this.item.cp = object.codigo

      this.$forceUpdate()
    },

    // async getOne() {
    //   const item = Object.assign({}, this.instalacion)
    //   if (!item.pais) item.pais = { uuid: '' }
    //   if (!item.contacto) item.contacto = { uuid: '' }
    //   if (!item.comunidad) item.comunidad = { uuid: '' }
    //   if (!item.provincia) item.provincia = { uuid: '' }
    //   if (!item.municipio) item.municipio = { uuid: '' }
    //   if (!item.localidad) item.localidad = { uuid: '' }

    //   // if (typeof item.instalacionData == 'object' && item.instalacionData.length == undefined)
    //   //   item.instalacionData = [item.instalacionData]

    //   this.item = item

    //   if (this.isQuesoManchego && Object.keys(item.instalacionData).length)
    //     this.addCamposPersonalizados(item.instalacionData)

    //   // console.info('this.item', this.item);
    // },

    async submit() {
      let step = false

      // 1 . Form validations
      if (await this.validate()) step = 'valid'

      // 2. Do api requests
      if (step == 'valid') await this.store()
      else
        notifications.show({
          text: 'Debes revisar los campos antes de continuar',
          color: 'warning',
        })
    },

    async validate() {
      await this.$refs.form.resetValidation()
      await this.$refs.form.validate()

      if (this.ui.isValid) return true

      console.warn('🔴', this.$refs.form?.errorBag)
      return false
    },

    async NOPEsubmit() {
      if (this.$refs.form.validate()) {
        if (this.isQuesoManchego) {
          //   this.item.instalacionData_original = this.item.instalacionData
          //   this.item.instalacionData.forEach((item) => {
          //     let data = [
          //       {
          //         keyname: 'superficie_total',
          //         value: item.superficie_total,
          //       },
          //       {
          //         keyname: 'edificado',
          //         value: item.edificado,
          //       },
          //       {
          //         keyname: 'cinicial',
          //         value: item.cinicial,
          //       },
          //       {
          //         keyname: 'ampliaciones',
          //         value: item.ampliaciones,
          //       },
          //       {
          //         keyname: 'situacion',
          //         value: item.situacion,
          //       },
          //       {
          //         keyname: 'curbano',
          //         value: item.curbano,
          //       },
          //       {
          //         keyname: 'pindustrial',
          //         value: item.pindustrial,
          //       },
          //       {
          //         keyname: 'trustico',
          //         value: item.trustico,
          //       },
          //     ]
          //     this.datos = this.datos.concat(data)
          //   })
          //   this.item.instalacionData = this.datos
        }

        const body = {
          cliente_uuid: this.cliente == null ? this.$route.params.uuid : this.cliente.uuid,
          instalacion: {
            ...this.item,
          },
        }

        body.instalacion.tramites = this.item.tramites.map((element) => element.uuid)
        body.instalacion.fecha_validez = this.formatDateToApi(this.item.fecha_validez)

        if (this.item.pais) body.instalacion.pais = this.item.pais.uuid
        if (this.item.comunidad) body.instalacion.comunidad = this.item.comunidad.uuid
        if (this.item.provincia) body.instalacion.provincia = this.item.provincia.uuid
        if (this.item.municipio) body.instalacion.municipio = this.item.municipio.uuid
        if (this.item.localidad) body.instalacion.localidad = this.item.localidad.uuid
        if (this.item.contacto) body.instalacion.contacto = this.item.contacto.uuid
        if (!this.item.fecha_validez) delete body.instalacion.fecha_validez
        // console.info('body', body);

        if (body.instalacion.tipo_instalacions?.length > 0) {
          if (body.instalacion.tipo_instalacions[0].uuid) {
            let reduction = body.instalacion.tipo_instalacions.map(function (a) {
              return a.uuid
            })

            body.tiposInstalacion = reduction
          }
        }
        body.instalacionData = this.item.site_data

        if (this.isNew) {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/create', body)

          if (!this.isNewCliente) {
            this.$emit('created', this.item)
          }
        } else {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/update', body)

          if (!this.isNewCliente) {
            this.$emit('updated', this.item)
          }
        }
        if (!this.isNewCliente) {
          this.$emit('init')
        }
        this.close()
      }
    },

    formatToStore(data) {
      console.warn('✨ Formating to store', this.item)

      if (!data.user && this.cliente['@id']) {
        if (this.is_contacto) data.contacto = this.cliente['@id']
        if(this.is_empresa) data.company = this.cliente['@id']
        if (!this.is_contacto && !this.is_empresa) data.user = this.cliente['@id']
      }
      //check if data localidad is a string or an object
      if (typeof data.localidad == 'object')
        data.localidad = '/api/localidades/' + data.localidad.uuid
      else data.localidad = '/api/localidades/' + this.item.localidad
      if (typeof data.tipo == 'object') data.tipo = data.tipo.texto

      console.warn('✨ Formated', data)
      return data
    },

    async store() {
      this.ui.loading = true

      let index = this.item.index || 0
      let action = this.item.action

      let payload = { ...this.item }
      payload = this.formatToStore(payload)

        if (this.isNew && !this.cliente['@id']) {
        this.$emit('created', {
          index,
          action,
          item: payload,
        })
        this.ui.show = false
        return
      } else {
        try {
          if (action == 'create') await this.$store.dispatch('direcciones/create', payload)
          if (action == 'update') await this.$store.dispatch('direcciones/update', payload)

          this.$emit('stored', {
            index,
            action,
            item: payload,
          })

          this.ui.show = false
        } catch (e) {
          console.info(e)
          notifications.show({
            text: 'Ha ocurrido un error al guardar. Revisa todos los campos',
            color: 'error',
          })
        } finally {
          this.ui.loading = false
        }
      }
    },

    // setInitialValues() {
    //   if (this.isNew) {
    //     this.item.is_active = true

    //     if (this.location?.cp) this.item.cp = this.location.cp
    //     if (this.location?.pais) this.item.pais.uuid = this.location.pais
    //     if (this.location?.comunidad) this.item.comunidad.uuid = this.location.comunidad
    //     if (this.location?.provincia) this.item.provincia.uuid = this.location.provincia
    //     if (this.location?.municipio) this.item.municipio.uuid = this.location.municipio
    //     if (this.location?.localidad) this.item.localidad.uuid = this.location.localidad

    //     if (this.isQuesoManchego) {
    //       this.item.nif = this.cliente.nif
    //       this.item.razon_social = this.cliente.nombre
    //       if (this.isPortal) this.item.is_active = false
    //     }
    //   }
    // },

    async init() {
      this.$nuxt.$on('localidades:selected', (payload) => {
        this.updateLocation(payload.uuid, payload.localidad)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('localidades:selected')
  },
}
</script>
