/**
 * @project: certiapp-nuxt
 * @file:    filesHelper.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 17th November 2021
 * Last Modified: Wed Nov 17 2021
 **/

export default {
  //+-------------------------------------------------
  // fileType()
  // Returns the type of the file
  // Only returns labels, this could be changed to return extenions or other
  // -----
  // Created on Wed Nov 17 2021
  //+-------------------------------------------------
  fileType(file) {
    let type = file.data ? file.data.tipo : file.tipo
    switch (type) {
      case 'application/pdf':
        return 'Archivo PDF'

      case 'image/jpeg':
      case 'image/png':
        return 'Imágen'

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return 'Documento Excel'

      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'Documento Word'

      default:
        return 'Archivo' // file.type
    }
  },

  //+-------------------------------------------------
  // fileSize()
  // Returns the size of the file in human readable format
  // -----
  // Created on Wed Nov 17 2021
  //+-------------------------------------------------
  fileSize(file) {
    let size = file.size || 0
    let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
    let bytes = parseInt(size)

    for (var i = 0; bytes > 1024; i++) {
      bytes /= 1024
    }

    return bytes.toFixed(2) + ' ' + units[i]
    return '0 bytes'
  },

  //+-------------------------------------------------
  // fileIcon()
  // Returns the material icon for each type
  // This could be a model
  // -----
  // Created on Wed Nov 17 2021
  //+-------------------------------------------------
  fileIcon(file) {
    let type = this.fileType(file)

    if (type == 'Imágen') return 'mdi-image'
    if (type == 'Archivo PDF') return 'mdi-file-pdf-box'
    if (type == 'Documento Excel') return 'mdi-file-excel-box'
    if (type == 'Documento Word') return 'mdi-file-word-box'

    return 'mdi-text-box-outline'
  },
}
