<template>
  <div>
    <table class="no-border" style="width: 100%">
      <tbody>
        <tr class="no-border">
          <td class="no-border" style="width: 33.33%; text-align: left">
            <p>
              <strong>{{ clienteNombreCompleto }}</strong>
            </p>
            <p v-if="datos.cliente && datos.cliente.direccion">
              <span style="font-weight: 400">{{
                datos.cliente && datos.cliente.direccion
              }}</span>
              <span v-if="datos.cliente.comunidad">{{
                datos.cliente.comunidad.nombre
              }}</span>
              <span v-if="datos.cliente.provincia">{{
                ", " + datos.cliente.provincia.nombre
              }}</span>
              <span v-if="datos.cliente.municipio">{{
                ", " + datos.cliente.municipio.nombre
              }}</span>
              <span v-if="datos.cliente.cp"><br />{{ datos.cliente.cp }}</span>
            </p>
          </td>
          <td class="no-border" style="width: 33.33%; text-align: center">
            <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
            <h2 style="font-weight: 700">araporcei</h2>
          </td>
          <td class="no-border" style="width: 33.33%; text-align: right">
            <table>
              <tbody>
                <tr>
                  <td colspan="2">
                    <p>REGISTRO DOCUMENTOS</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>
                      <input type="checkbox" />
                      ENTRADA
                    </label>
                  </td>
                  <td>
                    <label><input type="checkbox" /> SALIDA</label>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <p>
                      N&ordm; REGISTRO: {{ datos.iteracion.numero_registro }}
                    </p>
                    <p>
                      FECHA: {{ formatDate(new Date()) }}
                      <input type="checkbox" /> ANEJOS
                    </p>
                    <p>ARCHIVO:<strong> ST</strong></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
    </p>
    <p>
      <strong>ASUNTO: ACUERDO COMISION DE CERTIFICACION CP/IB-243/19</strong>
    </p>
    <p>
      <span style="font-weight: 400">Muy Sres. Nuestros:</span>
    </p>

    <p>
      Mediante el presente nos es grato comunicarles que el Expediente
      relacionado a continuaci&oacute;n, ha sido presentado a Comisi&oacute;n de
      Certificaci&oacute;n en fecha
      <strong>{{ formatDate(datos.fecha_decision) }}</strong>, y a la vista de la informaci&oacute;n se acord&oacute;
      <strong>{{ datos.accion }}</strong> la certificaci&oacute;n al:
    </p>
    <p>&nbsp;</p>
    <p>
      Expediente n&uacute;mero
      <strong>{{ this.datos.auditoria.expediente.codigo }}</strong> cuyo titular
      de la Certificaci&oacute;n es <strong>Araporcei</strong>, para la
      instalaci&oacute;n con n&ordm;
      <strong>RGSEAA {{ obtenerInstalacion().rgseaa }}</strong> y para los
      siguientes documentos normativos:
    </p>
    <p>&nbsp;</p>
    <p>
      <em>- &ldquo;</em>Pliego de Condiciones para la Especialidad Tradicional
      Garantizada &ldquo;Jam&oacute;n Serrano&rdquo;<strong> </strong>
    </p>
    <p>
      <strong>- &ldquo;</strong>PE CER- 02: Procedimiento de Trabajo para la
      realizaci&oacute;n de la Auditoria de Certificaci&oacute;n de Producto
      Especialidad Tradicional Garantizada &ldquo;Jam&oacute;n
      Serrano&rdquo;&rdquo;, en su edici&oacute;n vigente<strong>.</strong>
    </p>
    <p>&nbsp;</p>
    <p>
      El <strong>alcance</strong> del Certificado se mantiene
      <strong>sin cambio</strong>:
    </p>

    <p>&nbsp;</p>
    <table style="width: 1067px; height: 514px" width="656">
      <tbody>
        <tr style="height: 54px">
          <td style="width: 997px; height: 54px; text-align: center;background-color:#ddd" colspan="5" width="656">
            <p><strong>Alcance evaluado</strong></p>
          </td>
        </tr>
        <tr style="height: 54px;background-color:#ddd">
          <td style="width: 583.6px; height: 54px; text-align: center" colspan="3" width="347">
            <p><strong>Alcance de Proceso</strong></p>
          </td>
          <td style="width: 413.4px; height: 54px; text-align: center" colspan="2" width="308">
            <p><strong>Alcance de Producto</strong></p>
          </td>
        </tr>
        <tr style="height: 54px;background-color:#eee">
          <td style="width: 176.2px; height: 130px; text-align: center" rowspan="2" width="102">
            <p><strong>Tipo de Operador</strong></p>
          </td>
          <td style="width: 407.4px; height: 54px; text-align: center" colspan="2" width="246">
            <p><strong>Fases</strong></p>
          </td>
          <td style="width: 237.2px; height: 54px" width="208">
            <p style="text-align: center">
              <strong>Presentaciones Comerciales</strong>
            </p>
          </td>
          <td style="width: 176.2px; height: 130px" rowspan="2" width="100">
            <p style="text-align: center">
              <strong>Marcas comerciales</strong>
            </p>
          </td>
        </tr>
        <tr style="height: 76px">
          <td style="width: 206.2px; height: 76px; text-align: center;background-color:#eee" width="123">
            <p><strong>Actividades realizadas </strong></p>
          </td>
          <td style="width: 201.2px; height: 76px; text-align: center;background-color:#eee" width="123">
            <p><strong>Actividades subcontratadas</strong></p>
          </td>
          <td style="width: 237.2px; height: 76px" width="208">
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find(
                    (x) => x.actividades_subcontratadas == 'Etiquetado'
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />
              Producto con etiqueta
            </p>
          </td>
        </tr>
        <tr style="height: 276px">
          <td style="width: 176.2px; height: 276px" width="102">
            <p>
              <input type="checkbox" v-if="
                  this.datos.solicitud.params.alcance_proceso.tipo_operador.includes('responsable')
                " checked />
              <input type="checkbox" v-else />
              Operador <strong><em>responsable</em></strong> de todas las fases
            </p>
          </td>
          <td style="width: 206.2px; height: 276px" width="123">

            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).some(
                    (x) => x.actividades_realizadas == 'Elaboración'
                  )
                " checked />
              <input type="checkbox" v-else />
              Elaboración
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).some(
                    (x) => x.actividades_realizadas == 'Transformación'
                  )
                " checked />
              <input type="checkbox" v-else />Transformaci&oacute;n
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).some(
                    (x) => x.actividades_realizadas == 'Distribución'
                  )
                " checked />
              <input type="checkbox" v-else />Distribución
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).some(
                    (x) => x.actividades_realizadas == 'Etiquetado'
                  )
                " checked />
              <input type="checkbox" v-else />Etiquetado
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).some(
                    (x) => x.actividades_realizadas == 'Comercialización'
                  )
                " checked />
              <input type="checkbox" v-else />Comercialización
            </p>
          </td>
          <td style="width: 201.2px; height: 276px; text-align: center" width="123">
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).some(
                    (x) => x.actividades_subcontratadas == 'Elaboración'
                  )
                " checked />
              <input type="checkbox" v-else />
              Elaboración
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).some(
                    (x) => x.actividades_subcontratadas == 'Transformación'
                  )
                " checked />
              <input type="checkbox" v-else />Transformaci&oacute;n
            </p>
          </td>
          <td style="width: 237.2px; height: 276px" width="208">
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find((x) =>
                    x.nombre.includes('corte_v_con_pata')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Corte en v con pata
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find((x) =>
                    x.nombre.includes('corte_v_sin_pata')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Corte en v sin pata
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find((x) =>
                    x.nombre.includes('corte_redondo_sin_pata')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Corte redondo sin pata
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find((x) =>
                    x.nombre.includes('corte_redondo_con_pata')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Corte redondo con pata
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find((x) =>
                    x.nombre.includes('deshuesado_con_piel')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Deshuesado con piel
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find((x) =>
                    x.nombre.includes('deshuesado_corte_v')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Deshuesado corte en v
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find((x) =>
                    x.nombre.includes('deshuesado_sin_piel')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Deshuesado sin piel y desengrasado
            </p>
            <p>
              <input type="checkbox" v-if="
                  getSiteData(this.datos.auditoria.sites).find(
                    (x) =>
                      x.nombre.includes('loncheado') ||
                      x.nombre.includes('porcionado')
                  ) != undefined
                " checked />
              <input type="checkbox" v-else />Transformado (loncheado/ troceado)
            </p>
          </td>
          <td style="width: 176.2px; height: 276px" width="100">

            <p v-for="marca in this.getSiteDataAggroupedBy(this.datos.auditoria.sites, 'marcas')" :key="marca.key"
              style="font-weight: 400; text-align: center">
              <span v-html="marca.key"></span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <p>
      Queremos felicitarles por el resultado obtenido en su proceso de
      certificaci&oacute;n conforme se establece en el Pliego de Condiciones
      para la Especialidad Tradicional Garantizada &ldquo;Jam&oacute;n
      Serrano&rdquo;.
    </p>

    <p>
      <span style="font-weight: 400">Sin otro particular, reciban un cordial saludo.</span>
      <span style="font-weight: 400"></span>
      <span style="font-weight: 400"></span>
      <span style="font-weight: 400"></span>
      <span style="font-weight: 400"></span>
    </p>
    <p>
      <span style="font-weight: 400">Teresa L&oacute;pez Rivas</span>
    </p>
    <p>
      <span style="font-weight: 400">Directora T&eacute;cnica</span>
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 0.5px solid black;
  }

  thead {
    display: table-row-group;
  }

  tr td {
    page-break-inside: avoid;
  }

  .no-border {
    border: none !important;
  }
</style>

<script>
  import MODELS from "~/models/araporcei/solicitudes";

  export default {
    props: {
      datos: {
        type: Object,
        default: () => {},
      },
    },
    data: function () {
      return {
        tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
        tipoProducto: MODELS.PRODUCTO_TIPO,
        tipoRacial: MODELS.RACIAL_TIPO,
        tipoEstado: MODELS.ESTADOS_TIPO,
        tipoPresentacion: MODELS.PRESENTACION_TIPO,
        tipoCertificacion: MODELS.CERTIFICACION_TIPO,
      };
    },
    computed: {
      isOperadorResposable() {
        return (
          this.datos.solicitud &&
          this.datos.solicitud.params.alcance_proceso.tipo_operador.includes("responsable")
        );
      },
      clienteNombreCompleto() {
        let nombre = "";
        const cliente = this.datos.cliente;
        if (cliente) {
          if (cliente.nombre) nombre += cliente.nombre;
          if (cliente.primer_apellido) nombre += " " + cliente.primer_apellido;
          if (cliente.segundo_apellido) nombre += " " + cliente.segundo_apellido;
        }
        return nombre;
      },
      sites() {
        const planificacionesSites = this.datos.auditoria.planificaciones.map(
          (e) => e.site.uuid
        );

        const sites = this.datos.auditoria.sites.filter((e) => e.certificado);

        const parentSites = sites.filter((e) =>
          planificacionesSites.includes(e.uuid)
        );
        const parentSitesUuids = parentSites.map((e) => e.uuid);

        const childrenSites = sites.filter((e) =>
          parentSitesUuids.includes(e.parent_uuid)
        );

        return {
          instalaciones: this.getSiteData(parentSites),
          actividades: this.getSiteData(childrenSites),
        };
      },
      instalaciones() {
        const instalacionesNombre = this.sites.instalaciones.map((e) => e.nombre);
        return this.datos.cliente.instalaciones.filter((element) => {
          return instalacionesNombre.some((e) => e.includes(element.rgseaa));
        });
      },
      actividades() {
        return this.sites.actividades;
      },
      productos() {
        const productos = [];
        const alcance_producto = this.datos.solicitud.params.alcance_producto;
        if (alcance_producto) {
          for (let i = 0; i < alcance_producto.length; i++) {
            const element = alcance_producto[i];
            const tipo_producto = this.tipoProducto[element.producto];
            if (!productos.includes(tipo_producto)) productos.push(tipo_producto);
          }
        }
        if (productos.length) {
          const start = productos.slice(0, productos.length - 1);
          const end = productos[productos.length - 1];
          return start.join(", ") + " y " + end;
        } else return "";
      },
    },
    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format("DD/MM/YYYY") : "";
      },
      formatDateLong(date) {
        return date ? this.$moment(date).format("LL") : "";
      },
      getSiteData(sites) {
        const data = [];
        if (sites) {
          for (let i = 0; i < sites.length; i++) {
            const element = sites[i];
            let obj = {};
            obj.uuid = element.uuid;
            obj.nombre = element.nombre;
            obj.tipo = element.tipo;
            for (let k = 0; k < element.site_data.length; k++) {
              const item = element.site_data[k];
              obj[item.keyname] = item.value;
            }
            data.push(obj);
          }
        }
        return data;
      },
      obtenerInstalacion() {
        let instalacion = null;

        this.sites.instalaciones.forEach((inst) => {
          let rgseaa = inst.nombre.split("-", 1)[0];
          instalacion = this.datos.cliente.instalaciones.find(
            (x) => x.rgseaa == rgseaa
          );
        });
        return instalacion;
      },
      allSiteData() {
        return this.getSiteData(this.datos.auditoria.sites);
      },

      getSiteDataAggroupedBy(siteData, key) {
        return this.getataAggroupedBy(this.getSiteData(siteData), key);
      },
      getataAggroupedBy(data, key) {
        var agroupedData = [];
        data.forEach((site) => {
          if (site[key] === undefined) {
            return;
          }

          var elementExists = agroupedData.find((x) => x.key == site[key]);

          if (elementExists == null) {
            agroupedData.push({
              key: site[key],
              values: []
            });
          }

          agroupedData.find((x) => x.key == site[key]).values.push(site);
        });

        return agroupedData;
      },
      // init () {
      //   if (!this.datos.fecha_decision) {
      //     this.$router.replace({ name: 'dictamenes' })
      //     const alert = {
      //       text: 'El dictamen no tiene fecha de decisión.',
      //       color: 'error'
      //     }
      //     this.$store.commit("notification/show", alert)
      //   }
      // }
    },
    // created () {
    //   this.init()
    // }
  };
</script>
