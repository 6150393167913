<template>
  <span v-if="isNotGroup">
    <icon-status :estado="this.params.value"></icon-status>
  </span>
</template>

<script>
import IconStatus from "~/components/IconStatusAuditoria"

export default {
  components: {
    IconStatus
  },
  computed: {
    isNotGroup () {
      return !!this.params.data
    },
  }
}
</script>
