/**
 * @project: certiapp-nuxt
 * @file:    \store\plantillas-checklist.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Wed Jun 21 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  // Checklist
  async list({ rootState, commit }, item) {
    rootState.loading = true
    let itemsPerPage = 100
    let url = 'categoria_preguntas?page=1&itemsPerPage=' + itemsPerPage
    if (item.alcance_uuid) {
      url += '&alcance.uuid=' + item.alcance_uuid
    }
    if (item.subalcance_uuid) {
      url += '&subalcance.uuid=' + item.subalcance_uuid
    }
    const res = await this.$axios.get(url)
    let listResults = []
    listResults = res.data['hydra:member']
    rootState.loading = false
    if (res) {
      commit('SET_LIST', listResults)
      return listResults
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`categoria_preguntas/${uuid}`)
    // console.log(res)
    rootState.loading = false
    if (res) return res.data
  },

  // Preguntas
  async createCategoria({ rootState }, categoria) {
    // rootState.loading = true
    categoria.uuid = uuid.v4()
    await this.$axios.post(`categoria_preguntas`, categoria)
    // rootState.loading = false
  },

  async updateCategoria({ rootState }, categoria) {
    try {
      await this.$axios.patch(`categoria_preguntas/${categoria.uuid}`, categoria, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
    } catch (error) {
      console.error(`There was a problem with the axios operation: ${error.message}`)
    }
  },

  async updateOpcionCategoria({ rootState }, opcion) {
    try {
      await this.$axios.patch(`opcion_categorias/${opcion.uuid}`, opcion, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
    } catch (error) {
      console.error(`There was a problem with the axios operation: ${error.message}`)
    }
  },

  // Crear Opciones Respuesta
  async createOpcionCategoria({ rootState }, categoria) {
    // rootState.loading = true
    categoria.uuid = uuid.v4()
    await this.$axios.post(`opcion_categorias`, categoria)
    // rootState.loading = false
  },

  async deleteOpcionCategoria({ rootState }, opcion) {
    await this.$axios.delete(`opcion_categorias/${opcion.uuid}`)
  },

  async deleteCategoria({ rootState }, categoria) {
    await this.$axios.delete(`categoria_preguntas/${categoria.uuid}`)
  },

  async getCategoriasForSubalcance({ rootState }, subalcance_uuid) {
    const res = await this.$axios.get(
      'categoria_preguntas?page=1&itemsPerPage=100&subalcance.uuid=' + subalcance_uuid
    )
    if (res) return res.data['hydra:member']
  },
}
