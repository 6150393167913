/**
 * @project: certiapp-nuxt
 * @file:    \static\traducciones\es.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 10th July 2023
 * Last Modified: Fri Jul 28 2023
 **/

export default {
  global: {
    'one': 'two',
    'three': 'four',
    'caramba': 'carambita',
    'Texto en grupo GLOBAL': '✅ Text in GLOBAL group',
  },

  certiapp: {
    labelA: '✅ Label A',
    labelB: '✅ Label B',
  },

  ccl: {
    labelA: '✅ xxx A',
  },

  personal: {
    'key-a': 'value-a',
    'key-b': 'value-b',

    'Contacto se llama {nombre}': '✅ contact name is {nombre}',
    'Texto con traducción': '✅ Text with translation',
    'Texto con traduccion sin acentos': '✅ Text with translation without accents',
    'Respuesta con HTML': '✅ Response with <strong>HTML</strong>',
  },

  auditorias: {
    'Texto en grupo Auditorias': '✅ Text in Audits group',
  },
}
