/**
 * @project: certiapp-nuxt
 * @file:    store/contactos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 12th May 2021
 * Last Modified: Thu Jul 13 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    let res =null
    let url = `/contactos?paginate&page=${params.page}&itemsPerPage=${params.perPage}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`
    if(params.search){
      params.nombre = params.search.toLowerCase()

      url = url + `&nombre=${params.nombre}`
    }
    res= await this.$axios.get(url)
    rootState.loading = false
    if (res) return res
  },

  //+-------------------------------------------------
  // usuarios()
  // Created on Thu Jul 13 2023
  //+-------------------------------------------------
  async usuarios({ rootState }, params) {
    rootState.loading = true

   let res =null
    let url = `/usuarios?paginate&page=${params.page}&itemsPerPage=${params.perPage}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`
    if(params.search){
      params.nombre = params.search.toLowerCase()

      url = url + `&nombre=${params.nombre}`
    }
    res= await this.$axios.get(url)
    rootState.loading = false
    if (res) return res
  },

  //+-------------------------------------------------
  // listPersonalInterno()
  // Created on Fri Jun 25 2021
  //+-------------------------------------------------

  async listPersonalInterno({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`usuarios?tipo=personal_interno`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  //+-------------------------------------------------
  // get()
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`contactos/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // -----
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let body = { ...params }
    body.uuid = body.uuid || uuid.v4()
    body.tipo = body.tipo || 'potencial'
    //body.direcciones = [body.direccion]
    await this.$axios.post(`contactos`, body)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // Does not update the file, only the data
  // -----
  // Created on Fri May 21 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let body = {
      contacto: params.uuid,
      tipo: params.tipo,
      tipoContacto: params.tipoContacto,
      formaJuridica: params.forma_juridica,
      email: params.email,
      emails_otros: params.emails_otros,
      nombre: params.nombre,
      appellido1: params.primer_apellido,
      apellido2: params.segundo_apellido,
      telefono: params.telefono,
      telefonos_otros: params.telefonos_otros,
      //direcciones: [params.direccion],
      cp: params.cp,
      nif: params.nif,
      cargo: params.cargo,
      nombreEmpresa: params.nombreEmpresa,
      intereses: params.intereses,
      observaciones: params.observaciones,
      descripcion: params.descripcion,
      trabajadorPropio: params.trabajadorPropio || false,
      seguimientoAcciones: params.seguimiento_acciones || [],
    }

    if (params.pais) body.pais = params.pais.uuid
    if (params.provincia) body.provincia = params.provincia.uuid
    if (params.municipio) body.municipio = params.municipio.uuid
    if (params.localidad) body.localidad = params.localidad.uuid
    if (params.comunidad) body.comunidad = params.comunidad.uuid

    await this.$axios.patch(`contactos/${params.uuid}`, body,{
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // convert()
  // "contacto":  ->string
  // "tipo":  ->string
  // "usuario":  ->string
  // "password":  ->string
  // "email": -> tipo email
  // -----
  // Created on Thu May 13 2021
  //+-------------------------------------------------
  async convert({ rootState }, params) {
    rootState.loading = true

    let body = { ...params }
    body.username = body.email
    body.plainPassword = body.password
    await this.$axios.patch(`contactos/${params.contacto}/convert`, body,{
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // changeRoles()
  // Update contact roles
  // -----
  // Created on Tue Dec 27 2022
  //+-------------------------------------------------
  async changeRoles({ rootState }, params) {
    rootState.loading = true
    let body = {
      contacto: params.uuid,
      roles: params.roles,
    }
    await this.$axios.patch(`contactos/${params.uuid}/change-roles`, body)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`contactos/${item.uuid}`)
    rootState.loading = false
  },
}
