<template>
  <v-checkbox v-if="isNotGroup" color="primary" v-model="checkbox" @change="setCheck()" style="margin: 0px; margin-top: 5px;"></v-checkbox>  
</template>
 
<script>

export default {
  mounted (){
    if (this.params && this.params.data && this.params.data.informe) this.checkbox = true
  },
  data: () => ({
    checkbox: false
  }),
  computed: {
    isNotGroup () {
      return !!this.params.data
    },
  },
	methods: {   
    setCheck(){      
      this.params.data.edited = true
      this.params.data.informe = this.checkbox
      this.params.api.refreshCells()
    }
	}  
}
</script>
