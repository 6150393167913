<template>
  <div>
    <h3 style="text-align: center; color: #a8a7a7; font-size: 18px;">PRODUCCIÓN ECOLÓGICA</h3>
    <p style="text-align: center; color:#432e93; font-family: 'Material Design Icons'; font-size: 25px;">
      <strong>INFORME DE AUDITOR&Iacute;A DE CERTIFICACI&Oacute;N</strong>
    </p>

    <center>
      <table style=" height: 192px; width: 100%; font-family: 'Material Design Icons'; border: 1px solid white !important; border-bottom: 1px solid black; font-size:18px;" width="100%" cellspacing="0" cellpadding="5">
        <tbody>
          <tr valign="top">
            <td style="width: 50%; padding:15px; border-right:1px solid white; padding-bottom: 0px;" width="321" height="65">
              <p class="western"><span style="color: #5f497a;"><span style="font-family: Arial Narrow, serif;"><strong>Operador:</strong> {{datos.cliente.contacto.nombre}}</span></span></p>
              <span v-if="datos.cliente">
              {{ datos.cliente.contacto.direccion }}<br/>
              {{datos.cliente.contacto.cp}} {{datos.cliente.contacto.municipio.nombre}},{{datos.cliente.contacto.provincia.nombre}}<br/>
            </span>
              <p class="western"><span style="font-family: Arial Narrow, serif;"><strong>N&ordm; operador: {{ datos.cliente.codigo }}</strong></span></p>
              <!--<p>
               <em>
                <span style="font-weight: 400;">{{ datos.cliente.direccion }}</span>
                <br>
                <span v-if="datos.cliente.comunidad">{{ datos.cliente.comunidad.nombre }}</span>
                <span v-if="datos.cliente.provincia">{{ ', ' + datos.cliente.provincia.nombre }}</span>
                <span v-if="datos.cliente.municipio">{{ ', ' + datos.cliente.municipio.nombre }}</span>
                <span v-if="datos.cliente.cp"><br>{{ datos.cliente.cp }}</span>
              </em>
            </p>-->
            </td>
            <td style="width: 50%;  padding:15px; padding-bottom: 0px;" width="349">
              <p class="western"><span style="color: #5f497a;"><span style="font-family: Arial Narrow, serif;"><strong>CCL Certificaci&oacute;n, S.L.</strong></span></span></p>
              <p class="western"><span style="font-family: Arial Narrow, serif;">C/ Tierras de Medina, 1 1&ordm;A</span></p>
              <p class="western"><span style="font-family: Arial Narrow, serif;">C.P. 47008 Valladolid</span></p>
              <p class="western"><span style="font-family: Arial Narrow, serif;">T: 983 135 660 </span><span style="color: #0000ff;"><u><a href="http://www.cclcertificacion.es/"><span style="font-family: Arial Narrow, serif;">www.cclcertificacion.es</span></a></u></span><span style="font-family: Arial Narrow, serif;"> </span></p>


            </td>
          </tr>
        </tbody>
      </table>
    </center>

    <p>
      <br />
      <br />
    </p>
    <table style="width: 100%; border:1px solid black; font-family: 'Material Design Icons'; ">
      <tbody>
        <tr style="background-color: #e5e0ec; border-bottom:1px solid black;">
          <td style="border-right:1px solid black; color:#432e93; text-align:center; ">
            <p>
              <strong>ACTIVIDAD OBJETO DE VISITA</strong>
            </p>
          </td>
          <td style="border-right:1px solid black; color:#432e93; text-align:center;">
            <p>
              <strong>ALCANCE OBJETO DE VISITA</strong>
            </p>
          </td>
          <td style="color:#432e93; text-align:center;">
            <p>
              <strong>TIPO DE VISITA</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td style="border-right:1px solid black; ">
            <p>
              <span v-if="datos.expediente.subalcance" style="font-weight: 400;">{{ datos.expediente.subalcance.nombre }}</span>
            </p>
          </td>
          <td style="border-right:1px solid black;">
            <span v-if="datos.solicitud.params" style="font-weight: 400;">{{ datos.solicitud.params.alcance_certificacion.map(e => TIPO_CERTIFICACION[e]) .join(', ') }}</span>
          </td>
          <td>
            <span style="font-weight: 400;">{{ datos.tipo_visita }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="width: 100%; border:1px solid black; font-family: 'Material Design Icons'; ">
      <tbody>
        <tr style="border-bottom:1px solid black;">
          <td style="border-right:1px solid black; background-color: #e5e0ec; color:#432e93; text-align:center;">
            <p>
              <strong>FECHA AUDITORIA</strong>
            </p>
          </td>
          <td style="border-right:1px solid black; border-bottom:1px solid black;">
            <p>
              <strong>{{ formatDate(datos.desde) }}</strong>
            </p>
          </td>
          <td style="border-right:1px solid black; background-color: #e5e0ec; color:#432e93; text-align:center;">
            <p>
              <strong>FECHA DE INFORME</strong>
              <strong>&nbsp;</strong>
            </p>
          </td>
          <td style="border-bottom:1px solid black; border-right: 1px solid black;">
            <p>
              <strong>{{ formatDate(new Date()) }}</strong>
            </p>
          </td>
        </tr>
        <tr style="border-bottom:1px solid black;">
          <td style="border-right:1px solid black;  background-color: #e5e0ec; color:#432e93; text-align:center;">
            <p>
              <strong>DICTAMEN DE AUDITORIA*</strong>
            </p>
          </td>
          <td colspan="5" >
            <p>
              <span style="font-weight: 400;" v-if="datos.no_conformidades && datos.no_conformidades.length === 0 && datos.revisiones && datos.revisiones.length === 0"> &nbsp; &nbsp; &nbsp; SATISFACTORIO</span>
              <span style="font-weight: 400;" v-else-if="datos.revisiones && datos.revisiones.length>0 && datos.revisiones[0].estado === 'KO'"> &nbsp; &nbsp; &nbsp; NO SATISFACTORIO (ver Anexos No Conformidad)</span>
              <span style="font-weight: 400;" v-else-if="datos.revisiones && datos.revisiones.length>0 && datos.revisiones[0].estado === 'OK'"> &nbsp; &nbsp; &nbsp; SATISFACTORIO</span>
            </p>
          </td>
        </tr>
       <tr style="border-bottom:1px solid black;">
          <td style="border-right:1px solid black; background-color: #d9d9d9;">
            <p>
              <span style="font-weight: 400;">N&ordm; Observaciones</span>
            </p>
          </td>
          <td style="border-right:1px solid black;">
            <p>
              <span style="font-weight: 400;"> {{ datos.no_conformidades.filter(e => e.observaciones).length }}</span>
            </p>
          </td>

          <td colspan="3" style="border-right:1px solid black;">
            <p>
              <span style="font-weight: 400;">Ver Anexo Observaciones</span>
            </p>
          </td>
        </tr>
        <tr style="border-bottom:1px solid black;">
          <td style="border-right:1px solid black; background-color: #d9d9d9;">
            <p>
              <span style="font-weight: 400;">N&ordm; No Conformidades&nbsp;</span>
            </p>
          </td>
          <td style="border-right:1px solid black;">
            <p>
              <span style="font-weight: 400;">{{ datos.no_conformidades.length }}</span>
            </p>
          </td>
          <td colspan="3" style="border-right:1px solid black;">
            <p>
              <span style="font-weight: 400;">Ver Anexo No Conformidades</span>
            </p>
          </td>
        </tr>
        <tr style=" background-color: #e5e0ec; border-bottom:1px solid black;">
          <td style="border-right:1px solid black; color:#432e93; text-align:center;">
            <p>
              <strong>Representante del operador</strong>
            </p>
          </td>
          <td style="border-right:1px solid black; color:#432e93; text-align:center;">
            <p>
              <strong>Datos contacto</strong>
            </p>
          </td>
          <td style="border-right:1px solid black; color:#432e93; text-align:center;">
            <p>
              <strong>Auditor CCL**</strong>
            </p>
          </td>
          <td style="border-right:1px solid black; color:#432e93; text-align:center;">
            <p>
              <strong>Datos contacto</strong>
            </p>
          </td>
        </tr>
        <tr style="border-bottom:1px solid black;">
          <td style="border-right:1px solid black;">
            <p>
              <span style="font-weight: 400;" v-if="datos.cliente.tecnicos.length > 0">{{datos.cliente.tecnicos[0].nombre}} {{datos.cliente.tecnicos[0].apellido1}} {{datos.cliente.tecnicos[0].apellido2}}</span>
            </p>
          </td>
          <td style="border-right:1px solid black;">
            <p>
              <span style="font-weight: 400;" v-if="datos.cliente.tecnicos.length > 0">{{datos.cliente.tecnicos[0].telefono}} / {{datos.cliente.tecnicos[0].email}}</span>
            </p>
          </td>
          <td style="border-right:1px solid black;">
            <p>
              <span style="font-weight: 400;">{{ nombreCompleto(datos.responsable) }}</span>
            </p>
          </td>
          <td style="border-right:1px solid black;">
            {{ datos.responsable.email }} / {{ datos.responsable.telefono }}
          </td>
        </tr>
        <tr >
          <td colspan="6" >
            <p>
              <strong>Observaciones:</strong>
            </p>
            <p>
              <span style="font-weight: 400;">{{ datos.anotaciones }}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <em>
        <span style="font-weight: 400; text-align:justify;">*El proceso de evaluaci&oacute;n se somete a revisi&oacute;n t&eacute;cnica siendo tomada la decisi&oacute;n de certificaci&oacute;n por un Comit&eacute; independiente al equipo auditor (UNE-EN ISO/IEC 17065). No obstante se informa al auditado que tiene derecho a formular quedas y apelaciones acerca del proceso de certificaci&oacute;n. **El auditor declara que no tiene conflicto de inter&eacute;s con el auditado en cuesti&oacute;n y que la auditor&iacute;a se ha realizado de un modo imparcial e independiente.&nbsp;</span>
      </em>
    </p>

    <template v-for="(no_conformidad, idx) in datos.no_conformidades">
      <div class="page-break-before" :key="'a' + idx"></div>

      <div :key="'b' + idx">
        <h3 style="font-family: 'Material Design Icons'; text-align:center; color:#432e93; font-size:25px; ">NO CONFORMIDAD MENOR/MAYOR N°</h3>

        <table class="Normal tm5 tm6" style="width: 100%; border:1px solid black; " border="0" rules="all">
          <tbody class="tm7">
            <tr style="border-bottom:1px solid black;">
              <td class="tm8 tm9 tm10" style="width: 100%;" colspan="2">
                <p class="Normal"><span class="tm11">Requisito afectado: </span></p>
                <p>No Conformidad {{ no_conformidad.peso }} - {{ no_conformidad.codigo }}: {{ no_conformidad.titulo }}</p>
              </td>
            </tr>
            <tr style="border-bottom:1px solid black;">
              <td class="tm8 tm9 tm10" style="width: 100%;" colspan="2">
                <p class="Normal"><span class="tm11">Desarrollo de la No Conformidad</span></p>
                <div v-html="no_conformidad.no_conformidad"></div>
              </td>
            </tr>
            <tr style="border-bottom:1px solid black;">
              <td class="tm8 tm9 tm10" style="width:100%;" colspan="2">
                <p class="Normal"><span class="tm11">Plazo de resoluci&oacute;n: 15 d&iacute;as naturales</span></p>
              </td>
            </tr>
            <tr style="border-bottom:1px solid black;">
              <td class="tm8 tm9 tm15" style="width:50%; border-right:1px solid black;">
                <p class="Normal"><u><span class="tm11">Firma auditado: </span></u></p>
                <p class="Normal"><u><span class="tm11">&nbsp;</span></u></p>
                <p class="Normal"><u><span class="tm11">&nbsp;</span></u></p>
                <p class="Normal"><u><span class="tm11">&nbsp;</span></u></p>
              </td>
              <td class="tm8 tm9 tm16" style="width: 50%;">
                <p class="Normal"><u><span class="tm11">Firma auditor CCL:</span></u></p>
                <p class="Normal"><u><span class="tm11">&nbsp;</span></u></p>
                <p class="Normal"><u><span class="tm11">&nbsp;</span></u></p>
                <p class="Normal"><u><span class="tm11">&nbsp;</span></u></p>
              </td>
            </tr>
          </tbody>
        </table>

        <p class="Normal">&nbsp;</p>
      </div>

<!--      <div :key="'c' + idx">-->
<!--        <h3 style="font-family: 'Material Design Icons'; text-align:center; color:#432e93; font-size:25px; ">PLAN DE ACCIONES CORRECTIVAS (a cumplimentar por el operador)</h3>-->

<!--        <table class="Normal tm5 tm6" border="0" rules="all" style="border:1px solid black; font-family: 'Material Design Icons';" width="100%">-->
<!--          <tbody class="tm7">-->
<!--            <tr style="border-bottom:1px solid black; ">-->
<!--              <td class="tm8 tm9 tm10" colspan="2">-->
<!--                <p class="Normal"><u><span class="tm12">An&aacute;lisis de la causa</span></u><span class="tm12">:</span></p>-->
<!--                <p>{{ no_conformidad.accion_correctiva.causa }}</p>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr style="border-bottom:1px solid black; ">-->
<!--              <td class="tm8 tm9 tm10" colspan="2">-->
<!--                <p class="Normal"><u><span class="tm12">Acci&oacute;n Reparadora*</span><span class="tm11">:</span></u> <span class="tm14">(subsana el efecto de la no conformidad detectada)</span></p>-->
<!--                <p>{{ no_conformidad.accion_correctiva.accion_propuesta }}</p>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr style="border-bottom:1px solid black; ">-->
<!--              <td class="tm8 tm9 tm10" colspan="2">-->
<!--                <p class="Normal"><u><span class="tm12">Acci&oacute;n Correctiva</span></u><span class="tm12">*: </span><span class="tm16">(evita que la no conformidad se repita en el tiempo)</span></p>-->
<!--                <p>{{ no_conformidad.accion_correctiva.evidencias_aportadas }}</p>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr style="border-bottom:1px solid black; ">-->
<!--              <td class="tm8 tm9 tm10" colspan="2">-->
<!--                <p class="Normal"><u><span class="tm12">Fecha implantaci&oacute;n acciones correctivas</span></u><span class="tm12">:</span></p>-->
<!--                <p>{{ formatDate(no_conformidad.accion_correctiva.fecha_implantacion) }}</p>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr style="border-bottom:1px solid black; ">-->
<!--              <td class="tm8 tm9 tm19" style="border-right:1px solid black;" width="50%">-->
<!--                <p class="Normal"><u><span class="tm12">Responsable Implantaci&oacute;n:</span></u></p>-->
<!--                <p>{{ no_conformidad.accion_correctiva.responsable_implantacion }}</p>-->
<!--              </td>-->
<!--              <td class="tm8 tm9 tm19" width="50%">-->
<!--                <p class="Normal"><u><span class="tm12">Firma responsable:</span></u></p>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--        <p class="Normal">&nbsp;</p>-->
<!--      </div>-->

      <div class="page-break-before" :key="'d' + idx"></div>

      <div :key="'e' + idx">
        <table class="Normal tm5 tm6 tm7" border="0" rules="all" style="border:1px solid black; " width="100%">
          <tbody class="tm8">
            <tr style="border-bottom:1px solid black; ">
              <td class="tm9 tm10 tm11" style="background-color:#ccc; border-right:1px solid black; color:#432e93; text-align:center;" width="50%">
                <p class="Normal"><strong><span class="tm12">N&ordm; OBSERVACI&Oacute;N*</span></strong></p>
              </td>
              <td class="tm9 tm13 tm14" width="50%">
                <p class="Normal"><span class="tm15"></span></p>
              </td>
            </tr>
            <tr style="border-bottom:1px solid black; ">
              <td class="tm9 tm10 tm11"  style="background-color:#ccc; border-right:1px solid black; color:#432e93; text-align:center;"  width="50%">
                <p class="Normal"><strong><span class="tm12">REQUISITOS NORMATIVO</span></strong></p>
              </td>
              <td class="tm9 tm10 tm14"  style="background-color:#ccc; color:#432e93; text-align:center;"  width="50%">
                <p class="Normal"><strong><span class="tm12">DESARROLLO DE LA OBSERVACI&Oacute;N</span></strong></p>
              </td>
            </tr>
            <tr style="border-bottom:1px solid black; ">
              <td class="tm9 tm18 tm11"  width="50%" style="border-right:1px solid black;">
                <p class="Normal"><span class="tm19">&nbsp;</span></p>
                <p class="Normal">
                  <span class="tm19">
                    <p></p>
                  </span>
                </p>
                <p class="Normal"><span class="tm19">&nbsp;</span></p>
              </td>
              <td class="tm9 tm18 tm11"  width="50%" style="border-right:1px solid black;">
                <p class="Normal"><span class="tm19">&nbsp;</span></p>
                <p class="Normal">
                  <span class="tm19">
                    <p></p>
                  </span>
                </p>
                <p class="Normal"><span class="tm19">&nbsp;</span></p>
              </td>
            </tr>
            <tr style="border-bottom:1px solid black; ">
              <td class="tm9 tm13 tm11"  width="50%" style="border-right:1px solid black;">
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">Firma Auditado</span></u></strong></p>
              </td>
              <td class="tm9 tm13 tm14"  width="50%">
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><strong><u><span class="tm23">&nbsp;</span></u></strong></p>
                <p class="Normal"><u><strong><span class="tm23">Firma Auditor</span></strong></u></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="Normal">&nbsp;</p>

        <p class="Normal tm5" style="font-family: 'Material Design Icons'; text-align:justify;"><span class="tm6">*</span><span class="tm6">Las observaciones describen hallazgos que son</span><span class="tm6"> encontrados en el proceso de evaluaci&oacute;n, y que a juicio del equipo auditor, pueden ser considerados como potenciales no conformidades en el futuro, o situaciones que tengan un evidente potencial de mejora, aun cumpliendo con los requisitos normativos.</span></p>
      </div>
    </template>
  </div>
</template>


<style scoped>
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 0.5px solid black;
  }
  thead {
    display: table-row-group;
  }
  tr td {
    page-break-inside: avoid;
  }
  .no-border {
    border: none !important;
  }
</style>

<script>
import MODELS from "~/models/ccl/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    TIPO_CERTIFICACION: MODELS.CERTIFICACION_TIPO,
    TIPO_OPERADORES: MODELS.OPERADORES_TIPO,
  }),
  computed: {
    responsableNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.responsable_nombre) nombre += cliente.responsable_nombre
        if (cliente.responsable_primer_apellido) nombre += ' ' + cliente.responsable_primer_apellido
        if (cliente.responsable_segundo_apellido) nombre += ' ' + cliente.responsable_segundo_apellido
      }
      return nombre
    },
  },
  created() {
    console.info('datos', this.datos);
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    nombreCompleto(datos) {
      let nombre = ''
      if (datos) {
        if (datos.nombre) nombre += datos.nombre
        if (datos.primer_apellido) nombre += ' ' + datos.primer_apellido
        if (datos.segundo_apellido) nombre += ' ' + datos.segundo_apellido
        if (datos.apellido1) nombre += ' ' + datos.apellido1
        if (datos.apellido2) nombre += ' ' + datos.apellido2
        if (datos.apellidos) nombre += ' ' + datos.apellidos
      }
      return nombre
    },
  }
}
</script>
