export const state = () => ({
  list: [],
})

function getParameterCaseInsensitive(object, key) {
  return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())]
}

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }) {
    rootState.loading = true
    const res = await this.$axios.get(`noticias`)
    rootState.loading = false
    if (res && res.data) {
      commit('SET_LIST', res.data)
      return res.data['hydra:member']
    }
  },

  async get({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.get(`noticias/${params.noticia_uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, params) {
    rootState.loading = true

    const formData = new FormData()
    formData.append('titulo', params.titulo)
    formData.append(
      'subalcance',
      params.subalcance ? '/api/subalcances/' + params.subalcance.uuid : ''
    )
    formData.append('body', params.body)
    formData.append('color', params.color)
    formData.append('notify', params.notify)
    //formData.append('notifyUsers', params.notifyUsers)
    if (params.categorias) {
      for (let i = 0; i < params.categorias.length; i++) {
        formData.append('categorias[]', params.categorias[i])
      }
    }
    if (params.attachments) {
      for (let i = 0; i < params.attachments.length; i++) {
        formData.append('attachmentsToProcess[]', params.attachments[i])
      }
    }

    if (params.notifyUsers) {
      for (let i = 0; i < params.notifyUsers.length; i++) {
        formData.append('notifyUsers[]', params.notifyUsers[i]['@id'])
      }
    }

    const res = await this.$axios.post('noticias', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    rootState.loading = false
    if (res) return res.data
  },

  async update({ rootState }, noticia) {
    rootState.loading = true
    noticia.subalcance = noticia.subalcance ? '/api/subalcances/' + noticia.subalcance.uuid : null
    if (noticia.notifyUsers) {
      for (let i = 0; i < noticia.notifyUsers.length; i++) {
        noticia.notifyUsers[i] = '/api/usuarios/' + noticia.notifyUsers[i].uuid
      }
    }
    if(noticia.attachments){
      noticia.attachmentsToProcess = []
      for (let i = 0; i < noticia.attachments.length; i++) {
        noticia.attachmentsToProcess[i] = noticia.attachments[i]
      }
    }
    
    const res = await this.$axios.patch(`noticias/${noticia.uuid}`, noticia, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
    if (res) return res.data
  },

  async addUserToFav({ rootState }, params) {
    const body = {
      uuid: params.uuid,
    }
    rootState.loading = true
    const res = await this.$axios.patch(`noticias/${params.uuid}/userFav`, body,{
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
    if (res) return res.data
  },

  async delete({ rootState }, noticia) {
    rootState.loading = true
    await this.$axios.delete(`noticias/${noticia.uuid}`)
    rootState.loading = false
  },

  async downloadAttachment({ rootState }, params) {
    rootState.loading = true
    for (let i = 0; i < params.length; i++) {
      const res = await this.$axios.get(`/public/attachments/${params[i].uuid}?token=989fe37c51b782e39d7efe249bb2f323`, {
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data], {type: response.data.type})
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const contentDisposition = getParameterCaseInsensitive(
          response.headers,
          'Content-Disposition'
        )
        let fileName = params[i].originalName || params[i].nombre
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => {
        throw e
      })
      .finally(() => {})
      rootState.loading = false
      if (res) return res.data
    }
  }
}
