<template>
  <div>
    <v-icon :style="iconStyle">{{ icon }}</v-icon>
  </div>
</template>

<script>
export default {
  computed: {
    isGrouped() {
      return this.params.colDef && this.params.colDef.headerName === 'Group';
    },
    value() {
      return this.isGrouped ? this.params.node.groupData['ag-Grid-AutoColumn'] : this.params.value;
    },
    icon() {
      return this.value ? 'mdi-check-circle' : 'mdi-close-circle';
    },
    iconStyle() {
      return {
        color: this.value ? 'green' : 'red',
        fontSize: '24px', // Tamaño del icono
      };
    },
  },
  created() {
    // Asegúrate de que this.params está correctamente definido
    console.info(this.params);
  },
}
</script>
