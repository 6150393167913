

import { uuid } from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}

//dispatch actions
export const actions = {
  
  async list({ rootState, commit }, params) {
    rootState.loading = true
    let res =null
    let url = `/linea_plan_auditorias?page=${params.page}&itemsPerPage=${params.perPage}`
    if(params.search){
      params.nombre = params.search.toLowerCase()

      url = url + `&texto=${params.nombre}`
    }
    res= await this.$axios.get(url)
    rootState.loading = false
    if (res){ 
      commit('SET_LIST', res.data['hydra:member'])
      return res
    }
   
  },

  async get_planes({ rootState, commit }, params) {
    rootState.loading = true
    let res =null
    let url = `/linea_plan_auditorias?page=1&itemsPerPage=100`
    if(params.search){
      params.nombre = params.search.toLowerCase()

      url = url + `&texto=${params.nombre}`
    }
    res= await this.$axios.get(url)
    rootState.loading = false
    if (res){ 
      return res.data['hydra:member']
    }
  },

  async get({ rootState, commit }, uuid) {
    rootState.loading = true
    
      const res = await this.$axios.get(`linea_plan_auditorias/${uuid}`)

      rootState.loading = false
      if (res) {
        return res.data
      }
    
  },

  async create({ rootState }, params) {
    rootState.loading = true
    let data = { ...params }
    data.fecha = data.esFecha
    delete data.esFecha
    const res = await this.$axios.post('linea_plan_auditorias', data)
    rootState.loading = false
    return res
  },

  async update({ rootState, commit }, params) {
      console.log(params)
      const body = params
      body.fecha = params.esFecha
      delete body.esFecha
      const res = await this.$axios.patch(`linea_plan_auditorias/${params.uuid}`, body,{
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
        
      })
      return res
    
  },

  async delete({ rootState }, auditoria) {
    rootState.loading = true
    const res = await this.$axios.delete(`linea_plan_auditorias/${auditoria.uuid}`)
    rootState.loading = false
    return res
  },

  ///grupos
  async list_grupos({ rootState, commit }, params) {
    rootState.loading = true
    let res =null
    let url = `/grupo_lineas?page=${params.page}&itemsPerPage=${params.perPage}`
    if(params.search){
      params.nombre = params.search.toLowerCase()

      url = url + `&nombre=${params.nombre}`
    }
    res= await this.$axios.get(url)
    rootState.loading = false
    if (res){ 
      return res
    }
  },

  async get_grupos({ rootState, commit }, params) {
    rootState.loading = true
    let res =null
    let url = `/grupo_lineas?page=1&itemsPerPage=100`
    if(params.search){
      params.nombre = params.search.toLowerCase()

      url = url + `&nombre=${params.nombre}`
    }
    res= await this.$axios.get(url)
    rootState.loading = false
    if (res){ 
      return res.data['hydra:member']
    }
  },

  async get_grupo({ rootState, commit }, uuid) {
    rootState.loading = true
    
      const res = await this.$axios.get(`grupo_lineas/${uuid}`)

      rootState.loading = false
      if (res) {
        return res.data
      }
  },

  async create_grupo({ rootState }, params) {
    rootState.loading = true
    let data = { ...params }
    await this.$axios.post('grupo_lineas', data)
    rootState.loading = false
  },

  async update_grupo({ rootState, commit }, params) {
    
      const body = params

      await this.$axios.put(`grupo_lineas/${params.uuid}`, body,{
        // headers: {
        //   'Content-Type': 'application/merge-patch+json'
        // }
      })
  },

  async delete_grupo({ rootState }, grupo) {
    rootState.loading = true
    const res = await this.$axios.delete(`grupo_lineas/${grupo.uuid}`)
    rootState.loading = false
    return res
  },
  
}
