<template>
  <div>
    <!--    DIBUJAR TABLA-->
    <p>
      <b>Centros de manipulación del producto</b>
    </p>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">PRODUCTOS</th>
            <th class="text-center">GGN</th>
            <th class="text-center">NOMBRE Y DIRECCIÓN DE LA PHU</th>
            <th class="text-center">PROPIEDAD PARALELA</th>
          </tr>
        </thead>
        <tbody v-if="centrales">       
          <tr v-for="(item, index) in centrales" :key="index">
            <td class="text-center">
              <div v-for="(cultivo, index) in item.cultivos_solicitados" :key="index">
                {{cultivo}}<span v-if="index !== (item.cultivos_solicitados.length - 1)">,</span>
              </div>              
            </td>
            <td class="text-center">{{item.ggn_productor_certificado}}</td>
            <td class="text-center">{{getDatosCentral(item)}}</td>
            <td class="text-center">
              <span v-if="item.propiedad_paralela && item.propiedad_paralela !== ''">YES</span>
              <span v-else>NO</span>                  
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>    

    <loading v-model="loading" text="Generando el certificado..."/>

  </div>
</template>

<script>
import Loading from '~/components/Loading'
export default {
  name: "agrocolor-certificados-tabla-centros-manipulacion-producto",
  props: {
    componente: { type: Object, default: {} }
  },
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      certificado: '',
      centrales: []
    };
  },
  watch: {

  },
  async mounted() {    
    this.loading = true
    this.certificado = this.componente.certificado
    this.obtenerFicheros()
  },
  methods: {
    async obtenerFicheros(){
      // console.log('[Componente dinámico]', this.componente)      
      let iteracion = await this.$store.dispatch("expedientes/iteracion", [this.componente.expediente, this.componente.iteracion])   

      // TODO: DESARROLLO QUITAR !!!!!!!!!!!!!!!!!!!!!!!
      let sites = [
        {
            "uuid": "c869413f-4275-4db5-af5a-709e08741de9",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "GENERAL",
            "data": [
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "inicia_ciclo",
                    "value": "1"
                },
                {
                    "keyname": "auditoria_dividida",
                    "value": "0"
                }
            ]
        },
        {
            "uuid": "c93887ed-12c5-4c30-874f-76a8e7069431",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "CULTIVOS",
            "data": [
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "codigo_certificado",
                    "value": ""
                },
                {
                    "keyname": "cultivos_solicitados",
                    "value": "Aceitunas"
                }
            ]
        },
        {
            "uuid": "b919aaba-a04a-480f-94c3-ceddc8b439bb",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "CULTIVOS",
            "data": [
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "undefined",
                    "value": ""
                },
                {
                    "keyname": "codigo_certificado",
                    "value": ""
                },
                {
                    "keyname": "cultivos_solicitados",
                    "value": "Acelga"
                }
            ]
        },
        {
            "uuid": "c0918325-ee21-43fc-a8ad-7aa7698bc68f",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "PRODUCTORES",
            "data": [
                {
                    "keyname": "fax",
                    "value": ""
                },
                {
                    "keyname": "gln",
                    "value": ""
                },
                {
                    "keyname": "pais",
                    "value": "espa\u00f1a"
                },
                {
                    "keyname": "email",
                    "value": ""
                },
                {
                    "keyname": "sr_sra",
                    "value": "SRA"
                },
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "nif_cif",
                    "value": "87687687C"
                },
                {
                    "keyname": "tramite",
                    "value": ""
                },
                {
                    "keyname": "telefono",
                    "value": ""
                },
                {
                    "keyname": "direccion",
                    "value": ""
                },
                {
                    "keyname": "localidad",
                    "value": "alcoy"
                },
                {
                    "keyname": "provincia",
                    "value": "alicante"
                },
                {
                    "keyname": "codigo_postal",
                    "value": ""
                },
                {
                    "keyname": "ggn_globalgap",
                    "value": "1231231231231"
                },
                {
                    "keyname": "recurre_asesor",
                    "value": ""
                },
                {
                    "keyname": "apellidos_productor",
                    "value": ""
                },
                {
                    "keyname": "nombre_farm_assurer",
                    "value": ""
                },
                {
                    "keyname": "nombre_organizacion",
                    "value": "dfsa"
                },
                {
                    "keyname": "nombre_del_productor",
                    "value": ""
                },
                {
                    "keyname": "licensed_farm_assurer",
                    "value": ""
                },
                {
                    "keyname": "privacidad_de_los_datos",
                    "value": ""
                },
                {
                    "keyname": "numero_registro_agrocolor",
                    "value": ""
                },
                {
                    "keyname": "codigos_internos_secundarios",
                    "value": "345"
                },
                {
                    "keyname": "codigo_interno_principal_productor",
                    "value": "12"
                }
            ]
        },
        {
            "uuid": "c1dfc0da-f3d9-49f8-ba65-49d9146ae926",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "PRODUCTORES",
            "data": [
                {
                    "keyname": "fax",
                    "value": ""
                },
                {
                    "keyname": "gln",
                    "value": ""
                },
                {
                    "keyname": "pais",
                    "value": "espa\u00f1a"
                },
                {
                    "keyname": "email",
                    "value": ""
                },
                {
                    "keyname": "sr_sra",
                    "value": "SRA"
                },
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "nif_cif",
                    "value": "87687687C"
                },
                {
                    "keyname": "tramite",
                    "value": ""
                },
                {
                    "keyname": "telefono",
                    "value": ""
                },
                {
                    "keyname": "direccion",
                    "value": ""
                },
                {
                    "keyname": "localidad",
                    "value": "alcoy"
                },
                {
                    "keyname": "provincia",
                    "value": "alicante"
                },
                {
                    "keyname": "codigo_postal",
                    "value": ""
                },
                {
                    "keyname": "ggn_globalgap",
                    "value": "1231231231231"
                },
                {
                    "keyname": "recurre_asesor",
                    "value": ""
                },
                {
                    "keyname": "apellidos_productor",
                    "value": ""
                },
                {
                    "keyname": "nombre_farm_assurer",
                    "value": ""
                },
                {
                    "keyname": "nombre_organizacion",
                    "value": "dfsa"
                },
                {
                    "keyname": "nombre_del_productor",
                    "value": ""
                },
                {
                    "keyname": "licensed_farm_assurer",
                    "value": ""
                },
                {
                    "keyname": "privacidad_de_los_datos",
                    "value": ""
                },
                {
                    "keyname": "numero_registro_agrocolor",
                    "value": ""
                },
                {
                    "keyname": "codigos_internos_secundarios",
                    "value": "345"
                },
                {
                    "keyname": "codigo_interno_principal_productor",
                    "value": "12"
                }
            ]
        },
        {
            "uuid": "323b6e5b-5d9c-4acd-b8b6-17a0f00c7469",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "PRODUCTOS",
            "data": [
                {
                    "keyname": "bio",
                    "value": ""
                },
                {
                    "keyname": "lat",
                    "value": ""
                },
                {
                    "keyname": "lng",
                    "value": ""
                },
                {
                    "keyname": "site",
                    "value": ""
                },
                {
                    "keyname": "sigpac",
                    "value": ""
                },
                {
                    "keyname": "cultivo",
                    "value": "Acelga"
                },
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "nif_cif",
                    "value": "87687687C"
                },
                {
                    "keyname": "recinto",
                    "value": "1"
                },
                {
                    "keyname": "tramite",
                    "value": "Alta"
                },
                {
                    "keyname": "parcelas",
                    "value": "1"
                },
                {
                    "keyname": "poligono",
                    "value": "1"
                },
                {
                    "keyname": "propiedad",
                    "value": ""
                },
                {
                    "keyname": "manipulado",
                    "value": ""
                },
                {
                    "keyname": "superficie",
                    "value": "5"
                },
                {
                    "keyname": "codigo_ucth",
                    "value": "31"
                },
                {
                    "keyname": "sub-recinto",
                    "value": ""
                },
                {
                    "keyname": "autorizaciones",
                    "value": ""
                },
                {
                    "keyname": "subnumeros_gln",
                    "value": ""
                },
                {
                    "keyname": "codigo_iso_pais",
                    "value": ""
                },
                {
                    "keyname": "fuentes_de_agua",
                    "value": ""
                },
                {
                    "keyname": "cultivo_rotacion",
                    "value": ""
                },
                {
                    "keyname": "no_conformidades",
                    "value": ""
                },
                {
                    "keyname": "tecnico_de_campo",
                    "value": ""
                },
                {
                    "keyname": "inspector_interno",
                    "value": ""
                },
                {
                    "keyname": "propiedad_paralela",
                    "value": ""
                },
                {
                    "keyname": "tipo_segundo_ciclo",
                    "value": ""
                },
                {
                    "keyname": "produccion_paralela",
                    "value": ""
                },
                {
                    "keyname": "entidad_certificadora",
                    "value": ""
                },
                {
                    "keyname": "exclusion_recoleccion",
                    "value": ""
                },
                {
                    "keyname": "ampliacion_de_superficie",
                    "value": ""
                },
                {
                    "keyname": "fecha_inspeccion_interna",
                    "value": ""
                },
                {
                    "keyname": "cubiert_aire_libre_prenne",
                    "value": ""
                },
                {
                    "keyname": "actividades_subcontratadas",
                    "value": ""
                },
                {
                    "keyname": "codigo_catastral_localidad",
                    "value": "30"
                },
                {
                    "keyname": "codigo_catastral_provincia",
                    "value": "31"
                },
                {
                    "keyname": "resultado_inspeccion_interna",
                    "value": ""
                },
                {
                    "keyname": "ubicacion_instalaciones_de_agua",
                    "value": ""
                }
            ]
        },
        {
            "uuid": "d63c975f-b7fa-4baf-8d6b-266f9c69f5aa",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "PRODUCTOS",
            "data": [
                {
                    "keyname": "bio",
                    "value": ""
                },
                {
                    "keyname": "lat",
                    "value": ""
                },
                {
                    "keyname": "lng",
                    "value": ""
                },
                {
                    "keyname": "site",
                    "value": ""
                },
                {
                    "keyname": "sigpac",
                    "value": ""
                },
                {
                    "keyname": "cultivo",
                    "value": "Aceitunas"
                },
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "nif_cif",
                    "value": "87687687C"
                },
                {
                    "keyname": "recinto",
                    "value": "1"
                },
                {
                    "keyname": "tramite",
                    "value": "Alta"
                },
                {
                    "keyname": "parcelas",
                    "value": "1"
                },
                {
                    "keyname": "poligono",
                    "value": "1"
                },
                {
                    "keyname": "propiedad",
                    "value": ""
                },
                {
                    "keyname": "undefined",
                    "value": ""
                },
                {
                    "keyname": "manipulado",
                    "value": ""
                },
                {
                    "keyname": "superficie",
                    "value": "3"
                },
                {
                    "keyname": "codigo_ucth",
                    "value": "31"
                },
                {
                    "keyname": "sub-recinto",
                    "value": ""
                },
                {
                    "keyname": "autorizaciones",
                    "value": ""
                },
                {
                    "keyname": "subnumeros_gln",
                    "value": ""
                },
                {
                    "keyname": "codigo_iso_pais",
                    "value": ""
                },
                {
                    "keyname": "fuentes_de_agua",
                    "value": ""
                },
                {
                    "keyname": "cultivo_rotacion",
                    "value": ""
                },
                {
                    "keyname": "no_conformidades",
                    "value": ""
                },
                {
                    "keyname": "tecnico_de_campo",
                    "value": ""
                },
                {
                    "keyname": "inspector_interno",
                    "value": ""
                },
                {
                    "keyname": "propiedad_paralela",
                    "value": ""
                },
                {
                    "keyname": "tipo_segundo_ciclo",
                    "value": ""
                },
                {
                    "keyname": "produccion_paralela",
                    "value": ""
                },
                {
                    "keyname": "entidad_certificadora",
                    "value": ""
                },
                {
                    "keyname": "exclusion_recoleccion",
                    "value": ""
                },
                {
                    "keyname": "ampliacion_de_superficie",
                    "value": ""
                },
                {
                    "keyname": "fecha_inspeccion_interna",
                    "value": ""
                },
                {
                    "keyname": "cubiert_aire_libre_prenne",
                    "value": ""
                },
                {
                    "keyname": "actividades_subcontratadas",
                    "value": ""
                },
                {
                    "keyname": "codigo_catastral_localidad",
                    "value": "30"
                },
                {
                    "keyname": "codigo_catastral_provincia",
                    "value": "31"
                },
                {
                    "keyname": "resultado_inspeccion_interna",
                    "value": ""
                },
                {
                    "keyname": "ubicacion_instalaciones_de_agua",
                    "value": ""
                }
            ]
        },
        {
            "uuid": "495fafae-9c55-4508-9603-855a96ec3d61",
            "edited": false,
            "enabled": false,
            "tipo_fichero": "CENTRALES",
            "data": [
                {
                    "keyname": "fax",
                    "value": ""
                },
                {
                    "keyname": "lat",
                    "value": ""
                },
                {
                    "keyname": "lng",
                    "value": ""
                },
                {
                    "keyname": "ria",
                    "value": ""
                },
                {
                    "keyname": "pais",
                    "value": "España"
                },
                {
                    "keyname": "email",
                    "value": ""
                },
                {
                    "keyname": "nombre",
                    "value": "Central 1"
                },
                {
                    "keyname": "esquema",
                    "value": "Globalgap"
                },
                {
                    "keyname": "tramite",
                    "value": "Alta"
                },
                {
                    "keyname": "telefono",
                    "value": ""
                },
                {
                    "keyname": "direccion",
                    "value": "C/ Demo, 45"
                },
                {
                    "keyname": "localidad",
                    "value": "La Alberca"
                },
                {
                    "keyname": "provincia",
                    "value": "Murcia"
                },
                {
                    "keyname": "codigo_postal",
                    "value": "30170"
                },
                {
                    "keyname": "n_trabajadores",
                    "value": ""
                },
                {
                    "keyname": "propiedad_paralela",
                    "value": "1"
                },
                {
                    "keyname": "registro_sanitario",
                    "value": ""
                },
                {
                    "keyname": "cultivos_solicitados",
                    "value": "Aceitunas"
                },
                {
                    "keyname": "ggn_productor_certificado",
                    "value": "1234567890"
                },
                {
                    "keyname": "tratamientos_post_cosecha",
                    "value": ""
                },
                {
                    "keyname": "certificado_reconocido_gfsi",
                    "value": ""
                },
                {
                    "keyname": "fecha_auditoria_interna_central",
                    "value": ""
                },
                {
                    "keyname": "c_manipulado_incluido_en_el_alcance",
                    "value": ""
                },
                {
                    "keyname": "envasa_producto_comprado_de_otros_productores_certificados",
                    "value": ""
                }
            ]
        }
      ]
      //iteracion.sites = sites
      // _ END QUITAR !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


      for (let index = 0; index < iteracion.sites.length; index++) {
        const site = iteracion.sites[index]
        const data = site.data
        if (site.tipo_fichero === "CENTRALES" && data.length > 0) {
          let central = {
            cultivos_solicitados: [],
            nombre: '',
            direccion: '',
            provincia: '',
            localidad: '',
            codigo_postal: '',
            pais: '',
            ggn_productor_certificado: '',
            propiedad_paralela: 'NO'
          }
          for (let i=0; i < data.length; i++) {
            const element = data[i]
            if (element.keyname === 'ggn_productor_certificado') central.ggn_productor_certificado = element.value
            else if (element.keyname === 'propiedad_paralela') central.propiedad_paralela = element.value
            // TODO: QUE PASA SI HAY CENTRALES REPETIDAS CON MÁS PRODUCTOS
            else if (element.keyname === 'cultivos_solicitados') central.cultivos_solicitados.push(element.value)
            else if (element.keyname === 'nombre') central.nombre = element.value
            else if (element.keyname === 'direccion') central.direccion = element.value
            else if (element.keyname === 'provincia') central.provincia = element.value
            else if (element.keyname === 'localidad') central.localidad = element.value
            else if (element.keyname === 'codigo_postal') central.codigo_postal = element.value
            else if (element.keyname === 'pais') central.pais = element.value
          }
          this.centrales.push(central)
        }
      }
  
      this.loading = false   
    },
    getDatosCentral(central) {
      let datos = ''
      if (central.nombre && central.nombre.length > 0) datos += central.nombre
      if (central.direccion && central.direccion.length > 0) datos += ', '+central.direccion
      if (central.codigo_postal && central.codigo_postal.length > 0) datos += ' '+central.codigo_postal
      if (central.localidad && central.localidad.length > 0) datos += ', '+central.localidad
      if (central.pais && central.pais.length > 0) datos += ' ('+central.pais+')'
      return datos
    }
  }
};
</script>

<style scoped>
  table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  table tr:nth-child(even){background-color: #f2f2f2;}

  table tr:hover {background-color: #ddd;}

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(146, 208, 81);
    color: black !important;
  }
</style>
