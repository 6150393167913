<template>

    <v-checkbox v-if="isNotGroup" color="primary" v-model="checkbox" @change="setCheck" style="margin: 0px; margin-top: 5px;"></v-checkbox>  

</template>
 
<script>

export default {
  components: {
    
  },
  data: function () {
    return {
      checkbox: null
    }
  },  
  mounted(){
    if (this.params && this.params.data && this.params.data.solo_titulo) this.checkbox = true
  },
  computed: {
    isNotGroup () {
      return !!this.params.data
    },
  },
	methods: {   
    setCheck(new_value){      
      this.params.data.edited = true
      this.params.data.solo_titulo = this.checkbox
      if (this.checkbox) {
        this.params.data.hint = ''
        this.params.data.observaciones = ''
        this.params.data.nivel = ''
        this.params.api.refreshCells()
      } else {
        this.params.data.nivel = 'Menor'
        this.params.api.refreshCells()
      }
    }
	}  
}
</script>
