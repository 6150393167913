<template>
  <span v-if="isNotGroup">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn small dark depressed color="info" v-on="on">
          Acciones
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="item in filterActions" :key="item.id" @click="controlActionsModal($event, item.action)">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
export default {
  data: () => ({
    tipo_fichero: '',
    globalgapActions: []
  }),
  computed: {
    isNotGroup () {
      return !!this.params.data
    },
    filterActions () {
      return this.globalgapActions.filter(element => {
        return element.tipo_fichero.includes(this.tipo_fichero)
      })
    }
  },
  methods: {
    controlActionsModal($event, action) {
      if (this.params.controlActionsModal instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
          event: $event,
          action: action
          // ...something
        }
        this.params.controlActionsModal(action);
      }
    }
  },
  created () {
    this.tipo_fichero = this.params.tipo_fichero
    this.globalgapActions = this.params.globalgapActions
  }
}
</script>
