<template>
  <div v-show="ui.show" class="helper-overlay">
    <v-btn @click="ui.show = false">Close</v-btn>
    <pre>{{ table.headers }}</pre>

    <v-card>
      <input type="file" ref="inputFile"
        @change="importFile($event)"/>

      <div class="ag-container">
        <ag-grid-vue
          :key="ui.key"
          style="width: 100%; height: 600px;"
          class="ag-grid ag-theme-material"
          :rowData="items"
          :paginationPageSize="50"
          :defaultColDef="table.defaults"
          :columnDefs="table.headers"
          :pagination="true"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
      </div>
    </v-card>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\ExcelBridge.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 6th May 2022
 * Last Modified: Fri May 13 2022
 **/

import * as UTIL from '~/util'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ExcelBridge',

  data() {
    return {
      ref: null,
      items: [],

      table: {
        api: null,
        columnApi: null,
        excelStyles: [
          {
            id: 'default',
            dataType: 'string',
          },
        ],

        defaults: {
          resizable: false,
        },

        headers: [
          { headerName: 'A', field: 'data.tipo' },
          { headerName: 'B', field: 'data.nombre' },
          { headerName: 'C', field: 'data.primer_apellido' },
        ],
      },

      ui: {
        key: 0,
        show: false,
      },
    }
  },

  watch: {},

  computed: {
    ...mapState(['context']),
    ...mapGetters({
      getState: 'context/getState',
    }),
  },

  methods: {
    setHeaders(payload) {
      this.table.headers = [...payload.headers]
    },

    setBody(payload) {
      this.items = [...payload.body]
    },

    doAction(payload) {
      if (payload.action == 'import') {
        this.$refs.inputFile.click()
      }

      if (payload.action == 'export') {
        setTimeout(() => this.excelExport(), 1000)
      }
    },

    onGridReady(params) {
      this.table.api = params.api
      this.table.api.sizeColumnsToFit()
      // if (!this.hideOverlay) this.api.showLoadingOverlay()
      // this.api.expandAll();

      this.table.columnApi = params.columnApi
      this.$emit('gridApi', this.table.api)
      this.$emit('columnApi', this.table.columnApi)
    },

    onChange() {
      this.setBody({ body: this.table.rowData })
    },

    excelExport() {
      // Get Headers keys
      const keys = []
      const headers = this.table.headers
        .filter((e) => e.field || e.children)
        .map((e) => e.field || e.children)
      for (let i = 0; i < headers.length; i++) {
        const element = headers[i]
        Array.isArray(element)
          ? (keys = keys.concat(element.map((e) => e.field)))
          : keys.push(element)
      }
      const params = {
        allColumns: true,
        columnKeys: keys,
        processCellCallback: (params) => {
          const cellClass = params.column.colDef.cellClass
          const value = params.value
          return cellClass === 'date'
            ? this.$moment(params.value).format('DD-MM-YYYY')
            : params.value
        },
      }
      this.table.api.exportDataAsExcel(params)
    },

    //+-------------------------------------------------
    // importFile()
    // Uses UTIL.importfile to import data to a table
    // We use the timeout event to give time for the importer to run
    // -----
    // Created on Fri May 13 2022
    //+-------------------------------------------------
    async importFile(event) {
      const allowEmptyCells = true
      await UTIL.importFile(
        this,
        event,
        this.table,
        this.table.headers,
        this.onChange.bind(this),
        null,
        allowEmptyCells
      )
      this.$refs.inputFile.value = ''

      setTimeout(() => {
        $nuxt.$emit('excelBridge:imported', {
          ref: this.ref,
          body: this.items,
        })
      }, 500)
    },

    async init() {
      this.$nuxt.$on('excelBridge:load', (payload) => {
        this.ref = payload.ref

        if (payload.headers) this.setHeaders(payload)
        if (payload.body) this.setBody(payload)
        this.ui.key++

        setTimeout(() => {
          if (payload.action) this.doAction(payload)
        }, 500)
      })
    },
  },
  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('excelBridge:load')
  },
}
</script>

<style scoped>
.helper-overlay {
  position: fixed;
  z-index: 999;
  background: rgb(245, 245, 245);
  width: 95vw;
  height: 95vh;
  top: 2vh;
  right: 2vw;
  border: 3px solid rgb(54, 98, 173);
  padding: 10px;
  border-radius: 5px;
  opacity: 0.5;
}

.helper-overlay:hover {
  opacity: 1;
}
</style>
