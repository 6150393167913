<template>
  <div>
    
    <p style="text-align: center; white-space: pre-line; font-size: 20px; font-weight: bold;">
      AGROCOLOR Registration Number {{codigo_interno}}
      GGN: {{ggn}} 
    </p>

  </div>
</template>

<script>

export default {
  name: "gnn-certificados",
  props: {
    componente: { type: Object, default: {} }
  },
  components: {
    
  },
  data() {
    return {
      ggn: '',
      codigo_interno: '',
    };
  },
  watch: {

  },
  async mounted() {    
    const body = { modulo: 'clientes', external_uuid: this.componente.cliente }
    const custom_variables = await this.$store.dispatch('custom_variables/list', body) 
    try {
      this.ggn = custom_variables[0].variables.ggn
    } catch (error) {
      console.log('[ERROR] Al obtener el ggn del cliente.', error.message)
      this.ggn = ''
    }
    try {
      this.codigo_interno = custom_variables[0].variables.codigo_interno
    } catch (error) {
      console.log('[ERROR] Al obtener el código interno del cliente.', error.message)
      this.codigo_interno = ''
    }    
  },
  methods: {

  }
};
</script>

<style scoped>

</style>
