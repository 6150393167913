<template>
  <v-autocomplete
    ref="cliente"
    label="Cliente"
    v-model="selected"
    :items="items"
    :search-input.sync="search"
    :placeholder="ui.loading ? 'Cargando...' : 'Escriba para buscar el cliente'"
    item-text="nombre"
    item-value="uuid"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :hide-details="hideDetails"
    :dense="dense"
    :outlined="outlined"
    :multiple="multiple"
    @change="$emit('input', selected)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectClientes',
  props: {
    value: [String, Array],
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
  },
  created() {
    this.init()
  },
  data(ctx) {
    return {
      search: null,
      selected: ctx.value,
      items: [],
      ui: {
        loading: false,
      },
    }
  },
  computed: {
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
  },
  methods: {
    text(item) {
      if(item.nombre_cliente) return item.nombre_cliente
      let text = ''
      if (item.codigo) text += item.codigo + ' - '
      if (item.nombre) text += ' ' + item.nombre
      if (item.primer_apellido) text += ' ' + item.primer_apellido
      if (item.segundo_apellido) text += ' ' + item.segundo_apellido
      return text
    },
    async getClientes(nombre = null) {
      let res = null
      if (nombre) {
        res = await this.$store.dispatch('clientes/listActive', 
          {
            nombre: nombre,
          })
      } else {
        res = await this.$axios.$get('/api/clientes')
      }
      if (res) {
        this.items = res
      }
    },
    async init() {
     await this.getClientes()
      console.info(this.items)
    },
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.cliente.reset()
      this.selected = val
    },
    search(val) {
      this.loading = true
      if (val && val.length >= 2) {
        this.getClientes(val)
      }
      this.loading = false
    },
  },
}
</script>
