/**
 * @project: certiapp-nuxt
 * @file:    arrayHelper.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 6th May 2021
 * Last Modified: Tue Jul 11 2023
 **/

import EN from '~/locales/en.js'

export default ({ app, route, context }, inject) => {
  // Inject $_t(msg) in Vue, context and store.

  let locales = ['EN']
  // let ES = {}
  // let EN = {}

  // console.warn(locales, ES)

  function findText(input, domain) {
    let search = []
    if (domain) search.push(domain)
    else {
      search.push(route?.path?.split('/')[1])
      search.push('global')
    }

    let text = clean(input)

    for (const area of search) {
      let source = EN[area] || {}

      for (const entry in source) {
        console.log('☀️', area, entry)
        let _entry = clean(entry)
        if (_entry == text) {
          console.log('🟢', area, entry)
          return source[entry]
        }
      }
    }

    return false
  }

  function clean(text) {
    let result = text.toLowerCase().trim()
    result = result.replace(/[áàäâ]/gi, 'a')
    result = result.replace(/[éèëê]/gi, 'e')
    result = result.replace(/[íìïî]/gi, 'i')
    result = result.replace(/[óòöô]/gi, 'o')
    result = result.replace(/[úùüû]/gi, 'u')
    result = result.replace(/[ñ]/gi, 'n')
    result = result.replace(/[ç]/gi, 'c')
    result = result.replace(/[^\w\s]/gi, '')
    result = result.replace(/[\s]/gi, '-')
    result = result.replace(/[-]{2,}/gi, '-')

    return result
  }

  function translate(text, vars) {
    // search in text if there is any value between %var%
    // if there is, replace it with the value in vars
    // if there is not, return the text as is

    if (typeof vars == 'string') vars = { '*': vars }
    vars = vars || {}
    let result = text || ''

    for (const key in vars) {
      // if key is *, replace any text between braces with the value

      if (key == '*') result.replace(/ *\{[^}]*\) */g, vars[key])

      result = result.replace(`%${key}%`, vars[key]).replace(`%${key}%`, vars[key])
      result = result.replace(`{${key}}`, vars[key]).replace(`{${key}}`, vars[key])
    }

    return result
  }

  //+-------------------------------------------------
  // _t()
  // Global method to translate strings
  // Settings, bridge methods and other options are in components/translator
  // -----
  // Created on Mon Jul 10 2023
  //+-------------------------------------------------
  inject('_t', (text, vars = {}, domain = false) => {
    if (!text) return ''

    let string = findText(text, domain) || text
    return translate(string, vars)

    console.log(`Hello ${text}!`)
    console.warn(ES)
    console.warn()
    return 1234
  })
}
