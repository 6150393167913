<template>
  <div>
    <!-- Pagina 1 -->
    <div style="width:100%;text-align:center">
      <img src="https://www.kaam.es/images/logo.png" height="60px" width="60px">
      <h2 style="font-weight: 700;font-size:30px" >kaam</h2>
    </div>

    <div style="width:50%;float:left;">
      <p>
        <b>{{ datos.cliente.codigo }}</b><br />
        C/ {{ datos.cliente && datos.cliente.direccion }}<br />
        C.P. {{ datos.cliente.cp }} <br />
        PROVINCIA {{ datos.cliente.provincia.nombre }}
      </p>
      <p>
        <b>A/A: <span v-if="datos.responsable">{{ datos.responsable.nombre + ' ' + datos.responsable.apellidos }}</span></b>
      </p>
    </div>
    <div style="width:50%;float:left;text-align:right">
      <table style="margin-right:0;margin-left:auto;width:50%;padding:2px">
        <tbody>
          <tr>
            <td colspan="2" style="text-align:center">REGISTRO DOCUMENTOS</td>
          </tr>
          <tr style="text-align:left">
            <td><input type="checkbox" /> ENTRADA</td>
            <td><input type="checkbox" /> SALIDA</td>
          </tr>
          <tr style="text-align:left">
            <td colspan="2">N REGISTRO:</td>
          </tr>
          <tr style="text-align:left">
            <td>FECHA</td>
            <td><input type="checkbox" /> ANEJOS</td>
          </tr>
          <tr style="text-align:left">
            <td colspan="2">ARCHIVO: ST</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="clear: both;"></div>

    <p>
      <strong><u>Asunto:</u> Plan de Auditoria.</strong>
    </p>
    <p>Estimados Sres.:</p>
    <p>
      El motivo de esta carta es confirmar que la Auditoria de Certificación de la Especialidad Tradicional Garantizada "Jamón Serrano", a la empresa {{ datos.cliente && (datos.cliente.nombre + ' ' + datos.cliente.primer_apellido + ' ' + datos.cliente.segundo_apellido) }}, SL,
      con nº de expediente ETG-<span v-if="datos.expediente">{{ datos.expediente.codigo }}</span>,
      se realizará el próximo <b>{{ formatDate(datos.desde) }}</b>; conforme al Plan de Auditoria adjunto a esta comunicación.
    </p>
    <p>El equipo auditor estará formado por: </p>
    <p>
      <b>Auditora: <span v-if="datos.auditores">{{ auditores }}</span></b>
    </p>
    <p>
      Le recuerdo el derecho a recusar a los miembros del equipo auditor, en caso de no recibir indicación en contra, en el plazo de tres días, se considerará aceptada esta propuesta.
    </p>
    <p>
      Se comunica que la información proporcionada durante la auditoria será tratada a todos los efectos como confidencial.
    </p>
    <p>
      El horario contemplado en el Plan de Auditoria adjunto es orientativo pudiéndose modificar según las necesidades de la auditoria.
    </p>
    <p>
      Sin más que comunicarte, quedo a su disposición.
    </p>
    <p>
      Un cordial saludo.
    </p>

    <p>
      Fdo.: <br />
      Auditora
    </p>

    <!-- Pagina 2 -->
    <div class="page-break-before"></div>

    <div style="width:100%;border:solid 1px black;margin-top:5px;margin-bottom:15px;text-align:center;font-weight:700">
      AUDITORIA SEGUIMIENTO DE LA CERTIFICACIÓN<br />
        Nº expediente ETG-<span v-if="datos.expediente">{{ datos.expediente.codigo }}</span><br />
        Empresa {{ datos.cliente && (datos.cliente.nombre) }}
    </div>

    <p style="font-weight: 700">
      1.- Documentación de Calidad:
    </p>
    <p>
      <ul>
        <li>Pliego de Condiciones de la ETG Jamón Serrano según Reglamento (CE) nº 1204/2008 de la Comisión, relativo a la inscripción de determinadas denominaciones en el registro de ETG.s establecido en el Reglamento (CE) nº 509/2006.</li>
        <li>Documento  elaborado  por  el  MAPA  (con  fecha  10/07/2000)  relativo  al  control  de  los Requisitos mínimos de control de las características específicas del Jamón Serrano.</li>
        <li>PE CER 02: Procedimiento de trabajo para la realización de la auditoria de certificación de producto especialidad tradicional garantizada “Jamón Serrano”.</li>
        <li>Procedimiento General de Certificación, PG-CER-03.</li>
        <li>Real Decreto 474/2014, de 13 de junio.</li>
        <li>Manual / Procedimiento / Instrucción del autocontrol de la empresa solicitante.</li>
      </ul>
    </p>
    <p style="font-weight: 700">
      2. Plan de Auditoria:
    </p>
    <p>
      <ul>
        <li>Fecha: {{ formatDate(new Date()) }}</li>
        <li>
          Lugar:
          <span>{{ datos.cliente && (datos.cliente.nombreo) }}</span><br />
          <span>C/ {{ datos.cliente && datos.cliente.direccion }}</span><br />
          C.P. {{ datos.cliente.cp }} <br />
          Provincia {{ datos.cliente.provincia.nombre }}
        </li>
      </ul>
    </p>
    <p style="font-weight: 700">
      12:00 – 12:15	Reunión Inicial.
    </p>
    <p>
      <ul>
        <li>Presentación del equipo auditor.</li>
        <li>Aclaraciones sobre el plan de auditoria y alcance.</li>
        <li>Resumen de las actividades de la auditoria.</li>
        <li>Confirmación de auditores e interlocutores.</li>
        <li>Confirmación del progreso de la auditoria: Revisión documental.</li>
        <li>Confidencialidad.</li>
        <li>Método de información.</li>
        <li>Condiciones para terminar / detener una auditoria.</li>
        <li>Apelaciones sobre la realización / conclusiones de la auditoria.</li>
      </ul>
    </p>
    <p style="font-weight: 700">
      12:15 – 17:00	Evaluación Empresa <span>{{ datos.cliente && (datos.cliente.nombre) }}</span>
    </p>
    <p style="font-weight: 700">
      17:00 – 17:30	Revisión de las evidencias encontradas por el equipo auditor.
    </p>
    <p style="font-weight: 700">
      17:30 – 18:00	Reunión Final.
    </p>

    <!-- Pagina 3 -->
    <div class="page-break-before"></div>

    <div style="width:100%;border:solid 1px black;margin-top:5px;margin-bottom:15px;text-align:center;font-weight:700">
      AUDITORIA SEGUIMIENTO
    </div>

    <table style="width: 100%" v-if="instalaciones.length">
      <thead>
        <tr style="text-align: center">
          <td style="font-weight-bold">INSTALACIONES<br />AUDITOR</td>
          <td style="font-weight-bold">VERIFICACIONES</td>
          <td style="font-weight-bold">FECHA</td>
          <td style="font-weight-bold">HORA</td>
        </tr>
      </thead>
      <tbody>
        <tr style="text-align: center" v-for="item in instalaciones" :key="item.id">
          <td>{{ item.razon_social }}<br /><span v-if="datos.responsable">{{ datos.responsable.nombre + ' ' + datos.responsable.apellidos }}</span></td>
          <td style="text-align:left">
            <ul>
              <li>Auditoria en las instalaciones.</li>
              <li>Funcionamiento  sistema  de  autocontrol  y  de  la  competencia  técnica respecto  a  los  requisitos  establecidos  para  la  producción  de  ETG  Jamón Serrano.</li>
              <li>Conformidad del producto y la producción:</li>
              <li>
                <u>Materia Prima:</u>
                <ul>
                  <li>Evaluación de proveedores</li>
                  <li>Requisitos de la materia prima</li>
                  <li>Registros derivados de la recepción de la materia prima y de la aplicación del sistema de autocontrol</li>
                  <li>Sistema de trazabilidad, formación de lotes</li>
                  <li>Establecimiento jamones piloto por lote</li>
                </ul>
              </li>
              <li>
                <u>Control del Proceso de Producción</u>
                <ul>
                  <li>Recepción de la materia prima</li>
                  <li>Condiciones de elaboración</li>
                  <li>Permanencia de los períodos mínimos (y condiciones de Tª/H) establecidos para cada fase de la elaboración</li>
                  <li>Registros derivados de la elaboración del producto y del sistema de autocontrol</li>
                </ul>
              </li>
              <li>
                <u>Control del Producto Terminado definido por el etiquetador:</u>
                <ul>
                  <li>Control de mermas</li>
                  <li>Registros derivados del sistema de autocontrol para el control de mermas y características de producto terminado</li>
                  <li>Tiempo de curación</li>
                  <li>Requisitos del producto terminado</li>
                </ul>
              </li>
              <li>Tratamiento de productos no conformes</li>
              <li>Sistema de trazabilidad e identificación</li>
              <li>Identificación, etiquetado y presentación del producto</li>
              <li>Registro de los datos necesarios para evaluar el cumplimiento de los requisitos descritos en el Procedimiento Especifico PE CER 02 y en el sistema de autocontrol establecido en la empresa solicitante</li>
              <li>Revisión de la identificación, calibraciones y verificaciones de los equipos</li>
              <li>Tratamiento de las reclamaciones de los clientes</li>
              <li>Toma de muestras, de Producto Terminado tal y como se encuentra definido por el etiquetador</li>
            </ul>
          </td>
          <td>{{ formatDate(datos.desde) }}</td>
          <td>{{ formatTime(datos.desde) }}</td>
        </tr>
      </tbody>
    </table>

    <p>
      Fdo.:<br />
      Auditora
    </p>
  </div>
</template>

<style scoped>
  table {
  border-collapse: collapse;
}

table, th, td {
  border: 0.5px solid black;
}

thead {
  display: table-row-group;
}

tr td {
  page-break-inside: avoid;
}

.no-border {
  border: none !important;
}
</style>

<script>
import MODELS from "~/models/kaam/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    tipoOperador: MODELS.OPERADORES_TIPO,
    tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
    tipoProducto: MODELS.PRODUCTO_TIPO,
    tipoRacial: MODELS.RACIAL_TIPO,
    tipoEstado: MODELS.ESTADOS_TIPO,
    tipoPresentacion: MODELS.PRESENTACION_TIPO,
    tipoCertificacion: MODELS.CERTIFICACION_TIPO,
  }),
  computed: {
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    alcance () {
      return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
    },
    instalaciones () {
      const actividades = this.datos
        && this.datos.solicitud
        && this.datos.solicitud.params.alcance_producto.map(e => e.rgseaa)
        || []
      return this.datos.cliente.instalaciones.filter(e => actividades.includes(e.rgseaa))
    },
    actividades () {
      const planificaciones = this.datos.planificaciones
      const sites = this.datos.sites

      const planificacionesSites = planificaciones.map(e => e.site.uuid)

      const childrenSites = sites.filter(e => e.parent_uuid)

      return childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
      // return childrenSites.length
      //   ? childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
      //   : sites.filter(e => planificacionesSites.includes(e.uuid))
    },
    auditores () {
      const auditores = []
      const planificaciones = this.datos.planificaciones && this.datos.planificaciones
      if (planificaciones) {
        for (let i=0; i < planificaciones.length; i++) {
          const planificacion = planificaciones[i]
          if (!auditores.includes(planificacion.auditor)) auditores.push(planificacion.auditor)
        }
      }
      return auditores.join(', ')
    },
    planificaciones_data () {
      return this.datos && this.datos.planificaciones.map(element => element.data)
    },
    solicitud_data () {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach(element => {
          data[element.keyname] = element.value
        })
        return data
      }
      else return {}
    },
    site_data () {
      if (this.datos.isCliente) return this.datos.isCliente
      else if (this.datos.sites) {
        let data = {}
        let tipos = []

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
        }

        for (let i=0; i < tipos.length; i++) {
          const element = tipos[i]
          data[element] = []
        }

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          let obj = {}
          obj.uuid = element.uuid
          for (let k=0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data[element.tipo].push(obj)
        }

        return data
      }
      else return {}
    },
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    formatTime (date) {
      return date ? this.$moment(date).format('HH:mm') : ''
    },
  }
}
</script>

