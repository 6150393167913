var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[(!_vm.isEmbed)?_c('v-col',{staticClass:"align-center",attrs:{"cols":"7"}},[(!_vm.expediente)?_c('h1',[_vm._v("Marcas")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":_vm.isEmbed ? 12 : 5}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"label":"Buscar en la tabla","placeholder":"Escribe...","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mx-2",attrs:{"depressed":"","outlined":""},on:{"click":function($event){_vm.$router.push((_vm.isPortal) ? '/portal/marcas/nueva' : '/marcas/nueva')}}},[_vm._v("\n        Nueva marca\n      ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-data-table',{attrs:{"headers":[

            { text: '', align: 'start', value: 'status', cellClass: 'subData' },
            {
              text: 'Marca',
              align: 'start',
              value: 'nombre',
            },
            { text: 'Fecha', align: 'start', value: 'fechas', cellClass: 'subData' },

            {
              text: 'Marca propia',
              align: 'center',
              value: 'is_propia',
              cellClass: 'subData',
              sortable: false
            },

            {
              text: 'Etiqueta recibida',
              align: 'center',
              value: 'is_etiqueta_recibida',
              cellClass: 'subData',
              sortable: false
            },

            {
              text: 'Etiqueta validada',
              align: 'center',
              value: 'is_etiqueta_validada',
              cellClass: 'subData',
              sortable: false
            },

            {
              text: 'En certificado',
              align: 'center',
              value: 'is_imprimir_en_certificado',
              cellClass: 'subData',
              sortable: false
            },
            { text: '', value: 'actions', align: 'left' }
          ],"items":_vm.items,"search":_vm.search},on:{"click:row":_vm.rowAction},scopedSlots:_vm._u([{key:"item.auditor",fn:function({ item }){return [_c('span',{staticStyle:{"font-size":"0.85rem","color":"#929292"}},[_vm._v("\n              "+_vm._s(item.auditor && item.auditor.nombre || "--")+"\n            ")])]}},{key:"item.is_propia",fn:function({ item }){return [(item.is_propia)?_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("\n              ✅\n            ")]):_vm._e()]}},{key:"item.is_etiqueta_recibida",fn:function({ item }){return [(item.is_etiqueta_recibida)?_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("\n              ✅\n            ")]):_vm._e()]}},{key:"item.is_etiqueta_validada",fn:function({ item }){return [(item.is_etiqueta_validada)?_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("\n              ✅\n            ")]):_vm._e()]}},{key:"item.is_imprimir_en_certificado",fn:function({ item }){return [(item.is_imprimir_en_certificado)?_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("\n              ✅\n            ")]):_vm._e()]}},{key:"item.fechas",fn:function({ item }){return [_vm._v("\n              "+_vm._s(_vm.$moment(item.fecha).format("DD [de] MMMM, YYYY"))+"\n          ")]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","small":"","outlined":""}},[_vm._v("\n              "+_vm._s(item.is_active ? "Activo" : "Inactivo")+"\n            ")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticStyle:{"font-size":"20px"},attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.toDelete(item)}}},[_vm._v("mdi-delete-outline")])]}}])})],1)],1)],1),_vm._v(" "),_c('confirmation',{attrs:{"text":'¿Eliminar el objeto seleccionado?',"colorButton":"error"},on:{"confirm":function($event){return _vm.deleteItem()}},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }