<template>
  <div>
    <!-- MENÚ LATERAL DE NOTIFICACIONES -->
    <v-navigation-drawer v-model="drawer" app right temporary width="570px" class="elevation-6 fondo-app"
      style="background-color: #EFF2F7 !important;">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title class="flex">
          Notificaciones
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn v-if="!selected.length" elevation="0" text @click="read('all')">Eliminar todas</v-btn>
        <v-btn v-else elevation="0" flat text @click="read()">Leer {{ selected.length }}</v-btn>

        <!-- <v-badge
            :value="notificacionesPendientes.length > 0"
            :content="notificacionesPendientes.length"
            color="blue"
            class="mr-2"
        >
          <v-icon class="icon-menu" color="white">mdi-bell-ring</v-icon>
        </v-badge> -->
      </v-toolbar>

      <v-progress-linear v-if="ui.loading" color="deep-purple accent-4" indeterminate height="5"></v-progress-linear>

      <v-list-item-group v-model="selected" multiple active-class="" v-if="list.length" :disabled="ui.loading">

        <template v-for="(item, idx) in list">
          <v-list-item two-line :key="item.id" :style="{ backgroundColor: item.is_new ? '#FFF7E8' : 'white' }"
            :disabled="ui.loading" style="overflow: visible">
            <template v-slot:default="{ active }">

              <v-list-item-action>
                <v-checkbox :input-value="active" class="ml-2"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content @click.stop="goToRoute(item)">
                <v-list-item-title class="caption" style="white-space: normal !important;">
                  {{ $moment(item.release_date).format('D [de] MMMM, HH:mm:ss') }}
                </v-list-item-title>
                <v-list-item-subtitle class="body-1 pt-1" style="white-space: normal !important; color: #3D4853">
                  {{ item.titulo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption pt-1" style="white-space: normal !important; color: #8F96A1">
                  {{ item.hint }}
                  <span v-if="item.hint == ''">
                    {{ item.cliente.nombre }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click.stop="deleteItem(item)">
                      mdi-bell-check
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </v-list-item-action>
            </template>

          </v-list-item>

        </template>
      </v-list-item-group>
      <!--      <div class="text-center mt-10" v-if="list.length > 0">-->
      <!--        <v-btn-->
      <!--            rounded-->
      <!--            color="info"-->
      <!--            dark-->
      <!--            @click="deleteAll"-->
      <!--        >-->
      <!--          Borrar todas-->
      <!--        </v-btn>-->
      <!--      </div>-->

    </v-navigation-drawer>

    <confirmation v-model="dialog" text="¿Está seguro que desea borrar la notificación?" colorButton="error"
      textButton="Borrar" @confirm="deleteItem()" />
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\Notificaciones.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Wed Jul 12 2023
 **/

import Confirmation from '~/components/Confirmation'

export default {
  name: 'Notificaciones',
  props: {
    value: Boolean,
  },
  components: {
    Confirmation,
  },
  data: () => ({
    selected: [],
    dialog: false,
    drawer: false,
    // EVENTO: Estados.COMUNICACION_EVENTO,

    ui: {
      loading: false,
    },
  }),
  watch: {
    value(val) {
      this.drawer = val
    },
    drawer(val) {
      if (!val) this.$emit('close')
    },
  },
  computed: {
    list() {
      let items = [...this.$store.state.notificaciones.list]

      items = items.filter((item) => {
        return !item.is_read
      })

      items.sort((a, b) => {
        return a.release_date < b.release_date ? 1 : -1
      })
      return items
    },
    notificacionesPendientes() {
      return this.$store.getters['notificaciones/notificacionesPendientes']
    },
  },
  methods: {
    async read(amount = false) {
      this.ui.loading = true

      if (!amount) {
        for (let i = 0; i < this.selected.length; i++) {
          const item = this.selected[i]
          let itemSelected = this.list[item]
          await this.$store.dispatch(`notificaciones/delete`, itemSelected)
          this.list[item].is_read = true
        }
      }

      if (amount == 'all') {
        for (let i = 0; i < this.list.length; i++) {
          const item = this.list[i]
          await this.$store.dispatch(`notificaciones/delete`, item)
          this.list[i].is_read = true
        }
      }

      this.selected = []
      this.loadNotificaciones()

      this.ui.loading = false
    },

    // deleteAll() {
    //   this.list.forEach((item) => {
    //     this.$store.dispatch(`notificaciones/delete`, item)
    //     this.$store.commit('SET_LIST', [])
    //   })
    // },

    async goToRoute(item) {
      let path = ''
      const modulo = item.modulo
      if (modulo == 'PACS') {
        path = 'auditorias'
      } else {
        const routes = this.$router.options.routes
        for (let i = 0; i < routes.length; i++) {
          const route = routes[i]
          if (route.name.includes(modulo)) {
            path = route.path
            break
          }
        }
      }

      if (item.is_new) {
        // this.deleteItem(item)
        await this.$store.dispatch(`notificaciones/check`, item)
        await this.$store.dispatch(`notificaciones/list`)
      }

      let route = ''
      switch (modulo) {
        case 'clientes':
          route = `personal/${item.external_uuid}`
          break

        case 'etiquetas':
          route = path
          break

        case 'documentos':
          route = 'documentos'
          break

        case 'PACS':
          route = path + '/' + item.auditoria.uuid
          break

        case 'newsboard':
          route = path
          break

        default:
          route = path + '/' + item.external_uuid
          break
      }

      this.$router.push(route)
    },

    async deleteItem(item) {
      console.warn(item)

      await this.$store.dispatch(`notificaciones/delete`, item)
      await this.$store.dispatch(`notificaciones/list`)
      return

      if (item) {
        this.itemSelected = item
        this.dialog = true
      } else {
        this.dialog = false
        await this.$store.dispatch(`notificaciones/delete`, this.itemSelected)
        await this.$store.dispatch(`notificaciones/list`)
      }
    },

    loadNotificaciones() {
      this.$store.dispatch(`notificaciones/list`)
    },

    async init() {
      //this.loadNotificaciones()

      this.$nuxt.$on('notificaciones:reload', (payload) => {
        this.loadNotificaciones()
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('notificaciones:reload')
  },
}
</script>


<style scoped>
.v-list-item {
  border-bottom: 1px solid #eff2f7 !important;
}
</style>
