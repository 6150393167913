<template>
  <div>
    <p :class="componenteClass(componente.tipografia)">{{ componente.text.label['es_ES'] }}</p>
  </div>
</template>

<script>
export default {
  props: {
    componente: { type: Object, default: () => {} },
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
  }),
  methods: {
    componenteClass (estilo) {
      let classes = ''
      if (estilo.alignment) classes += ' ' + estilo.alignment
      if (estilo.margin_bottom) classes += ' mb-' + estilo.margin_bottom
      if (estilo.margin_top) classes += ' mt-' + estilo.margin_top
      if (estilo.size) classes += ' ' + estilo.size
      if (estilo.weight) classes += ' ' + estilo.weight
      return classes
    }
  }
}
</script>
