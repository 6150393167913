/**
 * @project: certiapp-nuxt
 * @file:    notificationHelper.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 6th May 2021
 * Last Modified: Thu Jul 07 2022
 **/

import store from '~/store/index'

export default {
  //+-------------------------------------------------
  // show()
  // Displays a notification
  // -----
  // Created on Thu May 06 2021
  //+-------------------------------------------------
  show(body) {
    $nuxt.$store.commit('notification/show', {
      text: body.text,
      color: body.color || 'success',
      timeout: body.timeout || 5000,
    })
  },

  //+-------------------------------------------------
  // onSaved()
  // Displays a notification when the save flag is present
  // in the url and cleans the url
  // -----
  // Created on Thu May 06 2021
  //+-------------------------------------------------
  onSaved($vm) {
    if ($vm.$route.query.hasOwnProperty('saved')) {
      this.show(
        {
          text: 'La solicitud se ha procesado correctamente',
          color: 'success',
          timeout: 5000,
        },
        $vm
      )

      if (window.history.replaceState) {
        window.history.replaceState('Clean URL', 'CertiApp', window.location.href.split('?')[0])
      }
    }
  },
}
