export const actions = {

  async getGlobalgapPlantillasChecklist(){
    const {data} = await this.$axios.get('globalgap-plantillas-checklist')
    return data
  },

  async getGlobalgapPlantillasChecklistByUuid({ rootState }, params){
    const body = {
      uuid: params.checklist.ChecklistID,
      alcance: params.alcance.uuid
    }
    const {data} = await this.$axios.post('globalgap-plantillas-checklist/descargar', body)
    return data
  },

}
