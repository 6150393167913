<template>
  <span>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn small dark depressed color="info" v-on="on">
          Acciones
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="item in actions" :key="item.id" @click="controlClick($event, item)">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
  export default {
    data: () => ({
      actions: [
        { tipo: 'productores', name: 'Cambio NIF',  action: 'cambio_nif', field: 'nif' },
        { tipo: 'productores', name: 'Cambio GGN',  action: 'cambio_ggn', field: 'ggn' },
        { tipo: 'productores', name: 'Baja productor', action: 'baja_productor' },
      ]
    }),
    methods: {
      controlClick($event, action) {
        if (this.params.controlClick instanceof Function) {
          const body = {
            ...action
          }
          this.params.controlClick(body)
        }
      }
    }
  }
</script>
