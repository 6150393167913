export const state = () => ({
  list: [],
  item: {}
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list
  },
  SET_LIST(state, items) {
    this.list = items
  }
};

//dispatch actions
export const actions = {

  async list({commit}) {
    const res = await this.$axios.put('componentes')
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },
  async get({commit}, uuid) {
    const res = await this.$axios.get(`componentes/${uuid}`)
    if (res) return res.data
  },
  async edit({commit}, item) {
    const res = await this.$axios.patch(`componentes/${item.uuid}`, item)
    if (res) return res.data
  },
  async create({commit}, item) {
    const res = await this.$axios.post(`componentes`, item)
    if (res) return res.data
  },
  async delete({commit}, item) {
    const res = await this.$axios.delete(`componentes/${item.uuid}`)
    if (res) return res.data
  }

};
