<template>
  <div>

    <floating-button-menu
      v-if="!isDisabled"
      :valid="valid"
      @submit="submit()"
    ></floating-button-menu>

    <v-form ref="form" lazy-validation v-model="valid">
      <v-row>
        <!-- ALERTA MODO OFFLINE -->
        <v-expand-transition>
          <v-col cols="12" class="pb-0" v-if="offlineMode">
            <v-alert type="error">
              <v-row no-gutters>
                <v-col cols="8">
                  Estas usando el <span class="font-weight-bold">Modo Offline</span>, tus cambios serán efectuados una vez vuelvas a estar online.
                  <br>
                  No puedes subir archivos mientras te encuentres en este modo.
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn small class="mr-4" color="success" @click="confirmarModoOnline()">Volver Online</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-expand-transition>

        <!-- Header -->
        <v-col cols="12" class="display-1 font-weight-medium text-capitalize">
          Muestras
        </v-col>

        <!-- Content -->
        <v-col cols="12">
          <v-card :loading="loading">
            <v-card-title class="pb-0" primary-title>
              <span>
                N° Operador: {{ toma_muestra.codigo}}
                <br>
                Estado: <span class="success--text">{{ toma_muestra.estado }}</span>
              </span>
              <v-spacer></v-spacer>

                <v-alert
                v-if="cumple"
                color="green"
                dense
                text
                type="success"
                >Este operador cumple con todas las muestras</v-alert>

                <v-alert
                v-else
                color="orange"
                dense
                text
                type="info"
                >Este operador no cumple con todas las muestras</v-alert>

              <!-- <v-switch
                v-model="cumple"
                color="success"
                readonly
                :ripple="false"
                class="cursor-default"
              >
                <template slot="label">
                  <span class="font-weight-bold" :class="cumple ? 'success--text' : 'error--text'">
                    {{ cumple ? 'CUMPLE' : 'NO CUMPLE' }}
                  </span>
                </template>
              </v-switch> -->

            </v-card-title>
            <v-row no-gutters align="center">

              <!-- AVATAR -->
              <v-col cols="3" class="text-center">
                <v-avatar size="150">
                  <v-img :src="cliente.avatar ? cliente.avatar : require('~/static/Profile_avatar_placeholder_large.png')" alt="Avatar" cover></v-img>
                </v-avatar>
              </v-col>

              <v-col cols="9">
                <v-row class="fill-height">

                  <!-- DATOS BÁSICOS -->
                  <v-col cols="5">
                    <v-card flat>
                      <v-card-title v-if="cliente.nombreCompleto" class="pb-0">
                        {{ cliente.nombreCompleto }}
                      </v-card-title>
                      <v-card-title v-else class="pb-0">
                        {{ cliente.nombre }}
                        <span v-if="cliente.apellido1">{{ '  ' + cliente.apellido1 + '  ' }}</span>
                        <span v-if="cliente.apellido2">{{ cliente.apellido2}}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row no-gutters>

                            <v-col cols="12" class="py-1">
                              {{ cliente.codigo }}
                            </v-col>

                            <v-col cols="12" class="py-1">
                              <v-icon class="mr-2">mdi-phone</v-icon> <a :href="'tel:' + cliente.telefono">{{ cliente.telefono }}</a>
                            </v-col>

                            
                            <v-col cols="12" class="py-1">
                              <v-icon class="mr-2">mdi-home</v-icon> {{ cliente.direccionCompleta.direccion}}
                            </v-col>

                            <v-col cols="12" class="py-1">
                              <v-icon class="mr-2">mdi-map-marker</v-icon> {{ cliente.direccionCompleta.codigoPostal }}, {{ cliente.direccionCompleta.municipio  }}
                              <div class="pt-1">{{ cliente.direccionCompleta.provincia  }}, {{ cliente.direccionCompleta.pais }}</div>
                            </v-col>
                          
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- MAPA -->
                  <v-col cols="5" class="py-6">
                    <mapa :data="cliente"></mapa>
                  </v-col>

                </v-row>
              </v-col>

            </v-row>

            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-row align="center">

                  <!-- AUDITOR -->
                  <v-col cols="4">
                    <SelectAuditores
                      v-if="auditoria.responsable"
                      title="Responsable"
                      v-model="toma_muestra.responsable"
                      required
                      outlined
                      :disabled="isDisabled"
                      dense/>
                  </v-col>

                  <!-- OTRO RESPONSABLE -->
                  <v-col cols="4">
                    <v-text-field
                      label="Otro Responsable"
                      outlined
                      v-model="toma_muestra.otroResponsable"
                      :disabled="isDisabled"
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- CODIGO -->
                  <v-col cols="4">
                    <v-text-field
                      label="Codigo"
                      outlined
                      v-model="toma_muestra.codigo"
                      :disabled="isDisabled"
                      required
                      :rules="rules"
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- PERSONAL -->
                  <v-col cols="4">
                    <v-text-field
                      label="Nombre personal presente en la toma de muestras"
                      v-model="toma_muestra.nombrePersonal"
                      :disabled="isDisabled"
                      required
                      outlined
                      :rules="rules"
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- DNI -->
                  <v-col cols="4">
                    <v-text-field
                      label="DNI personal presente en la toma de muestras"
                      v-model="toma_muestra.dniPersonal"
                      :disabled="isDisabled"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-3" v-if="isOceGlobal">
                    <v-card flat style="border: 2px solid #ccc; cursor: pointer;"
                      v-if="toma_muestra.uuid"
                      @click.stop="$refs.croquis.sign()">
                    <v-card-text>
                      <Signature
                        :ref="'croquis'"
                        :signed="toma_muestra.croquis"
                        :options="{
                          modulo: 'muestras',
                          modulo_ref: toma_muestra.uuid,
                          type:'croquis',
                          can_replace: true
                        }" />
                    </v-card-text>
                  </v-card>
                  </v-col>

                  <!-- DESCRIPCION -->
                  <v-col cols="12" class="py-3">
                    <v-textarea
                      label="Observaciones"
                      v-model="toma_muestra.observaciones"
                      :disabled="isDisabled"
                      hide-details="auto"
                      auto-grow
                      outlined
                    ></v-textarea>
                  </v-col>

                  <!-- TRATAMIENTOS -->
                  <v-col cols="12" class="py-3">
                    <v-textarea
                      label="Tratamientos realizados"
                      v-model="toma_muestra.tratamientosRealizados"
                      :disabled="isDisabled"
                      hide-details="auto"
                      auto-grow
                      outlined
                    ></v-textarea>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

          </v-card>
        </v-col>

      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12">
        <MuestrasTab
          v-if="toma_muestra && auditoria.uuid"
          :auditoria="auditoria"
          :toma_muestra="toma_muestra"
          :muestras="toma_muestra.muestras"
          :isDisabled="isDisabled"
          @updateMuestras="updateMuestras"
        />
      </v-col>
    </v-row>

    <!-- CONFIRMACIÓN DE CAMBIO A MODO ONLINE -->
    <!-- <confirmation v-model="dialogModoOnline"
                  :text="texto_confirmacion"
                  textButton="Aceptar"
                  colorButton="primary"
                  @confirm="toggleOnline()"/> -->

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\auditorias\tabs\muestras\_uuid.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Apr 03 2023
 **/

import Mapa from '~/components/maps/Mapa'
import Confirmation from '~/components/Confirmation'
import FloatingButtonMenu from '~/components/FloatingButtonMenu'
import MuestrasTab from '~/components/auditorias/muestras/MuestrasTab'
import SelectAuditores from '~/components/forms/SelectAuditores'
import Empresas from '~/models/empresas'
import moment from 'moment'
import Signature from '~/components/signatures/Show'

export default {
  props: ['uuid', 'auditoriaUUID'],
  components: {
    Mapa,
    Confirmation,
    FloatingButtonMenu,
    MuestrasTab,
    SelectAuditores,
    Signature,
  },
  data: () => ({
    search: '',
    texto_confirmacion: '',
    dialogModoOnline: false,
    image: null,
    valid: true,
    loading: false,
    EMPRESAS: Empresas,
    rules: [(v) => !!v || 'Obligatorio'],
    toma_muestra: {
      uuid: null,
      codigo: '',
      estado: 'NUEVA',
      responsable: { uuid: '' },
      otroResponsable: '',
      nombrePersonal: '',
      dniPersonal: '',
      observaciones: '',
      tratamientosRealizados: '',
      muestras: [],
    },
    auditoria: {},
    cliente: {
      uuid: null,
      activo: true,
      is_moroso: false,
      codigo: '',
      user: {
        username: '',
        password: '',
        email: '',
      },
      direccionCompleta: {
        direccion: '',
        codigoPostal: '',
        municipio: '',
        provincia: '',
        pais: '',
      },
      pais: { uuid: '' },
      provincia: { uuid: '' },
      municipio: { uuid: '' },
    },
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    isNew() {
      return this.$route.params.uuid === '_'
    },

    auditoria_uuid() {
      return this.auditoriaUUID ? this.auditoriaUUID : this.$route.query.auditoria_uuid
      return this.$route.query.auditoria_uuid
    },

    muestra_uuid() {
      return this.uuid ? this.uuid : this.$route.params.uuid
      return this.$route.params.uuid
    },

    //+-------------------------------------------------
    // isDisabled()
    // Casos en los que no se permite la edición de campos
    // -----
    // Created on Thu Oct 13 2022
    //+-------------------------------------------------
    isDisabled() {
      if (!this.auditoria?.uuid) return true

      let isAuditor = this.$auth.user.roles.includes('ROLE_AUDITOR')
      let isTheAuditor = this.$auth.user.uuid === this.auditoria.responsable.uuid
      let isAuditorAdmin = this.$auth.user.roles.includes('ROLE_AUDITOR_ADMIN')
      let isAuditorInAuditores = this.auditoria.auditores.some((auditor) => {
        return this.$auth.user.uuid === auditor.uuid
      })

      if (isAuditor && !isTheAuditor && !isAuditorInAuditores && !isAuditorAdmin) return true

      return false
    },

    //+-------------------------------------------------
    // cumple()
    // Reads content from tabs https://trello.com/c/3ZCAB3ZK
    // -----
    // Updated on Thu Oct 06 2022
    //+-------------------------------------------------
    cumple() {
      if (!this.toma_muestra?.muestras) return

      let state = false
      let states = ['INICIAL', 'CONTRADICTORIO', 'DIRIMENTE']
      console.log('muestras', this.toma_muestra.muestras)
      states.forEach((key) => {
        let item = this.toma_muestra.muestras.find((muestra) => muestra.tipoMuestra === key)
        console.log('item', item)
        if (key === 'INICIAL') state = item ? item.estado : false 
        if (key !== 'INICIAL' && item?.params?.length) state = item ? item.estado : false 

        // console.warn(item, state, item.params.length, item.estado)
      })

      state = state == 'NO_CUMPLE' ? false : state
      state = state == 'CUMPLE' ? true : state
      state = state === null ? false : state

      // console.warn(state)
      return state
    },

    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },

    updateMuestras(data) {
      console.log('muestras1', data)
      this.toma_muestra.muestras = data
    },

    async init() {
      await this.$store.dispatch('auditorias/get', this.auditoria_uuid).then((res) => {
        this.auditoria = res
        this.$store.dispatch('clientes/get', this.auditoria.cliente.uuid).then( (res2) => {
           this.cliente = res2
          // ----------------------------------------------------->
          if (this.cliente.fecha_consentimiento_lopd)
            this.cliente.fecha_consentimiento_lopd = moment(
              this.cliente.fecha_consentimiento_lopd
            ).format('YYYY-MM-DD')
          if (this.cliente.fecha_revocacion_lopd)
            this.cliente.fecha_revocacion_lopd = moment(this.cliente.fecha_revocacion_lopd).format(
              'YYYY-MM-DD'
            )
          // ----------------------------------------------------->
          if (!this.cliente.pais) this.cliente.pais = { uuid: '' }
          if (!this.cliente.provincia) this.cliente.provincia = { uuid: '' }
          if (!this.cliente.municipio) this.cliente.municipio = { uuid: '' }
          // Tomas Muestras
          this.getTomaMuestra()
        })
      })

      console.log('auditoria', this.auditoria)
      console.log('cliente', this.cliente)
    },

    async getTomaMuestra() {
      await this.$store.dispatch('tomas_muestra/get', this.muestra_uuid).then((res) => {
        if (res) {
          this.toma_muestra = res
          console.log('toma_muestra', this.toma_muestra)
        }
      })

      const res = await this.$store.dispatch('tomas_muestra/listMuestra', this.toma_muestra.uuid)
      this.toma_muestra.muestras = res

      // this is here for preloading reasons
      if (this.auditoriaUUID) {
        await this.$store.dispatch('tomas_muestra/listMuestra', this.toma_muestra.uuid)
        await this.getEstaticos('datos_muestra')
        await this.getEstaticos('tipos_analisis')
      }
      if (!this.toma_muestra.nombrePersonal) {
        if (
          this.auditoria?.representante
        ) {
          this.toma_muestra.nombrePersonal = this.auditoria.representante.nombre ? this.auditoria.representante.nombre : ''
          this.toma_muestra.dniPersonal = this.auditoria.representante.nif ? this.auditoria.representante.nif : ''
        }
      }

      $nuxt.$emit('offline:loaded', {
        item: 'muestra',
        uuid: this.toma_muestra.uuid,
        data: this.toma_muestra,
      })
    },

    async getEstaticos(codigo) {
      const body = {
        alcance: this.auditoria.alcance.uuid,
        codigos: [codigo],
      }
      this.estaticos = await this.$store.dispatch('estaticos/list', body)
    },

    async updateTomaMuestra() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('tomas_muestra/update', this.toma_muestra)
        this.getTomaMuestra()
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('tomas_muestra/update', this.toma_muestra)
        this.init()
      }
    },
    // async checkOffline() {
    //   const offlineMode = localStorage.getItem('offlineMode')
    //   const offlineUuid = localStorage.getItem('offlineUuid')
    //   if (offlineMode && offlineUuid) this.toggleOffline(offlineUuid)
    //   else this.init()
    // },
    confirmarModoOnline() {
      this.texto_confirmacion = '¿Está seguro de que desea volver al Modo Online?'
      this.dialogModoOnline = true
    },
    // async toggleOffline(uuid) {
    //   this.dialogModoOffline = false
    //   const auditoria_uuid = uuid ? uuid : this.$route.query.auditoria
    //   await this.$store.commit('auditorias/OFFLINE_MODE', true)
    //   await this.$store.commit('auditorias/OFFLINE_UUID', auditoria_uuid)
    //   localStorage.setItem('offlineMode', true)
    //   localStorage.setItem('offlineUuid', auditoria_uuid)
    //   this.init()
    // },
    // async toggleOnline() {
    //   this.dialogModoOnline = false
    //   await this.$store.dispatch('auditorias/goOnline')
    //   this.init()
    // },
  },
  mounted() {
    this.$store.commit('menu/setPageTitle', 'Auditoría')
    this.init()
  },
}
</script>
