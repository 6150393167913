<template>
  <div>
    <table style="width: 100%">
      <tbody>
        <tr>
          <td style="text-align: center">
            <img src="https://www.kaam.es/images/logo.png" height="60px" width="60px">
            <h2 style="font-weight: 700" >kaam</h2>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
      <br />
    </p>
    <h3 style="text-align: center">
      PLAN DE AUDITORÍA
    </h3>
    <!-- Pagina 1 -->
    <p>
      <strong>
        {{ clienteNombreCompleto }}
      </strong>
    </p>
    <p>
      <span>{{ datos.cliente && datos.cliente.direccion }}</span>
    </p>
    <p>
      <strong>A: </strong>
      <span v-if="datos.responsable">{{ datos.responsable.nombre + ' ' + datos.responsable.apellidos }}</span>
    </p>
    <p>
      <strong>Teléfono: </strong>
      <span v-if="datos.responsable">{{ datos.cliente.responsable_telefono }}</span>
    </p>
    <p>
      <strong>E-mail: </strong>
      <span v-if="datos.responsable">{{ datos.cliente.responsable_email }}</span>
    </p>
    <p>
      <strong>Asunto: </strong>
      Plan de Auditoria de la Norma de Calidad de los productos Ibéricos.
    </p>
    <p>Estimados Sres.:</p>
    <p>
      El motivo de esta carta es confirmar que la Auditoria inicial de Certificación de la Norma de Calidad para la Carne, el jamón, la paleta y la caña de lomo ibérico,
      aprobada por el Real Decreto 4/2014 de 10 de enero, a la empresa <strong>{{ datos.cliente && (datos.cliente.nombre + ' ' + datos.cliente.primer_apellido + ' ' + datos.cliente.segundo_apellido) }}</strong>,
      con nº de expediente Nº <strong v-if="datos.expediente">{{ datos.expediente.codigo }}</strong>,
      se realizará el próximo día <span>{{ formatDate(datos.desde) }}</span>; conforme al Plan de Auditoria adjunto a esta comunicación.
    </p>
    <p>El equipo auditor estará formado por: </p>
    <p>
      <strong>Auditores: </strong>
      <span v-if="datos.auditores">{{ auditores }}</span>
    </p>
    <p>
      Le recuerdo el derecho a recusar a los miembros del equipo auditor, en caso de no recibir indicación en contra, en el plazo de tres días, se considerará aceptada esta propuesta.
    </p>
    <p>
      Se comunica que la información proporcionada durante la auditoria será tratada a todos los efectos como confidencial.
    </p>
    <p>
      El horario contemplado en el Plan de Auditoria adjunto es orientativo pudiéndose modificar según las necesidades de la auditoria.
    </p>
    <p>
      Una vez planificada la auditoria y confirmadas las fechas por el cliente, en caso de producirse una cancelación o aplazamiento de la misma por parte
      del propio cliente en la semana anterior a la fecha de realización acordada, KAAM, se reserva el derecho de solicitar al cliente
      una evidencia que justifique la anulación de la auditoria. En estos casos, se emitirá una nota de cargo por un importe equivalente
      al 50% del precio presupuestado para la auditoría.
    </p>
    <p>
      Sin más que comunicarte, quedo a su disposición.
    </p>
    <p>
      Un cordial saludo.
    </p>
    <!-- Pagina 2 -->
    <div class="page-break-before"></div>
    <p style="font-weight: 700">
      1.- Documentación de Calidad:
    </p>
    <p>
      <ul>
        <li>R.D. 4/2014 por el que se aprueba la Norma de Calidad para la carne, el jamón, la paleta y la caña de lomo ibérico.</li>
        <li>Protocolos de actuación Norma de Calidad del Ibérico, II Certificación. Protocolo de certificación_08/05/17_E-1.</li>
        <li>PE CER 01: Procedimiento de trabajo para la realización de la auditoria de certificación de  la norma de calidad de los productos ibéricos.</li>
        <li>IT-02: Designación de los alcances del certificado.</li>
        <li>IT-03: Cumplimentación del Q.A. de auditoría.</li>
        <li>Procedimiento General de Certificación, PG-CER-03.</li>
        <li>Manual / Procedimiento / Instrucción del autocontrol de la empresa solicitante.</li>
      </ul>
    </p>
    <p style="font-weight: 700">
      2. Plan de Auditoria: (en los casos de operadores con varias instalaciones ver apartado 2.1.- Instalaciones, actividades y producto amparado y Anexo I)
    </p>
    <p>
      <ul>
        <li>Fecha: {{ formatDate(new Date()) }}</li>
        <li>
          Lugar:
          <span>{{ datos.cliente && (datos.cliente.nombre + ' ' + datos.cliente.primer_apellido + ' ' + datos.cliente.segundo_apellido) }}</span>
          <br>
          <span>{{ datos.cliente && datos.cliente.direccion }}</span>
          <br>
          RGSEAA:
          <span>
          {{ planificaciones_data && planificaciones_data.length && planificaciones_data[0] && planificaciones_data[0].rgseaa }}
          </span>
        </li>
      </ul>
    </p>
    <p style="font-weight: 700">
      12:00 – 12:15	Reunión Inicial.
    </p>
    <p>
      <ul>
        <li>Presentación del equipo auditor.</li>
        <li>Aclaraciones sobre el plan de auditoria y alcance.</li>
        <li>Resumen de las actividades de la auditoria.</li>
        <li>Confirmación de auditores e interlocutores.</li>
        <li>Confidencialidad.</li>
        <li>Condiciones para terminar / detener una auditoria.</li>
        <li>Apelaciones sobre la realización / conclusiones de la auditoria.</li>
      </ul>
    </p>
    <p style="font-weight: 700">
      12:15 – 15.00 y 16:00 – 18.00	Evaluación Empresa
      <span>{{ datos.cliente && (datos.cliente.nombre + ' ' + datos.cliente.primer_apellido + ' ' + datos.cliente.segundo_apellido) }}</span>
    </p>
    <p style="font-weight: 700">
      18:00 – 18:30	Revisión de las evidencias encontradas por el equipo auditor.
    </p>
    <p style="font-weight: 700">
      18:30 – 19:00	Reunión Final.
    </p>
    <!-- Pagina 3 -->
    <div class="page-break-before"></div>
    <p style="font-weight: 700">
      2.1.- Instalaciones, Actividades y Producto a Auditar
    </p>
    <table style="width: 100%" v-if="instalaciones.length">
      <thead>
        <tr style="text-align: center">
          <td style="font-weight-bold">Instalaciones</td>
          <td style="font-weight-bold">Verificaciones</td>
          <td style="font-weight-bold">Fecha</td>
          <td style="font-weight-bold">Hora</td>
        </tr>
      </thead>
      <tbody>
        <tr style="text-align: center" v-for="item in instalaciones" :key="item.id">
          <td>{{ item.razon_social }}</td>
          <td>
            <ul>
              <li style="font-weight: 400;">
                <span style="font-weight: 400;">Evaluaci&oacute;n documental o in situ (indicar) de los procedimientos solicitados en la solicitud de certificaci&oacute;n&nbsp;</span>
              </li>
              <li style="font-weight: 400;">
                <span style="font-weight: 400;">Auditoria in situ en las instalaciones del operador. Revisar solicitud de certificaci&oacute;n y certificado. Adjuntar copia.</span>
              </li>
              <li style="font-weight: 400;">
                <span style="font-weight: 400;">Funcionamiento sistema de autocontrol y de la competencia t&eacute;cnica respecto a los requisitos establecidos para la producci&oacute;n de los productos de la Norma de Calidad del Ib&eacute;rico descrito en los documentos normativos.</span>
              </li>
              <li style="font-weight: 400;">
                <span style="font-weight: 400;">Conformidad del producto. Trazabilidad y balance de masas.</span>
              </li>
              <li style="font-weight: 400;">
                <span style="font-weight: 400;">Evaluaci&oacute;n de la eficacia de las acciones correctivas implantadas para&nbsp; las desviaciones detectadas en auditorias anteriores, si aplica.</span>
              </li>
            </ul>
          </td>
          <td>{{ formatDate(datos.desde) }}</td>
          <td>12.00/19:00</td>
        </tr>
      </tbody>
    </table>
    <!-- Pagina 4 -->
    <div class="page-break-before"></div>
    <!-- Alcance Producto -->
    <h2 style="text-align: center; font-weight: 700">
      ANEXO I: EVALUACION EN PROVEEDORES DEL DISTRIBUIDOR
    </h2>
    <!-- Alcance Producto -->
    <table style="width: 100%">
      <thead>
        <tr style="text-align: center">
          <td style="font-weight-bold">Actividades</td>
        </tr>
      </thead>
      <tbody>
        <tr style="text-align: center" v-for="item in actividades" :key="item.id">
          <td>{{ item.nombre }}</td>
        </tr>
      </tbody>
    </table>
    <!-- Pagina 5 -->
    <div class="page-break-before"></div>
    <!-- Alcance Proceso Instalaciones -->
    <h2 style="text-align: center; font-weight: 700">
      ANEXO II: LISTADO DE PROVEEDORES
    </h2>
    <!-- Subcontratadas -->
    <table class="mb-4" style="width: 100%" v-if="datos.solicitud && datos.solicitud.params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="8" style="text-align: center; font-weight: 700">Subcontratadas</td>
        </tr>
        <tr v-if="datos.solicitud.params.actividades_proceso.subcontratadas.length">
          <td colspan="3" style="font-weight-bold">Proveedor del Servicio</td>
        </tr>
        <tr style="text-align: center" v-if="datos.solicitud.params.actividades_proceso.subcontratadas.length">
          <td colspan="1" style="font-weight-bold">Nombre</td>
          <td colspan="1" style="font-weight-bold">Dirección de la Instalación</td>
          <td colspan="1" style="font-weight-bold">N° RGSEAA</td>
          <td colspan="1" style="font-weight-bold">Actividad</td>
          <td colspan="1" style="font-weight-bold">Volumen de animales / canales /piezas / kg de carne</td>
          <td colspan="1" style="font-weight-bold">Actividad Subcontratada Certificada</td>
          <td colspan="1" style="font-weight-bold">Entidad de Certificación</td>
          <td colspan="1" style="font-weight-bold">Certificado de Conformidad y Anexo Técnico</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="datos.solicitud.params.actividades_proceso.subcontratadas.length">
          <tr style="text-align: center" v-for="item in datos.solicitud.params.actividades_proceso.subcontratadas" :key="item.id">
            <td colspan="1">{{ item.nombre }}</td>
            <td colspan="1">{{ item.direccion }}</td>
            <td colspan="1">{{ item.rgseaa }}</td>
            <td colspan="1">{{ item.actividad }}</td>
            <td colspan="1">{{ item.volumen }}</td>
            <td colspan="1">{{ (item.certificada ? 'SI' : 'NO') }}</td>
            <td colspan="1">{{ item.entidad }}</td>
            <td colspan="1">{{ item.conformidad_anexo }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="8" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table>
    <!-- Alquiladas -->
    <table style="width: 100%" v-if="datos.solicitud && datos.solicitud.params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="10" style="text-align: center; font-weight: 700">Alquiladas</td>
        </tr>
        <tr style="text-align: center" v-if="datos.solicitud.params.actividades_proceso.alquiladas.length">
          <td style="font-weight-bold">N° RGSEAA</td>
          <td style="font-weight-bold">Dirección de la Instalación</td>
          <td style="font-weight-bold">Actividad</td>
          <td style="font-weight-bold">Volumen de animales/canales/piezas/kg de carne</td>
          <td style="font-weight-bold">Solicita Certificación de Kaam</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="datos.solicitud.params.actividades_proceso.alquiladas.length">
          <tr style="text-align: center" v-for="item in datos.solicitud.params.actividades_proceso.alquiladas" :key="item.id">
            <td>{{ item.rgseaa }}</td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.actividad }}</td>
            <td>{{ item.volumen }}</td>
            <td>{{ (item.solicitda_certificacion ? 'SI' : 'NO') }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 0.5px solid black;
  }
  thead {
    display: table-row-group;
  }
  tr td {
    page-break-inside: avoid;
  }
</style>

<script>
import MODELS from "~/models/kaam/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    tipoOperador: MODELS.OPERADORES_TIPO,
    tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
    tipoProducto: MODELS.PRODUCTO_TIPO,
    tipoRacial: MODELS.RACIAL_TIPO,
    tipoEstado: MODELS.ESTADOS_TIPO,
    tipoPresentacion: MODELS.PRESENTACION_TIPO,
    tipoCertificacion: MODELS.CERTIFICACION_TIPO,
  }),
  computed: {
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    alcance () {
      return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
    },
    instalaciones () {
      const actividades = this.datos
        && this.datos.solicitud
        && this.datos.solicitud.params.alcance_producto.map(e => e.rgseaa)
        || []
      return this.datos.cliente.instalaciones.filter(e => actividades.includes(e.rgseaa))
    },
    actividades () {
      const planificaciones = this.datos.planificaciones
      const sites = this.datos.sites

      const planificacionesSites = planificaciones.map(e => e.site.uuid)

      const childrenSites = sites.filter(e => e.parent_uuid)

      return childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
      // return childrenSites.length
      //   ? childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
      //   : sites.filter(e => planificacionesSites.includes(e.uuid))
    },
    auditores () {
      const auditores = []
      const planificaciones = this.datos.planificaciones && this.datos.planificaciones
      if (planificaciones) {
        for (let i=0; i < planificaciones.length; i++) {
          const planificacion = planificaciones[i]
          if (!auditores.includes(planificacion.auditor)) auditores.push(planificacion.auditor)
        }
      }
      return auditores.join(', ')
    },
    planificaciones_data () {
      return this.datos && this.datos.planificaciones.map(element => element.data)
    },
    solicitud_data () {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach(element => {
          data[element.keyname] = element.value
        })
        return data
      }
      else return {}
    },
    site_data () {
      if (this.datos.isCliente) return this.datos.isCliente
      else if (this.datos.sites) {
        let data = {}
        let tipos = []

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
        }

        for (let i=0; i < tipos.length; i++) {
          const element = tipos[i]
          data[element] = []
        }

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          let obj = {}
          obj.uuid = element.uuid
          for (let k=0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data[element.tipo].push(obj)
        }

        return data
      }
      else return {}
    },
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
  }
}
</script>

