<template>
  <div>
    <v-card outlined>
      <v-card-title>
        Campos Personalizados
        <v-spacer/>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              fab
              depressed
              color="success"
              class="white--text"
              v-on="on"
              @click="dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear</span>
        </v-tooltip>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-0"
            hide-default-footer
            @click:row="editItem($event)"
          >
            <!-- Actions -->
            <template v-slot:item.actions="{ item }">
              <v-icon style="font-size: 20px" color="primary" @click.stop="deleteItem(item)">mdi-delete-outline</v-icon>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          Datos Campo
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Superficie Total"
                    v-model="item.superficie_total"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Edificado"
                    v-model="item.edificado"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Construcción Inicial"
                    v-model="item.cinicial"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Ampliaciones"
                    v-model="item.ampliaciones"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Situación"
                    v-model="item.situacion"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Casco Urbano"
                    v-model="item.curbano"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Polígono industrial"
                    v-model="item.pindustrial"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Terreno rústico"
                    v-model="item.trustico"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()">Cancelar</v-btn>
          <v-btn depressed color="primary" :disabled="!valid" @click="saveItem()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm -->
    <Confirmation
      v-model="confirmDelete"
      :text="textDelete || '¿Eliminar el objeto seleccionado?'"
      colorButton="error"
      @confirm="deleteItem()"
      @close="close()"
    />
  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  props: {
    value: { type: Array, default: () => [] },
  },
  components: {
    Confirmation,
  },
  data: () => ({
    valid: true,
    dialog: false,
    confirmDelete: false,
    textDelete: '',
    editedIndex: -1,
    datos: [],
    item: {},
    items: [],
    rules: [(v) => !!v || 'Obligatorio'],
    headers: [
      { text: 'Superficie Total', value: 'superficie_total', align: 'left' },
      { text: 'Edificado', value: 'edificado', align: 'left' },
      { text: 'Construcción Inicial', value: 'cinicial', align: 'left' },
      { text: 'Ampliaciones', value: 'ampliaciones', align: 'left' },
      { text: 'Situación', value: 'situacion', align: 'left' },
      { text: 'Casco Urbano', value: 'curbano', align: 'left' },
      { text: 'Polígono industrial', value: 'pindustrial', align: 'left' },
      { text: 'Terreno rústico', value: 'trustico', align: 'left' },
      { text: 'Acciones', value: 'actions', align: 'left', sortable: false },
    ],
  }),
  watch: {
    value() {
      console.log('watch', this.items)
      this.init()
    },
  },
  methods: {
    init() {
      this.items = this.value
    },
    emitChange() {
      this.$emit('input', this.items)
      this.$emit('change', this.items)
      this.$forceUpdate()
    },
    close() {
      this.dialog = false
      this.confirmDelete = false
      this.valid = true
      this.editedIndex = -1
      this.item = {}
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
    deleteItem(item) {
      if (item) {
        this.confirmDelete = true
        this.editedIndex = this.items.indexOf(item)
        this.textDelete = '¿Eliminar el campo personalizado seleccionado?'
      } else {
        this.items.splice(this.editedIndex, 1)
        this.close()
        this.emitChange()
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.item = Object.assign({}, item)
      this.dialog = true
    },
    saveItem() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          console.log('herere')
          Object.assign(this.items[this.editedIndex], this.item)
        } else {
          this.items.push(this.item)
        }
        this.close()
        this.emitChange()
      }
    },
  },
  created() {
    console.log('created')
    this.init()
  },
}
</script>
