export const state = () => ({
  list: [],
  item: {},
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list
  },
  SET_LIST(state, items) {
    this.list = items
  },
  GET_ITEM(state) {
    return this.item
  },
  SET_ITEM(state, item) {
    this.item = item
  },
}

//dispatch actions
export const actions = {
  async list({ commit }) {
    const res = await this.$axios.get('empresas' + '?owner.uuid=' + this.$auth.user.empresaOwner.uuid)
    if (res && res.data['hydra:member']) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },

  async get({ commit }, params) {
    const res = await this.$axios.get('empresas/' + params)
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async create({ commit }, params) {
    const res = await this.$axios.post('empresas', params)
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async update({ commit }, params) {
    const res = await this.$axios.patch('empresas/' + params.uuid, params, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async delete({ commit }, params) {
    const res = await this.$axios.delete('empresas/' + params.uuid)
    if (res) {
      commit('SET_ITEM', {})
      return res
    }
  },

  async updateLogo({ commit }, params) {
    const res = await this.$axios.post(
      'empresas/' + params.uuid + '/update-logo',
      params.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async getLogo({ commit }, params) {
    const res = await this.$axios.get('empresas/' + params + '/logo', { responseType: 'blob' })
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async addPaises({ commit }, params) {
    let body = {
      paises: params.paises,
    }
    const res = await this.$axios.post('empresas/' + params.uuid + '/add-paises', body)
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },
}
