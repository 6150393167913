<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-container>
          <v-data-iterator
            :items="items"
            hide-default-footer
          >
            <template slot="no-data">
              <div class="text-center">Listado vacío</div>
            </template>
            <template slot="no-results">
              <div class="text-center">No hay resultados</div>
            </template>
            <template v-slot:default="props">
              <v-container class="px-6 grey lighten-4">
                <v-row>
                  <v-col cols="12" sm="6" lg="4" xl="3" v-for="item in props.items" :key="item.id">
                    <v-card>
                      <v-card-title class="title">
                        {{ item.grupo_alcance && item.grupo_alcance.nombre }}
                      </v-card-title>
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col cols="12" class="py-1" v-for="key in Object.keys(item.variables)" :key="key.id">
                            
                            <v-checkbox
                              v-if="isBoolean(item.variables[key])"
                              v-model="item.variables[key]"
                              class="cursor-default mt-0"
                              color="info"
                              :ripple="false"
                              readonly
                              dense
                              hide-details
                            >
                              <template slot="label">
                                <span class="v-label theme--light text-capitalize">{{ key.replace('_', ' ') }}</span>
                              </template>
                            </v-checkbox>

                            <div v-else>
                              <span class="font-weight-bold text-capitalize">{{ key.replace('_', ' ') }}:</span>
                              {{ item.variables[key] }}
                            </div>

                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: []
  }),
  methods: {
    isBoolean(value) {
      return value === true || value === false
    },
    async init() {
      const body = {
        modulo: 'clientes',
        external_uuid: this.$route.params.uuid
      }
      const res = await this.$store.dispatch('custom_variables/list', body)
      if (res) {
        const data = res
        for (let i=0; i < data.length; i++) {
          const element = data[i]
          const booleans = {}
          const items = {}
          let variables = []
          const keys = Object.keys(element.variables)
          for (let k=0; k < keys.length; k++) {
            const key = keys[k]
            const value = element.variables[key]
            if (this.isBoolean(value)) booleans[key] = value
            else items[key] = value 
          }
          variables = {
            ...items,
            ...booleans
          }
          element.variables = variables
        }
        this.items = data
      }
    },
  },
  created () {
    this.init()
  }
}
</script>
