<template>
  <div v-if="isNotGroup">
    <span v-if="!hide">
      <v-icon
        style="font-size: 20px"
        color="primary"
        :disabled="!CAN_DELETE(params.node.data)"
        @click.stop="controlClick()"
        >mdi-delete-outline</v-icon
      >
    </span>

    <confirmation
      v-model="confirmDelete"
      :text="params.text || '¿Eliminar el objeto seleccionado?'"
      colorButton="error"
      @confirm="deleteItem()" />
  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  components: {
    Confirmation,
  },
  data: function () {
    return {
      confirmDelete: false,
    }
  },
  computed: {
    IS_LIST() {
      // console.log('hide', this.params)
      return !this.$route.params.uuid
    },

    isNotGroup() {
      // console.log('hide', this.params)
      return !!this.params.data
    },
    hide() {
      // console.log('hide', this.params)
      return this.params.value === true
    },
  },
  methods: {
    CAN_DELETE(item) {
      if (item?.empresa) {
        return (
          this.$store.state.permisos.can_delete &&
          item.empresa.uuid === this.$store.state.user.empresa.uuid
        )
      } else {
        return this.$store.getters.isAdminGroup && this.$store.state.permisos.can_delete
      }
    },
    init() {
      if (this.params.init instanceof Function) this.params.init()
    },
    controlClick() {
      if (this.params.dispatch || this.params.confirm) this.confirmDelete = true
      else this.deleteItem()
    },
    async deleteItem() {
      this.confirmDelete = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      let response = { status: 200 }
      if (this.params.dispatch && this.params.node.data.uuid) {
        response = await this.$store.dispatch(`${this.params.dispatch}`, this.params.node.data)
      }

      // if (response && response.status !== 403) {
      // Eliminamos la row del ag-grid y refrescamos
      const groupedItems = this.params.node.allLeafChildren
      // if (groupedItems && groupedItems.length) {
      //   for (let i = 0; i < groupedItems.length; i++) {
      //     const row = groupedItems[i]
      //     let indexRow = gridOptions.rowData.indexOf(row.data)
      //     gridOptions.rowData.splice(indexRow, 1)
      //   }
      // } else {
      //   let indexRow = gridOptions.rowData.indexOf(this.params.node.data)
      //   gridOptions.rowData.splice(indexRow, 1)
      // }
      // }

      //TODO: Revisar si es necesario
      this.$router.go()

      gridApi.refreshCells()
      this.init()
    },
  },
}
</script>
