<template>
  <div>
    <v-autocomplete
      :items="items"
      v-model="value"
      :placeholder="placeholder"
      :multiple="multiple"
      :clearable="multiple"
      @change="getValue(), multiple ? null : params.api.stopEditing()"
      @keydown.enter="multiple ? params.api.stopEditing() : null"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  data: () => ({
    value: null,
    placeholder: "Seleccionar",
    multiple: false,
    items: []
  }),
  methods: {
    getValue() {
      return this.value;
    },
    async getPais () {
      const { data } = await this.$axios.get("territorios/paises")
      const paises = data.map(element => ({ value: element, text: element.nombre}))
      // const paises = data.map(element => ({ value: element.uuid, text: element.nombre}))
      this.items = paises
    },
    async getProvincia () {
      if (!this.params.pais) {
        this.$store.commit("notification/show", {
          text: 'Debes seleccionar un país',
          color: 'error'
        })
      } else {
        const pais_uuid = this.params.pais.uuid
        // const pais_uuid = this.params.pais
        const { data } = await this.$axios.get(`territorios/paises/${pais_uuid}/provincias`)
        const provincias = data.map(element => ({ value: element, text: element.nombre}))
        // const provincias = data.map(element => ({ value: element.uuid, text: element.nombre}))
        this.items = provincias
      }
    },
    async getMunicipio () {
      if (!this.params.provincia) {
        this.$store.commit("notification/show", {
          text: 'Debes seleccionar una provincia',
          color: 'error'
        })
      } else {
        const provincia_uuid = this.params.provincia.uuid
        // const provincia_uuid = this.params.provincia
        const { data } = await this.$axios.get(`territorios/provincias/${provincia_uuid}/municipios`)
        const municipios = data.map(element => ({ value: element, text: element.nombre}))
        // const municipios = data.map(element => ({ value: element.uuid, text: element.nombre}))
        this.items = municipios
      }
    }
  },
  created() {
    this.value = this.params.value;
    if (this.params.items && this.params.items.length > 0) (this.items = this.params.items);
    if (this.params.placeholder) this.placeholder = this.params.placeholder;
    if (this.params.multiple) this.multiple = this.params.multiple;
    if (this.params.codigo === 'PAIS') this.getPais()
    if (this.params.codigo === 'PROVINCIA') this.getProvincia()
    if (this.params.codigo === 'LOCALIDAD') this.getMunicipio()
  }
};
</script>

<style scoped>
.v-text-field {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>