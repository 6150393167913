<template>
  <span>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn small dark depressed color="info" v-on="on" @click="isSelected()">
          Acciones
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="item in actions" :key="item.id" @click="controlClick($event, item)">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
  export default {
    data: () => ({
      actions: [
        { tipo: 'parcela', name: 'Cambio Superficie',  action: 'cambio_superficie', field: 'superficie_ha' },
        { tipo: 'parcela', name: 'Baja parcela', action: 'baja_parcela' },
      ]
    }),
    methods: {
      isSelected() {
        const row = this.params.node
        const selected = row.isSelected()
        if (!selected) row.setSelected(true)
      },
      controlClick($event, action) {
        if (this.params.controlClick instanceof Function) {
          const body = {
            ...action
          }
          this.params.controlClick(body)
        }
      }
    }
  }
</script>
