/**
 * @project: certiapp-nuxt
 * @file:    arrayHelper.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 6th May 2021
 * Last Modified: Fri Feb 25 2022
 **/

export default {
  //+-------------------------------------------------
  // sortBy()
  // Sorts an array of items by one keyed attribute
  // -----
  // Created on Thu May 06 2021
  //+-------------------------------------------------
  sortBy(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

      let comparison = 0
      if (varA > varB) {
        comparison = 1
      } else if (varA < varB) {
        comparison = -1
      }
      return order === 'desc' ? comparison * -1 : comparison
    }
  },

  //+-------------------------------------------------
  // keyData()
  // Returns an array of objects with the key and value
  // Structure used in sites
  // -----
  // Created on Fri Feb 25 2022
  //+-------------------------------------------------
  keyData(item) {
    let arr = {}

    for (const el of item) {
      arr[el.keyname] = el.value
    }

    return arr
  },
}
