<template>
  <div>
    <!-- String -->
    <v-text-field
      v-if="type === 'string'"
      v-model="string"
      @keyup.enter="getValue($event)"
    ></v-text-field>
    <!-- Integer -->
    <v-text-field
      v-if="type === 'integer'"
      v-model="integer"
      :error-messages="integerErrors"
      :maxlength="maxlength"
      :counter="maxlength"
      @input="$v.integer.$touch()"
      @blur="$v.integer.$touch()"
      @keyup.enter="getValue($event)"
    ></v-text-field>
    <!-- Float -->
    <v-text-field
      v-if="type === 'float'"
      v-model="float"
      :error-messages="floatErrors"
      @input="$v.float.$touch()"
      @blur="$v.float.$touch()"
      @keyup.enter="getValue($event)"
    ></v-text-field>
    <!-- Email -->
    <v-text-field
      v-if="type === 'email'"
      v-model="email"
      :error-messages="emailErrors"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
      @keyup.enter="getValue($event)"
    ></v-text-field>
    <!-- NIF -->
    <v-text-field
      v-if="documentation.includes(type)"
      v-model="nif"
      v-mask="nifMask"
      :suffix="checkDocument"
      :error-messages="nifErrors"
      maxlength="11"
      @input="$v.nif.$touch()"
      @blur="$v.nif.$touch()"
      @keyup.enter="getValue($event)"
    ></v-text-field>
    <!-- Phone -->
    <v-text-field
      v-if="type === 'phone'"
      v-model="phone"
      v-mask="telefonoMask"
      :error-messages="telefonoErrors"
      maxlength="9"
      counter="9"
      @input="$v.phone.$touch()"
      @blur="$v.phone.$touch()"
      @keyup.enter="getValue($event)"
    ></v-text-field>
    <!-- Postal Code -->
    <v-text-field
      v-if="type === 'cp'"
      v-model="cp"
      maxlength="5"
      counter="5"
      v-mask="codigoPostalMask"
      :error-messages="codigoPostalErrors"
      @input="$v.cp.$touch()"
      @blur="$v.cp.$touch()"
      @keyup.enter="getValue($event)"
    ></v-text-field>
  </div>
</template>

<style scoped>
.v-text-field {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
</style>

<script>
import { mask } from 'vue-the-mask' 
import { validationMixin } from 'vuelidate'
import { required, requiredIf, email, minLength, decimal, integer } from 'vuelidate/lib/validators'

export default {
  directives: { mask },
  mixins: [validationMixin],
  validations: {
    integer: { 
      integer, 
      minLength (val) { 
        return minLength(this.minlength)(val)
      },
      required: requiredIf(function (val) {
        return this.required
      })
    },
    float: {
      decimal,
      required: requiredIf(function (val) {
        return this.required
      })
    },
    email: {
      email,
      required: requiredIf(function (val) {
        return this.required
      })
    },
    nif: {
      minLength: minLength(7),
      required: requiredIf(function (val) {
        return this.required
      })
    },
    phone: {
      minLength: minLength(9),
      required: requiredIf(function (val) {
        return this.required
      })
    },
    cp: {
      minLength: minLength(5),
      required: requiredIf(function (val) {
        return this.required
      })
    }
  },
  data: () => ({
    codigoPostalMask: '#####',
    telefonoMask: '#########',
    nifMask: {
      mask: 'CCCCCCCCCCC',
      tokens: {
        '#': { pattern: /\d/ },
        'A': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        'C': { pattern: /[0-9a-zA-Z-]/, transform: v => v.toLocaleUpperCase() }
      }
    },
    type: 'string',
    required: false,
    maxlength: null,
    minlength: 0,
    string: null,
    float: null,
    integer: null,
    email: null,
    nif: null,
    phone: null,
    cp: null,
    documentation: ['nif', 'cif', 'dni']
  }),
  computed: {
    integerErrors () {
      const errors = []
      if (!this.$v.integer.$dirty) return errors
      !this.$v.integer.required && errors.push('Obligatorio')
      !this.$v.integer.integer && errors.push('Número inválido')
      !this.$v.integer.minLength && errors.push('Número inválido')
      return errors
    },
    floatErrors () {
      const errors = []
      if (!this.$v.float.$dirty) return errors
      !this.$v.float.required && errors.push('Obligatorio')
      !this.$v.float.decimal && errors.push('Número inválido')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push('Obligatorio')
      !this.$v.email.email && errors.push('Email inválido')
      return errors
    },
    nifErrors () {
      const errors = []
      if (!this.$v.nif.$dirty) return errors
      !this.$v.nif.required && errors.push('Obligatorio')
      !this.$v.nif.minLength && errors.push('Documento inválido')
      !this.nifValid && errors.push('Documento inválido')
      return errors
    },
    telefonoErrors () {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.required && errors.push('Obligatorio')
      !this.$v.phone.minLength && errors.push('Número inválido')
      return errors
    },
    codigoPostalErrors () {
      const errors = []
      if (!this.$v.cp.$dirty) return errors
      !this.$v.cp.required && errors.push('Obligatorio')
      !this.$v.cp.minLength && errors.push('Código postal inválido')
      return errors
    },
    nifValid () {
      if (this.nif) {
        let valid = false
        const passport = new RegExp('^[P](?:-)*[0-9]+$')
        const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
        const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
        const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
        const other1 = new RegExp('^[0-9]{7}$')
        const other2 = new RegExp('^[0-9]{8}$')
        const other3 = new RegExp('^[0-9]{9}$')
        const other4 = new RegExp('^[0-9]{11}$')
        if (passport.test(this.nif)) valid = true
        if (dni.test(this.nif)) valid = true
        if (nie.test(this.nif)) valid = true
        if (nif.test(this.nif)) valid = true
        if (other1.test(this.nif)) valid = true
        if (other2.test(this.nif)) valid = true
        if (other3.test(this.nif)) valid = true
        if (other4.test(this.nif)) valid = true
        return valid
      } else {
        return true
      }
    },
    checkDocument () {
      let document = 'OTRO'
      const passport = new RegExp('^[P](?:-)*[0-9]+$')
      const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
      const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
      const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
      const other1 = new RegExp('^[0-9]{7}$')
      const other2 = new RegExp('^[0-9]{8}$')
      const other3 = new RegExp('^[0-9]{9}$')
      const other4 = new RegExp('^[0-9]{11}$')
      if (passport.test(this.nif)) document = 'PASAPORTE'
      if (dni.test(this.nif)) document = 'DNI'
      if (nie.test(this.nif)) document = 'NIE'
      if (nif.test(this.nif)) document = 'NIF'
      return document
    }
  },
  methods: {
    getValue (event) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.type === 'string') return this.string
        if (this.type === 'integer') return this.integer
        if (this.type === 'float') return this.float
        if (this.type === 'email') return this.email
        if (this.documentation.includes(this.type)) return this.nif
        if (this.type === 'phone') return this.phone
        if (this.type === 'cp') return this.cp
      } else if (event) {
        event.stopPropagation()
      }
    },
    cellValue () {
      let value = this.params.value
      if (this.type === 'string') this.string = value
      if (this.type === 'integer') this.integer = value
      if (this.type === 'float') this.float = value
      if (this.type === 'email') this.email = value
      if (this.documentation.includes(this.type)) this.nif = value
      if (this.type === 'phone') this.phone = value
      if (this.type === 'cp') this.cp = value
    }
  },
  created () {
    if (this.params.required) this.required = this.params.required
    if (this.params.maxlength) this.maxlength = this.params.maxlength
    if (this.params.minlength) this.minlength = this.params.minlength
    if (this.params.type) this.type = this.params.type
    this.cellValue()
  }
}
</script>