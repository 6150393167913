export default {
  IBERICO: {
    REGISTRO_AMBITO_AUTONOMICO: [
      {text: 'Alcance de certificacion propio', value: 'propio'},
      {
        text: 'Alcance de certificacion de producto para Maquilas/Servicios a clientes',
        value: 'clientes',
      },
    ],

    PRODUCCION_TIPOS: ['propia', 'maquila'],

    SOLICITUD_TIPOS: [
      {text: 'Inicial', value: 'inicial'},
      {text: 'Modificación de alcance', value: 'modificacion'},
    ],

    TIPO_ALCANCE_CERTIFICACION: [
      {text: 'Alcance de certificacion propio', value: 'propios'},
      {
        text: 'Alcance de certificacion de producto para Maquilas/Servicios a clientes',
        value: 'clientes',
      },
    ],

    ANIMALES_HEADERS: [
      {headerName: 'Nº ANIMALES SACRIFICADOS/AÑO', field: 'stats_ano'},
      {headerName: 'Nº CANALES DESPIEZADAS/AÑO', field: 'stas_canales'},
      {headerName: 'KGS DE CARNE COMERCIALIZADOS/AÑO', field: 'stats_kgs'},
      {headerName: '(*) Nº PIEZAS ELABORADAS/AÑO', field: 'stats_piezas_elaboradas'},
      {headerName: 'Nº PIEZAS DISTRIBUIDAS/AÑO', field: 'stats_piezas_distribuidas'},
    ],
    PRODUCTOS_HEADERS: [
      {
        headerName: '#',
        sortable: true,
        filter: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        valueGetter: (params) => params.node.rowIndex + 1,
      },
      {headerName: 'Codigo instalación', field: 'instalacion_codigo'},
      {headerName: 'Nombre Instalacion', field: 'instalacion_nombre'},
      {headerName: 'Categoria RGSEAA', field: 'categoria'},
      {headerName: 'Actividad a evaluar', field: 'actividad'},
      {headerName: 'Marca', field: 'marca'},
      {headerName: 'Actividad a evaluar', field: 'actividad'},
      {headerName: 'Producto', field: 'producto'},
      {headerName: 'Alimentacion', field: 'alimentacion'},
      {headerName: 'Pieza', field: 'pieza'},
      {headerName: 'Racial', field: 'racial'},
      {headerName: 'Presentacion', field: 'presentacion'},
      {headerName: 'Manejo', field: 'manejo'},
    ],
    PRODUCTOS_MAQUILA_HEADERS: [
      {
        headerName: '#',
        sortable: true,
        filter: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        valueGetter: (params) => params.node.rowIndex + 1,
      },
      // { headerName: 'Instalación', field: 'razon_social' },
      // { headerName: 'Localidad', field: 'localidad' },
      // { headerName: 'RGSEAA', field: 'rgseaa' },
      {headerName: 'Codigo instalación', field: 'instalacion_codigo'},
      {headerName: 'Nombre Instalacion', field: 'instalacion_nombre'},
      {headerName: 'Categoria RGSEAA', field: 'categoria'},
      {headerName: 'Actividad a evaluar', field: 'actividad'},
      {headerName: 'Marca', field: 'marca'},
      {headerName: 'Actividad a evaluar', field: 'actividad'},
      {headerName: 'Producto', field: 'producto'},
      {headerName: 'Alimentacion', field: 'alimentacion'},
      {headerName: 'Pieza', field: 'pieza'},
      {headerName: 'Racial', field: 'racial'},
      {headerName: 'Presentacion', field: 'presentacion'},
      {headerName: 'Manejo', field: 'manejo'},
    ],
    CATEGORIAS: [
      'Matadero porcino',
      'Sala de despiece',
      'Salado y secado de jamones',
      'Fabricacion elaboración y transformación',
      'Almacenamiento',
      'Distribución',
      'Envasado',
    ],
    ACTIVIDADES: [
      'SACRIFICIO',
      'DESPIECE',
      'ELABORACIÓN',
      'TRANSFORMACIÓN',
      'DESHUESADO',
      'TROCEADO',
      'FRACCIONADO',
      'LONCHEADO',
      'ENVASADO',
      'ETIQUETADO',
      'ALMACENAMIENTO',
      'DISTRIBUCIÓN',
      'COMERCIALIZACIÓN',
      'EXPEDICIÓN',
      'RE-ETIQUETADO',
    ],
    ACTIVIDADES_SUBCONTRATADAS: [
      'SACRIFICIO',
      'DESPIECE',
      'ELABORACIÓN',
      'TRANSFORMACIÓN',
      'DESHUESADO',
      'TROCEADO',
      'FRACCIONADO',
      'LONCHEADO',
      'ENVASADO',
      'ETIQUETADO',
      'ALMACENAMIENTO',
      'EXPEDICIÓN',
      'COMERCIALIZACIÓN',
    ],
    PRODUCTOS_TIPO: ['Curado', 'Semicurado', 'Fresco'],
    PRODUCTOS_PIEZA: ['Carnes frescas', 'Jamón', 'Paleta', 'Lomo'],
    PRODUCTOS_ALIMENTACION: ['Bellota', 'Cebo de campo', 'Cebo'],
    PRODUCTOS_RACIAL: ['100% Ibérico', 'Ibérico'],
    PRODUCTOS_PRESENTACION: [
      'Piezas enteras',
      'Fileteado',
      'Piezas transformadas - Deshuesado',
      'Piezas transformadas - Troceado/Porciones',
      'Piezas transformadas - Loncheado',
    ],
    TIPOS_INSTALACION: {
      propia: 'propia',
      alquilada: 'alquilada',
      subcontratada: 'subcontratada',
      maquila: 'maquila',
    },
  },
}
