<template>
  <div>
    <SelectTipoEstaticos
      label=''
      v-model='value'
      :subalcance='params.subalcance'
      item-text='codigoNombre'
      item-value='uuid'
      placeholder='Seleccione'
      dense
      @loaded='onLoadedItems($event)'
    />
  </div>
</template>

<script>

import SelectTipoEstaticos from '~/components/forms/SelectTipoEstaticos.vue'

export default {
  components: { SelectTipoEstaticos },
  data: () => ({
    value: null,
    subalcance: '',
    items: [],
  }),
  methods: {
    getValue() {
      return this.items.find(item => item.uuid === this.value)
    },

    onLoadedItems(items) {
      this.items = items;
    },
  },

  created() {},
}
</script>

<style scoped>
.v-text-field {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
