<template>
  <v-dialog eager v-model="ui.dialog" width="800" persistent>

    <v-card>
      <v-row class="ma-0" v-show="!ui.generated">
        <v-col id="signaturePad-wrapper">
          <canvas id='signaturePad'
            ref="signaturePad"
            class="signaturePad"
            style="opacity: 0"></canvas>
        </v-col>
      </v-row>

      <v-card-text class="px-7">
        <v-form ref="form" lazy-validation v-model="ui.valid" style="height: 100%">
          <v-row>
            <v-btn
            class="mt-5"
              color="success"
              block depressed
              @click="submit">
              {{ options.type == 'croquis' ? 'Guardar' : 'Firmar' }}
            </v-btn>
            <template v-if="ui.showErrorEmpty">
              <div class="error--text">
                <v-icon color="red" style="font-size: 20px; margin: 7px;">mdi-close</v-icon>
                No se ha encontrado ninguna firma
              </div>
            </template>

          <!-- <p>
            Realiza la firma que quieres utilizar<br>
            Esta firma se almacenará de forma segura y se asociará al usuario
          </p> -->

          <!-- <template v-if="false || !isClient && latest.length > 0">
            <v-col cols="11" class="mx-auto my-3">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" sm="4"
              v-for="(recent, i) in latest"
              :key="'latest'+i">
              <v-list-item @click="close()">
                <v-list-item-content>
                  <img style="max-width: 80%; margin: 0 auto;" >
                  <span style="color: rgb(146, 146, 146);
                    font-size: 12px !important;
                    white-space: nowrap;
                    padding: 0 23px !important;">
                    El {{ $moment(recent.created_at).format('D [de] MMMM, YYYY') }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            </template> -->
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions v-if="!ui.loading">
        <v-btn
          text small
          color="error"
          @click="close()">
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="deep-purple accent-4"
          text small
          @click="cleanSignaturePad">
          {{ options.type == 'croquis' ? 'Limpiar' : 'Limpiar y volver a firmar' }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/documentsUploader.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 13th July 2021
 * Last Modified: Fri May 05 2023
 **/

import SignaturePad from 'signature_pad'
import MODULOS from '~/models/modulos'

export default {
  name: 'Signatures',

  data: () => ({
    user: {},

    signaturePad: null,
    signatures: {
      primary: {},
      secondary: {},
    },

    options: {},

    db: {
      latest: [],
      modulos: [],
    },

    ui: {
      valid: false,
      dialog: false,
      loading: false,
      generated: false,
      showErrorEmpty: false,

      $canvas: null,
      $$wrapper: null,
    },
  }),

  computed: {
    isClient() {
      return this.options?.typeOfUser == 'cliente' || this.$store.getters.isCliente
    },

    //+-------------------------------------------------
    // latest()
    // Last 3 signatures
    // -----
    // Created on Mon Aug 29 2022
    //+-------------------------------------------------
    latest() {
      return this.db.latest.slice(0, 3)
    },
  },

  methods: {
    open(payload) {
      this.options = { ...payload }
      if (payload.isNew == true) {
        delete this.options.isNew
        delete this.options.modulo
        delete this.options.moduloRef
      }

      this.ui.dialog = true
      this.ui.showErrorEmpty = false

      this.startSignaturePad()
    },

    close() {
      this.files = []
      this.options = {}

      this.ui.dialog = false
      this.ui.loading = false
      this.ui.generated = false

      this.ui.$canvas.style.opacity = 0

      // No form validations for the moment
      // this.$refs.form.reset()
    },

    //+-------------------------------------------------
    // startSignaturePad()
    // Initializes external plugin
    // -----
    // Created on Fri Jul 22 2022
    //+-------------------------------------------------
    startSignaturePad() {
      setTimeout(() => {
        this.ui.$wrapper = document.getElementById('signaturePad-wrapper')
        this.ui.$canvas = document.getElementById('signaturePad')

        this.signaturePad = new SignaturePad(this.ui.$canvas, {
          penColor: 'rgb(0, 0, 0)',
        })

        this.resizeCanvas()
      }, 200)
    },

    //+-------------------------------------------------
    // cleanSignaturePad()
    // Resets the signature pad
    // -----
    // Created on Fri Sep 02 2022
    //+-------------------------------------------------
    cleanSignaturePad() {
      this.signaturePad.clear()
    },

    resizeCanvas() {
      const ratio = Math.max(window.devicePixelRatio || 1, 1)
      const wrapperWidth = this.ui.$wrapper.offsetWidth - 28 // 12px padding + 2px border
      const wrapperHeight = this.options.type == 'croquis' ? 400 : 250 // this.ui.$wrapper.offsetHeight + 30

      this.ui.$canvas.width = wrapperWidth * ratio
      this.ui.$canvas.height = wrapperHeight * ratio
      this.ui.$canvas.getContext('2d').scale(ratio, ratio)
      // console.warn('Canvas resized', this.ui.$canvas.width, this.ui.$canvas.height, ratio)

      this.signaturePad.clear() // otherwise isEmpty() might return incorrect value

      this.ui.$canvas.style.opacity = 1
    },

    //+-------------------------------------------------
    // signatureToFile()
    // Converts base64 data url to blob object,
    // apparently this can't be done on backend 🤷‍♀️
    // https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
    // -----
    // Created on Tue Aug 23 2022
    //+-------------------------------------------------
    signatureToFile() {
      let dataURI = this.signaturePad.toDataURL('image/png')

      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(',')[1])

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      //Old Code
      //write the ArrayBuffer to a blob, and you're done
      //var bb = new BlobBuilder();
      //bb.append(ab);
      //return bb.getBlob(mimeString);

      //New Code
      return new Blob([ab], { type: mimeString })
    },

    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    async submit() {
      let jxr = false
      let step = false

      // 1 . Form validations
      if (this.validate()) step = 'valid'

      // 2. Api requests
      if (step === 'valid') {
        jxr = await this.store()
      }

      // Finally
      if (step === 'valid' && jxr !== false) {
        this.$nuxt.$emit('signatures:signed', {
          data: jxr,
          payload: this.options.payload,
        })

        this.close()
      }
    },

    //+-------------------------------------------------
    // validate()
    // Checks that the signaturepad is not empty
    // -----
    // Created on Mon Aug 22 2022
    //+-------------------------------------------------
    validate() {
      let isValid = !this.signaturePad.isEmpty()
      if (!isValid) this.ui.showErrorEmpty = true

      return isValid
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async store() {
      let body = { ...this.options }
      body.file = this.signatureToFile()
      body.base64 = this.signaturePad.toDataURL('image/png')

      if (!body.created_by) body.created_by = this.user.uuid

      try {
        const xhr = await this.$store.dispatch('signatures/create', body)
        return { ...body, ...xhr }
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error generando la firma',
          color: 'error',
          timeout: 3000,
        })

        return false
      }
    },

    loadModulos() {
      this.db.modulos = MODULOS
    },

    async getLatestSignatures() {
      let xhr = await this.$store.dispatch('signatures/latest', this.user.uuid)
      if (xhr) {
        this.db.latest = xhr
      }
    },

    async init() {
      this.user = this.$auth.user

      this.loadModulos()
      this.getLatestSignatures()

      this.$nuxt.$on('signatures:open', (payload) => {
        this.open(payload)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('signatures:open')
  },
}
</script>

<style>
.v-dialog.v-dialog--active::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-thumb {
  background-color: #00000069;
  border-radius: 15px;
  border: 1px solid #a5a5a5;
}

.signaturePad {
  border-radius: 5px;
  background: #eceded;
  border: 3px solid #cccccc !important;
  transition: all 0.7s ease-in-out;
}
</style>
