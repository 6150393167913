<template>
    <div>
      <v-chip
        v-if="value"
        class="cursor-pointer"
        outlined
        :style="`border-color: ${getColor} !important; color: ${getColor} !important;`"
      >
        {{ value.nombre }}
      </v-chip>
    </div>
  </template>
  
  <script>

  export default {
    data: () => ({
    }),
    computed: {
      isGrouped () {
        return this.params.colDef.headerName === 'Group'
      },
      getColor() {
        const data = this.params.data
        console.info('getColor', this.params);
        //if data has a color property, return it
        if (data?.subalcance?.color) {
          return data.subalcance.color
        }
        //if data has auditoria property, return its color from auditoria.subalcance.color
        if (data?.auditoria?.expediente?.subalcance?.color) {
          return data.auditoria.expediente.subalcance.color
        }
        //if data has expediente property, return its color from expediente.subalcance.color
        if (data?.expediente?.subalcance?.color) {
          return data.expediente.subalcance.color
        }

        return 'orange'
      },
      value() {
        return this.isGrouped ? this.params.node.groupData['ag-Grid-AutoColumn'] : this.params.value
      }
    },
    created () {
      // console.info(this.params);
    }
  }
  </script>
  
  