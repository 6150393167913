import {uuid} from 'vue-uuid'

export const state = () => ({
  list: [],
  item: {}
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list
  },
  SET_LIST(state, items) {
    this.list = items
  }
};


//dispatch actions
export const actions = {

  async list({commit}) {
    const res = await this.$axios.put(`presupuesto`)
    if (res) {
      commit('SET_LIST', res,data)
      return res.data
    }
  },

  async get({commit}, uuid) {
    const res = await this.$axios.get(`presupuesto/${uuid}`)
    if (res) return res.data
  },

  async new({commit}, solicitud_alcance) {
    let new_uuid = uuid.v4()
    await this.$axios.post(`presupuesto`, {
      uuid: new_uuid,
      solicitud_alcance: solicitud_alcance.uuid
    } )
    return new_uuid
  },

  async update({commit}, presupuesto) {
    await this.$axios.patch(`presupuestos/${presupuesto.uuid}`, { } )
  },

  async delete({commit}, uuid) {
    await this.$axios.delete(`presupuestos/${uuid}`)
  }

};
