<template>
  <div>
    <table style="width: 100%; border:1px solid white !important;">
      <tbody>
        <tr style="border:1px solid white; !important;">
          <td style="text-align: center">
            <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px">
            <h2 style="font-weight: 700">araporcei</h2>
          </td>
        </tr>
      </tbody>
    </table>

   <table style="border-collapse: collapse; width: 100%; height: 105px;" border="1">
    <tbody>
    <tr style="height: 21px;">
    <td style="width: 68.8008%; height: 21px; border-width: 1px; border-style: solid; border-color: white black white white; border-image: initial;"><strong>{{ clienteNombreCompleto }}</strong></td>
    <td style="width: 29.1822%; height: 21px; text-align: center;" colspan="2"><strong>REGISTRO DOCUMENTOS</strong></td>
    </tr>
    <tr style="height: 21px;">
      <strong>{{ datos.cliente.contacto.localidad.nombre }},</strong>
    <td style="width: 68.8008%; height: 21px; border-width: 1px; border-style: solid; border-color: white black white white; border-image: initial;"><strong>{{ datos.cliente.direccion }}</strong></td>
    <td style="width: 18.334%; height: 21px;"><strong><input type="checkbox" /> ENTRADA</strong></td>
    <td style="width: 10.8482%; height: 21px;"><strong><input type="checkbox" /> SALIDA</strong></td>
    </tr>
    <tr style="height: 21px;">
    <td style="width: 68.8008%; height: 21px; border-width: 1px; border-style: solid; border-color: white black white white; border-image: initial;"><strong>{{ clienteDireccion1 }}</strong></td>
    <td style="width: 29.1822%; height: 63px;" colspan="2" rowspan="3"><strong>N&deg; REGISTRO:{{datos.codigo}}</strong><br /><strong>FECHA:{{ formatDate(datos.created_at) }}&nbsp; &nbsp; <input type="checkbox" /> ANEJOS</strong><br /><strong>ARCHIVO:</strong></td>
    </tr>
    <tr style="height: 21px;">
    <td style="width: 68.8008%; height: 21px; border-width: 1px; border-style: solid; border-color: white black white white; border-image: initial;"><strong>{{ clienteDireccion2 }}</strong></td>
    </tr>
    <tr style="height: 21px;">
    <td style="width: 68.8008%; height: 21px; border-width: 1px; border-style: solid; border-color: white black white white; border-image: initial;"><strong>A/A: {{ clienteNombreCompleto }}</strong></td>
    </tr>
    </tbody>
  </table>
    <p>
      <br>
    </p>
    <p style="text-align: right;">
      <span style="font-weight: 400;">{{ formatDateLong(datos.created_at) }}</span>
    </p>

    <p>
      <strong>ASUNTO: TRAMITACION DE SU SOLICITUD Y APERTURA DEL N&Uacute;MERO DE EXPEDIENTE DE&nbsp;
        CERTIFICACION&nbsp;</strong>
    </p>
    <p>
      <span style="font-weight: 400;">Estimado Sr.:&nbsp;&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">Conforme a nuestro procedimiento de certificaci&oacute;n, le comunicamos
        que:&nbsp;</span>
    </p>
    <p>1&ordm;.- Se ha recibido debidamente cumplimentada y firmada, la Solicitud de Certificaci&oacute;n y Contrato de
      su empresa, <strong>{{clienteNombreCompleto}}</strong>, para la certificaci&oacute;n de producto
      conforme al Pliego de Condiciones de la Especialidad Tradicional Garantizada ETG &ldquo;Jam&oacute;n
      Serrano&rdquo; seg&uacute;n Reglamento CE 1204/2008.</p>
    <p><strong>&nbsp;</strong></p>
    <p>2&ordm;.- Que Araporcei procede a asignarle un N&uacute;mero de Expediente para iniciar el proceso de
      certificaci&oacute;n. En su caso este n&uacute;mero de expediente es:</p>
    <p>
      <table style="border-collapse: collapse; width: 100%; height: 50px; border-color: black; background-color: HotPink" border="1">
<tbody>
<tr style="height: 50px;">
<td style="width: 98.7784%; height: 87px;">
<h2 style="text-align: center;"><strong>N&Uacute;MERO DE EXPEDIENTE: {{ datos.codigo }}</strong></h2>
</td>
</tr>
</tbody>
</table>
<p>Este N&uacute;mero de Expediente permanecer&aacute; a lo largo de toda la relaci&oacute;n contractual con Araporcei y se utilizara de acuerdo a lo previsto en nuestro Procedimiento General de uso de la marca, una vez otorgado el Certificado de Conformidad de Producto.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>Sin otro particular reciba un cordial saludo.</p>
<br><br><br>
    <p>
      <span style="font-weight: 400;">Mar&iacute;a Silvestre&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">Secretaria T&eacute;cnica. Araporcei. </span>
    </p>
<hr/>
<p style="text-align: center;"><strong>araporcei SL</strong></p>
<p style="text-align: center;">Tel. 954647423 Fax 954644416 Email: secretec@araporcei.es</p>
<p style="text-align: center;">C/ Gabriel Miro 3. Edificio Wertice M&oacute;dulos 1 y 2 41703 Dos Hermanas. Sevilla</p>
<p style="text-align: center;">www.araporcei.es</p>

  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 0.5px solid black;
  }

  .pink-light-araporcei {
    background-color: #EC81B3
  }

  @media print {
    .pink-light-araporcei {
      background-color: #EC81B3 !important;
      -webkit-print-color-adjust: exact !important;
    }
  }
</style>

<script>
  import MODELS from "~/models/araporcei/solicitudes"

  export default {
    props: {
      datos: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      tipoProducto: MODELS.PRODUCTO_TIPO,
    }),
    computed: {
      clienteNombreCompleto() {
        let nombre = ''
        const cliente = this.datos.cliente
        if (cliente) {
          if (cliente.nombre) nombre += cliente.nombre
          if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
          if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
        }
        return nombre
      },
      clienteDireccion1() {
        let direccion = ''
        const cliente = this.datos.cliente
        const cp = this.datos.cliente.cp
        const municipio = this.datos.cliente.municipio && this.datos.cliente.municipio.nombre
        if (cliente) {
          if (cp) direccion += cp
          if (direccion.length && municipio) direccion += ', '
          if (municipio) direccion += municipio
        }
        return direccion
      },
      clienteDireccion2() {
        let direccion = ''
        const cliente = this.datos.cliente
        const provincia = this.datos.cliente.provincia && this.datos.cliente.provincia.nombre
        const pais = this.datos.cliente.pais && this.datos.cliente.pais.nombre
        if (cliente) {
          if (provincia) direccion += provincia
          if (direccion.length && pais) direccion += ', '
          if (pais) direccion += pais
        }
        return direccion
      },
      productos() {
        const productos = []
        const alcance_producto = this.datos.solicitud.params.alcance_producto
        if (alcance_producto) {
          for (let i = 0; i < alcance_producto.length; i++) {
            const element = alcance_producto[i]
            const tipo_producto = this.tipoProducto[element.producto]
            if (!productos.includes(tipo_producto)) productos.push(tipo_producto)
          }
        }
        if (productos.length) {
          const start = productos.slice(0, productos.length - 1)
          const end = productos[productos.length - 1]
          return start.join(', ') + ' y ' + end
        } else return ''
      }
    },
    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : ''
      },
      formatDateLong(date) {
        return date ? this.$moment(date).format('LL') : ''
      },
    }
  }
</script>
