/**
 * @project: certiapp-nuxt
 * @file:    offline.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 2nd November 2021
 * Last Modified: Wed Jul 12 2023
 **/

import qs from 'qs'
import localforage from 'localforage'
import { setupCache } from 'axios-cache-adapter'

// regex of the route whitelist that I WANT to stay in the cache
const WHITELIST = [
  /alcances|auditores|clientes|firmas|listas-validacion|auditorias|expedientes|grupo-solicitudes|plantillas-checklist|checklists|bloques|plantilla-bloques|estaticos|tramites|tomas_muestra/,
]

let CACHE
let forageStore

//+-------------------------------------------------
// State
// Array of data held by the store
// Updated via mutations and accessible with getters
// ---
// cache and queue are stored in localForage
//+-------------------------------------------------
export const state = () => ({
  cache: {
    get: {},
    put: {},
  },

  queue: [],
  caching: false,
})

//+-------------------------------------------------
// Mutations
// The only way to actually change state in a Vuex
// store is by committing a mutation.
//+-------------------------------------------------
export const mutations = {
  enableCache(state, value) {
    console.warn('✍ [offline.js] enableCache', value)
    state.caching = value
  },

  //+-------------------------------------------------
  // snapshot()
  // Mutation bridge, called from axios.js and calls
  // for a nuxt event
  // -----
  // Created on Tue Mar 08 2022
  //+-------------------------------------------------
  snapshot(state) {
    $nuxt.$emit('offline:snapshot')
  },

  //+-------------------------------------------------
  // snapshotPage()
  // Components listening to snapshot event will
  // call snapshotPage with their data
  // -----
  // Created on Tue Mar 08 2022
  //+-------------------------------------------------
  snapshotPage(state, params) {
    let path = $nuxt.$route.path
    params.time = new Date().getTime()

    console.warn('📷 Snapshotting', path, params)
    localforage.setItem(path, params)
  },

  resetCache(state) {
    // state.cache.get = {}
    // state.cache.put = {}
    // localforage.removeItem('cache')
    // localforage.removeItem('my-cache')
  },

  //+-------------------------------------------------
  // addToQueue()
  // Add requests to queue
  // Do not cache requests that are not POST, PATCH or DELETE
  // -----
  // Created on Tue Nov 09 2021
  //+-------------------------------------------------
  addToQueue(state, item) {
    if (['post', 'patch', 'delete'].indexOf(item.method) == -1) return

    if (item.data instanceof FormData) {
      let JSONdata = {}
      for (var pair of item.data.entries()) {
        JSONdata[pair[0]] = pair[1]
      }

      item.data = JSONdata
      item.is = 'formdata'
    }

    console.log('💾 queued request', item.method, item.url, item)
    state.queue.push({
      is: item.is,
      url: item.url,
      data: item.data,
      synced: false,
      method: item.method,
    })

    localforage.setItem('queue', state.queue)
  },

  removeFromQueue(state, item) {
    state.queue.splice(state.queue.indexOf(item), 1)
  },

  resetQueue(state) {
    state.queue = []
    localforage.removeItem('queue')
  },

  removeItem(state, item) {
    localforage.removeItem('/' + item)
    forageStore.removeItem(item)
  },

  //+-------------------------------------------------
  // loadLocalData()
  // Loads data from localforage and sets the store
  // -----
  // Created on Wed Nov 10 2021
  //+-------------------------------------------------
  loadLocalData(state, item) {
    console.log('💾 Loading local data')

    localforage.getItem('queue', (err, value) => {
      if (err) {
        console.error('💾 Error loading local data', err)
        return
      }

      if (value) {
        console.log('Found data for queue', value)
        state.queue = value
      }
    })
  },

  clearLocalData(state) {
    console.log('💾 Clearing local data')
    localforage.clear()
    forageStore.clear()
  },
}

//+-------------------------------------------------
// Getters
// Actions that to get modified state
// Getters receive the state as their 1st argument
// also receive other getters as the 2nd argument
// -----
// Accesed with $vm0.$store.getters['store/getter']
// https://vuex.vuejs.org/guide/getters.html#property-style-access
//+-------------------------------------------------
export const getters = {
  isOffline(state) {
    let $nuxt = $nuxt || undefined
    if (!$nuxt || $nuxt === undefined) return false
    return $nuxt !== undefined ? $nuxt.isOffline : false
  },

  caching(state) {
    return state.caching
  },

  getCache: (state) => {
    return state.cache
  },

  getQueue: (state) => {
    return state.queue
  },
}

export const actions = {
  //+-------------------------------------------------
  // initAxios()
  // Initialize localforage and append as axios driver
  // -----
  // Created on Thu Jan 13 2022
  //+-------------------------------------------------
  async initAxios({ rootState }, params) {
    // await localforage.defineDriver(memoryDriver)
    forageStore = localforage.createInstance({
      driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        //memoryDriver._driver
      ],
      // Prefix all storage keys to prevent conflicts
      name: 'my-cache',
    })

    CACHE = setupCache({
      store: forageStore,
      maxAge: 60 * 2 * 60 * 1000, // 2h
      exclude: {
        query: false,
        filter: (req) => filterCacheRequests(req),
        methods: ['post', 'patch', 'delete'],
      },

      key: (req) => {
        // req.url + serializeQuery(req.params),
        const base = this.$axios.defaults.baseURL
        return req.url.replace(base, '') + paramsSerializer(req.params)
      },
      // ignoreCache: true,
      // debug: true,
    })

    // console.warn(this.$axios.defaults, cache)
    this.$axios.defaults.adapter = CACHE.adapter
  },

  toggleAdapter({ rootState }, params) {
    CACHE = setupCache({
      store: forageStore,
      maxAge: 60 * 2 * 60 * 1000, // 2h
      exclude: {
        query: false,
        filter: (req) => filterCacheRequests(req),
        methods: ['post', 'patch', 'delete'],
      },

      key: (req) => {
        // req.url + serializeQuery(req.params),
        const base = this.$axios.defaults.baseURL
        return req.url.replace(base, '') + paramsSerializer(req.params)
      },
      ignoreCache: params,
      // debug: true,
    })

    // console.warn(CACHE.config)
    this.$axios.defaults.adapter = CACHE.adapter
  },

  //+-------------------------------------------------
  // getCache()
  // Get cached data from localforage
  // -----
  // Created on Tue Feb 01 2022
  //+-------------------------------------------------
  async getCache({ commit, state }, key) {
    const val = await forageStore.getItem(key).then(function (value) {
      return value
    })

    if (val) console.warn('💾 getCached', key, val)

    return val
  },

  async getSnapshot({ commit, state }, key) {
    const val = await localforage.getItem(key).then(function (value) {
      return value
    })

    console.warn('📸 getSnapshot', key, val)
    return val
  },
}

//+-------------------------------------------------
// paramsSerializer()
// Function to call qs.stringify() and serialize params
// -----
// Created on Tue Feb 01 2022
//+-------------------------------------------------
const paramsSerializer = (params) => {
  return qs.stringify(params)
}

//+-------------------------------------------------
// filterCacheRequests()
// Determines if a request should be cached
// Return true is skip, false is cache
// -----
// Created on Wed Feb 02 2022
//+-------------------------------------------------
function filterCacheRequests(req) {
  if ($nuxt.$store.getters['offline/caching']) {
    return false
  }

  if (!$nuxt.$store.getters['offline/caching'] && !$nuxt.$store.getters['offline/isOffline']) {
    // console.warn('[offline.js] Not caching because the app is online and not flagged')
    return true
  }
  console.warn(
    '🍭 filterCacheRequests',
    $nuxt.$store.getters['offline/caching'],
    $nuxt.$store.getters['offline/isOffline']
  )
  const { url } = req
  const urlMapped = WHITELIST.map((pattern) => {
    const patternRegexp = new RegExp(pattern)
    return patternRegexp.test(url)
  })

  let shouldCached = urlMapped.includes(true)

  console.warn('shouldCached', url, shouldCached, req)
  return !shouldCached
}
