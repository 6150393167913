<template>
  <v-select ref="select" :label="label" v-model="item" :items="items" :item-text="itemText" :item-value="itemValue"
    :required="required" :rules="rules" :disabled="disabled" :readonly="readonly" :chips="chips" :small-chips="smallChips"
    :dense="dense" :outlined="outlined" :clearable="clearable" :hide-details="hideDetails" @click.stop="() => { }"
    @input="$emit('input', $event)" @change="$emit('change', $event)"></v-select>
</template>

<script>

export default {
  name: 'SelectTipoEstaticos',
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: 'Seleccionar opción' },
    subalcance: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
    itemText: { type: String, default: 'texto' },
    itemValue: { type: String, default: 'texto' },
  },

  data(ctx) {
    return {
      item: ctx.value,
      items: [],
    }
  },
  /*watch: {
    value(val) {
      if (!val || val === '') this.$refs.select.reset()
      // this.item = val
    },
  },*/

  computed: {
    rules() {
      if (this.required) {
        return [(v) => !!v || 'Obligatorio']
      } else return []
    },

    /*selectedItem() {
      return this.items.find((e) => e[this.itemValue] == this.item)
    },*/
  },

  methods: {
    async getEstaticos() {
      const params = {
        itemsPerPage: 50,
      }
      this.items = []
      if (this.subalcance) params['subalcance.uuid'] = this.subalcance
      let jxr = await this.$store.dispatch('estaticos/list', params)
      if (jxr) {
        this.items = jxr.data['hydra:member'];
        this.items.forEach((item) => {
          if (!item.codigoNombre) item.codigoNombre = item.codigo
        })
        this.$emit('loaded', this.items)
      }
    },

    async init() {
      await this.getEstaticos()
    },
  },

  mounted() {
    this.init()
  },
}
</script>
