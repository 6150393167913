/**
 * @project: certiapp-nuxt
 * @file:    \store\lotes.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 25th March 2022
 * Last Modified: Tue Jul 04 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // -----
  // Created on Wed Mar 30 2022
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    const xhr = await this.$axios.get(`lotes/${params.group}`)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // get()
  // -----
  // Created on Wed Mar 30 2022
  //+-------------------------------------------------
  async get({ rootState }, params) {
    rootState.loading = true
    const xhr = await this.$axios.get(`lotes/${params.group}/${params.uuid}`)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // create()
  // payload:
  // -----
  // Created on Fri Mar 25 2022
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    payload.auditor1 = params.auditor1?.uuid
    payload.auditor2 = params.auditor2?.uuid
    payload.en_calidad_de_auditor1 = params.auditor1?.en_calidad_de || '*'
    payload.en_calidad_de_auditor2 = params.auditor2?.en_calidad_de || '*'

    await this.$axios.post(`lotes/${params.group}`, {
      uuid: params.uuid || uuid.v4(),
      ...payload,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // Se mandan unicamente las revisiones o auditorias
  // que se van a actualizar. Los datos del lote, no se modifican
  // -----
  // Created on Thu Mar 31 2022
  // Updated on Thu Jun 30 2022
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    payload.auditor1 = params.auditor1?.uuid
    payload.auditor2 = params.auditor2?.uuid
    payload.en_calidad_de_auditor1 = params.auditor1?.en_calidad_de || '*'
    payload.en_calidad_de_auditor2 = params.auditor2?.en_calidad_de || '*'
    // delete payload.dictamenes

    const xhr = await this.$axios.patch(`lotes/${params.group}/${params.uuid}`, payload)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // unlink()
  // Removes a previously selected item from the lote
  // -----
  // Created on Wed Nov 09 2022
  //+-------------------------------------------------
  async unlink({ rootState }, params) {
    rootState.loading = true

    const xhr = await this.$axios.put(`lotes/${params.group}/${params.lote}/remove`, { ...params })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Oct 28 2022
  //+-------------------------------------------------
  async delete({ rootState }, params) {
    rootState.loading = true
    await this.$axios.delete(`lotes/${params.group}/${params.uuid}`)
    rootState.loading = false
  },
}
