<template>
  <div>
    <table class="no-border" style="width: 100%">
      <tbody>
        <tr class="no-border">
          <td class="no-border" style="width: 33.33%; text-align: left">
            <p>
              <strong>{{ clienteNombreCompleto }}</strong>
            </p>
            <p v-if="datos.cliente && datos.cliente.direccion">
              <span style="font-weight: 400;">{{ datos.cliente && datos.cliente.direccion }}</span>
              <span v-if="datos.cliente.comunidad">{{ datos.cliente.comunidad.nombre }}</span>
              <span v-if="datos.cliente.provincia">{{ ', ' + datos.cliente.provincia.nombre }}</span>
              <span v-if="datos.cliente.municipio">{{ ', ' + datos.cliente.municipio.nombre }}</span>
              <span v-if="datos.cliente.cp"><br>{{ datos.cliente.cp }}</span>
            </p>
          </td>
          <td class="no-border" style="width: 33.33%; text-align: center">
            <img src="https://www.kaam.es/images/logo.png" height="60px" width="60px">
            <h2 style="font-weight: 700" >kaam</h2>
          </td>
          <td class="no-border" style="width: 33.33%; text-align: right">
            <p>
              <span style="font-weight: 400;">{{ formatDateLong(new Date()) }}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
    </p>
    <p>
      <strong>ASUNTO: ACUERDO COMISION DE CERTIFICACION CP/IB-243/19</strong>
    </p>
    <p>
      <span style="font-weight: 400;">Muy Sres. Nuestros:</span>
    </p>
    <p>
      <span style="font-weight: 400;">En fecha <span style="font-weight: 700;">{{ formatDate(datos.fecha_decision) }}</span> se re&uacute;ne la Comisi&oacute;n de Certificaci&oacute;n de Kaam, &oacute;rgano encargado de la toma de decisi&oacute;n en materia de certificaci&oacute;n, para revisar el informe de auditor&iacute;a n&ordm; IB 243/19_140619.</span>
    </p>
    <p>
      <span style="font-weight: 400;">A la vista de la evaluaci&oacute;n de la documentaci&oacute;n presentada se acord&oacute; </span>
      <strong>conceder la certificaci&oacute;n </strong>
      <span style="font-weight: 400;">conforme al Real Decreto 4/2014, 10 de enero, por el que se aprueba la norma de calidad para {{ productos }} con alcance para las siguientes actividades, productos y/o procesos:</span>
    </p>
    <ul>
      <li style="font-weight: 400;">
        <p style="font-weight: 400;">Direcci&oacute;n de las instalaciones propias:</p>
        <p v-for="instalacion in instalaciones" :key="instalacion.id" style="font-weight: 400;">
          {{ instalacion.direccion }}
          <span v-if="instalacion.comunidad">{{ instalacion.comunidad.nombre }}</span>
          <span v-if="instalacion.provincia">{{ ', ' + instalacion.provincia.nombre }}</span>
          <span v-if="instalacion.municipio">{{ ', ' + instalacion.municipio.nombre }}</span>
          <span v-if="instalacion.cp">{{ instalacion.cp }}</span>
          con RGSEAA n&ordm; {{ instalacion.rgseaa }}
        </p>
      </li>
    </ul>
    <ul>
      <li style="font-weight: 400;">
        <p style="font-weight: 400;">Alcance de la certificaci&oacute;n: Actividades, producto y/o proceso:</p>
        <p style="font-weight: 400;" v-for="item in actividades" :key="item.id">
          Para el producto propio (compra de producto curado).
          Actividad (es): {{ item.actividades_realizadas }}
          Denominaci&oacute;n de venta: {{ tipoProducto[item.producto] + ' ' + tipoAlimentacion[item.alimentacion] }}
          con las menciones obligatorias 50% raza ib&eacute;rico y 75% raza ib&eacute;rico.
          Estado del producto: {{ tipoEstado[item.estado] }}.
          Presentaci&oacute;n (es) comercial (es): {{ tipoPresentacion[item.presentacion] }}
          Marca (s) comercial (es) {{ item.marcas }}.
        </p>
      </li>
    </ul>
    <!-- Alcance Proceso -->
    <!-- <div v-if="!isOperadorResposable">
      <div>
        <span class="pa-2 font-weight-bold">Actividades Realizadas: </span>
        <span>{{ datos.solicitud && datos.solicitud.params.alcance_proceso.realizadas.join(', ') }}</span>
      </div>
      <div>
        <span class="pa-2 font-weight-bold">Actividades Subcontratadas: </span>
        <span>{{ datos.solicitud && datos.solicitud.params.alcance_proceso.subcontratadas.join(', ') }}</span>
      </div>
      <div>
        <span class="pa-2 font-weight-bold">Certificación: </span>
        <span>{{ datos.solicitud && datos.solicitud.params.alcance_proceso.certificacion.join(', ') }}</span>
      </div>
    </div> -->
    <!-- Alcance Producto -->

    <p v-if="datos.no_conformidades && datos.no_conformidades.length">
      <strong>No Conformidades:</strong>
    </p>

    <span v-if="datos.no_conformidades && datos.no_conformidades.length">
      <p v-for="(no_conformidad, idx) in datos.no_conformidades" :key="no_conformidad.id">
        <strong>{{ idx + 1 }}. {{ no_conformidad.codigo + ' - ' + no_conformidad.titulo }}</strong>
        <br>
        {{ no_conformidad['texto-no-conformidad'] }}
      </p>
    </span>

    <p>
      <strong>Observaciones:</strong> {{ datos.observaciones || '' }}
    </p>

    <p>
      <span style="font-weight: 400;">Ante esta decisi&oacute;n podr&aacute; interponer un recurso siguiendo la sistem&aacute;tica establecida en el procedimiento general PG-13 &ldquo;Quejas, Recurso y Reclamaciones&rdquo;.</span>
    </p>

    <p>
      <span style="font-weight: 400;">Sin otro particular, reciban un cordial saludo.</span>
      <span style="font-weight: 400;"></span>
      <span style="font-weight: 400;"></span>
      <span style="font-weight: 400;"></span>
      <span style="font-weight: 400;"></span>
    </p>
    <p>
      <span style="font-weight: 400;">Teresa L&oacute;pez Rivas</span>
    </p>
    <p>
      <span style="font-weight: 400;">Directora T&eacute;cnica</span>
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 0.5px solid black;
  }
  thead {
    display: table-row-group;
  }
  tr td {
    page-break-inside: avoid;
  }
  .no-border {
    border: none !important;
  }
</style>

<script>
import MODELS from "~/models/kaam/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: function() {
    return {
      tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
      tipoProducto: MODELS.PRODUCTO_TIPO,
      tipoRacial: MODELS.RACIAL_TIPO,
      tipoEstado: MODELS.ESTADOS_TIPO,
      tipoPresentacion: MODELS.PRESENTACION_TIPO,
      tipoCertificacion: MODELS.CERTIFICACION_TIPO,
    }
  },
  computed: {
    isOperadorResposable() {
      return this.datos.solicitud && this.datos.solicitud.params.alcance_proceso.tipo_operador.includes('responsable')
    },
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    sites () {
      const planificacionesSites = this.datos.auditoria.planificaciones.map(e => e.site.uuid)

      const sites = this.datos.auditoria.sites.filter(e => e.certificado)

      const parentSites = sites.filter(e => planificacionesSites.includes(e.uuid))
      const parentSitesUuids = parentSites.map(e => e.uuid)

      const childrenSites = sites.filter(e => parentSitesUuids.includes(e.parent_uuid))

      return {
        instalaciones: this.getSiteData(parentSites),
        actividades: this.getSiteData(childrenSites)
      }
    },
    instalaciones () {
      const instalacionesNombre = this.sites.instalaciones.map(e => e.nombre)
      return this.datos.cliente.instalaciones.filter(element => {
        return instalacionesNombre.some(e => e.includes(element.rgseaa))
      })
    },
    actividades () {
      return this.sites.actividades
    },
    productos() {
      const productos = []
      const alcance_producto = this.datos.solicitud.params.alcance_producto
      if (alcance_producto) {
        for (let i=0; i < alcance_producto.length; i++) {
          const element = alcance_producto[i]
          const tipo_producto = this.tipoProducto[element.producto]
          if (!productos.includes(tipo_producto)) productos.push(tipo_producto)
        }
      }
      if (productos.length) {
        const start = productos.slice(0, productos.length - 1)
        const end = productos[productos.length - 1]
        return start.join(', ') + ' y ' + end
      }
      else return ''
    }
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    formatDateLong (date) {
      return date ? this.$moment(date).format('LL') : ''
    },
    getSiteData (sites) {
      const data = []
      if (sites) {
        for (let i=0; i < sites.length; i++) {
          const element = sites[i]
          let obj = {}
          obj.uuid = element.uuid
          obj.nombre = element.nombre
          obj.tipo = element.tipo
          for (let k=0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data.push(obj)
        }
      }
      return data
    },
    // init () {
    //   if (!this.datos.fecha_decision) {
    //     this.$router.replace({ name: 'dictamenes' })
    //     const alert = {
    //       text: 'El dictamen no tiene fecha de decisión.',
    //       color: 'error'
    //     }
    //     this.$store.commit("notification/show", alert)
    //   }
    // }
  },
  // created () {
  //   this.init()
  // }
}
</script>
