import { render, staticRenderFns } from "./tabla-centros-manipulacion-producto.vue?vue&type=template&id=9be0dcac&scoped=true"
import script from "./tabla-centros-manipulacion-producto.vue?vue&type=script&lang=js"
export * from "./tabla-centros-manipulacion-producto.vue?vue&type=script&lang=js"
import style0 from "./tabla-centros-manipulacion-producto.vue?vue&type=style&index=0&id=9be0dcac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9be0dcac",
  null
  
)

export default component.exports