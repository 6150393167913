
// TODO: PARA BUSCAR LAS LISTAS DE VALIDACIÓN DE UN OBJETO EN CONCRETO
// HAY QUE ESTABLECER EN EL STORE EL ALCANCE Y EL refUuid DEL MISMO,
// POR EJEMPLO, EN EL STORE DE AUDITORÍAS, AL HACER EL GET ONE AÑADIRIAMOS LO SIGUIENTE:
/*
      ("item" es el el objeto resultado del GET)
      rootState.listas_validacion.refUuid = item.uuid
      if (item.alcance && item.alcance.uuid) rootState.listas_validacion.alcance = item.alcance.uuid
*/

export const state = () => ({
  modulo: null,
  alcance: null,
  refUuid: null,
  lista: null,
  verListaValidacion: false,
  disabled: false,
  subalcance: null
});

export const mutations = {
  SET_MODULO(state, modulo) {
    state.modulo = modulo
  },
  SET_ALCANCE(state, alcance) {
    state.alcance = alcance
  },
  SET_REF_UUID(state, refUuid) {
    state.refUuid = refUuid
  },
  SET_LISTA(state, lista) {
    state.lista = lista
  },
  SET_LISTA_DESHABILIDATA(state) {
    state.disabled = true
  },
  SET_LISTA_HABILIDATA(state) {
    state.disabled = false
  },
  SET_SUBALCANCE(state, subalcance) {
    state.subalcance = subalcance
  }
};

export const getters = {
  listaValidacion(state) {
    if (state.lista) return state.lista.plantilla
    else return []
  },
  validacionesPendientes(state) {
    if (state.lista && state.lista.plantilla) {
      let pendientes = false
      for (let index = 0; index < state.lista.plantilla.length; index++) {
        const item = state.lista.plantilla[index]
        if (!item.check) pendientes = true
        if (index === (state.lista.plantilla.length - 1)) return pendientes
      }
    } else return false
  },
  listaDeshabilidata(state){
    return state.disabled
  }
};

export const actions = {

  // Cada vez que se cambia de ruta se resetean los parámetros de las listas de validación
  async reset({ state }, route) {
    const path = route.path.split('/')
    const modulo = path[1] === 'grupo_solicitudes' ? 'solicitudes' : path[1]
    state.modulo = modulo
    state.alcance = null
    state.refUuid = null
    state.lista = null
    state.disabled = false
    if (route.name.indexOf('uuid') !== -1) {
      state.verListaValidacion = true
      state.refUuid = route.params.uuid
    }
    else state.verListaValidacion = false
  },

  // ########################
  // ### PLANTILLAS
  // ########################

  async listAll({rootState}, params) {
    rootState.loading = true
    const  res  = await this.$axios.get('listas_validacion?exists[refUuid]=false')
    rootState.loading = false
    if (res?.data && res.data['hydra:member']) return res.data['hydra:member']

  },    
  
    async listAllBySubalcance({rootState}, uuid) {
      rootState.loading = true
      const  res  = await this.$axios.get('listas_validacion?exists[refUuid]=false&subalcance.uuid=' + uuid)
      rootState.loading = false
      if (res?.data && res.data['hydra:member']) return res.data['hydra:member']
  
    },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`listas_validacion/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, data) {
    rootState.loading = true
    await this.$axios.post('listas_validacion', {
      uuid: data.uuid,
      alcance: data.alcance,
      subalcance: data.subalcance,
      modulo: data.modulo,
      plantilla: data.plantilla,
      role: data.role
    })
    rootState.loading = false
  },

  async update({rootState}, data) {
    rootState.loading = true
    await this.$axios.patch(`listas_validacion/${data.uuid}`, {
      lista_validacion: data.uuid,
      alcance: data.alcance.uuid,
      subalcance: data.subalcance,
      modulo: data.modulo,
      plantilla: data.plantilla,
      role: data.role
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
    rootState.loading = false
  },

  async delete({rootState}, lista) {
    rootState.loading = true
    await this.$axios.delete(`listas_validacion/${lista.uuid}`)
    rootState.loading = false
  },

	async clone({ commit }, lista) {
		const res = await this.$axios.post(`listas_validacion/${lista.uuid}/clone`, { lista: lista.uuid });
		if (res) return res.data;
  },

  // #############################
  // ### VALIDACIONES CON refUuid
  // #############################

  async list({state}, data) {
    if (state.modulo && state.alcance && state.refUuid) {
      const roles = this.$auth.user.roles
      // TODO: ¿Añadimos role y cual del array o lo filtramos en el componente?
      const res = await this.$axios.get('listas_validacion', {
        alcance: state.alcance,
        modulo: state.modulo,
        ref_uuid: state.ref_uuid,
        // role: ''
      })
      if (res) state.lista = res.data['hydra:member'][0]
    }
    return state.lista
  },

 async listByRefUuid({state}) {
    if (state.refUuid && state.alcance && state.modulo) {
      const res = await this.$axios.get('listas_validacion?refUuid=' + state.refUuid)
      if (res) state.lista = res.data['hydra:member'][res.data['hydra:member'].length - 1]
    }
    return state.lista
  },

  async updateByUuid({rootState}, data) {
    rootState.loading = true
    await this.$axios.patch(`listas_validacion/${data.uuid}`, {
      plantilla: data.plantilla,
      data: data.plantilla
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
    rootState.loading = false
  },


  async save({state}, payload) {
    if (state.modulo && state.alcance && state.refUuid) {
      console.log('payload', payload)
      //${payload.item.uuid}
      await this.$axios.post(`listas_validacion`, 
        {
          //lista_validacion: payload.item.uuid,
          subalcance: payload.item.subalcance['@id'],
          modulo: payload.item.modulo,
          plantilla: payload.item.plantilla,
          role: payload.item.role,
          data: payload.item.plantilla,
          refUuid: payload.refUuid
        },
        //{
        //  headers: {
        //    'Content-Type': 'application/merge-patch+json'
        //  }
        //}
      )
    }
  }
};
