<template>
  <div v-if="isNotGroup">

    <confirmation 
      v-model="confirmPublicar"
      :text="'¿Publicar el certificado seleccionado?'"
      textButton="Publicar"
      colorButton="primary"
      @confirm="publicar()"/>

    <confirmation 
      v-model="confirmDespublicar"
      :text="'¿Despublicar el certificado seleccionado?'"
      textButton="Despublicar"
      colorButton="error"
      @confirm="despublicar()"/>

      <v-btn v-if="!publicado" small color="success" @click.stop="confirmPublicar = true">Publicar</v-btn>

      <v-btn v-else small color="error" @click.stop="confirmDespublicar = true">Despublicar</v-btn>

  </div>
</template>

<script>
import Confirmation from "~/components/Confirmation"
export default {
  components: {
    Confirmation
  },
  data: () => ({
    confirmPublicar: false,
    confirmDespublicar: false
  }),
  computed: {
    isNotGroup () {
      return !!this.params.data
    },
    data () {
      return this.params.node.data
    },
    publicado () {
      return this.params.node.data.publicado
    }
  },
	methods: {
    reload () {
      if (this.params.reload instanceof Function) {
        this.params.reload();
      }
    },
    async publicar() {
      this.confirmPublicar = false
      await this.$store.dispatch('certificados/publicar', this.params.node.data.uuid)
      this.reload()
    },
    async despublicar() {
      this.confirmDespublicar = false
      await this.$store.dispatch('certificados/despublicar', this.params.node.data.uuid)
      this.reload()
    }
  },
}
</script>
