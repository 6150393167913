<template>
  <div>

    <!-- Confirm -->
    <confirmation
      v-model="confirm"
      :text="`¿Desea crear un análisis?`"
      textButton="Crear"
      :valid="valid"
      @confirm="createAnalisis()"
      @close="close()"
    >
      <v-form ref="form" lazy-validation v-model="valid">
        <v-container class="px-8">
          <v-row align="center" justify="space-around">
            <v-col cols="6">
              <SelectEstaticos
                v-if="auditoria.alcance"
                v-model="newAnalisis.tipo_analisis"
                :alcance="auditoria.alcance.uuid"
                codigo="tipos_analisis"
                required
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Precinto"
                v-model="newAnalisis.precinto"
                :rules="rules"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Respuesta"
                v-model="newAnalisis.respuesta"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Observaciones"
                v-model="newAnalisis.observaciones"
                outlined
                auto-grow
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </confirmation>
    
    <v-card outlined>

      <v-card-title>
        Análisis {{ tab + 1 }}
        <v-spacer></v-spacer>
        <v-btn
          v-for="(item, idx) in analisis"
          :key="item.id"
          :color="tab === idx ? 'info' : 'grey lighten-4'"
          :class="tab === idx ? 'white--text' : ' grey--text text--darken-1'"
          class="mr-4"
          depressed
          @click="tab = idx"
        >
          <span class="text-capitalize">{{ idx + 1 }}</span>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small fab depressed color="success" class="white--text" v-on="on" @click="confirm = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear</span>
        </v-tooltip>
      </v-card-title>
      
      <v-divider></v-divider>

      <v-card-text>
        <v-window v-model="tab">

          <v-window-item v-for="(item, idx) in analisis" :key="item.id" :value="idx">
            <v-row no-gutters align="center">

              <!-- INFORMACION -->
              <v-col cols="12">
                <v-card flat>
                  <v-card-title>Información</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row justify="space-between">

                        <v-col cols="5" class="pb-1">
                          <v-select
                            label="Tipo de análisis"
                            v-model="item.tipo_analisis"
                            :items="tipos"
                            dense
                            @change="updateAnalisis($event, item)"
                          ></v-select>
                        </v-col>

                        <v-col cols="5" class="pb-1">
                          <v-text-field
                            label="Precinto"
                            v-model="item.precinto"
                            dense
                            @change="updateAnalisis($event, item)"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" class="pb-1">
                          <v-text-field
                            label="Respuesta"
                            v-model="item.respuesta"
                            dense
                            @change="updateAnalisis($event, item)"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" class="pb-1">
                          <v-textarea
                            label="Observaciones"
                            v-model="item.observaciones"
                            outlined
                            auto-grow
                            @change="updateAnalisis($event, item)"
                          ></v-textarea>
                        </v-col>

                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- PARAMETROS -->
              <v-col cols="12">
                <analisis-parametros
                  :toma_muestra="toma_muestra"
                  :muestra="muestra"
                  :analisis="item"
                  @init="listAnalisis()"
                  :key="component"/>
              </v-col>

            </v-row>
          </v-window-item>

        </v-window>
      </v-card-text>

    </v-card>

  </div>
</template>

<script>
import Confirmation from "~/components/Confirmation"
import SelectEstaticos from "~/components/forms/SelectEstaticos"
import AnalisisParametros from "~/components/auditorias/muestras/MuestrasTabAnalisisParametros"
import {uuid} from 'vue-uuid'

export default {
  props: {
    muestra: { type: Object, default: () => {} },
    toma_muestra: { type: Object, default: () => {} },
    auditoria: { type: Object, default: () => {} },
  },
  components: {
    Confirmation,
    SelectEstaticos,
    AnalisisParametros
  },
  data: () => ({
    component: 0,
    valid: true,
    confirm: false,
    tab: 0,
    newAnalisis: {},
    analisis: [],
    tipos: [
      'Multiresiduo',
      'Físico químico',
      'Organoléptico',
      'Otros'
    ],
    rules: [ v => !!v || 'Obligatorio' ],
  }),
  watch: {
    toma_muestra (val) {
      if (val.uuid) this.listAnalisis()
    },
    muestra (val) {
      if (val.uuid) this.listAnalisis()
    }
  },
  methods: {
    close() {
      this.confirm = false
      this.newAnalisis = {}
      this.$refs.form.reset()
    },
    // Analisis
    async listAnalisis() {
      if (this.toma_muestra.uuid && this.muestra.uuid) {
        const body = {
          toma_muestra: this.toma_muestra.uuid,
          muestra: this.muestra.uuid
        }
        const res = await this.$store.dispatch('tomas_muestra/listAnalisis', body)
        if (res) this.analisis = res
        this.component++
      }
    },
    async createAnalisis(idx) {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.newAnalisis,
          toma_muestra: this.toma_muestra.uuid,
          muestra: this.muestra.uuid
        }
        await this.$store.dispatch('tomas_muestra/createAnalisis', body)
        this.close()
        this.listAnalisis()
      }
    },
    async updateAnalisis(val, item) {
      if (val) {
        const body = {
          ...item,
          toma_muestra: this.toma_muestra.uuid,
          muestra: this.muestra.uuid
        }
        await this.$store.dispatch('tomas_muestra/updateAnalisis', item)
        this.listAnalisis()
      }
    },
  },
  created() {
    this.listAnalisis()
  }
}
</script>
