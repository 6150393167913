/**
 * @project: certiapp-nuxt
 * @file:    clientes_contactos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 26th May 2021
 * Last Modified: Wed May 26 2021
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // -----
  // Created on Wed May 26 2021
  //+-------------------------------------------------
  async list({ rootState }, uuid) {
    rootState.loading = true
    console.log('hre')
    let res = null
    if(uuid) res = await this.$axios.get(`contactos?users.uuid=${uuid}`)
    else res = await this.$axios.get(`contactos`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async tecnicos({ rootState }, uuid) {
    rootState.loading = true
    console.log('hre')
    let res = null
    if(uuid) res = await this.$axios.get(`usuarios?cliente.uuid=${uuid}`)
    else res = await this.$axios.get(`contactos`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  // //+-------------------------------------------------
  // // get()
  // // Created on Thu Apr 29 2021
  // //+-------------------------------------------------
  // async get({ rootState }, uuid) {
  //   rootState.loading = true
  //   const res = await this.$axios.get(`grupo-contactos/${uuid}`)

  //   rootState.loading = false
  //   if (res) return res.data
  // },

  //+-------------------------------------------------
  // create()
  // payload: uuid, nombre, contactos
  // -----
  // Created on Tue May 25 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    await this.$axios.post(`grupo-contactos`, {
      uuid: params.uuid || uuid.v4(),
      nombre: params.nombre,
      contactos: params.contactos,
    })
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`grupo-contactos`, {
      grupo_contacto: params.uuid,
      nombre: params.nombre,
      contactos: params.contactos,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`grupo-contactos/${item.uuid}`)
    rootState.loading = false
  },
}
