/**
 * @project: certiapp-nuxt
 * @file:    \util\index.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Fri Jun 30 2023
 **/

import XLSX from 'xlsx'

const oceglobalEmptyOptionalBlankFiels = ['variedad', 'secano_regadio']

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string') return string
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ''
}

export const importFile = (
  vm,
  event,
  grid,
  columnDefs,
  onLoadEnd,
  validations,
  allowEmptyCells
) => {
  const fichero = event.target.files || event
  if (fichero) {
    let reader = new FileReader()
    // let self = this

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]

      // Obtenemos los datos y le damos formato con la primera letra en mayuscula
      const rowData = XLSX.utils.sheet_to_json(ws)

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })

      /* Update state */
      // let header = data[0]
      let header = []
      const headers = (columnDefs || grid.columnDefs)
        .filter((e) => e.field || e.children)
        .map((e) => e.field || e.children)
      for (let i = 0; i < headers.length; i++) {
        const element = headers[i]
        Array.isArray(element)
          ? (header = header.concat(element.map((e) => e.field)))
          : header.push(element)
      }

      data.splice(0, 1)

      let ficheroValido = true
      let error = ''
      const gridData = []

      // Formato de las cabeceras con primera letra en mayúscula
      for (let i = 0; i < data.length; i++) {
        const row = {}
        const rowValues = data[i]
        if (rowValues.length) {
          for (let k = 0; k < header.length; k++) {
            const key = header[k]

            // 17/05/2022
            // if allowEmptyCells is true, we allow empty cells
            // Just add a default value if the cell is empty
            if (allowEmptyCells && rowValues[k] === undefined) rowValues[k] = '-'
            if (rowValues[k] == '-') rowValues[k] = ' '

            // Try to work with dates
            if (key.indexOf('fecha') > -1) {
              if (typeof rowValues[k] === 'number') {
                let mom = vm.$moment('1900-01-01').add(rowValues[k] - 2, 'days')
                rowValues[k] = mom.format('DD-MM-YYYY')
              }

              rowValues[k] = rowValues[k].replace(/\//g, '-')
              if (typeof rowValues[k] === 'string' && rowValues[k].indexOf('-') > -1) {
                let mom = vm.$moment(rowValues[k], ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'])
                rowValues[k] = mom.format('DD-MM-YYYY')
              }
            }

            if (
              (rowValues[k] !== undefined && rowValues[k] !== null && rowValues[k] !== '') ||
              oceglobalEmptyOptionalBlankFiels.includes(key)
            ) {
              if (validations && validations.length) {
                const validationIndex = validations.findIndex((e) => e.header == key.toLowerCase())
                if (validationIndex > -1) {
                  const validation = validations[validationIndex]
                  const fieldValid = validation.items.includes(rowValues[k])
                  if (!fieldValid) {
                    ficheroValido = false
                    error = rowValues[k] + ' ' + validation.error
                    break
                  }
                }
              }

              // Magic for dates
              // if (key == 'fecha_alta') {
              //   var fecha = rowValues[k]

              //   var utc_value = Math.floor(fecha - 25569) * 86400
              //   var date_info = new Date(utc_value * 1000)
              //   var month = parseInt(date_info.getMonth()) + 1
              //   var newDate = date_info.getFullYear() + '/' + month + '/' + date_info.getDate()

              //   rowValues[k] = newDate
              // }

              //magic for objects
              // This searches sub-attributes and creates an object
              if (key.indexOf('.') > -1) {
                let object = key.split('.')[0]
                let attribute = key.split('.')[1]

                if (row[object] == undefined) {
                  row[object] = {}
                }

                row[object][attribute] = rowValues[k]
                continue
              }

              // Booleans
              if (rowValues[k] == 'true') rowValues[k] = true
              if (rowValues[k] == 'false') rowValues[k] = false

              row[key] = rowValues[k]
            } else {
              if (!allowEmptyCells) {
                ficheroValido = false
                error = 'El formato del fichero no es correcto. Contiene celdas vacias.'
                break
              }
            }
          }
          gridData.push(row)
        }
      }
      if (ficheroValido) {
        if (grid) {
          grid.api.setRowData(gridData)
          grid.rowData = gridData
        }
      } else {
        const alert = {
          text: error,
          color: 'error',
          timeout: 4000,
        }
        vm.$store.commit('notification/show', alert)
      }

      const finalData = ficheroValido ? gridData : []
      onLoadEnd(finalData)

      // if (data) {
      //   if (self.formatoCabeceraFicheroValida(fichero.grid, header)) {
      //     self.validarContenidoPorColumnasFichero(fichero, rowData)
      //     fichero.grid.api.setRowData(rowData)
      //     fichero.grid.rowData = rowData
      //     fichero.grid.contieneDatos = true
      //   } else {
      //     fichero.input_file = null
      //     fichero.grid.contieneDatos = false
      //     self.loading_file = false
      //     self.$store.commit("notification/show", {text: 'El formato del fichero no es correcto. Compruebe el formato con la tabla que aparece debajo.', color: 'error', timeout: 4000})
      //   }
      // } else {
      //   fichero.input_file = null
      //   fichero.grid.contieneDatos = false
      //   self.loading_file = false
      //   self.$store.commit("notification/show", {text: 'El fichero no contiene filas de datos.', color: 'error', timeout: 3000})
      // }
    }
    reader.onerror = (stuff) => {
      console.log(stuff.getMessage())
    }
    reader.readAsBinaryString(event.target.files[0])
  }
}

export const exportFile = (vm, filename, ws_name, columnDefs, rowData) => {
  const file_name = filename.split('.')[0] + '.xlsx'
  
  // Map data in array of string
  const data = rowData.map((element) => {
    const row = []
    for (let i = 0; i < columnDefs.length; i++) {
      const header = columnDefs[i]
      const field = header.field ?? header
      
      let content = element[field] || ''
      let cleanText = convertToPlain(content)
      //console.log('we',header, field, cleanText)
      row.push(cleanText || '')
    }
    return row
  })

  // Map Headers
  const headers = []
  for (let i = 0; i < columnDefs.length; i++) {
    const header = columnDefs[i]
    const headerName = header.headerName ?? header
    headers.push(headerName)
  }
  data.unshift(headers)

  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(data)

  XLSX.utils.book_append_sheet(wb, ws, ws_name)
  XLSX.writeFile(wb, file_name)
}

function convertToPlain(html) {
  // Create a new div element
  var tempDivElement = document.createElement('div')

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || ''
}
