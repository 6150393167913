<template>
  <div>
    <v-row justify="space-around">
      <!-- Cambio Cultivo -->
      <v-col cols="3" v-if="action === 'cambio_cultivo'">
        <v-autocomplete
          :items="list"
          v-model="newValue"
          item-text="value"
          item-value="value"
          label="Cultivo"
          @input="$emit('newValue', newValue), $emit('valid', true)"
        ></v-autocomplete>
      </v-col>
      <!-- Cambio Superficie -->
      <v-col cols="3" v-if="action === 'cambio_superficie'">
        <v-text-field
          v-model="superficie"
          label="Superficie"
          :error-messages="superficieErrors"
          @input="$v.superficie.$touch(), $emit('newValue', superficie), $emit('valid', !$v.superficie.$invalid && superficieValid)"
        ></v-text-field>
      </v-col>
      <!-- GGN -->
      <v-col cols="3" v-if="action === 'cambio_ggn'">
        <v-text-field
          v-model="ggn"
          label="GGN"
          hint="Código numérico de 13 cifras"
          persistent-hint
          maxlength="13"
          counter="13"
          :error-messages="ggnErrors"
          @input="$v.ggn.$touch(), $emit('newValue', ggn), $emit('valid', !$v.ggn.$invalid)"
        ></v-text-field>
      </v-col>
      <!-- Gln -->
      <v-col cols="3" v-if="action === 'cambio_gln'">
        <v-text-field
          v-model="gln"
          label="GLN"
          hint="Código numérico de 13 cifras"
          persistent-hint
          maxlength="13"
          counter="13"
          :error-messages="glnErrors"
          @input="$v.gln.$touch(), $emit('newValue', gln), $emit('valid', !$v.gln.$invalid)"
        ></v-text-field>
      </v-col>
      <!-- NIF -->
      <v-col cols="3" v-if="action === 'cambio_nif'">
        <v-text-field
          label="NIF"
          v-model="nif"
          v-mask="nifMask"
          :suffix="checkDocument"
          :error-messages="nifErrors"
          maxlength="11"
          @input="$v.nif.$touch(), $emit('newValue', nif), $emit('valid', !$v.nif.$invalid && nifValid)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { integer, minLength } from 'vuelidate/lib/validators'

export default {
  props: ['action', 'grupo_alcance_uuid'],
  directives: { mask },
  mixins: [validationMixin],
  validations: {
    superficie: { },
    nif: { minLength: minLength(7) },
    gln: { integer, minLength: minLength(13) },
    ggn: { integer, minLength: minLength(13) }
  },
  data: () => ({
    newIteration: true,
    newValue: null,
    superficie: null,
    gln: null,
    ggn: null,
    nif: null,
    nifMask: {
      mask: 'CCCCCCCCCCC',
      tokens: {
        '#': { pattern: /\d/ },
        'A': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        'C': { pattern: /[0-9a-zA-Z-]/, transform: v => v.toLocaleUpperCase() }
      }
    },
  }),
  computed: {
    list () {
      return this.$store.state.dropdowns.list['CULTIVOS_SOLICITADOS']
    },
    superficieErrors () {
      const errors = []
      if (!this.$v.superficie.$dirty) return errors
      !this.superficieValid && errors.push('Superficie inválida')
      return errors
    },
    superficieValid () {
      return this.superficie > 0
    },
    glnErrors () {
      const errors = []
      if (!this.$v.gln.$dirty) return errors
      !this.$v.gln.integer && errors.push('Código inválido')
      !this.$v.gln.minLength && errors.push('Código inválido')
      return errors
    },
    ggnErrors () {
      const errors = []
      if (!this.$v.ggn.$dirty) return errors
      !this.$v.ggn.integer && errors.push('Código inválido')
      !this.$v.ggn.minLength && errors.push('Código inválido')
      return errors
    },
    nifErrors () {
      const errors = []
      if (!this.$v.nif.$dirty) return errors
      !this.$v.nif.minLength && errors.push('Documento inválido')
      !this.nifValid && errors.push('Documento inválido')
      return errors
    },
    nifValid () {
      if (this.nif) {
        let valid = false
        const passport = new RegExp('^[P](?:-)*[0-9]+$')
        const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
        const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
        const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
        const other1 = new RegExp('^[0-9]{7}$')
        const other2 = new RegExp('^[0-9]{8}$')
        const other3 = new RegExp('^[0-9]{9}$')
        const other4 = new RegExp('^[0-9]{11}$')
        if (passport.test(this.nif)) valid = true
        if (dni.test(this.nif)) valid = true
        if (nie.test(this.nif)) valid = true
        if (nif.test(this.nif)) valid = true
        if (other1.test(this.nif)) valid = true
        if (other2.test(this.nif)) valid = true
        if (other3.test(this.nif)) valid = true
        if (other4.test(this.nif)) valid = true
        return valid
      } else {
        return true
      }
    },
    checkDocument () {
      let document = 'OTRO'
      const passport = new RegExp('^[P](?:-)*[0-9]+$')
      const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
      const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
      const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
      const other1 = new RegExp('^[0-9]{7}$')
      const other2 = new RegExp('^[0-9]{8}$')
      const other3 = new RegExp('^[0-9]{9}$')
      const other4 = new RegExp('^[0-9]{11}$')
      if (passport.test(this.nif)) document = 'PASAPORTE'
      if (dni.test(this.nif)) document = 'DNI'
      if (nie.test(this.nif)) document = 'NIE'
      if (nif.test(this.nif)) document = 'NIF'
      return document
    }
  },
  created () {
    if (this.action === 'cambio_cultivo') {
      this.$store.dispatch('dropdowns/list', {
        empresa: this.$auth.user.empresa.uuid,
        grupo_alcance: this.grupo_alcance_uuid,
        codigo: 'CULTIVOS_SOLICITADOS'
      })
    }
  }
}
</script>
