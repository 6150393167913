/**
 * @project: certiapp-nuxt
 * @file:    store/compartir.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 8th July 2021
 * Last Modified: Mon Aug 22 2022
 **/

import { uuid } from 'vue-uuid'
import axios from 'axios'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // get()
  // Returns single item
  // -----
  // Created on Wed Sep 29 2021
  //+-------------------------------------------------
  async get({ rootState }, params) {
    rootState.loading = true

    let xhr = null
    let instance = axios.create({
      baseURL: this.$axios.defaults.baseURL,
    })

    await instance
      .put(`public-shares/${params.hash}`, params)
      .then(function (response) {
        xhr = response
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          xhr = error.response
        }
        // else if (error.request) {
        //   // The request was made but no response was received
        //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //   // http.ClientRequest in node.js
        //   console.log(error.request)
        // } else {
        //   // Something happened in setting up the request that triggered an Error
        //   console.log('Error', error.message)
        // }
        // console.log(error.config)
      })

    rootState.loading = false
    return xhr
  },

  //+-------------------------------------------------
  // list()
  // Returns all items
  // -----
  // Created on Mon Sep 20 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    const xhr = await this.$axios.get(`shares`)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // create()
  // Requests a new share URL based on modulo and modulo_ref
  // This URL may already exists
  // -----
  // Created on Tue Sep 21 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    const xhr = await this.$axios.post(`shares`, {
      ...params,
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Wed Sep 22 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`shares/${params.uuid}`, {
      elemento_compartido: params.uuid,
      ...params,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // toggleVisibility()
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async toggleVisibility({ rootState }, params) {
    rootState.loading = true
    const xhr = await this.$axios.patch(`shares/${params.uuid}/toggle-visible`, {
      documento: params.uuid,
      visible: params.visible,
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  // async delete({rootState}, item) {
  //   rootState.loading = true
  //   await this.$axios.delete(`shares/${item.uuid}`)
  //   rootState.loading = false
  // },
}
