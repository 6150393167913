<template>
  <v-dialog v-model="value" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <span v-if="title">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field required :rules="RULES.REQUIRED" persistent-hint v-model="item.nombre" label="Nombre"></v-text-field>
              </v-col>

              <v-col cols="6">
                  <v-text-field
                    label="CODPR"
                    v-model="data.codpr"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                      label="PR"
                      v-model="data.pr"
                      :rules="RULES.REQUIRED"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Código Municipio"
                    v-model="data.codigo_municipio"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                      label="Municipio"
                      v-model="data.municipio"
                      :rules="RULES.REQUIRED"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="POLG"
                    v-model="data.polg"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Parcel"
                    v-model="data.parcel"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="RC"
                    v-model="data.rc"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Sup. Parcela"
                    v-model.number="data.superficie_parcela"
                    type="number"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Sup. Plantada"
                    v-model.number="data.superficie_plantada"
                    type="number"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-menu
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="formatDate(data.fecha_alta)"
                        label="Fecha Alta"
                        prepend-icon="event"
                        clearable
                        readonly
                        dense
                        v-on="on"
                        @click:clear="data.fecha_alta = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.fecha_alta"
                      color="primary"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="CALF"
                    v-model="data.calf"
                    :items="MODEL.CALF_TIPOS"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Propiedad"
                    v-model="data.propiedad"
                    :items="MODEL.PROPIEDAD_TIPOS"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-select>
                </v-col>


                <v-col cols="6">
                  <v-text-field
                      label="Agregado"
                      v-model.number="data.agregado"
                      :rules="RULES.REQUIRED"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <SelectEstaticos
                    v-model="data.cultivo"
                    nope:alcance="alcance.uuid"
                    codigo="cultivos"
                    required
                    dense
                  />
                </v-col>
            </v-row>
          </v-card-text>

        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancelar</v-btn>
        <v-btn color="primary" @click="submit()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import MODEL from "~/models/ccl/solicitudes"
import RULES from "~/models/form.rules"
import customVModelMixin from '~/util/custom.vmodel.mixin'
import SelectEstaticos from "~/components/forms/SelectEstaticos";

export default {
  name: "AlcanceProductoDialog",
  mixins: [customVModelMixin],
  props: {
    title: {type: String, default: ''},
    itemSelected: {type: Object, default: null},
    parentSites: {type: Array, default: []},
    firstChildrenSite: {type: Object, default: null},
  },
  components: {
      SelectEstaticos
  },
  data: () => ({
    MODEL,
    RULES,
    item: {},
    data: {},
    isNew: false
  }),
  watch: {
    value(val) {
      if (val === true) {
        this.item = this.itemSelected
        if (this.item && this.item.parent_uuid) {
          const parents = this.parentSites.filter(parent => {
            return parent.uuid === this.item.parent_uuid
          })
          this.item.parent = (parents.length > 0) ? parents[0] : {}
        }
      } else {
        this.item = {};
        this.data = {};
      }
      this.formatData();
    }
  },
  computed: {
    submitEventName() {
      return (this.isNew) ? 'create' : 'update';
    }
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },

    formatData() {
      if (this.item == null) {
        this.data = {};
        this.item = {parent: {}};
        this.isNew = true;
        return;
      }

      if (this.item && this.item.site_data) {
        for (const property of this.item.site_data) {
          this.data[property.keyname] = property.value;
        }
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let payload = {
        uuid: this.item.uuid,
        nombre: this.item.nombre,
        data: []
      };


      if (this.isNew) {
        payload = Object.assign({}, this.firstChildrenSite)
        payload.uuid = this.$uuid.v4()
        payload.nombre = this.item.nombre
        payload.parent = this.item.parent.uuid
        payload.source_iteracion_uuid = payload.uuid
        payload.source_parent_uuid = this.item.parent.source_iteracion_uuid  //todo: buscar de db el parente_uuid del site marcado como parent.
        payload.data = []
      }

      for (const item in this.data) {
        payload.data.push({
          'key': item,
          'value': this.data[item]
        });
      }

      this.$emit(this.submitEventName, payload);
      this.$emit('close')
    },
    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },

  }
};
</script>
