<template>

  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="hover ? num_hover: 3" :tile="tile">

      <v-card-title>
        <span class="title-vuely" v-if="title">{{ title }}</span>
        <span class="title-vuely" v-else>{{ `Listado ${$nuxt.$route.name}` }}</span>
        <v-spacer></v-spacer>
        <!--        <v-file-input @change="checkFile" v-model="file" single-line hide-details label="Importar excel" class="mr-3"></v-file-input>-->
        <v-text-field @keyup="updateFilter" v-model="search" append-icon="search" label="Búsqueda rápida" single-line hide-details clearable></v-text-field>
        <v-btn tile small dark color="grey" rounded @click="excelExport" class="mt-5 ml-3">
          <v-icon class="mr-1" size="20">mdi-file-excel</v-icon>
          exportar
        </v-btn>
        <!--        <v-btn tile small dark color="grey" rounded @click="excelImport" class="mt-5 ml-3">-->
        <!--          <v-icon class="mr-1" size="20">mdi-file-excel</v-icon>importar-->
        <!--        </v-btn>-->
      </v-card-title>

      <div style="height: 100%">
        <div class="ag-container">
          <ag-grid-vue style="width: 100%;  height: 57.5vh; border: 1px solid #eee"
                       :suppressCellSelection="true"
                       class="ag-theme-material ag-grid"
                       id="ag-grid"
                       :columnDefs="headers"
                       :rowData="items"
                       :sideBar="sideBar"
                       :animateRows="true"
                       :localeText="localeText"
                       :pagination="true"
                       :paginationAutoPageSize="true"
                       :quickFilter="search"
                       @grid-ready="onGridReady"
                       @rowClicked="clickRow"
                       rowGroupPanelShow="always"
                       :overlayLoadingTemplate="overlayLoadingTemplate"
                       :frameworkComponents="frameworkComponents"
                       :excelStyles="excelStyles"
                       @firstDataRendered="autoSize"
          >
          </ag-grid-vue>

        </div>
      </div>

    </v-card>
  </v-hover>

</template>

<script>
export default {
  name: "AgDatatableSelectable",
  props: {
    showSelect: {type: Boolean, default: () => false},
    headers: {type: Array, default: () => []},
    items: {type: Array, required: true, default: () => []},
    title: {type: String, default: () => null},
    disableClickRow: {type: Boolean, default: () => false},
    emitClickRow: {type: Boolean, default: () => false},
    showHover: {type: Boolean, default: () => true},
    tile: {type: Boolean, default: () => false},
  },
  data() {
    return {
      file: undefined,
      singleSelect: false,
      loading: true,
      search: '',
      page: 1,
      pageCount: 0,
      localeText: {},
      sideBar: {},
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      frameworkComponents: null,
      excelStyles: null,
      overlayLoadingTemplate: null,
      num_hover: 8
    }
  },
  beforeMount() {
    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Por favor, espere mientras se cargan los datos...</span>';
    this.localeText = {
      equals: "es igual",
      notEqual: "no es igual",
      lessThan: "es menor",
      greaterThan: "es mayor",
      inRange: "esta en el rango",
      contains: "contiene",
    };
    this.frameworkComponents = {
      SwitchRenderer: "SwitchRenderer",
      DeleteRenderer: "DeleteRenderer"
    };
    this.sideBar = {
      toolPanels: ["filters", "columns"]
    };
    this.excelStyles = [
      {
        id: "default",
        dataType: "string"
      }
    ]

  },
  watch: {
    items: function (value) {
      if (value && value.length > 0 && this.gridApi) {
        this.gridApi.hideOverlay()
      }
      if (value && value.length === 0 && this.gridApi) {
        this.gridApi.hideOverlay()
      }
    }
  },
  mounted() {
    setTimeout(() => this.loading = false, 1500)
    if (!this.showHover) this.num_hover = 3
  },
  methods: {
    async autoSize() {
      if (this.columnApi) {
        const rowGroupColumnsIds = this.columnApi.getRowGroupColumns().map(column => column.getColId())
        await this.columnApi.removeRowGroupColumns(rowGroupColumnsIds)
        setTimeout(() => {
          this.columnApi.autoSizeAllColumns()
          this.columnApi.addRowGroupColumns(rowGroupColumnsIds)
          setTimeout(() => {
            this.columnApi.autoSizeColumn('ag-Grid-AutoColumn')
          }, 1)
        }, 1);
      }
    },
    checkFile(e) {
      console.info(e);
      console.info(this.file);
    },
    excelImport() {
    },
    excelExport() {
      const params = {
        processCellCallback: function (params) {
          params.column.colDef.cellClass = "default"
          return params.value
        }
      }
      this.gridApi.exportDataAsExcel(params);
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.showLoadingOverlay()
      this.columnApi = params.columnApi
      this.gridApi.sizeColumnsToFit()
      this.gridApi.expandAll();
      // this.gridApi.forEachNode(node =>{
      //   node.expanded= true;
      // });
      // this.gridApi.onGroupExpandedOrCollapsed();
    },
    updateFilter() {
      this.gridApi.setQuickFilter(this.search)
    },
    clickRow(row) {
      if (!this.disableClickRow && !this.emitClickRow) {
        if (row.data) {
          let route = (this.$nuxt.$route.name).replace(/-/g, '/')
          route = `/${route}/${row.data.uuid}`
          this.$router.push(route)
        }
      } else if (!this.disableClickRow && this.emitClickRow) {
        this.$emit('click-row', row.data)
      }
    },
  }
}
</script>

<style>
.ag-header-cell, .ag-header-group-cell {
  border-right: 1px solid #e2e2e2 !important;
}

.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background: #eeeeee;
  cursor: pointer !important;
}

.ag-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.ag-grid {
  height: 1px;
  min-height: 1px;
  flex-grow: 1;
}

.ag-cell {
  font-size: 1.1em !important;
}

.ag-header-cell-text {
  font-size: 1.3em !important;
  font-weight: bold;
}
</style>
