<template>
  <div>
    <table style="border-collapse: collapse; width: 100%;" border="1">
      <tbody>
        <tr>
          <td style="width: 17.5293%;">&nbsp;</td>
          <td style="width: 80.5829%;">
            <p style="text-align: center;">
              <strong>&nbsp;Programa de Auditor&iacute;a &ndash; Producci&oacute;n Ecol&oacute;gica</strong>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <table>
      <tbody>
        <tr>
          <td>
            <h2>
              <em>
                <span style="font-weight: 400;">Operador: PE/{{ datos.cliente.codigo }}</span>
              </em>
            </h2>
            <p>
              <strong>
                <em>{{ nombreCompleto(datos.cliente) }}</em>
              </strong>
            </p>
            <p>
              <em>
                <span style="font-weight: 400;">{{ datos.cliente.nif }}</span>
              </em>
            </p>
            <p>
              <em>
                <span style="font-weight: 400;">{{ datos.cliente.direccion }}</span>
                <br>
                <span v-if="datos.cliente.comunidad">{{ datos.cliente.comunidad.nombre }}</span>
                <span v-if="datos.cliente.provincia">{{ ', ' + datos.cliente.provincia.nombre }}</span>
                <span v-if="datos.cliente.municipio">{{ ', ' + datos.cliente.municipio.nombre }}</span>
                <span v-if="datos.cliente.cp"><br>{{ datos.cliente.cp }}</span>
              </em>
            </p>
            <p>
              <em>
                <span style="font-weight: 400;">Instalaciones:</span>
              </em>
            </p>
            <div v-if="datos.cliente.instalaciones && datos.cliente.instalaciones.length">
              <p>
                <em>
                  <span style="font-weight: 400;">{{ datos.cliente.instalaciones[0].direccion }}</span>
                </em>
              </p>
              <p>
                <em>
                  <span style="font-weight: 400;">{{ datos.cliente.instalaciones[0].comunidad && datos.cliente.instalaciones[0].comunidad.nombre }}</span>
                  <span style="font-weight: 400;">{{ datos.cliente.instalaciones[0].municipio && ', ' + datos.cliente.instalaciones[0].municipio.nombre }}</span>
                  <span style="font-weight: 400;">{{ datos.cliente.instalaciones[0].provincia && ', ' + datos.cliente.instalaciones[0].provincia.nombre }}</span>
                </em>
              </p>
            </div>
          </td>
          <td>
            <p>
              <strong>
                <em>CCL Certificaci&oacute;n, S.L.</em>
              </strong>
            </p>
            <p>
              <em>
                <span style="font-weight: 400;">C/ TIERRAS DE MEDINA, 1 -1&ordm;A</span>
              </em>
              <em>
                <span style="font-weight: 400;">&nbsp;</span>
              </em>
            </p>
            <p>
              <em>
                <span style="font-weight: 400;">47008 Valladolid</span>
              </em>
            </p>
          </td>
        </tr>
        <tr>
          <td v-if="datos.cliente.tecnicos && datos.cliente.tecnicos.length">
            <p>
              <span style="font-weight: 400;">A/a. {{ nombreCompleto(datos.cliente.tecnicos[0]) }}</span>
            </p>
            <p>
              <span style="font-weight: 400;">{{ datos.cliente.tecnicos[0].email }}</span>
            </p>
            <p>
              <span style="font-weight: 400;">{{ datos.cliente.tecnicos[0].telefono }}</span>
            </p>
          </td>
          <td>
            <p style="font-weight: 400;">Auditores:</p>
            <p v-for="item in datos.auditores" :key="item.id">
              <span style="font-weight: 400;">{{ nombreCompleto(item) }}</span>
              <!-- <br>
              <a href="mailto:AUDITOR2@cclalimentaria.es">
                <span style="font-weight: 400;">AUDITOR2</span>
                <span style="font-weight: 400;">@ccl</span>
              </a>
              <br>
              <span style="font-weight: 400;">983 135 660 &ndash; 625 46 91 95</span> -->
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>
                <em>Fecha de evaluaci&oacute;n</em>
              </strong>
            </p>
          </td>
          <td>
            <p>
              <strong>{{ formatDate(datos.desde) }}</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p style="text-align: center;">
              <strong>
                <em>Planificaci&oacute;n de la Auditor&iacute;a de certificaci&oacute;n&nbsp;</em>
              </strong>
            </p>
            <p style="text-align: center;">
              <strong>
                <em>PRODUCCI&Oacute;N ECOL&Oacute;GICA</em>
              </strong>
            </p>
            <p style="text-align: center;">
              <strong>
                <em>
                  OPERADOR:&nbsp;
                  <span v-if="datos.solicitud.params">{{ datos.solicitud.params.tipo_operador.map(e => TIPO_OPERADORES[e]).join(', ') }}</span>
                </em>
              </strong>
            </p>
            <p style="text-align: center;">
              <strong>
                <em>
                  ALCANCE DE LA CERTIFICACI&Oacute;N:&nbsp;
                  <span v-if="datos.solicitud.params">{{ datos.solicitud.params.alcance_certificacion.map(e => TIPO_CERTIFICACION[e]).join(', ') }}</span>
                </em>
              </strong>
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p>
              <strong>
                <em>Documento normativo:&nbsp;</em>
              </strong>
            </p>
            <ul>
              <li style="font-weight: 400;">
                <strong>
                  <em>R(CE) n&ordm; 834/2007 del consejo</em>
                </strong>
                <strong>
                  <em>,</em>
                </strong>
                <em>
                  <span style="font-weight: 400;"> de 28 de junio de 2007, sobre producci&oacute;n y etiquetado de los productos ecol&oacute;gicos y sus disposiciones de aplicaci&oacute;n:&nbsp;</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <strong>
                  <em>R(CE) n&ordm; 889/2008</em>
                </strong>
                <em>
                  <span style="font-weight: 400;"> y sus modificaciones de las cuales se detalla espec&iacute;ficamente:</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Reglamento (UE) 271/2010 de la Comisi&oacute;n, de 24 marzo de 2010, que modifica el Reglamento (CE) n&ordm; 889/2008 por el que se establecen disposiciones de aplicaci&oacute;n del Reglamento (CE) n&ordm; 834/2007 del Consejo, en lo que ata&ntilde;e al </span>
                </em>
                <strong>
                  <em>logotipo de producci&oacute;n ecol&oacute;gica</em>
                </strong>
                <em>
                  <span style="font-weight: 400;"> de la Uni&oacute;n Europea.</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">REGLAMENTO (UE) 2019/2164, de 17 de Diciembre de 2019 (productos autorizados)</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Orden AYG/452/2013, de 29 de mayo, por la que se aprueba el reglamento regulador de la producci&oacute;n agraria ecol&oacute;gica y su indicaci&oacute;n sobre los productos agrarios y alimenticios y del consejo de agricultura ecol&oacute;gica.</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">procedimiento espec&iacute;fico &ndash; Armonizaci&oacute;n de criterios en el Control Oficial de la Agricultura Ecol&oacute;gica (PR66.ITA)</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Procedimiento espec&iacute;fico de Certificaci&oacute;n de Producci&oacute;n Ecol&oacute;gica de CCL Certificaci&oacute;n, S.L. (POP06).</span>
                </em>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p>
              <strong>
                <em>Tipo de visita de control</em>
              </strong>
            </p>
            <ul>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">{{ datos.tipo_visita.nombre }} (Anunciada)</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Toma de Muestra* (*</span>
                </em>
                <em>
                  <span style="font-weight: 400;">S&oacute;lo si durante el proceso de evaluaci&oacute;n se produce la sospecha de incumplimiento de la normativa de aplicaci&oacute;n de Producci&oacute;n Ecol&oacute;gica se podr&aacute; efectuar la Toma de Muestra de producto para garantizar el cumplimiento de la norma)</span>
                </em>
                <em>
                  <span style="font-weight: 400;">.</span>
                </em>
              </li>
            </ul>
            <p>
              <strong>
                <em>Actividad sujeta a la visita de control</em>
              </strong>
            </p>
            <ul>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">PRODUCTOS AGRARIOS TRANSFORMADOS PARA LA ALIMENTACI&Oacute;N HUMANA&nbsp;</span>
                </em>
              </li>
            </ul>
            <ul>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">SECTOR: ELABORACI&Oacute;N DE VINO ECOL&Oacute;GICO</span>
                </em>
              </li>
            </ul>
            <br />
            <br />
            <br />
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>
                <em>Horario</em>
              </strong>
            </p>
          </td>
          <td>
            <p>
              <strong>
                <em>Desarrollo de la auditor&iacute;a</em>
              </strong>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <br />
            <p>
              <strong>
                <em>12.00 H&nbsp;</em>
              </strong>
            </p>
          </td>
          <td>
            <p>
              <strong>
                <em>Reuni&oacute;n inicial</em>
              </strong>
            </p>
            <ul>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Presentaci&oacute;n</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Objetivo y Alcance de la Auditor&iacute;a</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Aclaraci&oacute;n de dudas acerca del Proceso de Evaluaci&oacute;n&nbsp;</span>
                </em>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <br />
            <br />
          </td>
          <td>
            <p>
              <strong>
                <em>visita de las instalaciones</em>
              </strong>
            </p>
            <p>
              <em>
                <span style="font-weight: 400;">Se realizar&aacute; visita a las instalaciones para la verificaci&oacute;n de:</span>
              </em>
            </p>
            <ul>
              <li style="font-weight: 400;">
                <strong>
                  <em>Diagrama de flujo de la producci&oacute;n ecol&oacute;gica </em>
                </strong>
                <em>
                  <span style="font-weight: 400;">con comprobaci&oacute;n in situ de elaboraci&oacute;n de producto ecol&oacute;gico.</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Comprobaci&oacute;n de la </span>
                </em>
                <strong>
                  <em>implantaci&oacute;n de medidas cautelares establecidas</em>
                </strong>
                <em>
                  <span style="font-weight: 400;"> conforme al Procedimiento interno de la empresa con respecto a la fabricaci&oacute;n de productos ecol&oacute;gicos.</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <strong>
                  <em>Almacenes y existencias de producto ecol&oacute;gico</em>
                </strong>
                <em>
                  <span style="font-weight: 400;"> &ndash; Identificaci&oacute;n y separaci&oacute;n f&iacute;sica de materias primas ecol&oacute;gicas y productos final ecol&oacute;gico.</span>
                </em>
              </li>
              <li>
                <strong>
                  <em>Etiquetado de producto ecol&oacute;gico.</em>
                </strong>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <br />
            <br />
          </td>
          <td>
            <p>
              <strong>
                <em>Auditoria de Certificaci&oacute;n</em>
              </strong>
            </p>
            <p>
              <em>
                <span style="font-weight: 400;">El operador deber&aacute; tener preparada la documentaci&oacute;n requerida para la evaluaci&oacute;n de los siguientes requisitos:</span>
              </em>
            </p>
            <ul>
              <li style="font-weight: 400;">
                <strong>Trazabilidad de la producci&oacute;n ecol&oacute;gica</strong>
                <span style="font-weight: 400;"> &ndash; Se comprobar&aacute; para cada referencia:</span>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Documentaci&oacute;n de proveedor de materias primas ecol&oacute;gicas (certificado de proveedor + documentos de compra).</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Etiquetado de producto &ndash; marcado lote de producto ecol&oacute;gico y dise&ntilde;o conforme con normativa de producci&oacute;n ecol&oacute;gica.</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Almacenamiento y distribuci&oacute;n de producto ecol&oacute;gico.</span>
                </em>
              </li>
              <li>
                <strong>Procedimiento de limpieza y desinfecci&oacute;n.</strong>
              </li>
            </ul>
            <ul>
              <li style="font-weight: 400;">
                <strong>Sistema de autocontrol </strong>
                <span style="font-weight: 400;">- Medidas cautelares establecidas en el Procedimiento interno de la empresa para:</span>
              </li>
            </ul>
            <ul>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Separar e identificaci&oacute;n la producci&oacute;n ecol&oacute;gica de la producci&oacute;n convencional.</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Reducir y/o minimizar el riesgo de contaminaci&oacute;n de la producci&oacute;n ecol&oacute;gica con productos no autorizados.</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Reducir y/o minimizar el riesgo de fraude ecol&oacute;gico en materias primas y/o la comercializaci&oacute;n de producto convencional como ecol&oacute;gico.</span>
                </em>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <br />
            <p>
              <strong>
                <em>14:00-14:30 h.</em>
              </strong>
            </p>
          </td>
          <td>
            <br />
            <p>
              <strong>
                <em>Reuni&oacute;n final</em>
              </strong>
            </p>
            <ul>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Exposici&oacute;n de las observaciones y conclusiones de auditor&iacute;a</span>
                </em>
              </li>
              <li style="font-weight: 400;">
                <em>
                  <span style="font-weight: 400;">Aclaraci&oacute;n de dudas sobre las observaciones detectadas.</span>
                </em>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
      <br />
      <br />
      <br />
      <br />
    </p>
    <p>
      <span style="font-weight: 400;">Durante auditoria deber&aacute; estar presente al menos, el responsable de certificaci&oacute;n. El programa es orientativo y susceptible de ser modificado de com&uacute;n acuerdo con el operador</span>
      <span style="font-weight: 400;">.</span>
      <span style="font-weight: 400;">&nbsp;</span>
    </p>
    <p>
      <br />
      <br />
    </p>
  </div>
</template>


<style scoped>
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 0.5px solid black;
  }
  thead {
    display: table-row-group;
  }
  tr td {
    page-break-inside: avoid;
  }
  .no-border {
    border: none !important;
  }
</style>

<script>
import MODELS from "~/models/ccl/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    TIPO_CERTIFICACION: MODELS.CERTIFICACION_TIPO,
    TIPO_OPERADORES: MODELS.OPERADORES_TIPO,
  }),
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    nombreCompleto(datos) {
      let nombre = ''
      if (datos) {
        if (datos.nombre) nombre += datos.nombre
        if (datos.primer_apellido) nombre += ' ' + datos.primer_apellido
        if (datos.segundo_apellido) nombre += ' ' + datos.segundo_apellido
        if (datos.apellido1) nombre += ' ' + datos.apellido1
        if (datos.apellido2) nombre += ' ' + datos.apellido2
        if (datos.apellidos) nombre += ' ' + datos.apellidos
      }
      return nombre
    },
  }
}
</script>