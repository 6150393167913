<template>
    <div>
      <v-chip
        v-if="value"
        class="cursor-pointer"
        outlined
        :style="`border-color: ${getColor} !important; color: ${getColor} !important;`"
      >
        {{ value }}
      </v-chip>
    </div>
  </template>
  
  <script>

  export default {
    data: () => ({
    }),
    computed: {
      isGrouped () {
        return this.params.colDef.headerName === 'Group'
      },
      getColor() {
        const data = this.params.data
        console.info('getColor',data.codigo);

        return  data.subalcance.color
         return 'orange'
      },
      value() {
        return this.isGrouped ? this.params.node.groupData['ag-Grid-AutoColumn'] : this.params.value
      }
    },
    created () {
      // console.info(this.params);
    }
  }
  </script>
  
  