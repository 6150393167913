export const state = () => ({
	list: [],
	item: {}
});

//commit mutations
export const mutations = {
	GET_LIST(state) {
		return this.list;
	},
	SET_LIST(state, items) {
		this.list = items;
	}
};


//dispatch actions
export const actions = {

	async list({commit}) {
		const res = await this.$axios.get("grupos");
		if (res) {
			commit('SET_LIST', res.data);
			return res.data;
		}
	},
	
  async delete({rootState}, grupo) {
    rootState.loading = true
    await this.$axios.delete(`grupos/${grupo.uuid}`)
    rootState.loading = false
  }

};
