<template>
  <div>

    <v-dialog v-model="ui.dialog" persistent :max-width="800">
      <v-card>
        <v-card-title>
          <v-avatar color="grey lighten-4" size="36" class="mr-2">
            <v-icon color="grey">mdi-lightning-bolt-circle</v-icon>
          </v-avatar>
          Sincronización de cambios en la nube
        </v-card-title>

        <v-card-text class="px-7">
          <v-form ref="form" v-model="ui.valid" style="height: 100%">
            <p>
              Se han detectado cambios en la aplicación mientras se encontraba sin conexión.<br>
              Es necesario sincronizar estos cambios con la nube de CertiApp.
            </p>

            <v-row>
              <v-col cols="12" v-if="!ui.loading"><v-divider></v-divider></v-col>
              <v-col class="pa-0" v-if="!ui.loading">
                <v-list
                  subheader
                  two-line
                >
                  <v-list-item
                    dense
                    v-for="(el, index) in queue"
                    :key="'queue-'+index"
                  >
                    <v-list-item-avatar>
                      <v-icon>mdi-cloud-sync-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="el.method == 'patch'">
                          Actualizar datos
                        </span>

                        <span v-if="el.method == 'post'">
                          <template v-if="el.url == 'documentos'">
                            Subir archivo al gestor documental
                          </template>
                          <template v-else>
                            Crear datos
                          </template>
                        </span>

                        <span v-if="el.method == 'delete'">
                          Eliminar
                        </span>

                      </v-list-item-title>
                      <v-list-item-subtitle>
                        /{{ el.url }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="el.saving">
                      <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions v-if="!ui.loading && !ui.saving">
          <v-spacer></v-spacer>
          <v-btn
            small text outlined
            color="success"
            @click="sync()">
            Sincronizar cambios
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="$nuxt.isOffline" top right :timeout="-1">
      <v-icon
        color="warning"
        class="mr-2"
      >
        mdi-wifi-strength-alert-outline
      </v-icon>

      Estás sin conexión a internet
    </v-snackbar>

    <v-snackbar v-model="ui.snackbar" bottom right :timeout="-1" class="offline-changes">
      <v-icon
        color="warning"
        class="mr-2"
      >
        mdi-wifi-strength-alert-outline
      </v-icon>

      Se han realizado {{ queue.length }} cambios sin conexión.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="ui.snackbar = false">
          Ocultar
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="hasQueueOnline" top right :timeout="-1">
      <v-icon
        color="warning"
        class="mr-2">
        mdi-lightning-bolt-circle
      </v-icon>

      Se han encontrado {{ queue.length }} cambios en offline

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="ui.dialog = true">
          Sincronizar ahora
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    queue.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 8th November 2021
 * Last Modified: Thu Jun 15 2023
 **/

import { mapGetters, mapState } from 'vuex'
import notifications from '~/util/notificationHelper'

export default {
  name: 'OfflineQueue',

  data: () => ({
    ui: {
      snackbar: false,
      valid: false,
      dialog: false,
      saving: false,
      loading: false,
    },
  }),

  watch: {
    isOffline: function (val) {
      if (this.isOffline) {
        this.close()
        this.notifyAboutChanges()
      } else {
        this.open({})
        this.ui.snackbar = false
      }
    },
  },

  computed: {
    isOffline: function () {
      return this.$nuxt.isOffline
    },

    isClient() {
      return this.$store.getters.isCliente
    },

    ...mapState({
      queue: (state) => state.offline.queue,
    }),

    ...mapGetters({
      queue: 'offline/getQueue',
    }),

    hasQueueOnline() {
      return this.isOffline == false && this.queue.length > 0
    },
  },

  methods: {
    open() {
      let queue = [...this.queue]
      console.warn(queue, queue.length)
      if (queue.length) this.ui.dialog = true
    },

    close() {
      this.ui.dialog = false
      this.ui.saving = false
      this.ui.loading = false
    },

    notifyAboutChanges() {
      let queue = [...this.queue]
      if (queue.length) this.ui.snackbar = true
    },

    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    //+-------------------------------------------------
    // formatFormData()
    // Formats item data to be sent to the server
    // -----
    // Created on Mon Nov 15 2021
    //+-------------------------------------------------
    formatFormData(item) {
      let data = new FormData()
      for (const key in item.data) {
        data.append(key, item.data[key])
      }

      item.data = data

      return item
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async sync() {
      this.ui.saving = true
      let queue = [...this.queue]

      for (const item of queue) {
        await this.store(item)

        if (item.url.indexOf('auditorias/') == 0) {
          this.$store.commit('offline/removeItem', item.url)
          if (item.data?.auditoria && item.data.auditoria.length > 1) {
            await this.$store.dispatch('auditorias/toggleLock', {
              uuid: item.data.auditoria,
              action: 'unlock',
            })
          }
        }
      }

      this.$store.commit('offline/resetQueue')
      // this.$store.commit('offline/resetCache')
      // this.$store.commit('offline/clearLocalData')

      this.$nuxt.$emit('offline:end')

      notifications.show({ text: 'Todos los cambios se han sincronizado correctamente' })
      this.close()

      this.$router.push('/auditorias')
    },

    async store(item) {
      item.saving = true

      try {
        // Convert item to formData
        if (item.is == 'formdata') {
          item = this.formatFormData(item)
        }

        if (item.method == 'patch') {
          await this.$axios.patch(item.url, item.data)
        } else if (item.method == 'post') {
          await this.$axios.post(item.url, item.data)
        } else if (item.method == 'delete') {
          await this.$axios.delete(item.url)
        }

        item.synced = true
        item.saving = false
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error sincronizando',
          color: 'error',
          timeout: 3000,
        })
      }
    },

    //+-------------------------------------------------
    // loadLocalData()
    // Loads and restores data from localstorage to the store
    // -----
    // Created on Wed Nov 10 2021
    //+-------------------------------------------------
    loadLocalData() {
      this.$store.commit('offline/loadLocalData')
    },

    async init() {},
  },

  mounted() {
    this.loadLocalData()
    this.init()
  },
}
</script>

<style>
.offline-changes .v-snack__wrapper {
  transform: translateY(-60px);
}
</style>
