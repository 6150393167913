export const state = () => ({
  variables: []
});

//commit mutations
export const mutations = {

  initVariables (state) {
    state.variables = []
  },
  updateVariables (state, data) {
    let exist = false
    if (state.variables.length > 0) {
      state.variables.forEach(element => {
        if (element.grupo_alcance === data.grupo_alcance && element.custom_variables === data.custom_variables) {
          exist = true
          element.variables = data.variables
        }
      })
    }
    if (!exist) state.variables.push(data)
    console.info('update_custom', state.variables)
  },
  createVariables (state, data) {
    let exist = false
    if (state.variables.length > 0) {
      state.variables.forEach(element => {
        if (element.grupo_alcance === data.grupo_alcance && element.external_uuid === data.external_uuid) {
          exist = true
          element.variables = data.variables
        }
      })
    }
    if (!exist) state.variables.push(data)
  },
  deleteVariables (state, uuid) {
    console.log("deleteVariables -> uuid", uuid)
    let idx = -1
    for (let i=0; i < state.variables.length; i++) {
      const element = state.variables[i]
      if (element.uuid === uuid) {
        idx = i
        break
      }
    }
    if (idx > -1) state.variables.splice(idx, 1)
    console.log("deleteVariables -> state.variables", state.variables)
  }
};
//dispatch actions
export const actions = {

  async list ({ rootState, commit }, body) {
    commit('initVariables')
    const res = await this.$axios.put("custom-variables", body);
    if (res) return res.data
  },

  async update ({ rootState }, element) {
    console.info('update element', element);
    await this.$axios.patch(`custom-variables/${element.custom_variables}`, element)
  },

  async create ({ rootState }, element) {
    console.info('create element', element);
    await this.$axios.post(`custom-variables`, element)
  },

  async check ({ rootState, commit, dispatch, state }, external_uuid) {
    if (state.variables.length > 0) {
      state.variables.forEach(element => {
        if (element.newVariables) {
          delete element.newVariables
          if (external_uuid) element.external_uuid = external_uuid
          dispatch('create', element)
        } else {
          delete element.newVariables
          dispatch('update', element)
        }
      });
    }
    commit('initVariables')
  },

  async delete ({ rootState }, id) {
    await this.$axios.delete(`custom-variables/${id}`)
  },

};
