export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
}

export const getters = {
  notificacionesPendientes(state) {
    return []
    return state.list.filter((element) => element.is_new)
  },
}

//dispatch actions
export const actions = {
  async list({ rootState, commit }, payload) {
    rootState.loading = true
    if (payload) {
      let url = 'notificaciones'
      if (payload.cliente) {
        url += `?cliente.uuid=${payload.cliente}`
      }
      const res = await this.$axios.get(url)
      rootState.loading = false
      if (res?.data && res.data['hydra:member']) {
        commit('SET_LIST', res.data['hydra:member'])
        return res.data['hydra:member']
      }
    } else {
      const res = await this.$axios.get('notificaciones')
      rootState.loading = false
      if (res?.data && res.data['hydra:member']) {
        commit('SET_LIST', res.data['hydra:member'])
        return res.data['hydra:member']
      }
    }
  },

  async check({ rootState }, item) {
    rootState.loading = true
    const res = await this.$axios.get(`notificaciones/${item.uuid}/check`)
    rootState.loading = false
    if (res) return res.data
  },

  async delete({ rootState }, item) {
    rootState.loading = true
    const res = await this.$axios.delete(`notificaciones/${item.uuid}`)
    rootState.loading = false
    if (res) return res.data
  },
}
