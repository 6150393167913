export default function (ctx) {

  // if (ctx.$auth.user) {
  //   if (ctx.store.getters.isOnesignalSubscribed === false) {
  //     window.OneSignal.push(["isPushNotificationsEnabled", enabled => {
  //       if (!enabled) {
  //         window.OneSignal.showSlidedownPrompt();
  //         window.OneSignal.setExternalUserId(ctx.$auth.user.uuid);
  //         window.OneSignal.setEmail(ctx.$auth.user.email);
  //         ctx.store.commit('SET_ONESIGNAL_SUBSCRIBED', true);
  //       } else {
  //         ctx.store.commit('SET_ONESIGNAL_SUBSCRIBED', true);
  //       }
  //     }]);
  //   }
  // }

}
