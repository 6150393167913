<template>
  <v-dialog v-model="value" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <span v-if="title">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field required :rules="RULES.REQUIRED" persistent-hint v-model="item.nombre" label="Nombre"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-select
                    label="Emplazamiento"
                    v-model="item.parent"
                    :items="parentSites"
                    item-text="nombre"
                    item-value="uuid"
                    :rules="RULES.REQUIRED"
                    return-object
                    :disabled="!isNew"
                    required
                ></v-select>

              </v-col>
              <v-col cols="4">
                <v-select label="Categoría" :rules="RULES.REQUIRED" required v-model="data.categoria" :items="MODEL.IBERICO.CATEGORIAS"></v-select>
              </v-col>
              <v-col cols="4">
                <v-select label="Actividad" :rules="RULES.REQUIRED" required v-model="data.actividad" :items="MODEL.IBERICO.ACTIVIDADES"></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-title>Productos</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field required :rules="RULES.REQUIRED" persistent-hint v-model="data.marca" label="Marcas comerciales"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select label="Producto" :rules="RULES.REQUIRED" required v-model="data.producto" :items="MODEL.IBERICO.PRODUCTOS_TIPO"></v-select>
              </v-col>
              <v-col cols="6">
                <v-select label="Pieza" :rules="RULES.REQUIRED" required v-model="data.pieza" :items="MODEL.IBERICO.PRODUCTOS_PIEZA"></v-select>
              </v-col>
              <v-col cols="6">
                <v-select label="Pieza" :rules="RULES.REQUIRED" required v-model="data.racial" :items="MODEL.IBERICO.PRODUCTOS_RACIAL"></v-select>
              </v-col>
              <v-col cols="6">
                <v-select label="Racial" :rules="RULES.REQUIRED" required v-model="data.alimentacion" :items="MODEL.IBERICO.PRODUCTOS_ALIMENTACION"></v-select>
              </v-col>
              <v-col cols="6">
                <v-select label="Presentación" :rules="RULES.REQUIRED" required v-model="data.presentacion" :items="MODEL.IBERICO.PRODUCTOS_PRESENTACION"></v-select>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="data.manejo" label="Productos procedentes del despiece de la canal para todas sus designaciones por alimentación, manjeo y tipo racial."></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancelar</v-btn>
        <v-btn color="primary" @click="submit()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MODEL from "~/models/certicar/solicitudes"
import RULES from "~/models/form.rules"
import customVModelMixin from '~/util/custom.vmodel.mixin'

export default {
  name: "AlcanceProductoDialog",
  mixins: [customVModelMixin],
  props: {
    title: {type: String, default: ''},
    itemSelected: {type: Object, default: null},
    parentSites: {type: Array, default: []},
    firstChildrenSite: {type: Object, default: []},
  },
  components: {
  },
  data: () => ({
    MODEL,
    RULES,
    item: {},
    data: {},
    isNew: false
  }),
  watch: {
    value(val) {
      if (val === true) {
        this.item = this.itemSelected
        if (this.item && this.item.parent_uuid) {
          const parents = this.parentSites.filter(parent => {
            return parent.uuid === this.item.parent_uuid
          })
          this.item.parent = (parents.length > 0) ? parents[0] : {}
        }
      } else {
        this.item = {};
        this.data = {};
      }
      this.formatData();
    }
  },
  computed: {
    submitEventName() {
      return (this.isNew) ? 'create' : 'update';
    }
  },
  methods: {
    formatData() {
      if (this.item == null) {
        this.data = {};
        this.item = {parent: {}};
        this.isNew = true;
        return;
      }

      if (this.item && this.item.site_data) {
        for (const property of this.item.site_data) {
          this.data[property.keyname] = property.value;
        }
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let payload = {
        uuid: this.item.uuid,
        nombre: this.item.nombre,
        data: []
      };


      if (this.isNew) {
        payload = Object.assign({}, this.firstChildrenSite)
        payload.uuid = this.$uuid.v4()
        payload.nombre = this.item.nombre
        payload.parent = this.item.parent.uuid
        payload.source_iteracion_uuid = payload.uuid
        payload.source_parent_uuid = this.item.parent.source_iteracion_uuid  //todo: buscar de db el parente_uuid del site marcado como parent.
        payload.data = []
      }

      for (const item in this.data) {
        payload.data.push({
          'key': item,
          'value': this.data[item]
        });
      }

      this.$emit(this.submitEventName, payload);
      this.$emit('close')
    },
    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },

  }
};
</script>
