<template>
  
    <div style="padding:10px">
      <template v-for="toma_muestra in this.datos.toma_muestras">
         <template v-for="muestras in toma_muestra.muestras" :key="">
           <template v-if="muestras.tipo =='INICIAL' && muestras.laboratorio !=='' && muestras.params.length ">
            <table class="Normal tm5 tm6 tm7" style="width: 100%; height: 80px; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr style="height: 91px;">
                    <td class="tm9 tm10 tm11" style="width: 27.7485%; height: 80px; text-align:center;" rowspan="2">
                  <strong><span class="tm13"> <img style="display: block; margin-left: auto; margin-right: auto;" src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" alt="" width="161" height="74" /></span></strong>
                  <h1 style="font-weight: 700; margin:0px !important" >araporcei</h1>
                    </td>
                    <td class="tm9 tm10 tm14" style="width: 67.3355%; height: 61px; text-align: center;">
                    <p><strong><span class="tm13">PARTE DE ENVIO DE MUESTRAS AL LABORATORIO</span></strong></p>
                    <p><strong><span class="tm13">ETG JAMON SERRANO</span></strong></p>
                    </td>
                    </tr>
                    <tr style="height: 61px;">
                    <td class="tm9 tm10 tm14" style="width: 67.3355%; height: 19px;">
                    <strong><span class="tm13">CODIGO INFORME AUDITORIA:{{"  "+toma_muestra.codigo}}</span></strong><br>
                    <strong> </strong> <strong><span class="tm13">FECHA:{{"  "+formatDate(toma_muestra.created_at)}}</span></strong><br>
                    <strong> </strong> <strong><span class="tm13">Tipo de Muestra: {{muestras.tipo_muestra}}</span></strong>
                    </td>
                    </tr>
                </tbody>

            </table>
            <table class="Normal tm5 tm6 tm7" style="width: 100%; height: 265px; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr style="height: 53px;">
                        <td class="tm9 tm10 tm11" style="width: 21.1374%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">IDENTIFICACION ETIQUETA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm14" style="width: 15.6593%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">N&ordm; LOTE PRODUCTO</span></strong></p>
                        </td>
                        <td class="tm9 tm15 tm16" style="width: 13.6672%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">COLOR ETIQUETA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm17" style="width: 12.5716%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">N&ordm; DE MUESTRA</span></strong></p>
                        </td>
                        <td class="tm9 tm15 tm18" style="width: 14.2648%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">ESPESOR LONCHA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm17" style="width: 14.3644%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">TIPO DE MUESTRA</span></strong></p>
                        </td>
                    </tr>
                    
                      
                          
                          <tr style="height: 53px;" v-for="muestra in muestras.params" :key="muestra.id">
                              <td class="tm20 tm21 tm11" style="width: 21.1374%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.codigo_muestra}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm14" style="width: 15.6593%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestras.lote}}</span></p>
                              </td>
                              <td class="tm20 tm23 tm16" style="width: 13.6672%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.marca_comercial}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm17" style="width: 12.5716%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.numero_muestras_primarias}}</span></p>
                              </td>
                              <td class="tm20 tm23 tm18" style="width: 14.2648%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.matriz}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm17" style="width: 14.3644%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestras.tipo}}</span></p>
                              </td>
                          </tr>
                          
                        
                    
                    
                </tbody>
            </table>
            <p class="Normal">&nbsp;</p>

            <p class="Normal tm5"><span class="tm6">Conforme a los documentos normativos, el auditor tomar&aacute; la muestra o en su caso pedir&aacute; a la empresa que lo haga de un jam&oacute;n deshuesado y sin corteza, se obtendr&aacute; una cortada de como m&iacute;nimo </span><strong><u><span class="tm7">10 cm</span></u><span class="tm7"> de espesor</span></strong><span class="tm6">, tomada a 4 cm por encima de la cabeza del f&eacute;mur y perpendicular a este hueso. El espesor de la cortada podr&aacute; aumentarse por exigencias de preparaci&oacute;n, por parte del laboratorio subcontratado, de las siguientes sub-muestras: </span></p>
            <p class="Normal tm9" style="padding-left: 40px;"><span class="tm6">1.- La muestra con etiqueta </span><strong><span class="tm7">azul</span></strong><span class="tm6"> ser&aacute; destinada a la realizaci&oacute;n del a</span><span class="tm6">n&aacute;lisis </span><strong><span class="tm7">f&iacute;sico &ndash; qu&iacute;mico</span></strong><span class="tm6"> de conformidad con el pliego de condiciones de la ETG Jam&oacute;n Serrano. La cantidad m&iacute;nima de muestra necesaria para llevar a cabo el an&aacute;lisis es una porci&oacute;n transversal al eje longitudinal al jam&oacute;n, de espesor m&iacute;nimo de </span><strong><u><span class="tm7">2 cm.</span></u></strong><span class="tm6"> y envasada al vac&iacute;o. </span><span class="tm6">&nbsp;</span></p>
            <p class="Normal tm9" style="padding-left: 40px;"><span class="tm6">2.-La muestra con etiqueta</span><strong><span class="tm7"> rojo</span></strong><span class="tm6"> ser&aacute; destinada a la realizaci&oacute;n del an&aacute;lisis </span><strong><span class="tm7">organol&eacute;ptico</span></strong><span class="tm6"> de conformidad con el pliego de condiciones de la ETG Jam&oacute;n Serrano. La cantidad m&iacute;nima de muestra necesaria para llevar a cabo el an&aacute;lisis es una porci&oacute;n transversal al eje longitudinal al jam&oacute;n, de espesor m&iacute;nimo de </span><strong><u><span class="tm7">5 cm.</span></u></strong><span class="tm6"> y envasada al vac&iacute;o, paralela a la loncha obtenida para el an&aacute;lisis f&iacute;sico &ndash; qu&iacute;mico. </span></p>

            <hr>

            <p class="Normal tm5"><span class="tm6">El auditor de ARAPORCEI ha llevado a cabo la toma de muestras</span><span class="tm6">, codific&aacute;ndolas y sell&aacute;ndolas con las correspondientes etiquetas/precintos, entreg&aacute;ndolas al laboratorio designado para el an&aacute;lisis de las mismas. La correcta conservaci&oacute;n de las muestras es responsabilidad del laboratorio. La empresa auditada tiene a su disposici&oacute;n un juego de muestras para la realizaci&oacute;n del an&aacute;lisis contradictorio si procede que se encuentra custodiado en el laboratorio. El juego de muestras para la realizaci&oacute;n del an&aacute;lisis dirimente queda en custodia en el laboratorio y es propiedad de ARAPORCEI. </span></p>
            <p class="Normal tm5"><span class="tm6">Ante cualquier manipulaci&oacute;n de envases, bolsas y precintos de seguridad, ARAPORCEI invalidar&aacute; las muestras.</span><span class="tm7">&nbsp;</span></p>
            <p class="Normal tm5"><strong><span class="tm8">Este registro se cumplimenta por duplicado: un ejemplar para el laboratorio y el original que lo archiva y custodia ARAPORCEI.</span></strong></p>

            <table class="Normal tm5 tm6 tm7" style="width: 41.1227%; margin-left: auto; margin-right: auto; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr>
                        <td class="tm9 tm10 tm11" style="width: 100%;">
                        <p class="Normal tm12" style="padding: 10px"><strong><span class="tm13">Firma:</span></strong></p>
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">&nbsp;</span></strong></p>
                        <p class="Normal tm12"  style="padding: 10px"><strong><span class="tm13">Fecha:</span></strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td class="tm15 tm10 tm11" style="width: 100%;">
                        <p class="Normal tm12"  style="padding: 10px"><strong><span class="tm13">Fdo: ARAPORCEI</span></strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="Normal" style="text-align: center;">&nbsp;</p>
            <p><div class="page-break-before"></div></p>
      </template>

      <template v-if="muestras.tipo =='DIRIMENTE' && muestras.laboratorio !=='' && muestras.params.length ">
            <table class="Normal tm5 tm6 tm7" style="width: 100%; height: 80px; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr style="height: 91px;">
                    <td class="tm9 tm10 tm11" style="width: 27.7485%; height: 80px; text-align:center;" rowspan="2">
                  <strong><span class="tm13"> <img style="display: block; margin-left: auto; margin-right: auto;" src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" alt="" width="161" height="74" /></span></strong>
                  <h1 style="font-weight: 700; margin:0px !important" >araporcei</h1>
                    </td>
                    <td class="tm9 tm10 tm14" style="width: 67.3355%; height: 61px; text-align: center;">
                    <p><strong><span class="tm13">PARTE DE ENVIO DE MUESTRAS AL LABORATORIO</span></strong></p>
                    <p><strong><span class="tm13">ETG JAMON SERRANO</span></strong></p>
                    </td>
                    </tr>
                    <tr style="height: 61px;">
                    <td class="tm9 tm10 tm14" style="width: 67.3355%; height: 19px;">
                    <strong><span class="tm13">CODIGO INFORME AUDITORIA:{{"  "+toma_muestra.codigo}}</span></strong><br>
                    <strong> </strong> <strong><span class="tm13">FECHA:{{"  "+formatDate(toma_muestra.created_at)}}</span></strong><br>
                    <strong> </strong> <strong><span class="tm13">Tipo de Muestra: {{muestras.tipo_muestra}}</span></strong>
                    </td>
                    </tr>
                </tbody>

            </table>
            <table class="Normal tm5 tm6 tm7" style="width: 100%; height: 265px; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr style="height: 53px;">
                        <td class="tm9 tm10 tm11" style="width: 21.1374%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">IDENTIFICACION ETIQUETA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm14" style="width: 15.6593%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">N&ordm; LOTE PRODUCTO</span></strong></p>
                        </td>
                        <td class="tm9 tm15 tm16" style="width: 13.6672%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">COLOR ETIQUETA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm17" style="width: 12.5716%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">N&ordm; DE MUESTRA</span></strong></p>
                        </td>
                        <td class="tm9 tm15 tm18" style="width: 14.2648%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">ESPESOR LONCHA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm17" style="width: 14.3644%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">TIPO DE MUESTRA</span></strong></p>
                        </td>
                    </tr>
                    
                      
                          
                          <tr style="height: 53px;" v-for="muestra in muestras.params" :key="muestra.id">
                              <td class="tm20 tm21 tm11" style="width: 21.1374%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.codigo_muestra}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm14" style="width: 15.6593%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestras.lote}}</span></p>
                              </td>
                              <td class="tm20 tm23 tm16" style="width: 13.6672%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.marca_comercial}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm17" style="width: 12.5716%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.numero_muestras_primarias}}</span></p>
                              </td>
                              <td class="tm20 tm23 tm18" style="width: 14.2648%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.matriz}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm17" style="width: 14.3644%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestras.tipo}}</span></p>
                              </td>
                          </tr>
                          
                        
                    
                    
                </tbody>
            </table>
            <p class="Normal">&nbsp;</p>

            <p class="Normal tm5"><span class="tm6">Conforme a los documentos normativos, el auditor tomar&aacute; la muestra o en su caso pedir&aacute; a la empresa que lo haga de un jam&oacute;n deshuesado y sin corteza, se obtendr&aacute; una cortada de como m&iacute;nimo </span><strong><u><span class="tm7">10 cm</span></u><span class="tm7"> de espesor</span></strong><span class="tm6">, tomada a 4 cm por encima de la cabeza del f&eacute;mur y perpendicular a este hueso. El espesor de la cortada podr&aacute; aumentarse por exigencias de preparaci&oacute;n, por parte del laboratorio subcontratado, de las siguientes sub-muestras: </span></p>
            <p class="Normal tm9" style="padding-left: 40px;"><span class="tm6">1.- La muestra con etiqueta </span><strong><span class="tm7">azul</span></strong><span class="tm6"> ser&aacute; destinada a la realizaci&oacute;n del a</span><span class="tm6">n&aacute;lisis </span><strong><span class="tm7">f&iacute;sico &ndash; qu&iacute;mico</span></strong><span class="tm6"> de conformidad con el pliego de condiciones de la ETG Jam&oacute;n Serrano. La cantidad m&iacute;nima de muestra necesaria para llevar a cabo el an&aacute;lisis es una porci&oacute;n transversal al eje longitudinal al jam&oacute;n, de espesor m&iacute;nimo de </span><strong><u><span class="tm7">2 cm.</span></u></strong><span class="tm6"> y envasada al vac&iacute;o. </span><span class="tm6">&nbsp;</span></p>
            <p class="Normal tm9" style="padding-left: 40px;"><span class="tm6">2.-La muestra con etiqueta</span><strong><span class="tm7"> rojo</span></strong><span class="tm6"> ser&aacute; destinada a la realizaci&oacute;n del an&aacute;lisis </span><strong><span class="tm7">organol&eacute;ptico</span></strong><span class="tm6"> de conformidad con el pliego de condiciones de la ETG Jam&oacute;n Serrano. La cantidad m&iacute;nima de muestra necesaria para llevar a cabo el an&aacute;lisis es una porci&oacute;n transversal al eje longitudinal al jam&oacute;n, de espesor m&iacute;nimo de </span><strong><u><span class="tm7">5 cm.</span></u></strong><span class="tm6"> y envasada al vac&iacute;o, paralela a la loncha obtenida para el an&aacute;lisis f&iacute;sico &ndash; qu&iacute;mico. </span></p>

            <hr>

            <p class="Normal tm5"><span class="tm6">El auditor de ARAPORCEI ha llevado a cabo la toma de muestras</span><span class="tm6">, codific&aacute;ndolas y sell&aacute;ndolas con las correspondientes etiquetas/precintos, entreg&aacute;ndolas al laboratorio designado para el an&aacute;lisis de las mismas. La correcta conservaci&oacute;n de las muestras es responsabilidad del laboratorio. La empresa auditada tiene a su disposici&oacute;n un juego de muestras para la realizaci&oacute;n del an&aacute;lisis contradictorio si procede que se encuentra custodiado en el laboratorio. El juego de muestras para la realizaci&oacute;n del an&aacute;lisis dirimente queda en custodia en el laboratorio y es propiedad de ARAPORCEI. </span></p>
            <p class="Normal tm5"><span class="tm6">Ante cualquier manipulaci&oacute;n de envases, bolsas y precintos de seguridad, ARAPORCEI invalidar&aacute; las muestras.</span><span class="tm7">&nbsp;</span></p>
            <p class="Normal tm5"><strong><span class="tm8">Este registro se cumplimenta por duplicado: un ejemplar para el laboratorio y el original que lo archiva y custodia ARAPORCEI.</span></strong></p>

            <table class="Normal tm5 tm6 tm7" style="width: 41.1227%; margin-left: auto; margin-right: auto; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr>
                        <td class="tm9 tm10 tm11" style="width: 100%;">
                        <p class="Normal tm12" style="padding: 10px"><strong><span class="tm13">Firma:</span></strong></p>
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">&nbsp;</span></strong></p>
                        <p class="Normal tm12"  style="padding: 10px"><strong><span class="tm13">Fecha:</span></strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td class="tm15 tm10 tm11" style="width: 100%;">
                        <p class="Normal tm12"  style="padding: 10px"><strong><span class="tm13">Fdo: ARAPORCEI</span></strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="Normal" style="text-align: center;">&nbsp;</p>
            <p><div class="page-break-before"></div></p>
      </template>

      <template v-if="muestras.tipo =='CONTRADICTORIO' && muestras.laboratorio !=='' && muestras.params.length ">
            <table class="Normal tm5 tm6 tm7" style="width: 100%; height: 80px; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr style="height: 91px;">
                    <td class="tm9 tm10 tm11" style="width: 27.7485%; height: 80px; text-align:center;" rowspan="2">
                  <strong><span class="tm13"> <img style="display: block; margin-left: auto; margin-right: auto;" src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" alt="" width="161" height="74" /></span></strong>
                  <h1 style="font-weight: 700; margin:0px !important" >araporcei</h1>
                    </td>
                    <td class="tm9 tm10 tm14" style="width: 67.3355%; height: 61px; text-align: center;">
                    <p><strong><span class="tm13">PARTE DE ENVIO DE MUESTRAS AL LABORATORIO</span></strong></p>
                    <p><strong><span class="tm13">ETG JAMON SERRANO</span></strong></p>
                    </td>
                    </tr>
                    <tr style="height: 61px;">
                    <td class="tm9 tm10 tm14" style="width: 67.3355%; height: 19px;">
                   <strong><span class="tm13">CODIGO INFORME AUDITORIA:{{"  "+toma_muestra.codigo}}</span></strong><br>
                    <strong> </strong> <strong><span class="tm13">FECHA:{{"  "+formatDate(toma_muestra.created_at)}}</span></strong><br>
                    <strong> </strong> <strong><span class="tm13">Tipo de Muestra: {{muestras.tipo_muestra}}</span></strong>
                    </td>
                    </tr>
                </tbody>

            </table>
            <table class="Normal tm5 tm6 tm7" style="width: 100%; height: 265px; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr style="height: 53px;">
                        <td class="tm9 tm10 tm11" style="width: 21.1374%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">IDENTIFICACION ETIQUETA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm14" style="width: 15.6593%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">N&ordm; LOTE PRODUCTO</span></strong></p>
                        </td>
                        <td class="tm9 tm15 tm16" style="width: 13.6672%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">COLOR ETIQUETA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm17" style="width: 12.5716%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">N&ordm; DE MUESTRA</span></strong></p>
                        </td>
                        <td class="tm9 tm15 tm18" style="width: 14.2648%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">ESPESOR LONCHA</span></strong></p>
                        </td>
                        <td class="tm9 tm10 tm17" style="width: 14.3644%; height: 53px;">
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">TIPO DE MUESTRA</span></strong></p>
                        </td>
                    </tr>
                    
                      
                          
                          <tr style="height: 53px;" v-for="muestra in muestras.params" :key="muestra.id">
                              <td class="tm20 tm21 tm11" style="width: 21.1374%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.codigo_muestra}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm14" style="width: 15.6593%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestras.lote}}</span></p>
                              </td>
                             <td class="tm20 tm23 tm16" style="width: 13.6672%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.marca_comercial}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm17" style="width: 12.5716%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.numero_muestras_primarias}}</span></p>
                              </td>
                              <td class="tm20 tm23 tm18" style="width: 14.2648%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestra.matriz}}</span></p>
                              </td>
                              <td class="tm20 tm21 tm17" style="width: 14.3644%; height: 53px;">
                              <p class="Normal tm12"><span class="tm22">{{muestras.tipo}}</span></p>
                              </td>
                          </tr>
                          
                        
                    
                    
                </tbody>
            </table>
            <p class="Normal">&nbsp;</p>

            <p class="Normal tm5"><span class="tm6">Conforme a los documentos normativos, el auditor tomar&aacute; la muestra o en su caso pedir&aacute; a la empresa que lo haga de un jam&oacute;n deshuesado y sin corteza, se obtendr&aacute; una cortada de como m&iacute;nimo </span><strong><u><span class="tm7">10 cm</span></u><span class="tm7"> de espesor</span></strong><span class="tm6">, tomada a 4 cm por encima de la cabeza del f&eacute;mur y perpendicular a este hueso. El espesor de la cortada podr&aacute; aumentarse por exigencias de preparaci&oacute;n, por parte del laboratorio subcontratado, de las siguientes sub-muestras: </span></p>
            <p class="Normal tm9" style="padding-left: 40px;"><span class="tm6">1.- La muestra con etiqueta </span><strong><span class="tm7">azul</span></strong><span class="tm6"> ser&aacute; destinada a la realizaci&oacute;n del a</span><span class="tm6">n&aacute;lisis </span><strong><span class="tm7">f&iacute;sico &ndash; qu&iacute;mico</span></strong><span class="tm6"> de conformidad con el pliego de condiciones de la ETG Jam&oacute;n Serrano. La cantidad m&iacute;nima de muestra necesaria para llevar a cabo el an&aacute;lisis es una porci&oacute;n transversal al eje longitudinal al jam&oacute;n, de espesor m&iacute;nimo de </span><strong><u><span class="tm7">2 cm.</span></u></strong><span class="tm6"> y envasada al vac&iacute;o. </span><span class="tm6">&nbsp;</span></p>
            <p class="Normal tm9" style="padding-left: 40px;"><span class="tm6">2.-La muestra con etiqueta</span><strong><span class="tm7"> rojo</span></strong><span class="tm6"> ser&aacute; destinada a la realizaci&oacute;n del an&aacute;lisis </span><strong><span class="tm7">organol&eacute;ptico</span></strong><span class="tm6"> de conformidad con el pliego de condiciones de la ETG Jam&oacute;n Serrano. La cantidad m&iacute;nima de muestra necesaria para llevar a cabo el an&aacute;lisis es una porci&oacute;n transversal al eje longitudinal al jam&oacute;n, de espesor m&iacute;nimo de </span><strong><u><span class="tm7">5 cm.</span></u></strong><span class="tm6"> y envasada al vac&iacute;o, paralela a la loncha obtenida para el an&aacute;lisis f&iacute;sico &ndash; qu&iacute;mico. </span></p>

            <hr>

            <p class="Normal tm5"><span class="tm6">El auditor de ARAPORCEI ha llevado a cabo la toma de muestras</span><span class="tm6">, codific&aacute;ndolas y sell&aacute;ndolas con las correspondientes etiquetas/precintos, entreg&aacute;ndolas al laboratorio designado para el an&aacute;lisis de las mismas. La correcta conservaci&oacute;n de las muestras es responsabilidad del laboratorio. La empresa auditada tiene a su disposici&oacute;n un juego de muestras para la realizaci&oacute;n del an&aacute;lisis contradictorio si procede que se encuentra custodiado en el laboratorio. El juego de muestras para la realizaci&oacute;n del an&aacute;lisis dirimente queda en custodia en el laboratorio y es propiedad de ARAPORCEI. </span></p>
            <p class="Normal tm5"><span class="tm6">Ante cualquier manipulaci&oacute;n de envases, bolsas y precintos de seguridad, ARAPORCEI invalidar&aacute; las muestras.</span><span class="tm7">&nbsp;</span></p>
            <p class="Normal tm5"><strong><span class="tm8">Este registro se cumplimenta por duplicado: un ejemplar para el laboratorio y el original que lo archiva y custodia ARAPORCEI.</span></strong></p>

            <table class="Normal tm5 tm6 tm7" style="width: 41.1227%; margin-left: auto; margin-right: auto; border:1px solid black;" border="0" rules="all">
                <tbody class="tm8">
                    <tr>
                        <td class="tm9 tm10 tm11" style="width: 100%;">
                        <p class="Normal tm12" style="padding: 10px"><strong><span class="tm13">Firma:</span></strong></p>
                        <p class="Normal tm12" style="text-align: center;"><strong><span class="tm13">&nbsp;</span></strong></p>
                        <p class="Normal tm12"  style="padding: 10px"><strong><span class="tm13">Fecha:</span></strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td class="tm15 tm10 tm11" style="width: 100%;">
                        <p class="Normal tm12"  style="padding: 10px"><strong><span class="tm13">Fdo: ARAPORCEI</span></strong></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="Normal" style="text-align: center;">&nbsp;</p>
            <p><div class="page-break-before"></div></p>
      </template>



    </template>
  </template>
    </div>
  
</template>

<style scoped>
@media screen {
  div.footer {
    display: none;
  }
}

@media print {
  div.footer {
    border-top: 2px solid #F47BA9;
    margin-top: 10px;
    position: fixed;
    bottom: 0;
  }
  div.footer p {
    text-align: center;
  }
}

table {
  border-collapse: collapse;
}

table, th, td {
  border: 0.5px solid black;
}

thead {
  display: table-row-group;
}

tr td {
  page-break-inside: avoid;
}

.no-border {
  border: none !important;
}
</style>

<script>
import MODELS from "~/models/araporcei/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    tipoOperador: MODELS.OPERADORES_TIPO,
    tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
    tipoProducto: MODELS.PRODUCTO_TIPO,
    tipoRacial: MODELS.RACIAL_TIPO,
    tipoEstado: MODELS.ESTADOS_TIPO,
    tipoPresentacion: MODELS.PRESENTACION_TIPO,
    tipoCertificacion: MODELS.CERTIFICACION_TIPO,
  }),
  computed: {
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    alcance () {
      return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
    },
    instalaciones () {
      const actividades = this.datos
        && this.datos.solicitud
        && this.datos.solicitud.params.alcance_producto.map(e => e.rgseaa)
        || []
      return this.datos.cliente.instalaciones.filter(e => actividades.includes(e.rgseaa))
    },
    actividades () {
      const planificaciones = this.datos.planificaciones
      const sites = this.datos.sites

      const planificacionesSites = planificaciones.map(e => e.site.uuid)

      const childrenSites = sites.filter(e => e.parent_uuid)

      return childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
      // return childrenSites.length
      //   ? childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
      //   : sites.filter(e => planificacionesSites.includes(e.uuid))
    },
    auditores () {
      const auditores = []
      const planificaciones = this.datos.planificaciones && this.datos.planificaciones
      if (planificaciones) {
        for (let i=0; i < planificaciones.length; i++) {
          const planificacion = planificaciones[i]
          if (!auditores.includes(planificacion.auditor)) auditores.push(planificacion.auditor)
        }
      }
      return auditores.join(', ')
    },
    planificaciones_data () {
      return this.datos && this.datos.planificaciones.map(element => element.data)
    },
    solicitud_data () {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach(element => {
          data[element.keyname] = element.value
        })
        return data
      }
      else return {}
    },
    site_data () {
      if (this.datos.isCliente) return this.datos.isCliente
      else if (this.datos.sites) {
        let data = {}
        let tipos = []

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
        }

        for (let i=0; i < tipos.length; i++) {
          const element = tipos[i]
          data[element] = []
        }

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          let obj = {}
          obj.uuid = element.uuid
          for (let k=0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data[element.tipo].push(obj)
        }

        return data
      }
      else return {}
    },
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    formatTime (date) {
      return date ? this.$moment(date).format('HH:mm') : ''
    },
  }
}
</script>