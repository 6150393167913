export default {
  REQUIRED: [v => !!v || 'Campo obligatorio'],
  REQUIRED_MULTIPLE: [v => !!v && !!v.length || "Campo obligatorio"],
  EMAIL: [v => !!v || 'Email obligatorio', v => /.+@.+\..+/.test(v) || 'E-mail inválido'],
  NUMBER: [v => Number.isInteger(Number(v)) || 'El valor ingresado debe ser un número entero'],
  //if is boolean, accept 0 and 1
  BOOLEAN: [v => (v === 0 || v === 1 || v === false || v === true) || 'Campo obligatorio'],
  //required but accept 0
  REQUIRED_NUMBER: [v => v === 0 || !!v || 'Campo obligatorio'],
  
}
