import ROLE from './role'

export default [
  {
    name: {
      es_ES: 'Solicitudes',
      en_US: 'Requests'
    },
    block: 'administracion',
    modulo: 'solicitudes',
    type: 'menu',
    link: null,
    icon: 'mdi-text-box-check-outline',
    role: ROLE.ROLE_AUDITOR,
    items: [
      {
        name: {
          es_ES: 'Ver solicitudes'
        },
        submodulo: 'solicitudes',
        link: 'portal/solicitudes',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_AUDITOR
      },
      {
        name: {
          es_ES: 'Crear nueva'
        },
        submodulo: 'nueva-solicitud',
        link: 'portal/solicitudes/create',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_AUDITOR
      }
    ]
  },

  {
    name: {
      es_ES: 'Auditorías'
    },
    block: 'administracion',
    modulo: 'auditorias',
    type: 'link',
    link: 'portal/auditorias',
    icon: 'mdi-ballot-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE
  }
]
