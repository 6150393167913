<template>
  <div>
    <table style="width: 100%">
      <tbody style="font-weight: 400;">
        <tr style="text-align: center">
          <td colspan="1">
            <img src="https://www.kaam.es/images/logo.png" height="60px" width="60px">
            <h2 style="font-weight: 700" >kaam</h2>
          </td>
          <td colspan="2">
            <h2>
              Solicitud de Certificación
              <br>
              Productos Ibéricos
            </h2>
          </td>
        </tr>
      </tbody>
    </table>

    <p>
      <span style="font-weight: 400;">Fecha Solicitud: {{ formatDate(created_at) }} </span>
    </p>
    <p>
      <span style="font-weight: 400;">Tipo Solicitud: {{ tipoSolicitud[params.tipo_solicitud] }} </span>
    </p>
    <p>
      <span style="font-weight: 400;">DATOS DEL SOLICITANTE (TITULAR DE LA CERTIFICACION): </span>
    </p>
    <p>
      <strong>{{ clienteNombreCompleto }}</strong>
    </p>
    <p>
      <span style="font-weight: 400;">{{ cliente && cliente.direccion }}</span>
        <span v-if="cliente.comunidad">{{ cliente.comunidad.nombre }}</span>
        <span v-if="cliente.provincia">{{ ', ' + cliente.provincia.nombre }}</span>
        <span v-if="cliente.municipio">{{ ', ' + cliente.municipio.nombre }}</span>
        <span v-if="cliente.cp"><br>{{ cliente.cp }}</span>
    </p>
    <p v-if="instalaciones.length">
      <span style="font-weight: 400;">DATOS RELATIVOS A LAS INSTALACIONES EN LAS QUE SE REALIZA LA ACTIVIDAD</span>
    </p>
    <ul v-if="instalaciones.length">
      <li style="font-weight: 400;">
        <span v-for="instalacion in instalaciones" :key="instalacion.id" style="font-weight: 400;">
          RGSEAA: {{ instalacion.rgseaa }}
          <br>
          Razón social: {{ instalacion.razon_social }}
          <br>
          Domicilio Industrial: {{ instalacion.direccion }}
          <span v-if="instalacion.comunidad">{{ instalacion.comunidad.nombre }}</span>
          <span v-if="instalacion.provincia">{{ ', ' + instalacion.provincia.nombre }}</span>
          <span v-if="instalacion.municipio">{{ ', ' + instalacion.municipio.nombre }}</span>
          <span v-if="instalacion.cp">{{ instalacion.cp }}</span>
          <br>
          Actividades que realiza: {{ instalacion.tramites.map(e => e.nombre).join(', ') }}
          <br>
          Observaciones: {{ instalacion.observaciones }}
        </span>
      </li>
    </ul><p>&nbsp;</p>

    <table>
      <tbody>
        <tr>
          <td>
            <p>
              <strong>DATOS RELATIVOS AL ALCANCE DE LA PRODUCCION</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span style="font-weight: 400;">☐</span>
              <span style="font-weight: 400;"> Se solicita que toda la producci&oacute;n este amparada por la certificaci&oacute;n de Kaam.</span>
            </p>
            <p>
              <span style="font-weight: 400;">☐ </span>
              <span style="font-weight: 400;">Se solicita que se excluya parte de la certificaci&oacute;n porque est&aacute; bajo el control de la Entidad de Certificaci&oacute;n del propietario del producto seg&uacute;n lo dispuesto en el art&iacute;culo 14.3 del RD 4/2014.(solo para el caso de operadores que prestan servicio a maquila)&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <p>
      <strong>SOLICITA:</strong>
      <strong></strong>
    </p>
    <p>
      <span style="font-weight: 400;">1.- Ser evaluado para verificar la competencia t&eacute;cnica de la empresa conforme a los siguientes documentos:</span>
    </p>
    <p>
      <span style="font-weight: 400;">a.- R.D. 4/2014, de 10 de enero, por el que se aprueba la norma de calidad para la carne, el jam&oacute;n, la paleta y la ca&ntilde;a de lomo ib&eacute;rico. Y Protocolo de Certificaci&oacute;n en vigor. Ministerio de Agricultura y Pesca, y Alimentaci&oacute;n Medio.</span>
    </p>
    <p>
      <strong>DECLARA:</strong>
      <span style="font-weight: 400;"></span>
      <span style="font-weight: 400;"></span>
    </p>
    <p>
      <span style="font-weight: 400;">1.- Conocer y aceptar el procedimiento de certificaci&oacute;n de producto denominado PG-CER-03.</span>
    </p>
    <p>
      <span style="font-weight: 400;">2.- Que la veracidad de los datos contenidos en este registro queda bajo la responsabilidad de la empresa solicitante de la certificaci&oacute;n.</span>
    </p>

    <!-- Pagina 2 -->
    <div v-if="isModificacion">
      <div class="page-break-before"></div>
      <h2 style="text-align: center;">Solicitud de Modificación de Alcance</h2>
      <p>&nbsp;</p>
      <p>
        <span style="font-weight: 400;">Tipo Modiciación: {{ params.tipo_modificacion }} </span>
      </p>
      <p>
        <span style="font-weight: 400;">Producto: {{ params.producto }} </span>
      </p>
      <p>
        <span style="font-weight: 400;">Proceso/Activida: {{ params.proceso_actividad }} </span>
      </p>
      <p>
        <span style="font-weight: 400;">Operador Subcontratado Certificado: {{ params.operador_certificado }} </span>
      </p>
      <p>
        <span style="font-weight: 400;">Operador Subcontratado No Certificado: {{ params.operador_no_certificado }} </span>
      </p>
      <p>
        <span style="font-weight: 400;">Marca Comercial: {{ params.marca_comercial }} </span>
      </p>
      <p>
        <span style="font-weight: 400;">Otros motivos: {{ params.otros_motivos }} </span>
      </p>
      <p>
        <span style="font-weight: 400;">Observaciones: {{ params.observaciones }} </span>
      </p>
    </div>

    <!-- Pagina 3 -->
    <div class="page-break-before"></div>
    <!-- Alcance Proceso -->
    <h2 style="text-align: center;">Alcance de Proceso (Actividades)</h2>
    <p>&nbsp;</p>
    <div v-if="!isOperadorResposable">
      <div>
        <span class="pa-2 font-weight-bold">Actividades Realizadas: </span>
        <span>{{ params && params.alcance_proceso.realizadas.map(e => e.nombre).join(', ') }}</span>
      </div>
      <div>
        <span class="pa-2 font-weight-bold">Actividades Subcontratadas: </span>
        <span>{{ params && params.alcance_proceso.subcontratadas.map(e => e.nombre).join(', ') }}</span>
      </div>
      <div>
        <span class="pa-2 font-weight-bold">Certificación: </span>
        <span>{{ params && params.alcance_proceso.certificacion }}</span>
      </div>
    </div>
    <!-- Alcance Producto -->
    <table style="width: 100%" v-else-if="params && params.alcance_producto.length">
      <thead>
        <tr>
          <td colspan="10" style="text-align: center;">Alcance de Producto</td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">RGSEAA</td>
          <td style="font-weight: 700">Razón Social</td>
          <td style="font-weight: 700">Producto</td>
          <td style="font-weight: 700">Alimentación</td>
          <td style="font-weight: 700">Racial</td>
          <td style="font-weight: 700">Estado</td>
          <td style="font-weight: 700">Presentación</td>
          <td style="font-weight: 700">Actividad Realizada</td>
          <td style="font-weight: 700">Actividad Subcontratada</td>
          <td style="font-weight: 700">Certificación</td>
        </tr>
      </thead>
      <tbody>
        <tr style="text-align: center" v-for="item in params.alcance_producto" :key="item.id">
          <td>{{ item.rgseaa }}</td>
          <td>{{ item.razon_social }}</td>
          <td>{{ tipoProducto[item.producto] }}</td>
          <td>{{ tipoAlimentacion[item.alimentacion] }}</td>
          <td>{{ tipoRacial[item.racial] }}</td>
          <td>{{ tipoEstado[item.estado] }}</td>
          <td>{{ tipoPresentacion[item.presentacion] }}</td>
          <td>{{ item.actividades_realizadas }}</td>
          <td>{{ item.actividades_subcontratadas }}</td>
          <td>{{ tipoCertificacion[item.certificacion] }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 4 -->
    <div class="page-break-before"></div>
    <!-- Volumen Produccion -->
    <h2 style="text-align: center;">
      Volumen de Producción Anual (Último año completo) - {{ params && params.volumen_produccion.fecha }}
    </h2>
    <p>&nbsp;</p>
    <table style="width: 100%" v-if="params && params.volumen_produccion">
      <thead>
        <tr>
          <td />
          <td style="text-align: center;">Producción Propia</td>
          <td style="text-align: center;">Producción Maquila</td>
          <td style="text-align: center;">Total</td>
        </tr>
      </thead>
      <tbody>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Animales Sacrificados</td>
          <td>{{ params.volumen_produccion.propia.animales_sacrificados }}</td>
          <td>{{ params.volumen_produccion.maquila.animales_sacrificados }}</td>
          <td>
            {{ Number(params.volumen_produccion.propia.animales_sacrificados) + Number(params.volumen_produccion.maquila.animales_sacrificados) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Canales Despiezadas</td>
          <td>{{ params.volumen_produccion.propia.canales_despiezadas }}</td>
          <td>{{ params.volumen_produccion.maquila.canales_despiezadas }}</td>
          <td>
            {{ Number(params.volumen_produccion.propia.canales_despiezadas) + Number(params.volumen_produccion.maquila.canales_despiezadas) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Piezas Elaboradas</td>
          <td>{{ params.volumen_produccion.propia.piezas_elaboradas }}</td>
          <td>{{ params.volumen_produccion.maquila.piezas_elaboradas }}</td>
          <td>
            {{ Number(params.volumen_produccion.propia.piezas_elaboradas) + Number(params.volumen_produccion.maquila.piezas_elaboradas) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Piezas Transformadas</td>
          <td>{{ params.volumen_produccion.propia.piezas_transformadas }}</td>
          <td>{{ params.volumen_produccion.maquila.piezas_transformadas }}</td>
          <td>
            {{ Number(params.volumen_produccion.propia.piezas_transformadas) + Number(params.volumen_produccion.maquila.piezas_transformadas) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">Kg Carne Comercializada</td>
          <td>{{ params.volumen_produccion.propia.carne_comercializada }}</td>
          <td>{{ params.volumen_produccion.maquila.carne_comercializada }}</td>
          <td>
            {{ Number(params.volumen_produccion.propia.carne_comercializada) + Number(params.volumen_produccion.maquila.carne_comercializada) || '' }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 5 -->
    <div class="page-break-before"></div>
    <!-- Alcance Proceso Instalaciones -->
    <h2 style="text-align: center;">Alcance de Proceso (Instalaciones)</h2>
    <p>&nbsp;</p>
    <!-- Subcontratadas -->
    <table class="mb-8" style="width: 100%" v-if="params && params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="8" style="text-align: center;">Subcontratadas</td>
        </tr>
        <tr v-if="params.actividades_proceso.subcontratadas.length">
          <td colspan="8" style="font-weight: 700">Proveedor del Servicio</td>
        </tr>
        <tr style="text-align: center" v-if="params.actividades_proceso.subcontratadas.length">
          <td style="font-weight: 700">Nombre</td>
          <td style="font-weight: 700">Dirección de la Instalación</td>
          <td style="font-weight: 700">N° RGSEAA</td>
          <td style="font-weight: 700">Actividad</td>
          <td style="font-weight: 700">Volumen de animales/canales/piezas/kg de carne</td>
          <td style="font-weight: 700">Actividad Subcontratada Certificada</td>
          <td style="font-weight: 700">Entidad de Certificación</td>
          <td style="font-weight: 700">Certificado de Conformidad y Anexo Técnico</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="params.actividades_proceso.subcontratadas.length">
          <tr style="text-align: center" v-for="item in params.actividades_proceso.subcontratadas" :key="item.id">
            <td>{{ item.nombre }}</td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.rgseaa }}</td>
            <td>{{ item.actividad }}</td>
            <td>{{ item.volumen }}</td>
            <td>{{ (item.certificada ? 'SI' : 'NO') }}</td>
            <td>{{ item.entidad }}</td>
            <td>{{ item.conformidad_anexo }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="8" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <!-- Alquiladas -->
    <table style="width: 100%" v-if="params && params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="5" style="text-align: center;">Alquiladas</td>
        </tr>
        <tr style="text-align: center" v-if="params.actividades_proceso.alquiladas.length">
          <td style="font-weight: 700">N° RGSEAA</td>
          <td style="font-weight: 700">Dirección de la Instalación</td>
          <td style="font-weight: 700">Actividad</td>
          <td style="font-weight: 700">Volumen de animales/canales/piezas/kg de carne</td>
          <td style="font-weight: 700">Solicita Certificación de Kaam</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="params.actividades_proceso.alquiladas.length">
          <tr style="text-align: center" v-for="item in params.actividades_proceso.alquiladas" :key="item.id">
            <td>{{ item.rgseaa }}</td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.actividad }}</td>
            <td>{{ item.volumen }}</td>
            <td>{{ (item.solicitda_certificacion ? 'SI' : 'NO') }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 6 -->
    <div class="page-break-before"></div>
    <h2 style="text-align: center;">ACEPTA</h2>
    <p>&nbsp;</p>
    <p>
      <span style="font-weight: 400;">1.-&nbsp; Cumplir con las condiciones descritas en los procedimientos de </span>
      <span style="font-weight: 400;">KAAM</span>
      <span style="font-weight: 400;"> que le sean de aplicaci&oacute;n y en especial con el PG-CER 04 &ldquo;Procedimiento para el correcto Uso de la Marca de Conformidad&rdquo; as&iacute; como aquellos requisitos establecidos en los documentos normativos y si procede, en los requisitos establecidos en la legislaci&oacute;n nacional y auton&oacute;mica, as&iacute; como en los procedimientos establecidos por la Entidad Nacional de Acreditaci&oacute;n.</span>
    </p>
    <p>
      <span style="font-weight: 400;">2.- </span>
      <span style="font-weight: 400;">Permitir cualquier verificaci&oacute;n que sea requerida realizar por KAAM en el marco de esta certificaci&oacute;n.</span>
    </p>
    <p>
      <span style="font-weight: 400;">3.- Facilitar a KAAM la informaci&oacute;n necesaria para la realizaci&oacute;n de las auditorias, as&iacute; como los datos de producci&oacute;n solicitados anualmente por Kaam a fin de establecer y cumplir con la frecuencia de evaluaci&oacute;n por centro de producci&oacute;n establecido en el Protocolo de Certificaci&oacute;n en su edici&oacute;n en vigor.</span>
    </p>
    <p>
      <span style="font-weight: 400;">4.- Comunicar a KAAM, en el menor plazo posible, cualquier cambio relevante que afecte o influya en el proceso de certificaci&oacute;n contratado.</span>
    </p>
    <p>
      <span style="font-weight: 400;">5.- Adjuntar la documentaci&oacute;n que se identifica en el Anexo 1.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">6.- Aceptar el resultado de las auditor&iacute;as realizadas.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">7.- Abonar los servicios contratados conforme a las condiciones establecidas en la oferta-contrato.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">8.- Autorizar a KAAM a comunicar los resultados de las auditor&iacute;as y la informaci&oacute;n relacionada con las mismas a las autoridades competentes en la forma que &eacute;sta establezca y expresamente:</span>
    </p>
    <ul>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Autorizo a </span>
        <strong>kaam SL</strong>
        <span style="font-weight: 400;"> a facilitar la informaci&oacute;n relativa al control efectuado en las instalaciones bajo el alcance (y la de los proveedores, si procede) siempre y cuando la autoridad competente en la materia se lo solicite, extremo este que el cliente consiente a la firma de la presente solicitud.&nbsp;</span>
      </li>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Autorizo a</span>
        <strong> kaam SL</strong>
        <span style="font-weight: 400;"> a facilitar informaci&oacute;n a ASICI y poder proporcionar al sistema de informaci&oacute;n ITACA los datos relativos a la gesti&oacute;n y uso de precintos de los productos ib&eacute;ricos, siempre y cuando la autoridad competente en la materia lo entienda oportuno o se lo solicite, extremo este que el cliente consiente a la firma de la presente solicitud.</span>
      </li>
    </ul>
    <p>
      <span style="font-weight: 400;">9.-</span>
      <strong></strong>
      <span style="font-weight: 400;">En el caso de que el solicitante realice actividades a maquila y excluya parte de la producci&oacute;n de la certificaci&oacute;n porque este bajo el alcance de otra Entidad de Certificaci&oacute;n, deber&aacute; tener a disposici&oacute;n de Kaam la siguiente documentaci&oacute;n</span>
      <strong>:&nbsp;</strong>
    </p>
    <ul>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Acuerdo- contrato con el propietario (s) del producto en el que se identifique las instalaciones y la parte de la producci&oacute;n que no se acoge a la certificaci&oacute;n de Kaam.</span>
      </li>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Certificado de Conformidad de Producto del propietario (s).&nbsp;</span>
      </li>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Compromiso de mantener actualizada la informaci&oacute;n sobre los propietarios y la producci&oacute;n no sujeta a la certificaci&oacute;n.&nbsp;</span>
      </li>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Autorizaci&oacute;n expresa del propietario del producto para que el certificado de conformidad de Kaam recoja est&aacute; exclusi&oacute;n en el alcance de certificaci&oacute;n.&nbsp;</span>
      </li>
    </ul>
    <p>
      <span style="font-weight: 400;">10.- Cuando el solicitante de la certificaci&oacute;n alquile instalaciones para realizar actividades propias o a maquila deber&aacute; tener a disposici&oacute;n de Kaam la siguiente documentaci&oacute;n:</span>
    </p>
    <ul>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Contrato de alquiler con el propietario de la instalaci&oacute;n en el que se indique la ubicaci&oacute;n de la parte alquilada, tipo de producto y actividad bajo el alcance de certificaci&oacute;n.&nbsp;</span>
      </li>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Compromiso de mantener actualizada la informaci&oacute;n sobre las instalaciones alquiladas y la producci&oacute;n que se encuentra en las instalaciones alquiladas.&nbsp;</span>
      </li>
      <li style="font-weight: 400;">
        <span style="font-weight: 400;">Autorizaci&oacute;n expresa del propietario de la instalaci&oacute;n alquilada para que el certificado de conformidad recoja la direcci&oacute;n de la instalaci&oacute;n alquilada en el alcance de certificaci&oacute;n.&nbsp;</span>
      </li>
    </ul>
    <p>
      <span style="font-weight: 400;">11.- Kaam en la fase del proceso de certificaci&oacute;n, revisi&oacute;n de la solicitud, podr&aacute; realizar una visita para analizar el riesgo de la certificaci&oacute;n cuando se trate de un proveedor que tenga o haya tenido su certificaci&oacute;n retirada, suspendida o, en otras circunstancias que Kaam considere de riesgo. Esta visita se facturar&aacute; al cliente y deber&aacute; estar abonada antes de su realizaci&oacute;n.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">La aceptaci&oacute;n del Contrato de prestaci&oacute;n de servicios y Oferta econ&oacute;mica para el desarrollo de las labores de certificaci&oacute;n de producto y la firma de esta Solicitud Formal de Certificaci&oacute;n tiene car&aacute;cter contractual, y supone la total aceptaci&oacute;n de los t&eacute;rminos t&eacute;cnicos y econ&oacute;micos de la propuesta y, as&iacute; como de todos los procedimientos aprobados y vigentes de KAAM que le sean de aplicaci&oacute;n a la empresa solicitante de la certificaci&oacute;n.</span>
    </p>

    <!-- Pagina 7 -->
    <div class="page-break-before"></div>
    <h2 style="text-align: center;">ANEXO 1: DOCUMENTACION</h2>
    <p>&nbsp;</p>
    <p>
      <span style="font-weight: 400;">1.-Env&iacute;o a </span>
      <strong>kaam SL</strong>
      <span style="font-weight: 400;">, adjunto a la presente solicitud:</span>
    </p>
    <p>&nbsp;</p>
    <ol>
      <ol>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Procedimiento de Trazabilidad (desde la entrada de las materias primas hasta la expedici&oacute;n de los productos).</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Procedimiento de Compras y Control de Proveedores.</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Tarjeta CIF/NIF.&nbsp;</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Escrituras de la sociedad.&nbsp;</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Poder de la persona que solicita la certificaci&oacute;n.</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Si aplica, fotocopia del documento de inscripci&oacute;n de la marca (s) comercial (es) en el Registro de Patentes y Marcas</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Copia del registro sanitario.</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Carta de declaraci&oacute;n de no haber tenido o tener ning&uacute;n procedimiento de sanci&oacute;n por parte de la administraci&oacute;n competente en materia de salud p&uacute;blica y/o fraude.</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">En caso de haber trabajado con otra entidad antes de aceptar la propuesta de servicios de Kaam, carta de declaraci&oacute;n de no estar suspendida o retirada la certificaci&oacute;n con la entidad anterior. Certificado de conformidad emitido por la anterior entidad de certificaci&oacute;n. &Uacute;ltimo informe de auditor&iacute;a.</span>
        </li>
        <li style="font-weight: 400;">
          <span style="font-weight: 400;">Listado de proveedores con la informaci&oacute;n solicitada en esta solicitud (si no figura cumplimentada la informaci&oacute;n en esta solicitud).&nbsp;</span>
        </li>
      </ol>
    </ol>
    <p>&nbsp;</p>
    <p>
      <span style="font-weight: 400;">2.-Kaam SL </span>
      <strong>se reserva el derecho de solicitar</strong>
      <span style="font-weight: 400;"> que se adjunte a la presente solicitud, conforme a nuestro Manual de Cumplimiento Normativo, documentaci&oacute;n de acuerdo con la legislaci&oacute;n en vigor en materia de prevenci&oacute;n de riesgos penales: Manual de Cumplimiento Normativo de su empresa o documento an&aacute;logo, Certificado de aprobaci&oacute;n del mismo por el &oacute;rgano competente para ello en atenci&oacute;n a su estructura social, Protocolo o protocolos de actuaci&oacute;n con proveedores y clientes, Certificado de aprobaci&oacute;n de tales documentos por el &oacute;rgano competente para ello en su empresa, Designaci&oacute;n del oficial de cumplimiento u &oacute;rgano que asuma dicha funci&oacute;n, as&iacute; como certificado del acta en que se le designa por el &oacute;rgano competente de su mercantil.</span>
      <strong>&nbsp;</strong>
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>Firma y sello del solicitante </strong>
      <strong></strong>
      <strong></strong>
      <strong></strong>
      <strong></strong>
      <strong></strong>
      <strong>Revisado por kaam, SL</strong>
    </p>

  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 0.5px solid black;
  }
  thead {
    display: table-row-group;
  }
  tr td {
    page-break-inside: avoid;
  }
  .no-border {
    border: none !important;
  }
</style>

<script>
import MODELS from "~/models/kaam/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: function() {
    return {
      ...this.datos,
      tipoSolicitud: MODELS.SOLICITUD_TIPO,
      tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
      tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
      tipoProducto: MODELS.PRODUCTO_TIPO,
      tipoRacial: MODELS.RACIAL_TIPO,
      tipoEstado: MODELS.ESTADOS_TIPO,
      tipoPresentacion: MODELS.PRESENTACION_TIPO,
      tipoCertificacion: MODELS.CERTIFICACION_TIPO,
    }
  },
  computed: {
    isModificacion() {
      return this.params.tipo_solicitud === 'modificacion'
    },
    isOperadorResposable() {
      return this.params.alcance_proceso.tipo_operador.includes('responsable')
    },
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    instalaciones () {
      const actividades = this.params.alcance_producto.map(e => e.rgseaa)
      return this.cliente.instalaciones.filter(e => actividades.includes(e.rgseaa))
    }
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    formatDateLong (date) {
      return date ? this.$moment(date).format('LL') : ''
    },
  }
}
</script>
