<template>
  <div>
    <table style="width: 100%;    border-collapse: collapse;    margin-top: 20px;">
      <tbody>
        <tr>
          <td colspan="4"
            style="width:484.6pt;border:solid windowtext 1.0pt;border-top:none;background:#FFE599;padding:0cm 5.4pt 0cm 5.4pt;height:11.65pt;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;'>DATOS RELATIVOS A LAS INSTALACIONES EN LAS QUE SE REALIZA LA ACTIVIDAD</span></strong></p>
          </td>
        </tr>

        <tr>
          <td colspan="4" style="padding: 5pt">
            <p style='font-size: 13px; font-family: Calibri, sans-serif; margin: 0;'>Nº RGSEAA: {{datos.cliente.responsable_nombre}} - * Adjuntar copia</p>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="header" style="width: 100%; display: block">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


<div align="center" style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <table style="width: 100%; border-collapse: collapse; border: none">
        <tbody>
        <tr>
          <td colspan="2"
            style="width:260.55pt;border:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:13.85pt;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;'>Fecha de
                  solicitud</span></strong><span style='font-size:13px;font-family:"Calibri",sans-serif;'>:</span></p>
          </td>
          <td colspan="2"
            style="width:224.05pt;border:solid windowtext 1.0pt;border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:13.85pt;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;'>N&ordm; de
                  Expediente:</span></strong></p>
          </td>
        </tr>
        <tr>
          <td colspan="4"
            style="width:484.6pt;border:solid windowtext 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:26.05pt;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;color:#C00000;'>SOLICITUD INICIAL
                  &nbsp; &nbsp;&nbsp;</span></strong><input type="checkbox" v-if="datos.params.tipo_solicitud == 'inicial'" checked /><input v-else type="checkbox" /></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;color:#C00000;'>SOLICITUD DE
                  MODIFICACION DE ALCANCE &nbsp; &nbsp;</span></strong><input type="checkbox" v-if="datos.params.tipo_solicitud == 'modificacion'" checked /><input v-else type="checkbox" /></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;color:#C00000;'>SOLICITUD INICIAL TRAS UNA RETIRADA
                </span></strong><input type="checkbox" v-if="datos.params.tipo_solicitud == ''" checked /><input v-else type="checkbox" /></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;color:#C00000;'>SOLICITUD PROCEDENTE DE OTRA EC
                &nbsp; &nbsp;</span></strong><input type="checkbox" v-if="datos.params.tipo_solicitud == ''" checked /><input v-else type="checkbox" /></p>
          </td>
        </tr>
        <tr>
          <td colspan="4"
            style="width:484.6pt;border:solid windowtext 1.0pt;border-top:none;background:#FFE599;padding:0cm 5.4pt 0cm 5.4pt;height:11.65pt;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span style='font-size:13px;font-family:  "Calibri",sans-serif;'>DATOS DEL SOLICITANTE (TITULAR DE
                  LA CERTIFICACION):&nbsp;</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>* cumplimentar esta informaci&oacute;n para
                cada uno de los establecimientos a auditar, indicando la sede central.</span></p>
          </td>
        </tr>
        <tr>
          <td colspan="2"
            style="width: 260.55pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 27.75pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Nombre/Empresa: {{clienteNombreCompleto}}</span></p>
          </td>
          <td colspan="2"
            style="width: 224.05pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 27.75pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'>
              <strong><span
                  style='font-size:13px;font-family:  "Calibri",sans-serif;'>NIF/CIF:&nbsp; {{datos.cliente.contacto.nif}}</span></strong><strong><span
                  style='font-size:11px;font-family:  "Calibri",sans-serif;'>* adjuntar copia</span></strong></p>
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>&nbsp;</span></p>
          </td>
        </tr>
        <tr>
          <td
            style="width: 153.5pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 13.85pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Tel&eacute;fono: {{datos.cliente.contacto.telefono}}</span></p>
          </td>
          <td
            style="width: 107.05pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 13.85pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>M&oacute;vil: {{datos.cliente.contacto.telefono}}</span></p>
          </td>
          <td colspan="2"
            style="width: 224.05pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 13.85pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Fax:</span></p>
          </td>
        </tr>
        <tr>
          <td colspan="2"
            style="width: 260.55pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 18.85pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Direcci&oacute;n raz&oacute;n
                social:&nbsp; {{datos.cliente.contacto.direccion}}</span></p>
          </td>
          <td
            style="width: 3cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 18.85pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>C.P: {{datos.cliente.contacto.cp}}</span></p>
          </td>
          <td
            style="width: 139pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 18.85pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Email:&nbsp; {{datos.cliente.contacto.user.email}}</span></p>
          </td>
        </tr>
        <tr>
          <td colspan="2"
            style="width: 260.55pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 11.65pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span v-if="datos.cliente.contacto.localidad.municipio"
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Poblaci&oacute;n:&nbsp; {{datos.cliente.contacto.localidad.municipio.nombre}}</span></p>
          </td>
          <td colspan="2"
            style="width: 224.05pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 11.65pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span v-if="datos.cliente.contacto.localidad.provincia"
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Provincia: {{datos.cliente.contacto.localidad.provincia.nombre}}</span></p>
          </td>
        </tr>
        <tr>
          <td colspan="4"
            style="width: 484.6pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 22.5pt;vertical-align: top;">
            <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><span
                style='font-size:13px;font-family:"Calibri",sans-serif;'>Persona autorizada/Persona de contacto: {{datos.cliente.responsable_nombre}}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    </div>


    <div v-for="instalacion in instalaciones" :key="instalacion.id" align="center" style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;">
      <table style="width: 100%; border-collapse: collapse; border: none">
        <tbody>
          <tr>
            <td colspan="2" style="
                width: 484.1pt;
                border: 1pt solid windowtext;
                background: rgb(255, 229, 153);
                padding: 0cm 5.4pt;
                height: 11.5pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: justify;
                ">
                <strong><span style="font-size: 13px; font-family: 'Calibri', sans-serif">DATOS RELATIVOS A LAS
                    INSTALACIONES EN LAS QUE SE REALIZA
                    LA ACTIVIDAD</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="
                width: 484.1pt;
                border-right: 1pt solid windowtext;
                border-bottom: 1pt solid windowtext;
                border-left: 1pt solid windowtext;
                border-image: initial;
                border-top: none;
                padding: 0cm 5.4pt;
                height: 11.5pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 13px; font-family: 'Calibri', sans-serif">N&ordm;
                    RGSEAA*</span></strong><strong><span
                    style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;adjuntar copia :
                    {{ instalacion.rgseaa }}</span></strong><strong><span
                    style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="
                width: 484.1pt;
                border-right: 1pt solid windowtext;
                border-bottom: 1pt solid windowtext;
                border-left: 1pt solid windowtext;
                border-image: initial;
                border-top: none;
                padding: 0cm 5.4pt;
                height: 12.7pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 13px; font-family: 'Calibri', sans-serif">Raz&oacute;n social:
                  {{ instalacion.razon_social }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="
                width: 484.1pt;
                border-right: 1pt solid windowtext;
                border-bottom: 1pt solid windowtext;
                border-left: 1pt solid windowtext;
                border-image: initial;
                border-top: none;
                padding: 0cm 5.4pt;
                height: 13.5pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 13px; font-family: 'Calibri', sans-serif">Domicilio industrial:&nbsp;
                  <span>{{ instalacion.direccion }}</span>
                  <span v-if="instalacion.comunidad">{{
                    instalacion.comunidad.nombre
                  }}</span>
                  <span v-if="instalacion.provincia">{{
                    ", " + instalacion.provincia.nombre
                  }}</span>
                  <span v-if="instalacion.municipio">{{
                    ", " + instalacion.municipio.nombre
                  }}</span>
                  <span v-if="instalacion.cp">{{ instalacion.cp }}</span>
                </span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="
                width: 274.9pt;
                border-right: 1pt solid windowtext;
                border-bottom: 1pt solid windowtext;
                border-left: 1pt solid windowtext;
                border-image: initial;
                border-top: none;
                padding: 0cm 5.4pt;
                height: 23.65pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 13px; font-family: 'Calibri', sans-serif">Actividades que realiza: &nbsp;
                  &nbsp;</span><span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span>
              </p>
               <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Sacrificio' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Sacrificio</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Despiece' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Despiece</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Elaboracion Total' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Elaboraci&oacute;n Total</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some((x) =>
                      x.actividades_realizadas.includes('Elaboracion ') && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Elaboraci&oacute;n Parcial</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_realizadas == 'Elaboracion Parcial Salado' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Salado&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_realizadas ==
                        'Elaboracion Parcial Post Salado' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Post-salado,&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_realizadas ==
                        'Elaboracion Parcial Curado Madrudación' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Curado&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">maduraci&oacute;n</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Transformación' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Transformaci&oacute;n</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Envasado' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Envasado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Almacenamiento' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Almacenamiento</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Distribución' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Distribuci&oacute;n</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Etiquetado' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Etiquetado</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Comercialización' && x.rgseaa == instalacion.rgseaa
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Comercializacion</span>
              </p>
            </td>
            <td style="
                width: 209.2pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid windowtext;
                border-right: 1pt solid windowtext;
                padding: 0cm 5.4pt;
                height: 23.65pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 13px; font-family: 'Calibri', sans-serif">Observaciones:</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <strong><span style="
            font-size: 12px;
            font-family: 'Calibri', sans-serif;
            color: red;
          ">A&ntilde;adir tantas filas como RGSEAA se pretendan acoger a
          certificaci&oacute;n.</span></strong>
    </p>

    <table>
      <tbody>
        <tr>
          <td>
            <p>
              <strong>DATOS RELATIVOS AL ALCANCE DE LA PRODUCCION</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span style="font-weight: 400"><input type="checkbox" v-if="datos.params.certificacion_amparada" checked /><input v-else type="checkbox" /> </span>
              <span style="font-weight: 400">
                Se solicita que toda la producci&oacute;n este amparada por la
                certificaci&oacute;n de Araporcei.</span>
            </p>
            <p>
              <span style="font-weight: 400"><input type="checkbox" v-if="datos.params.certificacion_excluida" checked /><input v-else type="checkbox" /> </span>
              <span style="font-weight: 400">Se solicita que se excluya parte de la certificaci&oacute;n
                porque est&aacute; bajo el control de la Entidad de
                Certificaci&oacute;n del propietario del producto seg&uacute;n
                lo dispuesto en el art&iacute;culo 14.3 del RD 4/2014.(solo para
                el caso de operadores que prestan servicio a
                maquila)&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>
    <p>
      <strong>SOLICITA:</strong>
      <strong></strong>
    </p>
    <p>
      <span style="font-weight: 400">1.- Ser evaluado para verificar la competencia t&eacute;cnica de la
        empresa conforme a los siguientes documentos:</span>
    </p>
    <p>
      <span style="font-weight: 400">a.- R.D. 4/2014, de 10 de enero, por el que se aprueba la norma de
        calidad para la carne, el jam&oacute;n, la paleta y la ca&ntilde;a de
        lomo ib&eacute;rico. Y Protocolo de Certificaci&oacute;n en vigor.
        Ministerio de Agricultura y Pesca, y Alimentaci&oacute;n Medio.</span>
    </p>
    <p>
      <strong>DECLARA:</strong>
      <span style="font-weight: 400"></span>
      <span style="font-weight: 400"></span>
    </p>
    <p>
      <span style="font-weight: 400">1.- Conocer y aceptar el procedimiento de certificaci&oacute;n de
        producto denominado PG-CER-03.</span>
    </p>
    <p>
      <span style="font-weight: 400">2.- Que la veracidad de los datos contenidos en este registro queda
        bajo la responsabilidad de la empresa solicitante de la
        certificaci&oacute;n.</span>
    </p>
<p>
      <span style="font-weight: 400">3.- Que solo se contrata a Araporcei como única entidad para certificar
        todas las instalaciones (y sus correspondientes RGSEAA) cuyo titular sea el NIF del solicitante en
        cumplimiento del acuerdo de la Mesa de Coordinación de la Norma de Calidad del Ibérico de 10 de octubre de
         2019 y del Protocolo. En el caso de que el solicitante de la certificación pueda justificar la imposibilidad
         de cumplir esta norma general ante la autoridad competente de su Comunidad Autónoma, se podrá realizar una excepción y
         deberá aportar el acuerdo por escrito emitido por la autoridad competente de su CCAA(* adjuntar copia) que indique
         la posibilidad de contratar más de una entidad de certificación</span>
    </p>


    <!-- Pagina 2 -->
    <div v-if="isModificacion">
      <div class="page-break-before"></div>

      <!-- <div class="header" style="width: 100%; display: block">
        <table style="width: 100%">
          <tbody style="font-weight: 400">
            <tr style="text-align: center">
              <td colspan="1">
                <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
                <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
              </td>
              <td colspan="2">
                <h2>
                  Solicitud de Certificación
                  <br />
                  Productos Ibéricos
                </h2>
              </td>
              <td>
                <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <!-- <h2 style="text-align: center; margin-top: 150px">
        Solicitud de Modificación de Alcance
      </h2>
      <p>&nbsp;</p>
      <p>
        <span style="font-weight: 400">Tipo Modiciación: {{ params.tipo_modificacion }}
        </span>
      </p>
      <p>
        <span style="font-weight: 400">Producto: {{ params.producto }} </span>
      </p>
      <p>
        <span style="font-weight: 400">Proceso/Activida: {{ params.proceso_actividad }}
        </span>
      </p>
      <p>
        <span style="font-weight: 400">Operador Subcontratado Certificado: {{ params.operador_certificado }}
        </span>
      </p>
      <p>
        <span style="font-weight: 400">Operador Subcontratado No Certificado:
          {{ params.operador_no_certificado }}
        </span>
      </p>
      <p>
        <span style="font-weight: 400">Marca Comercial: {{ params.marca_comercial }}
        </span>
      </p>
      <p>
        <span style="font-weight: 400">Otros motivos: {{ params.otros_motivos }}
        </span>
      </p>
      <p>
        <span style="font-weight: 400">Observaciones: {{ params.observaciones }}
        </span>
      </p> -->
    </div>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        text-align: center;
      ">
      <strong><span style="
            font-size: 13px;
            font-family: 'Calibri', sans-serif;
            color: red;
          ">NECESARIO ADJUNTAR DOCUMENTACION DONDE SE INDIQUE (* adjuntar
          copia)</span></strong>
    </p>
    <!-- Pagina 3 -->
    <div class="page-break-before"></div>

    <div class="header" style="width: 100%">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Alcance Proceso -->
    <div align="center" style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        margin-top: 40px;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <table style="
          width: 100%;
          border-collapse: collapse;
          border: none;
          margin-top: 40px;
        ">
        <tbody>
          <tr>
            <td colspan="4" style="
                width: 751.9pt;
                border: solid #a6a6a6 1pt;
                background: #ffccff;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 1.75pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Alcance de Proceso
                    (actividades)&nbsp;</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="2" style="
                width: 153.5pt;
                border-top: none;
                border-left: solid #a6a6a6 1pt;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #bfbfbf 1pt;
                background: #ffccff;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 1.75pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Tipo de
                    Operador</span></strong>
              </p>
            </td>
            <td style="
                width: 248.05pt;
                border-top: none;
                border-left: none;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #a6a6a6 1pt;
                background: #ffccff;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 1.75pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Actividades
                    realizadas</span></strong>
              </p>
            </td>
            <td colspan="2" style="
                width: 350.35pt;
                border-top: none;
                border-left: none;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #a6a6a6 1pt;
                background: #ffccff;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 1.75pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Actividades
                    Subcontratadas</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="2" style="
                width: 248.05pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                background: white;
                padding: 0cm 5.4pt;
                height: 9.65pt;
                vertical-align: bottom;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Sacrificio'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Sacrificio</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Despiece'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Despiece</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Elaboracion Total'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Elaboraci&oacute;n Total</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some((x) =>
                      x.actividades_realizadas.includes('Elaboracion ')
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Elaboraci&oacute;n Parcial</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_realizadas == 'Elaboracion Parcial Salado'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Salado&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_realizadas ==
                        'Elaboracion Parcial Post Salado'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Post-salado,&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_realizadas ==
                        'Elaboracion Parcial Curado Madrudación'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Curado&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">maduraci&oacute;n</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Transformación'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Transformaci&oacute;n</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Envasado'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Envasado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Almacenamiento'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Almacenamiento</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Etiquetado'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Etiquetado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Distribución'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Distribuci&oacute;n</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) => x.actividades_realizadas == 'Comercialización'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Comercializacion</span>
              </p>
            </td>
            <td colspan="2" style="
                width: 350.35pt;
                border-top: none;
                border-left: none;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #a6a6a6 1pt;
                background: white;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 9.65pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="
                      font-size: 11px;
                      font-family: 'Calibri', sans-serif;
                      color: #2f5496;
                    ">indicar la (s) actividad (es) subcontratada (s)</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td style="
                width: 153.5pt;
                border-top: none;
                border-left: solid #a6a6a6 1pt;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #bfbfbf 1pt;
                background: white;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 78.6pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input name="Casilla25" type="checkbox" v-if="
                    datos.params.alcance_proceso.tipo_operador.includes(
                      'responsable'
                    )
                  " checked /><input name="Casilla57" type="checkbox" v-else />
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Operador responsable de
                    la actividad que queda bajo
                    el alcance</span></strong>
              </p>
            </td>
            <td style="
                width: 200.45pt;
                border-top: none;
                border-left: none;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #a6a6a6 1pt;
                background: white;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 78.6pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Operador certificadoxx<span
                      style="color: #2f5496">&nbsp;</span></span></strong>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 5px; font-family: 'Calibri', sans-serif">&nbsp;</span></strong>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Sacrificio' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Sacrificio</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Despiece' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />

                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Despiece</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Elaboracion Total' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />

                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Elaboraci&oacute;n Total</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas.includes('Elaboracion ') &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span>
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Elaboraci&oacute;n parcial</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span><input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas ==
                          'Elaboracion Parcial Salado' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Salado&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span><input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas ==
                          'Elaboracion Parcial Post Salado' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Post-salado,&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span><input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas ==
                          'Elaboracion Parcial Curado Madrudación' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Curado&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">maduraci&oacute;n</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Transformación' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Transformaci&oacute;n&nbsp;</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Envasado' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Envasado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Almacenamiento' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Almacenamiento</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Etiquetado' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Etiquetado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Expedicion' &&
                        x.certificacion == 'operador'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Expedici&oacute;n</span>
              </p>
            </td>
            <td style="
                width: 149.9pt;
                border-top: none;
                border-left: none;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #a6a6a6 1pt;
                background: white;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 78.6pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Operador No certificado<span
                      style="color: #2f5496">&nbsp;</span></span></strong>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 3px; font-family: 'Calibri', sans-serif">&nbsp;</span></strong>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Sacrificio' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Sacrificio</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Despiece' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Despiece</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Elaboracion Total' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Elaboraci&oacute;n Total</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas.includes('Elaboracion ') &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Elaboraci&oacute;n Parcial</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas ==
                          'Elaboracion Parcial Salado' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Salado&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas ==
                          'Elaboracion Parcial Post Salado' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Post-salado,&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas ==
                          'Elaboracion Parcial Curado Madrudación' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Curado&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">maduraci&oacute;n</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Transformación' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Transformaci&oacute;n&nbsp;</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Envasado' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Envasado&nbsp;</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Almacenamiento' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Almacenamiento&nbsp;</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Distribución' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Etiquetado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.alcance_producto.some(
                      (x) =>
                        x.actividades_subcontratadas == 'Expedicion' &&
                        x.certificacion == 'parcial'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Expedici&oacute;n</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="
                width: 153.5pt;
                border: solid #a6a6a6 1pt;
                border-top: none;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 76.3pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input name="Casilla25" type="checkbox" v-if="
                    datos.params.alcance_proceso.tipo_operador.includes(
                      'prestador'
                    )
                  " checked /><input name="Casilla57" type="checkbox" v-else /><strong><span
                    style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Operador como prestador de
                    servicios (a
                    maquila)</span></strong>
              </p>
            </td>
            <td style="
                width: 248.05pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 76.3pt;
                vertical-align: bottom;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="
                    font-size: 4px;
                    font-family: 'Calibri', sans-serif;
                    color: #2f5496;
                  ">&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Sacrificio'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Sacrificio'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Sacrificio</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Despiece'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Despiece'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Despiece&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Elaboracion Total'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Elaboracion Total'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Elaboraci&oacute;n Total</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some((x) =>
                      x.actividad.includes('Elaboracion ')
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some((x) =>
                      x.actividad.includes('Elaboracion ')
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Elaboraci&oacute;n Parcial</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span><input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Elaboracion Parcial Salado'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Elaboracion Parcial Salado'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Salado&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Elaboracion Parcial Post Salado'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Elaboracion Parcial Post Salado'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Post-salado,&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Curado Madrudación'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Curado Madrudación'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Curado&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">maduraci&oacute;n</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Transformación'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Transformación'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Transformaci&oacute;n</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Envasado'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Envasado'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Envasado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Almacenamiento'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Almacenamiento'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Almacenamiento</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Etiquetado'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Etiquetado'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Etiquetado</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.alquiladas.some(
                      (x) => x.actividad == 'Expedicion'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Expedicion'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Expedici&oacute;n</span>
              </p>
            </td>
            <td style="
                width: 200.45pt;
                border-top: none;
                border-left: none;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #a6a6a6 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 76.3pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Operador certificado<span
                      style="color: #2f5496">&nbsp;</span></span></strong>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="
                    font-size: 4px;
                    font-family: 'Calibri', sans-serif;
                    color: #2f5496;
                  ">&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Sacrificio' && x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Sacrificio' && x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Sacrificio</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Despiece' && x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Despiece' && x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Despiece&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Total' &&
                        x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Total' &&
                        x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Elaboraci&oacute;n Total</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some((x) =>
                      x.actividad.includes(
                        'Elaboracion ' && x.certificada == 'SI'
                      )
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some((x) =>
                      x.actividad.includes(
                        'Elaboracion ' && x.certificada == 'SI'
                      )
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Elaboraci&oacute;n Parcial</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span><input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Salado' &&
                        x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Salado' &&
                        x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Salado&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Post Salado' &&
                        x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Post Salado' &&
                        x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Post-salado,&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad ==
                          'Elaboracion Parcial Curado Madrudación' &&
                        x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad ==
                          'Elaboracion Parcial Curado Madrudación' &&
                        x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Curado&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">maduraci&oacute;n</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Transformación' && x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Transformación' && x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Transformaci&oacute;n</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Envasado' && x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Envasado' && x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Envasado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Almacenamiento' && x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Almacenamiento' && x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Almacenamiento</span>
              </p>


              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Etiquetado' && x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Etiquetado' && x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Etiquetado</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Expedicion' && x.certificada == 'SI'
                    ) ||
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Expedicion' && x.certificada == 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Expedici&oacute;n</span>
              </p>
            </td>
            <td style="
                width: 149.9pt;
                border-top: none;
                border-left: none;
                border-bottom: solid #a6a6a6 1pt;
                border-right: solid #a6a6a6 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 76.3pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 11px; font-family: 'Calibri', sans-serif">Operador No certificado<span
                      style="color: #2f5496">&nbsp;</span></span></strong>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="
                    font-size: 4px;
                    font-family: 'Calibri', sans-serif;
                    color: #2f5496;
                  ">&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Sacrificio' && x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Sacrificio</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) => x.actividad == 'Despiece' && x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Despiece&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Total' &&
                        x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Elaboraci&oacute;n Total</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad.includes('Elaboracion ') &&
                        x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Elaboraci&oacute;n Parcial</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span><input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Salado' &&
                        x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else /><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Salado&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Elaboracion Parcial Post Salado' &&
                        x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Post-salado,&nbsp;</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp;&nbsp;</span>
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad ==
                          'Elaboracion Parcial Curado Madrudación' &&
                        x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">Curado&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">maduraci&oacute;n</span>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Transformación' && x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                  style="font-size: 11px; font-family: 'Calibri', sans-serif">Transformaci&oacute;n</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Envasado' && x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Envasado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Almacenamiento' && x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Almacenamiento</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Etiquetado' && x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Etiquetado</span>
              </p>

              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <input type="checkbox" v-if="
                    datos.params.actividades_proceso.subcontratadas.some(
                      (x) =>
                        x.actividad == 'Expedición' && x.certificada != 'SI'
                    )
                  " checked /><input type="checkbox" v-else />
                <span style="font-size: 11px; font-family: 'Calibri', sans-serif">&nbsp;Expedición</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Alcance Proceso -->

    <div align="center" style="
        width: 100%;
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <table style="border-collapse: collapse; border: none; width: 100%;">
        <tbody>
          <tr>
            <td style="
                width: 741.8pt;
                border: 1pt solid windowtext;
                padding: 0cm 5.4pt;
                vertical-align: top;
              ">
              <div style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  border: none;
                  padding: 0cm 0cm 0cm 4pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    text-align: justify;
                    line-height: 107%;
                    border: none;
                    padding: 0cm;
                    font-size: 8px;
                    font-family: 'Calibri', sans-serif;
                  ">

                    <br><strong> Actividad Propia.</strong> Es aquella actividad que realiza el operador bajo su responsabilidad en instalaciones que son propias o alquiladas.<br>
                    <br><strong> Actividad subcontratada:</strong> Es aquella actividad que se contrata contra el pago por los servicios prestados.<br>
                    <br><strong> Sacrificio:</strong> actividad de matanza de animales destinada al consumo humano.<br>
                    <br><strong> Despiece:</strong> actividad de cortar canales y obtener carne en piezas.<br>
                    <br><strong> Elaboración:</strong> actividad de elaborar jamones, paletas y cañas de lomos ibéricos que incluye una o varias de las fases establecidas en el artículo 11 del RD 4/2014.
                    <ul>
                      <li>Jamones y paletas se lleva a cabo con las piezas osteomusculares integras y constara de las siguientes fases: salazón, lavado,post-salado y curado maduración.</li>
                      <li>Cañas de lomo ibérico, constara de las siguientes fases: adobado y embutido en tripas naturales o artificiales y curado maduración.</li>
                    </ul>

                    <br>
                    <br>

                    La actividad de elaboración puede ser:<br><br>

                    <br><strong> Elaboración total:</strong> Actividad que lleva aparejada todas las fases de las que consta el proceso de elaboración indicadas en el artículo 11 de RD 4/2014.<br>
                    <br><strong>Elaboración parcial:</strong> Actividad que lleva aparejada alguna de las fases establecidas en el artículo 11 del RD 4/2014.<br>
                    <br><strong>Transformación:</strong> operación destinada a obtener producto: troceado, loncheado, deshuesado o fileteado.<br>
                    <br><strong>Envasado:</strong> actividad que implica la introducción, en cualquier presentación, de un producto ibérico en un envase o recipiente en contacto directo con el mismo, así como el propio envase o recipiente ya recubra el envase al producto por entero o sólo parcialmente, pero de forma que no pueda modificarse el contenido sin abrir o modificar dicho envase. Esta actividad solo se indicará en el caso de carne fresca.<br>
                    <br><strong>Etiquetado:</strong> actividad que implica proporcionar cualquier información al consumidor y/o destinatario del producto, por escrito y de manera visible en cualquier parte del proceso, esté o no adherida al producto, así como folletos o cualquier otro material publicitario. La actividad de etiquetado a maquila se refiere únicamente al etiquetado del producto con marca comercial.<br>
                    <br><strong>Almacenamiento:</strong> actividad que implica guardar y custodiar productos ibéricos que ya cumplen el tiempo mínimo de elaboración listos para su para su expedición, y/o comercialización.<br>
                    <br><strong>Comercialización:</strong> actividad de venta del producto ibérico certificado siempre implica la emisión de DEPC o Registro de trazabilidad y, alternativamente la identificación del producto con etiqueta con marca comercial.<br>

                    <ul>
                      <li>En el caso de que el operador realice esta actividad a maquila figurará el término Expedición. Siempre implica la emisión de un Registro de Trazabilidad RT.</li>
                      <li>En el caso de que el operador realice esta actividad sin instalaciones propias, traders, figurará el termino Distribución. Siempre implica la emisión de DEPC o la identificación del producto con etiqueta con marca comercial.</li>
                    </ul>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="page-break-before"></div>

    <div class="header" style="width: 100%">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Alcance Producto -->
    <table style="
        width: 100%;
        border-collapse: collapse;
        border: none;
        margin-left: 4.8pt;
        margin-right: 4.8pt;
      ">
      <tbody>
        <tr>
          <td colspan="8" style="
              width: 777.95pt;
              border: solid #a6a6a6 1pt;
              background: #ffccff;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">Alcance del
                  Producto</span></strong>
            </p>
          </td>
        </tr>
        <tr>
          <td rowspan="10" style="
              width: 26.65pt;
              border-top: none;
              border-left: solid #a6a6a6 1pt;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                margin-top: 0cm;
                margin-right: 5.65pt;
                margin-left: 5.65pt;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">PRODUCTO PROCEDENTE DE ANIMALES
                  SACRIFICADOS A PARTIR DEL
                  12/01/2014</span></strong>
            </p>
          </td>
          <td rowspan="10" style="
              width: 13.35pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                margin-top: 0cm;
                margin-right: 5.65pt;
                margin-left: 5.65pt;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">REAL DECRETO 4/2<span
                    style="background: #d9d9d9">0</span>14</span></strong>
            </p>
          </td>
          <td colspan="6" style="
              width: 737.95pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">Producto</span></strong>
            </p>
          </td>
        </tr>
        <tr>
          <td style="
              width: 66.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid #a6a6a6 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">Tipo de Producto</span></strong>
            </p>
          </td>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid #a6a6a6 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span
                  style="font-size: 9px; font-family: 'Calibri', sans-serif">Alimentaci&oacute;n</span></strong>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid #a6a6a6 1pt;
              border-right: solid #bfbfbf 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">Tipo racial</span></strong>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid #a6a6a6 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">Estado</span></strong>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid #bfbfbf 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">Presentaci&oacute;n
                  Comercial</span></strong>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid #bfbfbf 1pt;
              border-right: solid #a6a6a6 1pt;
              background: #ededed;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 2.05pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <strong><span style="font-size: 9px; font-family: 'Calibri', sans-serif">Marcas amparadas</span></strong>
            </p>
          </td>
        </tr>
        <tr>
          <td rowspan="2" style="
              width: 66.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('jamon')" checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Jam&oacute;n</span>
            </p>
          </td>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('jamon') && isRacial('100_iberico')" checked /><input
                type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;100% Ib&eacute;rico</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isEstado('curado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Curado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isEstado('semicurado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Semicurado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isPresentacion('deshuesado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Deshuesado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('100_iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('jamon')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('jamon') && isRacial('iberico')" checked /><input
                type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Ib&eacute;rico*</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isEstado('curado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Curado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isEstado('semicurado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Semicurado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isPresentacion('deshuesado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Deshuesado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('jamon') &&
                  isRacial('iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('jamon')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>

        <tr>
          <td rowspan="2" style="
              width: 66.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('paleta')" checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">Paleta</span>
            </p>
          </td>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('paleta') && isRacial('100_iberico')" checked /><input
                type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;100% Ib&eacute;rico</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isEstado('curado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Curado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isEstado('semicurado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Semicurado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isPresentacion('deshuesado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Deshuesado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('100_iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('paleta')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('paleta') && isRacial('iberico')" checked /><input
                type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Ib&eacute;rico*</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isEstado('curado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Curado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isEstado('semicurado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Semicurado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isPresentacion('deshuesado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Deshuesado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('paleta') &&
                  isRacial('iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('paleta')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>

        <tr>
          <td rowspan="2" style="
              width: 66.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('lomo')" checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">Lomo</span>
            </p>
          </td>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('lomo') && isRacial('100_iberico')" checked /><input
                type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;100% Ib&eacute;rico</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isEstado('curado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Curado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isEstado('semicurado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Semicurado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isPresentacion('deshuesado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Deshuesado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('100_iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('lomo')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('lomo') && isRacial('iberico')" checked /><input
                type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Ib&eacute;rico*</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isEstado('curado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Curado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isEstado('semicurado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Semicurado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isPresentacion('deshuesado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Deshuesado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('lomo') &&
                  isRacial('iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('lomo')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>

        <tr>
          <td rowspan="2" style="
              width: 66.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('carnes_frescas')" checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">Carnes Frescas</span>
            </p>
          </td>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('100_iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') && isRacial('100_iberico')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;100% Ib&eacute;rico</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('100_iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('100_iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('100_iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('100_iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 1cm;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('carnes_frescas')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="
              width: 73.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('iberico') &&
                  isAlimentacion('bellota')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Bellota</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo_Campo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo de campo</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('iberico') &&
                  isAlimentacion('cebo')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Cebo</span>
            </p>
          </td>
          <td style="
              width: 73.45pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="isTipoProducto('carnes_frescas') && isRacial('iberico')" checked /><input
                type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Ib&eacute;rico*</span>
            </p>
          </td>
          <td style="
              width: 127.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('iberico') &&
                  isEstado('fresco')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
                style="font-size: 9px; font-family: 'Calibri', sans-serif">Fresco</span>
            </p>
          </td>
          <td style="
              width: 148.85pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('iberico') &&
                  isPresentacion('entera')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Pieza Entera</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('iberico') &&
                  isPresentacion('loncheado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Loncheado</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <input type="checkbox" v-if="
                  isTipoProducto('carnes_frescas') &&
                  isRacial('iberico') &&
                  isPresentacion('troceado')
                " checked /><input type="checkbox" v-else />
              <span style="font-size: 9px; font-family: 'Calibri', sans-serif">&nbsp;Troceado</span>
            </p>
          </td>
          <td style="
              width: 248.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid gray 1pt;
              border-right: solid #a6a6a6 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 33.45pt;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
                text-align: center;
              ">
              <span v-for="marca in getMarcas('carnes_frescas')" :key="marca"
                style="font-size: 9px; font-family: 'Calibri', sans-serif">{{ marca }}<br /></span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <div style="
    display: block;
    width: 100%;
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <ul style="
          margin-bottom: 0cm;
          list-style-type: undefined;
          margin-left: 26px;
        ">
        <li style="
            margin: 0cm;
            margin-bottom: 0.0001pt;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
          ">
          <span style="font-family: 'Calibri', sans-serif; font-size: 10pt">Menciones Obligatorias: 50% Ib&eacute;rico
            y/o 75%
            Ib&eacute;rico</span>
        </li>
      </ul>
    </div>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
        line-height: 107%;
      ">
      <strong><em><span style="
              font-size: 11px;
              line-height: 107%;
              font-family: 'Calibri', sans-serif;
              color: #c00000;
            ">&nbsp;</span></em></strong>
    </p>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
        line-height: 107%;
      ">
      <strong><em><span style="
              font-size: 11px;
              line-height: 107%;
              font-family: 'Calibri', sans-serif;
              color: #c00000;
            ">INDICAR PARA CADA TIPO DE PRODUCTO LA (S) DENOMINACION (ES) DE
            VENTA (ALIMENTACION, TIPO RACIAL, ESTADO, PRESENTACION COMERCIAL Y
            MARCA) A&Ntilde;ADIENDO TANTAS FILAS COMO SEAN
            NECESARIAS)&nbsp;</span></em></strong>
    </p>

    <!-- Alcance Producto -->

    <div class="page-break-before"></div>

    <div class="header" style="width: 100%">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        border: none;
        border-bottom: solid windowtext 1pt;
        padding: 0cm 0cm 1pt 0cm;
      ">
      <p style="
          margin-top: 0cm;
          margin-right: 0cm;
          margin-bottom: 0.0001pt;
          margin-left: 0cm;
          font-size: 16px;
          font-family: 'Times New Roman', serif;
          border: none;
          padding: 0cm;
        ">
        <strong><span style="font-size: 13px; font-family: 'Calibri', sans-serif">SOLICITUD DE MODIFICACION DE ALCANCE:
            &nbsp; &nbsp;</span></strong>
      </p>
    </div>
    <p style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0.0001pt;
        margin-left: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <strong><em><span style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;</span></em></strong>
    </p>
    <p style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0.0001pt;
        margin-left: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <strong><span style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;Indicar
          el Tipo de Modificaci&oacute;n en el Alcance:&nbsp;
          &nbsp;&nbsp;</span></strong><input type="checkbox" v-if="datos.params.tipo_modificacion == 'ampliacion'" checked /><input v-else type="checkbox" /><strong><span
          style="font-size: 13px; font-family: 'Calibri', sans-serif">AMPLIACION&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;
          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;</span></strong><input type="checkbox" v-if="datos.params.tipo_modificacion == 'reduccion'" checked /><input v-else type="checkbox" /><strong><span
          style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;REDUCCION: &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp;
          &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp;</span></strong><input type="checkbox" v-if="datos.params.tipo_modificacion == 'otros'" checked/>
         <input v-else type="checkbox"/><strong><span
          style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;OTROS MOTIVOS</span></strong>
    </p>
    <p style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0.0001pt;
        margin-left: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <strong><em><span style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;</span></em></strong>
    </p>
    <p style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0.0001pt;
        margin-left: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <strong><span style="
            font-size: 12px;
            font-family: 'Calibri', sans-serif;
            color: red;
          ">Indicar los datos referentes a la Modificaci&oacute;n SOLICITADA:
          (marcar el apartado correspondiente e indicar los datos
          necesarios)</span></strong>
    </p>
    <p style="
        margin-top: 0cm;
        margin-right: 0cm;
        margin-bottom: 0.0001pt;
        margin-left: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <strong><em><span style="
              font-size: 12px;
              font-family: 'Calibri', sans-serif;
              color: #c00000;
            ">&nbsp;</span></em></strong>
    </p>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <input type="checkbox" style="transform: translateY(3px);" />
      <span style="font-size: 12px">&nbsp;</span><span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">Producto: (Indicar que producto)&nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>

        <input type="checkbox" style="transform: translateY(3px);" />
        <span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">Proceso/Actividad: (Indicar actividad y la
        instalaci&oacute;n)</span>
    </p>
    <table style="border-collapse: collapse; border: none">
      <tbody>
        <tr>
          <td style="
              width: 288.9pt;
              border: 1pt solid rgb(166, 166, 166);
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{datos.params.producto}}</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
          </td>
          <td style="
              width: 14cm;
              border-top: 1pt solid rgb(166, 166, 166);
              border-right: 1pt solid rgb(166, 166, 166);
              border-bottom: 1pt solid rgb(166, 166, 166);
              border-image: initial;
              border-left: none;
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{datos.params.proceso_actividad}}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
    </p>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        text-align: justify;
      ">
      <input type="checkbox" style="transform: translateY(3px);" />
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span><span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">Instalaci&oacute;n (Indicar actividad y RGSEAA)
        &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;&nbsp;</span>
    </p>
    <table style="border-collapse: collapse; border: none">
      <tbody>
        <tr>
          <td style="
              width: 685.8pt;
              border: 1pt solid rgb(166, 166, 166);
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
    </p>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <input type="checkbox" style="transform: translateY(3px);" />
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;Operador
        Subcontratado Certificado:&nbsp;(Indicar
        actividad/producto/EC)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>

        <input type="checkbox" style="transform: translateY(3px);" />
        <span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;Operador Subcontratado no Certificado
        (</span><span style="font-size: 12px; font-family: 'Calibri', sans-serif">Indicar actividad/producto</span><span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">)</span>
    </p>
    <table style="border-collapse: collapse; border: none">
      <tbody>
        <tr>
          <td style="
              width: 288.9pt;
              border: 1pt solid rgb(166, 166, 166);
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{datos.params.operador_certificado}}</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
          </td>
          <td style="
              width: 14cm;
              border-top: 1pt solid rgb(166, 166, 166);
              border-right: 1pt solid rgb(166, 166, 166);
              border-bottom: 1pt solid rgb(166, 166, 166);
              border-image: initial;
              border-left: none;
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{datos.params.operador_no_certificado}}</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
    </p>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <input type="checkbox" style="transform: translateY(3px);" />
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;Marca
        Comercial</span><span style="font-size: 12px; font-family: 'Segoe UI Emoji', sans-serif">&nbsp;(</span><span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">Indicar productos</span><span
        style="font-size: 12px; font-family: 'Segoe UI Emoji', sans-serif">)</span><span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;&nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
        <input type="checkbox" style="transform: translateY(3px);" />
        <span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;Describir otros</span><span
        style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;motivos por los que se solicita la
        modificacion de alcance</span>
    </p>
    <table style="border-collapse: collapse; border: none">
      <tbody>
        <tr>
          <td style="
              width: 288.9pt;
              border: 1pt solid rgb(166, 166, 166);
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{datos.params.marca_comercial}}</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
            </p>
          </td>
          <td style="
              width: 14cm;
              border-top: 1pt solid rgb(166, 166, 166);
              border-right: 1pt solid rgb(166, 166, 166);
              border-bottom: 1pt solid rgb(166, 166, 166);
              border-image: initial;
              border-left: none;
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin: 0cm;
                margin-bottom: 0.0001pt;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{datos.params.otros_motivos}}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
    </p>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <span style="font-size: 12px; font-family: 'Calibri', sans-serif">&nbsp;</span>
    </p>
    <table style="border-collapse: collapse; border: none">
      <tbody>
        <tr>
          <td style="
              width: 685.8pt;
              border: 1pt solid rgb(166, 166, 166);
              padding: 0cm 5.4pt;
              vertical-align: top;
            ">
            <p style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0.0001pt;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <strong><u><span
                    style="font-size: 12px; font-family: 'Calibri', sans-serif">Observaciones:</span></u></strong>
            </p>
            <p style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0.0001pt;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <strong><u><span style="font-size: 12px; font-family: 'Calibri', sans-serif"><span
                      style="text-decoration: none">&nbsp;</span>{{datos.params.observaciones}}</span></u></strong>
            </p>
            <p style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0.0001pt;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif"><span
                      style="text-decoration: none">&nbsp;</span></span></strong>
            </p>
            <p style="
                margin-top: 0cm;
                margin-right: 0cm;
                margin-bottom: 0.0001pt;
                margin-left: 0cm;
                font-size: 16px;
                font-family: 'Times New Roman', serif;
              ">
              <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif"><span
                      style="text-decoration: none">&nbsp;</span></span></strong>
            </p>

          </td>
        </tr>
      </tbody>
    </table>
    <p style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
      ">
      <strong><span style="font-size: 13px; font-family: 'Calibri', sans-serif">&nbsp;</span></strong>
    </p>

    <!-- Pagina 4 -->
    <div class="page-break-before"></div>

    <div class="header" style="width: 100%">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        border: none;
        border-bottom: solid windowtext 1pt;
        padding: 0cm 0cm 1pt 0cm;
      ">
      <div style="
          margin: 0cm;
          margin-bottom: 0.0001pt;
          font-size: 16px;
          font-family: 'Times New Roman', serif;
          border: none;
          border-bottom: solid windowtext 1pt;
          padding: 0cm 0cm 1pt 0cm;
        ">
        <p style="
            margin: 0cm;
            margin-bottom: 0.0001pt;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            border: none;
            padding: 0cm;
          ">
          <strong><span style="font-size: 13px; font-family: 'Calibri', sans-serif">Volumen de producci&oacute;n anual
              (&Uacute;ltimo a&ntilde;o
              completo):</span></strong>
        </p>
      </div>
      <p style="
          margin: 0cm;
          margin-bottom: 0.0001pt;
          font-size: 16px;
          font-family: 'Times New Roman', serif;
        ">
        <strong><u><span style="font-size: 13px; font-family: 'Calibri', sans-serif"><span
                style="text-decoration: none">&nbsp;</span></span></u></strong>
      </p>
      <table style="width: 689.6pt; border-collapse: collapse; border: none">
        <tbody>
          <tr>
            <td style="
                width: 138.8pt;
                border: solid #a6a6a6 1pt;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 42.95pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">A&ntilde;o:</span></strong>
              </p>
            </td>
            <td style="
                width: 105.45pt;
                border: solid #a6a6a6 1pt;
                border-left: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 42.95pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">N&ordm; de animales
                    sacrificados</span></strong>
              </p>
            </td>
            <td style="
                width: 86.15pt;
                border: solid #a6a6a6 1pt;
                border-left: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 42.95pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">N&ordm; de canales
                    despiezadas</span></strong>
              </p>
            </td>
            <td style="
                width: 119.85pt;
                border: solid #a6a6a6 1pt;
                border-left: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 42.95pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">N&ordm; de Piezas
                    elaboradas</span></strong>
              </p>
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">(Incluir compras de curado y/o
                    semicurado)</span></strong>
              </p>
            </td>
            <td style="
                width: 155.75pt;
                border: solid #a6a6a6 1pt;
                border-left: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 42.95pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">N&ordm; de pieza
                    transformadas:
                    (Deshuesados/troceados/loncheados)</span></strong>
              </p>
            </td>
            <td style="
                width: 83.6pt;
                border: solid #a6a6a6 1pt;
                border-left: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 42.95pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                  text-align: center;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">Kg de carne
                    comercializada</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td style="
                width: 138.8pt;
                border: solid #a6a6a6 1pt;
                border-top: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 43.05pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">Producci&oacute;n
                    Propia</span></strong>
              </p>
            </td>
            <td style="
                width: 105.45pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    params.volumen_produccion.propia.animales_sacrificados
                  }}</span>
              </p>
            </td>
            <td style="
                width: 86.15pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    params.volumen_produccion.propia.canales_despiezadas
                  }}</span>
              </p>
            </td>
            <td style="
                width: 119.85pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    params.volumen_produccion.propia.piezas_elaboradas
                  }}</span>
              </p>
            </td>
            <td style="
                width: 155.75pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                      params.volumen_produccion.propia.piezas_transformadas
                    }}</span></strong>
              </p>
            </td>
            <td style="
                width: 83.6pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                      params.volumen_produccion.propia.carne_comercializada
                    }}</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td style="
                width: 138.8pt;
                border: solid #a6a6a6 1pt;
                border-top: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 59.2pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">Producci&oacute;n
                    Maquila&nbsp;</span></strong>
              </p>
            </td>
            <td style="
                width: 105.45pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    params.volumen_produccion.maquila.animales_sacrificados
                  }}</span>
              </p>
            </td>
            <td style="
                width: 86.15pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    params.volumen_produccion.maquila.canales_despiezadas
                  }}</span>
              </p>
            </td>
            <td style="
                width: 119.85pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    params.volumen_produccion.maquila.piezas_elaboradas
                  }}</span>
              </p>
            </td>
            <td style="
                width: 155.75pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                      params.volumen_produccion.maquila.piezas_transformadas
                    }}</span></strong>
              </p>
            </td>
            <td style="
                width: 83.6pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 43.05pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                      params.volumen_produccion.maquila.carne_comercializada
                    }}</span></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td style="
                width: 138.8pt;
                border: solid #a6a6a6 1pt;
                border-top: none;
                background: #f3f3f3;
                padding: 0cm 5.4pt 0cm 5.4pt;
                height: 59.2pt;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <strong><span style="font-size: 12px; font-family: 'Calibri', sans-serif">Total&nbsp;</span></strong>
              </p>
            </td>
            <td style="
                width: 105.45pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 59.2pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    Number(
                      params.volumen_produccion.propia.animales_sacrificados
                    ) +
                      Number(
                        params.volumen_produccion.maquila.animales_sacrificados
                      ) || ""
                  }}</span>
              </p>
            </td>
            <td style="
                width: 86.15pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 59.2pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    Number(
                      params.volumen_produccion.propia.canales_despiezadas
                    ) +
                      Number(
                        params.volumen_produccion.maquila.canales_despiezadas
                      ) || ""
                  }}</span>
              </p>
            </td>
            <td style="
                width: 119.85pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 59.2pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    Number(params.volumen_produccion.propia.piezas_elaboradas) +
                      Number(
                        params.volumen_produccion.maquila.piezas_elaboradas
                      ) || ""
                  }}</span>
              </p>
            </td>
            <td style="
                width: 155.75pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 59.2pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    Number(
                      params.volumen_produccion.propia.piezas_transformadas
                    ) +
                      Number(
                        params.volumen_produccion.maquila.piezas_transformadas
                      ) || ""
                  }}</span>
              </p>
            </td>
            <td style="
                width: 83.6pt;
                border-top: none;
                border-left: none;
                border-bottom: 1pt solid rgb(166, 166, 166);
                border-right: 1pt solid rgb(166, 166, 166);
                padding: 0cm 5.4pt;
                height: 59.2pt;
                vertical-align: top;
              ">
              <p style="
                  margin: 0cm;
                  margin-bottom: 0.0001pt;
                  font-size: 16px;
                  font-family: 'Times New Roman', serif;
                ">
                <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{
                    Number(
                      params.volumen_produccion.propia.carne_comercializada
                    ) +
                      Number(
                        params.volumen_produccion.maquila.carne_comercializada
                      ) || ""
                  }}</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style="
          margin: 0cm;
          margin-bottom: 0.0001pt;
          font-size: 16px;
          font-family: 'Times New Roman', serif;
        ">
        <strong><u><span style="font-size: 13px; font-family: 'Calibri', sans-serif"><span
                style="text-decoration: none">&nbsp;</span></span></u></strong>
      </p>
    </div>

    <!-- Pagina 5 -->
    <div class="page-break-before"></div>

    <div class="header" style="width: 100%">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="
        margin: 0cm;
        margin-bottom: 0.0001pt;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        border: none;
        border-bottom: solid windowtext 1pt;
        padding: 0cm 0cm 1pt 0cm;
      ">
      <div style="
          margin: 0cm;
          margin-bottom: 0.0001pt;
          font-size: 16px;
          font-family: 'Times New Roman', serif;
          border: none;
          border-bottom: solid windowtext 1pt;
          padding: 0cm 0cm 1pt 0cm;
        ">
        <div style="
            margin: 0cm;
            margin-bottom: 0.0001pt;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            border: none;
            border-bottom: solid windowtext 1pt;
            padding: 0cm 0cm 1pt 0cm;
            background: white;
          ">
          <p style="
              margin: 0cm;
              margin-bottom: 0.0001pt;
              font-size: 16px;
              font-family: 'Times New Roman', serif;
              background: white;
              border: none;
              padding: 0cm;
            ">
            <strong><span style="font-size: 13px; font-family: 'Calibri', sans-serif">Actividades del proceso
                (instalaciones)</span></strong>
          </p>
        </div>
        <p style="
            margin: 0cm;
            margin-bottom: 0.0001pt;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
          ">
          <strong><span style="
                font-size: 12px;
                font-family: 'Calibri', sans-serif;
                color: gray;
              ">&nbsp;</span></strong>
        </p>
        <table style="width: 714.15pt; border-collapse: collapse; border: none">
          <tbody>
            <tr>
              <td style="
                  width: 246.45pt;
                  border: solid #a6a6a6 1pt;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.05pt;
                ">
                <div style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    border: solid windowtext 1pt;
                    padding: 1pt 4pt 1pt 4pt;
                    background: #fff2cc;
                  ">
                  <p style="
                      margin: 0cm;
                      margin-bottom: 0.0001pt;
                      font-size: 16px;
                      font-family: 'Times New Roman', serif;
                      text-align: center;
                      background: #fff2cc;
                      border: none;
                      padding: 0cm;
                    ">
                    <strong><span style="
                          font-size: 12px;
                          font-family: 'Calibri', sans-serif;
                        ">Instalaciones de las actividades subcontratadas.
                        Proveedor del servicio</span></strong>
                  </p>
                </div>
              </td>
              <td style="
                  width: 91.45pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.05pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Actividad</span></strong>
                </p>
              </td>
              <td style="
                  width: 91.25pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.05pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Volumen de animales/canales/piezas/kg de carne</span></strong>
                </p>
              </td>
              <td colspan="2" style="
                  width: 94.75pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.05pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Esta certificada la actividad subcontratada</span></strong>
                </p>
              </td>
              <td style="
                  width: 93.3pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.05pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Entidad de certificaci&oacute;n</span></strong>
                </p>
              </td>
              <td style="
                  width: 96.95pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.05pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Certificado de conformidad y anexo t&eacute;cnico</span></strong>
                </p>
              </td>
            </tr>
            <tr v-for="item in params.actividades_proceso.subcontratadas" :key="item.id">
              <td style="
                  width: 246.45pt;
                  border-right: 1pt solid rgb(166, 166, 166);
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-left: 1pt solid rgb(166, 166, 166);
                  border-image: initial;
                  border-top: none;
                  padding: 0cm 5.4pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="
                      font-size: 11px;
                      font-family: 'Calibri', sans-serif;
                      color: gray;
                    ">Nombre: {{ item.nombre }}</span>
                </p>
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="
                      font-size: 11px;
                      font-family: 'Calibri', sans-serif;
                      color: gray;
                    ">Direcci&oacute;n de la Instalaci&oacute;n:
                    {{ item.direccion }}</span>
                </p>
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="
                      font-size: 11px;
                      font-family: 'Calibri', sans-serif;
                      color: gray;
                    ">N&ordm;RGSEAA*: {{ item.rgseaa }}</span>
                </p>
              </td>
              <td style="
                  width: 91.45pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-right: 1pt solid rgb(166, 166, 166);
                  padding: 0cm 5.4pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{ item.actividad }}</span>
                </p>
              </td>
              <td style="
                  width: 91.25pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-right: 1pt solid rgb(166, 166, 166);
                  padding: 0cm 5.4pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{ item.volumen }}</span>
                </p>
              </td>
              <td style="
                  width: 47.65pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid #a6a6a6 1pt;
                  border-right: solid #a6a6a6 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <span v-if="item.certificada != 'SI'"
                    style="font-size: 12px; font-family: 'Calibri', sans-serif">NO</span>
                </p>
              </td>
              <td style="
                  width: 47.1pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid #a6a6a6 1pt;
                  border-right: solid #a6a6a6 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <span v-if="item.certificada == 'SI'"
                    style="font-size: 12px; font-family: 'Calibri', sans-serif">SI</span>
                </p>
              </td>
              <td style="
                  width: 93.3pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-right: 1pt solid rgb(166, 166, 166);
                  padding: 0cm 5.4pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{ item.entidad }}</span>
                </p>
              </td>
              <td style="
                  width: 96.95pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-right: 1pt solid rgb(166, 166, 166);
                  padding: 0cm 5.4pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{ item.conformidad_anexo }}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style="
            margin: 0cm;
            margin-bottom: 0.0001pt;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            margin-left: -7.1pt;
            text-align: center;
          ">
          <strong><span style="
                font-size: 12px;
                font-family: 'Calibri', sans-serif;
                color: #c00000;
              ">&nbsp;</span></strong>
        </p>
        <table style="width: 714.25pt; border-collapse: collapse; border: none">
          <tbody>
            <tr>
              <td style="
                  width: 257.6pt;
                  border: solid #a6a6a6 1pt;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 10.35pt;
                ">
                <div style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    border: solid windowtext 1pt;
                    padding: 1pt 4pt 1pt 4pt;
                    background: #fff2cc;
                  ">
                  <p style="
                      margin: 0cm;
                      margin-bottom: 0.0001pt;
                      font-size: 16px;
                      font-family: 'Times New Roman', serif;
                      text-align: center;
                      background: #fff2cc;
                      border: none;
                      padding: 0cm;
                    ">
                    <strong><span style="
                          font-size: 12px;
                          font-family: 'Calibri', sans-serif;
                        ">Instalaciones alquiladas a incluir en el alcance de
                        certificaci&oacute;n</span></strong>
                  </p>
                </div>
              </td>
              <td style="
                  width: 182.3pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 10.35pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Direcci&oacute;n de la instalaci&oacute;n</span></strong>
                </p>
              </td>
              <td style="
                  width: 91.45pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 10.35pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Actividad</span></strong>
                </p>
              </td>
              <td style="
                  width: 91.45pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 10.35pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Volumen de animales/canales/piezas/kg de carne</span></strong>
                </p>
              </td>
              <td colspan="2" style="
                  width: 91.45pt;
                  border: solid #a6a6a6 1pt;
                  border-left: none;
                  background: #f3f3f3;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 10.35pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <strong><span style="
                        font-size: 12px;
                        font-family: 'Calibri', sans-serif;
                      ">Solicita que este bajo la certificaci&oacute;n de
                      Araporcei</span></strong>
                </p>
              </td>
            </tr>
            <tr v-for="item in params.actividades_proceso.alquiladas" :key="item.id">
              <td style="
                  width: 257.6pt;
                  border-right: 1pt solid rgb(166, 166, 166);
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-left: 1pt solid rgb(166, 166, 166);
                  border-image: initial;
                  border-top: none;
                  padding: 0cm 5.4pt;
                  height: 14.45pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="
                      font-size: 11px;
                      font-family: 'Calibri', sans-serif;
                      color: gray;
                    ">N&ordm;RGSEAA*: {{ item.rgseaa }}</span>
                </p>
              </td>
              <td style="
                  width: 182.3pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-right: 1pt solid rgb(166, 166, 166);
                  padding: 0cm 5.4pt;
                  height: 14.45pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{ item.direccion }}</span>
                </p>
              </td>
              <td style="
                  width: 91.45pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-right: 1pt solid rgb(166, 166, 166);
                  padding: 0cm 5.4pt;
                  height: 14.45pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{ item.actividad }}</span>
                </p>
              </td>
              <td style="
                  width: 91.45pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: 1pt solid rgb(166, 166, 166);
                  border-right: 1pt solid rgb(166, 166, 166);
                  padding: 0cm 5.4pt;
                  height: 14.45pt;
                  vertical-align: top;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                  ">
                  <span style="font-size: 12px; font-family: 'Calibri', sans-serif">{{ item.volumen }}</span>
                </p>
              </td>
              <td style="
                  width: 45.7pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid #a6a6a6 1pt;
                  border-right: solid #a6a6a6 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.45pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <span v-if="item.solicitda_certificacion == 'SI'"
                    style="font-size: 12px; font-family: 'Calibri', sans-serif">SI</span>
                </p>
              </td>
              <td style="
                  width: 45.75pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid #a6a6a6 1pt;
                  border-right: solid #a6a6a6 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 14.45pt;
                ">
                <p style="
                    margin: 0cm;
                    margin-bottom: 0.0001pt;
                    font-size: 16px;
                    font-family: 'Times New Roman', serif;
                    text-align: center;
                  ">
                  <span v-if="item.solicitda_certificacion != 'SI'"
                    style="font-size: 12px; font-family: 'Calibri', sans-serif">NO</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style="
            margin: 0cm;
            margin-bottom: 0.0001pt;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            margin-left: -7.1pt;
            text-align: center;
          ">
          <strong><span style="
                font-size: 12px;
                font-family: 'Calibri', sans-serif;
                color: #c00000;
              ">SE PUEDE CUMPLIMENTAR ESTE LISTADO CON LA INFORMACION SOLICITADA
              O PRESENTAR OTRO EN EL FORMATO QUE UTILIZA LA EMPRESA</span></strong>
        </p>
      </div>
    </div>

    <!-- Pagina 6 -->
    <div class="page-break-before"></div>

    <div class="header" style="width: 100%">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>ACEPTA</h2>
    <p>&nbsp;</p>
    <p>
      <span style="font-weight: 400">1.-&nbsp; Cumplir con las condiciones descritas en los procedimientos
        de
      </span>
      <span style="font-weight: 400">ARAPORCEI</span>
      <span style="font-weight: 400">
        que le sean de aplicaci&oacute;n y en especial con el PG-CER 04
        &ldquo;Procedimiento para el correcto Uso de la Marca de
        Conformidad&rdquo; as&iacute; como aquellos requisitos establecidos en
        los documentos normativos y si procede, en los requisitos establecidos
        en la legislaci&oacute;n nacional y auton&oacute;mica, as&iacute; como
        en los procedimientos establecidos por la Entidad Nacional de
        Acreditaci&oacute;n.</span>
    </p>
    <p>
      <span style="font-weight: 400">2.- </span>
      <span style="font-weight: 400">Permitir cualquier verificaci&oacute;n que sea requerida realizar por
        ARAPORCEI en el marco de esta certificaci&oacute;n.</span>
    </p>
    <p>
      <span style="font-weight: 400">3.- Facilitar a ARAPORCEI la informaci&oacute;n necesaria para la
        realizaci&oacute;n de las auditorias, as&iacute; como los datos de
        producci&oacute;n solicitados anualmente por Araporcei a fin de
        establecer y cumplir con la frecuencia de evaluaci&oacute;n por centro
        de producci&oacute;n establecido en el Protocolo de Certificaci&oacute;n
        en su edici&oacute;n en vigor.</span>
    </p>
    <p>
      <span style="font-weight: 400">4.- Comunicar a ARAPORCEI, en el menor plazo posible, cualquier cambio
        relevante que afecte o influya en el proceso de certificaci&oacute;n
        contratado.</span>
    </p>
    <p>
      <span style="font-weight: 400">5.- Adjuntar la documentaci&oacute;n que se identifica en el Anexo
        1.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400">6.- Aceptar el resultado de las auditor&iacute;as
        realizadas.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400">7.- Abonar los servicios contratados conforme a las condiciones
        establecidas en la oferta-contrato.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400">8.- Autorizar a ARAPORCEI a comunicar los resultados de las
        auditor&iacute;as y la informaci&oacute;n relacionada con las mismas a
        las autoridades competentes en la forma que &eacute;sta establezca y
        expresamente:</span>
    </p>
    <ul>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Autorizo a </span>
        <strong>araporcei SL</strong>
        <span style="font-weight: 400">
          a facilitar la informaci&oacute;n relativa al control efectuado en las
          instalaciones bajo el alcance (y la de los proveedores, si procede)
          siempre y cuando la autoridad competente en la materia se lo solicite,
          extremo este que el cliente consiente a la firma de la presente
          solicitud.&nbsp;</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Autorizo a</span>
        <strong> araporcei SL</strong>
        <span style="font-weight: 400">
          a facilitar informaci&oacute;n a ASICI y poder proporcionar al sistema
          de informaci&oacute;n ITACA los datos relativos a la gesti&oacute;n y
          uso de precintos de los productos ib&eacute;ricos, siempre y cuando la
          autoridad competente en la materia lo entienda oportuno o se lo
          solicite, extremo este que el cliente consiente a la firma de la
          presente solicitud.</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Autorizo a</span>
        <strong> araporcei SL</strong>
        <span style="font-weight: 400">
         a evaluar en el sistema de información ITACA los datos relativos a su autocontrol y a
          la gestión y uso de precintos de los productos ibéricos, extremo este que el cliente
          consiente a la firma de la presente solicitud.</span>
      </li>
    </ul>
    <p>
      <span style="font-weight: 400">9.-</span>
      <strong></strong>
      <span style="font-weight: 400">En el caso de que el solicitante realice actividades a maquila y
        excluya parte de la producci&oacute;n de la certificaci&oacute;n porque
        este bajo el alcance de otra Entidad de Certificaci&oacute;n,
        deber&aacute; tener a disposici&oacute;n de Araporcei la siguiente
        documentaci&oacute;n</span>
      <strong>:&nbsp;</strong>
    </p>
    <ul>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Acuerdo- contrato con el propietario (s) del producto en el que se
          identifique las instalaciones y la parte de la producci&oacute;n que
          no se acoge a la certificaci&oacute;n de Araporcei.</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Certificado de Conformidad de Producto del propietario
          (s).&nbsp;</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Compromiso de mantener actualizada la informaci&oacute;n sobre los
          propietarios y la producci&oacute;n no sujeta a la
          certificaci&oacute;n.&nbsp;</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Autorizaci&oacute;n expresa del propietario del producto para que el
          certificado de conformidad de Araporcei recoja est&aacute;
          exclusi&oacute;n en el alcance de certificaci&oacute;n.&nbsp;</span>
      </li>
    </ul>
    <p>
      <span style="font-weight: 400">10.- Cuando el solicitante de la certificaci&oacute;n alquile
        instalaciones para realizar actividades propias o a maquila
        deber&aacute; tener a disposici&oacute;n de Araporcei la siguiente
        documentaci&oacute;n:</span>
    </p>
    <ul>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Contrato de alquiler con el propietario de la instalaci&oacute;n en
          el que se indique la ubicaci&oacute;n de la parte alquilada, tipo de
          producto y actividad bajo el alcance de
          certificaci&oacute;n.&nbsp;</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Compromiso de mantener actualizada la informaci&oacute;n sobre las
          instalaciones alquiladas y la producci&oacute;n que se encuentra en
          las instalaciones alquiladas.&nbsp;</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Autorizaci&oacute;n expresa del propietario de la instalaci&oacute;n
          alquilada para que el certificado de conformidad recoja la
          direcci&oacute;n de la instalaci&oacute;n alquilada en el alcance de
          certificaci&oacute;n.&nbsp;</span>
      </li>
    </ul>
    <p>
      <span style="font-weight: 400">11.- Araporcei en la fase del proceso de certificaci&oacute;n,
        revisi&oacute;n de la solicitud, podr&aacute; realizar una visita para
        analizar el riesgo de la certificaci&oacute;n cuando se trate de un
        proveedor que tenga o haya tenido su certificaci&oacute;n retirada,
        suspendida o, en otras circunstancias que Araporcei considere de riesgo.
        Esta visita se facturar&aacute; al cliente y deber&aacute; estar abonada
        antes de su realizaci&oacute;n.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400">La aceptaci&oacute;n del Contrato de prestaci&oacute;n de servicios y
        Oferta econ&oacute;mica para el desarrollo de las labores de
        certificaci&oacute;n de producto y la firma de esta Solicitud Formal de
        Certificaci&oacute;n tiene car&aacute;cter contractual, y supone la
        total aceptaci&oacute;n de los t&eacute;rminos t&eacute;cnicos y
        econ&oacute;micos de la propuesta y, as&iacute; como de todos los
        procedimientos aprobados y vigentes de ARAPORCEI que le sean de
        aplicaci&oacute;n a la empresa solicitante de la
        certificaci&oacute;n.</span>
    </p>

    <!-- Pagina 7 -->
    <div class="page-break-before"></div>

    <div class="header" style="width: 100%">
      <table style="width: 100%">
        <tbody style="font-weight: 400">
          <tr style="text-align: center">
            <td colspan="1">
              <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px" />
              <h2 style="font-weight: 700; font-size: 25px">araporcei</h2>
            </td>
            <td colspan="2">
              <h2>
                Solicitud de Certificación
                <br />
                Productos Ibéricos
              </h2>
            </td>
            <td>
              <h4 style="text-align: right">SS_C_IB<br />Rev.20_010721</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>ANEXO 1: DOCUMENTACION</h2>
    <p>&nbsp;</p>
    <p>
      <span style="font-weight: 400">1.-Env&iacute;o a </span>
      <strong>araporcei SL</strong>
      <span style="font-weight: 400">, adjunto a la presente solicitud:</span>
    </p>
    <ol>
      <ol type="a">
        <li style="font-weight: 400">
          <span style="font-weight: 400">Procedimiento de Trazabilidad (desde la entrada de las materias
            primas hasta la expedici&oacute;n de los productos).</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">Procedimiento de Compras y Control de Proveedores.</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">Tarjeta CIF/NIF.&nbsp;</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">Escrituras de la sociedad.&nbsp;</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">Poder de la persona que solicita la certificaci&oacute;n.</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">Si aplica, fotocopia del documento de inscripci&oacute;n de la
            marca (s) comercial (es) en el Registro de Patentes y Marcas</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">Copia del registro sanitario.</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">Carta de declaraci&oacute;n de no haber tenido o tener
            ning&uacute;n procedimiento de sanci&oacute;n por parte de la
            administraci&oacute;n competente en materia de salud p&uacute;blica
            y/o fraude.</span>
        </li>
        <li style="font-weight: 400">
          <span style="font-weight: 400">En caso de haber trabajado con otra entidad antes de aceptar la
            propuesta de servicios de Araporcei, carta de declaraci&oacute;n de
            no estar suspendida o retirada la certificaci&oacute;n con la
            entidad anterior. Certificado de conformidad emitido por la anterior
            entidad de certificaci&oacute;n. &Uacute;ltimo informe de
            auditor&iacute;a.</span>
        </li>

<li style="font-weight: 400">
          <span style="font-weight: 400">En el caso de solicitar la certificación a ARAPORCEI tras un
            proceso de retirada por parte de ARAPORCEI u otra entidad de certificación, carta de retirada e
            n la que se indiquen los motivos que dieron lugar a la retirada de la certificación en cumplimiento
            de la normativa de aplicación, informe de la última auditoría realizada y plan de acciones
            reparadoras/correctoras que propone el cliente para una valoración por parte ARAPORCEI con el
            objeto de verificar si es adecuado para poder iniciar el proceso. La evaluación de la eficacia
            el plan presentado se realizará durante la auditoria inicial.</span>
        </li>

        <li style="font-weight: 400">
          <span style="font-weight: 400">Listado de proveedores con la informaci&oacute;n solicitada en esta
            solicitud (si no figura cumplimentada la informaci&oacute;n en esta
            solicitud).&nbsp;</span>
        </li>

        <li style="font-weight: 400">
          <span style="font-weight: 400">Datos referentes a las instalaciones que utiliza, tanto si son
            propias como alquiladas, si están certificadas o no, así como de aquellas instalaciones propias
            alquiladas total o parcialmente a terceros, con el fin de poder delimitar adecuadamente la
            verificación de la actividad del operador.</span>
        </li >

        <li style="font-weight: 400">
          En el caso que el solicitante proceda de otra entidad de certificación.<br/>
            i. <span style="text-decoration: underline">Cambio voluntario de EC por parte del operador:</span>
            <ol>
              <li style="font-weight: 400">
                Araporcei comunicará la solicitud recibida a la Autoridad Competente de la
                Comunidad Autónoma en el plazo de 5 días hábiles, desde la recepción de la
                solicitud. El certificado de conformidad del que dispone el solicitante
                estará en vigor 15 días hábiles desde la comunicación de baja de Araporcei a
                 la CCAA. Para ello, Araporcei deberá disponer de la siguiente documentacion:
              </li>
              <li style="font-weight: 400">
                Justificante de haber presentado la correspondiente solicitud
                de baja en la EC anterior.
              </li>
              <li style="font-weight: 400">
                Toda la información de su expediente, que resulte necesaria para mantener
                el nivel de control exigible en este protocolo, que incluirá como mínimo la siguiente:<br/>
                    <ul><li style="font-weight: 400">Copia del último certificado en vigor
                      emitido y, en su caso, de sus anexos técnicos (desglose de instalaciones y
                      actividades sometidas a certificación, diferenciando las actividades desarrolladas
                      como propietario de producto y los servicios prestados a maquila);</li>
                    <li style="font-weight: 400">Último informe de auditoría. Si existen no conformidades se solicitará
                      también el plan de acciones correctoras y el informe de seguimiento indicando el cierre o
                       no de las no conformidades.</li>
                    </ul>
              </li>
              <li style="font-weight: 400">
                Si a la fecha de solicitud de certificación existieran no conformidades abiertas el plan
                de acciones correctoras lo revisará Araporcei, comunicando esta situación a la autoridad
                competente de la Comunidad Autónoma en la que se encuentre la instalación del operador.
              </li>
              <li style="font-weight: 400">
                Si a la fecha de solicitud de certificación se le hubiera retirado el certificado de conformidad
                al operador o se encontrara en suspensión temporal, Araporcei no aceptará la solicitud hasta que
                no haya resuelto los incumplimientos que dieron lugar a la suspensión o retirada.
              </li>
              <li style="font-weight: 400">
                Araporcei mantendrá informada a la autoridad competente de la Comunidad Autónoma en la que
                esté radicada el operador, sobre todo el proceso de cambio de entidad.
              </li>
              <li style="font-weight: 400">
                El operador destruirá los stocks de materiales en los que aparezca el nombre de la EC de origen
                y se abstendrá de utilizarlos desde el mismo momento en que ésta le comunique la baja de su certificado de conformidad.
              </li>
            </ol>
            ii. <span style="text-decoration: underline">Cambio de EC de forma forzosa para el operador:</span> (por motivo de la suspensión de la
            acreditación de la EC, cese repentino de la actividad por parte de la EC o cancelación de la autorización otorgada por la Comunidad autónoma a la EC).<br/>
            <ol>
              <li style="font-weight: 400">
                Araporcei admitirá que el certificado de conformidad de producto emitido por la entidad
                 anterior sea válido hasta la fecha de la siguiente visita de seguimiento programada o renovación
                 , en función de la frecuencia establecida en el protocolo correspondiente, salvo que ENAC adopte
                  la decisión de que los certificados no tienen validez. Es decir, el operador podrá emitir
                  Documentos de Expedición de Producto Conforme (DEPC) y Registros de Trazabilidad (RT) con normalidad hasta esa fecha.
              </li>
              <li style="font-weight: 400">
                Si la visita de seguimiento o renovación coincide en los 30 días siguientes a la ratificación
                de la suspensión, el operador podrá seguir emitiendo los documentos citados durante esos 30 días.
              </li>
              <li style="font-weight: 400">
                En ambos casos, al final del plazo indicado, el operador debe haber contratado los servicios de Araporcei.
                Araporcei iniciará el proceso de certificación del operador siguiendo lo establecido en sus Procedimientos.
              </li>
              <li style="font-weight: 400">
                Araporcei solicitará al operador la información de su expediente, que resulte necesaria para mantener
                el nivel de control exigible en el Protocolo, que incluirá como mínimo la siguiente:
              <ul><li style="font-weight: 400">Copia del último certificado en vigor emitido y, en su caso, de
                sus anexos técnicos (desglose de instalaciones y actividades sometidas a certificación, diferenciando
                las actividades desarrolladas como propietario de producto y los servicios prestados a maquila);</li>
                    <li style="font-weight: 400">Último informe de auditoría. Si existen no conformidades se solicitará
                       también el plan de acciones correctoras y el informe de seguimiento indicando el cierre
                       o no de las no conformidades.</li>
                    </ul>
              </li>
              <li style="font-weight: 400">
                Araporcei mantendrá informada a la autoridad competente según el territorio donde desarrolle
                su actividad el operador, durante todo el proceso de cambio de entidad, con el deber de comunicar
                cualquier avance o nueva información relacionada con el traslado del expediente del operador.
              </li>
              <li style="font-weight: 400">
                Si el certificado del operador caducase durante el proceso del cambio de entidad, Araporcei actuará
                conforme a las medidas adoptadas por la autoridad competente
              </li>
            </ol>
        </li>
        <li style="font-weight: 400">
          <b>Araporcei SL se reserva el derecho de solicitar</b> que se adjunte a la presente solicitud,
          conforme a nuestro Manual de Cumplimiento Normativo, documentación de acuerdo con
          la legislación en vigor en materia de prevención de riesgos penales: Manual de Cumplimiento
          Normativo de su empresa o documento análogo, Certificado de aprobación del mismo por
          el órgano competente para ello en atención a su estructura social, Protocolo o protocolos
          de actuación con proveedores y clientes, Certificado de aprobación de tales documentos por
          el órgano competente para ello en su empresa, Designación del oficial de cumplimiento u
          órgano que asuma dicha función, así como certificado del acta en que se le designa por el
          órgano competente de su mercantil.
        </li>
      </ol>
    </ol>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong>Firma y sello del solicitante </strong>
      <strong></strong>
      <strong></strong>
      <strong></strong>
      <strong></strong>
      <strong></strong>
      <strong>Revisado por araporcei, SL</strong>
    </p>

    <div class="footer" style="width: 100%">
      <p align="center" class="MsoFooter" style="text-align: center">
        <span style="font-size: 11px; font-family: 'Calibri', sans-serif">C/ Gabriel Miro, 3 Edificio W&eacute;rtice
          Planta 2 Modulo 1 41703
          &ndash; Dos Hermanas (SEVILLA)</span>
      </p>
      <p align="center" class="MsoFooter" style="text-align: center">
        <span lang="EN-US" style="font-size: 11px; font-family: 'Calibri', sans-serif">Tel. 954.64.74.23 Fax.
          954.64.44.16 Email:
          secretec@araporcei.es</span>
      </p>
      <!--EndFragment-->
      <p><br /></p>
    </div>
  </div>
</template>

<style scoped>
@media screen {
  div.footer {
    display: none;
  }
}

@media print {
  div.footer {
    margin-top: 10px;
    position: fixed;
    bottom: 0;
  }

  div.footer p {
    text-align: center;
  }

  table,
  table tr td,
  table tr th {
    page-break-inside: avoid;
  }
}

div.header {
  display: none;
}

@media print {
  div.header {
    /*position: fixed;*/
    top: 0;
    min-height: 150px;
    display: block;
  }
}

table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 0.5px solid black;
}

thead {
  display: table-row-group;
}

tr td {
  page-break-inside: avoid;
}

.no-border {
  border: none !important;
}
</style>

<script>
import MODELS from '~/models/araporcei/solicitudes'

export default {
  props: {
    datos: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      ...this.datos,
      tipoSolicitud: MODELS.SOLICITUD_TIPO,
      tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
      tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
      tipoProducto: MODELS.PRODUCTO_TIPO,
      tipoRacial: MODELS.RACIAL_TIPO,
      tipoEstado: MODELS.ESTADOS_TIPO,
      tipoPresentacion: MODELS.PRESENTACION_TIPO,
      tipoCertificacion: MODELS.CERTIFICACION_TIPO,
    }
  },
  computed: {
    isModificacion() {
      return this.params.tipo_solicitud === 'modificacion'
    },
    isOperadorResposable() {
      return this.params.alcance_proceso.tipo_operador.includes('responsable')
    },
    isOperadorPrestador() {
      return this.params.alcance_proceso.tipo_operador.includes('prestador')
    },
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    instalaciones() {
      const actividades = this.params.alcance_producto.map((e) => e.rgseaa)
      return this.cliente.instalaciones.filter((e) => actividades.includes(e.rgseaa))
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    formatDateLong(date) {
      return date ? this.$moment(date).format('LL') : ''
    },
    getDatosAlcance(clave, value) {
      var bool = false
      this.params.alcance_producto.forEach((element) => {
        if (element[clave] == value) {
          bool = true
        }
      })
      return bool
    },
    isTipoProducto(tipoProducto) {
      return this.getDatosAlcance('producto', tipoProducto)
    },
    isAlimentacion(value) {
      return this.getDatosAlcance('alimentacion', value)
    },
    isRacial(value) {
      return this.getDatosAlcance('racial', value)
    },
    isEstado(value) {
      return this.getDatosAlcance('estado', value)
    },
    isPresentacion(value) {
      return this.getDatosAlcance('presentacion', value)
    },
    getMarcas(producto) {
      const marcas = []

      this.datos.params.alcance_producto.forEach((x) => {
        if (x.producto == producto && !marcas.includes(x.marcas)) {
          marcas.push(x.marcas)
        }
      })

      return marcas
    },
  },
}
</script>
