<template>
  <v-autocomplete ref="auditor" v-model="selected" :items="items" :item-text="text" item-value="uuid" :label="title"
    :rules="rules" :required="required" :disabled="disabled" :readonly="readonly" :dense="dense" :outlined="outlined"
    @change="onChange($event)"></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectAuditores',
  props: {
    value: String,
    title: { type: String, default: 'Auditor' },
    dense: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
  },
  mounted() {
    if (this.required) this.rules = [(v) => !!v || 'Obligatorio']
    this.getItems()
  },
  data(ctx) {
    return {
      rules: [],
      selected: ctx.value,
      items: [],
    }
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.auditor.reset()
      this.selected = val
    },
  },
  computed: {
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
  },
  methods: {
    text(item) {
      let nombre = item.nombre
      if (item.primer_apellido) nombre += ' ' + item.primer_apellido
      if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido
      if (item.apellidos) nombre += ' ' + item.apellidos
      return nombre
    },
    async getItems() {
      if (this.offlineMode) {
        const storage = JSON.parse(localStorage.getItem('auditores'))
        this.items = storage
      } else {
        let pagination = { page: 1, perPage: 1000 }
        const res = await this.$store.dispatch('auditores/list', pagination)
        if (res) {
          this.items = res.filter((item) => item.user?.activo)
          this.items = res
        }
      }
    },
    onChange(event) {
      this.$emit('input', event)
      this.$emit('change', event)
    },
  },
}
</script>
