<template>
  <span>
    <v-checkbox
      v-if="isNotGroup"
      v-model="value"
      :label="params.label"
      :ripple="!readonly"
      class="mt-2"
      color="success"
      :readonly="readonly"
      hide-details
      @click.native.stop="controlClick()"
    ></v-checkbox>
  </span>
</template>

<script>
export default {
  data: () => ({
    value: false,
    readonly: true,
  }),
  computed: {
    isNotGroup () {
      return !!this.params.data
    }
  },
  methods: {
    controlClick() {
      if (this.params.controlClick instanceof Function) {
        const params = {
          params: this.params,
          value: !this.params.value
        }
        if (this.params.data.uuid) params.uuid = this.params.data.uuid,
        this.params.controlClick(params);
      }
    },
  },
  created() {
    if (this.params.value !== undefined) this.value = this.params.value
    if (this.params.readonly === false) this.readonly = false
  }
}
</script>

