<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title primary-title>
          Proposición
          <v-spacer />
          <v-icon @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text v-if="dialog">
          <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="item.proposicion"
                    label="Proposicion"
                    :items="ESTADOS"
                    :rules="rules"
                    required
                    dense
                    outlined
                    hide-details="auto"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="ui.showPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        hide-no-data
                        hide-details="auto"
                        nopev-model="item.fechaProposicion"
                        :value="
                          item.fechaProposicion
                            ? $moment(item.fechaProposicion).format('DD-MM-YYYY')
                            : ''
                        "
                        label="Fecha de proposición"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="item.fechaProposicion"
                      @input="ui.showPicker = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <!--AUDITOR 1-->
                <div class="hr-sect">Auditor 1</div>
                <v-col cols="6">
                  <select-auditores-object
                    title="Auditor"
                    v-model="item.auditor1"
                    required
                    dense
                    outlined
                    hide-details="auto" />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="En calidad de"
                    required
                    dense
                    outlined
                    hide-details="auto"
                    :rules="rules"
                    v-model="item.enCalidadDeAuditor1" />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Observaciones"
                    v-model="item.observacionesAuditor1"
                    no-resize
                    :required="!isOceGlobal"
                    outlined></v-textarea>
                </v-col>

                <!--AUDITOR 2-->
                <div class="hr-sect">Auditor 2</div>
                <v-col cols="6">
                  <select-auditores-object
                    title="Auditor"
                    v-model="item.auditor2"
                    dense
                    outlined
                    hide-details="auto" />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    outlined
                    label="En calidad de"
                    v-model="item.enCalidadDeAuditor2"
                    hide-details="auto" />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Observaciones"
                    v-model="item.observacionesAuditor2"
                    auto-grow
                    outlined></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    color="primary"
                    v-model="item.set_revisada"
                    label="Marcar como revisada"></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-2 mb-2" text color="red lighten-2" @click="close()"> Cerrar </v-btn>
          <v-btn
            class="mr-2 mb-2"
            :color="item.set_revisada ? 'green lighten-2' : 'orange lighten-2'"
            @click="confirm()">
            <template v-if="item.set_revisada">Guardar y marcar como revisada</template>
            <template v-else>Guardar los datos sin finalizar la revisión</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\revisiones\ProponerDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Oct 24 2022
 **/

import SelectAuditoresObject from '~/components/forms/SelectAuditoresObject'
import Estados from '~/models/estados'

export default {
  props: {
    value: Boolean,
    proposicion: Object,
  },

  components: {
    SelectAuditoresObject,
  },

  data: () => ({
    dialog: false,
    valid: false,
    item: {},
    rules: [(v) => !!v || 'Obligatorio'],
    ESTADOS: Estados.DICTAMEN_DECISIONES_PROPOSICIONES,

    ui: {
      showPicker: false,
    },
  }),

  watch: {
    value(val) {
      this.dialog = val
      if (val) this.open()
    },
  },

  computed: {
    isCcl() {
      return this.$auth.user.empresa.nombre == 'Ccl'
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },
  },

  methods: {
    open() {
      this.item = { ...this.proposicion }
      this.item.fechaProposicion = new Date().toISOString().substr(0, 10)

      if (this.item.auditor1 && this.item.auditor1.length > 0) {
        this.item.auditor1 = this.item.auditor1[0]
      }
      this.assignToRevisor()

      this.item.enCalidadDeAuditor1 = this.item.enCalidadDeAuditor1 || 'Revisor'
      this.item.observacionesAuditor1 = this.item.observacionesAuditor1

      if (this.item.auditor2 && this.item.auditor2.length > 0) {
        this.item.auditor2 = this.item.auditor2[0]
      }
      this.item.enCalidadDeAuditor2 = this.item.enCalidadDeAuditor2
      this.item.observacionesAuditor2 = this.item.observacionesAuditor2
    },

    //+-------------------------------------------------
    // assignToRevisor()
    // Checks if there is no item.auditor_º and assigns the revisor
    // -----
    // Created on Mon Oct 17 2022
    //+-------------------------------------------------
    assignToRevisor() {
      console.warn(this.item.auditor1, this.item.revisor)
      if (!this.item.auditor_1 && this.item.revisor) {
        this.item.auditor_1 = this.item.revisor
        // this.item.en_calidad_de_auditor_1 = 'Revisor'
      }
    },

    close() {
      this.dialog = false
      this.item = {}
      this.$emit('input', null)
      this.$emit('close')
    },

    async confirm() {
      if (this.$refs.form.validate()) {
        this.$emit('confirm', this.item)
        this.close()
      }
    },
  },
}
</script>
<style scoped>
.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  font-weight: bold;
  margin: 8px 0px;
}
.hr-sect:before,
.hr-sect:after {
  content: '';
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}
</style>
