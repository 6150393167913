<template>
  <v-autocomplete
    ref="select"
    label="Municipio"
    v-model="selected"
    :items="items"
    item-text="nombre"
    item-value="uuid"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :dense="dense"
    :outlined="outlined"
    :filled="filled"
    :hide-details="hideDetails"
    :return-object="returnObject"
    no-data-text="Listado vacío, seleccione la provincia."
    @change="emitChange()"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectMunicipio',
  props: {
    value: [String, Object],
    provincia_uuid: { type: String, default: null },
    label: { type: String, default: 'Provincia' },
    readonly: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
    returnObject: { type: Boolean, default: false },
  },
  data: (ctx) => ({
    selected: ctx.value,
    items: [],
  }),
  computed: {
    rules() {
      return this.required ? [(v) => !!v || 'Obligatorio'] : []
    },
  },
  watch: {
    value(val) {
      if (!val && this.$refs.select) this.$refs.select.resetValidation()
      this.selected = val
    },
    provincia_uuid(val) {
      this.init()
    },
  },
  methods: {
    emitChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },
    async init() {
      if (this.provincia_uuid) {
        const res = await this.$axios.get(
          `municipios?provincia=${this.provincia_uuid}`
        )
        if (res) {
          this.items = res.data['hydra:member']
          for (let i = 0; i < this.items.length; i++) {
            const element = this.items[i]
            if (this.selected === element.uuid) break
            else if (i === this.items.length - 1) this.$emit('input', null)
          }
        }
      } else {
        this.items = []
      }
    },
  },
  created() {
    this.init()
  },
}
</script>
