<template>
  <div>
    <v-autocomplete
      ref="instalaciones"
      :label="label"
      v-model="selected"
      :items="items"
      :item-text="text"
      item-value="uuid"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :loading="loading"
      :chips="chips"
      :small-chips="smallChips"
      :dense="dense"
      :outlined="outlined"
      :multiple="multiple"
      hide-details="auto"
      :return-object="returnObject"
      @change="$emit('input', selected)"
    >
      <template v-slot:append-outer v-if="create">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed
              :disabled="!cliente || !cliente.uuid"
              v-on="on" @click="dialog = true"
              color="success" class="white--text"
              style="transform: translateY(-4px);">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear instalación</span>
        </v-tooltip>
      </template>
    </v-autocomplete>

    <!-- Dialog Instalaciones -->
    <GestionInstalacionesDialog
      v-model="dialog"
      :cliente="cliente"
      @close="dialog = false"
      @init="init()"/>
  </div>
</template>

<script>
// se utiliza el componente default desde 25/10/2021
// import GestionInstalacionesDialog from '~/components/clientes/instalaciones/araporcei/GestionInstalacionesDialog'
import GestionInstalacionesDialog from '~/components/clientes/instalaciones/GestionInstalacionesDefaultDialog'

export default {
  name: 'SelectInstalaciones',
  components: {
    GestionInstalacionesDialog,
  },
  props: {
    tiposInstalacion: {
      type: Array,
      default() {
        return ['propia']
      },
    },
    value: [String, Array, Object],
    label: { type: String, default: 'Instalaciones' },
    cliente: { type: Object, default: {} },
    create: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    returnObject: { type: Boolean, default: false },
  },
  created() {
    this.init()
  },
  data(ctx) {
    return {
      dialog: false,
      loading: false,
      selected: ctx.value,
      items: [],
    }
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.instalaciones.reset()
      this.selected = val
    },
    cliente() {
      this.init()
    },
  },
  computed: {
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
  },
  methods: {
    //+-------------------------------------------------
    // setValueByCodigo()
    // Useful method to be called by parent component
    // update value
    // when having problems with v-model
    // -----
    // Created on Thu Dec 02 2021
    //+-------------------------------------------------
    setValueByCodigo(codigo) {
      const selected = this.items.find((e) => e.rgseaa == codigo)
      this.selected = { ...selected }
      this.$emit('input', selected)
    },

    text(item) {
      let rgseaa = item.rgseaa == undefined || item.rgseaa == '' ? '' : item.rgseaa + ' - '
      return rgseaa + item.razon_social
    },

    async init() {
      // const self = this
      let instalaciones = []
      let tiposInstalacion = this.tiposInstalacion

      if (this.cliente.uuid) {
        this.loading = true
        const res = await this.$store.dispatch('clientes/get', this.cliente.uuid)
        if (res) {
          this.items = tiposInstalacion.length
            ? res.instalaciones.forEach((element) => {
                let arrayTipos = element.tipo_instalacions.map((a) => a.codigo)
                if (tiposInstalacion.some((item) => arrayTipos.includes(item))) {
                  instalaciones.push(element)
                }
              })
            : (instalaciones = res.instalaciones)

          instalaciones = instalaciones.filter((e) => e.is_active == true)
          this.items = instalaciones
        }
        this.loading = false
        // console.log("init -> this.items", this.items)
      }
    },
  },
}
</script>
