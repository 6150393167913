<template>
  <div>
    <v-app-bar app dense id="toolbar" color="primary" class="icon-menu" :class="{ 'toolbar-offline': $nuxt.isOffline }">
      <v-btn icon class="icon-menu" @click="drawerMenu()">
        <v-icon>mdi-book-open-outline</v-icon>
      </v-btn>

      <!-- Menú flotante con opciones - ENLACES RÁPIDOS -->
      <!-- <v-menu
          transition="slide-y-transition"
          v-model="menuGeneral"
          :nudge-width="250"
          offset-y
          bottom
          right
      >
        <template v-slot:activator="{ on }">
          <v-btn icon class="icon-menu" v-on="on">
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </template>

<v-card tile flat width="380px">
  <v-card-title class="text-uppercase body-1 primary font-weight-medium white--text">
    ENLACES RÁPIDOS
  </v-card-title>

  <v-toolbar color="primary" dark style="box-shadow: none !important;">
    <v-toolbar-title class="overline" style="font-size: 12px !important; font-weight: 500; color: white !important;">
      ENLACES RÁPIDOS
    </v-toolbar-title>
  </v-toolbar>

  <v-card-text class="pa-2">
    <v-row no-gutters>
      <v-col cols="6" class="pa-1" v-for="(item, index) in enlacesRapidos" :key="index">
        <v-card>
          <v-list-item class="cursor-pointer" @click="linkTo(item.link)">
            <v-list-item-icon class="mr-3">
              <v-icon :color="item.color_icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-uppercase caption">
              {{ item.name.es_ES }}
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>

</v-card>

</v-menu> -->

      <v-btn icon class="icon-menu" @click="goBack()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title class="pl-2">
        {{ $nuxt.$store.state.menu.pageTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon v-if="$nuxt.isOffline">
        <v-icon class="icon-menu">mdi-lightning-bolt-circle</v-icon>
      </v-btn>

      <!-- <v-btn icon>
        <v-icon class="icon-menu" @click="linkTo('dashboard')">mdi-monitor-dashboard</v-icon>
      </v-btn> -->

      <!-- NOTIFICACIONES -->
      <v-badge :value="!!notificacionesPendientes.length" :content="notificacionesPendientes.length" offset-y="20"
        offset-x="20" color="blue">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="ui.showNotificaciones = true" v-bind="attrs" v-on="on">
              <v-icon class="icon-menu">mdi-bell-ring</v-icon>
            </v-btn>
          </template>
          <span>Notificaciones</span>
        </v-tooltip>
      </v-badge>

      <!-- ENVIAR EMAIL, when click show a menu with 2 options-->
      <v-menu v-if="isAuditoria" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-email-send</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="primary">
            <span class="text-uppercase body-1 white--text">Notificar por email</span>
          </v-card-title>

          <v-list>
            <v-list-item @click="sendOT(); ui.showEnviarEmail = false" :disabled="disabledOT">
              <v-list-item-icon>
                <v-icon color="primary" :disabled="disabledOT" >mdi-file-send</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-uppercase caption">
                Enviar Orden de trabajo
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="sendPA(); ui.showEnviarEmail = false" :disabled="disabledPA">
              <v-list-item-icon>
                <v-icon color="primary" :disabled="disabledPA">mdi-clipboard-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-uppercase caption">
                Enviar Plan de Auditoria
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>



      <!-- RESUMEN -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="openResumen()" v-bind="attrs" v-on="on">
            <v-icon class="icon-menu">mdi-ballot-outline</v-icon>
          </v-btn>
        </template>
        <span>Resumen</span>
      </v-tooltip>

      <!-- DOCUMENTOS -->
      <template v-if="!$nuxt.isOffline">
        <v-badge :value="!!documentos.length" :content="documentos.length" offset-y="20" offset-x="20" color="blue">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="showGestorDocumentalIcon" icon @click="openGestorDocumental()" v-bind="attrs" v-on="on">
                <v-icon class="icon-menu">mdi-folder-open</v-icon>
              </v-btn>
              <v-btn v-else icon style="cursor: not-allowed !important" v-bind="attrs" v-on="on">
                <v-icon class="icon-menu" style="color: rgba(255, 255, 255, 0.26) !important">mdi-folder-open</v-icon>
              </v-btn>
            </template>
            <span>Gestor Documental</span>
          </v-tooltip>
        </v-badge>
      </template>

      <!-- FORMATOS -->
      <template v-if="!$nuxt.isOffline">
        <v-badge v-if="!$nuxt.isOffline && $refs.formatos" :value="!!$refs.formatos.formatosCount"
          :content="$refs.formatos.formatosCount" offset-y="20" offset-x="20" color="blue">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :class="{ 'cursor-not-allowed': !$refs.formatos.hayFormatos }"
                :disabled="!$refs.formatos.hayFormatos" @click="ui.showFormatos = !ui.showFormatos" v-bind="attrs"
                v-on="on">
                <v-icon class="icon-menu" :style="{
                  color: !$refs.formatos.hayFormatos
                    ? 'rgba(255, 255, 255, 0.26) !important'
                    : '',
                }">mdi-file-document-edit</v-icon>
              </v-btn>
            </template>
            <span>Formatos</span>
          </v-tooltip>
        </v-badge>
      </template>

      <!-- LISTAS DE VALIDACIÓN -->
      <template v-if="!$nuxt.isOffline">
        <v-badge :value="listaValidacion" content="!" color="error" offset-y="20" offset-x="20">
          <v-btn icon @click="openListaValidacion()" v-if="verListaValidacion">
            <v-icon class="icon-menu">mdi-format-list-checkbox</v-icon>
          </v-btn>
          <v-btn v-else icon style="cursor: not-allowed !important">
            <v-icon class="icon-menu"
              style="color: rgba(255, 255, 255, 0.26) !important">mdi-format-list-checkbox</v-icon>
          </v-btn>
        </v-badge>
      </template>
    </v-app-bar>

    <!-- MENÚ LATERAL -->
    <v-navigation-drawer v-model="$store.state.menu.openMenu" app dark color="primary" width="275px"
      style="height: 100vh">
      <v-row no-gutters justify="center">
        <v-col cols="10">
          <v-img :src="require('../static/logo_horizontal.png')" contain></v-img>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="pt-5 pb-3 my-0">
        <v-col cols="12" style="
            display: flex;
            align-content: center;
            align-items: center;
            background: rgb(255 255 255 / 10%);
          ">
          <!-- <v-avatar class="mx-4" size="36">
            <v-img :src="$auth.user.foto ? $auth.user.foto : require('~/static/Profile_avatar_placeholder_large.png')" alt="Avatar" cover></v-img>
          </v-avatar> -->

          <v-icon class="ml-4 mr-3"> mdi-account-box </v-icon>
          <div class="text-uppercase body-1 white--text" style="
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 60%;
              overflow: hidden;
              font-size: 13px !important;
            ">
            {{ $auth.user.username }}
          </div>
          <!-- Menú flotante con opciones generales del menú -->
          <v-menu v-if="!$nuxt.isOffline" transition="slide-y-transition" v-model="menuOpcionesGenerales"
            :nudge-width="200" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn absolute right icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-card tile flat max-width="245px">
              <v-card-title class="primary">
                <span class="text-uppercase body-1 white--text">{{ $auth.user.username }}</span>
              </v-card-title>

              <v-list-item @click="linkTo('dashboard')">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-monitor-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-uppercase caption">
                  Dashboard
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item @click="linkTo('settings')" v-if="isEmpresa">
                <v-list-item-icon>
                  <v-icon color="secondary2">mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-uppercase caption">
                  Preferencias
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item @click="dialogConfirmExit = true">
                <v-list-item-icon>
                  <v-icon color="error">mdi-location-exit</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="text-uppercase caption"> Log Out </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-list id="menu" class="transparent" style="padding-top: 0px; height: 82.5vh; overflow: auto">
        <v-list-item-group color="accent">
          <div v-for="(item, index) in menu" :key="index">
            <!-- templated used to filter allowed while offline -->
            <template v-if="showWhileOffline(item)">
              <!-- LINKS -->
              <v-list-item v-if="item.submenus.length == 0">
                <v-list-item-icon v-if="item.icon" class="mr-3">
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="linkTo(item.link)">
                  <v-list-item-title>
                    {{ item.nombre ? ui.text._(item.nombre) : item.name.es_ES }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- MENÚS -->
              <v-list-group v-else color="accent">
                <template v-slot:activator>
                  <v-list-item-icon v-if="item.icon" class="mr-3">
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title @click="linkTo(item.link)">
                    {{ item.nombre }}
                  </v-list-item-title>
                </template>

                <template v-for="(submenu, indexSubmenu) in item.submenus">
                  <v-list-item link style="padding-left: 55px" v-if="showWhileOffline(submenu)" :key="indexSubmenu"
                    @click="linkTo(submenu.link)">
                    <v-list-item-title v-text="submenu.nombre"></v-list-item-title>
                    <v-list-item-icon v-if="submenu.icon">
                      <v-icon v-text="submenu.icon"></v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Confirmación Logout -->
    <Confirmation v-model="dialogConfirmExit" text="¿Está seguro que desea salir de la aplicación?" colorButton="error"
      textButton="Salir" @confirm="logout()" />

    <!-- GESTOR DOCUMENTAL -->
    <GestorDocumental v-model="gestorDocumental" />

    <!-- Gestor Documental v2 -->
    <Share />
    <Uploader />
    <Downloader />

    <!-- Formatos -->
    <Formatos ref="formatos" v-model="ui.showFormatos" @close="ui.showFormatos = false" />

    <!-- LISTAS DE VALIDACIÓN -->
    <ListasValidacion v-model="ui.showListaValidacion" />

    <!-- NOTIFICACIONES -->
    <Notificaciones v-model="ui.showNotificaciones" @close="ui.showNotificaciones = false" />

    <!-- RESUMEN AUDITORIA,CERTIFICADOS,DITCTAMEN ETC... -->
    <Resumen v-model="ui.showResumen" :route_object="this.$route" @close="ui.showResumen = false" />

    <!-- Offline Queue -->
    <OfflineQueue />
    <OfflineHelper />

    <!-- Excel bridge (import and export) -->
    <ExcelBridge />

    <Signatures />

    <Translator />
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/Menu.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Wed Jul 12 2023
 **/

import text from '~/models/labels'
// import menuJSON from '~/models/menu'

import GestorDocumental from '~/components/GestorDocumental'
import Share from '~/components/Share'
import Uploader from '~/components/documentsUploader'
import Downloader from '~/components/documents/downloader'
import Signatures from '~/components/signatures/Sign'
import ExcelBridge from '~/components/ExcelBridge'
import OfflineQueue from '~/components/offline/queue'
import OfflineHelper from '~/components/offline/helper'
import ListasValidacion from '~/components/ListasValidacion'
import Notificaciones from '~/components/Notificaciones'
import Confirmation from '~/components/Confirmation'
import Translator from '~/components/Translator'
// import ROLE from '~/models/role'
import { mapState } from 'vuex'
import Formatos from '~/components/Formatos'
import Resumen from '~/components/Resumen'

export default {
  name: 'Menu',
  components: {
    GestorDocumental,
    Share,
    Uploader,
    Downloader,
    Signatures,
    ExcelBridge,
    OfflineQueue,
    OfflineHelper,
    Formatos,
    Translator,
    ListasValidacion,
    Notificaciones,
    Confirmation,
    Resumen,
  },

  data: () => ({
    toolbar: null,
    dialogConfirmExit: false,

    db: {
      menu: [],
    },
    disabledOT: true,
    disabledPA: true,
    menuOpcionesGenerales: false,
    menuGeneral: false,
    gestorDocumental: false,
    ui: {
      text: text,
      showEnviarEmail: false,
      showFormatos: false,
      showListaValidacion: false,
      showNotificaciones: false,
      showResumen: false,
    },
  }),

  computed: {
    ...mapState({
      menuEmpresa: (state) => state.menu.menuEmpresa,
      openMenu: (state) => state.menu.openMenu,
      verGestorDocumental: (state) => state.formatos.list,
      listaValidacion: (state) => state.listas_validacion.lista,
      verListaValidacion: (state) => state.listas_validacion.verListaValidacion,
      formatos: (state) => state.formatos.list,
    }),

    cod_empresa() {
      return this.$auth.user.empresa.codigo
    },

    roles() {
      return this.$auth.user.roles
    },

    verFormatos() {
      return this.$store.getters['formatos/verFormatos']
    },

    verResumen() {
      return this.$store.getters['formatos/verFormatos']
    },

    documentos() {
      return this.showGestorDocumentalIcon ? this.$store.getters['gestor_documental/documentos'] : []
    },

    notificacionesPendientes() {
      return this.$store.getters['notificaciones/notificacionesPendientes']
    },

    isRoot() {
      return this.$auth.user.roles.includes('ROLE_ROOT')
    },

    isEmpresa() {
      return (
        this.$auth.user.roles.includes('ROLE_EMPRESA') ||
        this.$auth.user.roles.includes('ROLE_ADMIN')
      )
    },
    isAdminGroup() {
      return this.$auth.user.roles.includes('ROLE_ADMIN_GROUP')
    },

    showGestorDocumentalIcon() {
      if (this.$route.name.indexOf('uuid') !== -1) return true
    },

    //+-------------------------------------------------
    // menu()
    // Computed with menu elements
    // UPDATED: Now comes from api and users and roles are already filtered
    // -----
    // Created on Mon Mar 14 2022
    // Updated on Thu Jan 26 2023
    //+-------------------------------------------------
    menu() {
      return this.db.menu
      return this.db.menu.filter((element) => {
        return true
      })
    },

    isAuditoria() {
      return this.$route.name === 'auditorias-uuid'
    },
    
  },

  methods: {
    notification() {
      this.$store.commit('notification/show', { text: 'soy una notificacion' })
    },

    changeStatusOT(newValue) {
      console.log('changingOTStatus to: ', newValue)
      this.disabledOT = newValue
    },

    changeStatusPA(newValue) {
      this.disabledPA = newValue
      console.log('changingPAStatus to: ', newValue)

    },

    async logout() {
      await this.$auth.logout()
      this.$router.push('/login')
    },

    goBack() {
      console.log('23')
      this.$router.go(-1)
    },

    linkTo(link) {
      if (link) {
        // this.$store.commit('menu/update', link)
        this.$router.push('/' + link)
      }
    },

    // //+-------------------------------------------------
    // // filterSubmenu()
    // // Tries to filter the submenu based on the role
    // // -----
    // // Created on Tue Sep 27 2022
    // //+-------------------------------------------------
    // filterSubmenu(element) {
    //   const roles = this.$auth.user.roles

    //   if (element.role && !roles.includes(element.role)) return false
    //   if (element.notFor && element.notFor.includes(this.cod_empresa)) return false
    //   if (element.onlyFor && !element.onlyFor.includes(this.cod_empresa)) return false

    //   return true
    // },

    //+-------------------------------------------------
    // showWhileOffline()
    // Returns true if the app is offline and the item has offline support
    // Returns true if the application is online
    // -----
    // Created on Wed Mar 02 2022
    //+-------------------------------------------------
    showWhileOffline(item) {
      if (!this.$nuxt.isOffline) return true

      if (item.modulo == 'auditorias' && item.submodulo !== 'checklists') return true
      if (item.offline) return true
      return false
    },

    // showSubmenuEmpresa(menu, submenu) {
    //   if (this.$auth.user.roles.includes('ROLE_ROOT')) return true
    //   const permisos = this.$auth.user.permisos
    //   if (permisos) {
    //     const roles = this.$auth.user.roles
    //     const canList = permisos.some((e) => {
    //       return (
    //         // e.can_-list &&
    //         e.can_config?.canList &&
    //         e.modulo === menu.modulo &&
    //         e.submodulo === submenu.submodulo &&
    //         roles.includes(e.role.name)
    //       )
    //     })
    //     return canList
    //   }
    //   return false
    // },

    openCloseMenu() {
      this.$store.commit('menu/drawerMenu', this.openMenu)
    },

    drawerMenu() {
      this.$store.commit('menu/drawerMenu', !this.openMenu)
    },

    controlToolbarSize() {
      if (!this.openMenu) {
        this.toolbar.classList.add('toolbar-no-menu')
        this.toolbar.classList.remove('toolbar-width-menu')
      } else {
        this.toolbar.classList.remove('toolbar-no-menu')
        this.toolbar.classList.add('toolbar-width-menu')
      }
    },

    openGestorDocumental() {
      if (this.$route.name.indexOf('uuid') !== -1) this.gestorDocumental = true
    },

    openResumen() {
      console.log('openResumen', this.$route.name)
      if (this.$route.name.indexOf('uuid') !== -1) this.ui.showResumen = true
    },

    openListaValidacion() {
      if (this.$route.name.indexOf('uuid') !== -1) this.ui.showListaValidacion = true
    },

    async getMenu() {
      let jxr = await this.$store.dispatch('layout/menu')
      if (jxr) this.db.menu = jxr
    },

    async init() {
      this.getMenu()
    },
    sendOT() {
      this.$store.dispatch('auditorias/sendOT', this.$route.params.uuid)
    },
  
    sendPA() {
      this.$store.dispatch('auditorias/sendPA', this.$route.params.uuid)
    },
  },

  mounted() {
    this.toolbar = document.getElementById('toolbar')
    this.init()
  },

  created() {
    this.$nuxt.$on('changeStatusOT', ($event)=> this.changeStatusOT($event))
    this.$nuxt.$on('changeStatusPA', ($event)=> this.changeStatusPA($event))
  },

}
</script>

<style scoped>
.toolbar-offline {
  background: repeating-linear-gradient(-45deg,
      rgba(96, 108, 188, 0.25),
      rgba(96, 109, 188, 0.25) 10px,
      rgba(70, 82, 152, 0.25) 10px,
      rgba(70, 82, 152, 0.25) 20px);
}

.v-navigation-drawer {
  z-index: 7 !important;
}

.shadow-1 {
  border: 0px !important;
  -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08) !important;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08) !important;
}

.toolbar-div {
  height: 64px;
}

.toolbar-width-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 275px;
  position: fixed;
}

.toolbar-no-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 0;
  position: fixed;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
#menu {
  -ms-overflow-style: none;
}
</style>
