import ROLE from './role'

export default [
  {
    name: {
      es_ES: 'Mis datos',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/perfil',
    icon: 'mdi-account-edit',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es_ES: 'Técnicos',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/tecnicos',
    icon: 'mdi-account-hard-hat',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es_ES: 'Instalaciones',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/instalaciones',
    icon: 'mdi-home-city-outline',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es_ES: 'Productores',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/cooperativas',
    icon: 'mdi-package-variant-closed',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es_ES: 'Etiquetas',
      code: 'menu.etiquetas',
    },
    block: 'gestion',
    modulo: 'etiquetas',
    type: 'link',
    link: 'portal/etiquetas',
    icon: 'mdi-texture-box',
    role: ROLE.ROLE_AUDITOR,
    onlyFor: ['quesomanchego'],
  },

  {
    name: {
      es_ES: 'Marcas',
    },
    block: 'gestion',
    modulo: 'marcas',
    type: 'link',
    link: 'portal/marcas',
    icon: 'mdi-cards',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
    onlyFor: ['quesomanchego'],
  },

  {
    name: {
      es_ES: 'Documentos',
      en_US: 'Documents',
    },
    block: 'gestion',
    modulo: 'clientes',
    type: 'link',
    link: 'portal/documentos',
    icon: 'mdi-cloud-outline',
    role: ROLE.ROLE_AUDITOR,
  },

  {
    name: {
      es_ES: 'Autoevaluaciones',
      en_US: 'Requests',
    },
    block: 'administracion',
    modulo: 'solicitudes',
    type: 'menu',
    link: null,
    icon: 'mdi-book-check-outline',
    role: ROLE.ROLE_CLIENT,
    onlyFor: ['quesomanchego'],
    items: [
      {
        name: {
          es_ES: 'Crear nueva',
        },
        submodulo: 'nueva-solicitud',
        link: 'portal/autoevaluaciones/create',
        icon: 'mdi-plus-thick',
        role: ROLE.ROLE_CLIENT,
      },
      {
        name: {
          es_ES: 'Ver evaluaciones',
        },
        submodulo: 'solicitudes',
        link: 'portal/autoevaluaciones',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_CLIENT,
      },
    ],
  },

  {
    name: {
      es_ES: 'Solicitudes',
      en_US: 'Requests',
    },
    block: 'administracion',
    modulo: 'solicitudes',
    type: 'menu',
    link: null,
    icon: 'mdi-text-box-check-outline',
    role: ROLE.ROLE_CLIENT,
    items: [
      {
        name: {
          es_ES: 'Crear nueva',
        },
        submodulo: 'nueva-solicitud',
        link: 'portal/solicitudes/create',
        icon: 'mdi-plus-thick',
        role: ROLE.ROLE_CLIENT,
      },
      {
        name: {
          es_ES: 'Ver solicitudes',
        },
        submodulo: 'solicitudes',
        link: 'portal/solicitudes',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_CLIENT,
      },
    ],
  },

  {
    name: {
      es_ES: 'Auditorías',
    },
    block: 'administracion',
    modulo: 'auditorias',
    type: 'link',
    link: 'portal/auditorias',
    icon: 'mdi-ballot-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
  },

  {
    name: {
      es_ES: 'Certificados',
    },
    block: 'administracion',
    modulo: 'certificados',
    type: 'link',
    link: 'portal/certificados',
    icon: 'mdi-file-certificate-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
    notFor: ['coplaca'],
  },

  {
    name: {
      es_ES: 'Informes',
    },
    block: 'administracion',
    modulo: 'informes',
    type: 'link',
    link: 'portal/informes',
    icon: 'mdi-card-bulleted-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
    onlyFor: ['coplaca'],
  },

  {
    name: {
      es_ES: 'Noticias',
      en_US: 'News',
    },
    block: 'gestion',
    modulo: 'noticias',
    type: 'link',
    link: 'newsboard',
    icon: 'mdi-newspaper',
    role: ROLE.ROLE_AUDITOR,
  },
]
