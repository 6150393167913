<template>
  <v-autocomplete ref="select" label="Tipo de Instalacion" v-model="selected" :items="items" item-text="nombre"
    item-value="uuid" :rules="rules" :required="required" :disabled="disabled" :outlined="outlined"
    :hide-details="hideDetails" :dense="dense" :filled="filled" :error="error" :error-messages="errorMessages"
    no-data-text="Listado vacío" @change="emitChange()" multiple></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectTipoInstalacion',
  props: {
    value: { type: Array },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessages: { type: String, default: '' },
    hideDetails: { type: String, default: 'auto' },
    autoSelectFirst: { type: Boolean, default: false },
    autoSelectField: { type: String, default: '' },
    autoSelectValue: { type: String, default: '' },
  },
  data: (ctx) => ({
    selected: ctx.value,
    items: [],
  }),
  computed: {
    rules() {
      return this.required ? [(v) => !!v || 'Obligatorio'] : []
    },
  },
  watch: {
    value(val) {
      if (!val && this.$refs.select) this.$refs.select.resetValidation()
      this.selected = val
    },
  },
  methods: {
    emitChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },

    //+-------------------------------------------------
    // autoSelectItems()
    // Selecciona los items de la lista que coincidan con el valor de autoSelectField
    // -----
    // Created on Fri Jan 21 2022
    //+-------------------------------------------------
    autoSelectItems() {
      if (this.value?.length > 0) return

      if (this.autoSelectFirst) {
        this.selected = [res.data[0].uuid]
        this.emitChange()
        return
      }

      if (this.autoSelectField && this.autoSelectValue) {
        let selected = this.items.filter(
          (item) => item[this.autoSelectField] === this.autoSelectValue
        )
        this.selected = [selected[0].uuid]
        this.emitChange()
        return
      }
    },

    async init() {
      const res = false//await this.$axios.get('tipos-instalacion')
      if (res) this.items = res.data
      else {
        this.items = [{ "codigo": "alquilada", "nombre": "Alquilada", "uuid": "49601280-1fb2-11ec-9574-4b4ac9f27f4c", "created_at": "2020-06-01T00:00:00+00:00", "updated_at": "2023-07-28T07:47:50+00:00" }, { "codigo": "subcontratada", "nombre": "Subcontratada", "uuid": "4a71f7b0-1fb2-11ec-a2f2-732fff764c90", "created_at": "2020-06-01T00:00:00+00:00", "updated_at": "2023-04-12T12:23:38+00:00" }, { "codigo": "maquila", "nombre": "Maquila", "uuid": "4ba1ec30-1fb2-11ec-9d6b-895a8fc5612d", "created_at": "2020-06-01T00:00:00+00:00", "updated_at": "2023-08-03T08:09:40+00:00" }, { "codigo": "propia", "nombre": "Propia", "uuid": "569b5990-2c32-11ec-9916-7592fc51ea8a", "created_at": "2020-06-01T00:00:00+00:00", "updated_at": "2023-10-04T06:04:58+00:00" }]
      }

      //if (res?.data.length > 0) this.autoSelectItems()
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped></style>
