import {uuid} from 'vue-uuid'

export const state = () => ({});

//commit mutations
export const mutations = {};


//dispatch actions
export const actions = {

  async list({rootState}) {
    rootState.loading = true
    const res = await this.$axios.get(`tramites`, {
      empresa: this.$auth.user.empresa.uuid
    })
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`tramites/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async new({rootState}, tramite) {
    rootState.loading = true
    let new_uuid = uuid.v4()
    await this.$axios.post(`tramites`, {
      uuid: new_uuid,
      nombre: tramite.nombre,
      grupo_alcance: tramite.grupo.uuid,
      empresa: tramite.empresa.uuid
    })
    rootState.loading = false
    return new_uuid
  },

  async update({rootState}, tramite) {
    rootState.loading = true
    await this.$axios.patch(`tramites/${tramite.uuid}`, {
      tramite: tramite.uuid,
      nombre: tramite.nombre
    })
    rootState.loading = false
  },

  async delete({rootState}, tramite) {
    rootState.loading = true
    await this.$axios.delete(`tramites/${tramite.uuid}`)
    rootState.loading = false
  },

};
