/**
 * @project: certiapp-nuxt
 * @file:    store/context.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 24th August 2021
 * Last Modified: Wed Nov 03 2021
 **/

import {uuid} from 'vue-uuid'

//+-------------------------------------------------
// State
// Array of data held by the store
// Updated via mutations and accessible with getters
//+-------------------------------------------------
export const state = () => ({
  db: [],
  cliente: {},
  contacto: {},

  modulo: '',
  modulo_ref: '',

  submodulo: '',
  submodulo_ref: '',

  cache: {},
})

//+-------------------------------------------------
// Mutations
// The only way to actually change state in a Vuex
// store is by committing a mutation.
//+-------------------------------------------------
export const mutations = {
  set(state, payload) {
    for (const key in payload) {
      state[key] = payload[key]
    }

    state.db = [1, 2, 3]
    state.cache = Date.now()
  },

  reset(state) {
    state.db = [2, 3, 4]
    state.cache = Date.now()
  },
}

//+-------------------------------------------------
// Getters
// Actions that to get modified state
// Getters receive the state as their 1st argument
// also receive other getters as the 2nd argument
// -----
// Accesed with $vm0.$store.getters['store/getter']
// https://vuex.vuejs.org/guide/getters.html#property-style-access
//+-------------------------------------------------
export const getters = {
  doneTodos: (state, getters) => {
    return state.todos.filter((todo) => todo.done)
  },

  getTodoById: (state) => (id) => {
    return state.todos.find((todo) => todo.id === id)
  },

  getState: (state, getters) => {
    return state
  },
}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns all families
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async list({rootState}, params) {
    rootState.loading = true

    const res = await this.$axios.get(`documentos`)

    rootState.loading = false
    if (res) return res.data
  },
}
