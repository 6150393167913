<template>
  <div>
    <!-- MENÚ LATERAL DE Resumen -->
    <v-navigation-drawer v-model="drawer" app right temporary width="570px" class="elevation-6 fondo-app"
      style="background-color: #EFF2F7 !important;">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title class="flex">
          Resumen
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-progress-linear v-if="ui.loading" color="deep-purple accent-4" indeterminate height="5"></v-progress-linear>

      <v-list-item-group multiple active-class="" :disabled="ui.loading" v-if="info">
        <v-list-item two-line :disabled="ui.loading" style="overflow: visible" v-show="info.cliente">
          <v-list-item-content @click.stop="goToRoute(info.cliente)">
            <v-list-item-title class="caption" style="white-space: normal !important;">
              Cliente
            </v-list-item-title>
            <v-list-item>
              Nombre: {{ info.cliente ? getNombre(info.cliente) : '' }}
            </v-list-item>
            <v-list-item>
              Dirección: {{ info.cliente ? getDireecion(info.cliente) : '' }}

            </v-list-item>
            <v-list-item>
              CIF: {{ info.cliente ? info.cliente.iniciales : '' }}
            </v-list-item>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line :disabled="ui.loading" style="overflow: visible" v-show="info.expediente">
          <v-list-item-content @click.stop="goToRoute(info.expediente)">
            <v-list-item-title class="caption" style="white-space: normal !important;">
              Expediente
            </v-list-item-title>
            <v-list-item>
              Código: {{ info.expediente ? info.expediente.codigo : '' }}
            </v-list-item>
            <v-list-item>
              Fecha de alta: {{ info.expediente ? getFecha(info.expediente.createdAt) : '' }}
            </v-list-item>
            <v-list-item>
              Subalcance: {{ info.expediente ? info.expediente.subalcance.nombre : '' }}
            </v-list-item>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line :disabled="ui.loading" style="overflow: visible" v-show="info.auditoria">
          <v-list-item-content @click.stop="goToRoute(info.auditoria)">
            <v-list-item-title class="caption" style="white-space: normal !important;">
              Auditoría
            </v-list-item-title>
            <v-list-item>
              Fecha: <!-- {{ info.auditoria ? info.auditoria.desde + ' - ' + info.auditoria.hasta : '' }} -->
            </v-list-item>
            <v-list-item>
              Auditores: {{ info.auditoria ? info.auditoria.auditores.map((a) => a.nombreCompleto).join(', ') : '' }}
            </v-list-item>
            <v-list-item>
              Responsable: {{ info.auditoria ? info.auditoria.responsable.nombreCompleto : '' }}
            </v-list-item>
            <v-list-item>
              Estado: {{ info.auditoria ? info.auditoria.estado : '' }}
            </v-list-item>
            <v-list-item>
              Tipo de auditoría: {{ info.auditoria ? info.auditoria.tipoVisita.map((a) => a).join(', ') : '' }}
            </v-list-item>
            <v-list-item>
              Nº de acta: {{ info.auditoria ? info.auditoria.codigo : '' }}
            </v-list-item>
            <v-list-item>
              Nº de no conformidades:
            </v-list-item>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line :disabled="ui.loading" style="overflow: visible" v-show="info.revision">
          <v-list-item-content @click.stop="goToRoute()">
            <v-list-item-title class="caption" style="white-space: normal !important;">
              Revisión
            </v-list-item-title>
            <v-list-item>
              Revisor:
            </v-list-item>
            <v-list-item>
              Fecha de revisión:
            </v-list-item>
            <v-list-item>
              Fecha de proposición:
            </v-list-item>
            <v-list-item>
              Proposición:
            </v-list-item>
            <v-list-item>
              Estado:
            </v-list-item>
            <v-list-item>
              Observaciones:
            </v-list-item>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line :disabled="ui.loading" style="overflow: visible" v-show="info.dictamen">
          <v-list-item-content @click.stop="goToRoute()">
            <v-list-item-title class="caption" style="white-space: normal !important;">
              Dictámen
            </v-list-item-title>
            <v-list-item>
              Firma:
            </v-list-item>
            <v-list-item>
              Fecha de decisión:
            </v-list-item>
            <v-list-item>
              Desición:
            </v-list-item>
            <v-list-item>
              Acción:
            </v-list-item>
            <v-list-item>
              Observaciones:
            </v-list-item>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line :disabled="ui.loading" style="overflow: visible" v-show="info.certificado">
          <v-list-item-content @click.stop="goToRoute()">
            <v-list-item-title class="caption" style="white-space: normal !important;">
              Certificado
            </v-list-item-title>
            <v-list-item>
              Código:
            </v-list-item>
            <v-list-item>
              Válido desde:
            </v-list-item>
            <v-list-item>
              Válido hasta:
            </v-list-item>
            <v-list-item>
              Fecha de expedición:
            </v-list-item>
            <v-list-item>
              Fecha de certificación:
            </v-list-item>
            <v-list-item>
              Fecha de modificación:
            </v-list-item>
            <v-list-item>
              Revisición:
            </v-list-item>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>
    </v-navigation-drawer>

    <confirmation v-model="dialog" text="¿Está seguro que desea borrar la notificación?" colorButton="error"
      textButton="Borrar" @confirm="deleteItem()" />
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\Notificaciones.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Wed Jul 12 2023
 **/

import Confirmation from '~/components/Confirmation'

export default {
  name: 'Resumen',
  props: {
    value: Boolean,
    route_object: { type: Object, default: '' },
  },
  components: {
    Confirmation,
  },
  data: () => ({
    selected: [],
    dialog: false,
    drawer: false,
    info: {},
    // EVENTO: Estados.COMUNICACION_EVENTO,

    ui: {
      loading: false,
    },
  }),
  watch: {
    async value(val) {
      console.log('Resumen componente', val, this.route_object.name)
      if(val && this.route_object.name == 'auditorias-uuid') {
        console.log('Resumen componente2', val, this.$route.name)
        this.ui.loading = true
        const xhr = await this.$store.dispatch(`auditorias/get`, this.$route.params.uuid)
        console.log('Resumen componente3', xhr)
        this.info.auditoria = xhr
        this.info.auditoria.modulo = 'PACS'
        this.info.cliente = xhr.cliente
        this.info.cliente.modulo = 'clientes'
        this.info.expediente = xhr.expediente
        this.info.expediente.modulo = 'expedientes'
        this.ui.loading = false
      }
      if (val && this.$route.name == 'expedientes-uuid') {
        this.ui.loading = true
        const xhr = await this.$store.dispatch(`expedientes/expediente`, this.$route.params.uuid)
        console.log('Resumen componente3', xhr)
        this.info.expediente = xhr
        this.info.cliente = xhr.cliente
        this.info.cliente.modulo = 'clientes'
        this.info.expediente.modulo = 'expedientes'
        this.ui.loading = false
      }
      if (val && this.$route.name == 'personal-uuid') {
        this.ui.loading = true
        const xhr = await this.$store.dispatch(`clientes/get`, this.$route.params.uuid)
        console.log('Resumen componente3', xhr)
        this.info.cliente = xhr
        this.info.cliente.modulo = 'clientes'
        this.ui.loading = false
      }
      this.drawer = val
    },
    drawer(val) {
      if (!val) this.$emit('close')
    },
  },
  computed: {
    async list() {
      if (this.$route.name == 'auditorias_uuid') {
        this.ui.loading = true
        const xhr = await this.$store.dispatch(`auditorias/get`, this.$route.params.uuid)
        this.info = xhr.data
        this.drawer = val
        this.ui.loading = false
      }
    },
  },
  methods: {
    getNombre(cliente) {
      let nombre = ''
      if (cliente.nombreCliente) {
        nombre = cliente.nombreCliente
      }
      if (cliente.nombreCompleto) {
        nombre = cliente.nombreCompleto
      }
      return nombre
    },
    getDireecion(cliente) {
      let direccion = ''
      if (cliente.direccion) {
        direccion = cliente.direccion.direccion + ' (' + cliente.direccion.localidad + '- ' + cliente.direccion.provincia+ '- ' + cliente.direccion.codigoPostal + ')'
      }
      if(cliente.direccionCompleta) {
        direccion = cliente.direccionCompleta.direccion + ' (' + cliente.direccionCompleta.localidad + '- ' + cliente.direccionCompleta.provincia + '- ' + cliente.direccionCompleta.codigoPostal + ')'
      }
      
      return direccion
    },

    getFecha(fecha) {
      return this.$moment(fecha).format('DD/MM/YYYY')
    },

    async goToRoute(item) {
      //console.log('goToRoute', item)
      let path = ''
      const modulo = item.modulo
      if (modulo == 'PACS') {
        path = 'auditorias'
      } else {
        const routes = this.$router.options.routes
        for (let i = 0; i < routes.length; i++) {
          const route = routes[i]
          if (route.name.includes(modulo)) {
            path = route.path
            break
          }
        }
      }

      if (item.is_new) {
        // this.deleteItem(item)
        await this.$store.dispatch(`notificaciones/check`, item)
        await this.$store.dispatch(`notificaciones/list`)
      }

      let route = ''
      switch (modulo) {
        case 'clientes':
          route = `/personal/${item.uuid}?type=cliente`
          break
        case 'expedientes':
          route = `/expedientes/${item.uuid}`
          break
        case 'PACS':
          route = `/auditorias/${item.uuid}`
          break
        
      }
      console.log('goToRoute', route)
      this.$router.push(route)
    },

    loadNotificaciones() {
      this.$store.dispatch(`notificaciones/list`)
    },

    async init() {
      console.log('Resumen componente',this.$route)
      /* if (this.$route.name == 'auditorias-uuid') {
        this.ui.loading = true
        const xhr = await this.$store.dispatch(`auditorias/get`, this.$route.params.uuid)
        this.info = xhr
        this.ui.loading = false
      }
      if (this.$route.name == 'expedientes-uuid') {
        this.ui.loading = true
        const xhr = await this.$store.dispatch(`expedientes/expediente`, this.$route.params.uuid)
        this.info = xhr
        this.ui.loading = false
      } */
      //this.loadNotificaciones()

      //this.$nuxt.$on('notificaciones:reload', (payload) => {
      //  this.loadNotificaciones()
      //})
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('notificaciones:reload')
  },
}
</script>


<style scoped>
.v-list-item {
  border-bottom: 1px solid #eff2f7 !important;
}
</style>
