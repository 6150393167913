<template>
  <div class="resp-container"
    v-if="coordinates || location">
    <iframe
      id="gmap_canvas"
      :src="URL_MAP"
      class="resp-iframe"
    ></iframe>
  </div>
</template>

<style>
.resp-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 9px;
  border: 2px solid grey ;
}
.resp-iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>

<script>
import axios from 'axios'

const client = axios.create()

export default {
  name: 'Mapa',
  props: {
    data: { type: Object, default: () => {} },
    lat: { type: Number, default: 40.4165 },
    lng: { type: Number, default: -3.70256 },
  },
  data: () => ({
    key: 'AIzaSyCNafSox9fnS7rrv11qmM_pIKHabqUsFOw',
    coordinates: null,
  }),
  watch: {
    data() {
      this.getData()
    },
  },
  computed: {
    zoom() {
      return this.coordinates ? '' : '&z=5'
    },

    URL_MAP() {
      const coordinates = this.coordinates ? this.coordinates : `${this.lat},${this.lng}`
      return `https://maps.google.com/maps?q=${coordinates}&t=&z=15&ie=UTF8&iwloc=&output=embed${this.zoom}`
    },

    address() {
      let address = ''
      if (this.data) {
        if (this.data.direccionCompleta.direccion) address += this.data.direccionCompleta.direccion
        if (this.data.direccionCompleta.cp !== undefined) address += '+' + this.data.direccionCompleta.codigoPostal
        if (this.data.direccionCompleta.pais !== undefined) address += '+' + this.data.direccionCompleta.pais
        if (this.data.direccionCompleta.provincia !== undefined) address += '+' + this.data.direccionCompleta.provincia
        if (this.data.direccionCompleta.municipio !== undefined) address += '+' + this.data.direccionCompleta.municipio
      }
      return address.replace(/[ .]/g, '+')
    },

    location() {
      let location = ''
      if (!this.data) return location

      if (this.data.direccionCompleta?.direccion) location += this.data.direccionCompleta.direccion

      if (this.data.direccionCompleta?.provincia) location = this.data.direccionCompleta.provincia
      if (this.data.direccionCompleta?.municipio && this.data.direccionCompleta.municipio !== location)
        location = location + ', ' + this.data.direccionCompleta.municipio
      if (this.data.direccionCompleta?.comunidad) location = location + '. ' + this.data.direccionCompleta.comunidad

      if (!location) return ''
      return location.toLowerCase()
    },
  },
  methods: {
    async getData() {
      if (this.location) {
        const res = await client.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.address}&key=${this.key}`
        )
        if (res) {
          const results = res.data.results
          if (results.length > 0) {
            const location = results[0].geometry.location
            this.coordinates = `${location.lat},${location.lng}`
          }
        }
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>
