<template>
  <v-dialog v-model="ui.create" max-width="650" scrollable>
    <v-card :loading="ui.loading">
      <v-form ref="form" v-model="ui.isValid">
        <v-card-title class="grey lighten-4 py-3 mb-4">
          Gestión de localidad
          <v-spacer></v-spacer>
          <v-btn icon text @click="ui.create = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pr-10">
          <v-row class="pb-1 pt-2 mb-5 align-baseline">
            <v-col cols="2" class="form-label text-right px-0 pr-7">Detalles</v-col>
            <v-col cols="10" md="10" sm="12" class="row ma-0 pa-0">

              <v-col cols="12">
                <v-text-field outlined dense hide-details="auto" label="Nombre *" v-model="item.nombre"
                  :rules="[v => !!v || 'Debes rellenar el campo']"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field outlined dense hide-details="auto" label="Código *" v-model="item.codigo"
                  :rules="[v => !!v || 'Debes rellenar el campo']"></v-text-field>
              </v-col>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="pb-1 pt-2 mt-3 align-baseline" style="">
            <v-col cols="2" class="form-label text-right px-0 pr-7">Lugar</v-col>
            <v-col cols="10" md="10" sm="12" class="row ma-0 pa-0">

              <v-col cols="6" v-if="item.pais">
                <SelectPais outlined dense hide-details="auto" v-model="item.pais.uuid" @change="$forceUpdate();"
                  :rules="[v => !!v || 'Debes rellenar el campo']" />
              </v-col>

              <v-col cols="6" v-if="item.pais">
                <SelectComunidad outlined dense hide-details="auto" v-model="item.comunidad.uuid"
                  :pais_uuid="item.pais.uuid" @change="$forceUpdate()" :rules="[v => !!v || 'Debes rellenar el campo']" />
              </v-col>

              <v-col cols="6" v-if="item.pais">
                <SelectProvincia ref="provincia" outlined dense hide-details="auto" v-model="item.provincia.uuid"
                  :pais_uuid="item.pais.uuid" @change="$forceUpdate()" :rules="[v => !!v || 'Debes rellenar el campo']" />
              </v-col>

              <v-col cols="6" v-if="item.provincia">
                <SelectMunicipio ref="municipio" outlined dense v-model="item.municipio.uuid"
                  :provincia_uuid="item.provincia.uuid" @change="$forceUpdate()"
                  :rules="[v => !!v || 'Debes rellenar el campo']" />
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!ui.loading" class="pa-4">
          <v-btn color="error darken-1" text @click="ui.create = false" :disabled="ui.loading">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="submit" text outlined :disabled="ui.loading"> Guardar</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    /pages/metadata/crudDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 11th May 2021
 * Last Modified: Mon Dec 12 2022
 **/

import notifications from '~/util/notificationHelper'

import SelectPais from '~/components/forms/SelectPais'
import SelectComunidad from '~/components/forms/SelectComunidad'
import SelectProvincia from '~/components/forms/SelectProvincia'
import SelectMunicipio from '~/components/forms/SelectMunicipio'

import { uuid } from 'vue-uuid'

export default {
  props: ['_ui'],
  components: {
    SelectPais,
    SelectComunidad,
    SelectProvincia,
    SelectMunicipio,
  },
  data: () => ({
    item: {
      action: 'create',
      pais: { uuid: '' },
      comunidad: { uuid: '' },
      provincia: { uuid: '', nombre: '' },
      municipio: { uuid: '', nombre: '' },

      placeholder: 'value',
    },

    db: {},

    ui: {
      create: false,
      loading: false,
      isValid: false,
    },
  }),

  watch: {
    'ui.create': function (value) {
      if (value == false) this.reset()
    },
  },

  methods: {
    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    newItem() {
      this.item.action = 'create'
      this.ui.create = true
    },

    editItem(group, index) {
      this.item = { ...group }
      this.item.provincia = { ...group.municipio?.provincia }
      this.item.action = 'update'
      this.item.index = index
      this.ui.create = true
    },

    fillValues() {
      let $municipio = this.$refs.municipio.$el
      let $provincia = this.$refs.provincia.$el

      let value_mun = $municipio.querySelector('input')?.value
      let value_prov = $provincia.querySelector('input')?.value

      this.item.provincia.nombre = value_prov
      this.item.municipio.nombre = value_mun
    },

    async submit() {
      let step = false

      // 1 . Form validations
      if (this.validate()) step = 'valid'

      // fill input values
      if (this.item.action === 'create') this.fillValues()

      // 2. Do api requests
      if (step == 'valid') this.store()
      else
        notifications.show({
          text: 'Debes revisar los campos antes de continuar',
          color: 'warning',
        })
    },

    validate() {
      // this.$v.$touch()
      this.$refs.form.validate()

      if (this.item.provincia.uuid == '') return false

      // if (this.$v.$invalid) {
      if (!this.ui.isValid) {
        console.warn(this.$v, this.$refs.form)
        return false
      }

      return true
    },

    reset() {
      // this.$v.$reset()

      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async store() {
      let index = this.item.index
      let action = this.item.action
      if (action == 'create') this.item.uuid = uuid.v4()

      let payload = { ...this.item }
      payload.municipio = '/api/municipios/' + payload.municipio.uuid

      this.ui.loading = true
      try {
        await this.$store.dispatch('localidades/' + action, payload)

        this.$emit('stored', {
          index,
          action,
          item: { ...this.item },
        })

        this.ui.create = false
      } catch (e) {
        console.info(e)
        notifications.show({
          text: 'Ha ocurrido un error guardando los cambios',
          color: 'error',
        })
      } finally {
        this.ui.loading = false
      }
    },

    //+-------------------------------------------------
    // Async API requests
    //+-------------------------------------------------
  },

  mounted() {
    // this.ui = { ...this._ui }
  },
}
</script>

<style></style>
