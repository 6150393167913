/**
 * @project: certiapp-nuxt
 * @file:    \store\marcas.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 3rd November 2022
 * Last Modified: Fri Apr 28 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // -----
  // Created on Thu Nov 03 2022
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    params = params || {}
    const res = await this.$axios.get(`marcas`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // get()
  // Created on Mon Nov 07 2022
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`marcas/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // -----
  // Created on Fri Nov 04 2022
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    await this.$axios.post(`marcas`, params)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Nov 04 2022
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    await this.$axios.patch(`marcas/${params.uuid}`, payload)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Apr 28 2023
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`marcas/${item.uuid}`)
    rootState.loading = false
  },
}
