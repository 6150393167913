<template>
<!--    :item-value="actividades.length ? 'nombre' : 'uuid'"-->
  <v-autocomplete
    ref="actividades"
    :label="label"
    v-model="selected"
    :items="actividades.length ? actividades : items"
    item-text="nombre"
    :item-value="'nombre'"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :chips="chips"
    :small-chips="smallChips"
    :dense="dense"
    :multiple="multiple"
    :return-object="returnObject"
    @change="$emit('input', selected)"
    @blur="$emit('blurred', selected)"
  ></v-autocomplete>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    /components/forms/SelectActividades.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Tue May 09 2023
 **/

import MODELS from '~/models/certicalidad/solicitudes'

export default {
  name: 'SelectActividades',
  props: {
    value: [String, Array],
    label: { type: String, default: 'Actividades' },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    actividades: { type: Array, default: () => [] },
    avoid: { type: Array, default: () => [] },
    returnObject: { type: Boolean, default: false },
    alcance: { type: Object, default: () => ({}) },
  },
  created() {
    this.init()
  },
  data(ctx) {
    return {
      selected: ctx.value,
      // items: [],
      db: {
        tramites: [],
        actividadesETG: MODELS.ACTIVIDADES_ETG2,
      },
    }
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.actividades.reset()
      this.selected = val
    },
  },
  computed: {
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },

    items() {
      let res = [...this.db.tramites]
      if (!this.alcance?.codigo) return res

      // Avoid some elements not needed on a specific select
      // Added on Aug 17 2021
      //+-------------------------------------------------
      if (this.avoid.length > 0) {
        res = res.filter((el) => this.avoid.indexOf(el.codigo) == -1)
      }

      // Filter by alcance
      // Added on Mon May 08 2023
      //+-------------------------------------------------
      /* if (this.alcance && this.alcance.codigo) {
        res = res.filter((el) => el.grupo_alcance?.codigo == this.alcance?.grupo?.codigo)
        if (this.alcance.codigo == 'certicalidad-etg') {
          res = [...this.db.actividadesETG]
        }
      } */

      return res
    },
  },
  methods: {
    async init() {
      let res = await this.$store.dispatch('tramites/list')
      console.log('tramites',res)
      if (res) this.db.tramites = res
      // console.log("init -> this.items", this.items)
    },
  },
}
</script>
