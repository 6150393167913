import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
};
//dispatch actions
export const actions = {

  async checked({rootState}, body) {
    rootState.loading = true
    // await this.$axios.patch(`listas-comprobacion/${body.plantilla_lista_comprobacion}/puntos/${body.plantilla_lista_comprobacion_punto}/checked`)
    rootState.loading = false
  },

  async unchecked({rootState}, body) {
    rootState.loading = true
    // await this.$axios.patch(`listas-comprobacion/${body.plantilla_lista_comprobacion}/puntos/${body.plantilla_lista_comprobacion_punto}/unchecked`)
    rootState.loading = false
  },

}
