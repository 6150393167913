
import {uuid} from 'vue-uuid'

const validation_sample = {
  required: true,
  type: 'string|float|select|email',
  multiple: true,
  regex: 'ej. DNI, CIF, movil, email',
  codigo: 'CULTIVOS_SELECCIONADOS',
  nombre: 'Cultivos seleccionados'
}

//ej: llamada api para rellenar el select
// PUT [url]/api/dropdowns, data : []
// const data={
//   empresa: uuid,
//   grupo_alcance: uuid,
//   codigo: validation_sample.codigo
// }

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) { 
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({commit}, params) {
    const res = await this.$axios.put('dropdowns', params)
    if (res) {
      const data = res.data
      commit('SET_LIST', data)
      // console.info('[dropdowns]', params, data)
      return data
    }
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`dropdowns/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },  

  async create({rootState}, selector) {
    rootState.loading = true
    const body = {
      uuid: selector.uuid ? selector.uuid : uuid.v4(),
      codigo: selector.codigo,
      empresa: selector.empresa,
      grupo_alcance: selector.grupo_alcance,
      is_public: selector.is_public,
      value: selector.value,
      ref: ''
    }
    console.log('[Create dropdown]', body)
    await this.$axios.post('dropdowns', body)
    rootState.loading = false
  },

  async update({rootState}, selector) {
    rootState.loading = true
    const body = {
      dropdown: selector.dropdown,
      uuid: selector.uuid,
      codigo: selector.codigo,
      empresa: selector.empresa,
      grupo_alcance: selector.grupo_alcance,
      is_public: selector.is_public,
      value: selector.value
    }
    console.log('[Update dropdown]', body)
    await this.$axios.patch(`dropdowns/${selector.dropdown}`, body)
    rootState.loading = false
  },   

  async delete({rootState}, selector) {
    const dropdowns = selector.dropdowns
    rootState.loading = true
    dropdowns.forEach(async element => {
      await this.$axios.delete(`dropdowns/${element.uuid}`)
    })
    rootState.loading = false
  },     

  async clone({commit}, selector) {
    const res = await this.$axios.post(`dropdowns/${selector.uuid}/clonar`, { selector: selector.uuid} )
    if (res) return res.data
  },

};
