<template>
  <v-autocomplete
    ref="auditor"
    :label="title"
    v-model="selected"
    :items="items"
    :item-text="nombreCompleto"
    item-value="uuid"
    :rules="rules"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :outlined="outlined"
    :hide-details="hideDetails"
    :dense="dense"
    return-object
    @change="$emit('input', selected)"
  ></v-autocomplete>
</template>

<script>
import format from '~/util/formatHelper'

export default {
  name: 'SelectAuditoresObject',
  props: {
    value: Object,
    title: { type: String, default: 'Auditor' },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
  },
  mounted() {
    if (this.required) {
      this.rules = [(v) => !!v || 'Obligatorio']
    }
    this.getItems()
  },
  data(ctx) {
    return {
      rules: [],
      selected: ctx.value,
      items: [],
    }
  },
  methods: {
    nombreCompleto(item) {
      return format.fullName(item)
    },

    async getItems() {
      const res = await this.$store.dispatch('auditores/list')
      if (res) {
        this.items = res.filter((item) => item.user?.activo)
        this.items = res
      }
    },
  },
  watch: {
    value: function (val) {
      if (!val || val === undefined) this.$refs.auditor.reset()
      this.selected = val
    },
  },
}
</script>
