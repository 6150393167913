<template>
  <div>
    <v-col cols="12">
      <v-card :loading="loading">

        <v-card-title>
          Campos Global
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn small fab depressed color="error" class="white--text" v-on="on" @click="deleteVariables()">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="3">
                <v-text-field
                  label="GGN"
                  hint="Código numérico de 13 cifras"
                  maxlength="13"
                  counter="13"
                  :persistent-hint="true"
                  v-model="variables.ggn"
                  :error-messages="ggnErrors"
                  @input="$v.variables.ggn.$touch(), $emit('isValidGlobal', !$v.$invalid)"
                  @change="changeVariables()"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="GLN"
                  hint="Código numérico de 13 cifras"
                  maxlength="13"
                  counter="13"
                  :persistent-hint="true"
                  v-model="variables.gln"
                  :error-messages="glnErrors"
                  @input="$v.variables.gln.$touch(), $emit('isValidGlobal', !$v.$invalid)"
                  @change="changeVariables()"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="Okey"
                  hint="Código alfanumérico de 15 caracteres"
                  v-mask="mask15"
                  maxlength="15"
                  counter="15"
                  :persistent-hint="true"
                  v-model="variables.okey"
                  :error-messages="okeyErrors"
                  @input="$v.variables.okey.$touch(), $emit('isValidGlobal', !$v.$invalid)"
                  @change="changeVariables()"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="Código interno"
                  :persistent-hint="true"
                  v-model="variables.codigo_interno"
                  @change="changeVariables()"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

      </v-card>
    </v-col>
  </div>
</template>

<script>
  import {mask} from 'vue-the-mask'
  import {validationMixin} from 'vuelidate';
  import {integer, minLength} from 'vuelidate/lib/validators';

  export default {
    name: "VariablesAgrocolorGlobal",
    directives: {mask},
    mixins: [validationMixin],
    validations: {
      variables: {
        ggn: {integer, minLength: minLength(13)},
        gln: {integer, minLength: minLength(13)},
        okey: {minLength: minLength(15)}
      }
    },
    mounted() {
      this.init()
    },
    data: () => ({
      mask15: {
        mask: 'CCCCCCCCCCCCCCC',
        tokens: {
          '#': {pattern: /\d/},
          'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
          'C': {pattern: /[0-9a-zA-Z-]/, transform: v => v.toLocaleUpperCase()}
        }
      },
      loading: false,
      uuid: '',
      variables: {},
      newVariables: false,
      grupo_alcance: null
    }),
    computed: {
      isNew() {
        return this.$route.params.uuid === "_";
      },
      route_id() {
        return this.$route.params.uuid
      },
      ggnErrors() {
        const errors = []
        if (!this.$v.variables.ggn.$dirty) return errors
        !this.$v.variables.ggn.integer && errors.push('Código inválido')
        !this.$v.variables.ggn.minLength && errors.push('Código inválido')
        return errors
      },
      glnErrors() {
        const errors = []
        if (!this.$v.variables.gln.$dirty) return errors
        !this.$v.variables.gln.integer && errors.push('Código inválido')
        !this.$v.variables.gln.minLength && errors.push('Código inválido')
        return errors
      },
      okeyErrors() {
        const errors = []
        if (!this.$v.variables.okey.$dirty) return errors
        !this.$v.variables.okey.minLength && errors.push('Código inválido')
        return errors
      }
    },
    methods: {
      async init() {
        this.variables = {}
        
        // Init grupo alcance
        let grupo_alcances = await this.$store.dispatch('grupos_alcances/list');
        if (grupo_alcances) {
          grupo_alcances = grupo_alcances.filter(node => node.codigo === 'global');
          this.grupo_alcance = grupo_alcances[0].uuid;
        }

        let create = true
        if (!this.isNew) {
          const body = {
            modulo: 'clientes',
            external_uuid: this.route_id
          }
          const res = await this.$store.dispatch('custom_variables/list', body)
          if (res) {
            res.forEach(element => {
              if (element.grupo_alcance && element.grupo_alcance.codigo === 'global') {
                this.variables = element.variables
                this.uuid = element.uuid
                create = false
              }
            });
          }
        }
        if (create) {
          console.info('isnew global');
          this.uuid = JSON.parse(JSON.stringify(this.$uuid.v4()))
          this.newVariables = true
        }
      },
      changeVariables() {
        this.newVariables ? this.createVariables() : this.updateVariables()
      },
      updateVariables() {
        let body = {
          newVariables: false,
          custom_variables: this.uuid,
          grupo_alcance: this.grupo_alcance,
          variables: this.variables
        }
        console.log("updateVariables -> body", body)
        this.$store.commit('custom_variables/updateVariables', body)
      },
      createVariables() {
        let body = {
          newVariables: true,
          uuid: this.uuid,
          modulo: 'clientes',
          grupo_alcance: this.grupo_alcance,
          external_uuid: this.route_id,
          variables: this.variables
        }
        console.log("createVariables -> body", body)
        this.$store.commit('custom_variables/createVariables', body)
      },
      async deleteVariables() {
        this.newVariables
          ? await this.$store.commit('custom_variables/deleteVariables', this.uuid)
          : await this.$store.dispatch('custom_variables/delete', this.uuid)
        this.init()
      }
    }
  }
</script>

<style scoped>

</style>
