<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" :color="getColor(estado)">fiber_manual_record</v-icon>
    </template>
    <span>{{estado}}</span>
  </v-tooltip>
</template>

<script>
  import estados from "~/models/estados";
  export default {
    name: "IconStatus",
    props: ['estado'],
    methods: {
      getColor(estado) {
        const foundItems = estados.getColor(estado);
        return (foundItems.length > 0) ? foundItems[0].color : 'transparent'
      }
    }
  }
</script>

<style scoped>

</style>
