<template>
  <v-row>
    <v-col cols="4">
      <p class="pl-0 info-expediente">Emplazamientos</p>
      <v-subheader class="pl-0 mt-2 info-expediente">
        Selecciona los emplazamientos y sus productos se marcarán/desmarcarán
        automáticamente
      </v-subheader>
      <v-list class="mt-4">
        <v-list-item-group v-model="selected_emplazamientos" multiple>
          <v-list-item v-for="(emplazamiento, index) in emplazamientos" :key="'emplazamiento' + index"
            :value="emplazamiento" @click="selectEmplazamiento(emplazamiento)">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active && (listadoProductos(emplazamiento.tipo).length > 0)" @change="updateSelectedProductos(emplazamiento, active)"
                  :color="subalcance.color" :disabled="listadoProductos(emplazamiento.tipo).length == 0">
                </v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ emplazamiento.nombre }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ emplazamiento.tipo }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-fade-transition>
      <v-col cols="8" v-if="selectedEmplazamiento && listadoProductos(selectedEmplazamiento.tipo).length > 0">
        <v-row class="mt-3">
          <v-col cols="12">
            <v-subheader v-if="selectedEmplazamiento">{{ selectedEmplazamiento.tipo }}</v-subheader>
          </v-col>
          <v-data-table v-if="selectedEmplazamiento" :headers="getHeaders(selectedEmplazamiento.uuid)"
            :items="listadoProductos(selectedEmplazamiento.tipo)" :items-per-page="5" show-select
            @current-items="updateSelected" v-model="selected_productos" :checkbox-color="subalcance.color"
            item-key="uuid" class="elevation-1">
            <template v-for="header in getHeaders(selectedEmplazamiento.uuid)" v-slot:[`item.${header.value}`]="{ item }">
              {{
                typeof (item.params.find((param) => param.nombre === header.value).valor[0]) === 'object'
                  ? item.params.find((param) => param.nombre === header.value).valor[0].nombre
                  : item.params.find((param) => param.nombre === header.value).valor.join(', ')
              }}
            </template>
          </v-data-table>
        </v-row>
      </v-col>
      <v-col cols="8" v-else class="d-flex justify-center align-center">
        <v-alert max-width="60%" color="orange darken-2" dense text icon="mdi-alert-outline">Los productos de este
          emplazamiento ya están incluidos en la
          auditoría</v-alert>
      </v-col>
    </v-fade-transition>
  </v-row>
</template>

<script>
export default {
  props: {
    emplazamientos: {
      type: Array,
      required: true,
    },
    productos: {
      type: Array,
      required: true,
    },
    subalcance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected_emplazamientos: [],
      selected_productos: [],
      selectedEmplazamiento: null,
    }
  },
  computed: {
    listadoEmplazamientos() {
      let acc = []
      this.emplazamientos.forEach((emplazamiento) => {
        if (!acc.find((item) => item.tipo === emplazamiento.tipo)) {
          acc.push({ tipo: emplazamiento.tipo, uuid: emplazamiento.uuid })
        }
      })
      return acc
    },
  },
  methods: {
    getHeaders(uuid) {
      const product = this.productos.find((producto) => producto.emplazamiento.uuid === uuid)
      return Object.values(product.params).map((item) => {
        return {
          text: item.nombre,
          value: item.nombre,
          sortable: false,
        }
      })
    },

    listadoProductos(tipo) {
      if (!tipo) return []
      return this.productos.filter((producto) => producto.emplazamiento.tipo === tipo)
    },
    updateSelectedProductos(emplazamiento, active) {
      if (this.listadoProductos(emplazamiento.tipo).length == 0) {
        this.selected_emplazamientos = this.selected_emplazamientos.filter(
          (emplazamiento) => this.listadoProductos(emplazamiento.tipo).length > 0
        )
      }
      else if (active) {
        this.selected_productos = this.selected_productos.filter(
          (producto) => producto.emplazamiento.uuid !== emplazamiento.uuid
        )
      } else {
        this.selected_productos = [
          ...this.selected_productos,
          ...this.productos.filter(
            (producto) => producto.emplazamiento.uuid === emplazamiento.uuid
          ),
        ]
      }
    },
    updateSelected() {
      this.$emit('updateSelectedProductos', this.selected_productos)
      this.$emit('updateSelectedEmplazamientos', this.selected_emplazamientos)
      this.updateSelectedEmplazamientos()
    },


    updateSelectedEmplazamientos() {
      this.emplazamientos.forEach((emplazamiento) => {
        const productos = this.productos.filter(producto => producto.emplazamiento.uuid === emplazamiento.uuid)
        if (productos.length == 0) {
          return
        }
        const allSelected = productos.every(producto => this.selected_productos.includes(producto))
        if (allSelected && !this.selected_emplazamientos.includes(emplazamiento) && this.listadoProductos(emplazamiento.tipo).length > 0) {
          this.selected_emplazamientos.push(emplazamiento)
        } else if (!allSelected && this.selected_emplazamientos.includes(emplazamiento)) {
          const index = this.selected_emplazamientos.indexOf(emplazamiento)
          this.selected_emplazamientos.splice(index, 1)
        }
      })
    },
    selectEmplazamiento(emplazamiento) {
      this.selectedEmplazamiento = emplazamiento
    }
  },
  created() {
    // Por defecto, selecciona el primer emplazamiento si existe.
    if (this.emplazamientos.length > 0) {
      //this.selectedEmplazamiento = this.emplazamientos[0]
    }
  },
}
</script>