import {uuid} from 'vue-uuid'

export const state = () => ({});

//commit mutations
export const mutations = {};

//dispatch actions
export const actions = {

  async list({rootState}, payload = {}) {
    rootState.loading = true
    const res = await this.$axios.put(`tipos-fichero`, payload)
    rootState.loading = false
    return res.data
  },

  // async list({rootState}, all = 0) {
  //   rootState.loading = true
  //   const res = await this.$axios.get(`tipos-fichero`, {params: {showAll: all}});
  //   rootState.loading = false
  //   return res.data
  // },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`tipos-fichero/${uuid}`)
    rootState.loading = false
    if (res) {
      const data = res.data
      data.empresa = data.grupo_alcance.empresa
      return data
  }
  },

  async create({rootState}, tipo_fichero) {
    rootState.loading = true
    const body = {
      uuid: uuid.v4(),
      titulo: tipo_fichero.titulo,
      codigo: tipo_fichero.codigo,
      empresa: tipo_fichero.empresa.uuid,
      grupo_alcance: tipo_fichero.grupo_alcance.uuid,
      validacion: tipo_fichero.validacion
    }
    console.log('[new tipo_fichero]', body)
    await this.$axios.post('tipos-fichero', body)
    rootState.loading = false
    return tipo_fichero
  },

  async update({commit}, tipo_fichero) {
    const body = {
      tipo_fichero: tipo_fichero.uuid,
      is_valido: false,
      grupo_alcance: tipo_fichero.grupo_alcance.uuid,
      empresa: tipo_fichero.empresa.uuid,
      codigo: tipo_fichero.codigo,
      titulo: tipo_fichero.titulo,
      validacion: tipo_fichero.validacion
    }
    await this.$axios.patch(`tipos-fichero/${tipo_fichero.uuid}`, body)
  },

  async delete({commit}, tipo_fichero) {
    await this.$axios.delete(`tipos-fichero/${tipo_fichero.uuid}`)
  }

};
