<template>

  <v-dialog v-model="dialog" persistent>
    <v-card>

      <v-toolbar dark color="primary">
        <v-btn text icon class="mr-3" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn v-if="component === 'save'" color="error" @click="$emit('discard', true), $emit('close')">
            Descartar
          </v-btn>
          <v-btn :color="colorButton" :disabled="!valid" @click="save()">
            {{ textButton }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>

        <v-row align="center" justify="space-around" v-if="action !== 'save'">

          <v-col cols="6">
            <span class="title font-weight-bold text-capitalize">{{ tipo }}</span>
          </v-col>

          <v-col cols="6" class="text-right">
            <v-row align="center" justify="end">
              <v-checkbox
                color="primary"
                label="Realizar acciones en una nueva Iteracion"
                v-model="newIteration"
                :disabled="eliminar"
                class="mr-5"
              />
            </v-row>
          </v-col>

        </v-row>

        <!-- Componente -->
        <component
          :is="component"
          :action="action"
          :grupo_alcance_uuid="grupo_alcance_uuid"
          @newValue=" newValue = $event"
          @valid=" valid = $event"
        />

        <!-- Ag Grid -->
        <div style="height: 100%" v-if="action !== 'save'">
          <div class="ag-container">
            <ag-grid-vue
              style="width: 100%; height: 57.5vh; border: 1px solid #eee"
              :animateRows="true"
              class="ag-theme-material ag-grid"
              rowGroupPanelShow="always"
              :gridOptions="gridOptions"
              :pagination="true"
              :paginationAutoPageSize="true"
              :columnDefs="grid.columnDefs"
              :rowData="grid.rowData"
              @grid-ready="onGridReady"
            ></ag-grid-vue>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
  import Cambio from './Cambio'
  import Save from './SaveChanges'

  export default {
    name: "ActionsModal",
    components: {
      Cambio,
      Save
    },
    props: {
      value: Boolean,
      item: { type: Object, default: () => {} },
                grupo_alcance_uuid: { type: String, default: '' },
    },
    data: () => ({
      dialog: false,
      valid: false,
      newIteration: 0,
      newValue: null,
      grid: { api: null, columnDefs: [], rowData: [] },
      gridOptions: { rowHeight: 40, suppressPropertyNamesCheck: true },
      commonActions: ['baja', 'eliminar', 'save']
    }),
    watch: {
      value(val) {
        this.generarTabla()
        this.dialog = val
      },
      item (val) {
        this.valid = this.commonActions.some(element => val.action.includes(element))
      },
      eliminar(val) {
        val ? this.newIteration = 0 : this.newIteration = 1
      }
    },
    computed: {
      action() {
        return this.item.action
      },
      selection() {
        return this.item.selection
      },
      tipo() {
        return this.item.tipo
      },
      title() {
        return this.action
          ? `¿Está seguro de que desea realizar la acción ${this.item.name}?`
          : 'Guardar modificaciones'
      },
      component() {
        if (this.action === 'baja' || this.action === 'eliminar') return ''
        else if (this.action.includes('cambio')) return 'cambio'
        else return this.action
      },
      eliminar() {
        return this.action === 'eliminar'
      },
      textButton() {
        return this.eliminar ? 'Eliminar' :'Guardar'
      },
      colorButton() {
        return this.eliminar ? 'error' : 'secondary2'
      },
    },
    methods: {
      onGridReady(params) {
        this.grid.api = params.api
      },
      capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ''
      },
      generarTabla() {
        const data = this.selection
        let columnDefs = []
        columnDefs.unshift({
          headerName: "#", sortable: true, filter: true,
          resizable: true, editable: false,
          valueGetter: params => this.item.row ? this.item.row[params.node.data.uuid] : ''
        })
        if (data && data.length > 0) {
          const headers = Object.keys(data[0])
          for (let i=0; i < headers.length; i++) {
            const columna = headers[i]
            if (columna !== 'uuid') {
              columnDefs.push({
                headerName: columna,
                field: columna,
                enableRowGroup: true,
                editable: false,
                resizable: true,
                filter: true,
                valueSetter: function (params) {
                  if (params.value) return params.value.toString()
                }
              })
            }
          }
        }
        this.grid.columnDefs = columnDefs
        this.grid.rowData = data
        if (this.grid.api) this.grid.api.refreshCells()
      },
      save () {
        const body = {
          newIteration: this.newIteration,
          newValue: this.newValue
        }
        this.action === 'save'
          ? this.$emit('save', true)
          : this.$emit('changes', body)
      }
    }
  }
</script>
