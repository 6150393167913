<template>
  <div>
    <!-- Header -->
    <table style="width: 100%">
      <tbody>
        <tr>
          <td style="text-align: center">
            <img src="https://www.kaam.es/images/logo.png" height="60px" width="60px">
            <h2 style="font-weight: 700" >kaam</h2>
          </td>
          <td style="text-align: center">
            INFORME DE AUDITORÍA
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 1 -->
    <h2 style="text-align: center; font-weight: 700">1.- Datos Auditoria</h2>
    <p>
      <br />
      <br />
    </p>
    <p>
      <span style="font-weight: 700">Tipo de Auditoria:</span>
      <span v-if="datos.tipo_visita">{{ datos.tipo_visita.nombre }}</span>
    </p>
    <p>
      <span style="font-weight: 700">Norma base de la auditoría: </span>
      R.D. 4/2014 de 10 de enero por el que se aprueba la norma de calidad para la carne, el jamón, la paleta y la caña de lomo ibérico. Protocolo de Certificación_08/05/2017.
    </p>
    <p>
      <span style="font-weight: 700">Fecha Auditoria:</span>
      <span>{{ formatDate(datos.desde) + ' - ' + formatDate(datos.hasta) }}</span>
    </p>
    <p>
      <span style="font-weight: 700">Suministrador/Cliente:</span>
      <span>{{ clienteNombreCompleto }}</span>
    </p>
    <p>
      <span style="font-weight: 700">NIF:</span> <span>{{ datos.cliente && datos.cliente.nif }}</span>
    </p>
    <p>
      <span style="font-weight: 700">N° RGSEAA:</span> <span>{{ datos.instalacion && datos.instalacion.rgseaa }}</span>
    </p>
    <p>
      <span style="font-weight: 700">Domicilio Social:</span> <span>{{ datos.cliente && datos.cliente.direccion }}</span>
    </p>
    <p>
      <span style="font-weight: 700">Domicilio Industrial:</span> <span>{{ datos.instalacion && datos.instalacion.direccion }}</span>
    </p>
    <p>
      <span style="font-weight: 700">Instalaciones Auditadas:</span> <span>{{ datos.instalacion && datos.instalacion.razon_social }}</span>
    </p>
    <p>
      <span style="font-weight: 700">Auditor Jefe:</span>
      <span v-if="datos.responsable">{{ datos.responsable.nombre + ' ' + datos.responsable.apellidos }}</span>
    </p>

    <!-- Pagina 2 -->
    <div class="page-break-before"></div>
    <!-- Alcance Proceso -->
    <h2 style="text-align: center; font-weight: 700">2.- Alcance de Proceso (Actividades)</h2>
    <p>
      <br />
      <br />
    </p>
    <div v-if="!isOperadorResposable">
      <div>
        <span style="font-weight: 700">Actividades Realizadas: </span>
        <span>{{ datos.solicitud && datos.solicitud.params.alcance_proceso.realizadas.join(', ') }}</span>
      </div>
      <div>
        <span style="font-weight: 700">Actividades Subcontratadas: </span>
        <span>{{ datos.solicitud && datos.solicitud.params.alcance_proceso.subcontratadas.join(', ') }}</span>
      </div>
      <div>
        <span style="font-weight: 700">Certificación: </span>
        <span>{{ datos.solicitud && datos.solicitud.params.alcance_proceso.certificacion.join(', ') }}</span>
      </div>
    </div>
    <!-- Alcance Producto -->
    <table style="width: 100%" v-else-if="datos.solicitud && datos.solicitud.params.alcance_producto.length">
      <thead>
        <tr>
          <td colspan="10" style="text-align: center; font-weight: 700">Alcance de Producto</td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">RGSEAA</td>
          <td style="font-weight: 700">Razón Social</td>
          <td style="font-weight: 700">Producto</td>
          <td style="font-weight: 700">Alimentación</td>
          <td style="font-weight: 700">Racial</td>
          <td style="font-weight: 700">Estado</td>
          <td style="font-weight: 700">Presentación</td>
          <td style="font-weight: 700">Actividad Realizada</td>
          <td style="font-weight: 700">Actividad Subcontratada</td>
          <td style="font-weight: 700">Certificación</td>
        </tr>
      </thead>
      <tbody>
        <tr style="text-align: center" v-for="item in datos.solicitud.params.alcance_producto" :key="item.id">
          <td>{{ item.rgseaa }}</td>
          <td>{{ item.razon_social }}</td>
          <td>{{ tipoProducto[item.producto] }}</td>
          <td>{{ tipoAlimentacion[item.alimentacion] }}</td>
          <td>{{ tipoRacial[item.racial] }}</td>
          <td>{{ tipoEstado[item.estado] }}</td>
          <td>{{ tipoPresentacion[item.presentacion] }}</td>
          <td>{{ item.actividades_realizadas }}</td>
          <td>{{ item.actividades_subcontratadas }}</td>
          <td>{{ tipoCertificacion[item.certificacion] }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 3 -->
    <div class="page-break-before"></div>
    <!-- Volumen Produccion -->
    <h2 style="text-align: center; font-weight: 700">
      3.- Volumen de Producción Anual (Último año completo) - {{ datos.solicitud && datos.solicitud.params.volumen_produccion.fecha }}
    </h2>
    <p>
      <br />
      <br />
    </p>
    <table style="width: 100%" v-if="datos.solicitud && datos.solicitud.params.volumen_produccion">
      <thead>
        <tr>
          <td />
          <td style="text-align: center; font-weight: 700">Producción Propia</td>
          <td style="text-align: center; font-weight: 700">Producción Maquila</td>
          <td style="text-align: center; font-weight: 700">Total</td>
        </tr>
      </thead>
      <tbody>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Animales Sacrificados</td>
          <td>{{ datos.solicitud.params.volumen_produccion.propia.animales_sacrificados }}</td>
          <td>{{ datos.solicitud.params.volumen_produccion.maquila.animales_sacrificados }}</td>
          <td>
            {{ Number(datos.solicitud.params.volumen_produccion.propia.animales_sacrificados) + Number(datos.solicitud.params.volumen_produccion.maquila.animales_sacrificados) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Canales Despiezadas</td>
          <td>{{ datos.solicitud.params.volumen_produccion.propia.canales_despiezadas }}</td>
          <td>{{ datos.solicitud.params.volumen_produccion.maquila.canales_despiezadas }}</td>
          <td>
            {{ Number(datos.solicitud.params.volumen_produccion.propia.canales_despiezadas) + Number(datos.solicitud.params.volumen_produccion.maquila.canales_despiezadas) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Piezas Elaboradas</td>
          <td>{{ datos.solicitud.params.volumen_produccion.propia.piezas_elaboradas }}</td>
          <td>{{ datos.solicitud.params.volumen_produccion.maquila.piezas_elaboradas }}</td>
          <td>
            {{ Number(datos.solicitud.params.volumen_produccion.propia.piezas_elaboradas) + Number(datos.solicitud.params.volumen_produccion.maquila.piezas_elaboradas) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">N° Piezas Transformadas</td>
          <td>{{ datos.solicitud.params.volumen_produccion.propia.piezas_transformadas }}</td>
          <td>{{ datos.solicitud.params.volumen_produccion.maquila.piezas_transformadas }}</td>
          <td>
            {{ Number(datos.solicitud.params.volumen_produccion.propia.piezas_transformadas) + Number(datos.solicitud.params.volumen_produccion.maquila.piezas_transformadas) || '' }}
          </td>
        </tr>
        <tr style="text-align: center">
          <td style="font-weight: 700">Kg Carne Comercializada</td>
          <td>{{ datos.solicitud.params.volumen_produccion.propia.carne_comercializada }}</td>
          <td>{{ datos.solicitud.params.volumen_produccion.maquila.carne_comercializada }}</td>
          <td>
            {{ Number(datos.solicitud.params.volumen_produccion.propia.carne_comercializada) + Number(datos.solicitud.params.volumen_produccion.maquila.carne_comercializada) || '' }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 4 -->
    <div class="page-break-before"></div>
    <!-- Alcance Proceso Instalaciones -->
    <h2 style="text-align: center; font-weight: 700">4.- Alcance de Proceso (Instalaciones)</h2>
    <p>
      <br />
      <br />
    </p>
    <!-- Subcontratadas -->
    <table class="mb-8" style="width: 100%" v-if="datos.solicitud && datos.solicitud.params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="10" style="text-align: center; font-weight: 700">Subcontratadas</td>
        </tr>
        <tr style="text-align: center" v-if="datos.solicitud.params.actividades_proceso.subcontratadas.length">
          <td colspan="3">
            <tr>
              <td colspan="3" style="font-weight: 700">Proveedor del Servicio</td>
            </tr>
            <tr>
              <td style="font-weight: 700">Nombre</td>
              <td style="font-weight: 700">Dirección de la Instalación</td>
              <td style="font-weight: 700">N° RGSEAA</td>
            </tr>
          </td>
          <td style="font-weight: 700">Actividad</td>
          <td style="font-weight: 700">Volumen de animales/canales/piezas/kg de carne</td>
          <td style="font-weight: 700">Actividad Subcontratada Certificada</td>
          <td style="font-weight: 700">Entidad de Certificación</td>
          <td style="font-weight: 700">Certificado de Conformidad y Anexo Técnico</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="datos.solicitud.params.actividades_proceso.subcontratadas.length">
          <tr style="text-align: center" v-for="item in datos.solicitud.params.actividades_proceso.subcontratadas" :key="item.id">
            <td>{{ item.nombre }}</td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.rgseaa }}</td>
            <td>{{ item.actividad }}</td>
            <td>{{ item.volumen }}</td>
            <td>{{ (item.certificada ? 'SI' : 'NO') }}</td>
            <td>{{ item.entidad }}</td>
            <td>{{ item.conformidad_anexo }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="8" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table>
    <!-- Alquiladas -->
    <table style="width: 100%" v-if="datos.solicitud && datos.solicitud.params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="10" style="text-align: center; font-weight: 700">Alquiladas</td>
        </tr>
        <tr style="text-align: center" v-if="datos.solicitud.params.actividades_proceso.alquiladas.length">
          <td style="font-weight: 700">N° RGSEAA</td>
          <td style="font-weight: 700">Dirección de la Instalación</td>
          <td style="font-weight: 700">Actividad</td>
          <td style="font-weight: 700">Volumen de animales/canales/piezas/kg de carne</td>
          <td style="font-weight: 700">Solicita Certificación de Kaam</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="datos.solicitud.params.actividades_proceso.alquiladas.length">
          <tr style="text-align: center" v-for="item in datos.solicitud.params.actividades_proceso.alquiladas" :key="item.id">
            <td>{{ item.rgseaa }}</td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.actividad }}</td>
            <td>{{ item.volumen }}</td>
            <td>{{ (item.solicitda_certificacion ? 'SI' : 'NO') }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 5 -->
    <div class="page-break-before"></div>
    <h2 style="text-align: center; font-weight: 700">5.- Anotaciones</h2>
    <p>
      <br />
      <br />
    </p>
    <p>{{ datos.anotaciones }}</p>
    <!-- Pagina 6 -->
    <div class="page-break-before"></div>
    <h2 style="text-align: center; font-weight: 700">6.- Requisitos de certificación/ resultado de las comprobaciones realizadas</h2>
    <p>
      <br />
      <br />
    </p>
    <p>
      A continuación se indican las comprobaciones realizadas en la auditoria así como el resultado de las evaluaciones.
      Se indicará “C “si cumple/ conforme,  “NC” si no cumple/no conforme, (indicando la referencia del incumplimiento evidenciado)
      se indicará “NA” en el apartado Comentarios si no aplica el requisito.
    </p>
    <p>
      En el Anexo I se detallan los incumplimientos detectados durante la evaluacion sobre los requisitos establecidos en los documentos
      normativos y en los procedimientos de Kaam y en los procedimientos de la empresa auditada, con una descripción de los hallazgos
      que fundamentan el incumplimiento.
    </p>
    <!-- Checklist Preguntas -->
    <div v-for="(checklist, idx) in datos.checklists" :class="{'page-break-before': idx > 0}" :key="checklist.id">
      <table style="width: 100%" class="mb-4">
        <thead>
          <tr>
            <td colspan="10" style="text-align: center; font-weight: 700">{{ checklist.titulo }} - {{ checklist.auditor.nombre_completo }}</td>
          </tr>
          <tr>
            <td colspan="10" style="text-align: center; font-weight: 700">{{ checklist.site.nombre }}</td>
          </tr>
          <tr style="text-align: center">
            <td style="font-weight: 700">Título</td>
            <td style="font-weight: 700">C</td>
            <td style="font-weight: 700">NC</td>
            <td style="font-weight: 700">Observaciones</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pregunta in checklist.preguntas" :key="pregunta.id">
            <td>{{ pregunta.titulo }}</td>
            <td class="pa-2 caption text-center">{{ pregunta.respuesta === 'V' ? 'X' : '' }}</td>
            <td class="pa-2 caption text-center">{{ pregunta.respuesta === 'F' ? 'X' : '' }}</td>
            <td class="pa-2 caption text-center" v-if="pregunta.respuesta === 'NA'">{{ 'NA' }}</td>
            <td v-else>
              <span v-html="pregunta.observaciones"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagina 7 -->
    <div class="page-break-before"></div>
    <h2 style="text-align: center; font-weight: 700">Conclusiones y continuación del proceso de certificación</h2>
    <p>
      <br />
      <br />
    </p>
    <p>
      El presente informe corresponde a una <span class="font-weight-bpñd">auditoria de xxxx </span> y recoge una descripción de las evaluaciones,
      comprobaciones y verificaciones realizadas y los incumplimientos detectados por el equipo auditor durante la evaluación.
    </p>
    <p>
      La realización de esta auditoría y los resultados encontrados son fruto de una investigación muestral,
      por lo que no se descarta la aparición de incumplimientos en posteriores auditorias.
    </p>
    <p>
      La auditoría se desarrolló según la programación establecida. El personal se mostró colaborador e interesado,
      lo que ha permitido llevar a cabo la auditoria de una forma eficaz.
    </p>
    <p>
      KAAM ha procedido a la evaluación de los aspectos referidos a la competencia técnica de la empresa para producir y
      comercializar productos ibéricos y a la evaluación del sistema del autocontrol implantado.
    </p>
    <p>
      Como continuación al proceso de certificación, la empresa deberá remitir a KAAM, SL en un plazo máximo de <span class="font-weight-bpñd">1 mes </span>
      desde la recepción del presente informe, la documentación que justifique que, para cada uno de los incumplimientos identificados,
      la empresa realiza los siguientes pasos:
    </p>
    <p>
      <ul>
        <li>
          Se ha establecido una <span style="font-weight: 700">acción correctiva y/o preventiva </span> para garantizar que se ha subsanado
          el incumplimiento o está en vía de solución y que no se repite el incumplimiento.
        </li>
        <li>
          Se ha definido un <span style="font-weight: 700">plazo previsto para la resolución de los incumplimientos. </span>
        </li>
        <li>
          La empresa deberá aportar evidencias que demuestren que ha realizado las acciones solicitadas anteriormente.
        </li>
      </ul>
    </p>
    <p>
      Esta información será estudiada por el equipo auditor para determinar si las acciones propuestas, la información solicitada y
      las evidencias remitidas aportan la suficiente confianza en que los incumplimientos han sido adecuadamente resueltos.
    </p>
    <p>
      La empresa auditada tiene la obligación de informar a Kaam de cualquier reclamación,
      o información externa recibida por parte de la autoridad competente, de ASICI, o de otro operador.
    </p>
    <p>
      La empresa puede alegar aquellos extremos del informe con los que no esté de acuerdo adjuntando toda la información que justifique su postura.
    </p>
    <p>
      <span style="font-weight: 700">(*) </span> Si del desarrollo de la auditoria se abren No conformidades muy graves, NCMG que cuya medida implica
      retirada de los precintos de la norma de calidad y en todos los casos, perdida del derecho a utilizar en el etiquetado las denominacione
      de venta del articulo 3 del RD 4/2014 para el producto afectado por la NC (Punto 6.1 del Protocolo de Certificacion-08/05/2017-E-1) o
      cuya medida supondrá la suspensión de la Certificacion. (Punto 6.2 del Protocolo de Certificacion-08/05/2017-E-1),
      el apartado conclusiones de auditoria a partir del (*) se incluirá los párrafos indicados a continuación.
    </p>
    <p>
      <span style="font-weight: 700">(*) </span> Como continuación al proceso de certificación y según lo establecido en los documentos normativos de aplicación,
      Protocolo de Certificación “Norma de Calidad Ibérico (RD/ 4/2014) Protocolos de Actuación” 08/05/2017_E-1 y RDE -13 de Junio 2018 y dado que las
      desviaciones detectadas en la visita realizada llevan aparejadas las medidas de:
    </p>
    <p>
      <ul>
        <li>
          La retirada de los precintos de la norma de calidad y la pérdida del derecho a utilizar en el etiquetado
          las denominaciones de venta para el producto afectado por la no conformidad.
        </li>
        <li>
          La suspensión de la Certificación.
        </li>
      </ul>
    </p>
    <p>
      -----------., deberá remitir a KAAM, SL para la desviación NCMG_, en un plazo máximo de <span style="font-weight: 700">2 días hábiles contados
        desde la fecha de envió del presente informe,un plan de acciones correctoras que garantice el adecuado tratamiento de las mismas. </span>
    </p>
    <p style="font-weight: 700">
      El Plan de Acciones Correctoras debe incluir como mínimo: análisis de las causas; identificación del producto afectado,
      resultado de la investigación realizada para verificar si hay más producto afectado por la NCXXXX que el producto identificado
      por Kaam en la auditoria, acciones reparadoras y correctivas tendentes a la identificación y retirada del producto afectado
      y en todos los casos, plazo previsto para la resolución de los incumplimientos.
    </p>
    <p>
      <span style="font-weight: 700">
        El producto no conforme debera ser retirado del mercado en un plazo de 7 días máximo desde la fecha de realización de la auditoria.
      </span>
    </p>
    <p style="font-weight: 700">
      Asimismo, les informamos que, tras la recepción de la citada información, la misma, junto con el presente informe,
      serán remitidos a la Comisión encargada de la toma de decisión a fin de que adopte las medidas que estime oportunas.
    </p>

    <!-- Pagina 8 -->
    <div class="page-break-before"></div>
    <h2 style="text-align: center; font-weight: 700">Anexo 1. No Conformidades</h2>
    <p>
      <br />
      <br />
    </p>
    <!-- No Conformidades -->
    <template v-if="datos.no_conformidades.length">
      <div v-for="(no_conformidad, idx) in datos.no_conformidades" :key="no_conformidad.id">

        <p>
          <span style="font-weight: 700">{{ idx + 1 }}. No Conformidad {{ no_conformidad.peso }} - {{ no_conformidad.codigo }}:</span>
          {{ no_conformidad.titulo }}
        </p>

        <p>
          <span style="font-weight: 700">No Conformidad:</span>
          <br>
          <span v-html="no_conformidad.no_conformidad"></span>
        </p>

        <p>
          <span style="font-weight: 700">Observaciones:</span>
          <br>
          <span v-html="no_conformidad.observaciones"></span>
        </p>

      </div>
    </template>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 0.5px solid black;
  }
  thead {
    display: table-row-group;
  }
  tr td {
    page-break-inside: avoid;
  }
</style>

<script>
import MODELS from "~/models/kaam/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    tipoOperador: MODELS.OPERADORES_TIPO,
    tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
    tipoProducto: MODELS.PRODUCTO_TIPO,
    tipoRacial: MODELS.RACIAL_TIPO,
    tipoEstado: MODELS.ESTADOS_TIPO,
    tipoPresentacion: MODELS.PRESENTACION_TIPO,
    tipoCertificacion: MODELS.CERTIFICACION_TIPO,
  }),
  computed: {
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    isOperadorResposable() {
      return this.datos.solicitud && this.datos.solicitud.params.alcance_proceso.tipo_operador.includes('responsable')
    },
    alcance () {
      return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
    },
    solicitud_data () {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach(element => {
          data[element.keyname] = element.value
        })
        return data
      }
      else return {}
    },
    site_data () {
      if (this.datos.isCliente) return this.datos.isCliente
      else if (this.datos.sites) {
        let data = {}
        let tipos = []

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
        }

        for (let i=0; i < tipos.length; i++) {
          const element = tipos[i]
          data[element] = []
        }

        for (let i=0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          let obj = {}
          obj.uuid = element.uuid
          for (let k=0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data[element.tipo].push(obj)
        }

        return data
      }
      else return {}
    },
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
  }
}
</script>

