<template>
  <div v-if="isNotGroup">

    <v-icon v-if="print(params)" color="primary" @click="dialogAddEvidencia = true">mdi-paperclip</v-icon>

    <!-- DIALOG PARA SUBIR EVIDENCIAS -->
    <v-dialog eager v-model="dialogAddEvidencia" max-width="40%" hide-overlay persistent>
      <v-card>
        <v-card-title>Adjuntar evidencia</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">

            <v-row class="pl-10 pr-10" v-if="evidencia">
              <v-col cols="12">
                <v-text-field label="Título" required :rules="rules" v-model="evidencia.titulo"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="evidencia">
                <v-file-input required :rules="rules" label="Documento" v-model="evidencia.file"></v-file-input>
              </v-col>                            
            </v-row>

          </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pr-3">
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            class="mr-3"
            text
            @click="closeDialogAddEvidencia()">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="adjuntarEvidencia()">
            <v-icon left>mdi-upload</v-icon>
            Adjuntar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
 
<script>
export default {
  components: {},
  data: () => ({
    valid: false,
    solo_titulo: false,
    dialogAddEvidencia: false,
    rules: [ v => !!v || 'El campo es obligatorio' ], 
    evidencia: {
      uuid: '',
      titulo: null,
      file: null
    },
  }),
  watch: {
    dialogAddEvidencia (val) {
      if (!val) this.closeDialogAddEvidencia()
    }
  },
  computed:{
    isNotGroup () {
      return !!this.params.data
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    auditoria_uuid () {
      return this.$route.params.uuid;
    },
    pregunta () {
      return this.params && this.params.data && this.params.data.pregunta
    }
  }, 
  methods: {
    print(params) {
      if (this.pregunta && this.pregunta.solo_titulo) return false;
      else if (this.pregunta) return true;
      else if (params && !params.node.group) return true;
      else {
        return false;
      }
    },
    closeDialogAddEvidencia(){
      this.dialogAddEvidencia = false
      this.evidencia = {
        uuid: '',
        titulo: null,
        tipo: {name: 'PDF', accept: 'application/pdf'},
        file: null
      }
      this.$refs.form.resetValidation()
    },  
    async adjuntarEvidencia(){
      if (this.$refs.form.validate()) {
        const body = {
          uuid: this.$uuid.v4(),
          modulo: 'auditorias',
          modulo_ref: this.auditoria_uuid,
          submodulo: 'checklist_pregunta',
          submodulo_ref: this.pregunta.uuid,
          documento: this.evidencia
        }

        await this.$store.commit("gestor_documental/ADD_CUSTOM", body)

        this.offlineMode
          ? await this.$store.dispatch("offlineMode/createCustomDocument", body)
          : await this.$store.dispatch("gestor_documental/createCustom", body) 

        await this.$store.commit("gestor_documental/ACTUALIZAR_EVIDENCIAS", true)  

        this.dialogAddEvidencia = false
        
        this.init()
      }
    },
    init ($event, params) {
      if (this.params.init instanceof Function) {
        const body = {
          ...params
        }
        this.params.init(body)
      }
    }
  },
};
</script>
