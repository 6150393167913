var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._v(" "),_c('p',{staticStyle:{"text-align":"right"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(_vm.formatDateLong(_vm.datos.created_at)))])]),_vm._v(" "),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.clienteNombreCompleto))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.datos.cliente.contacto.localidad.nombre)+",")]),_vm._v(" "),_c('strong',[_vm._v(" "+_vm._s(_vm.datos.cliente.direccion))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.clienteDireccion1))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.clienteDireccion2))])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n      1º.- Se ha recibido debidamente cumplimentada y firmada, la Solicitud de Certificación y Contrato de su  empresa,\n      "+_vm._s(_vm.clienteNombreCompleto)+", para la certificación del Real Decreto 4/2014 de 10 de enero por \n      el que se aprueba la Norma de Calidad para "+_vm._s(_vm.productos)+".")])]),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('p',[_c('strong',[_vm._v("NÚMERO DE EXPEDIENTE: "+_vm._s(_vm.datos.codigo))]),_vm._v(" "),_c('strong',[_vm._v(" ")])]),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":"https://araporcei.certiapp.com/araporcei/araporcei_logo.png","height":"60px","width":"60px"}}),_vm._v(" "),_c('h2',{staticStyle:{"font-weight":"700"}},[_vm._v("araporcei")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("ASUNTO: TRAMITACION DE SU SOLICITUD Y APERTURA DEL NÚMERO DE EXPEDIENTE DE  CERTIFICACION ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Estimada Sra.:  ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Conforme a nuestro procedimiento de certificación, le comunicamos que: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n      2º.- Que Araporcei procede a asignarle el siguiente Número de Expediente para iniciar el proceso de  certificación: \n    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Cuando la empresa obtenga el Certificado de Conformidad, conforme al RD 4/2014 Artículo 4.  Etiquetado. Punto 4 b) debe incluir la expresión «certificado por» seguida del nombre del organismo  independiente de control o su acrónimo (ARAPORCEI) en el material de etiquetado y trazabilidad de los  productos certificados. Esta expresión deberá situarse en el etiquetado próxima a la denominación de  venta y de forma visible y siguiendo las instrucciones que Araporcei tiene establecido en su  Procedimiento General de “Uso correcto de la marca” que se le entregará cuando obtenga el  Certificado. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Araporcei comunicara a la autoridad competente de su Comunidad Autónoma, conforme al  procedimiento y los medios por ellos establecidos, la apertura de su Expediente de Certificación, así  como el resto de datos que sean solicitados a Araporcei sobre su empresa, a lo largo de todo el proceso  de certificación. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Sin otro particular reciba un cordial saludo. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("María Silvestre ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Secretaria Técnica. Araporcei. ")])])
}]

export { render, staticRenderFns }