<template>
  <div v-if="isNotGroup">
    <span v-for="(item, i) in items" :key="'instalacion'+i">
      {{ item }}
    </span>
    <!-- <v-select
      v-model="items"
      :items="items"
      class="mt-2"
      hide-details
      dense
      multiple
      @click.stop="() => {}"
    >
      <template v-slot:item="{ item }">
        <v-list-item readonly>
          <v-list-item-content>
            {{ item }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    value: '',
  }),
  computed: {
    isNotGroup() {
      return !!this.params.data
    },
    items() {
      const value = this.params.value
      const items = []
      if (value !== undefined) {
        for (let i = 0; i < value.length; i++) {
          const element = value[i]
          const parent_uuid = element.parent_uuid
          const nombre = element.nombre
          if (!parent_uuid && !items.includes(nombre)) items.push(nombre)
        }
      }
      return items
    },
  },
}
</script>
