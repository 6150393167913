<template>
  <div>
    <table width="942" cellspacing="0" cellpadding="5">
      <thead>
        <tr>
          <td width="314" height="79">
            <p lang="es-ES-u-co-trad" align="center"><span style="color: #808080;"><span
                  style="font-family: Calibri, sans-serif;"><span style="font-size: small;"><strong>CUESTIONARIO DE
                      AUDITORIA GLOBALG.A.P.</strong></span></span></span></p>
            <p lang="es-ES-u-co-trad" align="center"><span style="color: #808080;"><span
                  style="font-family: Calibri, sans-serif;"><span style="font-size: small;"><strong>Registro de no
                      conformidades </strong></span></span></span></p>
            <p lang="es-ES-u-co-trad" align="center">&nbsp;</p>
          </td>
          <td width="283">
            <p lang="es-ES-u-co-trad" align="center">
            <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px">
            </p>
          </td>
          <td width="311">
            <p lang="es-ES-u-co-trad" align="right"><span style="font-family: Calibri, sans-serif;"><span
                  style="font-size: medium;">QA_GG_CoC</span></span></p>
            <p lang="es-ES-u-co-trad" align="right"><span style="font-family: Calibri, sans-serif;"><span
                  style="font-size: medium;">Revisi&oacute;n 1_160718</span></span></p>
            <p lang="es-ES-u-co-trad" align="right"><span style="font-family: Calibri, sans-serif;"><span
                  style="font-size: medium;">INFORME DE AUDITORIA {{datos.expediente.codigo}}</span></span></p>
          </td>
        </tr>
      </thead>
    </table>

    <table width="941" cellspacing="0" cellpadding="5">
      <tbody>
        <tr>
          <td style="background: #d8d8d8;" colspan="4" valign="top" bgcolor="#d8d8d8" width="929">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-size: small; font-family: Calibri, sans-serif;">
                <strong>1.- DATOS DE LA AUDITORIA</strong>
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="4" width="929" height="8">
            <p lang="es-ES-u-co-trad" align="left">
              <span style="font-family: Calibri, sans-serif; font-size: xx-small;">
                <strong>Fecha: </strong>
              </span>
              <span style="font-family: Calibri, sans-serif;">
                <span style="font-size: small;">{{ formatDate(new Date()) }}</span>
              </span>
            </p>
            <p lang="es-ES-u-co-trad" align="left">&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td colspan="4" valign="top" width="929" height="16">
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-size: xx-small; font-family: Calibri, sans-serif;">
                <strong>Empresa auditada. Nombre/Razon Social/Codigo REGA:</strong>
              </span>
            </p>
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-size: medium; font-family: Calibri, sans-serif;">
                <span style="font-size: small;">
                  {{ datos.cliente && (datos.cliente.nombre + ' ' + datos.cliente.primer_apellido + ' ' + datos.cliente.segundo_apellido) }},
                  {{ datos.cliente && datos.cliente.direccion }} {{ datos.cliente && datos.cliente.municipio.nombre }} {{ datos.cliente && datos.cliente.provincia.nombre }},
                  {{ datos.empresa && datos.empresa.cif }} {{ datos.instalacion && datos.instalacion.rgseaa }}
                </span>
              </span>
            </p>
            <p class="western" lang="es-ES-u-co-trad" align="left">&nbsp;</p>
          </td>
        </tr>
        <tr valign="top">
          <td width="287">
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-family: Calibri, sans-serif; font-size: xx-small;">
                <strong>Tipo de auditoria (marcar con una cruz)</strong>
              </span>
            </p>
            <p class="western" lang="es-ES-u-co-trad" align="left">&nbsp;</p>
          </td>
          <td width="165">
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-size: xx-small; color: #000000; font-family: Calibri, sans-serif;">
                <strong>X</strong>
                <span style="font-family: Calibri, sans-serif; font-size: xx-small;">
                  <strong> CERTIFICACI&Oacute;N </strong>
                </span>
              </span>
            </p>
            <p class="western" lang="es-ES-u-co-trad" align="left">&nbsp;</p>
          </td>
          <td width="278">
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="color: #000000;">□</span>
              <span style="font-size: xx-small; font-family: Calibri, sans-serif;">
                <strong>NO ANUNCIADA </strong>
              </span>
            </p>
          </td>
          <td width="169">
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="color: #000000;">□</span>
              <span style="font-size: medium;">
                <span style="color: #000000; font-family: Calibri, sans-serif; font-size: xx-small;">
                  <strong>Otros(indicar)</strong>
                </span>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="western" lang="es-ES-u-co-trad" align="left">&nbsp;</p>
    <table width="944" cellspacing="0" cellpadding="5">
      <tbody>
        <tr valign="top">
          <td style="background: #cccccc;" bgcolor="#cccccc" width="114">
            <p class="western" lang="es-ES-u-co-trad" align="left">&nbsp;</p>
          </td>
          <td style="background: #cccccc;" bgcolor="#cccccc" width="808">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-size: small; font-family: Calibri, sans-serif;">
                <strong>2.- LISTADO DE INCUMPLIMIENTOS</strong>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="western" lang="es-ES-u-co-trad" align="justify">
      <span style="font-family: Calibri, sans-serif;">
        <span style="font-size: xx-small;">Para cada sitio de produccion/instalacion auditada se cumplimentara, si
          procede, este &ldquo;
          <span style="color: #ff0000;">
            <strong>Registro de No Conformidades</strong>
          </span>&rdquo;; adem&aacute;s del correspondiente Informe de auditor&iacute;a (Check list correctamente
          cumplimentado con numero de informe de auditoria) que incluye el resultado de la evaluacion de los requisitos
          espec&iacute;ficos definidos en el documento normativo ASEGURAMIENTO INTEGRADO DE FINCAS| M&Oacute;DULO BASE
          PARA TODO TIPO DE FINCA | M&Oacute;DULO BASE PARA PRODUCCI&Oacute;N ANIMAL | GANADO VACUNO Y OVINO, VERSION
          5.2. /FABRICACION DE ALIMENTOS PARA ANIMALES CFM VERSION 2.2/CADENA DE CUSTODIA CoC VERSI&Oacute;N 5.0.
        </span>
      </span>
    </p>
    <table width="943" cellspacing="0" cellpadding="5">
      <tbody>
        <tr>
          <td style="background: #d9d9d9;" bgcolor="#d9d9d9" width="107">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-family: Calibri, sans-serif; font-size: xx-small;">
                <strong>N&ordm; DE ORDEN DE LA NC</strong>
              </span>
            </p>
          </td>
          <td style="background: #d9d9d9;" bgcolor="#d9d9d9" width="600">
            <h3 class="western" lang="es-ES-u-co-trad">
              <span style="font-family: Calibri, sans-serif;">
                <span style="font-size: xx-small;">PUNTO DEL DOCUMENTO NORMATIVO QUE SE INCUMPLE</span>
              </span>
            </h3>
          </td>
          <td style="background: #d9d9d9;" bgcolor="#d9d9d9" width="113">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-family: Calibri, sans-serif; font-size: xx-small;">
                <strong>CLASIFICACION DE LA NC</strong>
              </span>
            </p>
          </td>
          <td style="background: #d9d9d9;" bgcolor="#d9d9d9" width="493">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-family: Calibri, sans-serif; font-size: xx-small;">
                <strong>BREVE DESCRIPCCION DE LA NC</strong>
              </span>
            </p>
          </td>
        </tr>
        <tr v-for="(no_conformidad, idx) in datos.no_conformidades" :key="no_conformidad.id">
          <td width="107">
            <p class="western" lang="es-ES-u-co-trad" align="center">&nbsp;</p>
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-family: Calibri, sans-serif; font-size: small;"> {{ idx + 1 }} </span>
            </p>
            <p class="western" lang="es-ES-u-co-trad" align="center">&nbsp;</p>
          </td>
          <td width="600">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-family: Calibri, sans-serif; font-size: small;"> {{ no_conformidad.codigo }} </span>
            </p>
          </td>
          <td width="113">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-family: Calibri, sans-serif; font-size: small;"> {{ no_conformidad.peso }} </span>
            </p>
          </td>
          <td valign="top" width="493">
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-size: medium;">
                <span style="color: #000000; font-family: Arial, sans-serif; font-size: small;" v-html="no_conformidad.no_conformidad"></span>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="western" lang="es-ES-u-co-trad" align="justify">&nbsp;</p>
    <table width="944" cellspacing="0" cellpadding="5">
      <tbody>
        <tr valign="top">
          <td style="background: #cccccc;" bgcolor="#cccccc" width="114">
            <p class="western" lang="es-ES-u-co-trad" align="left">&nbsp;</p>
          </td>
          <td style="background: #cccccc;" bgcolor="#cccccc" width="808">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-size: small; font-family: Calibri, sans-serif;">
                <strong>3.- OBSERVACIONES DEL AUDITOR</strong>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="western" lang="es-ES-u-co-trad" align="left">&nbsp;</p>
    <p class="western" lang="es-ES-u-co-trad" align="justify">&nbsp;</p>
    <p class="western" lang="es-ES-u-co-trad" align="justify">
      <span style="font-family: Calibri, sans-serif;">
        <span style="font-size: small;">{{datos.anotaciones}}</span>
      </span>
    </p>
    <table width="944" cellspacing="0" cellpadding="5">
      <tbody>
        <tr valign="top">
          <td style="background: #cccccc;" bgcolor="#cccccc" width="808">
            <p class="western" lang="es-ES-u-co-trad" align="center">
              <span style="font-size: small; font-family: Calibri, sans-serif;">
                <strong>4.- IDENTIFICACI&Oacute;N FIRMAS ENTREGA DEL REGISTRO</strong>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <table width="944" cellspacing="0" cellpadding="5">
      <thead>
        <tr valign="top">
          <td>
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-size: small; font-family: Calibri, sans-serif;">
                <strong>ENTREGADO POR</strong>
              </span>
            </p>
          </td>
          <td>
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-family: Calibri, sans-serif; font-size: small;">
                <strong>RECIBIDO POR</strong>
              </span>
            </p>
          </td>
        </tr>
      </thead>
      <tbody v-for="auditor in datos.auditores" :key="auditor.id">
        <tr valign="top">
          <td>
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-size: small; font-family: Calibri, sans-serif;">Nombre Auditor:
                {{auditor.nombre_completo}} </span>
            </p>
          </td>
          <td>
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-size: medium; font-family: Calibri, sans-serif;">Nombre del responsable del operador
                auditado: {{datos.responsable.nombre}} {{datos.responsable.apellidos}}</span>
            </p>
          </td>
        </tr>
        <tr valign="top">
          <td>
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-family: Calibri, sans-serif; font-size: small;">Firma:</span>
            </p>
          </td>
          <td>
            <p class="western" lang="es-ES-u-co-trad" align="left">
              <span style="font-family: Calibri, sans-serif; font-size: small;">Firma:</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      datos: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({}),
    mounted() {
      console.log(this, this.datos)
    },
    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : ''
      },
    }
  }
</script>

<style scoped>

</style>