<template>
  <div v-if="isNotGroup" style="transform: translateX(-8px);">

    <span v-if="!hide">
      <v-icon style="font-size: 20px" color="primary" :disabled="!CAN_DELETE" @click.stop="doClick()">mdi-pencil-outline</v-icon>
    </span>

    <confirmation
        v-model="confirmDelete"
        :text="params.text || '¿Eliminar el objeto seleccionado?'"
        colorButton="error"
        @confirm="deleteItem()"/>

  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  components: {
    Confirmation,
  },
  data: function () {
    return {
      confirmDelete: false,
    }
  },
  computed: {
    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_DELETE() {
      return true
      return this.IS_LIST ? this.$store.state.permisos.can_delete : true
    },
    isNotGroup() {
      return !!this.params.data
    },
    hide() {
      return this.params.value === true
    },
  },
  methods: {
    init() {
      if (this.params.init instanceof Function) this.params.init()
    },
    doClick() {
      // console.warn(this.$parent.gridOptions, this.params.data)
      if (this.params.onClick instanceof Function) this.params.onClick(this.params)
    },
  },
}
</script>
