<template>
  <div>
    {{ value }}
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    value: ''
  }),
  methods: {
    async checkValue () {
      const value = this.params.value
      const type = this.params.type
      const codigo = this.params.codigo
      if (type === 'date') return this.formatDate(value)
      if (type === 'boolean') return this.checkBoolean(value)
      if (type === 'autocomplete' || type === 'select' && value) return Array.isArray(value) ? value.join(', ') : value
      // if (codigo === 'PAIS' && value) return value.nombre
      // if (codigo === 'PROVINCIA' && value) return value.nombre
      // if (codigo === 'LOCALIDAD' && value) return value.nombre
      // if (codigo === 'PAIS' && value) return await this.getPais(value)
      // if (codigo === 'PROVINCIA' && value) return await this.getProvincia(value)
      // if (codigo === 'LOCALIDAD' && value) return await this.getMunicipio(value)
      return value
    },
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    checkBoolean (value) {
      return value === '1' || value === 1 || value === true
        ? 'SI'
        : value === '0' || value === 0 || value === false
        ? 'NO'
        : value
    },
    async getPais (pais_uuid) {
      const { data } = await this.$axios.get(`territorios/paises`)
      let pais = ''
      data.forEach(element => {
        if (element.uuid === pais_uuid) pais = element.nombre
      })
      return pais
    },
    async getProvincia (provincia_uuid) {
      const pais_uuid = this.params.pais
      const { data } = await this.$axios.get(`territorios/paises/${pais_uuid}/provincias`)
      let provincia = ''
      data.forEach(element => {
        if (element.uuid === provincia_uuid) provincia = element.nombre
      })
      return provincia
    },
    async getMunicipio (municipio_uuid) {
      const provincia_uuid = this.params.provincia
      const { data } = await this.$axios.get(`territorios/provincias/${provincia_uuid}/municipios`)
      let municipio = ''
      data.forEach(element => {
        if (element.uuid === municipio_uuid) municipio = element.nombre
      })
      return municipio
    }
  },
  async created () {
    this.value = await this.checkValue()
  }
}
</script>