export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async list({ rootState }, params) {
    rootState.loading = true
    let res = null
    if (params?.nombre) {
      res = await this.$axios.get(`alcances?nombre=${params.nombre}`)
    } 
    else {
      res = await this.$axios.get('alcances')
    }
    rootState.loading = false

    if (res?.data && res.data['hydra:member']) {
      return res.data['hydra:member']
    }
  },

  async getGeneral({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get('alcances?codigo=GEN')
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`alcances/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async getSubalcances({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances?alcance.uuid=${params.uuid}`)
    rootState.loading = false

    if (res) return res.data['hydra:member']
  },

  async getSubalcancesForEmpresa({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances?empresas.uuid=${params.uuid}`)
    rootState.loading = false

    if (res) return res.data['hydra:member']
  },

  async create({ rootState }, alcance) {
    rootState.loading = true
    let tramites = alcance.tramites
    let body = alcance
    body.grupo = alcance.grupo.uuid
    this.$axios.post('alcances', body).then(() => {
      tramites.map(async (tramite) => {
        let data = { alcance: alcance.uuid, tramite: tramite.uuid }
        await this.$axios.post(`alcances/${alcance.uuid}/tramites`, data).then(() => {})
      })
      rootState.loading = false

    })
  },

  async update({ rootState }, alcance) {
    rootState.loading = true

    const body = {
      uuid: alcance.uuid,
      nombre: alcance.nombre,
      codigo: alcance.codigo,
      // grupo: alcance.grupo.uuid,
      // nombre: alcance.nombre,
      // esfuerzo: alcance.esfuerzo,
      // codigo_esquema: alcance.codigo_esquema,
      // color: alcance.color,
    }
    await this.$axios.patch(`alcances/${alcance.uuid}`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    // await this.$axios.delete(`alcances/${alcance.uuid}/tramites`)
    // alcance.tramites.map(async (tramite) => {
    //   let data = { alcance: alcance.uuid, tramite: tramite.uuid }
    //   await this.$axios.post(`alcances/${alcance.uuid}/tramites`, data)
    // })
    rootState.loading = false
  },

  async updateSubalcance({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }

    await this.$axios.patch(`subalcances/${payload.uuid}`, payload, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
  },

  async createSubalcance({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }

    const res = await this.$axios.post(`subalcances`, payload)
    rootState.loading = false
    if (res) return res.data
  },

  async deleteSubalcance({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }

    await this.$axios.delete(`subalcances/${payload.uuid}`)
    rootState.loading = false
  },

  

  // ITERACIONES PENDIENTES DE AUDITAR
  async iteracionesPendientesAuditar({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get(`alcances/iteraciones/pendientes-auditar`)
    rootState.loading = false
    if (res) return res.data
  },
}
