export default {
  // ETG
  PRODUCTO_TIPOS_ETG: [
    { text: 'Jamón Serrano Curado', value: 'jamon_serrano_curado' },
  ],
  PRODUCTO_TIPO_ETG: {
    jamon_serrano_curado: 'Jamón Serrano Curado',
  },

  PRESENTACION_TIPOS_ETG: [
    { text: 'Corte en V con pata', value: 'corte_v_con_pata' },
    { text: 'Corte en V sin pata', value: 'corte_v_sin_pata' },
    { text: 'Corte redondo sin pata', value: 'corte_redondo_sin_pata' },
    { text: 'Corte redondo con pata', value: 'corte_redondo_con_pata' },
    { text: 'Deshuesado con piel', value: 'deshuesado_con_piel' },
    { text: 'Deshuesado sin piel', value: 'deshuesado_sin_piel' },
    { text: 'Deshuesado corte en V', value: 'deshuesado_corte_v' },
    { text: 'Loncheado', value: 'loncheado' },
    { text: 'Porcionado', value: 'porcionado' },
  ],
  PRESENTACION_TIPO_ETG: {
    corte_v_con_pata: 'Corte en V con pata',
    corte_v_sin_pata: 'Corte en V sin pata',
    corte_redondo_sin_pata: 'Corte redondo sin pata',
    corte_redondo_con_pata: 'Corte redondo con pata',
    deshuesado_con_piel: 'Deshuesado con piel',
    deshuesado_sin_piel: 'Deshuesado sin piel',
    deshuesado_corte_v: 'Deshuesado corte en V',
    loncheado: 'Loncheado',
    porcionado: 'Porcionado',
  },

  PRODUCCION_TIPOS_ETG: ['propia', 'compra', 'maquila'],
  PRODUCCION_TIPO_ETG: {
    propia: 'propia',
    compra: 'compra',
    maquila: 'maquila',
  },

  ACTIVIDADES_ETG: [
    { nombre: 'Elaboración', responsableOnly: false },
    { nombre: 'Transformación', responsableOnly: false },
    { nombre: 'Distribución', responsableOnly: true },
    { nombre: 'Etiquetado', responsableOnly: true },
    { nombre: 'Comercialización', responsableOnly: true },
  ],

  // Productos Ibericos
  ALIMENTACION_TIPOS: [
    { text: 'Bellota', value: 'bellota' },
    { text: 'Cebo de campo', value: 'cebo_Campo' },
    { text: 'Cebo', value: 'cebo' },
  ],
  ALIMENTACION_TIPO: {
    bellota: 'Bellota',
    cebo_Campo: 'Cebo de campo',
    cebo: 'Cebo',
  },

  PRODUCTO_TIPOS: [
    { text: 'Jamón', value: 'jamon' },
    { text: 'Paleta', value: 'paleta' },
    { text: 'Lomo', value: 'lomo' },
    { text: 'Carnes Frescas', value: 'carnes_frescas' },
  ],
  PRODUCTO_TIPO: {
    jamon: 'Jamón',
    paleta: 'Paleta',
    lomo: 'Lomo',
    carnes_frescas: 'Carnes Frescas',
  },

  RACIAL_TIPOS: [
    { text: '100% Ibérico', value: '100_iberico' },
    { text: 'Ibérico', value: 'iberico' },
  ],
  RACIAL_TIPO: {
    '100_iberico': '100% Ibérico',
    iberico: 'Ibérico',
  },

  ESTADOS_TIPOS: [
    { text: 'Curado', value: 'curado' },
    { text: 'Semicurado', value: 'semicurado' },
    { text: 'Fresco', value: 'fresco' },
  ],
  ESTADOS_TIPO: {
    curado: '100% Curado',
    semicurado: 'Semicurado',
    fresco: 'Fresco',
  },

  PRESENTACION_TIPOS: [
    { text: 'Pieza entera', value: 'entera' },
    { text: 'Deshuesado', value: 'deshuesado' },
    { text: 'Loncheado', value: 'loncheado' },
    { text: 'Troceado', value: 'troceado' },
  ],
  PRESENTACION_TIPO: {
    entera: 'Pieza entera',
    deshuesado: 'Loncheado',
    loncheado: 'Loncheado',
    troceado: 'Troceado',
  },

  CERTIFICACION_TIPOS: [
    { text: 'Parcial', value: 'parcial' },
    { text: 'Operador', value: 'operador' },
  ],
  CERTIFICACION_TIPO: {
    parcial: 'Parcial',
    operador: 'Operador',
  },

  MODIFICACION_TIPOS: [
    { text: 'Ampliación', value: 'ampliacion' },
    { text: 'Reducción', value: 'reduccion' },
    { text: 'Otros motivos', value: 'otros' },
  ],
  MODIFICACION_TIPO: {
    ampliacion: 'Ampliación',
    reduccion: 'Reducción',
    otros: 'Otros motivos',
  },

  SOLICITUD_TIPOS: [
    { text: 'Inicial', value: 'inicial' },
    { text: 'Modificación de alcance', value: 'modificacion' },
  ],
  SOLICITUD_TIPO: {
    inicial: 'Inicial',
    modificacion: 'Modificación de alcance',
  },


  PRODUCCION_TIPOS: ['propia', 'maquila'],
  PRODUCCION_TIPO: {
    propia: 'propia',
    maquila: 'maquila',
  },

  OPERADORES_TIPOS: [
    { text: 'Responsable de la actividad', value: 'responsable' },
    { text: 'Prestador de servicios (a maquila)', value: 'prestador' },
  ],
  OPERADORES_TIPO: {
    responsable: 'Responsable de la actividad',
    prestador: 'Prestador de servicios (a maquila)',
  },
}
