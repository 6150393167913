<template>
  <v-app>
    <notification />
    <v-main>
      <Menu />
      <transition>
        <Nuxt />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "~/components/Menu"
import Notification from "~/components/Notification"
export default {
  components: { Notification, Menu }
};
</script>
