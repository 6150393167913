import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: [],
  item: {}
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list
  },
  SET_LIST(state, items) {
    this.list = items
  }
};

const test = {
  fecha_envio: "2019-10-16T15:01:08+00:00",
  exportado: true,
  alcance: {
    uuid: "5cf9cf66-eb4a-4ae2-80ed-53fe393a6a29",
    nombre: 'alcance'
  },
  cliente: {
    uuid: "753dca08-20d3-4169-967a-0ab007f1319b",
    nombre: 'cliente'
  },
  expediente: {
    uuid: "27beb644-2f51-42da-a601-c9b42f0299de",
    codigo: '1580230272'
  },
  grupo: "global",
  iteracion: "65339811-b766-43c0-98d1-7214ecb9cda5",
  tipo: "productores",
  data: [
    {
      'test 1': 1,
      'test 2': 3
    },
    {
      'test 1': 2,
      'test 2': 4
    },
  ]
}

//dispatch actions
export const actions = {

  async list({rootState, commit}, expediente) {
    rootState.loading = true
    let body = {}
    if (expediente) {
      body = {
        expediente: expediente.uuid,
        cliente: expediente.cliente.uuid,
        alcance: expediente.alcance.uuid,
      }
    }
    const res = await this.$axios.put('exportaciones', body)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({rootState}, item) {
    rootState.loading = true
    const res = await this.$axios.get(`exportaciones/${item.uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, item) {
    rootState.loading = true
    const body = {
      ...item,
      uuid: uuid.v4()
    }
    await this.$axios.post(`exportaciones`, body)
    rootState.loading = false
  },

  async delete({rootState}, item) {
    rootState.loading = true
    await this.$axios.delete(`exportaciones/${item.uuid}`)
    rootState.loading = false
  },

  async generarProductores({rootState}, payload) {
    rootState.loading = true
    const body = {
      cliente: payload.cliente,
      alcance: payload.alcance,
      iteracion: payload.iteracion
    }
    const res = await this.$axios.put(`exportaciones/productores`, body)
    rootState.loading = false
    if (res) return res.data
  },

  async generarProductos({rootState}, payload) {
    rootState.loading = true
    const body = {
      cliente: payload.cliente,
      alcance: payload.alcance,
      iteracion: payload.iteracion
    }
    const res = await this.$axios.put(`exportaciones/productos`, body)
    rootState.loading = false
    if (res) return res.data
  },

  async exportar({rootState}, item) {
    rootState.loading = true
    const body = {
      exportacion: item.uuid,
      fecha_envio: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    await this.$axios.patch(`exportaciones/${item.uuid}/exportar`, body)
    rootState.loading = false
  },

  async actualizarComentario({rootState}, item) {
    rootState.loading = true
    const body = {
      exportacion: item.uuid,
      comentarios: item.comentarios
    }
    await this.$axios.patch(`exportaciones/${item.uuid}/actualizar-comentario`, body)
    rootState.loading = false
  },

};