export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async list({ rootState }, payload) {
    rootState.loading = true
    if (payload) {
      let url = 'solicitudes'
      if (payload.user) {
        url += `?user.uuid=${payload.user}`
      }
      const res = await this.$axios.get(url)
      rootState.loading = false
      if (res) return res.data['hydra:member']
    } else {
      const res = await this.$axios.get('solicitudes')
      rootState.loading = false
      if (res) return res.data['hydra:member']
    }
  },

  async listBySubalcance({ rootState },subalcance_uuid) {
    rootState.loading = true
    const res = await this.$axios.get('solicitudes?subalcance.uuid='+subalcance_uuid+'&estado=APROBADA')
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`solicitudes/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async getByIteracion({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`solicitudes?iteracion.uuid=${uuid}`)
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async create({ rootState }, solicitud) {
    rootState.loading = true
    await this.$axios.post(`solicitudes`, solicitud)
    rootState.loading = false
  },

  async update({ rootState }, item) {
    rootState.loading = true
    const res = await this.$axios.patch(`solicitudes/${item.uuid}`, {
      solicitud: item
    },{
      headers: { 'Content-Type': 'application/merge-patch+json' },
    })
   rootState.loading = false
  },

  async aprobar({ rootState }, item) {
    rootState.loading = true
    await this.$axios.post(`solicitudes/${item.uuid}/aprobar`, { solicitud: item.uuid })
    rootState.loading = false
  },

  async desaprobar({ rootState }, item) {
    rootState.loading = true
    await this.$axios.post(`solicitudes/${item.uuid}/deshacer`, { solicitud: item.uuid })
    rootState.loading = false
  },

  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`solicitudes/${item.uuid}`)
    rootState.loading = false
  },
}
