<template>
    <v-autocomplete filled outlined dense label="Auditores" hide-details="auto" :items="db.auditores"
        :item-text="displayText" item-value="uuid" v-model="plantilla.refUuid" return-object @change="submit">
        <template v-slot:append-outer class="ma-0">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="info darken-1" size="30" @click="generate"
                        style="cursor: pointer">mdi-file-download-outline</v-icon>
                </template>
                <span>Genera formato de ejemplo con la entidad seleccionada y descarga el
                    fichero</span>
            </v-tooltip>
        </template>
    </v-autocomplete>
</template>

<script>
import moment from 'moment'


export default {
    props: {
        plantilla: {
            type: Object,
            default: () => ({})
        },


    },
    data: () => ({
        db: {
            auditores: []
        }
    }),
    methods: {
        displayText(item) {
            return `${item.nombreCompleto
                } (${item.iniciales})`
        },
        submit() {
            this.$emit('input', this.plantilla)
        },
        generate() {
            this.$emit('generate')
        },

        async getAuditores() {
            if (!this.plantilla.subalcance) return
            let subalcance_uuid = null
            if (typeof this.plantilla.subalcance == 'object')
                subalcance_uuid = this.plantilla.subalcance.uuid
            else subalcance_uuid = this.plantilla.subalcance
            const auditores = []
            const xhr = await this.$store.dispatch('auditores/list')
            for (const item of xhr) {
                let tieneAfinidad = false
                for (const afinidad of item.afinidades) {
                    if (afinidad.subalcance && afinidad.subalcance.uuid == subalcance_uuid) {
                        if (afinidad.hasta) {
                            let hasta = this.$moment(afinidad.hasta)
                            let hoy = this.$moment()
                            if (hasta.isAfter(hoy) || hasta.isSame(hoy)) {
                                auditores.push(item)
                                tieneAfinidad = true
                                break
                            }
                        }
                    }
                }
                if (tieneAfinidad) {
                    for (const incompatibilidad of item.incompatibilidades) {
                        if (incompatibilidad.subalcance && incompatibilidad.subalcance.uuid == subalcance_uuid) {
                            if (incompatibilidad.hasta) {
                                let hasta = this.$moment(incompatibilidad.hasta)
                                let hoy = this.$moment()
                                if (hasta.isAfter(hoy) || hasta.isSame(hoy)) {
                                    auditores.pop()
                                    break
                                }
                            }
                        }
                    }
                }
            }
            this.db.auditores = auditores
        },

        async init() {
            await this.getAuditores()
        }
    },

    mounted() {
        this.init()
    }
}
</script>