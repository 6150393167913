import format from '~/util/formatHelper'
export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {

  async list({ rootState }, { endpoint, params }) {
    rootState.loading = true
    const queryString = format.objectToUrlParams(params);
    const queryParams = Object.keys(params).length > 0 ? `?${queryString}` : '';
    const res = await this.$axios.get(`${endpoint}${queryParams}`);

    rootState.loading = false

    if (res?.data && res.data['hydra:member']) {
      return res.data['hydra:member']
    }
  },

  //process get, create, update, delete operations
  async process({ rootState }, { endpoint, action, uuid, data }) {
    rootState.loading = true
    let uri = '';
    let res = null;
    switch (action) {
      case 'get':
      case 'put':
      case 'patch':
      case 'delete':
        uri = `${endpoint}/${uuid}`;
        break;
      case 'post':
        uri = `${endpoint}`;
        break;
    }
    if(action === 'post' || action === 'put') {
      res = await this.$axios[action](uri, data);
    } else if(action === 'patch') {
      res = await this.$axios[action](uri, data, { headers: { 'Content-Type': 'application/merge-patch+json' } });
    } else {
      res = await this.$axios[action](uri);
    }

    rootState.loading = false
    return res ? res.data : null
  },

  async get({ rootState, dispatch }, { endpoint, uuid }) {
    return await dispatch('process', { endpoint, action: 'get', uuid: uuid });
  },

  async create({ rootState, dispatch }, { endpoint, data }) {
    return await dispatch('process', { endpoint, action: 'post', uuid: null, data });
  },

  async update({ rootState, dispatch }, { endpoint, uuid, data }) {
    return await dispatch('process', { endpoint, action: 'patch', uuid: uuid, data });
  },

  async delete({ rootState, dispatch }, { endpoint, uuid }) {
    return await dispatch('process', { endpoint, action: 'delete', uuid: uuid });
  }

}
