<template>
  <div>

    <p>
      <br />
    </p>
    <h1
      style="text-align: center; color:rgb(233 115 196); font-weight:400; font-size: 65px; text-transform: uppercase; ">
      Certificado de Producto
    </h1>
    <div style="text-align: center; font-weight:900;font-size: 25px;">
      Concedida a/ Awarded to
    </div>
    <h1 style="text-align: center; font-size: xxx-large">
      <strong>{{ clienteNombreCompleto }}</strong>
    </h1>
    <h2 style="text-align: center">
      {{ this.datos.cliente.contacto.direccion }}
    </h2>

    <br />

    <h2 style="text-align: center">
      {{ this.datos.cliente.codigo }}
    </h2>

    <br />

    <h2 style="text-align: center">
      C.P.{{ this.datos.cliente.contacto.cp }}. <!-- ({{ this.datos.cliente.contacto.localidad.pais.nombre }})-->
    </h2>
    <p style="text-align: center;font-weight: 600; ">{{ this.datos.cliente.contacto.direccion }},
      {{ this.datos.cliente.contacto.localidad.municipio.nombre }} {{ this.datos.cliente.contacto.cp }} ({{ this.datos.cliente.contacto.localidad.provincia.nombre }})
          <p>
        <p style="text-align: center; font-weight: 600;">RGSEAA:{{this.datos.cliente.instalaciones[0].rgseaa}}</p>
        <br />

        <p style="text-align: center; font-style: italic;">
          <strong>
            Araporcei* entendidad de certificación de producto certifica que los productos indicados, amparados por las
            marcas comerciales definidos en el alcance,
            han sido auditados y contrados conforme con los requisitos del documento normativo:
          </strong>
        </p>
        <p style="text-align: center; font-style: italic;">
          Araporcei* product certification body certifies that the product indicated, protected by the commercial brands
          defined in the scoped,
          has been audited and found to be in accordance with requirements of standard:
        </p>
        <hr>

        <div style="text-align: center;font-weight:700;">Pliego de Condiciones de la Especialidad Tradicional
          Garantizada ETG &ldquo;Jam&oacute;n Serrano&rdquo; seg&uacute;n Reglamento (CE) n&ordm; 1204/2008 de la
          Comisi&oacute;n, de 3 de diciembre de 2008, relativo a la inscripci&oacute;n de determinadas denominaciones en
          el registro de especialidades tradicionales garantizadas establecido en el Reglamento (CE) n &ordm; 509/2006
          del Consejo sobre las especialidades tradicionales garantizadas de los productos agr&iacute;colas y
          alimenticios</div>
        <hr>


        <p style="text-align: center; font-style: italic;">
          Such criteria has been assessed to agreed requirements in accordance with the procedure PE_CER-02 property of
          araporcei
        </p>
        <p style="text-align: center; font-weight:700">
          <strong>Dichos requisitos han sido evaluado en base a lo establido en el procedimiento PE_CER-02 de
            araporcei</strong>
        </p>

        <h4 style="text-align: center;font-weight:700">
          El alcance es:
        </h4>
        <h4 style="text-align: center;font-weight:700">
          Scope of certification:
        </h4>


        <table class="Normal tm5 tm6 tm7" style="height: 173px; width: 100%; border:1px solid black" rules="all">
          <tbody class="tm8">
            <tr style="height: 10px; background-color: #ccc;">
              <td class="tm9 tm10 tm11" style="width: 95.4251%; height: 10px; text-align: center;" colspan="5">
                <strong><span class="tm13">Alcance evaluado</span></strong></td>
            </tr>
            <tr style="height: 10px;background-color: #ccc;">
              <td class="tm9 tm10 tm14" style="width: 59.0744%; height: 10px; text-align: center;" colspan="3">
                <strong><span class="tm13">Alcance de Proceso</span></strong></td>
              <td class="tm9 tm10 tm15" style="width: 36.3507%; height: 10px; text-align: center;" colspan="2">
                <strong><span class="tm13">Alcance de Producto</span></strong></td>
            </tr>
            <tr style="height: 11px;background-color: #ccc;">
              <td class="tm9 tm16 tm17" style="width: 25.6688%; height: 21px; text-align: center;" rowspan="2">
                <strong><span class="tm13">Tipo de Operador</span></strong></td>
              <td class="tm9 tm18 tm19" style="width: 33.4056%; height: 11px; text-align: center;" colspan="2">
                <strong><span class="tm13">Fases</span></strong></td>
              <td class="tm9 tm18 tm20" style="width: 23.1819%; height: 11px; text-align: center;"><strong><span
                    class="tm13">Presentaciones Comerciales</span></strong></td>
              <td class="tm9 tm16 tm21" style="width: 13.1688%; height: 21px;text-align: center;" rowspan="2">
                <strong><span class="tm13">Marcas comerciales</span></strong></td>
            </tr>
            <tr style="height: 10px;background-color: #ccc;">
              <td class="tm9 tm18 tm23" style="width: 14.9358%; height: 10px; text-align: center;"><strong><span
                    class="tm13">Actividades realizadas</span></strong></td>
              <td class="tm9 tm18 tm23" style="width: 18.4698%; height: 10px; text-align: center;"><strong><span
                    class="tm13">Actividades subcontratadas</span></strong></td>
              <td class="tm9 tm24 tm20" style="width: 23.1819%; height: 10px; text-align: center;"><span
                  class="tm25">Producto con etiqueta</span></td>
            </tr>
            <tr style="height: 132px;">
              <td class="tm9 tm27 tm17" style="width: 25.6688%; height: 132px; text-align: center;"><span
                  class="tm25">Operador </span><strong><em><span class="tm28"
                      v-if="this.datos.solicitud.params.alcance_proceso.tipo_operador == 'responsable'">responsable</span></em></strong><span
                  class="tm25"> de todas las fases</span> <strong><span class="tm13">&nbsp;</span></strong></td>
              <td class="tm9 tm27 tm23" style="width: 14.9358%; height: 132px; text-align: center;"><span class="tm25"
                  v-for="actividad in this.getSiteDataAggroupedBy(this.datos.auditoria.sites, 'actividades_realizadas')"
                  :key="actividad.key">{{actividad.key}}<br></span></td>
              <td class="tm9 tm27 tm23" style="width: 18.4698%; height: 132px; text-align: center;"><span class="tm25"
                  v-for="actividad in this.getSiteDataAggroupedBy(this.datos.auditoria.sites, 'actividades_subcontratadas')"
                  :key="actividad.key">{{actividad.key}}<br></span></td>
              <td class="tm9 tm27 tm20" style="width: 23.1819%; height: 132px; text-align: center;"><span class="tm25"
                  v-if="getSiteData(this.datos.auditoria.sites).some(x => x.nombre.includes('corte_v_con_pata'))">Corte
                  en v con pata<br></span> <span class="tm25"
                  v-if="getSiteData(this.datos.auditoria.sites).some(x => x.nombre.includes('corte_v_sin_pata'))">Corte
                  en v sin pata<br></span> <span class="tm25"
                  v-if="getSiteData(this.datos.auditoria.sites).some(x => x.nombre.includes('deshuesado_corte_v'))">Deshuesado
                  corte en v<br></span> <span class="tm25"
                  v-if="getSiteData(this.datos.auditoria.sites).some(x => x.nombre.includes('deshuesado_sin_piel'))">Deshuesado
                  sin piel y desengrasado<br></span> <span class="tm25"
                  v-if="getSiteData(this.datos.auditoria.sites).some(x => x.nombre.includes('loncheado') || x.nombre.includes('porcionado'))">Transformado
                  (loncheado/ troceado)<br></span></td>
              <td class="tm9 tm27 tm21" style="width: 13.1688%; height: 132px; text-align: center;"><span class="tm25"
                  v-for="marca in this.getSiteDataAggroupedBy(this.datos.auditoria.sites, 'marcas')" :key="marca.key"><span
                    v-html="marca.key"></span><br></span></td>
            </tr>
          </tbody>
        </table>
        <p class="Normal">&nbsp;</p>








        <p style="text-align: center">
          <strong>
            El presente certificado será válido por tres años salvo suspensión, retirada o expiración notificada por
            Araporcei.

          </strong>
        </p>
        <p style="text-align: center; font-style: italic;">
          This certificate shall be valid for up 3 years, unless suspension, withdrawal or expiration notified by
          araporcei.
          This certificate is not valid without its corresponding thecnical annex.
        </p>

        <p></p>

        <table style="width: 100%; border:1px solid white;">
          <tbody>
            <tr>
              <td style="width: 25%; text-align: center;border-right:1px solid white;">
                <img src="https://www.enac.es/documents/7020/15646/enac_logo_color.png" height="45px">
              </td>
              <td style="width: 25%; text-align: center; border-right:1px solid white;">
                <strong>N° de Certificado:</strong>
                <br>
                <span style="font-style: italic;">Ceritifcate Number:</span>
                <br>
                <strong>Fecha de entrada en vigor:</strong>
                <br>
                <span style="font-style: italic;">Original aprroval date:</span>
                <br>
                <strong>Fecha de Modificación:</strong>
                <br>
                <span>Modification date:</span>
                <br>
                <strong>Fecha de caducidad:</strong>
                <br>
                <span style="font-style: italic;">Certification expiration date:</span>
              </td>
              <td style="width: 25%; text-align: center;border-right:1px solid white;">
                <p style="font-style: italic;">{{ datos.codigo }}</p>
                <p style="font-style: italic;">{{ formatDate(datos.fecha_certificado) }}</p>
                <p>{{ formatDate(datos.updated_at) }}</p>
                <p style="font-style: italic;">{{ formatDate(datos.fecha_certificado) }}</p>
              </td>
              <td style="width: 25%; text-align: center">
                <p>
                  <br>
                  <br>
                  <br>
                  <br>
                </p>
                Fdo. Teresa López Rivas
                <br>
                Directora Técnica
              </td>
            </tr>
          </tbody>
        </table>

        <p class="Normal tm5" style="text-align: center;"><strong><span class="tm7">Cualquier aclaraci&oacute;n
              adicional relativa al alcance de este certificado puede obtenerse consultando a su
              titular</span></strong><span class="tm6">.</span></p>
        <p class="Normal tm5" style="text-align: center;"><em><span class="tm8">Any additional relative explication of
              the scope of this certificate can be consulted to the holder of the certificate</span></em></p>


  </div>
</template>

<style scoped>
  @media screen {
    div.footer {
      display: none;
    }
  }

  @media print {
    div.footer {
      border-top: 2px solid #F47BA9;
      margin-top: 10px;
      position: fixed;
      bottom: 0;
    }

    div.footer p {
      text-align: center;
    }
  }

  table {
    border-collapse: collapse;
  }


  thead {
    display: table-row-group;
  }

  tr td {
    page-break-inside: avoid;
  }

  .no-border {
    border: none !important;
  }
</style>

<script>
  import MODELS from "~/models/araporcei/solicitudes"

  export default {
    props: {
      datos: {
        type: Object,
        default: () => {}
      }
    },
    data: function () {
      return {
        tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
        tipoProducto: MODELS.PRODUCTO_TIPO,
        tipoRacial: MODELS.RACIAL_TIPO,
        tipoEstado: MODELS.ESTADOS_TIPO,
        tipoPresentacion: MODELS.PRESENTACION_TIPO,
        tipoCertificacion: MODELS.CERTIFICACION_TIPO,
      }
    },
    computed: {
      isOperadorResposable() {
        return this.datos.solicitud && this.datos.solicitud.params.alcance_proceso.tipo_operador.includes('responsable')
      },
      clienteNombreCompleto() {
        let nombre = ''
        const cliente = this.datos.cliente
        if (cliente) {
          if (cliente.nombre) nombre += cliente.nombre
          if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
          if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
        }
        return nombre
      },
      sites() {
        const planificacionesSites = this.datos.auditoria.planificaciones.map(e => e.site.uuid)

        const sites = this.datos.auditoria.sites.filter(e => e.certificado)

        const parentSites = sites.filter(e => planificacionesSites.includes(e.uuid))
        const parentSitesUuids = parentSites.map(e => e.uuid)

        const childrenSites = sites.filter(e => parentSitesUuids.includes(e.parent_uuid))

        return {
          instalaciones: this.getSiteData(parentSites),
          actividades: this.getSiteData(childrenSites)
        }
      },
      instalaciones() {
        const instalacionesNombre = this.sites.instalaciones.map(e => e.nombre)
        return this.datos.cliente.instalaciones.filter(element => {
          return instalacionesNombre.some(e => e.includes(element.rgseaa))
        })
      },
      actividades() {
        return this.sites.actividades
      },
      productos() {
        const productos = []
        const alcance_producto = this.datos.solicitud.params.alcance_producto
        if (alcance_producto) {
          for (let i = 0; i < alcance_producto.length; i++) {
            const element = alcance_producto[i]
            const tipo_producto = this.tipoProducto[element.producto]
            if (!productos.includes(tipo_producto)) productos.push(tipo_producto)
          }
        }
        if (productos.length) {
          const start = productos.slice(0, productos.length - 1)
          const end = productos[productos.length - 1]
          return start.join(', ') + ' y ' + end
        } else return ''
      }
    },
    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : ''
      },
      formatDateLong(date) {
        return date ? this.$moment(date).format('LL') : ''
      },
      getSiteData(sites) {
        const data = []
        if (sites) {
          for (let i = 0; i < sites.length; i++) {
            const element = sites[i]
            let obj = {}
            obj.uuid = element.uuid
            obj.nombre = element.nombre
            obj.tipo = element.tipo
            for (let k = 0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
            data.push(obj)
          }
        }
        return data
      },
      obtenerInstalacion() {
        let instalacion = null;

        this.sites.instalaciones.forEach(inst => {
          let rgseaa = inst.nombre.split("-", 1)[0];
          instalacion = this.datos.cliente.instalaciones.find(x => x.rgseaa == rgseaa);
        })
        return instalacion;
      },
      allSiteData() {
        return this.getSiteData(this.datos.auditoria.sites);
      },
      getSiteDataAggroupedBy(siteData, key) {
        return this.getataAggroupedBy(this.getSiteData(siteData), key);
      },
      getataAggroupedBy(data, key) {
        var agroupedData = [];
        data.forEach((site) => {
          if (site[key] === undefined) {
            return;
          }

          var elementExists = agroupedData.find((x) => x.key == site[key]);

          if (elementExists == null) {
            agroupedData.push({
              key: site[key],
              values: []
            });
          }

          agroupedData.find((x) => x.key == site[key]).values.push(site);
        });

        return agroupedData;
      },
      // init () {
      //   if (!this.datos.fecha_decision) {
      //     this.$router.replace({ name: 'dictamenes' })
      //     const alert = {
      //       text: 'El dictamen no tiene fecha de decisión.',
      //       color: 'error'
      //     }
      //     this.$store.commit("notification/show", alert)
      //   }
      // }
    },
    // created () {
    //   this.init()
    // }
  }
</script>
