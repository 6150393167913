<template>
  <div class="simpletable-editor">
    <div class="row" style="position: relative">
      <v-col cols="12">
        <v-simple-table style="border: 2px solid #bcbcbc; border-radius: 5px;">
          <template v-slot:default>
            <thead>
              <tr style="background-color: #f0f0f0;
                  color: #222;
                  text-align: center;
                  font-size: 13px;
                  font-weight: 400;
                  white-space: nowrap;
                  height: 26px;
                  line-height: 25px;">
                <th v-for="(h, index) in table.headers" :key="'header-' + index" class="text-left">
                  <v-text-field
                    :label="h.label ? '' : db.alphabet[index + 1]"
                    v-model="h.label"
                    dense
                    class="text-field-transparent"
                    solo
                    flat
                    hide-details
                    style="border: 0">
                  </v-text-field>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in table.rows" :key="'row-' + index">
                <td v-for="(field, i) in row" :key="'field-'+i+index">
                  <v-text-field
                    :ref="`input-${index}-${i}`"
                    :data-row="index"
                    :data-col="i"

                    label=""
                    v-model="row[i]"
                    dense
                    solo
                    flat
                    hide-details
                    style="border: 0"
                    class="text-field-transparent"
                    placeholder="..."
                    @keydown="moveInput">
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="99" style="padding: 5px;
                  text-align: center;
                  cursor: pointer;
                  background-color: rgb(236, 245, 240);
                  text-transform: uppercase;
                  font-weight: bold;
                  font-size: 9px;
                  color: rgb(113, 113, 113);
                  user-select: none;
                  border: 1px dashed rgb(204, 204, 204) !important;"
                  @click="addRowAtIndex('bottom')">
                  Añadir otra fila</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <div
        v-if="ui.mode == 'full'"
        @click="doAction('add-right', table.headers.length)"
        style="position: absolute;right: 0;top: calc((100% - 90%) / 2);
          cursor: pointer;width: 10px;height: 90%;
          background: rgb(240 240 240);border-radius: 6px;">
        <div style="height: 100%;
          background: rgb(188 188 188);
          width: 3px;
          margin: auto;">
            <i data-v-603eefba="" aria-hidden="true" class="v-icon notranslate icon-menu mdi mdi-plus theme--light" style="margin: auto;top: 45%;right: 8px;/* display: none; */z-index: 666;background: #93ce95f7;border-radius: 50%;color: #4caf50 !important;font-size: 20px; padding: 1px;"></i>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\bloques\edit\simpletableBlockEditor.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 9th May 2022
 * Last Modified: Mon Jun 06 2022
 **/

import { uuid } from 'vue-uuid'

import notifications from '~/util/notificationHelper'

export default {
  props: ['_ui', '_table'],

  data: () => ({
    table: {
      rows: [
        ['', ''],
        ['', ''],
        ['', ''],
      ],
      headers: [{ label: '' }, { label: '' }],
      footers: [{ formula: 'count' }, { formula: 'sum' }],
    },

    db: {
      alphabet: [],

      actions: {
        column: {
          'add-right': {
            label: 'Insertar a la derecha',
          },
          'add-left': {
            label: 'Insertar a la izquierda',
          },
          'remove-col': {
            label: 'Eliminar columna',
          },
        },

        row: {
          'add-top': {
            label: 'Insertar 1 encima',
          },
          'add-bottom': {
            label: 'Insertar 1 debajo',
          },
          'remove-row': {
            label: 'Eliminar fila',
          },
        },
      },

      defaults: {
        row: [],
        header: { label: '' },
        footer: { formula: 'count' },
      },
    },

    ui: {
      mode: 'full',
      loading: false,
      isValid: false,
    },
  }),

  computed: {},

  methods: {
    //+-------------------------------------------------
    // moveInput()
    // Detects arrow keys and moves focus to the next
    // -----
    // Created on Fri May 20 2022
    //+-------------------------------------------------
    moveInput(event) {
      let arrows = [37, 38, 39, 40]
      if (arrows.indexOf(event.keyCode) == -1) return

      let component = null
      let row = parseInt(event.target.dataset.row)
      let col = parseInt(event.target.dataset.col)

      if (event.keyCode == 37) col--
      if (event.keyCode == 38) row--
      if (event.keyCode == 39) col++
      if (event.keyCode == 40) row++

      // console.warn(row, col, 'input-' + (row - 1) + '-' + col, component)

      component = this.$refs['input-' + row + '-' + col]
      if (component && component.length) {
        component = component[0]
        component.$el.querySelector('input').focus()
        component.$el.querySelector('input').select()
      }

      event.preventDefault()
    },

    //+-------------------------------------------------
    // doAction()
    // Performs an action defined by Key
    // -----
    // Created on Mon Jun 28 2021
    //+-------------------------------------------------
    doAction(action, index, secondary) {
      if (action == 'add-right' || action == 'add-left') {
        let position = action == 'add-right' ? index + 1 : index
        this.addColumnAtIndex(position)
      }

      if (action == 'add-top' || action == 'add-bottom') {
        let position = action == 'add-top' ? index : index + 1
        this.addRowAtIndex(position)
      }

      if (action == 'remove-row') {
        this.removeRow(index)
      }

      if (action == 'remove-col') {
        this.removeCol(index)
      }

      console.warn('🛠', 'Action performed: ', action)
    },

    //+-------------------------------------------------
    // addColumnAtIndex()
    // Adds a column to headers and each row
    // -----
    // Created on Mon Jun 28 2021
    //+-------------------------------------------------
    addColumnAtIndex(index) {
      let header = { ...this.db.defaults.header }
      let footer = { ...this.db.defaults.footer }

      this.table.headers.splice(index, 0, header)
      this.table.footers.splice(index, 0, footer)

      this.table.rows.forEach((row) => {
        row.splice(index, 0, '')
      })
    },

    //+-------------------------------------------------
    // addRowAtIndex()
    // Adds a new row
    // -----
    // Created on Tue Jun 29 2021
    //+-------------------------------------------------
    addRowAtIndex(index) {
      index = index == 'bottom' ? this.table.rows.length : index

      let newRow = []
      this.table.headers.forEach((item) => {
        newRow.push('')
      })

      this.table.rows.splice(index, 0, newRow)
    },

    removeRow(index, type) {
      this.table.rows.splice(index, 1)
    },

    removeCol(index, type) {
      this.table.rows.forEach((row) => {
        row.splice(index, 1)
      })
      this.table.footers.splice(index, 1)
      this.table.headers.splice(index, 1)
    },

    //+-------------------------------------------------
    // getDataFromProps()
    // Assigns this.table values from _data
    // -----
    // Created on Tue Jul 06 2021
    //+-------------------------------------------------
    getDataFromProps() {
      if (this._table && this._table.rows) {
        this.table = { ...this._table }
      }

      this.ui.mode = this._ui?.mode || 'limited'
    },

    //+-------------------------------------------------
    // normalizeColsAndRows()
    // Ensures that there is enough elements in each row
    // for every header
    // -----
    // Created on Wed Jun 30 2021
    //+-------------------------------------------------
    normalizeColsAndRows() {
      let headers = this.table.headers.length
      this.table.rows.forEach((row) => {
        if (row.length < headers) {
          for (let i = row.length; i < headers; i++) {
            row.push('')
          }
        }
      })
    },

    createKeyedAlphabet() {
      var a = 97
      var charArray = {}
      for (var i = 0; i < 26; i++) charArray[i + 1] = String.fromCharCode(a + i).toUpperCase()

      this.db.alphabet = charArray
    },

    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    //+-------------------------------------------------
    // Async API requests
    //+-------------------------------------------------

    async init() {
      this.getDataFromProps()
      this.createKeyedAlphabet()
      this.normalizeColsAndRows()
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style>
.simpletable-editor .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background-color: transparent;
}
</style>
