<template>
  <div id="layout_body">

      <v-toolbar
        dense
        flat
        dark
        class="noprint"
        >
        <v-img :src="require('../static/logo_horizontal.png')" contain
            max-height="50"
            max-width="250"></v-img>
        <v-spacer></v-spacer>

        <v-btn icon @click="printDocument()">
            <v-icon>mdi-printer</v-icon>
        </v-btn>
        </v-toolbar>

    <Nuxt/>
    <!-- <v-btn
      rounded
      color="primary"
      dark
      class="print-button noprint"
      @click="printDocument()"
    >
      Imprimir documento
    </v-btn> -->
  </div>
</template>

<script>
export default {
    name: "public-layout",
    data: () => ({}),
    methods: {
        printDocument() {
            window.print();
        }
    }
}
</script>

<style>
.page-break-before {
    page-break-before: always;
}
</style>

<style scoped>
@media print {
   .noprint {
      /* visibility: hidden; */
      display: none;
   }
}

@media screen {
    #layout_body{
        /* Reset the document's margin values */
        margin: 0;
        /* Reset the document's padding values */
        padding: 0;
        /* Use the platform's native font as the default */
        font-family: "Roboto", -apple-system, "San Francisco", "Segoe UI", "Helvetica Neue", sans-serif;
        /* Define a reasonable base font size */
        font-size: 12pt;

        /* Styles for better appearance on screens only -- are reset to defaults in print styles later */

        /* Use a non-white background color to make the content areas stick out from the full page box */
        background-color: #eee;
    }

    * {
        /* Include the content box as well as padding and border for precise definitions */
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    tbody tr:hover{
        background: none !important;
    }

    .visualizer-header{
        font-size: 23px;
        font-weight: 300;
        line-height: 65px;
    }

    .print-button{
        bottom: 10px;
        right: 0;
        position: fixed;
        margin: 16px;
    }
}
</style>
