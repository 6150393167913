<template>
  <div>
    <v-card-title>
      <!-- <v-btn text small @click="onRemoveSelected($event)">Borrar selecionadas</v-btn> -->
      <v-spacer></v-spacer>
      <v-btn small depressed class="mr-2" color="primary" @click="showConfirm('Productores')">Generar productores</v-btn>
      <v-btn small depressed class="mr-2" color="primary" @click="showConfirm('Productos')">Generar productos</v-btn>
      <v-btn small depressed class="white--text" color="secondary2" @click="confirmSaveDialog = true" v-if="showSave">Guardar</v-btn>
    </v-card-title>

    <confirmation 
      v-model="confirmDialog"
      :text="textConfirm || '¿Está seguro de que desea realizar esta acción?'"
      :textButton="'confirmar'"
      colorButton="primary"
      @confirm="confirm()"
      @close="close()"
    />

    <confirmation 
      v-model="confirmSaveDialog"
      :text="'¿Está seguro de que desea guardar los cambios?'"
      :textButton="'guardar'"
      colorButton="primary"
      @confirm="confirmSave()"
      @close="close()"
    >
      <v-card-text>
        <v-container grid-list-xs>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="comentarios"
                label="Añadir comentario"
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </confirmation>

  </div>
</template>

<script>

  import Confirmation from "~/components/Confirmation"
  import moment from 'moment'

  export default {
    props: ['expediente', 'iteracionSelected'],
    components: {
      Confirmation
    },
    data: () => ({
      confirmDialog: false,
      confirmSaveDialog: false,
      showSave: false,
      textConfirm: '',
      tipo: '',
      comentarios: '',
      items: [],
      headers: []
    }),
    methods: {
      close () {
        this.confirmSaveDialog = false
        this.confirmDialog = false
        this.comentarios = ''
      },
      showConfirm (event) {
        this.tipo = event
        this.textConfirm = `¿Está seguro de que desea generar ${this.tipo.toLowerCase()}?`
        this.confirmDialog = true
      },
      confirm () {
        this.$emit('generarTabla', this.tipo)
        this.showSave = true
        this.close()
        // this.$emit('onAddRow', this.tipo)
      },
      confirmSave () {
        this.confirmSaveDialog = false
        const body = {
          tipo: this.tipo,
          comentarios: this.comentarios
        }
        this.showSave = false
        this.$emit('save', body)
      },
      onRemoveSelected () {
        this.$emit('onRemoveSelected')
      },
    }
  };
</script>
