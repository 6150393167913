<template>
  <div>
    <table style="width: 100%">
      <tbody>
        <tr>
          <td style="text-align: center">
            <img src="https://coplaca.certiapp.com/coplaca/coplaca_logo.png" height="52px" width="100px">
            <h3 style="font-weight: 700" >COMUNICACIÓN DPTO. TÉCNICO</h3>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
      <br />
    </p>
    <p>
      <strong>A:</strong>
      Dpto Técnico de {{ clienteNombreCompleto }}
    </p>
    
    <p>
      <strong>Asunto: BAJA TEMPORAL</strong> en producción certificada del productor
      <strong>{{ clienteNombreCompleto }}</strong> (Código XXXXX)
    </p>
    
    <p>
      <strong>Fecha:</strong> {{ dateLong }}
    </p>
    
    <p>
      El pasado <strong>{{ date }}</strong>,
      se realizó, por parte del personal técnico contratadopor COPLACA OPP para la realización de este trabajo,
      una auditoría interna para verificar cumplimiento de Normativa {{ datos.revision.alcance.nombre }} a la finca del productor
      <strong>{{ clienteNombreCompleto }}</strong>.
    </p>
    
    <p>
      Esto constituye una no conformidad de la Norma UNEGAP, y un incumplimiento mayor. Recordamos 
      <strong>
        que la entrada y permanencia en el sistema de producción certificada es voluntaria para el productor,
        y que implica el cumplimiento de lo recogido en la misma.
      </strong>
    </p>
    
    <p>
      Por tanto, comunicamos la <strong>BAJA</strong> de esta finca del censo de productores de COPLACA NATUR,
      durante un periodo no menor de <strong>TRES MESES desde la fecha actual</strong>.
      Transcurrido este plazo <strong>({{ dateAdd }})</strong>
      se podrá contactar con este Departamento por si procediera la realización de una auditoría inicial de entrada en el sistema,
      siempre que se demuestre previamente la solución de estas No Conformidades.
    </p>
    
    <p v-if="datos.no_conformidades && datos.no_conformidades.length">
      <strong>No Conformidades:</strong>
    </p>
    <p v-if="datos.no_conformidades && datos.no_conformidades.length">&nbsp;</p>
    <span v-if="datos.no_conformidades && datos.no_conformidades.length">
      <p v-for="(no_conformidad, idx) in datos.no_conformidades" :key="no_conformidad.id">
        <strong>{{ idx + 1 }}. {{ no_conformidad.codigo + ' - ' + no_conformidad.titulo }}</strong>
        <br>
        {{ no_conformidad['texto-no-conformidad'] }}
      </p>
    </span>
    
    <p>
      <strong>Observaciones:</strong> {{ datos.observaciones || '' }}
    </p>
    
    <p style="text-align: center">
      <br>
      <br>
      <br>
      <br>
      Javier López-Cepero Jiménez
      <br>
      Dpto. Técnico de COPLACA OPP
    </p>
    
    <p style="text-align: center">
      Santa Cruz de Tenerife, {{ dateLong }}
    </p>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }

  table, th, td {
    border: 0.5px solid black;
  }
</style>

<script>
import moment from 'moment'

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
  }),
  computed: {
    date () {
      return this.$moment().format('DD/MM/YYYY')
    },
    dateAdd () {
      return this.$moment().add(3, 'M').format('DD/MM/YYYY')
    },
    dateLong () {
      return this.$moment().format('LL')
    },
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
  },
}
</script>

