<template>
  <div>
    <table style="width: 100%">
      <tbody>
        <tr>
          <td style="text-align: center">
            <img src="https://coplaca.certiapp.com/coplaca/coplaca_logo.png" height="52px" width="100px">
            <h3 style="font-weight: 700" >
              PROCEDIMIENTO DE AUDITORÍAS
              <br>
              Y SANCIONES INTERNAS
            </h3>
          </td>
        </tr>
      </tbody>
    </table>

    <p>
      <br />
      <br />
    </p>
    <p>
      <strong>Cultivo:</strong> {{ solicitud_data.cultivo }}
    </p>
    <p>
      <strong>Alcance:</strong> {{ alcance.nombre }}
    </p>
    <p>
      <strong>Productor:</strong> <span v-if="site_data.productor">{{ site_data.productor[0].nombre_productor }}</span>
    </p>
    <p>
      <strong>NIF:</strong> <span v-if="site_data.productor" >{{ site_data.productor[0].nif }}</span>
    </p>
    <p>
      <strong>Datos SIGPAC:</strong>
    </p>
    <p v-for="item in site_data.parcela" :key="item.id">
      {{ item.cod_provincia + ' : ' + item.poligono + ' : ' + item.parcela + ' : ' + item.recinto }}
    </p>
    <p>
      <strong>Técnico responsable:</strong> {{ tecnico.nombre + ' ' + tecnico.apellido1 + ' ' + tecnico.apellido2 }}
    </p>
    <p>
      <strong>N° ROPO:</strong> {{ tecnico.num_ropo }}
    </p>

    <p>
      <strong>Datos del solicitante:</strong><br>
      {{ nombreCompleto }}<br>
      {{ $auth.user.email }}<br>
      {{ $auth.user.nif }}
    </p>

    <p>
      En esta fecha <strong>{{ $moment(datos.created_at).format('DD/MM/YYYY') }}</strong> se inician los registros correspondientes
      para la inclusión de la finca o fincascitadas en el sistema de producción certificada COPLACA.
      La auditoría de entrada se podrá realizar por el Departamento Técnico de COPLACA en cualquier momento a partir de que haya TRES meses de registros.
    </p>

    <p>
      Firmas del productor o encargado de la finca y del técnico responsable:
    </p>

    <table style="width: 100%">
      <tr>
        <td>
          <p>Fdo:</p>
          <br>
          <br>
          <br>
          <br>
        </td>
        <td>
          <p>Fdo:</p>
          <br>
          <br>
          <br>
          <br>
        </td>
      </tr>
      <tr>
        <td>Productor</td>
        <td>Tecnico</td>
      </tr>
    </table>

    <p style="text-align: right">
      <strong>Remitir este modelo por mail al Departamento Técnico de COPACA (cepero@coplaca.org)</strong>
    </p>
  </div>
</template>

<style scoped>
table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 0.5px solid black;
}
</style>

<script>
import format from '~/util/formatHelper'

export default {
  props: {
    datos: { type: Object, default: () => {} },
  },
  mounted() {
    this.getTecnico()
  },
  data: () => ({
    tecnico: {},
  }),
  computed: {
    nombreCompleto() {
      return format.fullName(this.$auth.user.contacto)
    },

    alcance() {
      return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
    },
    solicitud_data() {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach((element) => {
          data[element.keyname] = element.value
        })
        return data
      } else return {}
    },
    site_data() {
      if (this.datos.isCliente) return this.datos.isCliente
      else if (this.datos.sites) {
        let data = {}
        let tipos = []

        for (let i = 0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
        }

        for (let i = 0; i < tipos.length; i++) {
          const element = tipos[i]
          data[element] = []
        }

        for (let i = 0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          let obj = {}
          obj.uuid = element.uuid
          for (let k = 0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data[element.tipo].push(obj)
        }

        return data
      } else return {}
    },
  },
  methods: {
    async getTecnico() {
      const res = await this.$store.dispatch(
        'clientes_tecnicos/listCliente',
        this.datos.cliente.uuid
      )
      if (res && res.length > 0) this.tecnico = res[0]
    },
  },
}
</script>
