<template> 
  <v-overlay :value="dialog" z-index="1000">
    <v-card color="primary" dark width="500">
      <v-card-text class="ma-0">
        {{ text }}
        <v-progress-linear indeterminate color="white" class="mt-2 mb-1"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>

  export default {
    name: "Loading",
    props: {
      value: Boolean,
      text: { type: String, default: 'Cargando datos...' },
      overlay: { type: Boolean, default: false }
    },
    data(ctx) {
      return {
         dialog: ctx.value
      }
    },
    watch: {
      value: function (val) {
        this.dialog = val
      }
    },
    methods: {
      close(){
          this.dialog = false
          this.$emit('input', null)
      }
    }
  }
</script>
