<template>
  <div v-if="isNotGroup">

    <!-- <v-btn small color="success" @click.stop="confirm = true">Exportar</v-btn> -->

    <v-icon style="font-size: 20px" color="primary" class="mr-2" @click.stop="showConfirm('export')">mdi-download</v-icon>

    <v-icon style="font-size: 20px" color="primary" class="mr-2" :disabled="!CAN_EDIT" @click.stop="showConfirm('update')">mdi-pencil</v-icon>
    
    <v-icon style="font-size: 20px" color="primary" :disabled="!CAN_DELETE" @click.stop="showConfirm('delete')">mdi-delete-outline</v-icon>

    <confirmation 
      v-model="confirmExport"
      :text="'¿Exportar la comunicación seleccionada?'"
      textButton="Exportar"
      colorButton="primary"
      @confirm="exportar()"
      @close="close()"/>

    <confirmation 
      v-model="confirmUpdate"
      :text="'Actualizar comentario'"
      :textButton="'guardar'"
      colorButton="primary"
      @confirm="update()"
      @close="close()"
    >
      <v-card-text>
        <v-container grid-list-xs>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="comentarios"
                label="Añadir comentario"
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </confirmation>

    <confirmation 
      v-model="confirmDelete"
      :text="'¿Eliminar la comunicación seleccionada?'"
      colorButton="error"
      @confirm="deleteItem()"/>

  </div>
</template>

<script>
import Confirmation from "~/components/Confirmation"
import moment from 'moment'
import XLSX from 'xlsx'

export default {
  components: {
    Confirmation
  },
  data: () => ({
    confirmExport: false,
    confirmUpdate: false,
    confirmDelete: false,
    comentarios: '',
    itemSelected: null
  }),
  computed: {
    IS_LIST () {
      return !this.$route.params.uuid
    },
    CAN_EDIT () {
      return this.IS_LIST ? this.$store.state.permisos.can_edit : true
    },
    CAN_DELETE () {
      return this.IS_LIST ? this.$store.state.permisos.can_delete : true
    },
    isNotGroup () {
      return !!this.params.data
    },
    item () {
      return this.params.node.data
    },
    uuid () {
      return this.item.uuid
    },
    data () {
      return this.item.data
    },
    exportado () {
      return this.item.exportado
    },
    expediente () {
      return this.item.expediente.uuid
    },
    fecha_envio () {
      return this.item.fecha_envio
    },
  },
	methods: {
    reload () {
      if (this.params.reload instanceof Function) {
        this.params.reload();
      }
    },
    async showConfirm (dialog) {
      if (dialog !== 'delete') await this.getOne()
      if (dialog === 'export') this.confirmExport = true
      if (dialog === 'update') this.confirmUpdate = true
      if (dialog === 'delete')  this.confirmDelete = true
    },
    close () {
      this.itemSelected = null
      this.comentarios = ''
    },
    async getOne () {
      const res = await this.$store.dispatch('comunicaciones_externas/get', this.item)
      if (res) {
        this.itemSelected = res
        this.comentarios = res.comentarios
      }
    },
    async exportar() {
      this.confirmExport = false
      this.createFile()
      if (!this.exportado) await this.$store.dispatch('comunicaciones_externas/exportar', this.item)
      this.close()
      this.reload()
    },
    async createFile() {
      if (this.itemSelected) {
        const data = this.itemSelected.data
        const dataWS = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS, 'Hoja 1')
        const codigo = this.itemSelected.expediente.codigo.replace('/', '_')
        XLSX.writeFile(wb, 'GLOBALGAP_producer_' + codigo + '.xlsx')
      }
    },
    capitalizeFirstLetter(string) {
      if (string) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      else return ''
    },
    async update(){
      if (this.itemSelected) {
        this.confirmDelete = false
        const body = {
          uuid: this.uuid,
          comentarios: this.comentarios
        }
        await this.$store.dispatch('comunicaciones_externas/actualizarComentario', body)
        this.close()
        this.reload()
      }
    },
    async deleteItem(){
      this.confirmDelete = false
      await this.$store.dispatch('comunicaciones_externas/delete', this.item)      
      this.reload()
    }
  }
}
</script>
