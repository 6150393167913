<template>
  <div style="width: 100%;">
    <v-container>
      <v-row class="text-justify" justify="center">

        <v-col cols="12" class="text-capitalize">
          <span class="font-weight-bold body-1">NO CONFORMIDAD</span>
        </v-col>

        <v-col cols="12">
          <span class="font-weight-bold">No Conformidad - {{ params.data.codigo }}: </span>
          <p class="overflow-visible">{{ params.data.hint }}</p>
        </v-col>
        
        <!-- <v-col cols="12">
          <div>
            <span class="font-weight-bold">Respuesta:</span>
            {{ params.data.respuesta }}
          </div>
          <div>
            <span class="font-weight-bold">No Conformidad:</span>
            {{ params.data.no_conformidad }}
          </div>
          <div>
            <span class="font-weight-bold">Observaciones:</span>
            {{ params.data.observaciones }}
          </div>
        </v-col> -->
        
        <v-col cols="12">
          <table style="width: 100%">
            <thead>
              <tr>
                <td colspan="4" class="pa-2 text-center font-weight-bold">Acción Correctiva</td>
              </tr>
              <tr class="text-center">
                <td class="pa-2"></td>
                <td class="pa-2 caption font-weight-bold">Causa</td>
                <td class="pa-2 caption font-weight-bold">Acción</td>
                <td class="pa-2 caption font-weight-bold">Evidencias</td>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td class="pa-2 caption font-weight-bold">Valoración</td>
                <td class="pa-2 caption">{{ params.data.valoracion_causa }}</td>
                <td class="pa-2 caption">{{ params.data.valoracion_accion }}</td>
                <td class="pa-2 caption">{{ params.data.valoracion_evidencias }}</td>
              </tr>
              <tr>
                <td class="pa-2 caption text-center font-weight-bold">Texto</td>
                <td class="pa-2 caption">{{ params.data.causa }}</td>
                <td class="pa-2 caption">{{ params.data.accion_propuesta }}</td>
                <td class="pa-2 caption">{{ params.data.evidencias_aportadas }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }

  table, th, td {
    border: 0.5px solid black;
  }

  .overflow-visible {
    white-space: initial;
    overflow: hidden;
  }
</style>

<script>
export default {
}
</script>
