<template>
  <div>

    <span v-show="params.value !== false">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          {{ moment(params.value).format('DD / MM / YYYY') }}
          <v-icon
            color="primary" small
            dark
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            @click.stop="openDateEditor">
            mdi-square-edit-outline
          </v-icon>
        </template>
        <span>Modificar fecha de creación</span>
      </v-tooltip>
    </span>

    <v-dialog
        ref="dialog"
        v-model="item.show"
        :return-value.sync="item.value"
        persistent
        width="290px"
      >
        <v-date-picker
          v-model="item.value"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="item.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(item.value); storeDate()"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>


  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    EditCreatedAtRenderer.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 15th April 2021
 * Last Modified: Fri Mar 31 2023
 **/

import moment from 'moment'

export default {
  data: function () {
    return {
      item: {
        value: new Date().toISOString().substr(0, 10),
        show: false,
        modal: false,
      },
    }
  },

  methods: {
    openDateEditor() {
      this.item.value = moment(this.params.value).format('YYYY-MM-DD')
      this.item.show = true
    },

    async storeDate() {
      let uuid = this.params.node.data.uuid
      await this.$store.dispatch(`grupo_solicitudes/updateDate`, {
        fecha: this.item.value,
        grupo_solicitud: uuid,
      })

      this.item.show = false
      this.$parent.$parent.$parent.$parent.init()
    },

    moment: function (date) {
      return moment(date)
    },
  },
}
</script>
