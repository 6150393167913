export const state = () => ({
  list: [],
});

// commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
};

// dispatch actions
export const actions = {

  async list({rootState, commit}, params) {
    rootState.loading = true
    const res = await this.$axios.get('comunidad')
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

};
