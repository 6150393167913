<template>
  <div v-if="isNotGroup">
    <div v-html="params.value"></div>
  </div>
</template>

<script>
  export default {
    computed: {
      isNotGroup () {
        return !!this.params.data
      },
    },
    methods: {
    }
  }
</script>
