<template>
  <v-app>
      <notification/>
      <v-main>
        <transition>
          <Nuxt/>
        </transition>
      </v-main>
  </v-app>
</template>

<script>
  import Menu from "~/components/Menu"
  import Notification from "~/components/Notification"

  export default {
    name: "blank-layout",
    components: {Notification, Menu}
  }
</script>

<style scoped>

</style>
