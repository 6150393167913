export const state = () => ({});

//commit mutations
export const mutations = {};


//dispatch actions
export const actions = {

  async list({rootState}) {
    const res = await this.$axios.get("grupos-alcance");
    if (res) return res.data;
  },

  async get({rootState}, item) {
    const res = await this.$axios.get(`grupos-alcance/${item.uuid}`);
    if (res) return res.data;
  },

  async new({rootState}, item) {
    await this.$axios.post('grupo-alcance', {
      cliente: item.cliente.uuid,
      uuid: item.uuid,
      configuraciones: item.configuraciones,
      grupo_solicitud: item.grupo_solicitud.uuid
    })
  }

};
