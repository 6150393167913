import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _014917fc = () => interopDefault(import('../pages/alcances/index.vue' /* webpackChunkName: "pages/alcances/index" */))
const _bdcf83a8 = () => interopDefault(import('../pages/auditores/index.vue' /* webpackChunkName: "pages/auditores/index" */))
const _84a18f9a = () => interopDefault(import('../pages/auditorias/index.vue' /* webpackChunkName: "pages/auditorias/index" */))
const _66fb5ff1 = () => interopDefault(import('../pages/backups/index.vue' /* webpackChunkName: "pages/backups/index" */))
const _45198237 = () => interopDefault(import('../pages/bloques/index.vue' /* webpackChunkName: "pages/bloques/index" */))
const _40eceb9b = () => interopDefault(import('../pages/cargas/index.vue' /* webpackChunkName: "pages/cargas/index" */))
const _20cba910 = () => interopDefault(import('../pages/certificados/index.vue' /* webpackChunkName: "pages/certificados/index" */))
const _100b830f = () => interopDefault(import('../pages/clientes/index.vue' /* webpackChunkName: "pages/clientes/index" */))
const _032ffddd = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _5e7d6915 = () => interopDefault(import('../pages/competencias/index.vue' /* webpackChunkName: "pages/competencias/index" */))
const _11cb5f2a = () => interopDefault(import('../pages/componentes/index.vue' /* webpackChunkName: "pages/componentes/index" */))
const _6c605b36 = () => interopDefault(import('../pages/comunicaciones/index.vue' /* webpackChunkName: "pages/comunicaciones/index" */))
const _9b7d255e = () => interopDefault(import('../pages/comunicaciones_externas/index.vue' /* webpackChunkName: "pages/comunicaciones_externas/index" */))
const _edb723bc = () => interopDefault(import('../pages/cooperativas/index.vue' /* webpackChunkName: "pages/cooperativas/index" */))
const _0991e294 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0eec279d = () => interopDefault(import('../pages/delegaciones/index.vue' /* webpackChunkName: "pages/delegaciones/index" */))
const _60dafb0f = () => interopDefault(import('../pages/dictamenes/index.vue' /* webpackChunkName: "pages/dictamenes/index" */))
const _1c4ceb55 = () => interopDefault(import('../pages/documentos/index.vue' /* webpackChunkName: "pages/documentos/index" */))
const _a27ab5fc = () => interopDefault(import('../pages/dropdowns/index.vue' /* webpackChunkName: "pages/dropdowns/index" */))
const _0b831e36 = () => interopDefault(import('../pages/emplazamientos/index.vue' /* webpackChunkName: "pages/emplazamientos/index" */))
const _54bd4c77 = () => interopDefault(import('../pages/estaticos/index.vue' /* webpackChunkName: "pages/estaticos/index" */))
const _0efdacc2 = () => interopDefault(import('../pages/etiquetas/index.vue' /* webpackChunkName: "pages/etiquetas/index" */))
const _4c91534c = () => interopDefault(import('../pages/expedientes/index.vue' /* webpackChunkName: "pages/expedientes/index" */))
const _6b2e1250 = () => interopDefault(import('../pages/formaciones/index.vue' /* webpackChunkName: "pages/formaciones/index" */))
const _3d2fca1e = () => interopDefault(import('../pages/formatos/index.vue' /* webpackChunkName: "pages/formatos/index" */))
const _99cd9fa6 = () => interopDefault(import('../pages/generador_formatos/index.vue' /* webpackChunkName: "pages/generador_formatos/index" */))
const _770b087c = () => interopDefault(import('../pages/grupo_solicitudes/index.vue' /* webpackChunkName: "pages/grupo_solicitudes/index" */))
const _32b21400 = () => interopDefault(import('../pages/grupos/index.vue' /* webpackChunkName: "pages/grupos/index" */))
const _1a7b52e5 = () => interopDefault(import('../pages/incompatibilidades/index.vue' /* webpackChunkName: "pages/incompatibilidades/index" */))
const _2c8911ad = () => interopDefault(import('../pages/informes/index.vue' /* webpackChunkName: "pages/informes/index" */))
const _a4f7658e = () => interopDefault(import('../pages/listas_validacion/index.vue' /* webpackChunkName: "pages/listas_validacion/index" */))
const _71be3c52 = () => interopDefault(import('../pages/localidades/index.vue' /* webpackChunkName: "pages/localidades/index" */))
const _680b5ba9 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _56937c4d = () => interopDefault(import('../pages/marcas/index.vue' /* webpackChunkName: "pages/marcas/index" */))
const _1190ed0a = () => interopDefault(import('../pages/mensajes/index.vue' /* webpackChunkName: "pages/mensajes/index" */))
const _47e0e905 = () => interopDefault(import('../pages/metadata/index.vue' /* webpackChunkName: "pages/metadata/index" */))
const _186d3d33 = () => interopDefault(import('../pages/newsboard/index.vue' /* webpackChunkName: "pages/newsboard/index" */))
const _ee38ead8 = () => interopDefault(import('../pages/permisos/index.vue' /* webpackChunkName: "pages/permisos/index" */))
const _53f1227c = () => interopDefault(import('../pages/planificador/index.vue' /* webpackChunkName: "pages/planificador/index" */))
const _800a3834 = () => interopDefault(import('../pages/plantillas/index.vue' /* webpackChunkName: "pages/plantillas/index" */))
const _ac891e7c = () => interopDefault(import('../pages/portal/index.vue' /* webpackChunkName: "pages/portal/index" */))
const _26607bcd = () => interopDefault(import('../pages/portalpacscorrectiva/index.vue' /* webpackChunkName: "pages/portalpacscorrectiva/index" */))
const _4515ee36 = () => interopDefault(import('../pages/presupuestos/index.vue' /* webpackChunkName: "pages/presupuestos/index" */))
const _3c103c5f = () => interopDefault(import('../pages/revisiones/index.vue' /* webpackChunkName: "pages/revisiones/index" */))
const _2ac2e361 = () => interopDefault(import('../pages/rlogs/index.vue' /* webpackChunkName: "pages/rlogs/index" */))
const _9e98db3a = () => interopDefault(import('../pages/selectores/index.vue' /* webpackChunkName: "pages/selectores/index" */))
const _28a088f9 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _06c6fd7a = () => interopDefault(import('../pages/tipos_fichero/index.vue' /* webpackChunkName: "pages/tipos_fichero/index" */))
const _5aae2e19 = () => interopDefault(import('../pages/tramites/index.vue' /* webpackChunkName: "pages/tramites/index" */))
const _38df00bd = () => interopDefault(import('../pages/visitas/index.vue' /* webpackChunkName: "pages/visitas/index" */))
const _0244dbe6 = () => interopDefault(import('../pages/visitas_copy/index.vue' /* webpackChunkName: "pages/visitas_copy/index" */))
const _3adf3000 = () => interopDefault(import('../pages/zonas_visita/index.vue' /* webpackChunkName: "pages/zonas_visita/index" */))
const _6f67d9f8 = () => interopDefault(import('../pages/admin/certificados/index.vue' /* webpackChunkName: "pages/admin/certificados/index" */))
const _18b439d5 = () => interopDefault(import('../pages/admin/documentos/index.vue' /* webpackChunkName: "pages/admin/documentos/index" */))
const _8c6450c0 = () => interopDefault(import('../pages/admin/empresas/index.vue' /* webpackChunkName: "pages/admin/empresas/index" */))
const _73e60acd = () => interopDefault(import('../pages/admin/marcas/index.vue' /* webpackChunkName: "pages/admin/marcas/index" */))
const _b40a40ae = () => interopDefault(import('../pages/admin/news/index.vue' /* webpackChunkName: "pages/admin/news/index" */))
const _141849b4 = () => interopDefault(import('../pages/alcances/crudDialog.vue' /* webpackChunkName: "pages/alcances/crudDialog" */))
const _11d570fc = () => interopDefault(import('../pages/alcances/ficheros/index.vue' /* webpackChunkName: "pages/alcances/ficheros/index" */))
const _6fd6a0ff = () => interopDefault(import('../pages/auditorias/checklists/index.vue' /* webpackChunkName: "pages/auditorias/checklists/index" */))
const _9d780cfc = () => interopDefault(import('../pages/auditorias/old_uuid.vue' /* webpackChunkName: "pages/auditorias/old_uuid" */))
const _572c0e09 = () => interopDefault(import('../pages/auditorias/planes/index.vue' /* webpackChunkName: "pages/auditorias/planes/index" */))
const _50b2764d = () => interopDefault(import('../pages/auditorias/previstas/index.vue' /* webpackChunkName: "pages/auditorias/previstas/index" */))
const _6ce27e8d = () => interopDefault(import('../pages/compartir/details.vue' /* webpackChunkName: "pages/compartir/details" */))
const _3cfa7946 = () => interopDefault(import('../pages/configuraciones/checklists/index.vue' /* webpackChunkName: "pages/configuraciones/checklists/index" */))
const _a434a602 = () => interopDefault(import('../pages/dictamenes/lotes/index.vue' /* webpackChunkName: "pages/dictamenes/lotes/index" */))
const _15e395e5 = () => interopDefault(import('../pages/documentos/Details.vue' /* webpackChunkName: "pages/documentos/Details" */))
const _768e91ff = () => interopDefault(import('../pages/documentos/Sidebar.vue' /* webpackChunkName: "pages/documentos/Sidebar" */))
const _a2d631d6 = () => interopDefault(import('../pages/errors/unauthorized.vue' /* webpackChunkName: "pages/errors/unauthorized" */))
const _55ffc0ae = () => interopDefault(import('../pages/etiquetas/crudDialog.vue' /* webpackChunkName: "pages/etiquetas/crudDialog" */))
const _bdb3740e = () => interopDefault(import('../pages/etiquetas/nueva/index.vue' /* webpackChunkName: "pages/etiquetas/nueva/index" */))
const _d1a78c40 = () => interopDefault(import('../pages/etiquetas/stockBar.vue' /* webpackChunkName: "pages/etiquetas/stockBar" */))
const _488be915 = () => interopDefault(import('../pages/formaciones/categorias/index.vue' /* webpackChunkName: "pages/formaciones/categorias/index" */))
const _05f7c070 = () => interopDefault(import('../pages/formaciones/situacion/index.vue' /* webpackChunkName: "pages/formaciones/situacion/index" */))
const _c5a886fe = () => interopDefault(import('../pages/formatos/plantillas.vue' /* webpackChunkName: "pages/formatos/plantillas" */))
const _a7aa8dfc = () => interopDefault(import('../pages/informes/araporcei/index.vue' /* webpackChunkName: "pages/informes/araporcei/index" */))
const _0587e51e = () => interopDefault(import('../pages/informes/productores/index.vue' /* webpackChunkName: "pages/informes/productores/index" */))
const _6ba4034c = () => interopDefault(import('../pages/mensajes/Details.vue' /* webpackChunkName: "pages/mensajes/Details" */))
const _2ad8fa74 = () => interopDefault(import('../pages/mensajes/Sidebar.vue' /* webpackChunkName: "pages/mensajes/Sidebar" */))
const _7bc33490 = () => interopDefault(import('../pages/personal/agenda.vue' /* webpackChunkName: "pages/personal/agenda" */))
const _7968c9a0 = () => interopDefault(import('../pages/personal/auditores.vue' /* webpackChunkName: "pages/personal/auditores" */))
const _bfb22036 = () => interopDefault(import('../pages/personal/clientes.vue' /* webpackChunkName: "pages/personal/clientes" */))
const _532be854 = () => interopDefault(import('../pages/personal/grupos.vue' /* webpackChunkName: "pages/personal/grupos" */))
const _f55d5888 = () => interopDefault(import('../pages/personal/personal-interno.vue' /* webpackChunkName: "pages/personal/personal-interno" */))
const _dc7c137e = () => interopDefault(import('../pages/personal/profileAuditor.vue' /* webpackChunkName: "pages/personal/profileAuditor" */))
const _2a30fb9e = () => interopDefault(import('../pages/personal/profileBasic.vue' /* webpackChunkName: "pages/personal/profileBasic" */))
const _0d455850 = () => interopDefault(import('../pages/personal/profileClient.vue' /* webpackChunkName: "pages/personal/profileClient" */))
const _6c14b99e = () => interopDefault(import('../pages/personal/usuarios.vue' /* webpackChunkName: "pages/personal/usuarios" */))
const _574aee03 = () => interopDefault(import('../pages/planificador/Content.vue' /* webpackChunkName: "pages/planificador/Content" */))
const _1552c168 = () => interopDefault(import('../pages/planificador/Details.vue' /* webpackChunkName: "pages/planificador/Details" */))
const _56019b66 = () => interopDefault(import('../pages/planificador/Sidebar.vue' /* webpackChunkName: "pages/planificador/Sidebar" */))
const _909dffc0 = () => interopDefault(import('../pages/portal/auditorias/index.vue' /* webpackChunkName: "pages/portal/auditorias/index" */))
const _340c5e66 = () => interopDefault(import('../pages/portal/autoevaluaciones/index.vue' /* webpackChunkName: "pages/portal/autoevaluaciones/index" */))
const _7049ac25 = () => interopDefault(import('../pages/portal/certificados/index.vue' /* webpackChunkName: "pages/portal/certificados/index" */))
const _09d3eecf = () => interopDefault(import('../pages/portal/cooperativas/index.vue' /* webpackChunkName: "pages/portal/cooperativas/index" */))
const _164eb342 = () => interopDefault(import('../pages/portal/documentos/index.vue' /* webpackChunkName: "pages/portal/documentos/index" */))
const _3a603012 = () => interopDefault(import('../pages/portal/etiquetas/index.vue' /* webpackChunkName: "pages/portal/etiquetas/index" */))
const _9d356ffa = () => interopDefault(import('../pages/portal/formaciones/index.vue' /* webpackChunkName: "pages/portal/formaciones/index" */))
const _bb741e4c = () => interopDefault(import('../pages/portal/informes/index.vue' /* webpackChunkName: "pages/portal/informes/index" */))
const _08f53514 = () => interopDefault(import('../pages/portal/instalaciones/index.vue' /* webpackChunkName: "pages/portal/instalaciones/index" */))
const _6e6842ba = () => interopDefault(import('../pages/portal/marcas/index.vue' /* webpackChunkName: "pages/portal/marcas/index" */))
const _7160e08f = () => interopDefault(import('../pages/portal/perfil/index.vue' /* webpackChunkName: "pages/portal/perfil/index" */))
const _3224e545 = () => interopDefault(import('../pages/portal/solicitudes/index.vue' /* webpackChunkName: "pages/portal/solicitudes/index" */))
const _4b06f45d = () => interopDefault(import('../pages/portal/tecnicos/index.vue' /* webpackChunkName: "pages/portal/tecnicos/index" */))
const _2f44f2f8 = () => interopDefault(import('../pages/portal/upload/index.vue' /* webpackChunkName: "pages/portal/upload/index" */))
const _eb4e9b62 = () => interopDefault(import('../pages/revisiones/lotes/index.vue' /* webpackChunkName: "pages/revisiones/lotes/index" */))
const _1fb5eb70 = () => interopDefault(import('../pages/admin/empresas/crudDialog.vue' /* webpackChunkName: "pages/admin/empresas/crudDialog" */))
const _5f4e4449 = () => interopDefault(import('../pages/auditorias/checklists/crudDialog.vue' /* webpackChunkName: "pages/auditorias/checklists/crudDialog" */))
const _1fc9e773 = () => interopDefault(import('../pages/auditorias/checklists/editCrudDialog.vue' /* webpackChunkName: "pages/auditorias/checklists/editCrudDialog" */))
const _fda09d50 = () => interopDefault(import('../pages/auditorias/checklists/indexOld.vue' /* webpackChunkName: "pages/auditorias/checklists/indexOld" */))
const _6c06a7e6 = () => interopDefault(import('../pages/auditorias/checklists/new.vue' /* webpackChunkName: "pages/auditorias/checklists/new" */))
const _33a20627 = () => interopDefault(import('../pages/auditorias/widgets/checklist.vue' /* webpackChunkName: "pages/auditorias/widgets/checklist" */))
const _ba3faca0 = () => interopDefault(import('../pages/auditorias/widgets/pacs.vue' /* webpackChunkName: "pages/auditorias/widgets/pacs" */))
const _5d2aad12 = () => interopDefault(import('../pages/auditorias/widgets/toma_muestras.vue' /* webpackChunkName: "pages/auditorias/widgets/toma_muestras" */))
const _037307b0 = () => interopDefault(import('../pages/bloques/edit/simpletableBlockEditor.vue' /* webpackChunkName: "pages/bloques/edit/simpletableBlockEditor" */))
const _bee03614 = () => interopDefault(import('../pages/bloques/edit/tableBlockEditor.vue' /* webpackChunkName: "pages/bloques/edit/tableBlockEditor" */))
const _653fe8d2 = () => interopDefault(import('../pages/bloques/widgets/blockEmbed.vue' /* webpackChunkName: "pages/bloques/widgets/blockEmbed" */))
const _210e3b1e = () => interopDefault(import('../pages/bloques/widgets/crudDialog.vue' /* webpackChunkName: "pages/bloques/widgets/crudDialog" */))
const _e5d80430 = () => interopDefault(import('../pages/configuraciones/checklists/categorias/index.vue' /* webpackChunkName: "pages/configuraciones/checklists/categorias/index" */))
const _836226aa = () => interopDefault(import('../pages/configuraciones/checklists/crudDialog.vue' /* webpackChunkName: "pages/configuraciones/checklists/crudDialog" */))
const _439b8ed5 = () => interopDefault(import('../pages/configuraciones/checklists/editCrudDialog.vue' /* webpackChunkName: "pages/configuraciones/checklists/editCrudDialog" */))
const _bb2c642a = () => interopDefault(import('../pages/configuraciones/checklists/new.vue' /* webpackChunkName: "pages/configuraciones/checklists/new" */))
const _7f87e7b2 = () => interopDefault(import('../pages/dictamenes/lotes/form.vue' /* webpackChunkName: "pages/dictamenes/lotes/form" */))
const _a1722cba = () => interopDefault(import('../pages/etiquetas/nueva/serie.vue' /* webpackChunkName: "pages/etiquetas/nueva/serie" */))
const _43e85f63 = () => interopDefault(import('../pages/informes/ccl/controles.vue' /* webpackChunkName: "pages/informes/ccl/controles" */))
const _7e4f445e = () => interopDefault(import('../pages/informes/ccl/informe_industrias/index.vue' /* webpackChunkName: "pages/informes/ccl/informe_industrias/index" */))
const _8858e8c0 = () => interopDefault(import('../pages/informes/ccl/informe_operadores/index.vue' /* webpackChunkName: "pages/informes/ccl/informe_operadores/index" */))
const _73e3184a = () => interopDefault(import('../pages/informes/ccl/informe_superficies/index.vue' /* webpackChunkName: "pages/informes/ccl/informe_superficies/index" */))
const _106588e8 = () => interopDefault(import('../pages/informes/ccl/no_conformidades.vue' /* webpackChunkName: "pages/informes/ccl/no_conformidades" */))
const _6cde5926 = () => interopDefault(import('../pages/informes/coplaca/dictamenes.vue' /* webpackChunkName: "pages/informes/coplaca/dictamenes" */))
const _69d2e68e = () => interopDefault(import('../pages/informes/coplaca/pacs.vue' /* webpackChunkName: "pages/informes/coplaca/pacs" */))
const _0ce502e8 = () => interopDefault(import('../pages/informes/coplaca/parcelas.vue' /* webpackChunkName: "pages/informes/coplaca/parcelas" */))
const _c8e774dc = () => interopDefault(import('../pages/informes/coplaca/situacion.vue' /* webpackChunkName: "pages/informes/coplaca/situacion" */))
const _53ed9dd4 = () => interopDefault(import('../pages/informes/quesomanchego/control_etiquetas.vue' /* webpackChunkName: "pages/informes/quesomanchego/control_etiquetas" */))
const _1e142de3 = () => interopDefault(import('../pages/informes/quesomanchego/incumplimientos_trimestrales.vue' /* webpackChunkName: "pages/informes/quesomanchego/incumplimientos_trimestrales" */))
const _ad41d354 = () => interopDefault(import('../pages/informes/quesomanchego/operadores_certificados.vue' /* webpackChunkName: "pages/informes/quesomanchego/operadores_certificados" */))
const _ad7c325e = () => interopDefault(import('../pages/localidades/widgets/crudDialog.vue' /* webpackChunkName: "pages/localidades/widgets/crudDialog" */))
const _06df7a63 = () => interopDefault(import('../pages/metadata/widgets/crudDialog.vue' /* webpackChunkName: "pages/metadata/widgets/crudDialog" */))
const _49f27cc4 = () => interopDefault(import('../pages/personal/edit/tabHistorico.vue' /* webpackChunkName: "pages/personal/edit/tabHistorico" */))
const _e9dc30dc = () => interopDefault(import('../pages/personal/widgets/contactAltaBaja.vue' /* webpackChunkName: "pages/personal/widgets/contactAltaBaja" */))
const _6de713b2 = () => interopDefault(import('../pages/personal/widgets/moduleAdministracion.vue' /* webpackChunkName: "pages/personal/widgets/moduleAdministracion" */))
const _0fb7f487 = () => interopDefault(import('../pages/personal/widgets/moduleCalificaciones.vue' /* webpackChunkName: "pages/personal/widgets/moduleCalificaciones" */))
const _2181fcd7 = () => interopDefault(import('../pages/personal/widgets/moduleCompetencias.vue' /* webpackChunkName: "pages/personal/widgets/moduleCompetencias" */))
const _2e15fd9c = () => interopDefault(import('../pages/personal/widgets/moduleContactos.vue' /* webpackChunkName: "pages/personal/widgets/moduleContactos" */))
const _2e56c752 = () => interopDefault(import('../pages/personal/widgets/moduleDocumentos.vue' /* webpackChunkName: "pages/personal/widgets/moduleDocumentos" */))
const _01a6e624 = () => interopDefault(import('../pages/personal/widgets/moduleExpedientes.vue' /* webpackChunkName: "pages/personal/widgets/moduleExpedientes" */))
const _64b765c7 = () => interopDefault(import('../pages/personal/widgets/moduleHistorial.vue' /* webpackChunkName: "pages/personal/widgets/moduleHistorial" */))
const _2990aaa7 = () => interopDefault(import('../pages/personal/widgets/moduleImportaciones.vue' /* webpackChunkName: "pages/personal/widgets/moduleImportaciones" */))
const _35101ae7 = () => interopDefault(import('../pages/personal/widgets/moduleIncompatibilidades.vue' /* webpackChunkName: "pages/personal/widgets/moduleIncompatibilidades" */))
const _6dc91ddb = () => interopDefault(import('../pages/personal/widgets/moduleInstalaciones.vue' /* webpackChunkName: "pages/personal/widgets/moduleInstalaciones" */))
const _86f5dc62 = () => interopDefault(import('../pages/personal/widgets/moduleMarcas.vue' /* webpackChunkName: "pages/personal/widgets/moduleMarcas" */))
const _daf3d7d4 = () => interopDefault(import('../pages/personal/widgets/modulePermisos.vue' /* webpackChunkName: "pages/personal/widgets/modulePermisos" */))
const _b240789c = () => interopDefault(import('../pages/personal/widgets/moduleTecnicos.vue' /* webpackChunkName: "pages/personal/widgets/moduleTecnicos" */))
const _1c85836d = () => interopDefault(import('../pages/personal/widgets/quickCreate.vue' /* webpackChunkName: "pages/personal/widgets/quickCreate" */))
const _36487a54 = () => interopDefault(import('../pages/personal/widgets/quickView.vue' /* webpackChunkName: "pages/personal/widgets/quickView" */))
const _64eec214 = () => interopDefault(import('../pages/planificador/widgets/editAuditoria.vue' /* webpackChunkName: "pages/planificador/widgets/editAuditoria" */))
const _47831669 = () => interopDefault(import('../pages/portal/etiquetas/nueva.vue' /* webpackChunkName: "pages/portal/etiquetas/nueva" */))
const _21daf48b = () => interopDefault(import('../pages/portal/instalaciones/create/index.vue' /* webpackChunkName: "pages/portal/instalaciones/create/index" */))
const _6aac21a0 = () => interopDefault(import('../pages/portal/perfil/edit/index.vue' /* webpackChunkName: "pages/portal/perfil/edit/index" */))
const _829c4448 = () => interopDefault(import('../pages/portal/solicitudes/create/index.vue' /* webpackChunkName: "pages/portal/solicitudes/create/index" */))
const _324d23c4 = () => interopDefault(import('../pages/portal/tecnicos/create/index.vue' /* webpackChunkName: "pages/portal/tecnicos/create/index" */))
const _11ab1cd7 = () => interopDefault(import('../pages/revisiones/lotes/form.vue' /* webpackChunkName: "pages/revisiones/lotes/form" */))
const _33ca7b32 = () => interopDefault(import('../pages/auditorias/agrocolor/global_gap/cultivos.vue' /* webpackChunkName: "pages/auditorias/agrocolor/global_gap/cultivos" */))
const _766d412e = () => interopDefault(import('../pages/auditorias/agrocolor/global/cultivos.vue' /* webpackChunkName: "pages/auditorias/agrocolor/global/cultivos" */))
const _e8b8de00 = () => interopDefault(import('../pages/configuraciones/checklists/categorias/crudDialog.vue' /* webpackChunkName: "pages/configuraciones/checklists/categorias/crudDialog" */))
const _52ac00aa = () => interopDefault(import('../pages/configuraciones/checklists/categorias/editCrudDialog.vue' /* webpackChunkName: "pages/configuraciones/checklists/categorias/editCrudDialog" */))
const _f4e3a194 = () => interopDefault(import('../pages/configuraciones/checklists/categorias/new.vue' /* webpackChunkName: "pages/configuraciones/checklists/categorias/new" */))
const _2460fb3e = () => interopDefault(import('../pages/informes/ccl/informe_operadores/operadores_baja.vue' /* webpackChunkName: "pages/informes/ccl/informe_operadores/operadores_baja" */))
const _40a0e326 = () => interopDefault(import('../pages/portal/auditorias/pacs/accionCorrectiva.vue' /* webpackChunkName: "pages/portal/auditorias/pacs/accionCorrectiva" */))
const _25bc7ab4 = () => interopDefault(import('../pages/portal/informes/coplaca/auditoria.vue' /* webpackChunkName: "pages/portal/informes/coplaca/auditoria" */))
const _02f0d59b = () => interopDefault(import('../pages/portal/informes/coplaca/parcelas.vue' /* webpackChunkName: "pages/portal/informes/coplaca/parcelas" */))
const _030f868f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _aaf3b7e2 = () => interopDefault(import('../pages/auditorias/tabs/checklist/_uuid.vue' /* webpackChunkName: "pages/auditorias/tabs/checklist/_uuid" */))
const _4797a423 = () => interopDefault(import('../pages/auditorias/tabs/muestras/_uuid.vue' /* webpackChunkName: "pages/auditorias/tabs/muestras/_uuid" */))
const _5a5af604 = () => interopDefault(import('../pages/auditorias/tabs/pacs/_uuid.vue' /* webpackChunkName: "pages/auditorias/tabs/pacs/_uuid" */))
const _22935db0 = () => interopDefault(import('../pages/configuraciones/checklists/categorias/_uuid.vue' /* webpackChunkName: "pages/configuraciones/checklists/categorias/_uuid" */))
const _4f4d3768 = () => interopDefault(import('../pages/admin/empresas/_uuid.vue' /* webpackChunkName: "pages/admin/empresas/_uuid" */))
const _09656a95 = () => interopDefault(import('../pages/admin/marcas/_uuid.vue' /* webpackChunkName: "pages/admin/marcas/_uuid" */))
const _b1565e78 = () => interopDefault(import('../pages/alcances/ficheros/_uuid.vue' /* webpackChunkName: "pages/alcances/ficheros/_uuid" */))
const _055600c7 = () => interopDefault(import('../pages/auditorias/checklists/_uuid.vue' /* webpackChunkName: "pages/auditorias/checklists/_uuid" */))
const _9a4a46e0 = () => interopDefault(import('../pages/auditorias/checklists/_uuidOld.vue' /* webpackChunkName: "pages/auditorias/checklists/_uuidOld" */))
const _26a9245e = () => interopDefault(import('../pages/auditorias/planes/_uuid.vue' /* webpackChunkName: "pages/auditorias/planes/_uuid" */))
const _cb44ae80 = () => interopDefault(import('../pages/bloques/edit/_uuid.vue' /* webpackChunkName: "pages/bloques/edit/_uuid" */))
const _77022325 = () => interopDefault(import('../pages/configuraciones/checklists/_uuid.vue' /* webpackChunkName: "pages/configuraciones/checklists/_uuid" */))
const _43650cc7 = () => interopDefault(import('../pages/dictamenes/lotes/_uuid.vue' /* webpackChunkName: "pages/dictamenes/lotes/_uuid" */))
const _39d9295f = () => interopDefault(import('../pages/expedientes/iteracion/_expediente.vue' /* webpackChunkName: "pages/expedientes/iteracion/_expediente" */))
const _c9f17634 = () => interopDefault(import('../pages/informes/productores/_uuid.vue' /* webpackChunkName: "pages/informes/productores/_uuid" */))
const _3ef40381 = () => interopDefault(import('../pages/personal/edit/_uuid.vue' /* webpackChunkName: "pages/personal/edit/_uuid" */))
const _4d305fe8 = () => interopDefault(import('../pages/portal/auditorias/_uuid.vue' /* webpackChunkName: "pages/portal/auditorias/_uuid" */))
const _7b793095 = () => interopDefault(import('../pages/portal/autoevaluaciones/_uuid.vue' /* webpackChunkName: "pages/portal/autoevaluaciones/_uuid" */))
const _c15962d2 = () => interopDefault(import('../pages/portal/cooperativas/_uuid.vue' /* webpackChunkName: "pages/portal/cooperativas/_uuid" */))
const _46e4a7cb = () => interopDefault(import('../pages/portal/formaciones/_uuid.vue' /* webpackChunkName: "pages/portal/formaciones/_uuid" */))
const _03e7a282 = () => interopDefault(import('../pages/portal/marcas/_uuid.vue' /* webpackChunkName: "pages/portal/marcas/_uuid" */))
const _70b775e6 = () => interopDefault(import('../pages/portal/solicitudes/_uuid.vue' /* webpackChunkName: "pages/portal/solicitudes/_uuid" */))
const _2aadf92d = () => interopDefault(import('../pages/public/documentos/_uuid.vue' /* webpackChunkName: "pages/public/documentos/_uuid" */))
const _3d7d6cc9 = () => interopDefault(import('../pages/public/formatos/_uuid.vue' /* webpackChunkName: "pages/public/formatos/_uuid" */))
const _1fd81217 = () => interopDefault(import('../pages/revisiones/lotes/_uuid.vue' /* webpackChunkName: "pages/revisiones/lotes/_uuid" */))
const _d64a586c = () => interopDefault(import('../pages/alcances/_uuid.vue' /* webpackChunkName: "pages/alcances/_uuid" */))
const _36979df4 = () => interopDefault(import('../pages/auditores/_uuid.vue' /* webpackChunkName: "pages/auditores/_uuid" */))
const _532e97fb = () => interopDefault(import('../pages/auditorias/_uuid.vue' /* webpackChunkName: "pages/auditorias/_uuid" */))
const _4ace3c02 = () => interopDefault(import('../pages/bloques/_uuid.vue' /* webpackChunkName: "pages/bloques/_uuid" */))
const _5327693a = () => interopDefault(import('../pages/cargas/_uuid.vue' /* webpackChunkName: "pages/cargas/_uuid" */))
const _f5cce980 = () => interopDefault(import('../pages/certificados/_uuid.vue' /* webpackChunkName: "pages/certificados/_uuid" */))
const _b4ea3a52 = () => interopDefault(import('../pages/clientes/_uuid.vue' /* webpackChunkName: "pages/clientes/_uuid" */))
const _cea144b6 = () => interopDefault(import('../pages/compartir/_uuid.vue' /* webpackChunkName: "pages/compartir/_uuid" */))
const _18066e46 = () => interopDefault(import('../pages/competencias/_uuid.vue' /* webpackChunkName: "pages/competencias/_uuid" */))
const _e6cc9f9a = () => interopDefault(import('../pages/componentes/_uuid.vue' /* webpackChunkName: "pages/componentes/_uuid" */))
const _b728f136 = () => interopDefault(import('../pages/delegaciones/_uuid.vue' /* webpackChunkName: "pages/delegaciones/_uuid" */))
const _134b4a52 = () => interopDefault(import('../pages/dictamenes/_uuid.vue' /* webpackChunkName: "pages/dictamenes/_uuid" */))
const _444204ca = () => interopDefault(import('../pages/dropdowns/_uuid.vue' /* webpackChunkName: "pages/dropdowns/_uuid" */))
const _e0845ea6 = () => interopDefault(import('../pages/emplazamientos/_uuid.vue' /* webpackChunkName: "pages/emplazamientos/_uuid" */))
const _2b86a782 = () => interopDefault(import('../pages/estaticos/_uuid.vue' /* webpackChunkName: "pages/estaticos/_uuid" */))
const _3bde99d8 = () => interopDefault(import('../pages/expedientes/_uuid.vue' /* webpackChunkName: "pages/expedientes/_uuid" */))
const _00ad7218 = () => interopDefault(import('../pages/formaciones/_uuid.vue' /* webpackChunkName: "pages/formaciones/_uuid" */))
const _76e77ab9 = () => interopDefault(import('../pages/formatos/_uuid.vue' /* webpackChunkName: "pages/formatos/_uuid" */))
const _0c8a6844 = () => interopDefault(import('../pages/grupo_solicitudes/_uuid.vue' /* webpackChunkName: "pages/grupo_solicitudes/_uuid" */))
const _6f9d1870 = () => interopDefault(import('../pages/grupos/_uuid.vue' /* webpackChunkName: "pages/grupos/_uuid" */))
const _a00a9aa6 = () => interopDefault(import('../pages/incompatibilidades/_uuid.vue' /* webpackChunkName: "pages/incompatibilidades/_uuid" */))
const _4303ad01 = () => interopDefault(import('../pages/listas_validacion/_uuid.vue' /* webpackChunkName: "pages/listas_validacion/_uuid" */))
const _27da47d6 = () => interopDefault(import('../pages/marcas/_uuid.vue' /* webpackChunkName: "pages/marcas/_uuid" */))
const _453f6e66 = () => interopDefault(import('../pages/metadata/_uuid.vue' /* webpackChunkName: "pages/metadata/_uuid" */))
const _5e33129e = () => interopDefault(import('../pages/personal/_uuid.vue' /* webpackChunkName: "pages/personal/_uuid" */))
const _557a43ae = () => interopDefault(import('../pages/plantillas/_uuid.vue' /* webpackChunkName: "pages/plantillas/_uuid" */))
const _4ad56404 = () => interopDefault(import('../pages/presupuestos/_uuid.vue' /* webpackChunkName: "pages/presupuestos/_uuid" */))
const _5ce0c7b2 = () => interopDefault(import('../pages/revisiones/_uuid.vue' /* webpackChunkName: "pages/revisiones/_uuid" */))
const _4632f22b = () => interopDefault(import('../pages/selectores/_uuid.vue' /* webpackChunkName: "pages/selectores/_uuid" */))
const _c773457c = () => interopDefault(import('../pages/tipos_fichero/_uuid.vue' /* webpackChunkName: "pages/tipos_fichero/_uuid" */))
const _1fa4e43e = () => interopDefault(import('../pages/tramites/_uuid.vue' /* webpackChunkName: "pages/tramites/_uuid" */))
const _780fc7c8 = () => interopDefault(import('../pages/zonas_visita/_uuid.vue' /* webpackChunkName: "pages/zonas_visita/_uuid" */))
const _157402d6 = () => interopDefault(import('../pages/alcances/_uuid_alcance/subalcances/crudDialog.vue' /* webpackChunkName: "pages/alcances/_uuid_alcance/subalcances/crudDialog" */))
const _236c9e1a = () => interopDefault(import('../pages/alcances/_uuid_alcance/subalcances/_uuid.vue' /* webpackChunkName: "pages/alcances/_uuid_alcance/subalcances/_uuid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alcances",
    component: _014917fc,
    name: "alcances"
  }, {
    path: "/auditores",
    component: _bdcf83a8,
    name: "auditores"
  }, {
    path: "/auditorias",
    component: _84a18f9a,
    name: "auditorias"
  }, {
    path: "/backups",
    component: _66fb5ff1,
    name: "backups"
  }, {
    path: "/bloques",
    component: _45198237,
    name: "bloques"
  }, {
    path: "/cargas",
    component: _40eceb9b,
    name: "cargas"
  }, {
    path: "/certificados",
    component: _20cba910,
    name: "certificados"
  }, {
    path: "/clientes",
    component: _100b830f,
    name: "clientes"
  }, {
    path: "/compartir",
    component: _032ffddd,
    name: "compartir"
  }, {
    path: "/competencias",
    component: _5e7d6915,
    name: "competencias"
  }, {
    path: "/componentes",
    component: _11cb5f2a,
    name: "componentes"
  }, {
    path: "/comunicaciones",
    component: _6c605b36,
    name: "comunicaciones"
  }, {
    path: "/comunicaciones_externas",
    component: _9b7d255e,
    name: "comunicaciones_externas"
  }, {
    path: "/cooperativas",
    component: _edb723bc,
    name: "cooperativas"
  }, {
    path: "/dashboard",
    component: _0991e294,
    name: "dashboard"
  }, {
    path: "/delegaciones",
    component: _0eec279d,
    name: "delegaciones"
  }, {
    path: "/dictamenes",
    component: _60dafb0f,
    name: "dictamenes"
  }, {
    path: "/documentos",
    component: _1c4ceb55,
    name: "documentos"
  }, {
    path: "/dropdowns",
    component: _a27ab5fc,
    name: "dropdowns"
  }, {
    path: "/emplazamientos",
    component: _0b831e36,
    name: "emplazamientos"
  }, {
    path: "/estaticos",
    component: _54bd4c77,
    name: "estaticos"
  }, {
    path: "/etiquetas",
    component: _0efdacc2,
    name: "etiquetas"
  }, {
    path: "/expedientes",
    component: _4c91534c,
    name: "expedientes"
  }, {
    path: "/formaciones",
    component: _6b2e1250,
    name: "formaciones"
  }, {
    path: "/formatos",
    component: _3d2fca1e,
    name: "formatos"
  }, {
    path: "/generador_formatos",
    component: _99cd9fa6,
    name: "generador_formatos"
  }, {
    path: "/grupo_solicitudes",
    component: _770b087c,
    name: "grupo_solicitudes"
  }, {
    path: "/grupos",
    component: _32b21400,
    name: "grupos"
  }, {
    path: "/incompatibilidades",
    component: _1a7b52e5,
    name: "incompatibilidades"
  }, {
    path: "/informes",
    component: _2c8911ad,
    name: "informes"
  }, {
    path: "/listas_validacion",
    component: _a4f7658e,
    name: "listas_validacion"
  }, {
    path: "/localidades",
    component: _71be3c52,
    name: "localidades"
  }, {
    path: "/login",
    component: _680b5ba9,
    name: "login"
  }, {
    path: "/marcas",
    component: _56937c4d,
    name: "marcas"
  }, {
    path: "/mensajes",
    component: _1190ed0a,
    name: "mensajes"
  }, {
    path: "/metadata",
    component: _47e0e905,
    name: "metadata"
  }, {
    path: "/newsboard",
    component: _186d3d33,
    name: "newsboard"
  }, {
    path: "/permisos",
    component: _ee38ead8,
    name: "permisos"
  }, {
    path: "/planificador",
    component: _53f1227c,
    name: "planificador"
  }, {
    path: "/plantillas",
    component: _800a3834,
    name: "plantillas"
  }, {
    path: "/portal",
    component: _ac891e7c,
    name: "portal"
  }, {
    path: "/portalpacscorrectiva",
    component: _26607bcd,
    name: "portalpacscorrectiva"
  }, {
    path: "/presupuestos",
    component: _4515ee36,
    name: "presupuestos"
  }, {
    path: "/revisiones",
    component: _3c103c5f,
    name: "revisiones"
  }, {
    path: "/rlogs",
    component: _2ac2e361,
    name: "rlogs"
  }, {
    path: "/selectores",
    component: _9e98db3a,
    name: "selectores"
  }, {
    path: "/settings",
    component: _28a088f9,
    name: "settings"
  }, {
    path: "/tipos_fichero",
    component: _06c6fd7a,
    name: "tipos_fichero"
  }, {
    path: "/tramites",
    component: _5aae2e19,
    name: "tramites"
  }, {
    path: "/visitas",
    component: _38df00bd,
    name: "visitas"
  }, {
    path: "/visitas_copy",
    component: _0244dbe6,
    name: "visitas_copy"
  }, {
    path: "/zonas_visita",
    component: _3adf3000,
    name: "zonas_visita"
  }, {
    path: "/admin/certificados",
    component: _6f67d9f8,
    name: "admin-certificados"
  }, {
    path: "/admin/documentos",
    component: _18b439d5,
    name: "admin-documentos"
  }, {
    path: "/admin/empresas",
    component: _8c6450c0,
    name: "admin-empresas"
  }, {
    path: "/admin/marcas",
    component: _73e60acd,
    name: "admin-marcas"
  }, {
    path: "/admin/news",
    component: _b40a40ae,
    name: "admin-news"
  }, {
    path: "/alcances/crudDialog",
    component: _141849b4,
    name: "alcances-crudDialog"
  }, {
    path: "/alcances/ficheros",
    component: _11d570fc,
    name: "alcances-ficheros"
  }, {
    path: "/auditorias/checklists",
    component: _6fd6a0ff,
    name: "auditorias-checklists"
  }, {
    path: "/auditorias/old_uuid",
    component: _9d780cfc,
    name: "auditorias-old_uuid"
  }, {
    path: "/auditorias/planes",
    component: _572c0e09,
    name: "auditorias-planes"
  }, {
    path: "/auditorias/previstas",
    component: _50b2764d,
    name: "auditorias-previstas"
  }, {
    path: "/compartir/details",
    component: _6ce27e8d,
    name: "compartir-details"
  }, {
    path: "/configuraciones/checklists",
    component: _3cfa7946,
    name: "configuraciones-checklists"
  }, {
    path: "/dictamenes/lotes",
    component: _a434a602,
    name: "dictamenes-lotes"
  }, {
    path: "/documentos/Details",
    component: _15e395e5,
    name: "documentos-Details"
  }, {
    path: "/documentos/Sidebar",
    component: _768e91ff,
    name: "documentos-Sidebar"
  }, {
    path: "/errors/unauthorized",
    component: _a2d631d6,
    name: "errors-unauthorized"
  }, {
    path: "/etiquetas/crudDialog",
    component: _55ffc0ae,
    name: "etiquetas-crudDialog"
  }, {
    path: "/etiquetas/nueva",
    component: _bdb3740e,
    name: "etiquetas-nueva"
  }, {
    path: "/etiquetas/stockBar",
    component: _d1a78c40,
    name: "etiquetas-stockBar"
  }, {
    path: "/formaciones/categorias",
    component: _488be915,
    name: "formaciones-categorias"
  }, {
    path: "/formaciones/situacion",
    component: _05f7c070,
    name: "formaciones-situacion"
  }, {
    path: "/formatos/plantillas",
    component: _c5a886fe,
    name: "formatos-plantillas"
  }, {
    path: "/informes/araporcei",
    component: _a7aa8dfc,
    name: "informes-araporcei"
  }, {
    path: "/informes/productores",
    component: _0587e51e,
    name: "informes-productores"
  }, {
    path: "/mensajes/Details",
    component: _6ba4034c,
    name: "mensajes-Details"
  }, {
    path: "/mensajes/Sidebar",
    component: _2ad8fa74,
    name: "mensajes-Sidebar"
  }, {
    path: "/personal/agenda",
    component: _7bc33490,
    name: "personal-agenda"
  }, {
    path: "/personal/auditores",
    component: _7968c9a0,
    name: "personal-auditores"
  }, {
    path: "/personal/clientes",
    component: _bfb22036,
    name: "personal-clientes"
  }, {
    path: "/personal/grupos",
    component: _532be854,
    name: "personal-grupos"
  }, {
    path: "/personal/personal-interno",
    component: _f55d5888,
    name: "personal-personal-interno"
  }, {
    path: "/personal/profileAuditor",
    component: _dc7c137e,
    name: "personal-profileAuditor"
  }, {
    path: "/personal/profileBasic",
    component: _2a30fb9e,
    name: "personal-profileBasic"
  }, {
    path: "/personal/profileClient",
    component: _0d455850,
    name: "personal-profileClient"
  }, {
    path: "/personal/usuarios",
    component: _6c14b99e,
    name: "personal-usuarios"
  }, {
    path: "/planificador/Content",
    component: _574aee03,
    name: "planificador-Content"
  }, {
    path: "/planificador/Details",
    component: _1552c168,
    name: "planificador-Details"
  }, {
    path: "/planificador/Sidebar",
    component: _56019b66,
    name: "planificador-Sidebar"
  }, {
    path: "/portal/auditorias",
    component: _909dffc0,
    name: "portal-auditorias"
  }, {
    path: "/portal/autoevaluaciones",
    component: _340c5e66,
    name: "portal-autoevaluaciones"
  }, {
    path: "/portal/certificados",
    component: _7049ac25,
    name: "portal-certificados"
  }, {
    path: "/portal/cooperativas",
    component: _09d3eecf,
    name: "portal-cooperativas"
  }, {
    path: "/portal/documentos",
    component: _164eb342,
    name: "portal-documentos"
  }, {
    path: "/portal/etiquetas",
    component: _3a603012,
    name: "portal-etiquetas"
  }, {
    path: "/portal/formaciones",
    component: _9d356ffa,
    name: "portal-formaciones"
  }, {
    path: "/portal/informes",
    component: _bb741e4c,
    name: "portal-informes"
  }, {
    path: "/portal/instalaciones",
    component: _08f53514,
    name: "portal-instalaciones"
  }, {
    path: "/portal/marcas",
    component: _6e6842ba,
    name: "portal-marcas"
  }, {
    path: "/portal/perfil",
    component: _7160e08f,
    name: "portal-perfil"
  }, {
    path: "/portal/solicitudes",
    component: _3224e545,
    name: "portal-solicitudes"
  }, {
    path: "/portal/tecnicos",
    component: _4b06f45d,
    name: "portal-tecnicos"
  }, {
    path: "/portal/upload",
    component: _2f44f2f8,
    name: "portal-upload"
  }, {
    path: "/revisiones/lotes",
    component: _eb4e9b62,
    name: "revisiones-lotes"
  }, {
    path: "/admin/empresas/crudDialog",
    component: _1fb5eb70,
    name: "admin-empresas-crudDialog"
  }, {
    path: "/auditorias/checklists/crudDialog",
    component: _5f4e4449,
    name: "auditorias-checklists-crudDialog"
  }, {
    path: "/auditorias/checklists/editCrudDialog",
    component: _1fc9e773,
    name: "auditorias-checklists-editCrudDialog"
  }, {
    path: "/auditorias/checklists/indexOld",
    component: _fda09d50,
    name: "auditorias-checklists-indexOld"
  }, {
    path: "/auditorias/checklists/new",
    component: _6c06a7e6,
    name: "auditorias-checklists-new"
  }, {
    path: "/auditorias/widgets/checklist",
    component: _33a20627,
    name: "auditorias-widgets-checklist"
  }, {
    path: "/auditorias/widgets/pacs",
    component: _ba3faca0,
    name: "auditorias-widgets-pacs"
  }, {
    path: "/auditorias/widgets/toma_muestras",
    component: _5d2aad12,
    name: "auditorias-widgets-toma_muestras"
  }, {
    path: "/bloques/edit/simpletableBlockEditor",
    component: _037307b0,
    name: "bloques-edit-simpletableBlockEditor"
  }, {
    path: "/bloques/edit/tableBlockEditor",
    component: _bee03614,
    name: "bloques-edit-tableBlockEditor"
  }, {
    path: "/bloques/widgets/blockEmbed",
    component: _653fe8d2,
    name: "bloques-widgets-blockEmbed"
  }, {
    path: "/bloques/widgets/crudDialog",
    component: _210e3b1e,
    name: "bloques-widgets-crudDialog"
  }, {
    path: "/configuraciones/checklists/categorias",
    component: _e5d80430,
    name: "configuraciones-checklists-categorias"
  }, {
    path: "/configuraciones/checklists/crudDialog",
    component: _836226aa,
    name: "configuraciones-checklists-crudDialog"
  }, {
    path: "/configuraciones/checklists/editCrudDialog",
    component: _439b8ed5,
    name: "configuraciones-checklists-editCrudDialog"
  }, {
    path: "/configuraciones/checklists/new",
    component: _bb2c642a,
    name: "configuraciones-checklists-new"
  }, {
    path: "/dictamenes/lotes/form",
    component: _7f87e7b2,
    name: "dictamenes-lotes-form"
  }, {
    path: "/etiquetas/nueva/serie",
    component: _a1722cba,
    name: "etiquetas-nueva-serie"
  }, {
    path: "/informes/ccl/controles",
    component: _43e85f63,
    name: "informes-ccl-controles"
  }, {
    path: "/informes/ccl/informe_industrias",
    component: _7e4f445e,
    name: "informes-ccl-informe_industrias"
  }, {
    path: "/informes/ccl/informe_operadores",
    component: _8858e8c0,
    name: "informes-ccl-informe_operadores"
  }, {
    path: "/informes/ccl/informe_superficies",
    component: _73e3184a,
    name: "informes-ccl-informe_superficies"
  }, {
    path: "/informes/ccl/no_conformidades",
    component: _106588e8,
    name: "informes-ccl-no_conformidades"
  }, {
    path: "/informes/coplaca/dictamenes",
    component: _6cde5926,
    name: "informes-coplaca-dictamenes"
  }, {
    path: "/informes/coplaca/pacs",
    component: _69d2e68e,
    name: "informes-coplaca-pacs"
  }, {
    path: "/informes/coplaca/parcelas",
    component: _0ce502e8,
    name: "informes-coplaca-parcelas"
  }, {
    path: "/informes/coplaca/situacion",
    component: _c8e774dc,
    name: "informes-coplaca-situacion"
  }, {
    path: "/informes/quesomanchego/control_etiquetas",
    component: _53ed9dd4,
    name: "informes-quesomanchego-control_etiquetas"
  }, {
    path: "/informes/quesomanchego/incumplimientos_trimestrales",
    component: _1e142de3,
    name: "informes-quesomanchego-incumplimientos_trimestrales"
  }, {
    path: "/informes/quesomanchego/operadores_certificados",
    component: _ad41d354,
    name: "informes-quesomanchego-operadores_certificados"
  }, {
    path: "/localidades/widgets/crudDialog",
    component: _ad7c325e,
    name: "localidades-widgets-crudDialog"
  }, {
    path: "/metadata/widgets/crudDialog",
    component: _06df7a63,
    name: "metadata-widgets-crudDialog"
  }, {
    path: "/personal/edit/tabHistorico",
    component: _49f27cc4,
    name: "personal-edit-tabHistorico"
  }, {
    path: "/personal/widgets/contactAltaBaja",
    component: _e9dc30dc,
    name: "personal-widgets-contactAltaBaja"
  }, {
    path: "/personal/widgets/moduleAdministracion",
    component: _6de713b2,
    name: "personal-widgets-moduleAdministracion"
  }, {
    path: "/personal/widgets/moduleCalificaciones",
    component: _0fb7f487,
    name: "personal-widgets-moduleCalificaciones"
  }, {
    path: "/personal/widgets/moduleCompetencias",
    component: _2181fcd7,
    name: "personal-widgets-moduleCompetencias"
  }, {
    path: "/personal/widgets/moduleContactos",
    component: _2e15fd9c,
    name: "personal-widgets-moduleContactos"
  }, {
    path: "/personal/widgets/moduleDocumentos",
    component: _2e56c752,
    name: "personal-widgets-moduleDocumentos"
  }, {
    path: "/personal/widgets/moduleExpedientes",
    component: _01a6e624,
    name: "personal-widgets-moduleExpedientes"
  }, {
    path: "/personal/widgets/moduleHistorial",
    component: _64b765c7,
    name: "personal-widgets-moduleHistorial"
  }, {
    path: "/personal/widgets/moduleImportaciones",
    component: _2990aaa7,
    name: "personal-widgets-moduleImportaciones"
  }, {
    path: "/personal/widgets/moduleIncompatibilidades",
    component: _35101ae7,
    name: "personal-widgets-moduleIncompatibilidades"
  }, {
    path: "/personal/widgets/moduleInstalaciones",
    component: _6dc91ddb,
    name: "personal-widgets-moduleInstalaciones"
  }, {
    path: "/personal/widgets/moduleMarcas",
    component: _86f5dc62,
    name: "personal-widgets-moduleMarcas"
  }, {
    path: "/personal/widgets/modulePermisos",
    component: _daf3d7d4,
    name: "personal-widgets-modulePermisos"
  }, {
    path: "/personal/widgets/moduleTecnicos",
    component: _b240789c,
    name: "personal-widgets-moduleTecnicos"
  }, {
    path: "/personal/widgets/quickCreate",
    component: _1c85836d,
    name: "personal-widgets-quickCreate"
  }, {
    path: "/personal/widgets/quickView",
    component: _36487a54,
    name: "personal-widgets-quickView"
  }, {
    path: "/planificador/widgets/editAuditoria",
    component: _64eec214,
    name: "planificador-widgets-editAuditoria"
  }, {
    path: "/portal/etiquetas/nueva",
    component: _47831669,
    name: "portal-etiquetas-nueva"
  }, {
    path: "/portal/instalaciones/create",
    component: _21daf48b,
    name: "portal-instalaciones-create"
  }, {
    path: "/portal/perfil/edit",
    component: _6aac21a0,
    name: "portal-perfil-edit"
  }, {
    path: "/portal/solicitudes/create",
    component: _829c4448,
    name: "portal-solicitudes-create"
  }, {
    path: "/portal/tecnicos/create",
    component: _324d23c4,
    name: "portal-tecnicos-create"
  }, {
    path: "/revisiones/lotes/form",
    component: _11ab1cd7,
    name: "revisiones-lotes-form"
  }, {
    path: "/auditorias/agrocolor/global_gap/cultivos",
    component: _33ca7b32,
    name: "auditorias-agrocolor-global_gap-cultivos"
  }, {
    path: "/auditorias/agrocolor/global/cultivos",
    component: _766d412e,
    name: "auditorias-agrocolor-global-cultivos"
  }, {
    path: "/configuraciones/checklists/categorias/crudDialog",
    component: _e8b8de00,
    name: "configuraciones-checklists-categorias-crudDialog"
  }, {
    path: "/configuraciones/checklists/categorias/editCrudDialog",
    component: _52ac00aa,
    name: "configuraciones-checklists-categorias-editCrudDialog"
  }, {
    path: "/configuraciones/checklists/categorias/new",
    component: _f4e3a194,
    name: "configuraciones-checklists-categorias-new"
  }, {
    path: "/informes/ccl/informe_operadores/operadores_baja",
    component: _2460fb3e,
    name: "informes-ccl-informe_operadores-operadores_baja"
  }, {
    path: "/portal/auditorias/pacs/accionCorrectiva",
    component: _40a0e326,
    name: "portal-auditorias-pacs-accionCorrectiva"
  }, {
    path: "/portal/informes/coplaca/auditoria",
    component: _25bc7ab4,
    name: "portal-informes-coplaca-auditoria"
  }, {
    path: "/portal/informes/coplaca/parcelas",
    component: _02f0d59b,
    name: "portal-informes-coplaca-parcelas"
  }, {
    path: "/",
    component: _030f868f,
    name: "index"
  }, {
    path: "/auditorias/tabs/checklist/:uuid?",
    component: _aaf3b7e2,
    name: "auditorias-tabs-checklist-uuid"
  }, {
    path: "/auditorias/tabs/muestras/:uuid?",
    component: _4797a423,
    name: "auditorias-tabs-muestras-uuid"
  }, {
    path: "/auditorias/tabs/pacs/:uuid?",
    component: _5a5af604,
    name: "auditorias-tabs-pacs-uuid"
  }, {
    path: "/configuraciones/checklists/categorias/:uuid",
    component: _22935db0,
    name: "configuraciones-checklists-categorias-uuid"
  }, {
    path: "/admin/empresas/:uuid",
    component: _4f4d3768,
    name: "admin-empresas-uuid"
  }, {
    path: "/admin/marcas/:uuid",
    component: _09656a95,
    name: "admin-marcas-uuid"
  }, {
    path: "/alcances/ficheros/:uuid",
    component: _b1565e78,
    name: "alcances-ficheros-uuid"
  }, {
    path: "/auditorias/checklists/:uuid",
    component: _055600c7,
    name: "auditorias-checklists-uuid"
  }, {
    path: "/auditorias/checklists/:uuidOld",
    component: _9a4a46e0,
    name: "auditorias-checklists-uuidOld"
  }, {
    path: "/auditorias/planes/:uuid",
    component: _26a9245e,
    name: "auditorias-planes-uuid"
  }, {
    path: "/bloques/edit/:uuid?",
    component: _cb44ae80,
    name: "bloques-edit-uuid"
  }, {
    path: "/configuraciones/checklists/:uuid",
    component: _77022325,
    name: "configuraciones-checklists-uuid"
  }, {
    path: "/dictamenes/lotes/:uuid",
    component: _43650cc7,
    name: "dictamenes-lotes-uuid"
  }, {
    path: "/expedientes/iteracion/:expediente?",
    component: _39d9295f,
    name: "expedientes-iteracion-expediente"
  }, {
    path: "/informes/productores/:uuid",
    component: _c9f17634,
    name: "informes-productores-uuid"
  }, {
    path: "/personal/edit/:uuid?",
    component: _3ef40381,
    name: "personal-edit-uuid"
  }, {
    path: "/portal/auditorias/:uuid",
    component: _4d305fe8,
    name: "portal-auditorias-uuid"
  }, {
    path: "/portal/autoevaluaciones/:uuid",
    component: _7b793095,
    name: "portal-autoevaluaciones-uuid"
  }, {
    path: "/portal/cooperativas/:uuid",
    component: _c15962d2,
    name: "portal-cooperativas-uuid"
  }, {
    path: "/portal/formaciones/:uuid",
    component: _46e4a7cb,
    name: "portal-formaciones-uuid"
  }, {
    path: "/portal/marcas/:uuid",
    component: _03e7a282,
    name: "portal-marcas-uuid"
  }, {
    path: "/portal/solicitudes/:uuid",
    component: _70b775e6,
    name: "portal-solicitudes-uuid"
  }, {
    path: "/public/documentos/:uuid?",
    component: _2aadf92d,
    name: "public-documentos-uuid"
  }, {
    path: "/public/formatos/:uuid?",
    component: _3d7d6cc9,
    name: "public-formatos-uuid"
  }, {
    path: "/revisiones/lotes/:uuid",
    component: _1fd81217,
    name: "revisiones-lotes-uuid"
  }, {
    path: "/alcances/:uuid",
    component: _d64a586c,
    name: "alcances-uuid"
  }, {
    path: "/auditores/:uuid",
    component: _36979df4,
    name: "auditores-uuid"
  }, {
    path: "/auditorias/:uuid",
    component: _532e97fb,
    name: "auditorias-uuid"
  }, {
    path: "/bloques/:uuid",
    component: _4ace3c02,
    name: "bloques-uuid"
  }, {
    path: "/cargas/:uuid",
    component: _5327693a,
    name: "cargas-uuid"
  }, {
    path: "/certificados/:uuid",
    component: _f5cce980,
    name: "certificados-uuid"
  }, {
    path: "/clientes/:uuid",
    component: _b4ea3a52,
    name: "clientes-uuid"
  }, {
    path: "/compartir/:uuid",
    component: _cea144b6,
    name: "compartir-uuid"
  }, {
    path: "/competencias/:uuid",
    component: _18066e46,
    name: "competencias-uuid"
  }, {
    path: "/componentes/:uuid",
    component: _e6cc9f9a,
    name: "componentes-uuid"
  }, {
    path: "/delegaciones/:uuid",
    component: _b728f136,
    name: "delegaciones-uuid"
  }, {
    path: "/dictamenes/:uuid",
    component: _134b4a52,
    name: "dictamenes-uuid"
  }, {
    path: "/dropdowns/:uuid",
    component: _444204ca,
    name: "dropdowns-uuid"
  }, {
    path: "/emplazamientos/:uuid",
    component: _e0845ea6,
    name: "emplazamientos-uuid"
  }, {
    path: "/estaticos/:uuid",
    component: _2b86a782,
    name: "estaticos-uuid"
  }, {
    path: "/expedientes/:uuid",
    component: _3bde99d8,
    name: "expedientes-uuid"
  }, {
    path: "/formaciones/:uuid",
    component: _00ad7218,
    name: "formaciones-uuid"
  }, {
    path: "/formatos/:uuid",
    component: _76e77ab9,
    name: "formatos-uuid"
  }, {
    path: "/grupo_solicitudes/:uuid",
    component: _0c8a6844,
    name: "grupo_solicitudes-uuid"
  }, {
    path: "/grupos/:uuid",
    component: _6f9d1870,
    name: "grupos-uuid"
  }, {
    path: "/incompatibilidades/:uuid",
    component: _a00a9aa6,
    name: "incompatibilidades-uuid"
  }, {
    path: "/listas_validacion/:uuid",
    component: _4303ad01,
    name: "listas_validacion-uuid"
  }, {
    path: "/marcas/:uuid",
    component: _27da47d6,
    name: "marcas-uuid"
  }, {
    path: "/metadata/:uuid",
    component: _453f6e66,
    name: "metadata-uuid"
  }, {
    path: "/personal/:uuid?",
    component: _5e33129e,
    name: "personal-uuid"
  }, {
    path: "/plantillas/:uuid",
    component: _557a43ae,
    name: "plantillas-uuid"
  }, {
    path: "/presupuestos/:uuid",
    component: _4ad56404,
    name: "presupuestos-uuid"
  }, {
    path: "/revisiones/:uuid",
    component: _5ce0c7b2,
    name: "revisiones-uuid"
  }, {
    path: "/selectores/:uuid",
    component: _4632f22b,
    name: "selectores-uuid"
  }, {
    path: "/tipos_fichero/:uuid",
    component: _c773457c,
    name: "tipos_fichero-uuid"
  }, {
    path: "/tramites/:uuid",
    component: _1fa4e43e,
    name: "tramites-uuid"
  }, {
    path: "/zonas_visita/:uuid",
    component: _780fc7c8,
    name: "zonas_visita-uuid"
  }, {
    path: "/alcances/:uuid_alcance/subalcances/crudDialog",
    component: _157402d6,
    name: "alcances-uuid_alcance-subalcances-crudDialog"
  }, {
    path: "/alcances/:uuid_alcance/subalcances/:uuid?",
    component: _236c9e1a,
    name: "alcances-uuid_alcance-subalcances-uuid"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
