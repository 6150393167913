<template>
  <div>

    <!-- MENÚ LATERAL CON LISTAS DE VALIDACIÓN -->
    <v-navigation-drawer v-model="openListaValidacion"
          app
          right
          temporary
          width="500"
          class="elevation-6 fondo-app"
          style="background-color: rgb(246, 248, 249) !important;"
          @input="controlOpen()">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title class="flex">
          Lista de validación
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large v-if="!validacionesPendientes" color="green">mdi-check-bold</v-icon>        
      </v-toolbar>

      <div class="ml-3 mr-3" v-if="lista">

        <v-list dense>

          <v-list-item v-for="(item, i) in lista.plantilla" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item.titulo"></v-list-item-title>
              <v-list-item-subtitle v-if="item.descripcion">{{item.descripcion}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox v-model="item.check" :disabled="listaDeshabilidata" color="primary" @change="clickItemLista(item)"></v-checkbox>              
            </v-list-item-action>

          </v-list-item>

        </v-list>

      </div>

    </v-navigation-drawer>

  </div>
</template> 

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: "ListasValidacion",
    props: {
      value: Boolean
    },
    data() {
      return {
        openListaValidacion: false,        
        lista: null   
      }
    },
    watch: {
      value: function (val) {
        this.openListaValidacion = val
      },
      '$route'(to, from) {
        this.resetListaValidacion(to)
      },
      '$store.state.listas_validacion.alcance' () {
        if (this.$store.state.listas_validacion.alcance) this.getListaValidacion()
      }      
    },
    mounted() {
      this.resetListaValidacion(this.$route)
    },
    computed: {
      ...mapGetters({
        listaDeshabilidata: 'listas_validacion/listaDeshabilidata',
        validacionesPendientes: 'listas_validacion/validacionesPendientes',
      }),         
    },
    methods: {    
      controlOpen() {
        if (!this.openListaValidacion) {
          this.$emit('input', null)
          this.$emit('close')
        }
      },
      async resetListaValidacion(route) {
        this.$emit('input', null)
        const xhr = await this.$store.dispatch(`listas_validacion/reset`, route)
        this.lista = xhr
      }, 
      async getListaValidacion() {
        this.lista = await this.$store.dispatch(`listas_validacion/listByRefUuid`)
        if (!this.lista){
          let res = await this.$store.dispatch(`listas_validacion/listAllBySubalcance`, 
            this.$store.state.listas_validacion.subalcance.uuid
          ) 
          if (res.length > 0) {
            this.lista = res[0]
          }
          else this.lista = null

        }
        //this.lista.array.forEach(element => {
        //  element.data = elemet.plantilla
        //});
        // TODO: Cuando esté subida la modificación del API, comprobar el rol y asignar o no en base a este
        // console.log('[Lista validación]', this.lista)
      },       
      async clickItemLista(item) {
        if (this.lista.refUuid) {
          let payload = {
            plantilla: this.lista.plantilla,
            uuid: this.lista.uuid
          }
          await this.$store.dispatch(`listas_validacion/updateByUuid`, payload)
        }
        else {
          let refUuid = this.$route.params.uuid
          let payload = {
            item: this.lista,
            refUuid: refUuid
          }
          await this.$store.dispatch(`listas_validacion/save`, payload)
        }
      }
    }
  }
</script>

<style scoped>

</style>

