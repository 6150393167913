export const state = () => ({
  offlineActions: []
});


//commit mutations
export const mutations = {
  OFFLINE_ACTION(state, action) {
    const storage = JSON.parse(localStorage.getItem('offline_actions'))
    if (storage) state.offlineActions = storage
    state.offlineActions.push(action)
    localStorage.setItem('offline_actions', JSON.stringify(state.offlineActions))
  },
  CLEAR_OFFLINE_MODE(state) {
    state.offlineActions = []
    localStorage.removeItem('offline_actions')
    localStorage.removeItem('estaticos')
    localStorage.removeItem('auditores')
    localStorage.removeItem('alcance')
    localStorage.removeItem('plantillas_checklist')
    localStorage.removeItem('auditoria_checklist')
    localStorage.removeItem('sites')
    localStorage.removeItem('cliente')
    localStorage.removeItem('solicitud')
    localStorage.removeItem('custom_variables')
    localStorage.removeItem('documentos_custom')
  }
};

//dispatch actions
export const actions = {

  async goOnline({rootState, commit, dispatch}) {
    const storage = JSON.parse(localStorage.getItem('offline_actions'))
    if (storage && storage.length > 0) {
      for (const element of storage) {
        console.info(element)
        await dispatch(element.action, element.body)
      }
    }
    commit('CLEAR_OFFLINE_MODE')
  },

  // Estaticos
  async getEstaticos({rootState}, params) {
    const storage = JSON.parse(localStorage.getItem('estaticos?'+params.codigos))
    console.warn('estaticos',storage)
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      return storage
    } else {
      rootState.loading = true
      const body = Object.assign({}, params || {})
      delete body.offlineMode
      const res = await this.$axios.put('estaticos', body)
      rootState.loading = false
      if (res) {
        const data = res.data
        if (rootState.auditorias.offlineMode) {
          let storage = JSON.parse(localStorage.getItem('estaticos'))
          const estaticos = data.reduce((obj, element) => {
            obj[element.codigo] = element
            return obj
          }, {})
          storage = Object.assign(storage || {}, estaticos)
          localStorage.setItem('estaticos', JSON.stringify(storage))
        }
        return data
      }
    }
  },

  // Auditores
  async getAuditores({rootState}) {
    const storage = JSON.parse(localStorage.getItem('auditores'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      return storage
    } else {
      rootState.loading = true
      const res = await this.dispatch('auditores/list')
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('auditores', JSON.stringify(res))
        return res
      }
    }
  },

  // Alcances
  async getAlcance({rootState}, uuid) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('alcance'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      rootState.loading = false
      return storage
    } else {
      const res = await this.dispatch('alcances/get', uuid)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('alcance', JSON.stringify(res))
        return res
      }
    }
  },

  // Plantillas Checklist
  async getPlantillasChecklist({rootState}) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('plantillas_checklist'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      rootState.loading = false
      return storage
    } else {
      const res = await this.dispatch('plantillas-checklist/list')
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('plantillas_checklist', JSON.stringify(res))
        return res
      }
    }
  },

  // Checklists
  async getAuditoriaChecklist({rootState}, auditoria_uuid) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('auditoria_checklist'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      rootState.loading = false
      return storage
    } else {
      const res = await this.dispatch('checklists/list', auditoria_uuid)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('auditoria_checklist', JSON.stringify(res))
        return res
      }
    }
  },

  async getChecklist({rootState}, params) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('auditoria_checklist'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      let checklist = {}
      storage.forEach(element => {
        if (element.uuid === params.checklist_uuid) checklist = element
      })
      rootState.loading = false
      return checklist
    }
  },

  // Gestor Documental

  // TODO --------------------------------- GUARDAR EL DOCUMENTO EN EL LOCAL STORAGE ? ---------------------------------------------->
  async createDocument({rootState}, data) {
    // Check Offline
    if (rootState.auditorias.offlineMode) {
      const action = {
        action: 'createDocument',
        body: data
      }
      commit('OFFLINE_ACTION', action)
    } else {
      await this.dispatch('gestor_documental/create', data)
    }
    rootState.loading = false
  },
  // TODO --------------------------------- GUARDAR EL DOCUMENTO EN EL LOCAL STORAGE ? ---------------------------------------------->

  // Gestor Documental Custom
  async listCustomDocument({rootState}, params) {
    const storage = JSON.parse(localStorage.getItem('documentos_custom'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      let evidencias = []
      storage.forEach(element => {
        if (element.modulo_ref === params.modulo_ref && element.submodulo_ref === params.submodulo_ref) evidencias.push(element)
      })
      rootState.loading = false
      return evidencias
    } else {
      const res = await this.dispatch('gestor_documental/listCustom', params)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('documentos_custom', JSON.stringify(res))
        return res
      }
    }
  },

  async addCustomDocument({rootState, commit}, data) {
    const documentos_custom = rootState.gestor_documental.documentos_custom
    // Check Offline
    if (rootState.auditorias.offlineMode) {
      const action = {
        action: 'addCustomDocument',
        body: documentos_custom
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('documentos_custom'))
      storage.push(documentos_custom)
      localStorage.setItem('documentos_custom', JSON.stringify(storage))
    } else {
      this.dispatch('gestor_documental/addCustom', data)
    }
  },

  async createCustomDocument({rootState, commit}, data) {
    // Check Offline
    if (rootState.auditorias.offlineMode) {
      const action = {
        action: 'createCustomDocument',
        body: data
      }
      commit('OFFLINE_ACTION', action)
      const storage = JSON.parse(localStorage.getItem('documentos_custom'))
      const body = {
        created_at: new Date(),
        file: data.documento.file,
        tipo: data.documento.file.type,
        titulo: data.documento.titulo,
        modulo: data.modulo,
        modulo_ref: data.modulo_ref,
        submodulo: data.submodulo,
        submodulo_ref: data.submodulo_ref,
        documento: {
          file: data.documento.file,
          titulo: data.documento.titulo
        }
      }
      storage.push(body)
      localStorage.setItem('documentos_custom', JSON.stringify(storage))
    } else {
      this.dispatch('gestor_documental/createCustom', data)
    }
  },

  async deleteCustomDocument({rootState, commit}, data) {
    // Check Offline
    if (rootState.auditorias.offlineMode) {
      const action = {
        action: 'deleteCustomDocument',
        body: data
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('documentos_custom'))
      let item = {}
      storage.forEach(element => {
        if (element.uuid === data.uuid) item = element
      })
      const index = storage.indexOf(item)
      storage.splice(index, 1)
      localStorage.setItem('documentos_custom', JSON.stringify(storage))
    } else {
      this.dispatch('gestor_documental/delete', data)
    }
  },

  // Expediente
  async getSites({rootState}, [uuid_expediente, uuid_iteracion]) {
    rootState.loading = true
    const storage = JSON.parse(localStorage.getItem('sites'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      rootState.loading = false
      return storage
    } else {
      const res = await this.dispatch('expedientes/iteracion', [uuid_expediente, uuid_iteracion])
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('sites', JSON.stringify(res))
        return res
      }
    }
  },

  // Clientes
  async getCliente({rootState}, uuid) {
    const storage = JSON.parse(localStorage.getItem('cliente'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      return storage
    } else {
      rootState.loading = true
      const res = await this.dispatch('clientes/get', uuid)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('cliente', JSON.stringify(res))
        return res
      }
    }
  },

  // Solicitud
  async getSolicitud({rootState}, uuid) {
    const storage = JSON.parse(localStorage.getItem('solicitud'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      return storage
    } else {
      rootState.loading = true
      const res = await this.dispatch('grupo_solicitudes/get', uuid)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('solicitud', JSON.stringify(res))
        return res
      }
    }
  },

  // Custom Actions
  async customVariablesList ({ rootState, commit }, body) {
    const storage = JSON.parse(localStorage.getItem('custom_variables'))
    // Check Offline
    if (rootState.auditorias.offlineMode && storage) {
      rootState.loading = false
      return storage
    } else {
      const res = await this.dispatch("custom_variables/list", body)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) localStorage.setItem('custom_variables', JSON.stringify(res))
        return res
      }
    }
  },

  async customVariablesUpdate ({ rootState, commit }, element) {
    console.info('update element', element);
    // Check Offline
    if (rootState.auditorias.offlineMode) {
      const action = {
        action: 'customVariablesUpdate',
        body: element
      }
      commit('OFFLINE_ACTION', action)
    } else {
      await this.dispatch("custom_variables/update", element)
    }
    rootState.loading = false
  },

  async customVariablesCreate ({ rootState, commit }, element) {
    console.info('create element', element);
    // Check Offline
    if (rootState.auditorias.offlineMode) {
      const action = {
        action: 'customVariablesCreate',
        body: element
      }
      commit('OFFLINE_ACTION', action)
    } else {
      await this.dispatch("custom_variables/create", element)
    }
    rootState.loading = false
  },

}
