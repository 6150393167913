import { render, staticRenderFns } from "./concesion.vue?vue&type=template&id=1b3e6ebc&scoped=true"
import script from "./concesion.vue?vue&type=script&lang=js"
export * from "./concesion.vue?vue&type=script&lang=js"
import style0 from "./concesion.vue?vue&type=style&index=0&id=1b3e6ebc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b3e6ebc",
  null
  
)

export default component.exports