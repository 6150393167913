<template>
  <div>
    <table style="width: 100%">
      <tbody>
        <tr>
          <td style="text-align: center">
            <img src="https://coplaca.certiapp.com/coplaca/coplaca_logo.png" height="52px" width="100px">
            <h3 style="font-weight: 700" >
              INFORME DE AUDITORÍA
            </h3>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
      <br />
    </p>
    <p>
      <strong>Productor:</strong>
      {{ datos.productor }}
    </p>

    <p>
      <strong>Cliente:</strong>
      {{ datos.cliente.nombre }}
    </p>

    <p>
      <strong>Fecha Auditoria:</strong>
      {{ formatDate(datos.desde) + ' ~ ' + formatDate(datos.hasta) }}
    </p>

    <p>
      <strong>Auditor:</strong>
      {{ datos.planificaciones && datos.planificaciones.length && datos.planificaciones[0].auditor }}
    </p>

    <p>
      <strong>Título de la lista:</strong>
      {{ datos.planificaciones && datos.planificaciones.length && datos.planificaciones[0].titulo_lista }}
    </p>

    <p>
      <strong>Emplazamiento:</strong>
      {{ datos.planificaciones && datos.planificaciones.length && datos.planificaciones[0].emplazamiento }}
    </p>

    <!-- No Conformidades -->
    <template v-if="datos.no_conformidades.length">
      <p>
        <strong>NO CONFORMIDADES</strong>
      </p>

      <span v-for="(no_conformidad, idx) in datos.no_conformidades" :key="no_conformidad.id">

        <p>
          <strong>{{ idx + 1 }}. No Conformidad - {{no_conformidad.codigo }}: </strong>
          {{ no_conformidad.hint }}
        </p>

        <p>
          <strong>Respuesta:</strong>
          {{ no_conformidad.respuesta }}
        </p>
        <p>
          <strong>No Conformidad:</strong>
          <br>
          <span v-html="no_conformidad.no_conformidad"></span>
        </p>
        <p>
          <strong>Observaciones:</strong>
          <br>
          <span v-html="no_conformidad.observaciones"></span>
        </p>

        <table style="width: 100%">
          <thead>
            <tr style="text-align: center">
              <td colspan="4"><strong>Acción Correctiva</strong></td>
            </tr>
            <tr style="text-align: center">
              <td></td>
              <td><strong>Causa</strong></td>
              <td><strong>Acción</strong></td>
              <td><strong>Evidencias</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr style="text-align: center" v-if="no_conformidad.accion_correctiva">
              <td><strong>Valoración</strong></td>
              <td>{{ no_conformidad.accion_correctiva.valoracion_causa }}</td>
              <td>{{ no_conformidad.accion_correctiva.valoracion_accion }}</td>
              <td>{{ no_conformidad.accion_correctiva.valoracion_evidencias }}</td>
            </tr>
            <tr v-if="no_conformidad.accion_correctiva">
              <td><strong>Texto</strong></td>
              <td>{{ no_conformidad.accion_correctiva.causa }}</td>
              <td>{{ no_conformidad.accion_correctiva.accion_propuesta }}</td>
              <td>{{ no_conformidad.accion_correctiva.evidencias_aportadas }}</td>
            </tr>
          </tbody>
        </table>

      </span>
    </template>

    <!-- No Conformidades Pendientes -->
    <template v-if="no_conformidades_pendientes.length">
      <p>
        <strong>NO CONFORMIDADES PENDIENTES</strong>
      </p>

      <span v-for="(no_conformidad_pendiente, idx) in no_conformidades_pendientes" :key="no_conformidad_pendiente.id">

        <p>
          <strong>{{ idx + 1 }}. No Conformidad Pendiente - {{ no_conformidad_pendiente.codigo }}:</strong>
          {{ no_conformidad_pendiente.titulo }}
        </p>

        <p>
          <strong>Respuesta:</strong>
          {{ no_conformidad_pendiente.respuesta }}
        </p>
        <p>
          <strong>No Conformidad:</strong>
          <br>
          <span v-html="no_conformidad_pendiente.no_conformidad"></span>
        </p>
        <p>
          <strong>Observaciones:</strong>
          <br>
          <span v-html="no_conformidad_pendiente.observaciones"></span>
        </p>

      </span>
    </template>

  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }

  table, th, td {
    border: 0.5px solid black;
  }
</style>

<script>
import moment from 'moment'

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    tecnico: {}
  }),
  computed: {
    no_conformidades () {
      return (this.datos && this.datos.planificacion && this.datos.planificacion.no_conformidades) || 1
    },
    no_conformidades_pendientes () {
      return (this.datos && this.datos.planificacion && this.datos.planificacion.no_conformidades_pendientes) || 1
    },
    solicitud_data () {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach(element => {
          data[element.keyname] = element.value
        })
        return data
      }
      else return {}
    },
    site_data () {
      if (this.datos) {
        if (this.datos.isCliente) return this.datos.isCliente
        else if (this.datos.sites) {
          let data = {}
          let tipos = []

          for (let i=0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
          }

          for (let i=0; i < tipos.length; i++) {
            const element = tipos[i]
            data[element] = []
          }

          for (let i=0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            let obj = {}
            obj.uuid = element.uuid
            for (let k=0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
            data[element.tipo].push(obj)
          }

          return data
        }
      }
      else return {}
    },
  },
  methods: {
    formatDate (date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },
  }
}
</script>

