/**
 * @project: certiapp-nuxt
 * @file:    store/formatos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Thu Jul 20 2023
 **/

import { componentes } from '~/plugins/componentes'

const groupBy = function (array, key) {
  return array.reduce(function (result, element) {
    ;(result[element[key]] = result[element[key]] || []).push(element)
    return result
  }, {})
}

export const state = () => ({
  list: [],
  datos: [],
  tipos: [],
  modulo: null,
  modulo_ref: null,
  alcance: null,
  cliente: null,
  verFormatos: false,
  uuid: '',
  componentes: [],
})

//commit mutations
export const mutations = {
  INIT(state) {
    state.list = []
    state.datos = []
    state.tipos = []
    state.modulo = null
    state.modulo_ref = null
    state.alcance = null
    state.subalcance = null
    state.cliente = null
    state.alcanceCodigo = ''
    state.verFormatos = true
    state.uuid = ''
    state.componentes = componentes.formatos
  },
  SET_LIST(state, payload) {
    state.list = payload
  },
  SET_DATOS(state, payload) {
    state.datos = payload
  },
  GET_TIPOS(state, empresa) {
    const general = `${empresa}-${state.modulo}-general-`
    const alcance = `${empresa}-${state.modulo}-${state.alcanceCodigo}-`

    const tiposGeneral = componentes.formatos
      .filter((e) => e.includes(general))
      .map((e) => e.replace(general, ''))

    const tiposAlcance = componentes.formatos
      .filter((e) => e.includes(alcance))
      .map((e) => e.replace(alcance, ''))
    state.tipos = tiposGeneral.concat(tiposAlcance)
  },
  SET_TIPOS(state, payload) {
    state.tipos = payload
  },
  SET_MODULO(state, payload) {
    state.modulo = payload
  },
  SET_MODULO_REF(state, payload) {
    state.modulo_ref = payload
  },
  SET_ALCANCE(state, payload) {
    // state.alcance = payload.replace('_', '-')
    state.alcance = payload
    state.alcanceCodigo = payload.codigo.replace('_', '-')
  },
  SET_SUBALCANCE(state, payload) {
    state.subalcance = payload
  },
  SET_CLIENTE(state, payload) {
    state.cliente = payload
  },
  SET_VER_FORMATOS(state, payload) {
    state.verFormatos = payload
  },
  SET_UUID(state, payload) {
    state.uuid = payload
  },
}

export const getters = {
  LIST(state) {
    const formatos = state.list
    console.log('formatos', formatos)
    return groupBy(formatos, 'tipo')
  },
  verFormatos(state) {
    return state.verFormatos && !!state.tipos.length
  },
  getAlcance(state) {
    return state.alcance
  },
}

//dispatch actions
export const actions = {
  //+-------------------------------------------------
  // Created on Thu Feb 04 2021
  //+-------------------------------------------------
  async send({ rootState, commit }, params) {
    rootState.loading = true
    const res = await this.$axios.post(`formatos/${params.formato}/send`, params)
    rootState.loading = false

    if (res) return res.data
    return {}
  },

  async list({ commit }, route) {
    
    if (route.name.indexOf('uuid') !== -1) {
      const path = route.path.split('/')
      let modulo = path[1]
      if (modulo === 'grupo_solicitudes') modulo = 'solicitudes'
      const modulo_ref = path[2]
      const params = {
        modulo: modulo,
        modulo_ref: modulo_ref,
      }
      const res = await this.$axios.get(`plantilla_formatos?${new URLSearchParams(params).toString()}`)
      if (res) {
        const data = res.data['hydra:member']
        commit('SET_LIST', data)
      }
      commit('SET_VER_FORMATOS', true)
    } else commit('SET_VER_FORMATOS', false)
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`formatos/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getPublic()
  // Created on Thu Feb 04 2021
  //+-------------------------------------------------
  async getPublic({ rootState }, data) {
    rootState.loading = true
    const res = await this.$axios.get(`public/formatos/${data.uuid}?token=${data.token}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState, commit, state }, params) {
    rootState.loading = true
    const body = {
      ...params,
      alcance: state.alcance.uuid,
      cliente: state.cliente.uuid,
    }
    await this.$axios.post(`formatos`, body)
    commit('SET_UUID', params.uuid)
    rootState.loading = false
  },

  async update({ rootState, state }, params) {
    rootState.loading = true
    const body = {
      ...params,
      alcance: state.alcance.uuid,
      formato: params.uuid,
    }
    delete body.uuid
    delete body.version
    const res = await this.$axios.patch(`formatos/${params.uuid}`, body)
    rootState.loading = false
    if (res) return res.data
  },

  async delete({ rootState }, uuid) {
    rootState.loading = true
    await this.$axios.delete(`formatos/${uuid}`)
    rootState.loading = false
  },
}
