import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export const componentes = {
  formatos: []
}

// Generador Plantillas -> Get File Name Base On Folder Depth
const formatos = require.context('~/components/formatos', true, /\.vue$/)
formatos.keys().forEach(fileName => {
  // Get component config
  const componentConfig = formatos(fileName)
  const componentName = fileName
    .split('/')
    .map(element => upperFirst(
      camelCase(
        element.replace(/\.\w+$/, '')
      )
    ))
    .join('')

  // Components List
  const name = fileName
    .replace('./', '')
    .replace('_', '-')
    .split('/')
    .map(e => e.replace(/\.\w+$/, '').toLowerCase())
    .join('-')
  componentes.formatos.push(name)

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

// Expedientes -> Get File Name Base On Folder Depth
const expedientes = require.context('~/components/expedientes', true, /\.vue$/)
const expedientesSkip = [
  'sites',
  'Actions'
]

expedientes.keys().forEach(fileName => {
  // Get component config
  if (!expedientesSkip.some(element => fileName.includes(element))) {
    // console.log("fileName", fileName)
    const componentConfig = expedientes(fileName)
    const componentName = 'Expedientes' + fileName
      .split('/')
      .map(element => upperFirst(
        camelCase(
          element
            .replace('index.vue', '')
            .replace(/\.\w+$/, '')
        )
      ))
      .join('')

    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    )
  }
})

// Cell Editors -> Get Only File Name
const cellEditors = require.context('~/components/cellEditors', true, /\.vue$/)

cellEditors.keys().forEach(fileName => {
  // Get component config
  const componentConfig = cellEditors(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

// Cell Renderers -> Get Only File Name
const cellRenderers = require.context('~/components/cellRenderers', true, /\.vue$/)

cellRenderers.keys().forEach(fileName => {
  // Get component config
  const componentConfig = cellRenderers(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})
