<template>
  <div>

    <v-card outlined v-if="selected.length > 0 && ui.step == 0" class="mb-3">
      <v-list-item>
        <v-list-item-icon>
          <h3 class="pl-3">
            {{ selected.length || 0 }}
          </h3>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Elementos seleccionados</v-list-item-title>
          <v-list-item-subtitle>Continúa para crear un nuevo lote</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
            block
            small
            depressed
            color="success"
            @click="nextStep">
            Crear con la selección
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <template v-if="ui.step == 0">
      <v-card>
        <v-data-table
          ref="datatable"
          v-model="selected"
          item-key="uuid"
          show-select

          :items="rows"
          :headers="[
            { text: 'Estado', value: 'estado', align: 'left', cellClass: 'subData', width: 100, sortable: false },
            {
              text: 'Fecha creación',
              value: 'dates',
              cellClass: 'subData',
              width: 100,
              sortable: false,
            },
            { text: 'Alcance', value: 'nombre_alcance', cellClass: 'subData', width: 100, sortable: false},
            { text: 'Expediente', value: 'codigo_expediente', cellClass: 'subData', width: 100, sortable: false },
            { text: 'Cliente', value: 'client' },
          ]"
          :items-per-page="50"

          :search="table.search"
          :class="{'disabled': ui.loading}"
          :loading="ui.loading">
          <template v-slot:item.client="{ item }">
            {{ getName(item) }}
          </template>

          <template v-slot:item.dates="{ item }">
            {{ $moment(item.created_at).format("DD [de] MMMM, YYYY") }}
          </template>
        </v-data-table>
      </v-card>
    </template>

    <template v-if="ui.step == 1">
      <component
        ref="form"
        :is="formComponent"
        :type="type"
        :_batch="batch"
        :_selected="selected"
        :is_revision="revision"
        @lotes:reset="ui.step = 0; batch = []; selected = []"
      />
    </template>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\dictamenes\lotes\crud.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 23rd March 2022
 * Last Modified: Wed May 17 2023
 **/

import estados from '~/models/estados'
import SelectAuditoresObject from '~/components/forms/SelectAuditoresObject'

import loteDictamenes from '~/pages/dictamenes/lotes/form'
import loteRevisiones from '~/pages/revisiones/lotes/form'

export default {
  props: ['lote', 'lote', 'items', 'type'],
  components: {
    loteRevisiones,
    loteDictamenes,
    SelectAuditoresObject,
  },
  data() {
    return {
      item: {
        action: 'create',
        revision: false,
        auditor1: {},
        auditor2: {},
        fecha_decision: new Date().toISOString().substr(0, 10),
        fecha_proposicion: new Date().toISOString().substr(0, 10),
        motivo: '',
      },
      batch: [],
      selected: [],
      apply: {
        decision: null,
        accion: null,
      },

      estados: estados,
      decisiones: estados.DICTAMEN_DECISIONES_PROPOSICIONES,

      db: {
        revisiones: [],
      },

      table: {
        search: '',
        headers: [{ text: 'Estado', value: 'status', cellClass: 'subData', width: 100 }],
      },

      ui: {
        step: 0,
        caching: false,
        loading: false,
        datepicker: false,
        _datepicker: false,
      },
    }
  },

  watch: {},

  computed: {
    rows() {
      return this.items
    },

    formComponent() {
      return 'lote' + this.lote
    },
  },

  methods: {
    nextStep() {
      if (this.selected.length > 0) {
        if (!this.selected[0].proposicion) this.revision = true
      }
      this.ui.step = 1
    },
    getName(item) {
      if (item?.cliente) return item.cliente.nombre + ' ' + item.cliente.apellidos
      if (item) return item.nombre_cliente_completo ?? item.nombre_cliente
      return ''
    },
    //+-------------------------------------------------
    // setSelectedRevisiones()
    // Populate this.selected from this.lote.revisiones
    // -----
    // Created on Wed Mar 30 2022
    //+-------------------------------------------------
    // setSelectedRevisiones() {
    //   this.selected = []

    //   this.lote.revisiones.forEach((revision) => {
    //     let el = null
    //     let index = this.db.revisiones.findIndex((item) => item.uuid == revision.uuid)

    //     if (index > -1) {
    //       el = { ...this.db.revisiones[index] }
    //       el.accion = revision.accion
    //       el.decision = revision.decision
    //       this.selected.push(el)
    //     }
    //   })
    // },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async init() {
      // if (this.lote) {
      //   this.ui.step = 1
      //   this.item = { ...this.lote }
      //   this.item.action = 'update'
      //   this.item.auditor1.en_calidad_de = this.item.en_calidad_de_auditor1
      //   this.item.auditor2.en_calidad_de = this.item.en_calidad_de_auditor2
      // }
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {},
}
</script>

<style>
.v-data-table.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.subData {
  color: rgb(146, 146, 146);
  font-size: 12px !important;
  white-space: nowrap;
  /* padding: 0 23px !important; */
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  font-weight: bold;
  margin: 8px 0px;
}
.hr-sect:before,
.hr-sect:after {
  content: '';
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}
.fix-margin {
  margin-top: -14px;
}
</style>
