/**
 * @project: certiapp-nuxt
 * @file:    eventos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th March 2021
 * Last Modified: Wed Oct 20 2021
 **/

import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({rootState, commit}, cliente) {
    rootState.loading = true
    const body = {
    }
    const res = await this.$axios.get('eventos?itemsPerPage=100', body)
    let eventos = []
    if (res) {
      const data = res.data['hydra:member']
      data.forEach((event) => {
        eventos.push(event)
      })
      commit('SET_LIST', eventos)
    }
    rootState.loading = false
    return eventos
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`eventos/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, evento) {
    rootState.loading = true

    const body = {
      ...evento,
    }
    //debugger
    console.warn('evento', evento)

    body.uuid = body.uuid === undefined ? uuid.v4() : body.uuid
    body.descripcion = body.descripcion == null ? '' : body.descripcion
    body.alcance = '/api/alcances/' + evento.alcance
    body.subalcance = '/api/subalcances/' + evento.subalcance
    body.cliente = '/api/usuarios/' + evento.cliente
    
    body.user = '/api/usuarios/' + evento.users[0]
    body.users = body.users.map((user) => '/api/usuarios/' + user)
    delete body.auditoria
    //delete body.user
    console.warn('body', body)
    debugger
    await this.$axios.post('eventos', body)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  //
  //+-------------------------------------------------
  async update({rootState}, evento) {
    rootState.loading = true
    const body = {
      ...evento,
    }

    body.user = body.user ? '/api/usuarios/' + body.user.uuid : null
    body.alcance = body.alcance ? '/api/alcances/' + body.alcance.uuid : null
    body.subalcance = body.subalcance ? '/api/subalcances/' + body.subalcance.uuid : null
    body.cliente = body.cliente ? '/api/usuarios/' + body.cliente.uuid : null
    body.desde = this.$moment(body.desde).format('YYYY-MM-DD')
    body.hasta = this.$moment(body.hasta).format('YYYY-MM-DD')

    delete body.owner
    delete body.users
    delete body.empresa
    delete body.auditoria
    delete body.expediente

    await this.$axios.patch(`eventos/${evento.uuid}`, body,{
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
  },

  async delete({rootState}, evento) {
    rootState.loading = true
    await this.$axios.delete(`eventos/${evento.uuid}`)
    rootState.loading = false
  },

  async config({rootState}, data) {
    rootState.loading = true
    const body = {
      config: {
        filters: {
          ...data,
        },
      },
    }
    await this.$axios.post(`eventos/config`, body)
    rootState.loading = false
  },
}
