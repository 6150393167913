<template>
  <v-app>
    <notification/>
    <v-main>
      <Navigation />
      <v-container class="main-container" :class="padding">
        <v-fade-transition>
          <Nuxt/>
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import Navigation from "~/components/portal/Navigation"
  import Notification from "~/components/Notification"
  // import role from "~/models/role"
  import store from '~/store'

  export default {
    components: {
      Notification,
      Navigation
    },
    computed: {
      isPdf () {
        return this.$route.name === 'formatos'
      },
      isPlanificador () {
        return this.$route.name === 'planificador'
      },
      padding () {
        return this.isPlanificador ? 'pa-0'
          : this.isPdf ? 'white pa-0'
            : 'px-8'
      },
    }
  }
</script>

<style>
  .main-container {
    max-width: 100% !important;
  }
  .ag-theme-material .ag-icon-checkbox-checked {
    color: #2e3444 !important;
  }
</style>
