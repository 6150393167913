<template>
  <div>
    <v-col cols="12">
      <v-card :loading="loading">

        <v-card-title>
          Campos Ecológico
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn small fab depressed color="error" class="white--text" v-on="on" @click="deleteVariables()">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="4">
                <v-text-field
                  v-model="variables.codigo_sipea"
                  label="Código Sipea"
                  @change="changeVariables()"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="variables.codigo_interno"
                  label="Código interno"
                  @change="changeVariables()"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <!-- <v-text-field label="Coperativa"  v-model="variables.codigo_interno"></v-text-field> -->
                <v-select
                  v-model="variables.coperativa_uuid"
                  :items="coperativas"
                  label="Coperativas"
                  item-text="nombre"
                  item-value="uuid"
                  @change="changeVariables()"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-switch v-model="variables.demeter" label="Demeter" color="success" @change="changeVariables()"></v-switch>
              </v-col>

              <v-col cols="4">
                <v-switch v-model="variables.naturland" label="Naturland" color="success" @change="changeVariables()"></v-switch>
              </v-col>

              <v-col cols="4">
                <v-switch v-model="variables.biosuisse" label="Biosuisse" color="success" @change="changeVariables()"></v-switch>
              </v-col>

              <v-col cols="4">
                <v-switch v-model="variables.moroso" label="Es moroso" color="success" @change="changeVariables()"></v-switch>
              </v-col>

              <v-col cols="4">
                <v-switch v-model="variables.presupuesto_especifico" label="Presupuesto Específico" color="success" @change="changeVariables()"></v-switch>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="variables.condiciones_presupuesto"
                  label="Condiciones Presupuesto"
                  color="primary"
                  @change="changeVariables()"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

      </v-card>
    </v-col>
  </div>
</template>

<script>
  export default {
    name: "VariablesAgrocolorEcologico",
    mounted() {
      this.init()
    },
    data: () => ({
      loading: false,
      uuid: '',
      variables: {},
      coperativas: [],
      newVariables: false,
      grupo_alcance: null
    }),
    computed: {
      isNew () {
        return this.$route.params.uuid === "_";
      },
      route_id () {
        return this.$route.params.uuid
      },
    },
    methods: {
      async init () {
        this.variables = {}

        // Init grupo alcance
        let grupo_alcances = await this.$store.dispatch('grupos_alcances/list');
        if (grupo_alcances) {
          grupo_alcances = grupo_alcances.filter(node => node.codigo == 'ecologico');
          this.grupo_alcance = grupo_alcances[0].uuid;
        }

        const coperativas = await this.$store.dispatch("grupos/list")
        if (coperativas) this.coperativas = coperativas

        let create = true
        if (!this.isNew) {
          const body = {
            modulo: 'clientes',
            external_uuid: this.route_id
          }
          const res = await this.$store.dispatch('custom_variables/list', body)
          if (res) {
            res.forEach(element => {
              if (element.grupo_alcance && element.grupo_alcance.codigo === 'ecologico') {
                this.variables = element.variables
                this.uuid = element.uuid
                create = false
              }
            });
            // if (!this.variables.cooperativa) this.variables.coperativa = { uuid: '' }
          }
        }
        if (create) {
          console.info('isnew ecologico');
          this.uuid = JSON.parse(JSON.stringify(this.$uuid.v4()))
          this.newVariables = true
        }
      },
      changeVariables () {
        this.newVariables ? this.createVariables() : this.updateVariables()
      },
      updateVariables () {
        let body = {
          newVariables: false,
          custom_variables: this.uuid,
          grupo_alcance: this.grupo_alcance,
          variables: this.variables
        }
        // console.log("createVariables -> body", body)
        this.$store.commit('custom_variables/updateVariables', body)
      },
      createVariables () {
        let body = {
          newVariables: true,
          uuid: this.uuid,
          modulo: 'clientes',
          grupo_alcance: this.grupo_alcance,
          external_uuid: this.route_id,
          variables: this.variables
        }
        // console.log("createVariables -> body", body)
        this.$store.commit('custom_variables/createVariables', body)
      },
      async deleteVariables() {
        this.newVariables
          ? await this.$store.commit('custom_variables/deleteVariables', this.uuid)
          : await this.$store.dispatch('custom_variables/delete', this.uuid)
        this.init()
      }
    }
  }
</script>

