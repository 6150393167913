import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({rootState, commit}, params) {
    rootState.loading = true
    const res = await this.$axios.get('incidencias')
    rootState.loading = false
    if (res) {
      const data = res.data
      commit('SET_LIST', data)
      return data
    }
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`incidencias/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, incidencia) {
    rootState.loading = true
    const body = {
      uuid: uuid.v4(),
      revision: incidencia.revision,
      de: incidencia.de_uuid,
      para: incidencia.para_uuid,
      comentario: incidencia.comentario
    }
    await this.$axios.post('incidencias', body)
    rootState.loading = false
  },

  async update({rootState}, incidencia) {
    rootState.loading = true
    const body = {
      incidencia: incidencia.uuid,
      revision: incidencia.revision,
      para: incidencia.para,
      comentario: incidencia.comentario
    }
    await this.$axios.patch(`incidencias/${incidencia.uuid}`, body)
    rootState.loading = false
  },

  async responder({rootState}, incidencia) {
    rootState.loading = true
    const body = {
      incidencia: incidencia.uuid,
      respuesta: incidencia.respuesta
    }
    await this.$axios.patch(`incidencias/${incidencia.uuid}/respondida`, body)
    rootState.loading = false
  },

  async resolver({rootState}, incidencia) {
    rootState.loading = true
    const body = {
      incidencia: incidencia.uuid,
    }
    await this.$axios.patch(`incidencias/${incidencia.uuid}/resuelta`, body)
    rootState.loading = false
  },

  // async delete({rootState}, incidencia) {
  //   rootState.loading = true
  //   await this.$axios.delete(`incidencias/${incidencia.uuid}`)
  //   rootState.loading = false
  //   return data
  // },

}
