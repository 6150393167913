<template>
  <div>

    <!-- HEADER -->
    <header-component noAction v-if="!cliente && !expediente"/>

    <!-- LISTADO -->
    <v-row>
      <v-col cols="12">
        <ag-datatable title="Listado" :headers="headers" :items="items" emitClickRow @click-row="clickRow"></ag-datatable>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import HeaderComponent from "~/components/HeaderComponent"
import AgDatatable from "~/components/AgDatatable"
import Estados from "~/models/estados"
import moment from 'moment'

export default {
  props: {
    cliente: { type: String, default: null},
    expediente: { type: String, default: null}
  },
  components: {
    HeaderComponent,
    AgDatatable
  },
  mounted() {
    if (!this.cliente && !this.expediente) this.$store.commit("menu/setPageTitle", 'Auditorías')
  },
  data: () => ({
    items: [],
    headers: [
      { headerName: "Alcance", field: "alcance.nombre", enableRowGroup: true, sortable: true, filter: true, resizable: true, cellRendererFramework: 'AlcanceRenderer' },
      {
        headerName: "Fecha prevista", field: "fecha_prevista", sortable: true, sort: 'desc', filter: "agDateColumnFilter", resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.fecha_prevista
          return value ? moment(value).format('DD/MM/YYYY') : ''
        }
      },
      {
        headerName: "Cliente", field: "cliente.nombre", enableRowGroup: true, sortable: true, filter: true, resizable: true,
        valueGetter: (params) => {
          const item = params.data.cliente
          if (item) {
            let nombre = item.nombre
            if (item.apellido1) nombre += ' ' + item.apellido1
            if (item.apellido2) nombre += ' ' + item.apellido2
            if (item.primer_apellido) nombre += ' ' + item.primer_apellido
            if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido
            if (item.apellidos) nombre += ' ' + item.apellidos
            return nombre
          }
          else return ''
        }
      },
      {
        headerName: "Estado", field: "estado", enableRowGroup: true, sortable: true, sort: 'desc', filter: true, resizable: true,
        cellStyle: function (params) {
          if (params.value === Estados.AUDITORIA_ESTADO.PENDIENTE) return {color: '#FB8C00'}
          else if (params.value === Estados.AUDITORIA_ESTADO.PREVISTA) return {color: '#FB8C00'}
          else if (params.value === Estados.AUDITORIA_ESTADO.ABIERTA) return {color: '#2196F3'}
          else if (params.value === Estados.AUDITORIA_ESTADO.CERRADA) return {color: '#EF5350'}
          else if (params.value === Estados.AUDITORIA_ESTADO.REVISADA_OK) return {color: '#43A047'}
          else if (params.value === Estados.AUDITORIA_ESTADO.APTA) return {color: '#43A047'}
          else if (params.value === Estados.AUDITORIA_ESTADO.NO_APTA) return {color: '#EF5350'}
          else return null
        }
      },
      {
        headerName: "Emplazamientos", field: "sites", enableRowGroup: true, sortable: true, filter: true, resizable: true, cellRendererFramework: 'EmplazamientosRenderer'
      },
      { headerName: "Tipo visita", field: "tipo_visita", enableRowGroup: true, sortable: true, filter: true, resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.tipo_visita
          return value && value.nombre ? value.nombre : ''
        }
      },
      {
        headerName: "Bloqueada hasta", field: "iteracion.bloqueado_hasta", sortable: true, filter: "agDateColumnFilter", resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.iteracion && item.iteracion.bloqueado_hasta
          return value ? moment(value).format('DD/MM/YYYY') : ''
        }
      },
      {
        headerName: "PACS", field: "n_pacs", sortable: true, filter: true, resizable: true, cellRendererFramework: 'CheckboxRenderer'
      },
    ]
  }),
  computed: {
    isCliente() {
      return this.$store.getters.isCliente
    },
  },
  methods: {
    async init() {
      const body = {}
      if (this.expediente) body.expediente = this.expediente
      // Cliente
      if (this.cliente) body.cliente = this.cliente
      else if (this.isCliente) {
        const clientes = await this.$store.dispatch("clientes/search", this.$auth.user.uuid)
        if (clientes) body.cliente = clientes[0].uuid
      }
      // Auditorias
      const res = await this.$store.dispatch('auditorias/previstas', body)
      if (res) this.items = res
    },
    clickRow(data) {
      if (data) this.$router.push(`/auditorias/${data.uuid}?tab=0`)
    }
  },
  created() {
    this.$store.commit("menu/update", "auditorias")
    this.init()
  },
};
</script>
