export default {
  TIPOS_DATOS: [
    { text: 'Texto', value: 'text' },
    { text: 'Texto corto', value: 'character' },
    { text: 'Entero', value: 'int' },
    { text: 'Decimal', value: 'decimal' },
    { text: 'Check Box', value: 'tinyint' },
    { text: 'Fecha', value: 'date' },
    { text: 'Tiempo', value: 'time' },
    { text: 'Título', value: 'titulo' },
    { text: 'Estático', value: 'estatico' },
    { text: 'Grid', value: 'grid' },
    { text: 'Agrupación', value: 'group' },
    { text: 'Si/No', value: 'yesno' },
    { text: 'Emplazamiento', value: 'emplazamiento' },
  ],
}
