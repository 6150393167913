<template>
  <span>

    <!-- MENÚ LATERAL -->
    <v-navigation-drawer
      v-if="!$nuxt.isOffline"
      v-model="drawer"
      app
      right
      temporary
      width="600"
      class="elevation-6 fondo-app"
      style="background-color: rgb(246, 248, 249) !important; z-index: 10;">

      <!-- Header -->
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title class="flex" style="width: 100%;">
          Formatos
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>

        <v-alert type="error" v-if="!datos || ( !datos.length && !db.formatos.length)">
          No hay datos para generar las plantillas
        </v-alert>

        <v-expansion-panels flat :key="'panel-'+ui.refresh">
          <v-expansion-panel
              v-for="(tipo, key) in tiposFormatos"
              :key="key"
              class="ma-2"
          >
            <v-expansion-panel-header class="pa-3">
              <v-row no-gutters align="center">
                <v-col cols="6">
                  <v-badge
                      :value="!!formatos[tipo] && !!formatos[tipo].length"
                      :content="formatos[tipo] && formatos[tipo].length"
                      offset-y="20"
                      offset-x="20"
                      color="blue"
                  >
                    <v-subheader class="title-vuely text-capitalize">{{ tipo.replaceAll(/-/g, ' ') }}</v-subheader>
                  </v-badge>
                </v-col>

                <v-col cols="6" class="text-right pr-4">
                  <v-btn
                      depressed
                      small
                      color="primary"
                      class="white--text"
                      :disabled="!datos.length"
                      @click.stop="confirmGenerarFormato(tipo)"
                  >
                    Generar Plantilla
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="px-3">
              <v-divider></v-divider>
              <template v-if="formatos[tipo] && formatos[tipo].length">
                <v-list two-line subheader dense>
                  <template v-for="(documento, idx) in formatos[tipo]">
                    <v-list-item :key="idx" @click="openFormato(tipo, documento)">
                      <v-list-item-avatar>
                        <v-icon class="blue white--text">mdi-text-box-outline</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">{{ documento.titulo + ' v.' + documento.revision }}</v-list-item-title>

                        <v-list-item-subtitle>
                          <i>
                            <span class="font-weight-bold">Generado: </span>
                            {{ documento.created_at | formatDate }}
                            <span v-if="documento.created_by"> por <b>{{ documento.created_by.email }}</b></span>
                          </i>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="documento.sent_date">
                          <i>
                            <span class="font-weight-bold">Enviado al cliente: </span>
                            {{ documento.sent_date | formatDate }}
                            <span v-if="documento.sent_by"> por <b>{{ documento.sent_by.username}}</b></span>
                          </i>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn icon @click.stop="confirmSend(tipo, idx, documento)">
                          <v-icon small style="font-size: 20px">mdi-send-outline</v-icon>
                        </v-btn>

                        <v-btn icon @click.stop="confirmDeleteFormato(documento)">
                          <v-icon small style="font-size: 20px">mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </template>

              <v-list-item v-else>
                <v-list-item-content>
                  No se han generado revisiones
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- Formatos nuevos v2 -->
        <!-- v-model="ui.panels" -->
        <v-expansion-panels flat :key="'panel_-'+ui.refresh">
          <v-expansion-panel
            v-for="(item, code) in alcancesDeFormato"
            :key="code">
            <v-expansion-panel-header>
              {{ item.nombre }}
              <!-- ({{ item.count }} documentos) -->
            </v-expansion-panel-header>
            <v-expansion-panel-content>

              <v-row align="center" class="mx-1 my-2" style="background-color: white;"
                v-for="(item, key) in formatosInAlcances[code]"
                :key="key">
                <!-- v-for="(item, key) in db.formatos.slice().reverse()" -->
                <v-col class="grow">
                  {{ item.nombre }}
                  <code class="mx-1" style="font-size: 11px">{{ item.codigo }}</code>
                  <!-- <code> {{item.alcance.nombre }}</code> -->
                  <br>
                  <span style="color: #8a8a8a; font-size: 0.85rem;">
                    <v-icon small class="mr-1">mdi-calendar</v-icon>
                    {{ $moment(item.updated_at).format('D [de] MMMM, YYYY') }}
                  </span>
                </v-col>
                <v-col class="shrink">
                  <!-- <div style="font-size: 9px; margin: 0px auto; text-align: center; margin-bottom: -2px;">
                    Descargar documento
                  </div> -->
                  <v-btn-toggle class="pa-2">
                    <v-btn small text color="primary darken-2"
                      @click.stop="generate(item)">
                      Word
                    </v-btn>

                    <v-btn small text color="error darken-2"
                      @click.stop="generate(item, 'pdf')">
                      PDF
                    </v-btn>
                  </v-btn-toggle>

                  <!-- <v-btn
                    depressed
                    small
                    color="primary"
                    class="white--text"
                    @click.stop="generate(item)">
                    <v-icon small class="mr-2">mdi-cloud</v-icon>
                    Generar archivo
                  </v-btn> -->
                </v-col>
              </v-row>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-container>
    </v-navigation-drawer>

    <!-- CONFIRMACIÓN DE GENERACION DE FORMATO -->
    <confirmation
        v-model="dialogGenerar"
        text="Esto generará una plantilla con los últimos datos"
        textButton="Generar"
        @confirm="openFormato(tipo)"/>

    <!-- CONFIRMACIÓN DE ENVIAR A CLIENTE -->
    <confirmation
        v-model="dialogSend"
        text="Enviar el documento seleccionado al cliente?"
        textButton="Enviar"
        @confirm="sendFormato()"/>

    <!-- CONFIRMACIÓN DE BORRADO DE FORMATO -->
    <confirmation
        v-model="dialogDelete"
        text="¿Eliminar el documento seleccionado?"
        colorButton="error"
        @confirm="deleteFormato()"/>

  </span>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/.slice().reverse().vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Wed Jan 25 2023
 **/

import format from '~/util/formatHelper'
import Confirmation from '~/components/Confirmation'
import Formatos from './Formatos'

export default {
  name: 'Formatos',
  props: {
    value: Boolean,
  },
  components: {
    Confirmation,
    Formatos,
  },

  data: () => ({
    tipo: '',
    drawer: false,
    dialogGenerar: false,
    dialogSend: false,
    dialogDelete: false,
    itemSelected: {},

    db: {
      formatos: [],
      counter: {},
    },

    ui: {
      clean: 0,
      refresh: 0,
      panels: null,
    },
  }),

  watch: {
    value(val) {
      this.drawer = val
      this.ui.refresh++
      this.$forceUpdate()
    },
    drawer(val) {
      if (!val) this.$emit('close')
    },
    $route(to, from) {
      this.$store.commit('formatos/INIT')
      this.reset()
      this.getFormatos(to)
    },
  },

  computed: {
    isCliente() {
      return this.$store.getters.isCliente
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    formatos() {
      return this.$store.getters['formatos/LIST']
    },
    tiposFormatos() {
      return this.$store.state.formatos.tipos
    },
    verFormatos() {
      return this.$store.state.formatos.verFormatos && this.tiposFormatos.length
    },
    datos() {
      return this.$store.state.formatos.datos
    },

    //+-------------------------------------------------
    // hayFormatos()
    // Computed para comprobar si hay formatos en algunas
    // de las dos stores. Se utiliza una computed en vez de un
    // getter para simplificar el flujo de información
    // Created on Fri May 28 2021
    //+-------------------------------------------------
    hayFormatos() {
      return this.db.formatos?.length > 0 || this.verFormatos
    },

    //+-------------------------------------------------
    // formatosCount()
    // Computed que devuelve el número de formatos disponibles,
    // ya sea mediante el sistema antiguo o el nuevo
    // -----
    // Created on Fri May 28 2021
    //+-------------------------------------------------
    formatosCount() {
      return this.db.formatos ? this.db.formatos.length + this.formatos.length : 0
    },

    //+-------------------------------------------------
    // formatosInAlcances()
    // Returns all formatos in an array keyed by alcance
    // also counts the number of formatos in each alcance
    // -----
    // Created on Tue Jun 07 2022
    //+-------------------------------------------------
    formatosInAlcances() {
      let items = {}

      if (this.ui.refresh == 0) return []
      if (this.db.formatos?.length == 0) return []

      this.db.formatos.forEach((item) => {
        // console.warn(item, item.alcance?.codigo)
        if (item.alcance?.codigo) {
          // Contabilize the amount of documents
          if (!this.db.counter[item.alcance.codigo]) {
            this.db.counter[item.alcance.codigo] = {
              nombre: item.alcance?.nombre,
              count: 0,
            }
          }

          if (!items[item.alcance.codigo]) {
            items[item.alcance.codigo] = []
          }

          items[item.alcance.codigo].push(item)
          this.db.counter[item.alcance.codigo].count++
          // console.warn(items, items[item.alcance.codigo])
        }
      })

      // console.warn('formatos in alcances', items)
      this.$forceUpdate()
      return items
    },

    alcancesDeFormato() {
      let items = this.formatosInAlcances
      if (items.length == 0) return []

      return this.db.counter
    },
  },

  methods: {
    confirmGenerarFormato(tipo) {
      this.tipo = tipo
      this.dialogGenerar = true
    },

    openFormato(tipo, documento) {
      const uuid = documento && documento.uuid
      if (uuid) this.$store.commit('formatos/SET_UUID', uuid)
      const route = '/formatos?plantilla=' + tipo
      this.$router.push(route)
    },

    //+-------------------------------------------------
    // getModAndRef()
    // Returns modulo and modulo_ref from URL
    // -----
    // Updated on Thu Jul 28 2022
    //+-------------------------------------------------
    getModAndRef(route) {
      const path = route.path.split('/')
      let modulo = path[1]
      let modulo_ref = path[2]

      if (modulo === 'grupo_solicitudes') modulo = 'solicitudes'
      if (modulo === 'portal') return {}
      if (modulo_ref == 'lotes') modulo_ref = path[3]
      if (this.$route.query?.auditoria_uuid) modulo_ref = this.$route.query?.auditoria_uuid
      return {
        modulo: modulo,
        modulo_ref: modulo_ref,
      }
    },

    async getFormatos(route, alcance) {
      this.offlineMode ? null : await this.$store.dispatch(`formatos/list`, route)

      if (!this.offlineMode) {
        let data = this.getModAndRef(route)
        if (data.modulo_ref == undefined) return
        if (alcance) data.alcance = alcance.uuid
        const xhr = await this.$store.dispatch(`formato-plantillas/list`, data)
        if (xhr) this.db.formatos = xhr
      }

      this.ui.refresh++
      this.$forceUpdate()
    },

    reset() {
      this.ui.refresh = 0
      this.db.counter = {}
      this.db.formatos = []
    },

    confirmSend(tipo, idx, documento) {
      this.itemSelected.tipo = tipo
      this.itemSelected.idx = idx
      this.itemSelected.formato = documento.uuid
      this.itemSelected.modulo_ref = documento.modulo_ref
      this.itemSelected.modulo = documento.modulo
      this.dialogSend = true
    },

    //+-------------------------------------------------
    // generate()
    // Calls the new formatos api to generate a new dynamic file
    // The file is then downloaded
    // Update: Calls for documents:generated with the result blob
    // -----
    // Created on Fri Apr 30 2021
    // Updated on Tue Jan 04 2022
    //+-------------------------------------------------
    async generate(item, format) {
      let data = this.getModAndRef(this.$route)

      let name = `formato ${item.codigo} - ${item.nombre} - `
      name = name + this.$moment().format('DD-MM-YYYY')

      await this.$store.dispatch(`formato-plantillas/generate`, {
        modulo: item.modulo,
        formato: format,
        modulo_ref: data.modulo_ref,
        formato_plantilla: item.uuid,
        document: {
          name: name,
          modulo: item.modulo,
          modulo_ref: data.modulo_ref,
          submodulo: '',
          submodulo_ref: '',
          cliente: this.datos[0]?.cliente.uuid,
          cliente_name: this.datos[0]?.cliente?.nombre,
        },
      })
    },

    //+-------------------------------------------------
    // sendFormato()
    // Sends an order to the store to perform an api call
    // Pass document uuid
    // -----
    // Created on Thu Feb 04 2021
    //+-------------------------------------------------
    async sendFormato() {
      this.dialogSend = false

      const tipo = this.itemSelected.tipo
      const idx = this.itemSelected.idx
      this.formatos[tipo][idx].sent_by = this.$auth.user.email
      this.formatos[tipo][idx].sent_date = new Date()

      await this.$store.dispatch('formatos/send', {
        // cliente: ,
        formato: this.itemSelected.formato,
        modulo: this.itemSelected.modulo,
        modulo_ref: this.itemSelected.modulo_ref,
      })

      this.itemSelected = {}
    },

    confirmDeleteFormato(item) {
      this.itemSelected = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteFormato() {
      this.dialogDelete = false
      await this.$store.dispatch('formatos/delete', this.itemSelected.uuid)
      this.itemSelected = {}
      this.getFormatos(this.$route)
    },
  },

  mounted() {
    console.log('Formatos componente', this.$route)
    this.getFormatos(this.$route)

    this.$nuxt.$on('formatos:load', (payload) => {
      this.getFormatos(this.$route, payload.alcance)
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('formatos:load')
  },
}
</script>
