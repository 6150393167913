import {uuid} from 'vue-uuid'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({rootState, commit}, auditoria_uuid) {
    rootState.loading = true
    const res = await this.$axios.get('checklists?auditoria.uuid=' + auditoria_uuid)
    // const res = await this.$axios.get(`plantilla_checklists`)
    rootState.loading = false
    if (res && res.data) {
      commit('SET_LIST', res.data)
      return res.data['hydra:member']
    }
  },

  async get({rootState}, params) {
    rootState.loading = true
    const res = await this.$axios.get(`checklists/${params.checklist_uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, params) {
    rootState.loading = true
    const body = {
      uuid: uuid.v4(),
      titulo: params.titulo
    }
    await this.$axios.post(`auditorias/${params.auditoria_uuid}/checklists`, body)
    rootState.loading = false
  },

  // async update({rootState}, checklist) {
  //   checklist.checklist_checklist = checklist.uuid
  //   rootState.loading = true
  //   await this.$axios.patch(`auditorias/checklists/${checklist.uuid}`, checklist)
  //   rootState.loading = false
  // },

  async delete({rootState}, checklist) {
    rootState.loading = true
    await this.$axios.delete(`auditorias/checklists/${checklist.uuid}`)
    rootState.loading = false
  }

};
