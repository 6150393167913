<template>
  <div>
<!--    DIBUJAR TABLA-->
  </div>
</template>

<script>
  export default {
    name: "agrocolor-certificados-tabla-productos"
  }
</script>

<style scoped>

</style>
