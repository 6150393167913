/**
 * @project: certiapp-nuxt
 * @file:    public.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 3rd February 2021
 * Last Modified: Wed Feb 03 2021
 **/

export const state = () => ({
  data: {},
});

// commit mutations
export const mutations = {
  GET_DATA(state) {
    return state.data;
  },
  SET_DATA(state, items) {
    state.data = items;
  },
};

// dispatch actions
export const actions = {
  async list({ rootState, commit }, params) {
    rootState.loading = true;
    const res = await this.$axios.get("comunidad");
    rootState.loading = false;
    if (res) {
      commit("SET_LIST", res.data);
      return res.data;
    }
  },
};
