<template>
  <div>

    <v-btn
        color="success"
        @click="controlConfirmation()"
        dark
        fixed
        bottom
        right
        :disabled="disabled"
        fab> 
        <v-icon>mdi-content-save-outline</v-icon>
    </v-btn>

    <!-- CONFIRMACIÓN DE GUARDADO -->
    <confirmation v-model="dialogConfirmSave"
      :text="`¿Desea guardar ${item}?`"
      textButton="Guardar"
      :colorButton="`success`"
      @confirm="submit()"/>

  </div>
</template>

<script>

  import Confirmation from "~/components/Confirmation"

  export default {
    name: "FloatingButtonSave",
    components: {
      Confirmation
    },
    props: {
      valid: {
        type: Boolean
      },
      item: {
        type: String,
        default: 'los datos'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
         dialogConfirmSave: false
      }
    },
    watch: {
      valid: function(e){
        this.valid = e
      }
    },
    methods: {
      controlConfirmation(){
        if (this.valid) this.dialogConfirmSave = true
        else this.submit()
      },
      submit(){
        this.dialogConfirmSave = false
        this.$emit('submit')
      }
    }
  }
</script>
