/**
 * @project: certiapp-nuxt
 * @file:    plantillas-bloques.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st July 2021
 * Last Modified: Fri Dec 30 2022
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // -----
  // Created on Thu Jul 01 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    params = params || {}
    let url = `plantilla_bloques`
    if (params.modulo) url += `?modulo=${params.modulo}`
    if (params.subalcance) url += `&subalcance.uuid=${params.subalcance}`
    const res = await this.$axios.get(url)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  //+-------------------------------------------------
  // get()
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`plantilla_bloques/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // payload:
  // -----
  // Created on Fri Jul 02 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.post(`plantilla_bloques`, {
      uuid: params.uuid || uuid.v4(),
      titulo: params.titulo,
      codigo: params.codigo,
      orden: parseInt(params.orden),
      alcance: 'api/alcances/'+params.alcance,
      subalcance: 'api/subalcances/'+params.subalcance,
      modulo: params.modulo,
      content: [''],
      tipo: 'html',
    })
    if(res) return res.data
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    payload.orden = parseInt(params.orden)
    payload.alcance = 'api/alcances/'+params.alcance
    payload.subalcance = 'api/subalcances/'+params.subalcance.uuid
    await this.$axios.patch(`plantilla_bloques/${params.uuid}`, payload,{
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Mon Jul 05 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`plantilla_bloques/${item.uuid}`)
    rootState.loading = false
  },
}
