<template>
    <div>
        <!-- Confirm -->
        <confirmation v-model="confirm" :text="`¿Desea crear una toma de muestras?`" textButton="Crear" :valid="valid"
            @confirm="createTomaMuestra()" @close="close()">
            <v-form ref="form" lazy-validation v-model="valid">
                <v-container>
                    <v-row align="center" justify="space-around">
                        <v-col cols="8">
                            <v-select label="Producto" v-model="producto" :items="productos" item-text="nombre"
                                return-object required dense></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </confirmation>
        <v-row>

        </v-row>
        <!-- LISTADO -->
        <v-row v-show="!ui.showOffline">
            <v-col cols="6"></v-col>
            
            <v-col cols="12">
                <ag-datatable :key="ui.refresh" title="Listado" :headers="headers" :items="items"  showAddButton emitClickRow
                    @click-row="clickRow" @gridApi="gridApi = $event" :auth="auth"  @click-button="confirm = true "
                    @clickRowDelete="clickRowDelete" @clickRowEdit="clickRow">
                </ag-datatable>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Estados from '~/models/estados'
import AgDatatable from '~/components/Datatable'
import Confirmation from '~/components/Confirmation'
import { uuid } from 'vue-uuid'

export default {
    props: {
        iteracion: {
            type: Object,
            default: () => ({}),
        },
        cliente: { type: String, default: null },
        expediente: { type: String, default: null },
        auditoria: { type: Object, default: () => ({}) },
        sitesAvailable: { type: Array, default: () => [] },
    },
    components: {
        AgDatatable,
        Confirmation,
    },

    data: () => ({
        valid: true,
        headersPinned: true,
        gridApi: null,
        confirm: false,
        items: [],
        db: { items: [] },
        tipos_auditorias: {},
        producto: {},
        auth: {},

        ui: {
            retry: 0,
            refresh: 0,
            showOffline: false,
        },
    }),

    watch: {
        iteracion: {
            handler(newVal, oldVal) {
                if (newVal.uuid !== oldVal.uuid) {
                    this.filterIteracion()
                }
            },
            deep: true,
        },

        isOffline: function (weAreOffline) {
            this.filterOffline(weAreOffline)
        },
    },

    computed: {
        isOffline: function () {
            return this.$nuxt.isOffline
        },

        isCliente() {
            return this.$store.getters.isCliente
        },

        headers() {
            let headers = [
                {
                    text: '',
                    value: 'id',
                },
                {
                    text: 'Marca',
                    value: 'auditoria.subalcance',
                },
                {
                    text: 'Fecha Toma',
                    value: 'createdAt',
                },
                {
                    text: 'Codigo',
                    value: 'codigo',
                },
                {
                    text: 'Producto',
                    value: 'producto.nombre',
                },
                {
                    text:'Personal',
                    value: 'nombrePersonal',
                },
                {
                    text: 'Responsable',
                    value: 'responsable.nombreCompleto',
                },

                {
                    text: 'Cumple/No Cumple',
                    value: 'cumpleNoCumple',
                    cellRenderer: function(params) {
                        const item = params.data;
                        const value = item && item.cumpleNoCumple;
                        return value
                            ? '<i class="v-icon mr-1 mdi mdi-check theme--light" style="font-size: 17px;"></i> Cumple'
                            : '<i class="v-icon mr-1 mdi mdi-close theme--light" style="font-size: 17px;"></i> No cumple';
                    },
                },

                {
                    text: '',
                    value: 'actions',
                }
            ]

            return headers
        },
        allowedToOffline() {
            return this.items.filter(
                (item) => item.estado === Estados.AUDITORIA_ESTADO.ABIERTA //  item.checklists > 0
            )
        },
        auditoria_uuid() {
            return this.auditoria?.uuid ? this.auditoria.uuid : this.$route.params.uuid
            return this.$route.params.uuid
        },
        productos() {
            let productos = []
            if (this.sitesAvailable.length > 0) {
                this.sitesAvailable.forEach(site => {
                    if (site.productos.length > 0) {
                        site.productos.forEach(producto => {
                            productos.push(producto)
                        })
                    }
                })

            }
            return productos
        }
    },
    methods: {
        close() {
            this.site = {}
            this.$refs.form.reset()
        },
        async createTomaMuestra() {
            if (this.$refs.form.validate()) {
                const body = {
                    auditoria: this.auditoria,
                    producto: this.producto,
                    uuid: uuid.v4(),
                }
                body.codigo = this.auditoria.expediente.codigo
                body.responsable = this.auditoria.responsable
                body.uuid = await this.$store.dispatch('tomas_muestra/create', body)
                console.log('body', body)
                this.$router.push({
                    path: `/auditorias/tabs/muestras/${body.uuid}`,
                    query: { auditoria_uuid: this.auditoria_uuid },
                })
                this.confirm = false
            }
        },
        async init() {
            /*
            const body = {}
            if (this.expediente) body.expediente = this.expediente
            // Cliente
            if (this.cliente) body.cliente = this.cliente
            else if (this.isCliente) {
                const clientes = await this.$store.dispatch('clientes/search', this.$auth.user.uuid)
                if (clientes) body.cliente = clientes[0].uuid
            }

            // Auditorias
            const res = await this.$store.dispatch('auditorias/list', body)
            if (res) {
                this.items = []
                this.db.items = res
            }
                */
            
            const res = await this.$store.dispatch('tomas_muestra/list', this.auditoria_uuid)
            if (res) {
                console.log('res', res)
                this.items = res
                this.db.items = res
            }

            this.items = this.items.map((item) => {
                if (item.muestras.some(muestra => muestra.estado === 'CUMPLE')) {
                    item.cumpleNoCumple = true;
                }
                return item;
            });


        },

        clickRow(item) {
            this.$router.push(`/auditorias/tabs/muestras/${item.uuid}?auditoria_uuid=${this.auditoria_uuid}`)
            console.log('clickRow', item)
        },

        clickRowDelete(item) {
            this.$store.dispatch('tomas_muestra/deleteTomaMuestra', item)
            this.items = this.items.filter((i) => i.uuid !== item.uuid)
        },

        async checkRoles() {
            this.auth = await this.$store.dispatch('user/can', {
                module: 'auditorias',
                return: 'all',
            })
        },
    },

    mounted() {

        this.checkRoles()
    },

    async created() {
        await this.init()
    },
}
</script>
