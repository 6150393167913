import {uuid} from 'vue-uuid'

export const state = () => ({
});

//commit mutations
export const mutations = {
};


//dispatch actions
export const actions = {

  async list({commit, params}) {
    const res = await this.$axios.put(`solicitudes-plantillas`, params)
    if (res) return res.data
  },

  async get({commit}, uuid) {
    const res = await this.$axios.get(`solicitudes-plantillas/${uuid}`)
    if (res) return res.data
  },

  async create({commit}, plantilla) {
    let new_uuid = uuid.v4()
    await this.$axios.post(`solicitudes-plantillas`, {
      uuid: new_uuid,
      alcance: plantilla.alcance.uuid,
      tramite: plantilla.tramite.uuid,
      version: plantilla.version.uuid,
      datos: plantilla.datos
    } )
    return new_uuid
  },

  async update({commit}, plantilla) {
    await this.$axios.patch(`solicitudes-plantillas/${plantilla.uuid}`, {
      plantilla: plantilla.uuid,
      datos: plantilla.datos,
      alcance: plantilla.alcance.uuid,
      version: plantilla.version.uuid,
      tramite: plantilla.tramite.uuid
    })
  },

  async delete({commit}, uuid) {
    await this.$axios.delete(`solicitudes-plantillas/${uuid}`)
  },

  async clone({commit}, plantilla) {
    const res = await this.$axios.post(`solicitudes-plantillas/${plantilla.uuid}/clonar`, { plantilla: plantilla.uuid} )
    if (res) return res.data
  },

};
