export const state = () => ({});

//commit mutations
export const mutations = {};


//dispatch actions
export const actions = {

  async situacion({ rootState , commit }, params) {
    console.info('params', params);
    rootState.loading = true
    const body = {
      clientes: params.clientes,
    }
    if (params.desde) body.desde = params.desde
    if (params.hasta) body.hasta = params.hasta
    console.info('body', body);
    const res = await this.$axios.post('auditorias/situaciones', body)
    rootState.loading = false
    if (res) return res.data;
  },

  async pacs({ rootState, commit }, params) {
    console.info('params', params);
    rootState.loading = true
    const body = {}
    if (params.desde) body.desde = params.desde
    if (params.hasta) body.hasta = params.hasta
    console.info('body', body);
    const res = await this.$axios.put('auditorias/informes/pacs', body)
    rootState.loading = false
    if (res) return res.data;
  },

};
