<template>
    <v-dialog v-model="value" persistent max-width="700px">
        <v-card>
        <v-card-title>
            <span v-if="title">{{ title }}</span>
        </v-card-title>
        <v-card-text>

            <v-form ref="form" lazy-validation>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field required :rules="RULES.REQUIRED" persistent-hint v-model="item.nombre" label="Actividad"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-select
                            label="Emplazamiento"
                            v-model="item.parent"
                            :items="parentSites"
                            item-text="nombre"
                            item-value="uuid"
                            :rules="RULES.REQUIRED"
                            return-object
                            :disabled="!isNew"
                            required
                        ></v-select>
                    </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Realizadas"
                    v-model="data.actividades_realizadas"
                    :actividades="actividadesRealizadasETG"
                    required
                    dense
                    smallChips

                  />
                </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Subcontratadas"
                    v-model="data.actividades_subcontratadas"
                    :actividades="actividadesSubcontratadasETG"
                    :disabled="!isResponsable"
                    dense
                    smallChips

                  />
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" class="title black--text">
                  Productos
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Producto"
                    v-model="data.producto"
                    :items="MODEL.PRODUCTO_TIPOS_ETG"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Presentación"
                    v-model="data.presentacion"
                    :items="MODEL.PRESENTACION_TIPOS_ETG"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-select>
                </v-col>

                </v-row>
            </v-card-text>
            </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancelar</v-btn>
        <v-btn color="primary" @click="submit()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectInstalaciones from "~/components/forms/SelectInstalaciones"
import SelectActividades from "~/components/forms/SelectActividades"
import MODEL from "~/models/araporcei/solicitudes"
import RULES from "~/models/form.rules"
import ROLES from '~/models/role'
import customVModelMixin from '~/util/custom.vmodel.mixin'

export default {
  name: "AlcanceProductoDialog",
  mixins: [customVModelMixin],
  props: {
    title: {type: String, default: ''},
    itemSelected: {type: Object, default: null},
    parentSites: {type: Array, default: []},
    firstChildrenSite: {type: Object, default: null},
  },
  components: {
    SelectInstalaciones,
    SelectActividades
  },
   data: () => ({
    MODEL,
    RULES,
    ROLES,
    item: {},
    data: {},
    isResponsable: false,
    isNew: false
  }),
  watch: {
    value(val) {
      if (val === true) {
        this.item = this.itemSelected
        if (this.item && this.item.parent_uuid) {
          const parents = this.parentSites.filter(parent => {
            return parent.uuid === this.item.parent_uuid
          })
          this.item.parent = (parents.length > 0) ? parents[0] : {}
        }

        this.getAuditoria();
      } else {
        this.item = {};
        this.data = {};
      }
      this.formatData();
    }
  },
  computed: {
    submitEventName() {
      return (this.isNew) ? 'create' : 'update';
    },
     actividadesRealizadasETG() {
      return this.isResponsable
        ? MODEL.ACTIVIDADES_ETG
        : MODEL.ACTIVIDADES_ETG.filter(e => !e.responsableOnly)
    },
    actividadesSubcontratadasETG() {
      return MODEL.ACTIVIDADES_ETG.filter(e => !e.responsableOnly)
    }
  },
  methods: {
      async getAuditoria(){
          let uuid = this.$route.params.uuid;
          this.auditoria = await this.$store.dispatch("auditorias/get", uuid)
          console.log(this.auditoria);
          this.checkIfResponsable();
      },

      checkIfResponsable() {
        // Extraido de pages\auditorias\_uuid.vue
        if (this.auditoria && this.auditoria.responsable) {
            const isResponsable = this.$auth.user.uuid === this.auditoria.responsable.uuid
            const isRoot = this.$auth.user.roles.includes(ROLES.ROLE_ROOT)
            const isAuditorAdmin = this.$auth.user.roles.includes(ROLES.ROLE_AUDITOR_ADMIN)
            console.log("responsabilidad", isResponsable, this.$auth.user.uuid, this.auditoria.responsable.uuid);
            console.log("responsabilidad", this.$auth.user.roles.includes(ROLES.ROLE_ROOT));
            console.log("responsabilidad", this.$auth.user.roles.includes(ROLES.ROLE_AUDITOR_ADMIN));

            this.isResponsable = (isResponsable || isRoot || isAuditorAdmin);
        }
    },
    formatData() {
      if (this.item == null) {
        this.data = {};
        this.item = {parent: {}};
        this.isNew = true;
        return;
      }

    this.isNew = false;
      if (this.item && this.item.site_data) {
        for (const property of this.item.site_data) {
          this.data[property.keyname] = property.value;
        }
        console.log(this.data);
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let payload = {
        uuid: this.item.uuid,
        nombre: this.item.nombre,
        data: []
      };


      if (this.isNew) {
        payload = Object.assign({}, this.firstChildrenSite)
        payload.data = []
        payload.uuid = this.$uuid.v4()
        payload.nombre = this.item.nombre
        payload.parent = this.item.parent.uuid
        payload.source_iteracion_uuid = payload.uuid
        payload.source_parent_uuid = this.item.parent.source_iteracion_uuid  //todo: buscar de db el parente_uuid del site marcado como parent.
      }

      for (const item in this.data) {
        payload.data.push({
          'key': item,
          'value': this.data[item]
        });
      }

      this.$emit(this.submitEventName, payload);
      this.$emit('close')
    },
    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },

  }
};
</script>
