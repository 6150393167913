import {uuid} from 'vue-uuid'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({rootState, commit}) {
    rootState.loading = true
    const res = await this.$axios.get(`delegaciones`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`delegaciones/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, delegacion) {
    rootState.loading = true
    await this.$axios.post(`delegaciones`, delegacion)
    rootState.loading = false
  },

  async update({rootState}, delegacion) {
    rootState.loading = true
    await this.$axios.patch(`delegaciones/${delegacion.delegacion}`, delegacion)
    rootState.loading = false
  },

  async delete({rootState}, delegacion) {
    rootState.loading = true
    await this.$axios.delete(`delegaciones/${delegacion.uuid}`)
    rootState.loading = false
  }

};
