<template>
  <div>
    <table style="width: 100%">
      <tbody>
        <tr>
          <td style="text-align: center">
            <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px">
            <h2 style="font-weight: 700" >araporcei</h2>
          </td>
        </tr>
      </tbody>
    </table>

    <p style="text-align: right;">
      <span style="font-weight: 400;">{{ formatDateLong(datos.created_at) }}</span>
    </p>

    <p>
      <strong>{{ clienteNombreCompleto }}</strong>
      <br>
      <strong>{{ datos.cliente.contacto.localidad.nombre }},</strong>
      <strong> {{ datos.cliente.direccion }}</strong>
      <br>
      <strong>{{ clienteDireccion1 }}</strong>
      <br>
      <strong>{{ clienteDireccion2 }}</strong>
    </p>

    <p>
      <strong>ASUNTO: TRAMITACION DE SU SOLICITUD Y APERTURA DEL N&Uacute;MERO DE EXPEDIENTE DE&nbsp; CERTIFICACION&nbsp;</strong>
    </p>
    <p>
      <span style="font-weight: 400;">Estimada Sra.:&nbsp;&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">Conforme a nuestro procedimiento de certificaci&oacute;n, le comunicamos que:&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">
        1&ordm;.- Se ha recibido debidamente cumplimentada y firmada, la Solicitud de Certificaci&oacute;n y Contrato de su&nbsp; empresa,
        {{ clienteNombreCompleto }}, para la certificaci&oacute;n del Real Decreto 4/2014 de 10 de enero por&nbsp;
        el que se aprueba la Norma de Calidad para {{ productos }}.</span>
    </p>
    <p>
      <span style="font-weight: 400;">
        2&ordm;.- Que Araporcei procede a asignarle el siguiente N&uacute;mero de Expediente para iniciar el proceso de&nbsp; certificaci&oacute;n:&nbsp;
      </span>
    </p>
    <p>
      <strong>N&Uacute;MERO DE EXPEDIENTE: {{ datos.codigo }}</strong>
      <strong>&nbsp;</strong>
    </p>
    <p>
      <span style="font-weight: 400;">Cuando la empresa obtenga el Certificado de Conformidad, conforme al RD 4/2014 Art&iacute;culo 4.&nbsp; Etiquetado. Punto 4 b) debe incluir la expresi&oacute;n &laquo;certificado por&raquo; seguida del nombre del organismo&nbsp; independiente de control o su acr&oacute;nimo (ARAPORCEI) en el material de etiquetado y trazabilidad de los&nbsp; productos certificados. Esta expresi&oacute;n deber&aacute; situarse en el etiquetado pr&oacute;xima a la denominaci&oacute;n de&nbsp; venta y de forma visible y siguiendo las instrucciones que Araporcei tiene establecido en su&nbsp; Procedimiento General de &ldquo;Uso correcto de la marca&rdquo; que se le entregar&aacute; cuando obtenga el&nbsp; Certificado.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">Araporcei comunicara a la autoridad competente de su Comunidad Aut&oacute;noma, conforme al&nbsp; procedimiento y los medios por ellos establecidos, la apertura de su Expediente de Certificaci&oacute;n, as&iacute;&nbsp; como el resto de datos que sean solicitados a Araporcei sobre su empresa, a lo largo de todo el proceso&nbsp; de certificaci&oacute;n.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">Sin otro particular reciba un cordial saludo.&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">Mar&iacute;a Silvestre&nbsp;</span>
    </p>
    <p>
      <span style="font-weight: 400;">Secretaria T&eacute;cnica. Araporcei. </span>
    </p>
  </div>
</template>

<style scoped>
table {
  border-collapse: collapse;
}
table, th, td {
  border: 0.5px solid black;
}
.pink-light-araporcei {
  background-color: #EC81B3
}
@media print {
  .pink-light-araporcei {
    background-color: #EC81B3 !important;
    -webkit-print-color-adjust: exact !important;
  }
}
</style>

<script>
import MODELS from "~/models/araporcei/solicitudes"

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    tipoProducto: MODELS.PRODUCTO_TIPO,
  }),
  computed: {
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    clienteDireccion1() {
      let direccion = ''
      const cliente = this.datos.cliente
      const cp = this.datos.cliente.cp
      const municipio = this.datos.cliente.municipio && this.datos.cliente.municipio.nombre
      if (cliente) {
        if (cp) direccion += cp
        if (direccion.length && municipio) direccion += ', '
        if (municipio) direccion += municipio
      }
      return direccion
    },
    clienteDireccion2() {
      let direccion = ''
      const cliente = this.datos.cliente
      const provincia = this.datos.cliente.provincia && this.datos.cliente.provincia.nombre
      const pais = this.datos.cliente.pais && this.datos.cliente.pais.nombre
      if (cliente) {
        if (provincia) direccion += provincia
        if (direccion.length && pais) direccion += ', '
        if (pais) direccion += pais
      }
      return direccion
    },
    productos() {
      const productos = []
      const alcance_producto = this.datos.solicitud.params.alcance_producto
      if (alcance_producto) {
        for (let i=0; i < alcance_producto.length; i++) {
          const element = alcance_producto[i]
          const tipo_producto = this.tipoProducto[element.producto]
          if (!productos.includes(tipo_producto)) productos.push(tipo_producto)
        }
      }
      if (productos.length) {
        const start = productos.slice(0, productos.length - 1)
        const end = productos[productos.length - 1]
        return start.join(', ') + ' y ' + end
      }
      else return ''
    }
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    formatDateLong (date) {
      return date ? this.$moment(date).format('LL') : ''
    },
  }
}
</script>
