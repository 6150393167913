/**
 * @project: certiapp-nuxt
 * @file:    auditorias_ampliacion.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 15th September 2021
 * Last Modified: Thu Feb 23 2023
 **/

import { h } from 'vuelidate/lib/vval'

export const state = () => ({})

export const mutations = {}

export const actions = {
  // +-------------------------------------------------
  // create()
  // -----
  // Created on Wed Sep 15 2021
  // +-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true
    await this.$axios.post(`auditorias/${params.auditoria}/add-ampliacion`, {
      site: params.site,
      auditoria: params.auditoria,
      instalacion: params.instalacion.uuid,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Wed Sep 15 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`auditorias/${params.auditoria}/edit-ampliacion`, {
      auditoria: params.auditoria,
      site: params.site,
      data: params.data,
      original_data: params.originalData,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // This endpoint has changed to /auditorias/{uuid}/removeAmpliacion
  // -----
  // Created on Mon Sep 13 2021
  // Updated on Wed Sep 15 2021
  //+-------------------------------------------------
  async delete({ rootState }, params) {
    rootState.loading = true
    // await this.$axios.delete(`sites/${item.uuid}`)

    await this.$axios.patch(
      `auditorias/${params.auditoria}/removeAmpliacion`,
      {
        producto: params.site,
        removeFromIteracion: false,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  async deleteAlsoFromIteracion({ rootState }, params) {
    rootState.loading = true
    // await this.$axios.delete(`sites/${item.uuid}`)

    await this.$axios.patch(
      `auditorias/${params.auditoria}/removeAmpliacion`,
      {
        producto: params.site,
        removeFromIteracion: true,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },
}
