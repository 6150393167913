import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({commit}, params) {
    const res = await this.$axios.get('formaciones', params)
    if (res) {
      const data = res.data
      commit('SET_LIST', data)
      return data
    }
  },

  async situacion({commit}, params) {
    console.info('params', params);
    const body = {
      cliente: params.cliente,
      grupo_formacion: params.grupo_formacion
    }
    if (params.desde !== ''){
      body.desde = params.desde
    }
    if (params.hasta !== ''){
      body.hasta = params.hasta
    }
    console.info('body', body);
    const res = await this.$axios.post('formaciones-productores/situacion', body)
    if (res) return res.data;
  },

  async delete({rootState}, params) {
    rootState.loading = true
    await this.$axios.delete(`formaciones/${params.uuid}`)
    rootState.loading = false
  },

  // Productores

  async getProductores({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`formaciones-productores/${uuid}`)
    rootState.loading = false
    if (res) return res.data;
  },

  async createProductores({rootState}, params) {
    rootState.loading = true
    const body = {
      uuid: params.uuid,
      grupo_formacion: params.grupo_formacion,
      tipo_formacion: params.tipo_formacion,
      titulo: params.titulo,
      lugar: params.lugar,
      auditor: params.auditor,
      fecha: params.fecha,
      cliente: params.cliente,
      sites: params.sites,
      ciclo: params.ciclo,
      descripcion: params.descripcion
    }
    await this.$axios.post('formaciones-productores', body)
    rootState.loading = false
  },

  async updateProductores({rootState}, params) {
    rootState.loading = true
    const body = {
      formacion: params.uuid,
      titulo: params.titulo,
      grupo_formacion: params.grupo_formacion,
      lugar: params.lugar,
      auditor: params.auditor,
      fecha: moment(params.fecha).format('YYYY-MM-DD'),
      ciclo: params.ciclo,
      descripcion: params.descripcion,
      completados: params.completados
      // cliente: params.cliente,
      // sites: params.sites,
    }
    await this.$axios.patch(`formaciones-productores/${params.uuid}`, body)
    rootState.loading = false
  },

  async pendientesProductores({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`formaciones-productores/${uuid}/sites-pendientes`)
    rootState.loading = false
    if (res) return res.data;
  },

  // Asistentes Productores

  async asistentesProductores({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`formaciones-productores/${uuid}/asistentes`)
    rootState.loading = false
    if (res) return res.data;
  },

  async createAsistentesProductores({rootState}, params) {
    rootState.loading = true
    const body = {
      uuid: params.uuid ? params.uuid : uuid.v4(),
      formacion: params.formacion,
      site: params.site,
      nombre: params.nombre,
      dni: params.dni,
      is_encargado: (params.is_encargado) ? 1: 0
    }
    await this.$axios.post(`formaciones-productores/${params.formacion}/asistentes`, body)
    rootState.loading = false
  },

  async updateAsistentesProductores({rootState}, params) {
    rootState.loading = true
    const body = {
      asistente: params.uuid,
      formacion: params.formacion,
      site: params.site,
      nombre: params.nombre,
      dni: params.dni,
      is_encargado: (params.is_encargado) ? 1: 0
    }
    await this.$axios.patch(`formaciones-productores/${params.formacion}/asistentes/${params.uuid}`, body)
    rootState.loading = false
  },

  async deleteAsistentesProductores({rootState}, params) {
    rootState.loading = true
    await this.$axios.delete(`formaciones-productores/${params.formacion}/asistentes/${params.uuid}`)
    rootState.loading = false
  },

  // Tecnicos

  async getTecnicos({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`formaciones-tecnicos/${uuid}`)
    rootState.loading = false
    if (res) return res.data;
  },

  async createTecnicos({rootState}, params) {
    rootState.loading = true
    const body = {
      uuid: params.uuid,
      grupo_formacion: params.grupo_formacion,
      tipo_formacion: params.tipo_formacion,
      titulo: params.titulo,
      lugar: params.lugar,
      tecnico_auditor: params.tecnico_auditor,
      fecha: params.fecha,
      tecnicos: params.tecnicos,
      ciclo: params.ciclo,
      descripcion: params.descripcion
    }
    await this.$axios.post('formaciones-tecnicos', body)
    rootState.loading = false
  },

  async updateTecnicos({rootState}, params) {
    rootState.loading = true
    const body = {
      formacion: params.uuid,
      titulo: params.titulo,
      grupo_formacion: params.grupo_formacion,
      lugar: params.lugar,
      tecnico_auditor: params.tecnico_auditor,
      fecha: moment(params.fecha).format('YYYY-MM-DD'),
      ciclo: params.ciclo,
      descripcion: params.descripcion
      // tecnicos: params.tecnicos,
    }
    await this.$axios.patch(`formaciones-tecnicos/${params.uuid}`, body)
    rootState.loading = false
  },

  async pendientesTecnicos({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`formaciones-tecnicos/${uuid}/tecnicos-pendientes`)
    rootState.loading = false
    if (res) return res.data;
  },

  // Asistentes Tecnicos

  async asistentesTecnicos({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`formaciones-tecnicos/${uuid}/asistentes`)
    rootState.loading = false
    if (res) return res.data;
  },

  async createAsistentesTecnicos({rootState}, params) {
    rootState.loading = true
    const body = {
      uuid: uuid.v4(),
      formacion: params.formacion,
      tecnico: params.tecnico,
    }
    await this.$axios.post(`formaciones-tecnicos/${params.formacion}/asistentes`, body)
    rootState.loading = false
  },

  async deleteAsistentesTecnicos({rootState}, params) {
    rootState.loading = true
    await this.$axios.delete(`formaciones-tecnicos/${params.formacion}/asistentes/${params.uuid}`)
    rootState.loading = false
  },

  // Categorias

  async listCategorias({commit}, params) {
    const res = await this.$axios.get('formaciones-categorias', params)
    if (res) return res.data
  },

  async createCategorias({rootState}, params) {
    rootState.loading = true
    const body = {
      ...params,
      uuid: uuid.v4()
    }
    await this.$axios.post(`formaciones-categorias`, body)
    rootState.loading = false
  },


  async updateCategorias({rootState}, params) {
    rootState.loading = true
    const body = {
      grupo_formacion: params.uuid,
      titulo: params.titulo
    }
    await this.$axios.patch(`formaciones-categorias/${params.uuid}`, body)
    rootState.loading = false
  },

  async deleteCategorias({rootState}, params) {
    rootState.loading = true
    await this.$axios.delete(`formaciones-categorias/${params.uuid}`)
    rootState.loading = false
  },

};
