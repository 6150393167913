<template>
  <div>
    <component v-if='isDefault === false'
               :is='ampliacionDialogComponentName'
               v-model='dialogAmpliacion'
               title='Certificación'
               :alcance='alcance'
               :parentSites='parentSites'
               :firstChildrenSite='firstChildrenSite'
               :itemSelected='itemSelected'
               @create='create($event)'
               @update='update($event)'
               @close="$emit('close')">
    </component>

    <!-- <DefaultAmpliacion v-else></DefaultAmpliacion> -->
  </div>
</template>

<script>
// import AmpliacionAraporcei from '~/components/solicitudes/araporcei/AlcanceProducto/AlcanceProductoDialog';
// import DefaultAmpliacion from '~/components/auditorias/certificacion/DefaultAmpliacion'
import customVModelMixin from '~/util/custom.vmodel.mixin'

// import AmpliacionAraporcei from '~/components/solicitudes/araporcei/AlcanceProducto/AlcanceProductoDialog';
import Ampliacionaraporceietg from '~/components/auditorias/certificacion/araporcei/etg/AlcanceProductoDialog'
import Ampliacionaraporceiglobalgap_new from '~/components/auditorias/certificacion/araporcei/globalgap/AlcanceProductoDialog'
import Ampliacionaraporceiproductos_ibericos from '~/components/auditorias/certificacion/araporcei/productos-ibericos/AlcanceProductoDialog'
import Ampliacioncclproduccion_ecologica from '~/components/auditorias/certificacion/ccl/productos-ecologicos/AlcanceProductoDialog'
import Ampliacioncerticarcerticariberico from '~/components/auditorias/certificacion/certicar/iberico/AlcanceProductoDialog'

export default {
  mixins: [customVModelMixin],
  props: {
    alcance: { type: [String, Object], default: null },
    itemSelected: { type: Object, default: null },
    parentSites: { type: Array, default: [] },
    childrenSites: { type: Array, default: [] },
    planificaciones: { type: Array, default: () => [] },
  },
  components: {
    Ampliacionaraporceietg,
    Ampliacionaraporceiglobalgap_new,
    Ampliacionaraporceiproductos_ibericos,
    Ampliacioncclproduccion_ecologica,
    Ampliacioncerticarcerticariberico,
    // DefaultAmpliacion,
  },
  data: () => ({
    dialogAmpliacion: false,
    isDefault: null,
  }),
  watch: {
    value(val) {
      this.dialogAmpliacion = val
    },
  },
  computed: {
    firstChildrenSite() {
      return this.childrenSites[0] || this.parentSites[0]
    },

    ampliacionDialogComponentName() {
      if (this.isDefault == true) return 'DefaultAmpliacion'
      let alcance = this.alcance?.codigo ? this.alcance.codigo : this.alcance
      alcance = alcance?.replace('-', '')

      return 'Ampliacion' + this.$auth.user.empresa.codigo + alcance
    },
  },
  methods: {
    determineComponentName() {
      let thisName = this.ampliacionDialogComponentName
      this.isDefault = this.$options.components[thisName] !== undefined ? false : true
    },

    create(item) {
      this.save(item, 'create')
    },

    update(item) {
      this.save(item, 'update')
    },

    async save(item, event = 'create') {

      this.loading = true

      const body_site = {
        ...item,
      }

      try {

        if (event === 'create') {
          await this.$store.dispatch('sites/' + event, body_site)
          await this.$store.dispatch('auditorias_ampliacion/create', {
            site: item.uuid,
            auditoria: this.$route.params.uuid,
            instalacion: body_site.instalacion,
          })
        } else {
          await this.$store.dispatch('auditorias_ampliacion/update', {
            site: item.uuid,
            auditoria: this.$route.params.uuid,
            data: body_site.data,
          })
        }

        this.$store.commit('notification/show', {
          text: 'Cambios guardados correctamente',
          color: 'success',
        })

        this.dialog = false
        this.$emit('init')
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error creando la ampliación',
          color: 'error',
          timeout: 3000,
        })
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.determineComponentName()
  },
}
</script>
