<template>
  <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro */">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-card-text class="text-center">
            <v-icon class="display-1" color="red lighten-1">mdi-alert-circle-outline</v-icon>
            <h1 class="headline mb-2">¡Vaya! Algo salió mal</h1>
            <p class="subheading
            "> {{ errorMessage }}</p>
            <v-btn text @click="navigateHome" color="info" dark>Ir a la página principal</v-btn>
            <v-btn text @click="refreshPage" color="info" dark>Volver</v-btn>
            </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  layout: 'blank-layout',
  data() {
    return {
      errorMessage:
        '¡Oh no! Parece que hemos encontrado un problema inesperado. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, póngase en contacto con su administrador.',
    }
  },
  methods: {
    navigateHome() {
      this.$router.push('/dashboard')
    },
    refreshPage() {
      this.$router.go()
    },
  },
}
</script>
