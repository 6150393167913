<template>
  <div>
    <template v-if="!hide">
      <span v-if="auth && auth.canEdit">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              style="font-size: 20px"
              color="info"
              v-on="on"
              @click.stop="goTo(`/revisiones/${params.data.uuid}`)"
              >mdi-eye-outline</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>
      </span>
      <span v-if="auth && auth.canAmpliarRevision">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              style="font-size: 20px"
              color="deep-purple"
              class="mx-2"
              v-on="on"
              @click.stop="dialogProponer = true"
              >mdi-playlist-check</v-icon
            >
          </template>
          <span>Ampliar revisión</span>
        </v-tooltip>
      </span>

      <span v-if="auth && auth.canDelete">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              style="font-size: 20px"
              color="error"
              v-on="on"
              @click.stop="confirmDelete = true"
              >mdi-delete-outline</v-icon
            >
          </template>
          <span>Borrar</span>
        </v-tooltip>
      </span>
    </template>

    <!-- Confirmación -->

    <confirmation
      v-model="confirmDelete"
      :text="'¿Eliminar revision?'"
      colorButton="error"
      @confirm="deleteItem()" />

    <confirmation
      v-model="confirmDesaprobar"
      text="¿Confirma que desea eliminar la aprobación de la solicitud?"
      textButton="Aceptar"
      @confirm="desaprobarSolicitud()" />

    <proponer-dialog
      :proposicion="params.data"
      v-model="dialogProponer"
      @close="dialogProponer = false"
      @confirm="ampliarRevision($event)" />
  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'
import ProponerDialog from '~/components/revisiones/ProponerDialog'
export default {
  components: {
    Confirmation,
    ProponerDialog,
  },
  data: function () {
    return {
      confirmAprobar: false,
      confirmDelete: false,
      confirmDesaprobar: false,
      dialogProponer: false,
      auth: {},
    }
  },
  computed: {
    isAprobada() {
      return this.params.data.estado === 'APROBADA'
    },

    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_EDIT() {
      return this.IS_LIST ? this.$store.state.permisos.can_edit : true
    },
    CAN_DELETE() {
      return this.IS_LIST ? this.$store.state.permisos.can_delete : true
    },
    isNotGroup() {
      return !!this.params.data
    },
    hide() {
      return this.params.value === true
    },
  },
  methods: {
    init() {
      if (this.params.init instanceof Function) this.params.init()
    },
    goTo(path) {
      this.$router.push(path)
    },
    async ampliarRevision(event) {
      const body = {
        proposicion: event.proposicion,
        auditor1: event.auditor1.uuid,
        enCalidadDeAuditor1: event.enCalidadDeAuditor1,
        observacionesAuditor1: event.observacionesAuditor1,
        auditor2: event.auditor2 ? event.auditor2.uuid : null,
        enCalidadDeAuditor2: event.enCalidadDeAuditor2 ?? null,
        observacionesAuditor2: event.observacionesAuditor2 ?? null,
        fechaProposicion: event.fechaProposicion ?? null,
        uuid: this.item.uuid,
      }
      console.log('ampliarRevision -> body', body)

      await this.$store.dispatch(`revisiones/proponer`, body)
      if (event.set_revisada) {
        await this.$store.dispatch(`revisiones/cambiarEstado`, { uuid: this.params.uuid })
      }
      this.dialogProponer = false
      // this.$store.dispatch('revisiones/ampliar', item)
      this.init()
    },
    async deleteItem() {
      this.confirmDelete = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      if (this.params.node.data.uuid) {
        await this.$store.dispatch(`revisiones/delete`, this.params.node.data)
        this.init()
      }
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        submodule: 'revisiones',
        return: 'all',
      })
      console.log('auth', this.auth)
    },
  },

  async mounted() {
    this.checkRoles()
  },
}
</script>
