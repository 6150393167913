/**
 * @project: certiapp-nuxt
 * @file:    \store\mensajes.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 10th January 2023
 * Last Modified: Fri Feb 10 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Created on Tue Jan 10 2023
  //+-------------------------------------------------
  async list({ rootState }, type) {
    rootState.loading = true
    let res = null
    if (type == 'enviados'){
      res = await this.$axios.get(`mensajes?fromUser=${this.$auth.user.uuid}`)
    }

    else if (type == 'recibidos'){
      res = await this.$axios.get(`mensajes?toUsers=${this.$auth.user.uuid}`)
    }
    if (res) return res.data['hydra:member']
    rootState.loading = false

  },

  //+-------------------------------------------------
  // get()
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`mensajes/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // -----
  // Created on Tue Jan 10 2023
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    payload.is_sent = true
    payload.is_read = false
    payload.is_starred = false
    payload.is_received = false

    await this.$axios.post(`mensajes`, payload)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    await this.$axios.patch(`mensajes/${params.uuid}`, payload)

    rootState.loading = false
  },

  async star({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    if (typeof payload.from_user === 'object') payload.from_user = payload.from_user.uuid
    delete payload.to_users

    await this.$axios.patch(`mensajes/${params.mensaje}`, payload)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // read()
  //
  // -----
  // Created on Fri Feb 10 2023
  //+-------------------------------------------------
  async read({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    if (typeof payload.user === 'object') payload.user = payload.user.uuid

    await this.$axios.post(`mensajes/marcar-leido`, payload)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Mon Jul 05 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`mensajes/${item.uuid}`)
    rootState.loading = false
  },
}
