<template>
  <div>
    <v-card class="mt-5">
      <v-card-title>
        Certificación
        <v-spacer></v-spacer>
        <v-btn small depressed color="primary" :disabled="!items.length || auditoriaCerrada" @click="newItem">
          Añadir ampliación
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="mt-0 pt-0">
            <Datatable
                :showHeader="false"
                :headers="headers"
                :items="items"
                :disableClickRow="false"
                :disableHover="true"
                :key="component"
                :emitClickRow="true"
                @click-row="editItem"
            >
              <template v-slot:item.certificado="{item}">
                <v-checkbox v-model="item.certificado" @change="updateSiteCertificado(item)"></v-checkbox>
              </template>

              <template v-slot:item.tipo="{item}">
                <span class="text-capitalize">{{ item.tipo }}</span>
              </template>

              <template v-slot:item.emplazamiento="{item}">
                  <span :title="item.uuid">
                    {{
                    item.parent_uuid
                        ? sitesObject[item.parent_uuid] && sitesObject[item.parent_uuid].nombre
                        : sitesObject[item.uuid] && sitesObject[item.uuid].nombre
                    }}
                  </span>
              </template>

              <template v-slot:item.actividad="{item}">
                <div v-if="!offlineMode">{{ item.nombre }}</div>

                <v-edit-dialog
                    v-else
                    :ref="'siteNombre' + item.uuid"
                    :return-value.sync="item.nombre"
                    @save="updatSiteNombre(item)"
                    @open="updatSiteNombre(item, true)"
                    @close="oldSiteNombre = ''"
                >
                  {{ item.nombre }}
                  <!-- {{ sitesObject[item.uuid] && sitesObject[item.uuid].nombre }} -->
                  <template v-slot:input>
                    <v-text-field
                        v-model="item.nombre"
                        single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </Datatable>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <Ampliacion v-model="dialog"
        :alcance="alcance"
        :planificaciones="planificaciones"
        :parentSites="parentSites"
        :childrenSites="childrenSites"
        :itemSelected="itemSelected"
        @close="dialog = false"
        @init="$emit('init')">
    </Ampliacion>

  </div>
</template>

<script>
import Ampliacion from "~/components/auditorias/Ampliacion"
import Datatable from "~/components/Datatable"

export default {
  props: {
    tipo_operador: {type: String, default: ''},
    cliente: {type: String, default: ''},
    alcance: {type: String, default: ''},
    planificaciones: {type: Array, default: () => []},
    sites: {type: Array, default: () => []},
    auditoriaCerrada: {type: Boolean, default: false},
  },
  components: {
    Datatable,
    Ampliacion
  },
  data: () => ({
    component: 0,
    dialog: false,
    itemSelected: {},
    item: {
      nombre: '',
      site_uuid: '',
      site: {},
      data: []
    },
    rules: [v => !!v || 'Obligatorio'],
  }),
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      }
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    planificacionesSites() {
      return this.planificaciones.map(e => e.site.uuid)
    },
    childrenSites() {
        let parents = [];
        const plan_sites = this.planificaciones.map(e => e.site);
        const hasChildSites = this.checkIfHasChildSites();

        plan_sites.map(plansite => {
            this.sites.map(site => {
                if(site.parent_uuid === undefined ){
                    if(hasChildSites == false && plansite.uuid == site.uuid){
                        parents.push(site);
                    }
                }else{
                    if(site.parent_uuid == plansite.uuid){
                        parents.push(site);
                    }
                }
            })
        });
      return parents;
    },
    parentSites() {
      return this.sites.filter(e => !e.parent_uuid)
    },
    sitesObject() {
      return this.sites.reduce((obj, element) => {
        obj[element.uuid] = element
        return obj
      }, {})
    },
    items() {
      return this.childrenSites
    },
    headers() {
      const headers = [
        {text: "Habilitado", align: "left", value: "certificado"},
        {text: "Emplazamiento", align: "left", value: "emplazamiento"},
        {text: "Tipo", align: "left", value: "tipo"},
      ]
      if (this.childrenSites.length) {
        headers.push({text: "Nombre", align: "left", value: "actividad"})
      }
      return headers
    }
  },
  methods: {
    newItem(){
        this.itemSelected = null;
        this.dialog = true;
    },

    editItem(item) {
      console.info('click item:', item);
      this.itemSelected = item
      // item.site_data.map((node) => {
      //   this.itemSelected.data[node.keyname] = node.value
      // })
      this.dialog = true
    },

    checkIfHasChildSites(){
        for (const site of this.sites) {
            if (site.hasOwnProperty("parent_uuid")) {
                return true;
            }
        }
        return false;
    },

    async updatSiteNombre(item, open) {
      const dialog = 'siteNombre' + item.uuid
      if (open) this.oldSiteNombre = this.$refs[dialog].returnValue
      else {
        try {
          const body = {
            ...item
          }
          await this.$store.dispatch('sites/update', body)
        } catch (e) {
          item.nombre = this.oldSiteNombre
          this.component++
        }
      }
    },
    async updateSiteCertificado(item) {
      const body = {
        auditoria: this.$route.params.uuid,
        site: item.uuid,
        certificado: item.certificado
      }
      await this.$store.dispatch(`auditorias/updateSiteCertificado`, body)
    },
  }
}
</script>
