<template>
  <div>
    <!-- HEADER -->
    <!-- <header-component noAction v-if="!uuid"/> -->

    <v-row class="align-center">
      <v-col cols="6">
        <h1 v-if="!ui.showLote">Dictámenes</h1>
        <h1 v-else>
          <template v-if="$refs.lotes && $refs.lotes.ui.step == 0">
            {{ ui.panel == 'revisiones' ? 'Revisiones abiertas' : '' }}
            {{ ui.panel == 'dictamenes' ? 'Dictamenes pendientes' : '' }}
          </template>
          <template v-else> Lote de dictámenes </template>
        </h1>
        <!-- <h1 v-else>
          <template v-if="$refs.lotes && $refs.lotes.ui.step == 0">
            Revisiones pendientes de dictaminar
          </template>

          </h1> -->
      </v-col>

      <v-col cols="6" style="text-align: right">
        <v-btn
          depressed
          outlined
          v-if="auth.lotes && auth.lotes.canCreate"
          @click="ui.showLote = !ui.showLote">
          <v-icon small class="pr-2">mdi-folder-plus-outline</v-icon>
          <template v-if="ui.showLote"> Volver </template>
          <template v-else> Nuevo dictámen en lote </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="align-center" v-show="ui.showLote && $refs.lotes && $refs.lotes.ui.step == 0">
      <v-col cols="6" class="pb-0">
        <v-btn-toggle
          mandatory
          v-model="ui.panel"
          color="deep-purple accent-3"
          style="transform: translateX(-5px)"
          group>
          <v-btn
            small
            text
            style="border-radius: 3px"
            value="revisiones"
            @click="$refs.lotes.selected = []">
            Revisiones abiertas
          </v-btn>

          <v-btn
            small
            text
            style="border-radius: 3px"
            value="dictamenes"
            @click="$refs.lotes.selected = []">
            Dictamenes pendientes
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-show="ui.showLote" class="my-2 mb-5">
      <v-col>
        <lotesCRUD ref="lotes" lote="Dictamenes" :type="ui.panel" :items="lotesDB" />
      </v-col>
    </v-row>

    <!-- LISTADO -->
    <v-row v-show="!ui.showLote">
      <v-col cols="12">
        <ag-datatable
          :headers="columnDefs"
          :items="items"
          urlData="dictamenes"
          :emitClickRow="true"
          @click-row="clickRow"></ag-datatable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    index.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Wed Jun 14 2023
 **/

import Estado from '~/models/estados'
import lotesCRUD from '~/components/lotes/crud'
import AgDatatable from '~/components/AgDataTableNew'
import HeaderComponent from '~/components/HeaderComponent'

export default {
  props: ['uuid', 'iteracion'],
  components: {
    AgDatatable,
    lotesCRUD,
    HeaderComponent,
  },
  data: () => ({
    items: [],
    db: { items: [] },

    REVISADA_OK: Estado.AUDITORIA_ESTADO.REVISADA_OK,
    gridOptions: null,
    gridApi: null,
    columnApi: null,
    columnDefs: null,
    frameworkComponents: null,
    rowData: [],
    sideBar: {},

    auth: {},

    ui: {
      panel: null,
      showLote: false,
    },
  }),

  watch: {
    iteracion: {
      handler(newVal, oldVal) {
        if (newVal.uuid !== oldVal.uuid) {
          this.filterIteracion()
        }
      },
      deep: true,
    },
  },
  created() {
    if (!this.uuid) this.$store.commit('menu/setPageTitle', 'Dictamenes')
  },
  beforeMount() {
    this.gridOptions = {}
    this.columnDefs = [
      {
        headerName: 'Cliente',
        field: 'revision.auditoria.cliente.nombreCompleto',
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Código de expediente',
        field: 'revision.auditoria.expediente.codigo',
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Fecha auditoria',
        field: 'revision.auditoria.desde',
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.revision.auditoria.desde
          return value ? this.$moment(value).format('DD/MM/YYYY') : ''
        },
      },
      {
        headerName: 'Alcance',
        field: 'revision.auditoria.expediente.subalcance',
        cellRendererFramework: 'SubalcanceRendererColor',
      },
      {
        headerName: 'Auditor',
        field: 'auditor1.nombreCompleto',
      },
      {
        headerName: 'Fecha creación',
        field: 'createdAt',
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.createdAt
          return value ? this.$moment(value).format('DD/MM/YYYY') : ''
        },
      },
      {
        headerName: 'Fecha proposición',
        field: 'fechaProposicion',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.fechaProposicion
          return value ? this.$moment(value).format('DD/MM/YYYY') : ''
        },
      },
      {
        headerName: 'Proposición',
        field: 'proposicion',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: function (params) {
          if (params.value === Estado.DICTAMEN_DECISION_PROPOSICION.APTO)
            return { color: '#43A047' }
          else if (params.value === Estado.DICTAMEN_DECISION_PROPOSICION.NO_APTO)
            return { color: '#EF5350' }
          else return null
        },
      },
      {
        headerName: 'Fecha decisión',
        field: 'fechaDecision',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.fechaDecision
          return value ? this.$moment(value).format('DD/MM/YYYY') : ''
        },
      },
      {
        headerName: 'Decisión',
        field: 'decision',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: function (params) {
          if (params.value === Estado.DICTAMEN_DECISION_PROPOSICION.APTO)
            return { color: '#43A047' }
          else if (params.value === Estado.DICTAMEN_DECISION_PROPOSICION.NO_APTO)
            return { color: '#EF5350' }
          else return null
        },
      },
      {
        headerName: 'Acción',
        field: 'accion',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.accion
          return value ? value.toUpperCase() : ''
        },
      },
      {
        headerName: 'Estado',
        field: 'estado',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: function (params) {
          if (params.value === Estado.DICTAMEN_ESTADO.PENDIENTE) return { color: '#2196F3' }
          else if (params.value === Estado.DICTAMEN_ESTADO.OK) return { color: '#43A047' }
          else if (params.value === Estado.DICTAMEN_ESTADO.KO) return { color: '#EF5350' }
          else return null
        },
      },
      {
        headerName: 'Acciones',
        cellRendererFramework: 'DictamenActionRenderer',
        sortable: false,
        filter: false,
        resizable: true,
        width: 100,
        pinned: 'right',
      },
      
    ]
    this.sideBar = {
      toolPanels: ['filters', 'columns'],
    }
  },

  computed: {
    dictamenes() {
      if (!this.items) return this.items
      else {
        return this.items.filter((auditoria) => {
          return auditoria.estado === this.REVISADA_OK
        })
      }
    },
    lotesDB() {
      if (this.ui.panel === 'revisiones') return this.db.revisiones

      if (this.ui.panel === 'dictamenes')
        return this.db.items.filter((auditoria) => {
          // return !auditoria.fecha_decision
          return !auditoria.decision
        })
    },
  },
  methods: {
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit()
      params.api.forEachNode(function (node) {
        // node.setExpanded(true);
      })
    },
    onGridReady(params) {
      params.api.addEventListener('cellClicked', this.onCellClick)
    },
    // Edición de celdas al pinchar
    clickRow(data) {
      this.$router.push({ path: `/dictamenes/${data.uuid}` })
    },
    onCellClick(params) {
      if (params.colDef.headerName === 'Expediente') {
        // TODO: Necesitamos el grupo de alcance par ir a la edición del expediente
        // let empresa = this.$auth.user.empresa.codigo
        // let grupo_alcance = ''
        // this.$router.push({ path: `/expedientes/${empresa}/${grupo_alcance}/${params.data.expediente.uuid}` })
        this.$router.push({ path: `/expedientes` })
      } else if (params.colDef.headerName === 'Cliente') {
        this.$router.push({ path: `/clientes/${params.data.cliente.uuid}` })
      } else if (params.colDef.headerName === 'Alcance') {
        this.$router.push({ path: `/alcances/${params.data.alcance.uuid}` })
      } else
        this.$router.push({
          path: `/auditorias/${params.data.uuid}?tab=0`,
          query: { dictamen: true },
        })
    },

    //+-------------------------------------------------
    // filterIteracion()
    // Select only items where iteracion match the prop
    // -----
    // Created on Mon Nov 29 2021
    //+-------------------------------------------------
    filterIteracion() {
      if (!this.iteracion?.uuid) {
        this.items = [...this.db.items]
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.iteracion_uuid == this.iteracion?.uuid
      })

      this.items = items
    },

    async checkRoles() {
      this.auth.lotes = await this.$store.dispatch('user/can', {
        module: 'dictamenes',
        submodule: 'lotes',
      })
    },

    async getRevisiones() {
      const xhr = await this.$store.dispatch('revisiones/list')
      if (xhr) {
        this.db.revisiones = xhr.filter((item) => item.estado == 'PENDIENTE')
      }
      this.filterIteracion()
    },

    async getDictamenes() {
      const res = await this.$store.dispatch('dictamenes/list', this.uuid)
      if (res) {
        this.items = res
        this.db.items = this.items
        this.filteredItems = this.items
      }
    },

    async init() {
      this.checkRoles()
      await this.getDictamenes()
      this.filterIteracion()

      // Ag-grid
      this.gridApi = this.gridOptions.api
      this.gridColumnApi = this.gridOptions.columnApi

      await this.getRevisiones()
    },
  },

  async mounted() {
    this.init()

    this.$nuxt.$on('lotes:stored', (payload) => {
      this.init()
      this.ui.showLote = false
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('lotes:stored')
  },
}
</script>
