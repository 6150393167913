export default {
    'configuracion' : null,
    'logs': 'rlogs',
    'mensajes': 'mensajes',
    'permisos': 'permisos',
    'listas-de-validacion': 'listas_validacion',
    'plantillas-de-formatos': 'formatos/plantillas',
    'estaticos': 'estaticos',
    'com-internas': 'comunicaciones',
    'conf-checklists': 'configuraciones/checklists',
    'localidades': 'localidades',
    'bloques': 'bloques',
    'compartir': 'compartir',
    'backups': 'backups',
    'personal': null,
    'contactos': 'personal/agenda',
    'usuarios': 'personal/usuarios',
    'clientes': 'personal/clientes',
    'auditores': 'personal/auditores',
    'personal-interno': 'personal/personal-interno',
    'documentos': 'documentos',
    'alcances': 'alcances',
    'solicitudes': null,
    'nueva-solicitud': 'grupo_solicitudes/nueva',
    'solicitudes': 'grupo_solicitudes',
    'expedientes': 'expedientes',
    'planificador': 'planificador',
    'auditorias': null,
    'auditorias': 'auditorias',
    'checklists': 'auditorias/checklists',
    'planes': 'auditorias/planes',
    'revisiones': null,
    'revisiones': 'revisiones',
    'lotes': 'revisiones/lotes',
    'dictamenes': null,
    'dictamenes': 'dictamenes',
    'dictamenes-lotes': 'dictamenes/lotes',
    'certificados': 'certificados',
    'informes': 'informes',
    'noticias': 'newsboard',
};