<template>
  <div class="mx-2">
    <table>
      <tr>
        <td class="pb-12 pt-2 px-2 caption" style="border: 0.5px solid black;">
          <div class="mb-12" style="border-color: 0.5px black !important;">{{ componente.text.firmado['es_ES'] }}</div>
        </td>
      </tr>
    </table>
    <div class="caption text-center pt-2">
      {{ componente.text.nombre['es_ES'] }}
    </div>
  </div>
</template>

<style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
  }

  table, th, td {
    border: 0.5px solid black;
  }
</style>

<script>
export default {
  props: {
    componente: { type: Object, default: () => {} },
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
  }),
}
</script>
