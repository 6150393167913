<template>
  <div>
    
    <p style="text-align: center; white-space: pre-line; font-size: 20px; font-weight: bold;">
      CERTIFICADO Nº: {{alcance}} / {{certificado}}
    </p>

  </div>
</template>

<script>

export default {
  name: "numero-certificados",
  props: {
    componente: { type: Object, default: {} }
  },
  components: {
    
  },
  data() {
    return {
      certificado: '',
      alcance: ''
    };
  },
  watch: {

  },
  async mounted() {    
    this.certificado = this.componente.certificado
    this.alcance = this.componente.alcance
  },
  methods: {

  }
};
</script>

<style scoped>

</style>
