<template>
  <div v-if="isNotGroup">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <a href="" v-on="on" @click.stop.prevent="mailTo(params.value)" style="color:#0000EE !important;">
          {{ params.value }}
        </a>
      </template>
      <span>Enviar email a <span class="font-weight-medium">{{ params.value }}</span></span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  computed: {
    isNotGroup () {
      return !!this.params.data
    },
  },
  methods: {
    mailTo(email) {
      document.location.href = `mailto:${email}`
    }
  }
}
</script>

