<template>
<div>
  <v-autocomplete
    ref="contacto"
    label="Contacto"
    v-model="selected"
    :items="items"
    :item-text="text"
    item-value="uuid"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :dense="dense"
    :multiple="multiple"
    @change="$emit('input', selected)"
  >
    <template v-slot:append-outer v-if="create">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn x-small fab depressed
            :disabled="!cliente || !cliente.uuid"
            v-on="on"
            @click="ui.quickCreate = true"
            color="success" class="white--text"
            style="transform: translateY(-4px);">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Crear contacto asociado</span>
      </v-tooltip>
    </template>
  </v-autocomplete>

  <!-- Modal de creación -->
  <quick-create
    ref="quickCreate"
    :_ui="ui"
    @created="onContactCreated">
  </quick-create>

</div>

</template>

<script>
import quickCreate from '~/pages/personal/widgets/quickCreate'

export default {
  name: 'SelectContactos',
  props: {
    value: [String, Array],
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    cliente: { type: Object, default: {} },
    create: { type: Boolean, default: false },
  },
  components: {
    quickCreate,
  },
  created() {
    this.init()
  },
  data(ctx) {
    return {
      dialog: false,
      selected: ctx.value,
      items: [],

      ui: {
        quickCreate: false,
      },
    }
  },
  computed: {
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
  },
  methods: {
    async onContactCreated(contact) {
      console.warn(contact)
      this.ui.quickCreate = false

      await this.linkContactToClient(contact)
      await this.init()

      this.selected = contact.uuid
      this.$emit('input', this.selected)
    },

    async linkContactToClient(contact) {
      try {
        await this.$store.dispatch('clientes/addContacto', {
          cliente: this.cliente.uuid,
          contacto: contact.uuid,
        })
      } catch (e) {
      } finally {
      }
    },

    text(item) {
      let text = ''
      if (item.nombre) text += ' ' + item.nombre
      if (item.primer_apellido) text += ' ' + item.primer_apellido
      if (item.segundo_apellido) text += ' ' + item.segundo_apellido
      return text
    },
    async init() {
      const res = await this.$store.dispatch('clientes_contactos/list', this.cliente.uuid)
      if (res) this.items = res
    },
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.contacto.reset()
      this.selected = val
    },
  },
}
</script>
