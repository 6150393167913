import Vue from 'vue';

export default {
  props: {
    value: {}
  },
  data: () => ({
    localValue: Vue.value
  }),
  watch: {
    localValue(value) {
      Vue.$emit('input', value)
    },
    value(value) {
      Vue.localValue = value
    }
  }
}
