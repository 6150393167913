<template>
  <div>
    <!-- HEADER -->
    <!-- <header-component noAction v-if="!uuid" /> -->

    <v-row class="align-center">
      <v-col cols="6">
        <h1 v-if="!ui.showLote">Revisiones</h1>
        <h1 v-else>
          <template v-if="$refs.lotes && $refs.lotes.ui.step == 0">
            {{ ui.panel == 'auditorias' ? 'Auditorías abiertas' : '' }}
            {{ ui.panel == 'revisiones' ? 'Revisiones pendientes' : '' }}
          </template>
          <template v-else> Lote de revisiones </template>
        </h1>
      </v-col>

      <v-col cols="6" style="text-align: right">
        <v-btn
          depressed
          outlined
          v-if="auth.lotes && auth.lotes.canCreate"
          @click="ui.showLote = !ui.showLote">
          <v-icon small class="pr-2">mdi-folder-plus-outline</v-icon>
          <template v-if="ui.showLote"> Volver </template>
          <template v-else> Nuevo lote de revisiones </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="align-center" v-show="ui.showLote && $refs.lotes && $refs.lotes.ui.step == 0">
      <v-col cols="6" class="pb-0">
        <v-btn-toggle
          mandatory
          v-model="ui.panel"
          color="deep-purple accent-3"
          style="transform: translateX(-5px)"
          group>
          <v-btn
            small
            text
            style="border-radius: 3px"
            value="auditorias"
            @click="$refs.lotes.selected = []">
            Auditorías abiertas
          </v-btn>

          <v-btn
            small
            text
            style="border-radius: 3px"
            value="revisiones"
            @click="$refs.lotes.selected = []">
            Revisiones pendientes
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-show="ui.showLote" class="my-2 mb-5">
      <v-col>
        <lotesCRUD ref="lotes" lote="Revisiones" :items="lotesDB" />
      </v-col>
    </v-row>

    <!-- LISTADO -->
    <v-row v-show="!ui.showLote">
      <v-col cols="12">
        <ag-datatable
          title="Listado"
          :headers="headers"
          :items="items"
          urlData="revisiones"
          :emitClickRow="true"
          :rowGroupPanelShow="'never'"
          @click-row="clickRow"></ag-datatable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    revisiones/index.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Thu Feb 02 2023
 **/

import moment from 'moment'
import Estado from '~/models/estados'
import lotesCRUD from '~/components/lotes/crud'
import AgDatatable from '~/components/AgDataTableNew'

export default {
  props: ['uuid', 'iteracion'],
  components: {
    AgDatatable,
    lotesCRUD,
  },
  data: () => ({
    items: [],
    db: {
      items: [],
      auditorias: [],
    },
    CERRADA: Estado.AUDITORIA_ESTADO.CERRADA,
    headers: [
      {
        headerName: 'Estado',
        field: 'estado',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        cellRendererFramework: 'StatusRendererRevision',
        valueGetter: (params) => {
          const item = params.data
          //si no tiene decision, estado es SIN_DECISION
          //si tiene decision, estado es CON_PROPUESTA
          //si el estado es cerrada y la decision es OK, estado es OK
          //si el estado es cerrada y la decision es KO, estado es KO
          if (!item) return Estado.REVISION_ESTADO.SIN_DECISION
          if (!item.decision && !item.proposicion) return Estado.REVISION_ESTADO.SIN_DECISION
          if (item.proposicion && (item.estado !== Estado.REVISION_ESTADO.OK && item.estado !== Estado.REVISION_ESTADO.KO)) return Estado.REVISION_ESTADO.CON_PROPUESTA
          if (
            item.estado === Estado.AUDITORIA_ESTADO.CERRADA &&
            item.decision === Estado.REVISION_ESTADO.OK
          )
            return Estado.REVISION_ESTADO.OK
          if (
            item.estado === Estado.AUDITORIA_ESTADO.CERRADA &&
            item.decision === Estado.REVISION_ESTADO.KO
          )
            return Estado.REVISION_ESTADO.KO

          return item.estado
        },
      },
      {
        headerName: 'Marca',
        field: 'auditoria.expediente.subalcance',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        cellRendererFramework: 'SubalcanceRendererColor',
      },
      {
        headerName: 'Expediente',
        field: 'auditoria.expediente.codigo',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: 'Cliente',
        field: 'auditoria.cliente.nombreCompleto',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: 'Revisor',
        field: 'auditor1.nombreCompleto',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: 'Tipo Auditoría',
        field: 'auditoria.tipoVisita',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: 'Proposición',
        field: 'proposicion',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.proposicion
          return value ? value : 'Sin propuesta'
        },
        cellStyle: function (params) {
          if (params.value === 'APTO') return { color: 'green' }
          if (params.value === 'NO APTO') return { color: 'red' }
          if (params.value === 'SIN DECISION') return { color: 'grey' }
          if (params.value === 'CON PROPUESTA') return { color: 'blue' }
        },
      },
      {
        headerName: 'Fecha proposición',
        field: 'fechaProposicion',
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.fechaProposicion
          return value ? moment(value).format('DD/MM/YYYY') : 'Sin fecha'
        },
      },

      {
        headerName: 'Prioridad',
        field: 'prioridad',
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        cellRenderer: function (params) {
          const value = params.value
          if (value === 1) return '🔴 Prioridad alta'
          if (value === 2) return '🟠 Prioridad media'
          if (value === 3) return '🟡 Prioridad baja'
          if (value === 4) return '🟢 Prioridad muy baja'
          return '-'
        },
      },
      {
        headerName: 'Fechas Auditoría',
        field: 'desde',
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          // mostrar desde y hasta como rango de fechas. Si es la misma fecha, mostrar solo una
          let desde = item && item.auditoria && item.auditoria.desde
          let hasta = item && item.auditoria && item.auditoria.hasta
          if (desde && hasta) {
            if (moment(desde).isSame(hasta, 'day')) {
              return moment(desde).format('DD/MM/YYYY')
            } else {
              return `Del ${moment(desde).format('DD/MM/YYYY')} al ${moment(hasta).format('DD/MM/YYYY')}`
            }
          } else {
            return 'Sin fechas'
          }
        },
      },
      {
        headerName: 'Acciones',
        field: 'acciones',
        cellRendererFramework: 'ActionsRenderer',
        resizable: true,
        sortable: false,
        filter: false,
        cellRendererFramework: 'RevisionActionRenderer',
        pinned: 'right',
      },
    ],

    auth: {},

    ui: {
      panel: null,
      showLote: false,
    },
  }),

  computed: {
    revisiones() {
      if (!this.items) return this.items
      else {
        return this.items.filter((auditoria) => {
          return auditoria.estado === this.CERRADA
        })
      }
    },

    //+-------------------------------------------------
    // lotesDB()
    // As we have two possible arrays to use in the table,
    // return according to this.ui.panel
    // -----
    // Created on Mon Jul 18 2022
    //+-------------------------------------------------
    lotesDB() {
      if (this.ui.panel === 'auditorias') return this.db.auditorias

      if (this.ui.panel === 'revisiones')
        return this.db.items.filter((auditoria) => {
          return auditoria.estado === Estado.AUDITORIA_ESTADO.PENDIENTE
        })
    },
  },

  watch: {
    iteracion: {
      handler(newVal, oldVal) {
        if (newVal.uuid !== oldVal.uuid) {
          this.filterIteracion()
        }
      },
      deep: true,
    },
  },

  methods: {
    //+-------------------------------------------------
    // filterIteracion()
    // Select only items where iteracion match the prop
    // -----
    // Created on Mon Nov 29 2021
    //+-------------------------------------------------
    filterIteracion() {
      if (!this.iteracion?.uuid) {
        this.items = [...this.db.items]
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.iteracion_uuid == this.iteracion?.uuid
      })

      this.items = items
    },

    clickRow(data) {
      this.$router.push({ path: `/revisiones/${data.uuid}`, query: { revision: true } })
    },

    async checkRoles() {
      this.auth.lotes = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        submodule: 'lotes',
        return: 'all',
      })
    },

    //+-------------------------------------------------
    // getAuditorias()
    // Loads auditorias to use with lotes
    // -----
    // Created on Thu Jun 02 2022
    //+-------------------------------------------------
    async getAuditorias() {
      const xhr = await this.$store.dispatch('auditorias/list', {
        estados: ['ABIERTA'],
      })
      if (xhr) {
        this.db.auditorias = xhr
      }
    },

    async getRevisiones() {
      const xhr = await this.$store.dispatch('revisiones/list', this.uuid)
      if (xhr) {
        this.items = xhr
        this.db.items = xhr
      }

      this.filterIteracion()
    },

    async init() {
      await this.checkRoles()
      await this.getRevisiones()
      await this.getAuditorias()
    },
  },

  async mounted() {
    this.init()
  },

  created() {
    if (!this.uuid) this.$store.commit('menu/setPageTitle', 'Revisiones')
  },
}
</script>