import { uuid } from 'vue-uuid'

export default {
  state: () => ({
    defaultMuestras: [
      { tipoMuestra: 'INICIAL', icon: 'mdi-account-multiple-outline' },
      { tipoMuestra: 'DIRIMENTE', icon: 'mdi-text-box-check-outline' },
      { tipoMuestra: 'CONTRADICTORIO', icon: 'mdi-folder-text-outline' },
    ],
    offlineActions: [],
  }),
  // commit mutations
  mutations: {
    OFFLINE_ACTION(state, action) {
      const storage = JSON.parse(localStorage.getItem('offline_actions_muestras'))
      if (storage) state.offlineActions = storage
      state.offlineActions.push(action)
      localStorage.setItem('offline_actions_muestras', JSON.stringify(state.offlineActions))
    },
    CLEAR_OFFLINE_MODE(state) {
      state.offlineActions = []
      localStorage.removeItem('offline_actions_muestras')
      localStorage.removeItem('tomas_muestra')
      localStorage.removeItem('muestras')
    },
  },
  // dispatch actions
  actions: {
    async goOnline({ rootState, commit, dispatch }) {
      const storage = JSON.parse(localStorage.getItem('offline_actions_muestras'))
      if (storage && storage.length > 0) {
        for (const element of storage) {
          console.info(element)
          await dispatch(element.action, element.body)
        }
      }
      commit('CLEAR_OFFLINE_MODE')
    },

    //+-------------------------------------------------
    // function()
    //
    // -----
    // Updated on Wed Feb 08 2023
    //+-------------------------------------------------
    async list({ rootState, commit }, auditoria) {
      // const storage = JSON.parse(localStorage.getItem(`tomas_muestra`))
      // Check Offline
      // if (rootState.auditorias.offlineMode && storage) {
      //   return storage
      // } else {
      rootState.loading = true
      const body = {
        auditoria: auditoria,
      }
      const res = await this.$axios.get(`toma_muestras?auditoria.uuid=${auditoria}`)
      rootState.loading = false
      if (res && res.data) {
        // if (rootState.auditorias.offlineMode)
        //   localStorage.setItem('tomas_muestra', JSON.stringify(res.data))
        return res.data['hydra:member']
      }
      // }
    },

    async get({ rootState, commit }, uuid) {
      // const storage = JSON.parse(localStorage.getItem('tomas_muestra'))
      // Check Offline
      // if (rootState.auditorias.offlineMode && storage) {
      //   const index = storage.findIndex((e) => e.uuid == uuid)
      //   return storage[index]
      // } else {
      rootState.loading = true
      const res = await this.$axios.get(`toma_muestras/${uuid}`)
      rootState.loading = false
      if (res) return res.data
      // }
    },

    async create({ rootState, state, dispatch, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        const action = {
          action: 'create',
          body: {
            auditoria: params.auditoria,
            codigo: params.codigo,
            responsable: params.responsable,
            site: params.site,
            uuid: params.uuid,
          },
        }
        const muestras = [uuid.v4(), uuid.v4(), uuid.v4()]
        action.body.muestras = muestras

        commit('OFFLINE_ACTION', action)

        const toma_muestra = {
          ...action.body,
          estado: 'NUEVA',
          created_at: new Date(),
        }

        const storage = JSON.parse(localStorage.getItem('tomas_muestra'))
        storage.push(toma_muestra)
        localStorage.setItem('tomas_muestra', JSON.stringify(storage))

        for (let i = 0; i < state.defaultMuestras.length; i++) {
          const element = state.defaultMuestras[i]
          const uuid = muestras[i]
          const data = {
            toma_muestra: params.uuid,
            tipoMuestra: element.tipoMuestra,
            laboratorio: '',
            uuid: uuid,
          }
          await dispatch('createMuestra', data)
        }

        return params.uuid
      }else {
        rootState.loading = true
        const body = {
            auditoria: params.auditoria['@id'],
            codigo: params.codigo,
            responsable: params.responsable['@id'],
            producto: params.producto['@id'],
            uuid: params.uuid,
        }
        const responseUuid = await this.$axios.post('toma_muestras', body).then(async (res) => {
            const responseUuid = res.data
            console.log('create -> responseUuid', responseUuid)
            let muestras = state.defaultMuestras
            const storage = JSON.parse(localStorage.getItem('muestras'))
            if (storage && storage[params.uuid]) muestras = storage[params.uuid]

            for (let i = 0; i < muestras.length; i++) {
                const element = muestras[i]
                const data = {
                    toma_muestra: `/api/toma_muestras/${responseUuid.uuid}`,
                    tipoMuestra: element.tipoMuestra,
                    laboratorio: '',
                    uuid: element.uuid || uuid.v4(),
                }
                await dispatch('createMuestra', data)
            }
            // Retornar el UUID correctamente
            console.log('create -> responseUuid', responseUuid)
            return responseUuid.uuid
        })

        rootState.loading = false
        console.log('create -> responseUuid', responseUuid)
        return responseUuid // Retorna el UUID
    }

    },

    async update({ rootState, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        const action = {
          action: 'update',
          body: params,
        }
        commit('OFFLINE_ACTION', action)
        const storage = JSON.parse(localStorage.getItem('tomas_muestra'))
        const index = storage.findIndex((e) => e.uuid == params.uuid)
        storage[index] = params
        localStorage.setItem('tomas_muestra', JSON.stringify(storage))
      } else {
        rootState.loading = true
        console.log('update -> params', params)
        const body = {
          responsable: params.responsable['@id'],
          otroResponsable: params.otroResponsable,
          codigo: params.codigo,
          nombrePersonal: params.nombrePersonal,
          dniPersonal: params.dniPersonal,
          observaciones: params.observaciones,
          tratamientosRealizados: params.tratamientosRealizados,
          site: params.site,
          auditoria: params.auditoria['@id'],
        }
        //body.site = params.site['@id']
        await this.$axios.patch(`toma_muestras/${params.uuid}`, body,  {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          }})
        rootState.loading = false
      }
    },

    // Muestras

    async listMuestra({ rootState, commit }, uuid) {
      // const storage = JSON.parse(localStorage.getItem('muestras')) || {}
      // Check Offline
      // if (rootState.auditorias.offlineMode && storage[uuid]) {
      //   return storage[uuid]
      // } else {
      rootState.loading = true
      const res = await this.$axios.get(`muestras?tomaMuestra.uuid=${uuid}`)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) {
          storage[uuid] = res.data
          localStorage.setItem('muestras', JSON.stringify(storage))
        }
        return res.data['hydra:member']
      }
      // }
    },

    async createMuestra({ rootState, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        // const action = {
        //   action: 'createMuestra',
        //   body: params
        // }
        // commit('OFFLINE_ACTION', action)
        const storage = JSON.parse(localStorage.getItem('muestras')) || {}
        if (!storage[params.toma_muestra]) storage[params.toma_muestra] = []
        storage[params.toma_muestra].push(params)
        localStorage.setItem('muestras', JSON.stringify(storage))
      } else {
        rootState.loading = true
        const body = {
          lote: params.lote,
          laboratorio: params.laboratorio,
          //tipo: params.tipo,
          sustanciasDetectadas: params.sustanciasDetectadas,
          fechaRecepcion: params.fechaRecepcion,
          fechaEnvio: params.fechaEnvio,
          facturable: params.facturable,
          estado: params.estado,
          tomaMuestra: params.toma_muestra,
          precinto: params.precinto,
          tipoMuestra: params.tipoMuestra,
          uuid: params.uuid,
        }
        await this.$axios.post(`muestras`, body)
        rootState.loading = false
      }
    },

    async updateMuestra({ rootState, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        const action = {
          action: 'updateMuestra',
          body: params,
        }
        commit('OFFLINE_ACTION', action)
        const storage = JSON.parse(localStorage.getItem('muestras'))
        for (let i = 0; i < storage[params.toma_muestra].length; i++) {
          const muestra_uuid = storage[params.toma_muestra][i].uuid
          if (muestra_uuid == params.uuid) {
            storage[params.toma_muestra][i] = params
            break
          }
        }
        localStorage.setItem('muestras', JSON.stringify(storage))
      } else {
        rootState.loading = true
        const body = {
          lote: params.lote,
          laboratorio: params.laboratorio,
          //tipo: params.tipo,
          sustanciasDetectadas: params.sustanciasDetectadas,
          fechaRecepcion: params.fechaRecepcion,
          fechaEnvio: params.fechaEnvio,
          facturable: params.facturable,
          estado: params.estado,
          tomaMuestra: params.toma_muestra,
          muestra: params.uuid,
          precinto: params.precinto,
          tipoMuestra: params.tipoMuestra ? params.tipoMuestra : params.tipo,
          params: params.params,
        }
        await this.$axios.patch(
          `muestras/${params.uuid}`,
          body, 
          {
            headers: {
              'Content-Type': 'application/merge-patch+json',
            }
          }
        )
        rootState.loading = false
      }
    },

    async deleteTomaMuestra({ rootState, commit }, params) {
      rootState.loading = true
      await this.$axios.delete(`toma_muestras/${params.uuid}`)
      rootState.loading = false
    },

    // Analisis

    async listAnalisis({ rootState, commit }, params) {
      rootState.loading = true
      const res = await this.$axios.get(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis`
      )
      rootState.loading = false
      if (res) return res.data
    },

    async createAnalisis({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        tipo_analisis: params.tipo_analisis,
        precinto: params.precinto,
        respuesta: params.respuesta,
        observaciones: params.observaciones,
        muestra: params.muestra,
        uuid: uuid.v4(),
      }
      console.log('createAnalisis -> body', body)
      await this.$axios.post(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis`,
        body
      )
      rootState.loading = false
    },

    async updateAnalisis({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        tipo_analisis: params.tipo_analisis,
        precinto: params.precinto,
        respuesta: params.respuesta,
        observaciones: params.observaciones,
        muestra: params.muestra,
        analisis: params.uuid,
      }
      await this.$axios.patch(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.uuid}`,
        body
      )
      rootState.loading = false
    },

    // Parametro

    async createParametro({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        clave: params.clave,
        valor: params.valor,
        analisis: params.analisis,
      }
      await this.$axios.post(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.analisis}/parametros`,
        body
      )
      rootState.loading = false
    },

    async updateParametro({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        clave: params.clave,
        valor: params.valor,
        analisis: params.analisis,
        parametro: params.id,
      }
      await this.$axios.patch(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.analisis}/parametros/${params.id}`,
        body
      )
      rootState.loading = false
    },

    async deleteParametro({ rootState, commit }, params) {
      rootState.loading = true
      await this.$axios.delete(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.analisis}/parametros/${params.id}`
      )
      rootState.loading = false
    },
  },
}
