
import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({rootState, commit}, params) {
    rootState.loading = true
    const res = await this.$axios.get('incompatibilidades')
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`incompatibilidades/${uuid}`)
    rootState.loading = false
    if (res) {
      const data = res.data
      data.desde = moment(data.desde).format('YYYY-MM-DD')
      data.hasta = moment(data.hasta).format('YYYY-MM-DD')
      return data
    }
  },

  async create({rootState}, incompatibilidad) {
    rootState.loading = true
    incompatibilidad.uuid = uuid.v4()
    const body = incompatibilidad;
    body.desde = moment(incompatibilidad.desde).format('YYYY-MM-DD HH:mm:ss')
    body.hasta = moment(incompatibilidad.hasta).format('YYYY-MM-DD HH:mm:ss')
    body.alcance = incompatibilidad.alcance.uuid;
    body.cliente = incompatibilidad.cliente.uuid;
    body.auditor = incompatibilidad.auditor.uuid;
    const res = await this.$axios.post('incompatibilidades', body)
    rootState.loading = false
  },

  async update({rootState}, incompatibilidad) {
    rootState.loading = true
    const body = incompatibilidad;
    body.incompatibilidad = incompatibilidad.uuid
    body.alcance = incompatibilidad.alcance.uuid;
    body.cliente = incompatibilidad.cliente.uuid;
    body.auditor = incompatibilidad.auditor.uuid;
    body.desde = moment(incompatibilidad.desde).format('YYYY-MM-DD HH:mm:ss')
    body.hasta = moment(incompatibilidad.hasta).format('YYYY-MM-DD HH:mm:ss')
    await this.$axios.patch(`incompatibilidades/${body.uuid}`, body)
    rootState.loading = false
  },

  async delete({rootState}, incompatibilidad) {
    rootState.loading = true
    await this.$axios.delete(`incompatibilidades/${incompatibilidad.uuid}`)
    rootState.loading = false
  },

};
