export default {
  // ETG
  // PRODUCTO_TIPO_ETG: {
  //   jamon_serrano_curado: 'Jamón Serrano Curado',
  // },

  PRESENTACION_TIPO_ETG: {
    corte_v_con_pata: 'Corte en V con pata',
    corte_v_sin_pata: 'Corte en V sin pata',
    corte_redondo_sin_pata: 'Corte redondo sin pata',
    corte_redondo_con_pata: 'Corte redondo con pata',
    deshuesado_con_piel: 'Deshuesado con piel',
    deshuesado_sin_piel: 'Deshuesado sin piel',
    deshuesado_corte_v: 'Deshuesado corte en V',
    loncheado: 'Loncheado',
    porcionado: 'Porcionado',
  },

  PRODUCCION_TIPOS_ETG: ['propia', 'compra', 'maquila'],
  PRODUCCION_TIPO_ETG: {
    propia: 'propia',
    compra: 'compra',
    maquila: 'maquila',
  },

  ACTIVIDADES_ETG: [
    { nombre: 'Sacrificio', responsableOnly: false },
    { nombre: 'Despiece', responsableOnly: false },
    { nombre: 'Elaboración Total', responsableOnly: false },
    { nombre: 'Elaboración - Salado', responsableOnly: false },
    { nombre: 'Elaboración - Post-salado', responsableOnly: false },
    { nombre: 'Elaboración - Curado Maduración', responsableOnly: false },
    { nombre: 'Transformación', responsableOnly: false },
    { nombre: 'Envasado', responsableOnly: false },
    { nombre: 'Almacenamiento', responsableOnly: false },
    { nombre: 'Expedición', responsableOnly: false },
  ],

  ACTIVIDADES_ETG2: [
    { nombre: 'Elaboración', responsableOnly: false },
    { nombre: 'Elaboración (Maduración)', responsableOnly: false },
    { nombre: 'Transformación', responsableOnly: false },
    { nombre: 'Etiquetado', responsableOnly: false },
    { nombre: 'Comercialización', responsableOnly: false },
  ],

  // Productos Ibericos
  ALIMENTACION_TIPOS: [
    { text: 'Bellota', value: 'bellota' },
    { text: 'Cebo de campo', value: 'cebo_Campo' },
    { text: 'Cebo', value: 'cebo' },
    { text: 'N/A', value: 'na' },
  ],
  ALIMENTACION_TIPO: {
    bellota: 'Bellota',
    cebo_Campo: 'Cebo de campo',
    cebo: 'Cebo',
  },

  PRODUCTO_TIPOS: [
    { text: 'Jamón', value: 'jamon' },
    { text: 'Paleta', value: 'paleta' },
    { text: 'Caña de lomo', value: 'lomo' },
    { text: 'Carne fresca', value: 'carnes_frescas' },
  ],

  PRODUCTO_TIPOS_ETG: [{ text: 'Jamón Serrano', value: 'jamon_serrano' }],

  PRODUCTO_TIPO: {
    jamon: 'Jamón',
    paleta: 'Paleta',
    lomo: 'Caña de Lomo',
    carnes_frescas: 'Carne Fresca',
  },

  RACIAL_TIPOS: [
    { text: '100% Ibérico', value: '100_iberico' },
    { text: 'Ibérico', value: 'iberico' },
  ],
  RACIAL_TIPO: {
    '100_iberico': '100% Ibérico',
    'iberico': 'Ibérico',
  },

  ESTADOS_TIPOS: [
    { text: 'Curado', value: 'curado' },
    { text: 'Fresco', value: 'fresco' },
  ],
  ESTADOS_TIPO: {
    curado: 'Curado',
    fresco: 'Fresco',
  },

  PRESENTACION_TIPOS: [
    { text: 'Pieza entera', value: 'entera' },
    { text: 'Deshuesada', value: 'deshuesada' },
    { text: 'Loncheada', value: 'loncheada' },
    { text: 'Trozos', value: 'trozos' },
  ],

  PRESENTACION_TIPO: {
    entera: 'Pieza entera',
    deshuesado: 'Deshuesada',
    loncheado: 'Loncheada',
    troceado: 'Trozos',
  },

  PRESENTACION_TIPOS_ETG: [
    { text: 'Pieza entera', value: 'entera' },
    { text: 'Centros deshuesados', value: 'centro_deshuesado' },
    { text: 'Porciones', value: 'trozos_porciones' },
    { text: 'Loncheados', value: 'loncheado' },
  ],

  CERTIFICACION_TIPOS: [
    { text: 'Parcial', value: 'parcial' },
    { text: 'Operador', value: 'operador' },
  ],
  CERTIFICACION_TIPO: {
    parcial: 'Parcial',
    operador: 'Operador',
  },

  MODIFICACION_TIPOS: [
    { text: 'Ampliación', value: 'ampliacion' },
    { text: 'Reducción', value: 'reduccion' },
    { text: 'Otros motivos', value: 'otros' },
  ],
  MODIFICACION_TIPO: {
    ampliacion: 'Ampliación',
    reduccion: 'Reducción',
    otros: 'Otros motivos',
  },

  SOLICITUD_TIPOS: [
    { text: 'Inicial', value: 'inicial' },
    { text: 'Modificación de alcance', value: 'modificacion' },
  ],
  SOLICITUD_TIPO: {
    inicial: 'Inicial',
    modificacion: 'Modificación de alcance',
  },

  PRODUCCION_TIPOS: ['propia', 'maquila'],
  PRODUCCION_TIPO: {
    propia: 'propia',
    maquila: 'maquila',
  },

  OPERADORES_TIPOS: [
    { text: 'Responsable de la actividad', value: 'responsable' },
    { text: 'Prestador de servicios (a maquila)', value: 'prestador' },
  ],
  OPERADORES_TIPO: {
    responsable: 'Responsable de la actividad',
    prestador: 'Prestador de servicios (a maquila)',
  },
  TIPOS_INSTALACION: {
    propia: 'propia',
    alquilada: 'alquilada',
    subcontratada: 'subcontratada',
    maquila: 'maquila',
  },
}
