<template>
  <div>
    <template v-for="(no_conformidad, idx) in datos.no_conformidades" :key="">
      <div class="page-break-before" v-if="idx > 0"></div>
      <table style="width:100%; border-style: double; border-width: thick; text-align:center; height: 100px;">
        <tr>
          <td style="width: 25%;">
            <v-img v-if="datos.cliente.avatar != null" :src="datos.cliente.avatar" style="width:100%" />
          </td>
          <td style="width: 50%;">
            <h1>NO CONFORMIDAD</h1>
          </td>
          <td style="width: 25%;">
            <h2>Rev. 02</h2>
          </td>
        </tr>
      </table>
      <br>
      <table style="width:100%; border-style: solid; border-width: thick; text-align:center; font-size: 18px;">
        <thead style="background-color: grey;">
          <tr>
            <td style="width: 35%;">
              Nº
            </td>
            <td style="width: 35%;">
              TIPO
            </td>
            <td style="width: 30%;">
              FECHA
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="width: 35%;">
              {{idx + 1}}
            </td>
            <td style="width: 35%;">
              {{no_conformidad.peso | capitalize}}
            </td>
            <td style="width: 30%;">
              {{ formatDate(no_conformidad.accion_correctiva.created_at) }}
            </td>
          </tr>
        </tbody>
        <thead style="background-color: grey;">
          <tr>
            <td colspan="3">
              REFERENCIA APTDO.
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3">
              -------------------
            </td>
          </tr>
        </tbody>
        <thead style="background-color: grey;">
          <tr>
            <td colspan="3">
              Descripción de la No Conformidad.
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" style="height: 250px;">
              {{no_conformidad.no_conformidad}}
            </td>
          </tr>
        </tbody>
        <thead style="background-color: grey;">
          <tr>
            <td colspan="3">
              Persona que ha detectado la No Conformidad
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3">
              {{no_conformidad.accion_correctiva.responsable_implantacion}}
            </td>
          </tr>
        </tbody>
        <thead style="background-color: grey;">
          <tr>
            <td colspan="3">
              Acción Inmediata
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" style="height: 100px;">
              {{no_conformidad.observaciones}}
            </td>
          </tr>
        </tbody>
        <thead style="background-color: grey;">
          <tr>
            <td>
              Acciones correctivas y preventivas
            </td>
            <td>
              Fecha implantación: {{ formatDate(no_conformidad.accion_correctiva.fecha_implantacion) }}
            </td>
            <td>
              Fecha cierre: Recepción Nº de certificado
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" style="height: 400px;">
              {{no_conformidad.accion_correctiva.accion_propuesta}}
              <br><br><br>
              <p><u>Antonio Díez Lozano: </u> Responsable de Calidad y Administrador de Cárnicas Díaz de Zamora S.L.</p>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }

  thead {
    background-color: grey;
  }

  td {}
</style>

<script>
  import MODELS from "~/models/araporcei/solicitudes"

  export default {
    props: {
      datos: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({}),
    computed: {
      clienteNombreCompleto() {
        let nombre = ''
        const cliente = this.datos.cliente
        if (cliente) {
          if (cliente.nombre) nombre += cliente.nombre
          if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
          if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
        }
        return nombre
      },
      alcance() {
        return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
      },
      instalaciones() {
        const actividades = this.datos &&
          this.datos.solicitud &&
          this.datos.solicitud.params.alcance_producto.map(e => e.rgseaa) || []
        return this.datos.cliente.instalaciones.filter(e => actividades.includes(e.rgseaa))
      },
      actividades() {
        const planificaciones = this.datos.planificaciones
        const sites = this.datos.sites

        const planificacionesSites = planificaciones.map(e => e.site.uuid)

        const childrenSites = sites.filter(e => e.parent_uuid)

        return childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
        // return childrenSites.length
        //   ? childrenSites.filter(e => planificacionesSites.includes(e.parent_uuid))
        //   : sites.filter(e => planificacionesSites.includes(e.uuid))
      },
      auditores() {
        const auditores = []
        const planificaciones = this.datos.planificaciones && this.datos.planificaciones
        if (planificaciones) {
          for (let i = 0; i < planificaciones.length; i++) {
            const planificacion = planificaciones[i]
            if (!auditores.includes(planificacion.auditor)) auditores.push(planificacion.auditor)
          }
        }
        return auditores.join(', ')
      },
      planificaciones_data() {
        return this.datos && this.datos.planificaciones.map(element => element.data)
      },
      solicitud_data() {
        if (this.datos) {
          let data = {}
          this.datos.grupo_solicitud_data.forEach(element => {
            data[element.keyname] = element.value
          })
          return data
        } else return {}
      },
      site_data() {
        if (this.datos.isCliente) return this.datos.isCliente
        else if (this.datos.sites) {
          let data = {}
          let tipos = []

          for (let i = 0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
          }

          for (let i = 0; i < tipos.length; i++) {
            const element = tipos[i]
            data[element] = []
          }

          for (let i = 0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            let obj = {}
            obj.uuid = element.uuid
            for (let k = 0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
            data[element.tipo].push(obj)
          }

          return data
        } else return {}
      },
    },
    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : ''
      },
      formatTime(date) {
        return date ? this.$moment(date).format('HH:mm') : ''
      },
    }
  }
</script>