<template>
  <div>
    <div v-for="(componente,index) in componentes" :key="index">
      <component :is="componente" v-if="current(componente)" @isValidGlobal="$emit('isValidGlobal', $event)"></component>
    </div>
  </div>
</template>

<script>
// Generales
import Clientes from "./clientes/Variables";
// Agrocolor
import ClientesAgrocolorGlobal from "./clientes/agrocolor/global/Variables";
import ClientesAgrocolorEcologico from "./clientes/agrocolor/ecologico/Variables";
// import AgrocolorClientesEcologico from "./agrocolor/clientes/ecologico/VariablesAgrocolorEcologico";
// import AgrocolorExpedientesGlobalgap from "./agrocolor/expedientes/globalgap/VariablesAgrocolorGlobalgap";
// import AgrocolorExpedientesEcologico from "./agrocolor/expedientes/ecologico/VariablesAgrocolorEcologico";

export default {
  name: "CustomVariables",
  props: {
    empresa: String,
    modulo: String,
    grupo_alcance: String,
    editar: { type: Boolean, default: false }
  },
  components: {
    // Agrocolor
    ClientesAgrocolorGlobal,
    ClientesAgrocolorEcologico,
    // AgrocolorExpedientesGlobalgap,
    // AgrocolorExpedientesEcologico,
    Clientes,
  },
  data: () => ({
    variables: {},
    componentes: []
  }),
  async mounted() {
    this.setComponentesGruposdeAlcance();
  },
  methods: {
    async setComponentesGruposdeAlcance() {
      const grupos_alcances = await this.$store.dispatch('grupos_alcances/list');
      const empresa = this.empresa ? this.empresa : this.$auth.user.empresa.codigo;
      const componentesGrupoAlcance = [];
      if (grupos_alcances) {
        grupos_alcances.map(grupo => {
          componentesGrupoAlcance.push(`${this.modulo}-${empresa}-${grupo.codigo}`)
        })
        this.componentes = componentesGrupoAlcance;
        if (!this.editar) this.componentes.push(`${this.modulo}`)
      }
    },
    current (componente) {
      const empresa = this.empresa ? this.empresa : this.$auth.user.empresa.codigo;
      if (this.editar) {
        return this.grupo_alcance
          ? `${this.modulo}-${empresa}-${this.grupo_alcance}` === componente
          : componente.includes(`${this.modulo}-${empresa}`)
      }
      else return `${this.modulo}` === componente
    }
  }
}
</script>
