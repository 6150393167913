<template>
  <v-row class="ma-0">
    <v-col cols="6">
      <v-text-field
        label="RIA"
        v-model="item.ria"
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        label="RSI"
        v-model="item.rsi"
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'fieldsCerticalidad',
  props: ['item'],
  data(ctx) {
    return {
      fields: {
        append: ['ria', 'rsi'],
      },
    }
  },
}
</script>
