<style>
.celda-vertical {
  writing-mode: vertical-rl;
  font-family: Calibri, serif;
  font-size: small;
  transform: rotate(180deg);
  height: fit-content;
}
</style>

<template>
  <div>
    <div>
      <p style="text-align: center; font-size: large; color: #ff0066; font-family: Calibri, serif;">
        <strong>SOLICITUD DE CERTIFICACION GANADER&Iacute;A LB / FABRICACI&Oacute;N DE PIENSO CFM / CADENA DE CUSTODIA
          COC</strong>
      </p>
      <table width="952" cellspacing="0" cellpadding="7">
        <tbody>
          <tr valign="top">
            <td width="220">
              <p style="text-align: center; font-family: Calibri, serif; font-size: small;">
                <em>
                  <strong>FECHA SOLICITUD </strong>
                </em>
              </p>
            </td>
            <td width="287">
              <p style="text-align: center; font-family: Calibri, serif; font-size: small;">
                <em>
                  <strong>NUMERO EXPEDIENTE</strong>
                </em>
              </p>
            </td>
            <td width="401">
              <p style="text-align: center; font-family: Calibri, serif; font-size: small;">
                <em>
                  <strong>FECHA COMUNICACI&Oacute;N CLIENTE </strong>
                </em>
              </p>
            </td>
          </tr>
          <tr>
            <td width="220">
              <p style="text-align: center;">{{ formatDate(datos.created_at) }}</p>
            </td>
            <td width="287">
              <p style="text-align: center;">&nbsp;</p>
            </td>
            <td width="401">
              <p style="text-align: center;">&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 1º.- DATOS DEL SOLICITANTE DE LOS SERVICIOS. -->
    <div>
      <p class="western" style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <strong>1&ordm;.- DATOS DEL SOLICITANTE DE LOS SERVICIOS.</strong>
      </p>
      <p class="western" style="text-align:left; font-family: Calibri, serif; font-size: small;">
        <strong>Nombre de la empresa</strong> (solicitante de la certificaci&oacute;n):
        {{ datos.cliente.empresa.nombre }} ({{ datos.cliente.nombre }} {{ datos.cliente.primer_apellido }} {{ datos.cliente.segundo_apellido }})</p>
      <p class="western" style="text-align:left; font-family: Calibri, serif; font-size: small;">
        <strong>NIF:</strong> {{ datos.cliente.nif }}
        <strong>Nombre/Apellidos de la persona solicitante:</strong> {{ datos.cliente.responsable_nombre }} {{ datos.cliente.responsable_primer_apellido }} {{ datos.cliente.responsable_segundo_apellido }}
      </p>
      <p class="western" style="text-align:left; font-family: Calibri, serif; font-size: small;">
        <strong>DNI: </strong> {{ datos.cliente.responsable_nif }}
        <strong>Cargo que ocupa:</strong> Responsable
      </p>
      <p class="western" style="text-align:left; font-family: Calibri, serif; font-size: small;">
        <strong>Direcci&oacute;n empresa:</strong> {{ datos.cliente.direccion }}, {{ datos.cliente.municipio.nombre.charAt(0).toUpperCase() + datos.cliente.municipio.nombre.slice(1).toLowerCase() }}, {{ datos.cliente.provincia.nombre.charAt(0).toUpperCase() + datos.cliente.provincia.nombre.slice(1).toLowerCase() }}</p>
      <p class="western" style="text-align:left; font-family: Calibri, serif; font-size: small;">
        <strong>Tel&eacute;fono</strong> {{ datos.cliente.telefono }}
        <strong>Fax ...</strong>
        <strong>E-mail:</strong> {{ datos.cliente.email_contacto_fiscal }}
      </p>
    </div>

    <!-- 2º.- ALCANCE. -->
    <div>
<br><br>
      <p class="western" style="text-align: justify; font-family: Calibri, serif; color: #002060;">
        <strong>2&ordm;.- ALCANCE.</strong>
      </p>

      <p class="western" style="text-align: justify; font-family: Calibri, serif; color: #002060;">
        <strong>2.1.- ESQUEMA DE CERTIFICACI&Oacute;N (Marque con una cruz donde proceda) GANADERIA:</strong>
      </p>

      <p v-if="datos.params.globalgap.ganaderia_cattle" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;font-weight: bold;">
        <input type="checkbox" checked="" disabled="">
        Vacuno de carne (Bovino &lt; 12 meses: cattle : vacuno mayor: machos y hembras a partir de 24 meses y
        a&ntilde;ojo: animales entre 12 y 24 meses de edad)
      </p>

      <p v-if="datos.params.globalgap.ganaderia_cyb" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Vacuno de carne (Bovino &gt; 12 meses: CYB: ternera)
      </p>

      <p v-if="datos.params.globalgap.ganaderia_leche" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Vacuno de leche
      </p>

      <p v-if="datos.params.globalgap.ganaderia_pollo" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Pollo /Pavo
      </p>

      <p v-if="datos.params.globalgap.ganaderia_ovino" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Ovino/Caprino
      </p>

      <p v-if="datos.params.globalgap.ganaderia_cerdo" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Cerdo Blanco/Ib&eacute;rico
      </p>

      <p v-if="datos.params.globalgap.documentos_porcino || datos.params.globalgap.documentos_pollo" class="western"
        style="text-align: justify; font-family: Calibri, serif; color: #002060; font-weight: bold;">
        <strong>2.2.- ESQUEMA DE CERTIFICACI&Oacute;N DOCUMENTOS ADICIONALES DE BIENESTAR ANIMAL:</strong>
      </p>

      <p v-if="datos.params.globalgap.documentos_porcino" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Bienestar animal porcino (ADD ON)
      </p>

      <p v-if="datos.params.globalgap.documentos_pollo" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Bienestar animal pollo (ADD ON)
      </p>


      <p v-if="datos.params.globalgap.pienso" class="western"
        style="text-align: justify; font-family: Calibri, serif; color: #002060;;font-weight: bold;">
        <strong>2.3.- ESQUEMA DE CERTIFICACI&Oacute;N FABRICACION DE PIENSO:</strong>
      </p>

      <p v-if="datos.params.globalgap.pienso" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Pienso Compuesto
      </p>

      <p v-if="datos.params.globalgap.custodia" class="western"
        style="text-align: justify; font-family: Calibri, serif; color: #002060;font-weight: bold;">
        <strong>2.4 ESQUEMA DE CERTIFICACI&Oacute;N.</strong>
      </p>

      <p v-if="datos.params.globalgap.custodia" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <input type="checkbox" checked="" disabled="">
        Cadena de custodia
      </p>


      <p class="western" style="text-align: center; font-family: Calibri, serif; font-size: small;font-weight: bold;">
        <em>
          <strong>La veracidad de los datos contenidos en este registro queda bajo la responsabilidad de la empresa
            solicitante de la certificaci&oacute;n.</strong>
        </em>
      </p>
    </div>

    <!-- 3.- GANADERIA. -->
    <div>
      <br><br>
      <p class="western" style="text-align: justify; font-family: Calibri, serif; color: #002060;">
        <strong>3.- GANADERIA.</strong>

      </p>

      <p class="western" style="text-align: justify; color: #002060; font-family: Calibri, serif; color: #002060;"
        v-if="datos.params.globalgap.certificacion_individual || datos.params.globalgap.sin_implantacion || datos.params.globalgap.con_implantacion || datos.params.globalgap.grupo_productores">
        <strong>3.1.- OPCIONES DE CERTIFICACION GANADER&Iacute;A </strong>

      </p>

      <p v-if="datos.params.globalgap.certificacion_individual" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <strong><input type="checkbox" checked="" disabled=""> Opci&oacute;n 1.-</strong>
        Certificaci&oacute;n Individual: un productor individual solicita la certificaci&oacute;n. Una vez obtenida la
        certificaci&oacute;n el productor individual es el titular del certificado.
      </p>

      <p v-if="datos.params.globalgap.sin_implantacion" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <strong><input type="checkbox" checked="" disabled=""> Opci&oacute;n 1.- </strong>
        Productor con m&uacute;ltiple sitios de producci&oacute;n sin implantaci&oacute;n de un sistema de
        gesti&oacute;n de calidad en com&uacute;n para todos los sitios de producci&oacute;n: un productor individual o
        una organizaci&oacute;n que posee varios sitios de producci&oacute;n que no funcionan como entidades legales
        separadas.
      </p>

      <p v-if="datos.params.globalgap.con_implantacion" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <strong><input type="checkbox" checked="" disabled=""> Opci&oacute;n 1.- </strong>
        Productor con m&uacute;ltiple sitios de producci&oacute;n con implantaci&oacute;n de un sistema de
        gesti&oacute;n de calidad en com&uacute;n para todos los sitios de producci&oacute;n: un productor individual o
        una organizaci&oacute;n que posee varios sitios de producci&oacute;n que no funcionan como entidades legales
        separadas, pero donde se ha implantado un sistema de gesti&oacute;n de calidad com&uacute;n.
      </p>

      <p v-if="datos.params.globalgap.grupo_productores" class="western"
        style="text-align: justify; font-family: Calibri, serif; font-size: small;">
        <strong><input type="checkbox" checked="" disabled=""> Opci&oacute;n 2.-</strong>
        Un grupo de productores solicita la certificaci&oacute;n como &ldquo;grupo&rdquo; y tienen implantado un sistema
        de gesti&oacute;n de calidad com&uacute;n: Dicho grupo, como entidad legal, es el titular del certificado.
      </p>

      <p class="western" style="text-align: justify; color: #002060; font-family: Calibri, serif;">

        <strong>3.2.- LISTADO DE OPERADORES DE LA OPCCION ELEGIDA (PRODUCTOR/SITIO DE PRODUCCION).</strong>

        <strong v-if="datos.params.globalgap.ganaderia.otros_cultivos_convencional">Dispone de otros cultivos en
          convencional.</strong>

      </p>

      <strong style=" font-size: small;"
        v-if="datos.params.globalgap.certificacion_individual == true || datos.params.globalgap.sin_implantacion == true || datos.params.globalgap.con_implantacion == true">
        3.2.1.- DATOS ESPECÍFICOS PARA PRODUCTORES OPCIÓN 1.
        <br>
      </strong>
      <strong style=" font-size: small;"
       v-if="datos.params.globalgap.grupo_productores == true">
        3.2.2.- DATOS ESPECÍFICOS PARA OPCCION 1 CON QMS Y OPCCION 2.
        <br>
      </strong>
      <br><br>
      <table width="100%" border="1" cellspacing="0" cellpadding="5">
        <tbody>
          <tr>
            <td style="background: #92d050;padding:10px" bgcolor="#92d050" width="8%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>C&oacute;digo de explotaci&oacute;n<br />REGA</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="7%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>Nombre productor</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="10%">
              <p class="western" style="margin-left:25%;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>CIF</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="19%">
              <p class="western" style="margin-left:25%;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>Direcci&oacute;n<br />Localidad<br />Provincia<br />Tel&eacute;fono<br />Fax</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="9%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>Coordenadas<br />Latitud<br />Longitud</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="6%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>M&oacute;dulo<br />tipo de producto</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="5%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>Plazas de cebo</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="10%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>Producci&oacute;n<br />Toneladas/a&ntilde;o<br />Peso vivo</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="8%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>Existe<br />Producci&oacute;n Paralela<br />SI/NO</strong>
              </p>
            </td>

            <td style="background: #92d050;" bgcolor="#92d050" width="9%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>GGN de los fabricante<br />del pienso compuesto O<br />Esquema de certificaci&oacute;n<br />homologado</strong>
              </p>
            </td>

            <td style="background: #d9d9d9;" bgcolor="#d9d9d9" width="9%">
              <p class="western" style="margin:auto;text-align:center;writing-mode:vertical-rl;font-size:small;transform:rotate(180deg);height:max-content;letter-spacing:2px">
                <strong>Comunicaci&oacute;n de GGN<br />A RELLENAR POR<br />KAAM</strong>
              </p>
            </td>
          </tr>

          <tr v-for="item in datos.params.globalgap.cultivos">
            <td width="8%" height="13">
              <p class="western" align="left">{{ item.codigo_explotacion_rega }}</p>
            </td>
            <td width="7%">
              <p class="western" align="left">{{ item.nombre_productor }}</p>
            </td>
            <td valign="top" width="10%">
              <p class="western" align="left">{{ item.cif }}</p>
            </td>
            <td width="19%">
              <p class="western" align="left">{{ item.direccion_localidad_provincia_telefono_fax }}</p>
            </td>
            <td width="9%">
              <p class="western" align="left">{{ item.coordenadas_latitud_longitud }}</p>
            </td>
            <td width="6%">
              <p class="western" align="left">{{ item.modulo_tipo_producto }}</p>
            </td>
            <td width="5%">
              <p class="western" align="center">{{ item.plazas_cebo }}</p>
            </td>
            <td width="10%">
              <p class="western" align="center">{{ item.produccion_toneladas }}</p>
            </td>
            <td width="8%">
              <p class="western" align="center">{{ item.existe_produccion_paralela }}</p>
            </td>
            <td width="9%">
              <p class="western" align="center">{{ item.ggn_fabricante }}</p>
            </td>
            <td style="background: #d9d9d9;" bgcolor="#d9d9d9" width="9%">
              <p class="western" align="center">{{ item.comunicacion_ggn }}</p>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="western" style="text-align: justify; font-family: Calibri, serif; font-size: small;font-weight: bold;">
        <strong>M&oacute;dulos: Tipos de producto: Vacuno/ternero/vacuno lechero/ovino-caprino/cerdo. </strong>
        En caso de que alguno de los productores haya estado certificado en GLOBALG.A.P. anteriormente, marque en la
        tabla a ese productor con un signo * y en esos casos, indique aqu&iacute; su GGN.
      </p>

<br><br>
      <p class="western" align="justify">
        <span style="color: #002060;">
          <span style="font-family: Calibri, serif;">
            <span >
              <strong>3.3.- DATOS GENERALES.</strong>
            </span>
          </span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>3.3.1.- SUBCONTRATISTAS</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">: Describa Actividades subcontratadas (servicios veterinarios, medicamentos,
            asesor&iacute;a, servicios de prevenci&oacute;n de riesgos, DDD, etc) indicando el nombre del subcontratado
            y localizaci&oacute;n.</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{ datos.params.globalgap.subcontratistas }}</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;font-weight: bold;">
            <strong>3.3.2.- PROPIEDAD PARALELA/PRODUCCI&Oacute;N PARALELA EN GANADERIA.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify"  v-if="datos.params.globalgap.venta_misma_especia != null">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">&iquest;Produce o vende animales certificados y no certificados de la misma
            especie? </span>
        </span>
        <br>
        <span v-if="datos.params.globalgap.venta_misma_especia == true" style="font-family: Calibri, serif;"><br>
          <span style="font-size: small;"><input type="radio" checked disabled> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <span style="font-size: small;"><input type="radio" checked disabled> NO</span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.cria_misma_explotacion != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">En caso afirmativo, &iquest;Los cr&iacute;a en la misma explotaci&oacute;n
            ganadera? </span>
        </span>
        <span v-if="datos.params.globalgap.cria_misma_explotacion == true" style="font-family: Calibri, serif;">
          <span style="font-size: small;"><input type="radio" checked disabled> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <span style="font-size: small;"><input type="radio" checked disabled> NO</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">Indique de qu&eacute; manera diferencia los animales certificados de los no
            certificados (diferentes razas o rasgos morfol&oacute;gicos, diferentes ubicaciones, etc&hellip;)</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{ datos.params.globalgap.diferencia_entre_animales }}</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>3.3.3.- CERTIFICACI&Oacute;N ANTERIOR.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">Por favor, en el caso de estar certificado en GLOBALG.A.P. o haber estado
            anteriormente, indique su GGN y la entidad con la que estaba certificado anteriormente.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{ datos.params.globalgap.globalgap_detalle }}</span>
        </span>
      </p>
      <p v-if="datos.params.globalgap.globalgap_vigencia != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">Indique si su certificado GLOBALG.A.P. est&aacute; vigente en la actualidad
            (en ese caso afirmativo, incluya una copia en la solicitud.)</span>
        </span>
        <br>
        <span v-if="datos.params.globalgap.globalgap_vigencia == true" style="font-family: Calibri, serif;">
          <span style="font-size: small;"><input type="radio" checked disabled> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <span style="font-size: small;"><input type="radio" checked disabled> NO</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>3.3.4.- USO DE DATOS PERSONALES.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">La firma de la solicitud de certificaci&oacute;n lleva impl&iacute;cito el
            permiso de publicar los datos de registro en la Base de Datos de GLOBALG.A.P., para que todo el
            p&uacute;blico los pueda ver. Si desea una mayor restricci&oacute;n por favor ind&iacute;quenoslo marcando
            (X) las siguientes casillas: </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span v-if="datos.params.globalgap.ganaderia_uso_datos_personales" style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;"><input type="checkbox" checked disabled> Comunicaci&oacute;n m&iacute;nima de
            datos (Nombre, GGN, Estado GLOBALG.A.P).
          </span>
        </span>
        <span v-if="datos.params.globalgap.ganaderia_uso_datos_aportados" style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;"><input type="checkbox" checked disabled> Comunicaci&oacute;n de todos los
            datos aportados (incluyendo ubicaciones y
            resto de datos personales). </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>3.3.5.- REALIZACION DE LA AUDITORIA ANUAL.</strong>
          </span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.auditoria_dos_modulos" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;"><input type="checkbox" checked disabled> Deseo que KAAM realice la
            auditoria en 2 m&oacute;dulos: en oficina e in
            situ. </span>
        </span>
      </p>
    </div>

<br><br>
    <!-- 4.- PIENSO. -->
    <div v-if="datos.params.globalgap.pienso == '1'">
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>4.- PIENSO.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="left">
        <span style="color: #002060;">
          <span style="font-family: Calibri, serif;">
            <span style="color: #002060;">
              <strong>4.1. TIPO DE CERTIFICACI&Oacute;N.</strong>
            </span>
          </span>
        </span>
      </p>

      <p class="western" align="justify" v-if="datos.params.globalgap.indicar_instalaciones">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"></span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong><input type="checkbox" checked disabled /> OPCI&Oacute;N 1 </strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">(Indique los datos para todas las instalaciones a certificar).</span>
        </span>
      </p>

      <div class="western" align="justify" v-for="(instalacion, index) in datos.params.globalgap.instalaciones">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>R</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>RAZÓN SOCIAL INSTALACIÓN N&ordm; {{index+1}} ......</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{instalacion.razon_social}}</span>
        </span>

        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>N&ordm; LINEAS POR INSTALACION......</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">.......{{instalacion.especies.length}}</span>
        </span>

        <dl>
          <dd>
            <table width="886" cellspacing="0" cellpadding="7">
              <tbody>
                <tr>
                  <td width="276">
                    <p class="western" align="center">
                      <span style="font-family: Calibri, serif;">
                        <span style="font-size: small;">
                          <strong>ESPECIE</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td width="292">
                    <p class="western" align="center">
                      <span style="font-family: Calibri, serif;">
                        <span style="font-size: small;">
                          <strong>N&ordm; LINEAS POR ESPECIE</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                  <td width="274">
                    <p class="western" align="center">
                      <span style="font-family: Calibri, serif;">
                        <span style="font-size: small;">
                          <strong>TM/A&Ntilde;O DE PIENSO PRODUCIDO POR ESPECIE</strong>
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
                <tr valign="top" v-for="especie in instalacion.especies">
                  <td width="276">
                    {{especie.especie}}
                  </td>
                  <td width="292">
                    {{especie.especie_lineas}}
                  </td>
                  <td width="274">
                    {{especie.especie_pienso}}
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </dl>
      </div>


      <p class="western" align="left">
        <span style="color: #002060;">
          <span style="font-family: Calibri, serif;">
            <span style="color: #002060;">
              <strong>4.2.- DATOS GENERALES.</strong>
            </span>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>4.2.1.- SUBCONTRATISTAS</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">: Describa Actividades subcontratadas (servicios veterinarios, medicamentos,
            asesor&iacute;a, servicios de prevenci&oacute;n de riesgos, DDD, etc) indicando el nombre del subcontratado
            y localizaci&oacute;n.</span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{datos.params.globalgap.subcontratistas_pienso}}</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>4.2.2.- PROPIEDAD PARALELA/PRODUCCI&Oacute;N PARALELA EN GANADERIA.</strong>
          </span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.animales_certificados_pienso != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">&iquest;Produce o vende animales certificados y no certificados de la misma
            especie? </span>
        </span>
        <span v-if="datos.params.globalgap.animales_certificados_pienso == true" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> NO</span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.animales_explotacion != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">En caso afirmativo, &iquest;Los cr&iacute;a en la misma explotaci&oacute;n
            ganadera? </span>
        </span>
        <span v-if="datos.params.globalgap.animales_explotacion == true" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> NO</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">Indique de qu&eacute; manera diferencia los animales certificados de los no
            certificados (diferentes razas o rasgos morfol&oacute;gicos, diferentes ubicaciones, etc.)</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{datos.params.globalgap.animales_diferenciacion}}</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>4.2.3.- CERTIFICACI&Oacute;N ANTERIOR.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">Por favor, en el caso de estar certificado en GLOBALG.A.P. o haber estado
            anteriormente, indique su GGN y la entidad con la que estaba certificado anteriormente.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{datos.params.globalgap.certificacion_anterior}}</span>
        </span>
      </p>
      <p v-if="datos.params.globalgap.certificacion_vigente != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">Indique si su certificado GLOBALG.A.P. est&aacute; vigente en la actualidad
            (en ese caso afirmativo, incluya una copia en la solicitud.)</span>
        </span>
        <span v-if="datos.params.globalgap.certificacion_vigente == true" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> NO</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>4.2.4.- USO DE DATOS PERSONALES.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">La firma de la solicitud de certificaci&oacute;n lleva impl&iacute;cito el
            permiso de publicar los datos de registro en la Base de Datos de GLOBALG.A.P., para que todo el
            p&uacute;blico los pueda ver. Si desea una mayor restricci&oacute;n por favor ind&iacute;quenoslo marcando
            (X) las siguientes casillas: </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;" v-if="datos.params.globalgap.datos_minimos">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;font-weight: bold;"> Comunicaci&oacute;n m&iacute;nima de datos (Nombre, GGN, Estado GLOBALG.A.P).
          </span>
        </span>
        <span v-if="datos.params.globalgap.datos_extras" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;font-weight: bold;"> Comunicaci&oacute;n de todos los datos aportados (incluyendo ubicaciones y
            resto de datos personales). </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>4.2.5.- REALIZACION DE LA AUDITORIA ANUAL.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify" v-if="datos.params.globalgap.auditoria_confirmacion">
        <span style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;font-weight: bold;"> Deseo que KAAM realice la auditoria en 2 m&oacute;dulos: en oficina e in
            situ. </span>
        </span>
      </p>
    </div>

<br><br>
    <!-- 5.- CERTIFICACION CADENA DE CUSTODIA. -->
    <div>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>5.- CERTIFICACION CADENA DE CUSTODIA. </strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="color: #002060;">
          <span style="font-family: Calibri, serif;">
            <span style="color: #002060;">
              <strong>5.1.- TIPO DE CERTIFICACI&Oacute;N: OPCI&Oacute;N 1.</strong>
            </span>
          </span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">Deber&aacute;n estar certificados aquellos comerciantes o intermediarios que
              administren o comercialicen (compra/venta) productos certificados, incluidos los productores que
              act&uacute;an como comercializadores. y/o proveedores de servicios y los que gestionen productos
              certificados que se compren externamente.</span>
          </span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">Todo transporte subcontratado de animales deber&aacute; estar cubierto por el
              certificado CoC del matadero o por el certificado CoC del comercializador.</span>
          </span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">La norma CoC no permite la certificaci&oacute;n grupal, sin embargo, un grupo
              de productores certificado bajo IFA opci&oacute;n 2 podr&aacute; recibir el certificado GLOBAL G.A.P. CoC
              como entidad legal individual. Un miembro del grupo de productores no puede solicitar certificaci&oacute;n
              CoC dentro de su propio grupo, por lo que el Sistema de Gesti&oacute;n de la Calidad (SGC) deber&aacute;
              asegurar la trazabilidad y segregaci&oacute;n dentro de un grupo de productores.</span>
          </span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.tipo_individuales" class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">
              <strong>
                <input type="checkbox" checked disabled />
                Opci&oacute;n 1 &ndash; Sitios individuales.</strong>
            </span>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">
            <span lang="es-ES-u-co-trad">Un productor, grupo de productores o empresa individual con un &uacute;nico
              sitio de producci&oacute;n, procesamiento, manipulaci&oacute;n, almacenamiento y venta al consumidor final
              o centro administrativo deber&aacute; estar certificado como una entidad legal con un n&uacute;mero GGN o
              n&uacute;mero CoC. </span>
          </span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.tipo_multisitio_productor" class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">
              <strong>
                <input type="checkbox" checked disabled />
                Opci&oacute;n 1 &ndash; Multisitio: productor, grupo de productores o empresa individual.</strong>
            </span>
          </span>
        </span>
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">Un productor, grupo de productores o empresa individual es due&ntilde;o de
              varios sitios de producci&oacute;n, procesamiento, manipulaci&oacute;n, almacenamiento y venta al
              consumidor final o centros administrativos, los cuales no operan como entidades legales separadas. Esto se
              aplica para los subcontratistas y los centros administrativos de los intermediarios que no entran en
              contacto con el producto. </span>
          </span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.tipo_multisitio_comercios" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">
              <strong>
                <input type="checkbox" checked disabled />
                Opci&oacute;n 1. Multisitio: comercios minoristas y cadenas de restaurantes en franquicia.
              </strong>
            </span>
          </span>
        </span>
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">Una empresa individual que posee una red de tiendas minoristas o restaurantes
              en franquicia (sitios). Tanto los comercios como los restaurantes (sitios) funcionan como entidades
              legales independientes. Se registrar&aacute;n todos los sitios bajo una entidad legal con un solo
              GGN/n&uacute;mero CoC.</span>
          </span>
        </span>
      </p>


      <p class="western" align="justify">
        <span style="color: #002060;">
          <span style="font-family: Calibri, serif;">
            <span style="color: #002060;">
              <strong>5.2.- LISTADO DE OPERADORES DE LA OPCCION ELEGIDA. </strong>
            </span>
          </span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060l;">
            <strong>5.2.1.- </strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>CUMPLIMENTE UN EXCEL CON LOS SIGUIENTES DATOS DE PRODUCTORES/SITIOS DE
              PRODUCCION/SUBCONTRATISTAS</strong>
          </span>
        </span>
      </p>

      <table width="100%" cellspacing="0" cellpadding="5">
        <tbody>
          <tr>
            <td style="background: #92d050;" bgcolor="#92d050" width="9%" height="86">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Raz&oacute;n social de la empresa </strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="9%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>CIF</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="9%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>N&uacute;mero de registro sanitario</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="15%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Direcci&oacute;n</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Localidad</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Provincia</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Tel&eacute;fono</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Fax</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="7%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Actividades realizadas en la instalaci&oacute;n bajo el registro sanitario</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="9%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Tipo de producto etiquetado con N&ordm; GGN/Logotipo de la etiqueta GGN</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="12%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Producci&oacute;n del producto</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Toneladas/a&ntilde;o</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>(puede estar expresado en peso vivo de animales bajo la certificaci&oacute;n)</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="9%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>(*) Sistema de inocuidad alimentaria implantado</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="9%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Existe</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>Producci&oacute;n Paralela</strong>
                  </span>
                </span>
              </p>
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>SI/NO</strong>
                  </span>
                </span>
              </p>
            </td>
            <td style="background: #92d050;" bgcolor="#92d050" width="12%">
              <p class="western" align="center">
                <span style="font-family: Calibri, serif;">
                  <span style="font-size: small;">
                    <strong>(**) Subcontratistas que intervienen en alg&uacute;n proceso</strong>
                  </span>
                </span>
              </p>
            </td>
          </tr>
          <tr v-for="(item,index) in datos.params.globalgap.custodiaGrid.ecologicos">
            <td width="9%" height="43">
              <p class="western" align="left">{{ item.razon_social }}</p>
            </td>
            <td width="9%">
              <p class="western" align="left">{{ item.cif }}</p>
            </td>
            <td valign="top" width="9%">
              <p class="western" align="left">{{ item.numero_registro_sanitario }}</p>
            </td>
            <td width="15%">
              <p class="western" align="left">{{item.direccion_localidad_provincia_telefono_fax  }}</p>
            </td>
            <td width="7%">
              <p class="western" align="left">{{item.registro_sanitario }}</p>
            </td>
            <td width="9%">
              <p class="western" align="left">{{ item.ggn }}</p>
            </td>
            <td width="12%">
              <p class="western" align="center">{{ item.produccion_toneladas }}</p>
            </td>
            <td width="9%">
              <p class="western" align="center">{{ item.sistema_inocuidad }}</p>
            </td>
            <td width="9%">
              <p class="western" align="center">{{ item.produccion_paralela }}</p>
            </td>
            <td width="12%">
              <p class="western" align="center">{{ item.subcontratistas }}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="western" lang="es-ES-u-co-trad" align="justify">&nbsp;</p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">(*): </span>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">
              <strong>Sistema de inocuidad alimentaria</strong>
            </span>
          </span>
        </span>
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">: Indicar el sistema de inocuidad alimentaria que hay implantado en la
              raz&oacute;n social de la empresa. Tiene que ser un sistema reconocido por GFSI (BRC/IFS/ISO
              22000&hellip;) o un sistema APPCC basado en el C&oacute;dex Alimentarius.</span>
          </span>
        </span>
      </p>
      <p class="western" lang="es-ES-u-co-trad" align="justify">&nbsp;</p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">
              <strong>(*): Subcontratistas</strong>
            </span>
          </span>
        </span>
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">: Las actividades subcontratadas, sin tener la propiedad legal del producto en
              ning&uacute;n momento, comprendidas dentro del &aacute;mbito de certificaci&oacute;n CoC deber&aacute;n
              declararse durante el registro para permitir a KAAM programar las auditorias en todas las
              instalaciones que deban auditarse.</span>
          </span>
        </span>
      </p>
      <p class="western" align="left">
        <span style="color: #002060;">
          <span style="font-family: Calibri, serif;">
            <span style="color: #002060;">
              <strong>5.3.- DATOS GENERALES.</strong>
            </span>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>5.3.1.- SUBCONTRATISTAS</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">: Describa Actividades subcontratadas (servicios veterinarios, medicamentos,
            asesor&iacute;a, servicios de prevenci&oacute;n de riesgos, DDD, etc) indicando el nombre del subcontratado
            y localizaci&oacute;n.</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{ datos.params.globalgap.custodia_subcontratistas_actividades }}</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>5.3.2.- PROPIEDAD PARALELA/PRODUCCI&Oacute;N PARALELA EN GANADERIA.</strong>
          </span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.custodia_venta_misma_especia != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">&iquest;Produce o vende animales certificados y no certificados de la misma
            especie? </span>
        </span>
        <span v-if="datos.params.globalgap.custodia_venta_misma_especia = true" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> NO</span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.custodia_cria_misma_explotacion != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small; font-weight: bold;">En caso afirmativo, &iquest;Los cr&iacute;a en la misma explotaci&oacute;n
            ganadera? </span>
        </span>
        <span v-if="datos.params.globalgap.custodia_cria_misma_explotacion == true" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> NO</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small; font-weight: bold;">Indique de qu&eacute; manera diferencia los animales certificados de los no
            certificados (diferentes razas o rasgos morfol&oacute;gicos, diferentes ubicaciones, etc&hellip;)</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{ datos.params.globalgap.custodia_diferencia_entre_animales }}</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>5.3.3.- CERTIFICACI&Oacute;N ANTERIOR.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;font-weight: bold;">
          <span style="font-size: small;">Por favor, en el caso de estar certificado en GLOBALG.A.P. o haber estado
            anteriormente, indique su GGN y la entidad con la que estaba certificado anteriormente.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">{{ datos.params.globalgap.custodia_globalgap_detalle }}</span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.custodia_globalgap_vigencia != null" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">Indique si su certificado GLOBALG.A.P. est&aacute; vigente en la actualidad
            (en ese caso afirmativo, incluya una copia en la solicitud.)</span>
        </span>
        <span v-if="datos.params.globalgap.custodia_globalgap_vigencia == true" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> SI </span>
        </span>
        <span v-else style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;"> NO</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>5.3.4.- USO DE DATOS PERSONALES.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;font-weight: bold;">La firma de la solicitud de certificaci&oacute;n lleva impl&iacute;cito el
            permiso de publicar los datos de registro en la Base de Datos de GLOBALG.A.P., para que todo el
            p&uacute;blico los pueda ver. Si desea una mayor restricci&oacute;n por favor ind&iacute;quenoslo marcando
            (X) las siguientes casillas: </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span v-if="datos.params.globalgap.custodia_uso_datos_personales" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;font-weight: bold;"> Comunicaci&oacute;n m&iacute;nima de datos (Nombre, GGN, Estado GLOBALG.A.P).
          </span>
        </span>
        <span v-if="datos.params.globalgap.custodia_uso_datos_aportados" style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;font-weight: bold;"> Comunicaci&oacute;n de todos los datos aportados (incluyendo ubicaciones y
            resto de datos personales). </span>
        </span>
      </p>

      <p v-if="datos.params.globalgap.custodia_auditoria_dos_modulos" class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <strong>5.3.5.- REALIZACION DE LA AUDITORIA ANUAL.</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <input type="checkbox" checked disabled />
          <span style="font-size: small;font-weight: bold;"> Deseo que KAAM realice la auditoria en 2 m&oacute;dulos: en oficina e in
            situ. </span>
        </span>
      </p>
    </div>

    <!-- 6º- TASAS. -->
    <br><br>
    <div>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="color: #002060;">
            <em>
              <strong>6</strong>
            </em>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>&ordm;- TASAS. </strong>
          </span>
          <span style="font-family: Calibri, serif;">
            <span style="font-size: small;">{{ datos.params.globalgap.tasas }}</span>
          </span>
        </span>
      </p>
    </div>


    <!-- 7º- CONDICIONES GENERALES DE CONTRATACION. -->
    <div>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>7&ordm;- CONDICIONES GENERALES DE CONTRATACION.</strong>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Primero:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Que Kaam tiene concedido su reconocimiento como Organismo Independiente
            de Certificaci&oacute;n, por la Consejer&iacute;a de Agricultura, Pesca y Alimentaci&oacute;n de la Junta de
            Andaluc&iacute;a y trabaja en todo el territorio nacional y en Portugal. </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Segundo:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Que Kaam est&aacute; acreditado conforme a la Norma UNE-EN ISO 17065 por
            la entidad Nacional de Acreditaci&oacute;n (ENAC) en el sector agroalimentario, en particular para el
            Esquema GLOBALG.A.P.; pudiendo consultar su alcance en la P&aacute;gina web de ENAC (</span>
        </span>
        <span style="color: #0563c1;">
          <u>
            <a href="http://www.enac.es/">
              <span style="font-family: Calibri, serif;">
                <span style="font-size: small;">www.enac.es</span>
              </span>
            </a>
          </u>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">).</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Tercero:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Que en el caso concreto de la certificaci&oacute;n GLOBALG.A.P. el (los)
            documento (s) normativo (s) reconocido para la certificaci&oacute;n externa son los documentos de su
            propiedad que figuran al p&uacute;blico en la P&aacute;gina Web de GLOBALG.A.P. y que deben ser certificadas
            por un Organismo Independiente de Control acreditado en la Norma EN ISO/IEC 17.065.</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Cuarto:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Para la certificaci&oacute;n de GLOBALG.A.P., Kaam aplicara los
            siguientes documentos t&eacute;cnicos de su propiedad:</span>
        </span>
      </p>

      <ul>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Procedimiento de trabajo para la realizaci&oacute;n de la auditoria de
                certificaci&oacute;n PG_CER- 03, en vigor.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Procedimiento de trabajo para la certificaci&oacute;n GLOBALG.A.P. para el
                alcance de ganader&iacute;a y para la fabricaci&oacute;n de piensos en alimentaci&oacute;n animal PE_CER
                _06, en vigor.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Procedimiento de Uso de la Marca de Calidad PG_CER_04, en vigor.</span>
            </span>
          </p>
        </li>
      </ul>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Quinto:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> El cliente se obliga a aceptar todas las condiciones establecidas en los
            manuales de calidad y procedimientos de esta entidad. Y m&aacute;s concretamente a:</span>
        </span>
      </p>

      <ul>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Cumplir en todo momento las disposiciones aplicables al programa de
                certificaci&oacute;n definido por Kaam. Cumplir con los requisitos de certificaci&oacute;n,
                incluyendo la implementaci&oacute;n de los cambios adecuados cuando los comunica el organismo de
                certificaci&oacute;n.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Si la certificaci&oacute;n se aplica a la producci&oacute;n en curso, el
                producto certificado continuar&aacute; cumpliendo con los requisitos del producto.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Tomar todas las medidas necesarias para que pueda realizarse adecuadamente
                la auditoria, seguimiento, revaluaci&oacute;n o resoluci&oacute;n de reclamaciones, incluyendo las
                disposiciones para examinar la documentaci&oacute;n y los registros y tener acceso al equipo, las
                ubicaciones, las &aacute;reas, el personal y los subcontratistas del cliente que sean pertinentes,
                as&iacute; como, la investigaci&oacute;n de las quejas y la participaci&oacute;n de observadores, si es
                aplicable.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Tomar las medidas para que pueda verificarse el cumplimiento del documento
                normativo.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Las declaraciones sobre la certificaci&oacute;n han de ser coherentes con
                el alcance de la certificaci&oacute;n. </span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">El cliente no utilizar&aacute; su certificaci&oacute;n de producto de
                manera que ocasione mala reputaci&oacute;n para Kaam y no realizar&aacute; ninguna
                declaraci&oacute;n relacionada con su certificaci&oacute;n de producto que el Kaam pueda considerar
                enga&ntilde;osa o no autorizada. </span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Inmediatamente despu&eacute;s de la suspensi&oacute;n, retirada o la
                finalizaci&oacute;n del contrato, el cliente dejar&aacute; de utilizar todo material comercial y/o
                publicitario que contenga alguna referencia a la misma y emprender&aacute; las acciones exigidas por el
                esquema de certificaci&oacute;n y cualquier otra medida que se le requiera. </span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Si el cliente suministrara copias de los documentos de
                certificaci&oacute;n, con permiso de Kaam, &eacute;stos deber&aacute;n reproducirse en su totalidad
                y de la manera que este establecido en sus procedimientos.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Si el cliente hiciere referencia a su certificaci&oacute;n de producto en
                medios de comunicaci&oacute;n tales como documentos, folletos, p&aacute;ginas web, publicidad,
                cumplir&aacute; con los requisitos de Kaam o los especificados por el esquema de
                certificaci&oacute;n.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">El cliente cumplir&aacute; con todos los requisitos que pueda estipular el
                esquema de certificaci&oacute;n con relaci&oacute;n al uso de las marcas de conformidad (PG_CER-04
                &ldquo;Procedimiento para el correcto uso de la marca de conformidad&rdquo;) y a la informaci&oacute;n
                relacionada con el producto y utilizar el certificado &uacute;nicamente en el &aacute;mbito del alcance
                concedido.</span>
            </span>
          </p>
        </li>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">Deber&aacute; conservar un registro de todas las quejas que con respecto
                al cumplimiento de los requisitos de certificaci&oacute;n se le hayan planteado y ponerlo a
                disposici&oacute;n de Kaam cuando se le solicite. Deber&aacute; tomar y documentar las acciones
                adecuadas con respecto a las quejas planteadas.</span>
            </span>
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p class="western" align="justify">
            <span style="font-family: Calibri, serif;">
              <span style="font-size: small;">El cliente informar&aacute; a Kaam, sin retraso, de cualquier cambio
                que pueda afectar a su capacidad para cumplir los requisitos de certificaci&oacute;n (cambios en su
                condici&oacute;n legal, organizaci&oacute;n, gesti&oacute;n, direcciones, modificaciones en el
                producto&hellip;)</span>
            </span>
          </p>
        </li>
      </ul>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Sexto:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Si Kaam tuviera (deber de informaci&oacute;n) la obligaci&oacute;n legal
            de facilitar la informaci&oacute;n relativa al control efectuado sobre el cliente siempre y cuando la
            autoridad competente en la materia se lo solicite, extremo este que el cliente consiente a la firma del
            presente. </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>S&eacute;ptimo</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">: El cliente recibir&aacute; una copia del procedimiento PE_CER -03 (Proceso
            de Certificaci&oacute;n) y se obliga al cumplimiento de lo establecido en el mismo, previo a la
            realizaci&oacute;n de la auditoria inicial una vez haya aceptado la oferta econ&oacute;mica mediante la
            firma de la presente solicitud.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Octavo</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">: El cliente se compromete a permitir tanto las visitas en las que Kaam
            vaya acompa&ntilde;ada de la administraci&oacute;n p&uacute;blica, de Entidad Nacional de
            Acreditaci&oacute;n (ENAC) o de alg&uacute;n otro estamento con derecho legal a auditarla. </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Noveno: </strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">Kaam se compromete a comenzar su labor acorde con la legislaci&oacute;n
            vigente, como m&aacute;ximo en el plazo de 15 d&iacute;as a partir de la firma de esta solicitud.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimo:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Kaam es responsable de la gesti&oacute;n de toda la informaci&oacute;n
            obtenida o creada durante las actuaciones de certificaci&oacute;n, toda esta informaci&oacute;n se considera
            privada y confidencial. Kaam, informa al cliente de que se proceder&aacute; a poner a
            disposici&oacute;n del p&uacute;blico la informaci&oacute;n relativa al alcance de su
            certificaci&oacute;n.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Und&eacute;cimo</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">: Cuando por ley o autorizaci&oacute;n de las presentes disposiciones
            contractuales, se exija a Kaam la divulgaci&oacute;n de la informaci&oacute;n considerada confidencial,
            &eacute;sta notificar&aacute; al cliente o persona implicada, la informaci&oacute;n proporcionada salvo que
            est&eacute; prohibido por Ley.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">La informaci&oacute;n relativa al cliente que Kaam obtenga de fuentes
            distintas al cliente, ser&aacute; tratada como informaci&oacute;n confidencial por parte de la
            entidad.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Duod&eacute;cimo:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Kaam, en caso necesario, podr&aacute; contratar externamente la
            realizaci&oacute;n de las actividades de certificaci&oacute;n; en todo caso, la entidad, ser&aacute; la
            responsable de las actuaciones llevadas a cabo por dicho personal y responder&aacute; ante su cliente de la
            misma manera que si la actividad de certificaci&oacute;n se llevase a cabo por personal propio de la
            entidad. En todo caso, el cliente ser&aacute; previamente informado de la presente circunstancia, con el fin
            de dar la opci&oacute;n de objetar lo que el cliente estime oportuno. </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimotercero:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> El cliente satisfar&aacute; las cantidades establecidas en la oferta
            aceptada, para la realizaci&oacute;n de la labor de la entidad de certificaci&oacute;n y en el caso de que
            las hubiera, las tasas fijadas por el due&ntilde;o del esquema de certificaci&oacute;n.</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">Una vez planificada una auditoria y confirmada la fecha por el cliente, en
            caso de producirse una cancelaci&oacute;n o aplazamiento de la misma por parte del propio cliente en las 4
            semanas anteriores a la fecha de realizaci&oacute;n acordada, Kaam, se reserva el derecho de emitir una
            nota de cargo por un importe equivalente al 100% del precio presupuestado para la auditoria.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">Las auditorias extraordinarias ser&aacute;n facturadas a raz&oacute;n de
            1.000, 00 &euro; /auditor /jornada (IVA no incluido). Los gastos de traslado y hospedaje de los auditores
            ser&aacute;n facturados aparte a su coste, contra presentaci&oacute;n de facturas.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimocuarto:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> El cliente es el garante del sistema de producci&oacute;n que &eacute;l lleva
            a cabo y de que sus productos certificados cumplan con las condiciones de certificaci&oacute;n y las
            referidas en el documento normativo.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimoquinto:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> El cliente facilitar&aacute; en todo momento los medios y
            documentaci&oacute;n necesarios para que los evaluadores de Kaam puedan realizar las labores de
            auditor&iacute;a e inspecciones dispuestas.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimosexto</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">: Una vez se le otorga al cliente la certificaci&oacute;n y, por tanto el uso
            de la marca de certificaci&oacute;n, &eacute;ste tendr&aacute; la obligaci&oacute;n de cumplir todas las
            obligaciones que se establecen en el PG_CER- 04 (Procedimiento Para el Correcto Uso de la Marca de
            Certificaci&oacute;n) que le ser&aacute; facilitado en el momento en que se le notifique la concesi&oacute;n
            del certificado de aprobaci&oacute;n junto a los logos de las marcas.</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimos&eacute;ptimo:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> El cliente puede realizar un recurso contra los resultados de las decisiones
            del &oacute;rgano encargado de la toma de decisiones sobre la certificaci&oacute;n cuando entienda que
            &eacute;stas se han tomado de forma que se contradiga alg&uacute;n apartado de la norma de referencia o,
            bas&aacute;ndose en alguna actuaci&oacute;n de la entidad de certificaci&oacute;n que no se realice acorde
            con la misma. </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">Dicho recurso ser&aacute; presentado ante la entidad, por escrito, en un plazo
              de tiempo no superior a los 30 d&iacute;as naturales desde que el interesado hubiera tenido conocimiento
              de la producci&oacute;n del acto que da pie al mismo. La entidad de certificaci&oacute;n seguir&aacute; el
              procedimiento descrito para ello en el procedimiento </span>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <span lang="es-ES-u-co-trad">PG 13 &ldquo;Quejas, reclamaciones, apelaciones y recursos.</span>
          </span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimoctavo:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> Esta solicitud comenzar&aacute; a regir en la fecha de su firma,
            concert&aacute;ndose por el plazo inicial establecido en la oferta aceptada.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">Concluido el per&iacute;odo contractual pactado, el contrato se
            prorrogar&aacute;, por la t&aacute;cita por per&iacute;odos de cuatro a&ntilde;os mientras una de las partes
            no notifique a la otra, en el plazo de los quince d&iacute;as anteriores a la fecha del vencimiento del
            contrato o de alguna de sus eventuales pr&oacute;rrogas, su deseo de darlo por terminado.</span>
        </span>
      </p>
      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">
            <strong>Decimonoveno:</strong>
          </span>
        </span>
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;"> El contrato podr&aacute; rescindirse en cualquier momento de su vigencia, por
            las siguientes circunstancias: Acuerdo entre las partes; novaci&oacute;n; voluntad unilateral de alguna de
            las partes ante un incumplimiento grave de la otra, cuando este incumplimiento cause grave perjuicio a la
            primera; y por incumplimiento de las obligaciones reciprocas.</span>
        </span>
      </p>

      <p class="western" align="justify">
        <span style="font-family: Calibri, serif;">
          <span style="font-size: small;">Para el caso de la rescisi&oacute;n del contrato por las causas anteriormente
            se&ntilde;aladas la parte que proponga la misma habr&aacute; de comunic&aacute;rselo a la otra por escrito
            con una antelaci&oacute;n m&iacute;nima de un mes, rese&ntilde;ando en el mismo escrito los motivos que le
            lleva a solicitar la misma. En el caso de que existan facturas pendientes de pago por parte del cliente,
            estas deber&aacute;n estar abonadas antes de la fecha efectiva de la retirada de la certificaci&oacute;n y
            rescisi&oacute;n del contrato.</span>
        </span>
      </p>


      <center>
        <table width="579" cellspacing="0" cellpadding="7">
          <tbody>
            <tr valign="top">
              <td width="274">
                <p class="western" align="left">
                  <span style="font-family: Calibri, serif;">
                    <span style="font-size: small;">
                      <strong>Firma del solicitante</strong>
                    </span>
                  </span>
                </p>


                <p class="western" align="left">
                  <span style="font-family: Calibri, serif;">
                    <span style="font-size: small;">
                      <strong>Fecha:</strong>
                    </span>
                  </span>
                </p>
              </td>
              <td width="274">
                <p class="western" align="left">
                  <span style="font-family: Calibri, serif;">
                    <span style="font-size: small;">
                      <strong>kaam, SL</strong>
                    </span>
                  </span>
                </p>


                <p class="western" align="left">
                  <span style="font-family: Calibri, serif;">
                    <span style="font-size: small;">
                      <strong>Fecha:</strong>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </center>

    </div>
    <!--<v-container>
      <v-row>
        <v-col cols="12">
          <h2>7º- CONDICIONES GENERALES DE CONTRATACION.</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <p>Primero: Que Kaam tiene concedido su reconocimiento como Organismo Independiente de Certificación, por la Consejería de Agricultura, Pesca y Alimentación de la Junta de Andalucía y trabaja en todo el territorio nacional y en Portugal. </p>
          <p>Segundo: Que Kaam está acreditado conforme a la Norma UNE-EN ISO 17065 por la entidad Nacional de Acreditación (ENAC) en el sector agroalimentario, en particular para el Esquema GLOBALG.A.P.; pudiendo consultar su alcance en la Página web de ENAC (www.enac.es).</p>
          <p>Tercero: Que en el caso concreto de la certificación GLOBALG.A.P. el (los) documento (s) normativo (s) reconocido para la certificación externa son los documentos de su propiedad que figuran al público en la Página Web de GLOBALG.A.P. y que deben ser certificadas por un Organismo Independiente de Control acreditado en la Norma EN ISO/IEC 17.065.</p>
          <p>Cuarto: Para la certificación de GLOBALG.A.P., Kaam aplicara los siguientes documentos técnicos de su propiedad:</p>
          <ul>
            <li>Procedimiento de trabajo para la realización de la auditoria de certificación PG_CER- 03, en vigor.</li>
            <li>Procedimiento de trabajo para la certificación GLOBALG.A.P. para el alcance de ganadería y para la fabricación de piensos en alimentación animal PE_CER _06, en vigor.</li>
            <li>Procedimiento de Uso de la Marca de Calidad PG_CER_04, en vigor.</li>
          </ul>
          <p>Quinto: El cliente se obliga a aceptar todas las condiciones establecidas en los manuales de calidad y procedimientos de esta entidad.</p>
          <ul>
            <li>Cumplir en todo momento las disposiciones aplicables al programa de certificación definido por Kaam. Cumplir con los requisitos de certificación, incluyendo la implementación de los cambios adecuados cuando los comunica el organismo de certificación.</li>
            <li>Si la certificación se aplica a la producción en curso, el producto certificado continuará cumpliendo con los requisitos del producto.</li>
            <li>Tomar todas las medidas necesarias para que pueda realizarse adecuadamente la auditoria, seguimiento, revaluación o resolución de reclamaciones, incluyendo las disposiciones para examinar la documentación y los registros y tener acceso al equipo, las ubicaciones, las áreas, el personal y los subcontratistas del cliente que sean pertinentes, así como, la investigación de las quejas y la participación de observadores, si es aplicable.</li>
            <li>Tomar las medidas para que pueda verificarse el cumplimiento del documento normativo.</li>
            <li>Las declaraciones sobre la certificación han de ser coherentes con el alcance de la certificación.</li>
            <li>El cliente no utilizará su certificación de producto de manera que ocasione mala reputación para Kaam y no realizará ninguna declaración relacionada con su certificación de producto que el Kaam pueda considerar engañosa o no autorizada.  </li>
            <li>Inmediatamente después de la suspensión, retirada o la finalización del contrato, el cliente dejará de utilizar todo material comercial y/o publicitario que contenga alguna referencia a la misma y emprenderá las acciones exigidas por el esquema de certificación y cualquier otra medida que se le requiera. </li>
            <li>Si el cliente suministrara copias de los documentos de certificación, con permiso de Kaam, éstos deberán reproducirse en su totalidad y de la manera que este establecido en sus procedimientos.</li>
            <li>Si el cliente hiciere referencia a su certificación de producto en medios de comunicación tales como documentos, folletos, páginas web, publicidad, cumplirá con los requisitos de Kaam o los especificados por el esquema de certificación.</li>
            <li>El cliente cumplirá con todos los requisitos que pueda estipular el esquema de certificación con relación al uso de las marcas de conformidad (PG_CER-04 “Procedimiento para el correcto uso de la marca de conformidad”) y a la información relacionada con el producto y utilizar el certificado únicamente en el ámbito del alcance concedido.</li>
            <li>Deberá conservar un registro de todas las quejas que con respecto al cumplimiento de los requisitos de certificación se le hayan planteado y ponerlo a disposición de Kaam cuando se le solicite. Deberá tomar y documentar las acciones adecuadas con respecto a las quejas planteadas.</li>
            <li>El cliente informará a Kaam, sin retraso, de cualquier cambio que pueda afectar a su capacidad para cumplir los requisitos de certificación (cambios en su condición legal, organización, gestión, direcciones, modificaciones en el producto…)</li>
          </ul>
          <p>Sexto: Si Kaam tuviera (deber de información) la obligación legal de facilitar la información relativa al control efectuado sobre el cliente siempre y cuando la autoridad competente en la materia se lo solicite, extremo este que el cliente consiente a la firma del presente. </p>
          <p>Séptimo: El cliente recibirá una copia del procedimiento PE_CER -03 (Proceso de Certificación) y se obliga al cumplimiento de lo establecido en el mismo, previo a la realización de la auditoria inicial una vez haya aceptado la oferta económica mediante la firma de la presente solicitud.</p>
          <p>Octavo: El cliente se compromete a permitir tanto las visitas en las que Kaam vaya acompañada de la administración pública, de Entidad Nacional de Acreditación (ENAC) o de algún otro estamento con derecho legal a auditarla. </p>
          <p>Noveno: Kaam se compromete a comenzar su labor acorde con la legislación vigente, como máximo en el plazo de 15 días a partir de la firma de esta solicitud.</p>
          <p>Decimo: Kaam es responsable de la gestión de toda la información obtenida o creada durante las actuaciones de certificación, toda esta información se considera privada y confidencial. Kaam, informa al cliente de que se procederá a poner a disposición del público la información relativa al alcance de su certificación.</p>
          <p>Undécimo: Cuando por ley o autorización de las presentes disposiciones contractuales, se exija a Kaam la divulgación de la información considerada confidencial, ésta notificará al cliente o persona implicada, la información proporcionada salvo que esté prohibido por Ley.</p>
          <p>La información relativa al cliente que Kaam obtenga de fuentes distintas al cliente, será tratada como información confidencial por parte de la entidad.</p>
          <p>Duodécimo: Kaam, en caso necesario, podrá contratar externamente la realización de las actividades de certificación; en todo caso, la entidad, será la responsable de las actuaciones llevadas a cabo por dicho personal y responderá ante su cliente de la misma manera que si la actividad de certificación se llevase a cabo por personal propio de la entidad. En todo caso, el cliente será previamente informado de la presente circunstancia, con el fin de dar la opción de objetar lo que el cliente estime oportuno. </p>
          <p>Decimotercero: El cliente satisfará las cantidades establecidas en la oferta aceptada, para la realización de la labor de la entidad de certificación y en el caso de que las hubiera, las tasas fijadas por el dueño del esquema de certificación.</p>
          <p>Una vez planificada una auditoria y confirmada la fecha por el cliente, en caso de producirse una cancelación o aplazamiento de la misma por parte del propio cliente en las 4 semanas anteriores a la fecha de realización acordada, Kaam, se reserva el derecho de emitir una nota de cargo por un importe equivalente al 100% del precio presupuestado para la auditoria.</p>
          <p>Las auditorias extraordinarias serán facturadas a razón de 1.000, 00 € /auditor /jornada (IVA no incluido). Los gastos de traslado y hospedaje de los auditores serán facturados aparte a su coste, contra presentación de facturas.</p>
          <p>Decimocuarto: El cliente es el garante del sistema de producción que él lleva a cabo y de que sus productos certificados cumplan con las condiciones de certificación y las referidas en el documento normativo.</p>
          <p>Decimoquinto: El cliente facilitará en todo momento los medios y documentación necesarios para que los evaluadores de Kaam puedan realizar las labores de auditoría e inspecciones dispuestas.</p>
          <p>Decimosexto: Una vez se le otorga al cliente la certificación y, por tanto el uso de la marca de certificación, éste tendrá la obligación de cumplir todas las obligaciones que se establecen en el PG_CER- 04 (Procedimiento Para el Correcto Uso de la Marca de Certificación) que le será facilitado en el momento en que se le notifique la concesión del certificado de aprobación junto a los logos de las marcas.
            <br> Decimoséptimo: El cliente puede realizar un recurso contra los resultados de las decisiones del órgano encargado de la toma de decisiones sobre la certificación cuando entienda que éstas se han tomado de forma que se contradiga algún apartado de la norma de referencia o, basándose en alguna actuación de la entidad de certificación que no se realice acorde con la misma.
          </p>
          <p>Dicho recurso será presentado ante la entidad, por escrito, en un plazo de tiempo no superior a los 30 días naturales desde que el interesado hubiera tenido conocimiento de la producción del acto que da pie al mismo. La entidad de certificación seguirá el procedimiento descrito para ello en el procedimiento PG 13 “Quejas, reclamaciones, apelaciones y recursos.</p>
          <p>Decimoctavo: Esta solicitud comenzará a regir en la fecha de su firma, concertándose por el plazo inicial establecido en la oferta aceptada.</p>
          <p>Concluido el período contractual pactado, el contrato se prorrogará, por la tácita por períodos de cuatro años mientras una de las partes no notifique a la otra, en el plazo de los quince días anteriores a la fecha del vencimiento del contrato o de alguna de sus eventuales prórrogas, su deseo de darlo por terminado.</p>
          <p>Decimonoveno: El contrato podrá rescindirse en cualquier momento de su vigencia, por las siguientes circunstancias: Acuerdo entre las partes; novación; voluntad unilateral de alguna de las partes ante un incumplimiento grave de la otra, cuando este incumplimiento cause grave perjuicio a la primera; y por incumplimiento de las obligaciones reciprocas.</p>
          <p>Para el caso de la rescisión del contrato por las causas anteriormente señaladas la parte que proponga la misma habrá de comunicárselo a la otra por escrito con una antelación mínima de un mes, reseñando en el mismo escrito los motivos que le lleva a solicitar la misma. En el caso de que existan facturas pendientes de pago por parte del cliente, estas deberán estar abonadas antes de la fecha efectiva de la retirada de la certificación y rescisión del contrato.</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <table>
            <tr>
              <td>Firma del solicitante</td>
              <td>kaam, SL</td>
            </tr>
            <tr>
              <td>Fecha:</td>
              <td>Fecha:</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-container>-->
  </div>
</template>

<script>
  export default {
    props: {
      datos: {
        type: Object,
        default: () => {}
      }
    },
    name: "informe",
    mounted() {
      console.log(this.datos)
    },
    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : ''
      },
    }
  }
</script>

<style scoped>

</style>
