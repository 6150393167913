<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <!-- <v-icon v-on="on" :color="getColor(estado)">fiber_manual_record</v-icon> -->
      <v-chip v-on="on" :color="getColor(estado)" outlined>{{ label }}</v-chip>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
import estados from '~/models/estados'
export default {
  name: 'IconStatus',
  props: ['estado'],
  data: () => ({
    label: '',
  }),
  methods: {
    getColor(estado) {
      const foundItems = estados.getColor(estado)
      this.label = foundItems.length > 0 ? foundItems[0].label : ''
      return foundItems.length > 0 ? foundItems[0].color : 'transparent'
    },
  },
}
</script>

<style scoped></style>
