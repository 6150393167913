<template>
  <div>

    <v-tabs v-model="site_selected" color="primary" slider-color="primary" @change="generarTablasFicheros()">
      <v-tab v-for="(item, idx) in tipos" :key="idx" :href="'#' + item">
        <span class="primary--text">{{ item }}</span>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="site_selected" v-if="sites && sites.length">
      <v-tab-item v-for="tipo in tipos" :key="tipo.id" :eager="true" :value="tipo">
        <v-row>
          <!-- <v-col cols="2">
            <v-card>
              <v-list>
                <v-list-item-group mandatory v-model="site_selected" @change="generarTablasFicheros()">
                  <v-list-item v-for="(item, i) in tipos" :key="i" :value="item">
                    <v-list-item-content>
                      <v-list-item-title class="text-capitalize" v-text="item"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col> -->
          <v-col cols="12">
            <v-card flat>
              <v-card-title class="pt-0" primary-title>
                <component :is="actionsMultiple" @controlClick="controlClick($event)" v-if="actionsMultiple"/>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Búsqueda rápida"
                  single-line
                  hide-details
                  clearable
                  @keyup="updateFilter(tipo)"
                  @input="updateFilter(tipo)"
                ></v-text-field>
              </v-card-title>
              <v-card-text class="pa-0">

                <ag-grid-vue
                  id="ag-grid"
                  class="ag-theme-material ag-grid"
                  rowGroupPanelShow="always"
                  :animateRows="true"
                  :pagination="true"
                  :quickFilter="search"
                  :rowSelection="rowSelection"
                  :columnDefs="grid[tipo].columnDefs"
                  :rowData="grid[tipo].rowData"
                  :gridOptions="gridOptions[tipo]"
                  @grid-ready="onGridReady($event, tipo)"
                  @selectionChanged="selectionChange(tipo)"
                  @rowSelected="rowSelected($event)"
                  style="width: 100%;"
                  :style="{height: height}">
                </ag-grid-vue>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
  export default {
    props: {
      refreshCells: {type: Number, default: 0},
      height: {type: String, default: '74vh'},
      selectAllSites: {type: Boolean, default: false},
      multiple: {type: Boolean, default: false},
      protected: {type: Boolean, default: false},
      gridOptions: {type: Object, default: () => {}},
      sites: {type: Array, default: () => []},
      actions: {type: Array, default: () => []},
      editable: {type: Array, default: () => []},
    },
    data: () => ({
      search: '',
      tipos: [],
      site_data: {},
      site_selected: '',
      grid: {},
    }),
    watch: {
      sites() {
        this.getSites()
      },
      refreshCells() {
        this.getSites()
      }
    },
    computed: {
      rowSelection() {
        return this.multiple ? 'multiple' : null
      },
      actionsMultiple() {
        const action = this.actions.filter(element => element.tipo === this.site_selected)
        return action.length > 0 ? action[0].multiple : null
      }
    },
    methods: {
      rowSelected(event){
        this.$emit('rowSelected', event)
      },
      getSites() {
        if (this.sites && this.sites.length > 0) {
          let data = {}
          let tipos = []

          for (let i = 0; i < this.sites.length; i++) {
            const element = this.sites[i]
            if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
          }

          for (let i = 0; i < tipos.length; i++) {
            const tipo = tipos[i]
            data[tipo] = []
            this.grid[tipo] = {api: null, rowHeight: 40, columnDefs: [], rowData: [], selection: [], suppressPropertyNamesCheck: true}
          }

          for (let i = 0; i < this.sites.length; i++) {
            const element = this.sites[i]
            let obj = {}
            obj.uuid = element.uuid
            if (element.site_data) {
              for (let k = 0; k < element.site_data.length; k++) {
                const item = element.site_data[k]
                obj[item.keyname] = item.value
              }
            }
            data[element.tipo].push(obj)
          }

          this.tipos = tipos
          this.site_data = data
          if (!this.site_selected) this.site_selected = tipos[0]
          this.generarTablasFicheros()
        }
      },
      onGridReady(params, tipo) {
        console.info('gridReady', tipo);
        this.grid[tipo].api = params.api
        params.api.addEventListener('cellClicked', this.onCellClick)
        this.updateFilter(tipo)
      },
      updateFilter(tipo) {
        this.grid[tipo].api.setQuickFilter(this.search)
      },
      // Edición de celdas al pinchar
      onCellClick(params) {
        params.api.startEditingCell({rowIndex: params.rowIndex, colKey: params.column.getId()})
      },
      selectionChange(tipo) {
        const selection = this.grid[tipo].api.getSelectedRows()
        let body = null
        if (this.selectAllSites) {
          body = {
            tipos: this.tipos,
            tipo: tipo,
            selection: selection
          }
        } else body = selection
        this.$emit('selection', body)
      },
      isEditable(header) {
        if (this.protected) return false
        else if (this.editable.length > 0) {
          const site = this.editable.filter(element => element.tipo.toLowerCase() === this.site_selected.toLowerCase())
          const headers = site[0].headers
          return headers.includes(header)
        } else return true
      },
      controlClick(event) {
        const selection = this.grid[event.tipo].api.getSelectedRows()
        const body = {
          ...event,
          selection: selection,
          row: {}
        }
        this.grid[event.tipo].api.forEachNode(node => {
          if (node.isSelected()) body.row[node.data.uuid] = node.rowIndex + 1
        })
        this.$emit('action', body)
      },
      generarTablasFicheros(site) {
        const vm = this
        for (let i = 0; i < this.tipos.length; i++) {
          const tipo = this.tipos[i]
          const data = this.site_data[tipo]
          let columnDefs = []
          if (this.multiple) {
            columnDefs.unshift({
              headerName: "#", sortable: true, filter: true,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              checkboxSelection: true,
              valueGetter: params => params.node.rowIndex + 1
            })
          }
          if (data && data.length > 0) {
            const headers = Object.keys(data[0])
            for (let i = 0; i < headers.length; i++) {
              const columna = headers[i]
              if (columna !== 'uuid' && columna !== 'parent_uuid' && columna !== 'tipo') {
                columnDefs.push({
                  headerName: columna,
                  field: columna,
                  enableRowGroup: true,
                  editable: this.isEditable(columna),
                  resizable: true,
                  filter: true,
                  valueSetter: function (params) {
                    if (params.value) return params.value.toString()
                  }
                })
              }
            }
          }
          if (this.actions.length > 0) {
            const site = this.actions.filter(element => element.tipo.toLowerCase() === tipo.toLowerCase())
            if (site.length) {
              const action = site[0].action
              columnDefs.push({
                headerName: "Acciones",
                pinned: 'right',
                field: "actions",
                cellRendererFramework: action,
                cellRendererParams: {
                  controlClick: this.controlClick.bind(this)
                }
              })
            }
          }
          this.grid[tipo].columnDefs = columnDefs
          this.grid[tipo].rowData = data
          if (this.grid[tipo].api) {
            this.grid[tipo].api.refreshCells()
            this.grid[tipo].api.setQuickFilter(this.search)
          }
        }
        this.$emit('gridsReady', this.grid)
      }
    },
    created() {
      this.getSites()
    }
  }
</script>
