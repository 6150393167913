<template>
    <span v-if="isNotGroup">
      <v-icon style="font-size: 20px" color="primary" @click="controlClick($event)">mdi-delete-outline</v-icon>
    </span>
</template>

<script>
export default {
  computed: {
    isNotGroup () {
      return !!this.params.data
    },
  },
  methods: {
    controlClick($event) {
      if (this.params.confirmRemove instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
          event: $event,
          // ...something
        }
        this.params.confirmRemove();
      }
    }
  }
}
</script>
