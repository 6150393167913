/**
 * @project: certiapp-nuxt
 * @file:    \static\traducciones\es.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 10th July 2023
 * Last Modified: Mon Jul 10 2023
 **/

export default {
  global: {
    caramba: 'carambita',
  },

  personal: {
    'Contacto se llama {nombre}': 'Contact name is {nombre}',
  },
}
