<template>
  <div>

    <v-tabs v-model="site_selected" color="primary" slider-color="primary" @change="generarTablasFicheros(); calcRowHeight(site_selected)">
      <v-tab v-for="(item, idx) in tipos" :key="idx" :href="'#' + item">
        <span class="primary--text">{{ item }}</span>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="site_selected" v-if="sites && sites.length">
      <v-tab-item v-for="tipo in tipos" :key="tipo.id" :eager="true" :value="tipo">
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <v-card-title class="pt-0" primary-title>
                <component :is="actionsMultiple" @controlClick="controlClick($event)" v-if="actionsMultiple"/>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Búsqueda rápida"
                    single-line
                    hide-details
                    clearable
                    @keyup="updateFilter(tipo)"
                    @input="updateFilter(tipo)"
                ></v-text-field>
              </v-card-title>
              <v-card-text class="pa-0">
                <ag-grid-vue
                    id="ag-grid"
                    class="ag-theme-material ag-grid"
                    rowGroupPanelShow="always"
                    :animateRows="true"
                    :pagination="true"
                    :quickFilter="search"
                    :rowSelection="rowSelection"
                    :columnDefs="grid[tipo].columnDefs"
                    :rowData="grid[tipo].rowData"
                    :gridOptions="gridOptions && gridOptions[tipo]"
                    @grid-ready="onGridReady($event, tipo)"
                    @selectionChanged="selectionChange(tipo)"
                    @rowSelected="rowSelected($event)"
                    style="width: 100%;"
                    :style="{height: height}">
                </ag-grid-vue>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\expedientes\sites.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Thu Mar 02 2023
 **/

export default {
  props: {
    refreshCells: { type: Number, default: 0 },
    height: { type: String, default: '74vh' },
    selectAllSites: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    protected: { type: Boolean, default: false },
    gridOptions: {
      type: Object,
      default: () => {},
    },
    sites: { type: Array, default: () => [] },
    actions: { type: Array, default: () => [] },
    editable: { type: Array, default: () => [] },
    instalaciones: { type: Array, default: () => [] },
  },
  data: () => ({
    search: '',
    tipos: [],
    site_data: {},
    site_selected: '',
    sites_skipped: 0,
    grid: {},
  }),
  watch: {
    sites() {
      this.getSites()
    },
    refreshCells() {
      this.getSites()
    },
  },
  computed: {
    tiposInstalaciones() {
      return this.tipos.length ? [this.tipos[0], this.tipos[2], this.tipos[4], this.tipos[6]] : []
    },
    tiposActividades() {
      return this.tipos.length ? [this.tipos[1], this.tipos[3], this.tipos[5], this.tipos[7]] : []
    },
    rowSelection() {
      return this.multiple ? 'multiple' : null
    },
    actionsMultiple() {
      const action = this.actions.filter((element) => element.tipo === this.site_selected)
      return action.length > 0 ? action[0].multiple : null
    },
    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },
  },
  methods: {
    toggleSelect(grid, iteracion, tipo, select) {
      if (grid) {
        grid.api.forEachNode((node) => {
          // console.info(node.data)
          if (node.data.parent_uuid === iteracion) {
            node.setSelected(select)
          }
        })
      }
    },

    checkNodeSelected(grid, iteracion, tipo) {
      let isSelected = false
      if (grid) {
        const api = grid.api
        api.forEachNode((node) => {
          if (node.data.parent_uuid === iteracion && node.isSelected()) {
            isSelected = true
          }
        })
      }
      return isSelected
    },

    rowSelected(event) {
      const tipo = event.data.tipo
      const uuid = event.data.uuid
      const iteracion_uuid = event.data.iteracion_uuid
      const parent_uuid = event.data.parent_uuid
      if (
        this.tiposInstalaciones.includes(tipo) &&
        this.tiposInstalaciones.includes(this.site_selected)
      ) {
        event.node.selected
          ? this.checkAllChildren(tipo, iteracion_uuid)
          : this.uncheckAllChildren(tipo, iteracion_uuid)
      } else if (
        this.tiposActividades.includes(tipo) &&
        this.tiposActividades.includes(this.site_selected)
      ) {
        event.node.selected
          ? this.checkParent(tipo, parent_uuid)
          : this.uncheckParent(tipo, parent_uuid)
      }
    },

    checkAllChildren(tipo, iteracion_uuid) {
      if (this.tiposInstalaciones.includes(tipo)) {
        const gridTemp1 = this.grid[this.tipos[1]]
        this.toggleSelect(gridTemp1, iteracion_uuid, this.tipos[1], true)

        const gridTemp3 = this.grid[this.tipos[3]]
        this.toggleSelect(gridTemp3, iteracion_uuid, this.tipos[3], true)

        const gridTemp5 = this.grid[this.tipos[5]]
        this.toggleSelect(gridTemp5, iteracion_uuid, this.tipos[5], true)

        const gridTemp7 = this.grid[this.tipos[7]]
        this.toggleSelect(gridTemp7, iteracion_uuid, this.tipos[7], true)
      }
    },

    uncheckAllChildren(tipo, iteracion_uuid) {
      if (this.tiposInstalaciones.includes(tipo)) {
        const gridTemp1 = this.grid[this.tipos[1]]
        this.toggleSelect(gridTemp1, iteracion_uuid, this.tipos[1], false)

        const gridTemp3 = this.grid[this.tipos[3]]
        this.toggleSelect(gridTemp3, iteracion_uuid, this.tipos[3], false)

        const gridTemp5 = this.grid[this.tipos[5]]
        this.toggleSelect(gridTemp5, iteracion_uuid, this.tipos[5], false)

        const gridTemp7 = this.grid[this.tipos[7]]
        this.toggleSelect(gridTemp7, iteracion_uuid, this.tipos[7], false)
      }
    },

    checkParent(tipo, parent_uuid) {
      if (this.tiposActividades.includes(tipo)) {
        const grid = this.grid[this.tipos[0]]
        if (grid) {
          const apiTipo0 = this.grid[this.tipos[0]].api
          apiTipo0.forEachNode((node) => {
            if (node.data.iteracion_uuid === parent_uuid) {
              node.setSelected(true)
            }
          })
        }

        const grid2 = this.grid[this.tipos[2]]
        if (grid2) {
          const apiTipo0 = this.grid[this.tipos[2]].api
          apiTipo0.forEachNode((node) => {
            if (node.data.iteracion_uuid === parent_uuid) {
              node.setSelected(true)
            }
          })
        }

        const grid3 = this.grid[this.tipos[4]]
        if (grid3) {
          const apiTipo0 = this.grid[this.tipos[4]].api
          apiTipo0.forEachNode((node) => {
            if (node.data.iteracion_uuid === parent_uuid) {
              node.setSelected(true)
            }
          })
        }

        const grid4 = this.grid[this.tipos[6]]
        if (grid4) {
          const apiTipo0 = this.grid[this.tipos[6]].api
          apiTipo0.forEachNode((node) => {
            if (node.data.iteracion_uuid === parent_uuid) {
              node.setSelected(true)
            }
          })
        }
      }
    },
    // TODO REFACTOR uncheckParent
    uncheckParent(tipo, parent_uuid) {
      if (this.tiposActividades.includes(tipo)) {
        let parcelaProductorSelected = false
        // Revisamos si existe alguna parcela del productor seleccionada
        const grid = this.grid[this.tipos[0]]
        if (grid) {
          const apiTipo1 = this.grid[this.tipos[1]].api
          apiTipo1.forEachNode((node) => {
            if (node.data.parent_uuid === parent_uuid && node.isSelected()) {
              parcelaProductorSelected = true
            }
          })
          // Si no quedan parcelas del productor seleccionados lo quitamos
          if (!parcelaProductorSelected) {
            const apiTipo0 = this.grid[this.tipos[0]].api
            apiTipo0.forEachNode((node) => {
              if (node.data.iteracion_uuid === parent_uuid) {
                node.setSelected(false)
              }
            })
          }
        }

        const grid2 = this.grid[this.tipos[2]]
        if (grid2) {
          const apiTipo1 = this.grid[this.tipos[2]].api
          apiTipo1.forEachNode((node) => {
            if (node.data.parent_uuid === parent_uuid && node.isSelected()) {
              parcelaProductorSelected = true
            }
          })
          // Si no quedan parcelas del productor seleccionados lo quitamos
          if (!parcelaProductorSelected) {
            const apiTipo0 = this.grid[this.tipos[2]].api
            apiTipo0.forEachNode((node) => {
              if (node.data.iteracion_uuid === parent_uuid) {
                node.setSelected(false)
              }
            })
          }
        }

        const grid3 = this.grid[this.tipos[4]]
        if (grid3) {
          const apiTipo1 = this.grid[this.tipos[4]].api
          apiTipo1.forEachNode((node) => {
            if (node.data.parent_uuid === parent_uuid && node.isSelected()) {
              parcelaProductorSelected = true
            }
          })

          const grid4 = this.grid[this.tipos[6]]
          if (grid4) {
            const apiTipo1 = this.grid[this.tipos[6]].api
            apiTipo1.forEachNode((node) => {
              if (node.data.parent_uuid === parent_uuid && node.isSelected()) {
                parcelaProductorSelected = true
              }
            })
          }

          // Si no quedan parcelas del productor seleccionados lo quitamos
          if (!parcelaProductorSelected) {
            const apiTipo0 = this.grid[this.tipos[4]].api
            apiTipo0.forEachNode((node) => {
              if (node.data.iteracion_uuid === parent_uuid) {
                node.setSelected(false)
              }
            })
            const apiTipo1 = this.grid[this.tipos[6]].api
            apiTipo1.forEachNode((node) => {
              if (node.data.iteracion_uuid === parent_uuid) {
                node.setSelected(false)
              }
            })
          }
        }
      }
    },

    getSites() {
      if (this.sites && this.sites.length > 0) {
        let data = {}
        let tipos = []



        for (let i = 0; i < this.sites.length; i++) {
          const element = this.sites[i]
          if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
        }
        if (this.isOceGlobal) {
          tipos.sort()
          console.log('sor tipos', tipos)
        }

        for (let i = 0; i < tipos.length; i++) {
          const tipo = tipos[i]
          data[tipo] = []
          this.grid[tipo] = {
            api: null,
            rowHeight: 40,
            columnDefs: [],
            rowData: [],
            selection: [],
            suppressPropertyNamesCheck: true,
          }
        }

        for (let i = 0; i < this.sites.length; i++) {
          const element = this.sites[i]
          let ddbb = this.instalaciones.find((item) => item.uuid === element.instalacion?.uuid)
          if (ddbb && ddbb.is_active === false) {
            this.sites_skipped++
            continue
          }

          let obj = {}
          //obj.iri = element['@id']
          obj.uuid = element.uuid
          obj.parent_uuid = element.parent_uuid
          obj.iteracion_uuid = element.iteracion_uuid
          obj.tipo = element.tipo
          if (element.nombre) obj.nombre = element.nombre
          if (element.tipoOperador) obj.tipoOperador = element.tipoOperador
          if (element.tipoQueso) obj.tipoQueso = element.tipoQueso
          if (element.tipoOperadorQueseria) obj.tipoOperadorQueseria = element.tipoOperadorQueseria
          if (element.marcasPropias) obj.marcasPropias = element.marcasPropias
          if (element.marcasTerceros) obj.marcasTerceros = element.marcasTerceros
          if (element.observaciones) obj.observaciones = element.observaciones

          if (element.tipo.indexOf('instalacion') > -1) {
            // obj.rgseaa == undefined) {
            let rgseaa = null//element.site_data.find((item) => item.keyname == 'rgseaa')
            if (!rgseaa) {
              obj.rgseaa = element.instalacion?.rgseaa
            }
          }

          if (element.site_data) {
            for (let k = 0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
          }

          if (element.subtipo) {
            obj.subtipo = element.subtipo
          }

          if (element.estado) obj.estado = element.estado
          data[element.tipo].push(obj)
        }

        this.tipos = tipos
        this.site_data = data

        // console.warn(tipos, data)
        // debugger

        if (!this.site_selected) this.site_selected = tipos[0]
        this.$emit('tipos', tipos)
        this.generarTablasFicheros()
      }
    },

    onGridReady(params, tipo) {
      this.grid[tipo].api = params.api
      params.api.addEventListener('cellClicked', this.onCellClick)
      this.updateFilter(tipo)
    },

    updateFilter(tipo) {
      this.grid[tipo].api.setQuickFilter(this.search)
    },

    // Edición de celdas al pinchar
    onCellClick(params) {
      params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: params.column.getId() })
    },

    selectionChange(tipo) {
      const grid = this.grid[tipo].api
      if (grid) {
        const selection = this.grid[tipo].api.getSelectedRows()
        let body = null
        if (this.selectAllSites) {
          body = {
            tipos: this.tipos,
            tipo: tipo,
            selection: selection,
          }
        } else body = selection
        this.$emit('selection', body)
      }
    },

    isEditable(columna, tipo) {
      if (columna === 'nombre') return true
      else if (this.protected) return false
      else if (this.editable.length) {
        const site = this.editable.filter(
          (element) => element.tipo.toLowerCase() === tipo.toLowerCase()
        )
        const headers = site[0].headers
        return headers.includes(columna)
      } else return false
    },
    controlClick(event) {
      const selection = this.grid[event.tipo].api.getSelectedRows()
      const body = {
        ...event,
        selection: selection,
        row: {},
      }
      this.grid[event.tipo].api.forEachNode((node) => {
        if (node.isSelected()) body.row[node.data.uuid] = node.rowIndex + 1
      })
      this.$emit('action', body)
    },
    arrayCellRenderer(params) {
      if (params.data.hasOwnProperty(params.field)) {
        let splitData = params.data[params.field] ? params.data[params.field].split(',') : ''

        if (splitData.length > 1) {
          let output = '<ul>'
          splitData.forEach((elem) => (output = output + '<li>' + elem + '</li>'))
          output = output + '</ul>'
          return output
        } else {
          return params.data[params.field]
        }
      }
    },
    generarTablasFicheros(site) {
      const vm = this
      for (let i = 0; i < this.tipos.length; i++) {
        const tipo = this.tipos[i]
        const data = this.site_data[tipo]
        let columnDefs = []
        if (this.multiple) {
          columnDefs.unshift({
            headerName: '#',
            sortable: true,
            filter: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            // No longer used, but kept for reference
            // checkboxSelection: (params) => {
            //   if (this.instalaciones.length === 0) return true

            //   let _site = this.sites.find((item) => item.uuid === params.node.data.uuid)
            //   if (!_site) return true
            //   let ddbb = this.instalaciones.find((item) => item.uuid === _site.instalacion?.uuid)

            //   if (ddbb && ddbb.is_active) return true
            //   else return false
            // },
            valueGetter: (params) => params.node.rowIndex + 1,
          })
        }
        if (data && data.length > 0) {
          const headers = Object.keys(data[0])
          for (let i = 0; i < headers.length; i++) {
            const columna = headers[i]
            if (
              columna !== 'uuid' &&
              columna !== 'parent_uuid' &&
              columna !== 'iteracion_uuid' &&
              columna !== 'tipo' // &&
              // columna !== 'instalacion'
            ) {
              columnDefs.push({
                headerName: columna,
                field: columna,
                enableRowGroup: true,
                editable: false, // this.isEditable(columna, tipo),
                resizable: true,
                filter: true,
                autoHeight: true,
                cellRenderer: this.arrayCellRenderer,
                cellRendererParams: { field: columna },
                valueSetter: async function (params) {
                  const key = params.colDef.field
                  const oldValue = params.oldValue
                  const newValue = params.newValue
                  if (newValue) {
                    try {
                      const body = {
                        uuid: params.data.uuid,
                      }
                      body[key] = newValue
                      await vm.$store.dispatch('sites/update', body)
                      params.data[key] = newValue
                    } catch (e) {
                      params.data[key] = oldValue
                    } finally {
                      vm.grid[tipo].api.refreshCells()
                    }
                  }
                  return true
                },
              })
            }
          }
        }
        if (this.actions.length > 0) {
          const site = this.actions.filter(
            (element) => element.tipo.toLowerCase() === tipo.toLowerCase()
          )
          if (site.length) {
            const action = site[0].action
            columnDefs.push({
              headerName: 'Acciones',
              pinned: 'right',
              field: 'actions',
              cellRendererFramework: action,
              cellRendererParams: {
                controlClick: this.controlClick.bind(this),
              },
            })
          }
        }
        this.grid[tipo].columnDefs = columnDefs
        this.grid[tipo].rowData = data
        if (this.grid[tipo].api) {
          this.grid[tipo].api.refreshCells()
          this.grid[tipo].api.setQuickFilter(this.search)
          this.grid[tipo].api.resetRowHeights()
        }
      }
      this.$emit('gridsReady', this.grid)
    },

    calcRowHeight(site) {
      window.setTimeout(() => {
        this.grid[site].api.resetRowHeights()
      }, 500)
    },
  },
  created() {
    this.getSites()
  },
}
</script>
