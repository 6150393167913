/**
 * @project: certiapp-nuxt
 * @file:    \store\etiquetas.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 6th July 2022
 * Last Modified: Thu Oct 06 2022
 **/

import { uuid } from 'vue-uuid'
import axios from 'axios'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns all items
  // -----
  // Created on Wed Jul 06 2022
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    let url = 'etiquetas'

    if (params && params.request) url = url + '/' + params.request
    const xhr = await this.$axios.put(url)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // get()
  // Returns single item
  // -----
  // Created on Fri Jul 08 2022
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true

    const xhr = await this.$axios.get(`etiquetas/${uuid}`)

    rootState.loading = false
    return xhr
  },

  //+-------------------------------------------------
  // get_solicitud()
  // Returns single item
  // -----
  // Created on Fri Jul 08 2022
  //+-------------------------------------------------
  async get_solicitud({ rootState }, uuid) {
    rootState.loading = true

    const xhr = await this.$axios.get(`etiquetas/solicitudes/${uuid}`)

    rootState.loading = false
    return xhr.data
  },

  //+-------------------------------------------------
  // get_serie()
  // -----
  // Created on Wed Jul 20 2022
  //+-------------------------------------------------
  async get_serie({ rootState }, uuid) {
    rootState.loading = true

    const xhr = await this.$axios.get(`etiquetas/series/${uuid}`)

    rootState.loading = false
    return xhr.data
  },

  //+-------------------------------------------------
  // create()
  // -----
  // Created on Wed Jul 06 2022
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    const xhr = await this.$axios.post(`etiquetas`, {
      ...params,
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // create_series()
  // -----
  // Created on Thu Jul 14 2022
  //+-------------------------------------------------
  async create_series({ rootState }, params) {
    rootState.loading = true

    const xhr = await this.$axios.post(`etiquetas/series`, {
      ...params,
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // create_solicitar()
  // Required: cliente, tipo, uuid
  // -----
  // Created on Wed Jul 06 2022
  //+-------------------------------------------------
  async create_solicitar({ rootState }, params) {
    rootState.loading = true
    console.warn(params)
    const xhr = await this.$axios.post(`etiquetas/solicitudes`, {
      ...params,
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // create_consumir()
  // -----
  // Created on Fri Jul 08 2022
  //+-------------------------------------------------
  async create_consumir({ rootState }, params) {
    rootState.loading = true
    console.warn(params)
    const xhr = await this.$axios.post(`etiquetas/consumos`, {
      ...params,
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Wed Sep 22 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`etiquetas/${params.uuid}`, {
      elemento_compartido: params.uuid,
      ...params,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update_series()
  // -----
  // Created on Thu Jul 21 2022
  //+-------------------------------------------------
  async update_series({ rootState }, params) {
    rootState.loading = true

    if (params.empresa) delete params.empresa
    await this.$axios.patch(`etiquetas/series/${params.uuid}`, {
      uuid: params.uuid,
      ...params,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // deleteConsumo()
  // -----
  // Created on Thu Oct 06 2022
  //+-------------------------------------------------
  async deleteConsumo({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`etiquetas/consumos/${item.uuid}`)
    rootState.loading = false
  },
}
