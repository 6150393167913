<template>
  <v-app>
    <notification/>
    <v-main>
      <Menu/>
      <v-container class="main-container">
        <transition>
          <Nuxt/>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import Menu from "~/components/Menu"
  import Notification from "~/components/Notification"

  export default {
    name: "basic-toolbar-layout",
    components: {Notification, Menu}
  }
</script>

<style scoped>
  .main-container {
    max-width: 100% !important;
  }
</style>
