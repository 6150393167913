<template>
  <div v-if="isNotGroup">
    <template v-if="!hide && !isAprobada">

      <span v-if="auth && auth.canApprove">
        <v-icon style="font-size: 20px" color="primary" class="mr-5"
          @click.stop="confirmAprobar = true">mdi-check</v-icon>
      </span>

      <span v-if="auth && auth.canDelete">
        <v-icon style="font-size: 20px" color="primary" class="mr-5"
          @click.stop="confirmDelete = true">mdi-delete-outline</v-icon>
      </span>
    </template>

    <template v-else-if="auth && auth.canUndo">
      <v-tooltip bottom>
        <template v-slot:activator='{ on }'>
          <span v-on='on'>
            <v-icon style="font-size: 20px" color="primary" @click.stop="confirmDesaprobar = true">
              mdi-backup-restore</v-icon>
          </span>
        </template>
        Deshacer la aprobación
      </v-tooltip>
    </template>

    <!-- Confirmación Cambio de Estado -->
    <confirmation v-model="confirmAprobar" text="¿Desea aprobar la solicitud?" textButton="Aceptar"
      @confirm="aprobarSolicitud()" />

    <confirmation v-model="confirmDelete" :text="'¿Eliminar solicitud?'" colorButton="error" @confirm="deleteItem()" />

    <confirmation v-model="confirmDesaprobar" text="¿Confirma que desea eliminar la aprobación de la solicitud?"
      textButton="Aceptar" @confirm="desaprobarSolicitud()" />

  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  components: {
    Confirmation,
  },
  data: function () {
    return {
      confirmAprobar: false,
      confirmDelete: false,
      confirmDesaprobar: false,

      auth: {},
    }
  },
  computed: {
    isAprobada() {
      return this.params.data.estado === 'APROBADA'
    },

    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_EDIT() {
      return this.IS_LIST ? this.$store.state.permisos.can_edit : true
    },
    CAN_DELETE() {
      return this.IS_LIST ? this.$store.state.permisos.can_delete : true
    },
    isNotGroup() {
      return !!this.params.data
    },
    hide() {
      return this.params.value === true
    },
  },
  methods: {
    init() {
      if (this.params.init instanceof Function) this.params.init()
    },

    async deleteItem() {
      this.confirmDelete = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      if (this.params.node.data.uuid) {
        await this.$store.dispatch(`solicitudes/delete`, this.params.node.data)
        this.init()
      }
    },

    async aprobarSolicitud() {
      this.confirmAprobar = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      if (this.params.node.data.uuid) {
        await this.$store.dispatch(`solicitudes/aprobar`, this.params.node.data)
        this.init()
      }
    },

    async desaprobarSolicitud() {
      this.confirmDesaprobar = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      if (!this.params.node.data.uuid) return

      await this.$store.dispatch(`solicitudes/desaprobar`, this.params.node.data)
      this.init()
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'solicitudes',
        submodule: 'solicitudes',
        return: 'all',
        //check: ['canApprove', 'canUndo', 'canDelete'],
      })
      console.log('auth', this.auth)

      // Temporal mientras se trabaja con los nuevos sites:
      // this.auth.canApprove = false
      // this.auth.canUndo = false
    },
  },

  async mounted() {
    this.checkRoles()
  },
}
</script>
