<template>
  <div>

    <!-- Confirm -->
    <confirmation
      v-model="confirm"
      :text="`¿Desea eliminar el parametro?`"
      textButton="Eliminar"
      colorButton="error"
      @confirm="deleteParametro()"
      @close="close()"
    ></confirmation>

    <v-row>
      <v-col cols="12">
        <v-card outlined>

          <v-card-title primary-title>
            Parámetros
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn small fab depressed color="success" class="white--text" v-on="on" @click="editItem()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Crear</span>
            </v-tooltip>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="analisis.parametros"
              @click:row="editItem($event)"
            >
              <template v-slot:item.action="{ item }">
                <v-icon style="font-size: 20px" color="primary" @click.stop="deleteItem(item)">mdi-delete-outline</v-icon>
              </template>
            </v-data-table>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <!-- PARAMETRO DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title primary-title>
          Crear parámetro
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation v-model="valid">
              <v-row>

                <v-col cols="6">
                  <v-text-field
                    label="Clave"
                    v-model="item.clave"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Valor"
                    v-model="item.valor"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()">Cancelar</v-btn>
          <v-btn color="primary" :disabled="!valid" @click="createParametro()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Confirmation from "~/components/Confirmation"

export default {
  props: {
    toma_muestra: { type: Object, default: () => {} },
    muestra: { type: Object, default: () => {} },
    analisis: { type: Object, default: () => {} },
  },
  components: {
    Confirmation
  },
  data: () => ({
    valid: true,
    dialog: false,
    confirm: false,
    item: {},
    rules: [ v => !!v || 'Obligatorio' ], 
    headers: [
      { text: 'Clave', value: 'clave' },
      { text: 'Valor', value: 'valor' },
      { text: 'Acción', value: 'action' },
    ]
  }),
  methods: {
    close() {
      this.confirm = false
      this.dialog = false
      this.item = {}
      this.$refs.form.reset()
      this.$emit('init')
    },
    editItem(item) {
      if (item) this.item = item
      this.dialog = true
    },
    deleteItem(item) {
      this.item = item
      this.confirm = true
    },
    async createParametro() {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.item,
          toma_muestra: this.toma_muestra.uuid,
          muestra: this.muestra.uuid,
          analisis: this.analisis.uuid,
        }
        await this.$store.dispatch('tomas_muestra/createParametro', body)
        this.close()
      }
    },
    async updateParametro() {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.item,
          toma_muestra: this.toma_muestra.uuid,
          muestra: this.muestra.uuid,
          analisis: this.analisis.uuid
        }
        await this.$store.dispatch('tomas_muestra/updateParametro', body)
        this.close()
      }
    },
    async deleteParametro() {
      const body = {
        id: this.item.id,
        toma_muestra: this.toma_muestra.uuid,
        muestra: this.muestra.uuid,
        analisis: this.analisis.uuid
      }
      await this.$store.dispatch('tomas_muestra/deleteParametro', body)
      this.close()
    }
  }
}
</script>
