<template>
    <v-dialog v-model="value" persistent max-width="700px">
        <v-card>
        <v-card-title>
            <span v-if="title">{{ title }}</span>
        </v-card-title>
        <v-card-text>

            <v-form ref="form" lazy-validation>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field required :rules="RULES.REQUIRED" persistent-hint v-model="item.nombre" label="Nombre"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field class="mb-5"
                            label="Código de explotación REGA"
                            v-model="data.codigo_explotacion_rega"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="Nombre productor"
                            v-model="data.nombre_productor"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="CIF"
                            v-model="data.cif"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="Dirección Localidad Provincia Teléfono Fax"
                            v-model="data.direccion_localidad_provincia_telefono_fax"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="Coordenadas Latitud Longitud"
                            v-model="data.coordenadas_latitud_longitud"
                            dense
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field class="mb-5"
                            label="Módulo tipo de producto"
                            v-model="data.modulo_tipo_producto"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="Plazas de cebo"
                            v-model="data.plazas_cebo"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="Producción Toneladas/año Peso vivo"
                            v-model="data.produccion_toneladas"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="Existe Producción Paralela SI/NO"
                            v-model="data.existe_produccion_paralela"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                        <v-text-field class="mb-5"
                            label="GGN de los fabricante del pienso compuesto O Esquema de certificación homologado"
                            v-model="data.ggn_fabricante"
                            :rules="RULES.REQUIRED"
                            required
                            dense
                        ></v-text-field>

                    </v-col>

                    <v-col cols="12">
                        <v-text-field class="mb-5"
                            label="Comunicación de GGN A RELLENAR POR ARAPORCEI"
                            v-model="data.comunicacion_ggn"
                            dense
                        ></v-text-field>
                    </v-col>

                </v-row>
            </v-card-text>
            </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancelar</v-btn>
        <v-btn color="primary" @click="submit()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MODEL from '~/models/certicar/solicitudes'
import RULES from '~/models/form.rules'
import customVModelMixin from '~/util/custom.vmodel.mixin'

export default {
  name: 'AlcanceProductoDialog',
  mixins: [customVModelMixin],
  props: {
    title: { type: String, default: '' },
    itemSelected: { type: Object, default: null },
    parentSites: { type: Array, default: [] },
    firstChildrenSite: { type: Object, default: [] },
  },
  components: {},
  data: () => ({
    MODEL,
    RULES,
    item: {},
    data: {},
    isNew: false,
  }),
  watch: {
    value(val) {
      if (val === true) {
        this.item = this.itemSelected
        if (this.item && this.item.parent_uuid) {
          const parents = this.parentSites.filter((parent) => {
            return parent.uuid === this.item.parent_uuid
          })
          this.item.parent = parents.length > 0 ? parents[0] : {}
        }
      } else {
        this.item = {}
      }
      this.formatData()
    },
  },
  computed: {
    submitEventName() {
      return this.isNew ? 'create' : 'update'
    },
  },
  methods: {
    formatData() {
      if (this.item == null) {
        this.data = {}
        this.item = { parent: {} }
        this.isNew = true
        return
      }

      if (this.item && this.item.site_data) {
        for (const property of this.item.site_data) {
          this.data[property.keyname] = property.value
        }
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      let payload = {
        uuid: this.item.uuid,
        nombre: this.item.nombre,
        data: [],
      }

      if (this.isNew) {
        payload = Object.assign({}, this.firstChildrenSite)
        payload.add_planification = true
        payload.data = []
        payload.uuid = this.$uuid.v4()
        payload.nombre = this.item.nombre
        payload.parent = this.item.parent.uuid
        payload.source_iteracion_uuid = payload.uuid
        payload.source_parent_uuid = this.item.parent.source_iteracion_uuid //todo: buscar de db el parente_uuid del site marcado como parent.
      }

      for (const item in this.data) {
        payload.data.push({
          key: item,
          value: this.data[item],
        })
      }

      this.$emit(this.submitEventName, payload)
      this.$emit('close')
    },
    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
  },
}
</script>
