<template>
  <v-row>
    <v-col cols="6" class="mx-auto pa-8 text-center">
      <v-icon style="font-size: 80px; color: #d2d5db" class="my-4">
        mdi-cloud-sync
      </v-icon>
      <h2>Cargando datos</h2>
      <p>Por favor, espera mientras cargamos la información.<br>
      En función de los datos disponibles, esto puede tardar unos segundos</p>
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    value: Boolean,
    text: { type: String, default: 'Cargando datos...' },
    overlay: { type: Boolean, default: false },
  },
  data(ctx) {
    return {
      dialog: ctx.value,
    }
  },
  watch: {
    value: function (val) {
      this.dialog = val
    },
  },
  methods: {
    close() {
      this.dialog = false
      this.$emit('input', null)
    },
  },
}
</script>
