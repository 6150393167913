/**
 * @project: certiapp-nuxt
 * @file:    \store\autoevaluacion.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 24th August 2022
 * Last Modified: Tue Mar 28 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Created on Tue Sep 06 2022
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.put(`cuestionarios`, {
      empresa: params.empresa,
    })

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // get()
  // Created on Tue Sep 06 2022
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true

    const xhr = await this.$axios.get(`cuestionarios/${uuid}`)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // create()
  // Created on Tue Sep 06 2022
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    await this.$axios.post(`cuestionarios`, {
      uuid: params.uuid || uuid.v4(),
      ...params,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Tue Sep 06 2022
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    payload.cuestionario = params.uuid

    const xhr = await this.$axios.patch(`cuestionarios/${params.uuid}`, payload)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Tue Sep 06 2022
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`cuestionarios/${item.uuid}`)
    rootState.loading = false
  },
}
