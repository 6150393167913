<template>
    <v-dialog v-model="value" persistent max-width="700px">
        <v-card>
          <v-card-title>
              <span v-if="title">Alcance de producto</span>
          </v-card-title>
        <v-card-text>
            <v-form ref="form" lazy-validation>
            <v-card-text>
                <v-row>
                  <v-col cols="6">
                      <v-text-field required :rules="RULES.REQUIRED" persistent-hint v-model="item.nombre" label="Nombre de actividad"></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                        label="Emplazamiento"
                        v-model="item.parent"
                        :items="parentSites"
                        item-text="nombre"
                        item-value="uuid"
                        :rules="RULES.REQUIRED"
                        return-object
                        required
                    ></v-select>
                  </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Realizadas"
                    v-model="data.actividades_realizadas"
                    required
                    multiple
                    dense
                    smallChips
                  />
                </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Subcontratadas"
                    v-model="data.actividades_subcontratadas"
                    :disabled="!isResponsable"
                    multiple
                    dense
                    smallChips
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Certificación"
                    v-model="data.certificacion"
                    :items="MODEL.CERTIFICACION_TIPOS"
                    item-text="text"
                    item-value="value"
                    :rules="RULES.REQUIRED"
                    :required="data.actividades_subcontratadas && data.actividades_subcontratadas.length"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Marcas amparadas"
                    v-model="data.marcas"
                    :rules="RULES.REQUIRED"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" class="title black--text">
                  Productos
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Producto"
                    v-model="data.producto"
                    :items="MODEL.PRODUCTO_TIPOS"
                    :rules="RULES.REQUIRED"
                    item-value="text"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Alimentación"
                    v-model="data.alimentacion"
                    :items="MODEL.ALIMENTACION_TIPOS"
                    :rules="RULES.REQUIRED"
                    item-value="text"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Racial"
                    v-model="data.racial"
                    :items="MODEL.RACIAL_TIPOS"
                    :rules="RULES.REQUIRED"
                    item-value="text"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Estado"
                    v-model="data.estado"
                    :items="MODEL.ESTADOS_TIPOS"
                    :rules="RULES.REQUIRED"
                    item-value="text"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Presentación"
                    v-model="data.presentacion"
                    :items="MODEL.PRESENTACION_TIPOS"
                    :rules="RULES.REQUIRED"
                    item-value="text"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>
                </v-row>
            </v-card-text>
            </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancelar</v-btn>
        <v-btn color="primary" @click="submit()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectInstalaciones from '~/components/forms/SelectInstalaciones'
import SelectActividades from '~/components/forms/SelectActividades'
import MODEL from '~/models/araporcei/solicitudes'
import RULES from '~/models/form.rules'
import ROLES from '~/models/role'
import customVModelMixin from '~/util/custom.vmodel.mixin'

export default {
  name: 'AlcanceProductoDialog',
  mixins: [customVModelMixin],
  props: {
    title: {type: String, default: ''},
    itemSelected: {type: Object, default: null},
    parentSites: {type: Array, default: []},
    firstChildrenSite: {type: Object, default: null},
  },
  components: {
    SelectInstalaciones,
    SelectActividades,
  },
  data: () => ({
    MODEL,
    RULES,
    ROLES,
    item: {},
    data: {
      certificacion: null,
      marcas: null,
      producto: null,
      alimentacion: null,
      racial: null,
      estado: null,
      presentacion: null,
    },
    isResponsable: false,
    isNew: true,
  }),
  watch: {
    value(val) {
      if (val === true) {
        this.item = this.itemSelected
        if (this.item && this.item.parent_uuid) {
          const parents = this.parentSites.filter((parent) => {
            return parent.uuid === this.item.parent_uuid
          })
          this.item.parent = parents.length > 0 ? parents[0] : {}
        }

        this.getAuditoria()
      } else {
        this.item = {}
        this.data = {}
      }
      this.formatData()
    },
  },
  computed: {
    submitEventName() {
      return this.isNew ? 'create' : 'update'
    },
  },
  methods: {
    async getAuditoria() {
      let uuid = this.$route.params.uuid
      this.auditoria = await this.$store.dispatch('auditorias/get', uuid)
      console.log(this.auditoria)
      this.checkIfResponsable()
    },

    checkIfResponsable() {
      // Extraido de pages\auditorias\_uuid.vue
      if (this.auditoria && this.auditoria.responsable) {
        const isResponsable = this.$auth.user.uuid === this.auditoria.responsable.uuid
        const isRoot = this.$auth.user.roles.includes(ROLES.ROLE_ROOT)
        const isAuditorAdmin = this.$auth.user.roles.includes(ROLES.ROLE_AUDITOR_ADMIN)
        console.log(
          'responsabilidad',
          isResponsable,
          this.$auth.user.uuid,
          this.auditoria.responsable.uuid
        )
        console.log('responsabilidad', this.$auth.user.roles.includes(ROLES.ROLE_ROOT))
        console.log('responsabilidad', this.$auth.user.roles.includes(ROLES.ROLE_AUDITOR_ADMIN))

        this.isResponsable = isResponsable || isRoot || isAuditorAdmin
      }
    },
    formatData() {
      if (this.item == null || Object.keys(this.item).length == 0) {
        this.data = {}
        this.item = {parent: {}}
        this.isNew = true
        return
      }

      this.data = {...this.item}
      if (typeof this.data.certificacion == 'object') {
        this.data.certificacion = this.data.certificacion[0]
      }
      // for (const key of this.data) {
      //   this.data[key] = this.item[key] ? this.item.key : null
      // }

      // if (this.item && this.item.site_data) {
      //   for (const property of this.item.site_data) {
      //     if (this.data[property.keyname]) this.data[property.keyname] = property.value
      //   }
      // }

      this.isNew = false
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      let payload = {
        uuid: this.item.uuid,
        nombre: this.item.nombre,
        data: [],
      }

      if (this.isNew) {
        payload = Object.assign({}, this.firstChildrenSite)
        payload.data = []
        payload.uuid = this.$uuid.v4()
        payload.nombre = this.item.nombre
        payload.parent = this.item.parent.uuid
        payload.source_iteracion_uuid = payload.uuid
        payload.source_parent_uuid = this.item.parent.source_iteracion_uuid //todo: buscar de db el parente_uuid del site marcado como parent.
      }

      payload.data = this.cleanSiteData()

      this.$emit(this.submitEventName, payload)
      this.$emit('close')
    },

    //+-------------------------------------------------
    // cleanSiteData()
    // Removes attributes from an array before sending to api
    // -----
    // Created on Fri Sep 17 2021
    //+-------------------------------------------------
    cleanSiteData() {
      let data = []
      let avoid = [
        'nombre',
        'data',
        'site_data',
        'instalacion',
        'metadata',
        'certificado',
        'planificable',
        'subtipo',
        'source_parent_uuid',
        'source_iteracion_uuid',
        'expediente_uuid',
        'uuid',
        'parent_uuid',
        'parent',
        'iteracion_uuid',
        'tipo',
      ]

      for (const key in this.data) {
        if (avoid.indexOf(key) > -1) continue

        data.push({
          key: key,
          value: this.data[key],
        })
      }
      return data
    },

    resetForm() {
      this.item = {}
      this.isNew = true
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
  },
}
</script>
