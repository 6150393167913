/**
 * @project: certiapp-nuxt
 * @file:    \models\ccl\solicitudes.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Fri May 27 2022
 **/

export default {
  CERTIFICACION_TIPOS: [
    {
      text: 'Producción de productos ecológicos excepto durante el periodo de conversión',
      value: 'ecologicos_no_conversion',
    },
    { text: 'Producción de productos en conversión', value: 'conversion' },
    { text: 'Producción ecológica con producción no ecológica', value: 'mixto' },
  ],

  CERTIFICACION_TIPO: {
    ecologicos: 'Producción de productos ecológicos excepto durante el periodo de conversión',
    conversion: 'Producción de productos en conversión',
    mixto: 'Producción ecológica con producción no ecológica',
  },

  SOLICITUD_TIPOS: [
    { text: 'Inicio de Actividad', value: 'inicial' },
    { text: 'Modificación de la Solicitud Inicial', value: 'modificacion' },
  ],

  SOLICITUD_TIPO: {
    inicial: 'Inicio de Actividad',
    modificacion: 'Modificación de la Solicitud Inicial',
  },

  //+-------------------------------------------------
  // NOTE on Fri May 27 2022
  // This tipos of operadores have been updated
  // As per request in https://trello.com/c/OLYzW1Hy
  //+-------------------------------------------------

  OPERADORES_TIPOS: [
    { text: 'Producción agraria', value: 'PV' },
    { text: 'Preparación', value: 'PRE' },
    { text: 'Distribución', value: 'DIST' },
    { text: 'Almacenamiento', value: 'ALM' },
    { text: 'Importación', value: 'IMP' },
    { text: 'Exportación', value: 'EXP' },
    { text: 'Comercializador', value: 'COM' },
    { text: 'Sector vitivinícola Vino', value: 'VINO' },
  ],

  OPERADORES_TIPO: {
    productor: 'Producción agraria',
    // elaborador_transformador: 'Elaborador – Transformador',
    // mayorista: 'Comercializador – Mayorista',
    // importador: 'Importador',
    // exportador: 'Exportador',
    // minorista: 'Comercializador - Minorista',
  },

  CALF_TIPOS: ['A1', 'RC', 'ECO'],

  PROPIEDAD_TIPOS: ['propio', 'arrendado'],

  VOLUMEN_PRODUCCION_TIPOS: ['0 ≤ 75', '<75 ≤ 500', '>500'],
  // VOLUMEN_PRODUCCION_TIPO: {
  //   '0_75': '0 ≤ 75',
  //   '75_500': '<75 ≤ 500',
  //   '500': '>500',
  // },

  ACTIVIDAD_ASOCIADA_TIPOS: [
    'Importador 3º paises',
    'Elaborador',
    'Comercializador',
    'Exportación',
  ],
  ACTIVIDAD_ASOCIADA_TIPO: {
    importador: 'Importador 3º paises',
    elaborador: 'Elaborador',
    comercializador: 'Comercializador',
    exportacion: 'Exportación',
  },

  PRODUCCION_MIXTA_TIPOS: ['ecologica_pe>50', 'mixta_pe>50', 'ecologica_pe<50'],
  PRODUCCION_MIXTA_TIPO: {
    'ecologica_pe>50': 'Industria 100% Ecológica PE supone más del 50%  de la producción',
    'mixta_pe>50': 'Industria Mixta - PE supone más del 50%  de la producción',
    'ecologica_pe<50': 'Industria 100% Ecológica PE supone menos del 50% de la producción',
  },

  NOTIFICACIONES_TIPOS: ['ampliacion', 'cambio_datos', 'certificacion', 'cambio_organismo'],
  NOTIFICACIONES_TIPO: {
    ampliacion: 'Ampliación de la actividad de producción.',
    cambio_datos:
      'Cambio en los datos del solicitante (ej: cambio de titularidad, ampliación de instalaciones, ...)',
    certificacion:
      'Solicito la certificación en Producción Ecológica por primera vez: nunca he estado certificado en Producción Ecológica con otro organismo de control.',
    cambio_organismo:
      'Estoy certificado en Producción Ecológica y solicito el cambio de Organismo de Control. Manifiesto mi compromiso de facilitar toda la información' +
      ' que sea necesaria para que se puedan consultar mis antecedentes y aporto documento de baja con el anterior Organismo de Control.',
  },

  PROCESO_PRODUCTIVO_FASES: [
    { text: 'Almacenamiento', value: 'almacenamiento' },
    { text: 'Elaboración', value: 'elaboracion' },
    { text: 'Envejecimiento', value: 'envejecimiento' },
    { text: 'Embotellado', value: 'embotellado' },
    { text: 'Etiquetado', value: 'etiquetado' },
  ],

  SISTEMAS_APROVISIONAMIENTO: [
    {
      text: 'Realización de vendimia para la mención que solicita la certificación',
      value: 'vendimia',
    },
    { text: 'Descalificación de producto procedente de una IGP o DOP', value: 'descalificacion' },
    {
      text: 'Compra de producto a otros operadores dentro de su Comunidad Autonóma',
      value: 'compra_ca',
    },
    {
      text: 'Compra de producto a otros operadores ubicados fuera de su Comunidad Autonóma',
      value: 'compra_fuera_ca',
    },
  ],

  TIPOS_VINO: [
    { text: 'Blanco', value: 'blanco' },
    { text: 'Rosado', value: 'rosado' },
    { text: 'Tinto', value: 'tinto' },
    { text: 'Vinos de licor', value: 'licor' },
    { text: 'Vinos de uva sobremadura', value: 'uva_sobremadura' },
    { text: 'Vinos espumoso', value: 'espumoso' },
    { text: 'Vino de aguja', value: 'aguja' },
  ],

  VARIEDADES_UVA_BLANCA_PRINCIPALES: [
    { text: 'Albillo Mayor', value: 'albillo_mayor' },
    { text: 'Albillo Real', value: 'albillo_real' },
    { text: 'Viura (sin. Macabeo)', value: 'viura_sin_macabeo' },
    { text: 'Verdejo', value: 'verdejo' },
  ],

  VARIEDADES_UVA_BLANCA_SECUNDARIAS: [
    { text: 'Rojal (sin.: Malvasía Riojana, Alarije)', value: 'rojal_sin_malvasiariojana_alarije' },
    { text: 'Albarín Blanco', value: 'albarin_blanco' },
    { text: 'Albariño', value: 'albarino' },
    { text: 'Chardonnay', value: 'chardonnay' },
    { text: 'Garnacha Blanca', value: 'garnacha_blanca' },
    {
      text: 'Malvasía Castellana (sin. Doña Blanca)',
      value: 'malvasia_castellana_sin_doña_blanca',
    },
    { text: 'Gewürztraminer', value: 'gewurztraminer' },
    { text: 'Godello', value: 'godello' },
    { text: 'Hondarrabi Zuri', value: 'hondarrabi_zuri' },
    { text: 'Maturana Blanca', value: 'maturana_blanca' },
    { text: 'Montúa (sin. Chelva)', value: 'montua_sin_chelva' },
    { text: 'Moscatel de Alejandría', value: 'moscatel_de_alejandria' },
    { text: 'Moscatel de Grano Menudo', value: 'moscatel_de_grano_menudo' },
    { text: 'Palomino', value: 'palomino' },
    { text: 'Rabigato (sin. Puesta en Cruz)', value: 'rabigato_sin_puesta_en_cruz' },
    { text: 'Rufete Serrano Blanco', value: 'rufete_serrano_blanco' },
    { text: 'Sauvignon Blanc', value: 'sauvignon_blanc' },
    { text: 'Tempranillo Blanco', value: 'tempranillo_blanco' },
    { text: 'Treixadura y Viognier', value: 'treixadura_y_viognier' },
  ],

  VARIEDADES_UVA_TINTA_PRINCIPALES: [
    { text: 'Garnacha Tinta', value: 'garnacha_tinta' },
    { text: 'Juan García (sin. Mouraton)', value: 'juan_garcia_sin_mouraton' },
    { text: 'Mencía', value: 'mencia' },
    { text: 'Prieto Picudo', value: 'prieto_picudo' },
    {
      text: 'Tempranillo (sin.: Tinto Fino, Tinta del País, Tinta de Toro)',
      value: 'tempranillo_sin_tintofino_tintadelpais_tintadetoro',
    },
  ],

  VARIEDADES_UVA_TINTA_SECUNDARIAS: [
    { text: 'Bruñal (sin. Albarín Tinto)', value: 'brunal_sin_albarin_tinto' },
    { text: 'Cabernet Sauvignon', value: 'cabernet_sauvignon' },
    { text: 'Estaladiña', value: 'estaladina' },
    { text: 'Gajo Arroba', value: 'gajo_arroba' },
    { text: 'Garnacha Roja (sin. Garnacha Gris)', value: 'garnacha_roja_sin_garnacha_gris' },
    { text: 'Garnacha Tintorera', value: 'garnacha_tintorera' },
    { text: 'Graciano', value: 'graciano' },
    { text: 'Hondarrabi Beltza', value: 'hondarrabi_beltza' },
    { text: 'Mandón (sin. Garro)', value: 'mandon_sin_garro' },
    { text: 'Maturana Tinta', value: 'maturana_tinta' },
    {
      text: 'Merenzao (sin. Bastardillo Chico, Negro Saurí)',
      value: 'merenzao_sin_bastardillochico_negrosauri',
    },
    { text: 'Merlot', value: 'merlot' },
    { text: 'Petit Verdot', value: 'petit_verdot' },
    { text: 'Pinot Noir', value: 'pinot_noir' },
    { text: 'Rufete', value: 'rufete' },
    { text: 'Syrah', value: 'syrah' },
    { text: 'Tinto Jeromo', value: 'tinto_jeromo' },
    { text: 'Touriga Nacional', value: 'touriga_nacional' },
  ],
  TIPOS_INSTALACION: {
    propia: 'propia',
    alquilada: 'alquilada',
    subcontratada: 'subcontratada',
    maquila: 'maquila',
  },
}
