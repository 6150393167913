/**
 * @project: certiapp-nuxt
 * @file:    \store\direcciones.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 21st July 2023
 * Last Modified: Mon Jul 24 2023
 **/

import { uuid } from 'vue-uuid'
import axios from 'axios'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // user()
  // Created on Mon Jul 24 2023
  //+-------------------------------------------------
  async user({ rootState }, params) {
    console.log('store', params)
    rootState.loading = true
    let res = null
    let URL = `direcciones?user.uuid=${params.uuid}`
    if(params.tipo) URL += `&tipo=${params.tipo}`
  
    res = await this.$axios.get(URL)
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async contact({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.get(`direcciones?contacto.uuid=${params.uuid}`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async empresa({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.get(`direcciones?company.uuid=${params.uuid}`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  //+-------------------------------------------------
  // create()
  // Requests a new share URL based on modulo and modulo_ref
  // This URL may already exists
  // -----
  // Created on Tue Sep 21 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    
    rootState.loading = true
    if(params.tramites) {
      params.tramites = [params.tramites]
      //params.tramites.map((item) => item['@id'])
    }
    if(!params.principal) params.principal = false
    if(params.tipo_instalacions)params.tiposInstalacion = params.tipo_instalacions

    const xhr = await this.$axios.post(`direcciones`, {
      ...params,
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // show(uuid)
  // -----
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`direcciones/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },



  //+-------------------------------------------------
  // update()
  // -----
  // Created on Wed Sep 22 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    console.log('params',params)
    rootState.loading = true
    if(params.user) params.user = params.user['@id']
    if (params.contacto) params.contacto = params.contacto['@id']
    if (params.company) params.company = params.company['@id']
    params.tramites = [params.tramites]
    //params.tramites.map((item) => item['@id'])
    if(params.tipo_instalacions)params.tiposInstalacion = params.tipo_instalacions

    await this.$axios.patch(`direcciones/${params.uuid}`, {
      elemento_compartido: params.uuid,
      ...params,
    },{
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
   async delete({rootState}, item) {
     rootState.loading = true
     await this.$axios.delete(`direcciones/${item.uuid}`)
     rootState.loading = false
   },
}
