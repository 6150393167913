<template>
  <div v-if="isNotGroup">

    <span>
      <v-icon small class="mr-7" :disabled="!CAN_EDIT" @click.stop="controlClickClone()">mdi-content-copy</v-icon>
    </span>

    <span>
      <v-icon style="font-size: 20px" color="primary" :disabled="!CAN_DELETE"
        @click.stop="controlClickDelete()">mdi-delete-outline</v-icon>
    </span>

    <confirmation v-model="confirmClone" :text="'¿Clonar el objeto seleccionado?'" textButton="Clonar"
      colorButton="primary" @confirm="cloneItem()" />

    <confirmation v-model="confirmDelete" :text="params.text || '¿Eliminar el objeto seleccionado?'" colorButton="error"
      @confirm="deleteItem()" />

  </div>
</template>
 
<script>
import { f } from 'dropzone'
import Confirmation from "~/components/Confirmation"

export default {
  components: {
    Confirmation
  },
  data: function () {
    return {
      confirmDelete: false,
      confirmClone: false,
      auth: {},
    }
  },
  computed: {
    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_EDIT() {
      return this.IS_LIST ? this.auth.canEdit : false
    },
    CAN_DELETE() {
      return this.IS_LIST ? this.auth.canDelete : false
    },
    isNotGroup() {
      return !!this.params.data
    },
  },
  methods: {
    controlClickClone() {
      if (this.params.dispatchClone) this.confirmClone = true
      else this.cloneItem()
    },
    controlClickDelete() {
      if (this.params.dispatchDelete) this.confirmDelete = true
      else this.deleteItem()
    },
    async cloneItem() {
      this.confirmClone = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      if (this.params.dispatchClone && this.params.node.data.uuid) {
        let clon = await this.$store.dispatch(`${this.params.dispatchClone}`, this.params.node.data)
        // Añadimos la row con el clon al ag-grid y refrescamos
        if (clon) {
          let gridOptions = this.$parent.gridOptions
          let gridApi = this.$parent.gridOptions.api
          gridOptions.rowData.push(clon)
          gridApi.refreshCells()
        }
      }
    },
    async deleteItem() {
      this.confirmDelete = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      if (this.params.dispatchDelete && this.params.node.data.uuid) await this.$store.dispatch(`${this.params.dispatchDelete}`, this.params.node.data)
      // Eliminamos la row del ag-grid y refrescamos
      let indexRow = gridOptions.rowData.indexOf(this.params.node.data)
      gridOptions.rowData.splice(indexRow, 1)
      gridApi.refreshCells()
    },
    async init() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'listas-de-validacion',
      })
      console.log('PERMISOS', this.auth)
    }
  },

  mounted() {
    this.init()
  }
}
</script>
