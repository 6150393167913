<template>

  <div>
    <table style="width: 100%; margin-bottom: 70px;">
        <tr>
            <th style="width: 30%; text-align: left; padding: 0 5%;">
                <img src="https://sinergis.es/wp-content/uploads/2017/10/SINERGIS-logo-positivo175.png"
                    style="max-height: 100px;">
            </th>

            <th style="width: 70%; text-align: right;">
                <img :src="datos.cliente.avatar" alt=""
                    style="max-height: 100px;">
            </th>
        </tr>
        <tr>
            <th style="width: 30%; text-align: left; padding: 0 5%;">
            </th>

            <th class="informe-header-data" style="width: 70%">
                <strong>INFORME AUDITORÍA INTERNA</strong>
                <span>{{ datos.checklists[0].titulo }}</span>
                <span>{{ datos.cliente.nombre_contacto_fiscal }}</span>
                <span style="text-transform: capitalize">
                    {{ datos.cliente.direccion }} - {{ datos.cliente.cp }}<br>
                    {{ datos.cliente.provincia.nombre }}, {{ datos.cliente.pais.nombre }}
                </span>
            </th>
        </tr>
    </table>

    <div class="v-data-table v-data-table--dense">

        <table class="informe-body" style="width: 100%;">
            <tr>
                <td class="informe-field">
                    <h2 class="block-header">Datos de la auditoría</h2>
                </td>
                <td class="informe-data"></td>
            </tr>

            <tr>
                <td class="informe-field">
                    Fecha
                </td>

                <td class="informe-data">
                    {{ formatDate(datos.desde) }}
                </td>
            </tr>

            <tr>
                <td class="informe-field">
                    Tipo de Auditoría
                </td>

                <td class="informe-data">
                   {{ datos.tipo_visita }}
                </td>
            </tr>

            <tr>
                <td class="informe-field">
                    Procesos auditados
                </td>

                <td class="informe-data">
                  {{ datos.nivel }}
                </td>
            </tr>

            <tr>
                <td class="informe-field">
                   Alcance
                </td>

                <td class="informe-data">
                    {{ datos.alcance.nombre }}
                </td>
            </tr>

            <tr>
                <td class="informe-field">
                    Auditor
                </td>

                <td class="informe-data" style="text-transform: capitalize">
                    {{ datos.auditores[0].nombre_completo }}
                </td>
            </tr>

            <!-- <tr
          v-for="item in desserts"
          :key="item.name"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.calories }}</td>
        </tr> -->
        </table>

        <table class="informe-body" style="width: 100%">
            <tr>
                <td class="informe-field">
                    <h2 class="block-header">Check List</h2>
                </td>

                <td class="informe-data"></td>
            </tr>
            <tr>
                <td class="informe-field">
                </td>

                <td class="informe-data">
                    <ul>
                        <li v-for="(item, index) in datos.checklists[0].preguntas" :key="index">
                            <div v-if="item.informe == true">
                                {{ item.codigo }}.{{ item.titulo }} ({{ item.respuesta }})

                                <div style="line-height: 20px; color: #9e9e9e;">
                                    <strong>Observación</strong>
                                    <div v-html="item.observaciones || '<p>Sin observaciones</p>'"></div>

                                    <strong v-if="item.respuesta == 'F'">No conformidad</strong>
                                    <div v-if="item.respuesta == 'F'" v-html="item.no_conformidad || '<p>---</p>'"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </td>
            </tr>
        </table>

        <div class="page-break-before" style="padding-bottom: 70px;"></div>

        <table v-if="datos !== null && datos.revisiones.length > 0" class="informe-body" style="width: 100%">
            <tr>
                <td class="informe-field">
                    <h2 class="block-header">Conclusiones</h2>
                </td>
                <td class="informe-data"></td>
            </tr>

            <tr>
                <td class="informe-field">
                    Proposición
                </td>

                <td class="informe-data">
                    {{ datos.revisiones[0].proposicion }}
                </td>
            </tr>

            <tr>
                <td class="informe-field">
                    Observaciones
                </td>

                <td class="informe-data">
                    {{ datos.revisiones[0].observaciones_auditor }}
                </td>
            </tr>
        </table>

        <table v-else class="informe-body" style="width: 100%">
            <tr>
                <td class="informe-field">
                    <h2 class="block-header">Conclusiones</h2>
                </td>

                <td class="informe-data" style="font-style: italic;">
                    La auditoría no ha sido revisada aún
                </td>
            </tr>
        </table>

        <table v-if="dictamen !== null" class="informe-body" style="width: 100%">
            <tr>
                <td class="informe-field">
                    <h2 class="block-header">Resultado</h2>
                </td>
                <td class="informe-data"></td>
            </tr>

            <tr>
                <td class="informe-field">
                    Decisión
                </td>

                <td class="informe-data">
                    {{ dictamen.decision }}
                </td>
            </tr>

            <tr>
                <td class="informe-field">
                    Estado
                </td>

                <td class="informe-data">
                    {{ dictamen.estado }}
                </td>
            </tr>

            <tr>
                <td class="informe-field">
                    Motivo
                </td>

                <td class="informe-data">
                    {{ dictamen.motivo }}
                </td>
            </tr>
        </table>

        <table v-else class="informe-body" style="width: 100%">
            <tr>
                <td class="informe-field">
                    <h2 class="block-header">Resultado</h2>
                </td>

                <td class="informe-data" style="font-style: italic;">
                    El resultado de la auditoría no está disponible.
                </td>
            </tr>
        </table>

    </div>
  </div>

</template>

<script>
  export default {
    props: {
      datos: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
        dictamen: null,
    }),

    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : ''
      },

        //+-------------------------------------------------
        // getDictamenes()
        // Retrieves dictamenes from vuex store
        // -----
        // Created on Wed Feb 03 2021
        //+-------------------------------------------------
        async getDictamenes () {
            let dictamenes = await this.$store.dispatch('dictamenes/list',
                this.datos.revisiones[0].dictamen.uuid)

            this.dictamen = dictamenes[0];
            // if (res && res.length > 0) this.tecnico = res[0]
            console.info("🔔",this.dictamen);
        },

        init(){
            this.getDictamenes();
        }
    },

    mounted() {
        console.info("📋 datos", this.datos);
        this.init();
    },
  }
</script>

<style scoped>

@import "./informe.scss";

</style>
