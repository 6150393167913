/**
 * @project: certiapp-nuxt
 * @file:    \store\coplaca.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon May 15 2023
 **/

export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async productores({ rootState }, cliente) {
    rootState.loading = true
    const res = await this.$axios.put('portal/coplaca/productores', cliente)
    rootState.loading = false
    if (res) return res.data
  },

  // 25/02/2022
  // Cleanup, no se utiliza ni funciona en back
  async parcelas({ rootState }, cliente) {
    rootState.loading = true
    const res = await this.$axios.put(`portal/coplaca/parcelas`, cliente)
    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // clienteParcelas()
  // Returns all parcelas for all the productores in the selected clients
  // clients is an array of uuids
  // -----
  // Created on Mon Feb 28 2022
  //+-------------------------------------------------
  async clienteParcelas({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.put(`portal/coplaca/clienteParcelas`, params)

    rootState.loading = false
    if (res) return res.data
  },

  async productorParcelas({ rootState }, productor) {
    rootState.loading = true
    const res = await this.$axios.get(`portal/coplaca/productores/${productor.uuid}/parcelas`)
    rootState.loading = false
    if (res) return res.data
  },

  async createSolicitud({ rootState }, body) {
    rootState.loading = true
    await this.$axios.post(`portal/coplaca/solicitudes`, body)
    rootState.loading = false
    return body.uuid
  },
}
