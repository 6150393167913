<template>
  <div>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    helper.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 12th January 2022
 * Last Modified: Thu Jan 12 2023
 **/

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'offlineHelper',

  data: () => ({
    ui: {
      loading: false,
    },
  }),

  watch: {
    isOffline: function (val) {
      if (this.isOffline) {
        // this.close()
        // this.notifyAboutChanges()
      } else {
        // this.open({})
        // this.ui.snackbar = false
      }
    },

    isCaching: function (val) {
      this.$store.dispatch('offline/toggleAdapter', val)
    },
  },

  computed: {
    isOffline: function () {
      return this.$nuxt.isOffline
    },

    ...mapState({
      queue: (state) => state.offline.queue,
    }),

    ...mapGetters({
      queue: 'offline/getQueue',
      isCaching: 'offline/caching',
    }),
  },

  methods: {
    async init() {
      this.$store.dispatch('offline/initAxios')
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style>
</style>
