<template>
  <div v-if="isNotGroup" style="width: 100%;">
    <v-simple-table class="ml-12">
      <template v-slot:default>
        <tbody>
        <tr v-for="item in sites" :key="item.nombre">
          <td>{{ item.nombre }}</td>
          <td>
            <v-btn-toggle tile color="primary" v-model="item.option" group>
              <v-btn v-if="item.option === 'verdadero'" color="green" value="verdadero" style="color: white"> V</v-btn>
              <v-btn v-else value="verdadero"> V</v-btn>
              <v-btn v-if="item.option === 'falso'" color="red" value="falso" style="color: white"> F</v-btn>
              <v-btn v-else value="falso"> F</v-btn>
              <v-btn v-if="item.option === 'no_aplica'" color="grey" value="no_aplica" style="color: white"> NA</v-btn>
              <v-btn v-else value="no_aplica"> NA</v-btn>
            </v-btn-toggle>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn v-if="item.option === 'verdadero'" color="green" value="verdadero" style="color: white"> V</v-btn>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        item: {option: null},
        sites: {}
      };
    },
    beforeMount() {
    },
    mounted() {
      this.sites = this.params.data.sites;
    },
    computed: {
      isNotGroup () {
        return !!this.params.data
      },
    },
    methods: {
      // called when the cell is refreshed
      refresh() {
        return false;
      }
    }
  }
</script>
