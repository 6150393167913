import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({rootState, commit}, cliente) {
    rootState.loading = true
    const body = {}
    if (cliente) body.cliente = cliente
    const res = await this.$axios.put('eventos', body)
    let eventos = []
    if (res) {
      const data = res.data
      data.forEach(event => {
        event.name = event.titulo
        event.start = moment(event.desde).format('YYYY-MM-DD')
        event.end = moment(event.hasta).format('YYYY-MM-DD')
        event.color = 'primary'
        eventos.push(event)
      });
      commit('SET_LIST', eventos)
    }
    rootState.loading = false
    return eventos
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`eventos/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, evento) {
    rootState.loading = true
    evento.uuid = uuid.v4()
    const body = {
      titulo: evento.titulo,
      descripcion: evento.descripcion,
      uuid: evento.uuid,
      bloqueado: false,
      auditores: evento.auditores,
      desde: evento.desde,
      hasta: evento.hasta
    }
    await this.$axios.post('eventos', body)
    rootState.loading = false
  },

  async update({rootState}, evento) {
    rootState.loading = true
    const body = {
      titulo: evento.titulo,
      descripcion: evento.descripcion,
      evento: evento.uuid,
      bloqueado: false,
      auditores: evento.auditores,
      desde: evento.desde,
      hasta: evento.hasta
    }
    await this.$axios.patch(`eventos/${evento.uuid}`, body)
    rootState.loading = false
  },

  async delete({rootState}, evento) {
    rootState.loading = true
    await this.$axios.delete(`eventos/${evento.uuid}`)
    rootState.loading = false
  }

};
