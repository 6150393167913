<template>
  <div v-if="isNotGroup">
    <v-switch
      v-model="value"
      :label="params.label"
      :ripple="!readonly"
      class="mt-2"
      color="success"
      :readonly="readonly"
      hide-details
      :disabled="!readonly && !CAN_EDIT"
      @click.native.stop="controlClick()"
    ></v-switch>
  </div>
</template>

<script>
export default {
  data: () => ({
    value: false,
    readonly: true,
  }),
  computed: {
    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_EDIT() {
      return this.IS_LIST ? this.$store.state.permisos.can_edit : true
    },
    isNotGroup() {
      return !!this.params.data
    },
  },
  methods: {
    controlClick() {
      if (this.params.controlClick instanceof Function) {
        const params = {
          item: this.params.data,
          uuid: this.params.data.uuid,
          value: !this.value,
        }
        this.params.controlClick(params)
      }
    },
  },
  created() {
    this.value = this.params.value
    if (this.params.readonly === false) this.readonly = false
  },
}
</script>
