export default {
  
  TEXT: 'string',
  FLOAT: 'float',
  ENTERO: 'integer',
  EMAIL: 'email',
  DNI: 'dni',
  CIF: 'cif',
  CP: 'cp',
  TELEFONO: 'phone',
  SELECTOR: 'select',
  DATE: 'date',
  BOOLEANO: 'boolean',
  AUTOCOMPLETE: 'autocomplete',
  DROPDOWN: 'dropdown'
};
