<template>
  <v-autocomplete
    ref="alcance"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    v-model="selected"
    :search-input.sync="search"
    :placeholder="ui.loading ? 'Cargando...' : 'Escriba para buscar el alcance deseado'"
    @change="$emit('input', selected)"
    item-text="nombre"
    item-value="uuid"
    label="Alcance"
    :items="items"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectAlcances',
  props: {
    readonly: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    value: String,
  },
  mounted() {
    if (this.required) {
      this.rules = [(v) => !!v || 'Obligatorio']
    }
    this.getItems()
  },
  data(ctx) {
    return {
      search: null,
      rules: [],
      selected: ctx.value,
      items: [],
      ui: {
        loading: false,
      },
    }
  },
  watch: {
    value: function (val) {
      if (!val || val === '') this.$refs.alcance.reset()
      this.selected = val
    },
    search(val) {
      if (val && val.length >= 2) {
        this.getItems(val)
      }
    },
  },
  methods: {
    nombre: function (item) {
      // ? `${item.codigo} - ${item.nombre}`
      return item.codigo ? `${item.nombre}` : item.nombre
    },
    async getItems(nombre = null) {
      let res = null
      this.ui.loading = true
      if (nombre) {
        res = await this.$axios.get(`alcances?nombre=${nombre}`)
      } else {
        res = await this.$axios.get(`alcances`)
      }
      if (res?.data && res.data['hydra:member']) this.items = res.data['hydra:member']
    },
  },
}
</script>

<style scoped>
</style>
