<template>
  <div>
    <v-icon :style="dotStyle">mdi-circle</v-icon>
  </div>
</template>

<script>
export default {
  computed: {
    isGrouped() {
      return this.params.colDef && this.params.colDef.headerName === 'Group';
    },
    value() {
      return this.isGrouped ? this.params.node.groupData['ag-Grid-AutoColumn'] : this.params.value;
    },
    dotStyle() {
      return {
        color: this.value,
        fontSize: '24px', // Tamaño del dot
      };
    },
  },
  created() {
    // Asegúrate de que this.params está correctamente definido
    console.info(this.params);
  },
}
</script>
