<template>
    <div>
      <v-chip
        class="cursor-pointer"
        outlined
      >
        {{ bolean ? 'Si' : 'No'}}
      </v-chip>
    </div>
  </template>
  
  <script>

  
  export default {
    data: () => ({
      bolean: false,
    }),
    computed: {
      isGrouped () {
        return this.params.colDef.headerName === 'Group'
      },
    
      value() {
        return this.isGrouped ? this.params.node.groupData['ag-Grid-AutoColumn'] : this.params.value
      }
    },
    created () {
      // console.log('params', this.params)
      // console.info(this.params);
      this.bolean = this.params.value;
    }
  }
  </script>
  
  