/**
 * @project: certiapp-nuxt
 * @file:    store/metadata.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st June 2021
 * Last Modified: Wed Jun 09 2021
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns all families
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`metadata-familias`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getFamily()
  // Returns family data with subtipos
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async getFamily({ rootState }, uuid) {
    rootState.loading = true

    const res = await this.$axios.get(`metadata-familias/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getFamilyTipo()
  // Returns metadata items for a given family and tipo
  // -----
  // Created on Wed Jun 09 2021
  //+-------------------------------------------------
  async getFamilyTipo({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.put(`metadata`, {
      codigo_tipo: params.tipo,
      codigo_familia: params.familia,
    })

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getTipo()
  // Retuns metadata items for a given tipo
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  async getTipo({ rootState }, uuid) {
    rootState.loading = true

    const res = await this.$axios.put(`metadata`, {
      metadata_tipo: uuid,
    })

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getConfig()
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async getConfig({ rootState }, uuid) {
    rootState.loading = true

    const res = await this.$axios.get(`metadata-config/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // payload: uuid, nombre, descripción, datos, metadata_tipo
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    await this.$axios.post(`metadata`, {
      uuid: params.uuid || uuid.v4(),
      nombre: params.nombre,
      codigo: params.codigo,
      descripcion: params.descripcion || '',
      datos: params.datos,
      metadata_tipo: params.tipo,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true
    await this.$axios.patch(`metadata/${params.uuid}`, {
      metadata: params.uuid,
      nombre: params.nombre,
      codigo: params.codigo,
      descripcion: params.descripcion || '',
      datos: params.datos,
      metadata_tipo: params.tipo,
    })
    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`metadata/${item.uuid}`)
    rootState.loading = false
  },
}
