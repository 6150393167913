<template>
  <div v-if="isNotGroup && params.data && !params.data.solo_titulo">
    <v-btn-toggle tile borderless class="white--text" v-model="respuesta" v-if="print(params)">
      <v-btn :color="respuesta === 'V' ? 'success': ''" @click.stop="setRespuesta('V', respuesta)" value="V">V</v-btn>
      <v-btn :color="respuesta === 'F' ? 'error': ''" @click.stop="setRespuesta('F', respuesta)" value="F">F</v-btn>
      <v-btn :color="respuesta === 'NA' ? 'grey': ''" @click.stop="setRespuesta('NA', respuesta)" value="NA">NA</v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      respuesta: null,
      auditoria_uuid: null,
      solo_titulo: false,
    }
  },
  created() {
    if (this.params && this.params.value)
      this.respuesta = this.params.value.respuesta ? this.params.value.respuesta : this.params.value
    this.auditoria_uuid = this.$route.params.uuid
  },
  computed: {
    isNotGroup() {
      return !!this.params.data
    },
  },
  methods: {
    controlClick(new_value) {
      if (this.params.controlClick instanceof Function) {
        const params = {
          uuid: this.params.data.uuid,
          value: new_value,
        }
        this.params.controlClick(params)
      }
    },
    print(params) {
      if (params && params.value && params.value.solo_titulo) return false
      else if (params && params.value) return true
      else if (params && !params.node.group) return true
      else {
        // params.node.setExpanded(true)
        return false
      }
    },

    async setRespuesta(new_value, current) {
      if (new_value == current) new_value = null

      if (this.params.emitClick) {
        this.controlClick(new_value)
      } else {
        const params = {
          auditoria_uuid: this.auditoria_uuid,
          checklist_uuid: this.params.value.checklist_uuid,
          checklist_pregunta_uuid: this.params.value.uuid,
          // observaciones: pregunta.observaciones,
          // no_conformidad: pregunta.no_conformidad,
          respuesta: new_value,
        }
        await this.$store.dispatch('auditorias/editRespuesta', params)
      }
    },
  },
}
</script>
