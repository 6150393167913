<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formatDate(date)"
        readonly
        clearable
        v-on="on"
        @click:clear="date = '', menu = false, getValue(), params.api.stopEditing();"
      ></v-text-field>
    </template>
    <v-date-picker
      color="primary"
      locale="es-es"
      :first-day-of-week="1"
      v-model="date"
      @change="menu = false, getValue(), params.api.stopEditing();"
    ></v-date-picker>
  </v-menu>
</template>

<style scoped>
.v-text-field {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
</style>

<script>
import moment from 'moment'

export default {
  data: () => ({
    menu: false,
    date: null
  }),
  methods: {
    getValue () {
      return this.date
    },
    stopEditing () {
      setTimeout(() => {
        this.params.api.stopEditing()
      }, 50)
    },
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    }
  },
  created () {
    this.date = this.params.value
  }
}
</script>