<template>
  <div>
    <v-select
      :items="items"
      v-model="value"
      :placeholder="placeholder"
      @change="getValue(), params.api.stopEditing();"
    ></v-select>
  </div>
</template>

<script>
export default {
  data: () => ({
    value: null,
    placeholder: "Seleccionar",
  }),
  computed: {
    items() {
      const boolean = [
        { text: 'SI', value: 1 },
        { text: 'NO', value: 0 }
      ]
      const text = ['SI', 'NO']
      return this.params.text ? text : boolean
    }
  },
  methods: {
    getValue() {
      return this.value;
    }
  },
  created() {
    this.value = this.params.value;
    if (this.params.placeholder) this.placeholder = this.params.placeholder;
  }
};
</script>

<style scoped>
  .v-text-field {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
</style>