var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.selected.length > 0 && _vm.ui.step == 0)?_c('v-card',{staticClass:"mb-3",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('h3',{staticClass:"pl-3"},[_vm._v("\n          "+_vm._s(_vm.selected.length || 0)+"\n        ")])]),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Elementos seleccionados")]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("Continúa para crear un nuevo lote")])],1),_vm._v(" "),_c('v-list-item-action',[_c('v-btn',{attrs:{"block":"","small":"","depressed":"","color":"success"},on:{"click":_vm.nextStep}},[_vm._v("\n          Crear con la selección\n        ")])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.ui.step == 0)?[_c('v-card',[_c('v-data-table',{ref:"datatable",class:{'disabled': _vm.ui.loading},attrs:{"item-key":"uuid","show-select":"","items":_vm.rows,"headers":[
          { text: 'Estado', value: 'estado', align: 'left', cellClass: 'subData', width: 100, sortable: false },
          {
            text: 'Fecha creación',
            value: 'dates',
            cellClass: 'subData',
            width: 100,
            sortable: false,
          },
          { text: 'Alcance', value: 'nombre_alcance', cellClass: 'subData', width: 100, sortable: false},
          { text: 'Expediente', value: 'codigo_expediente', cellClass: 'subData', width: 100, sortable: false },
          { text: 'Cliente', value: 'client' },
        ],"items-per-page":50,"search":_vm.table.search,"loading":_vm.ui.loading},scopedSlots:_vm._u([{key:"item.client",fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm.getName(item))+"\n        ")]}},{key:"item.dates",fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm.$moment(item.created_at).format("DD [de] MMMM, YYYY"))+"\n        ")]}}],null,false,1467319569),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]:_vm._e(),_vm._v(" "),(_vm.ui.step == 1)?[_c(_vm.formComponent,{ref:"form",tag:"component",attrs:{"type":_vm.type,"_batch":_vm.batch,"_selected":_vm.selected,"is_revision":_vm.revision},on:{"lotes:reset":function($event){_vm.ui.step = 0; _vm.batch = []; _vm.selected = []}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }