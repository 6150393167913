/**
 * @project: certiapp-nuxt
 * @file:    \store\layout.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 26th January 2023
 * Last Modified: Wed Jul 12 2023
 **/

import { uuid } from 'vue-uuid'

//+-------------------------------------------------
// State
// Array of data held by the store
// Updated via mutations and accessible with getters
//+-------------------------------------------------
export const state = () => ({
  menu: [],
})

//+-------------------------------------------------
// Mutations
// The only way to actually change state in a Vuex
// store is by committing a mutation.
//+-------------------------------------------------
export const mutations = {
  setMenu(state, value) {
    state.menu = value
  },
}

//+-------------------------------------------------
// Getters
// Actions that to get modified state
// Getters receive the state as their 1st argument
// also receive other getters as the 2nd argument
// -----
// Accesed with $vm0.$store.getters['store/getter']
// https://vuex.vuejs.org/guide/getters.html#property-style-access
//+-------------------------------------------------
export const getters = {
  menu(state) {
    return state.menu
  },
}

export const actions = {
  //+-------------------------------------------------
  // menu()
  // -----
  // Created on Thu Jan 26 2023
  //+-------------------------------------------------
  async menu(ctx) {
    const jxr = await this.$axios.get(`menus`)
    ctx.commit('setMenu', jxr.data['hydra:member'])

    if (jxr) return jxr.data['hydra:member']
  },
}
