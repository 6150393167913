<template>
  <div v-if="isNotGroup">
    <span class="font-weight-bold body-1">{{ fullName }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    isNotGroup() {
      return !!this.params.data
    },
    fullName() {
      const item = this.params.data
      let nombre = item.nombre

      if (item.nombre_cliente) nombre = item.nombre_cliente

      if (item.apellido1) nombre += ' ' + item.apellido1
      if (item.apellido2) nombre += ' ' + item.apellido2
      if (item.primer_apellido) nombre += ' ' + item.primer_apellido
      if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido
      if (item.apellidos) nombre += ' ' + item.apellidos
      return nombre
    },
  },
}
</script>
