<template>
  <div>
    <template v-for="(no_conformidad, idx) in no_conformidades" >
      <div class="page-break-before" v-if="idx > 0" :key="no_conformidad.id"></div>

      <!-- Header -->
      <table class="no-border" style="width: 100%" :key="'#' + idx">
        <tbody>
          <tr class="no-border">
            <td  class="no-border" style="width: 33.33%; text-align: center">
              REGISTRO DE EVALUACION DE
              <br />
              IDENTIFICACION DEL PAC:
              <br />
              IB.-243/19_140619
            </td>
            <td class="no-border" style="width: 33.33%; text-align: center">
              <img src="https://www.kaam.es/images/logo.png" height="60px" width="60px">
              <h2 style="font-weight: 700" >kaam</h2>
            </td>
            <td class="no-border" style="width: 33.33%; text-align: center">
              REG_EV_PAC_C_ Rev. 5
              <br>
              <!-- Página 1 de 3 -->
              Rev.5_310519
            </td>
          </tr>
        </tbody>
      </table>

      <span v-if="idx == 0" :key="'t' + idx">
        <p>
          <br>
        </p>
        <p>
          <span style="font-weight: 400;">N&ordm; de informe: {{ datos.expediente.codigo }}</span>
          &nbsp;
          <span style="font-weight: 400;">N&ordm; PACs enviados: {{ datos.n_pacs }}</span>
        </p>
        <p>
          <span style="font-weight: 400;">Fecha de recepci&oacute;n:</span>
          &nbsp;
          <span style="font-weight: 400;">{{ formatDate(no_conformidad.accion_correctiva.created_at) }}</span>
          &nbsp;
          <span style="font-weight: 400;">Fecha de evaluaci&oacute;n:</span>
          &nbsp;
          <span style="font-weight: 400;">{{  }}</span>
          &nbsp;
          <span style="font-weight: 400;">&nbsp; </span>
          &nbsp;
          &nbsp;
          &nbsp;
          <span style="font-weight: 400;">Ha llegado en plazo:</span>
          &nbsp;
          <span style="font-weight: 400;">SI ☐ NO ☐</span>
        </p>
      </span>

      <p :key="'$' + idx">
        <br>
      </p>

      <table style="width: 100%; text-align: center" :key="idx">
        <tbody>
          <tr>
            <td style="width: 50%">
              <p>
                <span style="font-weight: 400;">Incumplimiento N&ordm;</span>
              </p>
            </td>
            <td style="width: 50%">
              <p>
                <span style="font-weight: 400;">{{ no_conformidad.codigo }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">Descripci&oacute;n&nbsp;</span>
              </p>
            </td>
            <td>
              <p>
                <span v-html="no_conformidad.no_conformidad"></span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">Descripci&oacute;n de la AC propuesta por el cliente&nbsp;</span>
              </p>
            </td>
            <td>
              <p>
                <span style="font-weight: 400;">{{ no_conformidad.accion_correctiva.accion_propuesta }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">Fecha de Implantaci&oacute;n</span>
              </p>
            </td>
            <td>
              <p>
                <span style="font-weight: 400;">{{ formatDate(no_conformidad.accion_correctiva.fecha_implantacion) }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">Evidencias adecuadas y suficientes para la verificaci&oacute;n de la implantaci&oacute;n de la AC&nbsp;</span>
              </p>
              <p>
                <span style="font-weight: 400;">&nbsp; ☐ SI ☐ NO</span>
              </p>
            </td>
            <td>
              <p>
                <span style="font-weight: 400;">{{ no_conformidad.accion_correctiva.evidencias_aportadas }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">La AC propuesta y las evidencias remitidas garantizan el cierre efectivo del incumplimiento <br>☐ SI ☐ NO</span>
              </p>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">Necesita verificaci&oacute;n in situ</span>
                <span style="font-weight: 400;">&nbsp; ☐ SI ☐ NO</span>
              </p>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">La no conformidad est&aacute; cerrada ☐ SI ☐ NO</span>
              </p>
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>
                <span style="font-weight: 400;">Se requiere un nuevo an&aacute;lisis del producto</span>
              </p>
              <p>
                <span style="font-weight: 400;">☐ SI ☐ NO ☐ NO APLICA</span>
              </p>
              <p>
                <span style="font-weight: 400;">Comentarios (si procede)</span>
              </p>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>

      <span v-if="idx === no_conformidades.length - 1" :key="'k' + idx">
        <p>
          <span style="font-weight: 400;">In</span>
          <strong>formaci&oacute;n a tener en cuenta para la toma de decisi&oacute;n </strong>
          <strong></strong>
          <strong></strong>
          <strong></strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style="font-weight: 400;">Existen NC abiertas</span>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <span style="font-weight: 400;">☐ SI </span>
          &nbsp;
          <span style="font-weight: 400;">Indicar cuales:</span>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <span style="font-weight: 400;">☐ NO</span>
        </p>
        <p>
          <span style="font-weight: 400;">El PAC (AACC y evidencias) garantiza el cierre efectivo de todas las desviaciones </span>
          &nbsp;
          <span style="font-weight: 400;">☐ SI </span>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <span style="font-weight: 400;">☐ NO</span>
        </p>
        <p>
          <span style="font-weight: 400;">Fecha en la que se considera que el PAC est&aacute; cerrado:&nbsp;</span>
        </p>
        <p>
          <span style="font-weight: 400;">Recomendaci&oacute;n del auditor. Dictamen. Favorable.</span>
        </p>
        <p>
          <span style="font-weight: 400;">Firma Auditor Jefe</span>
        </p>
      </span>

      <p :key="'i' + idx">
        <br>
      </p>

      <p :key="'l' + idx" style="font-size: 12px">
        La verificación in situ es una actividad de comprobación realizada dentro de la evaluación del PAC para evaluar si la acción correctora propuesta garantiza el cierre de una desviación. Esta actividad es decisión del auditor y debe quedar registrada en el PAC. El auditor puede utilizar la parte del cuestionario donde aparezca el requisito afectado por la NC para registrar el resultado de las comprobaciones realizadas pero no emitirá un informe, el resultado de eficacia de las acciones correctoras verificadas in situ se recogerá en el apartado correspondiente del PAC indicando la fecha.
      </p>
    </template>
  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }
  table, th, td {
    border: 0.5px solid black;
  }
  thead {
    display: table-row-group;
  }
  tr td {
    page-break-inside: avoid;
  }
  .no-border {
    border: none !important;
  }
</style>

<script>
export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: function() {
    return {
      ...this.datos
    }
  },
  methods: {
    formatDate (date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
  }
}
</script>
