<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="changesTable"
      :items-per-page="10"
      hide-default-footer
      class="elevation-0"
      :page.sync="page"
      @page-count="pageCount = $event"
    >
      <!-- Tipo -->
      <template v-slot:item.tipo="{ item }">
        <span class="text-capitalize">{{ item.tipo }}</span>
      </template>
      <!-- Value -->
      <template v-slot:item.value="{ item }">
        <span class="text-uppercase" v-if="item.value === 'baja' || item.value === 'eliminar'">{{ item.value }}</span>
        <span v-else>{{ checkValue(item) }}</span>
      </template>
      <!-- Nueva Iteracion -->
      <template v-slot:item.newIteration="{ item }">
        {{ checkBoolean(item.newIteration) }}
      </template>
    </v-data-table>
    <v-pagination color="info" v-model="page" :length="pageCount"></v-pagination>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    headers: [
      { text: "#", value: "row", sortable: true },
      { text: "Fichero", value: "tipo", sortable: true },
      { text: "Campo", value: "field", sortable: true },
      { text: "Acción", value: "action", sortable: true },
      { text: "Valor", value: "value", sortable: true },
      { text: "Nueva Iteración", value: "newIteration", sortable: true }
    ]
  }),
  computed: {
    changesTable () {
      return this.$store.state.expedientes.changesTable.reverse()
    },
    paises () {
      return this.$store.state.territorios.paises
    }
  },
  methods: {
    checkValue (item) {
      let value = item.value
      const type = item.type
      const codigo = item.codigo
      const field = item.field
      if (type === 'date') return this.formatDate(value)
      if (type === 'boolean') return this.checkBoolean(value)
      if (codigo === 'PAIS' && value) return value.nombre
      if (codigo === 'PROVINCIA' && value) return value.nombre
      if (codigo === 'LOCALIDAD' && value) return value.nombre
      if (field === 'Paises destinos') return this.paisesName(item.value)
      return value
    },
    paisesName (value) {
      const names = []
      for (let i=0; i < value.length; i++) {
        const uuid = value[i]
        this.paises.forEach(element => {
          if (element.uuid === uuid) names.push(element.nombre)
        })
      }
      return names.join(', ')
    },
    checkBoolean (value) {
      return value === 1 || value === true
        ? 'SI'
        : value === 0 || value === false
        ? 'NO'
        : value
    },
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
  }
}
</script>
