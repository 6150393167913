<template>
  <div>
    <v-chip
      v-if="value"
      class="cursor-pointer"
      :class="getColor + '--text'"
      :color="getColor"
      outlined
    >
      {{ value }}
    </v-chip>
  </div>
</template>

<script>
import ALCANCES from "~/models/alcances"
import COLORS from "~/models/colors"

export default {
  data: () => ({
    colors: COLORS
  }),
  computed: {
    isGrouped () {
      return this.params.colDef.headerName === 'Group'
    },
    getColor() {
      const data = this.params.data
      // console.info('getColor',data);
      const codigo = data && data.alcance && data.alcance.codigo
      const empresa = this.$auth.user.empresa?.codigo ?? '0'
      const alcances = ALCANCES[empresa]
      let color = ''
      if (codigo && alcances && alcances.length > 0) {
        for (let i=0; i < alcances.length; i++) {
          const element = alcances[i]
          if (codigo === element) color = this.colors[i]
        }
      }else{
        color = data.subalcance ? data.subalcance.color : data.color
      }
      return color
    },
    value() {
      return this.isGrouped ? this.params.node.groupData['ag-Grid-AutoColumn'] : this.params.value
    }
  },
  created () {
    // console.info(this.params);
  }
}
</script>

