<template>
  <div class="caption black--text">
    <!-- Header -->
    <table class="mb-4" style="width: 100%">
      <tbody>
        <tr class="text-center">
          <td class="py-4">
            <img src="https://coplaca.certiapp.com/coplaca/coplaca_logo.png" height="52px" width="100px">
          </td>
          <td class="body-1">
            INFORME DE AUDITORÍA
          </td>
          <td class="caption">
            <div class="font-weight-bold">PC-07: ASI</div>
            <div>Página 1 de 1</div>
            <div>Edición: 3ª</div>
            <div>Fecha: 07/07/2017</div>
          </td>
        </tr>
      </tbody>
    </table>
  
    <!-- Body -->
    <div class="text-capitalize">
      <span class="font-weight-bold">Productor:</span>
      {{ datos.productor }}
    </div>

    <div class="text-capitalize">
      <span class="font-weight-bold">Cliente:</span>
      {{ datos.cliente.nombre }}
    </div>

    <div class="text-capitalize">
      <span class="font-weight-bold">Fecha Auditoria:</span>
      {{ formatDate(datos.desde) + ' ~ ' + formatDate(datos.hasta) }}
    </div>

    <div class="text-capitalize">
      <span class="font-weight-bold">Auditor:</span>
      {{ datos.planificaciones && datos.planificaciones.length && datos.planificaciones[0].auditor }}
    </div>

    <div class="text-capitalize">
      <span class="font-weight-bold">Título de la lista:</span>
      {{ datos.planificaciones && datos.planificaciones.length && datos.planificaciones[0].titulo_lista }}
    </div>

    <div class="text-capitalize pb-4">
      <span class="font-weight-bold">Emplazamiento:</span>
      {{ datos.planificaciones && datos.planificaciones.length && datos.planificaciones[0].emplazamiento }}
    </div>

    <!-- No Conformidades -->
    <template v-if="datos.no_conformidades.length">
      <div class="text-capitalize">
        <span class="font-weight-bold body-1">NO CONFORMIDADES</span>
      </div>

      <v-row class="text-justify" justify="center" v-for="(no_conformidad, idx) in datos.no_conformidades" :key="no_conformidad.id">

        <v-col cols="12">
          <span class="font-weight-bold">{{ idx + 1 }}. No Conformidad - {{no_conformidad.codigo }}: </span>
          {{ no_conformidad.hint }}
        </v-col>

        <v-col cols="12">
          <div>
            <span class="font-weight-bold">Respuesta:</span>
            {{ no_conformidad.respuesta }}
          </div>
          <div>
            <span class="font-weight-bold">No Conformidad:</span>
            <br>
            <span v-html="no_conformidad.no_conformidad"></span>
          </div>
          <div>
            <span class="font-weight-bold">Observaciones:</span>
            <br>
            <span v-html="no_conformidad.observaciones"></span>
          </div>
        </v-col>

        <v-col cols="12">
          <table style="width: 100%">
            <thead>
              <tr>
                <td colspan="4" class="pa-2 text-center font-weight-bold">Acción Correctiva</td>
              </tr>
              <tr class="text-center">
                <td class="pa-2"></td>
                <td class="pa-2 caption font-weight-bold">Causa</td>
                <td class="pa-2 caption font-weight-bold">Acción</td>
                <td class="pa-2 caption font-weight-bold">Evidencias</td>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-if="no_conformidad.accion_correctiva">
                <td class="pa-2 caption font-weight-bold">Valoración</td>
                <td class="pa-2 caption">{{ no_conformidad.accion_correctiva.valoracion_causa }}</td>
                <td class="pa-2 caption">{{ no_conformidad.accion_correctiva.valoracion_accion }}</td>
                <td class="pa-2 caption">{{ no_conformidad.accion_correctiva.valoracion_evidencias }}</td>
              </tr>
              <tr v-if="no_conformidad.accion_correctiva">
                <td class="pa-2 caption text-center font-weight-bold">Texto</td>
                <td class="pa-2 caption">{{ no_conformidad.accion_correctiva.causa }}</td>
                <td class="pa-2 caption">{{ no_conformidad.accion_correctiva.accion_propuesta }}</td>
                <td class="pa-2 caption">{{ no_conformidad.accion_correctiva.evidencias_aportadas }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>

      </v-row>
    </template>

    <!-- No Conformidades Pendientes -->
    <template v-if="no_conformidades_pendientes.length">
      <div class="text-capitalize">
        <span class="font-weight-bold body-1">NO CONFORMIDADES PENDIENTES</span>
      </div>

      <v-row class="text-justify" justify="center" v-for="(no_conformidad_pendiente, idx) in no_conformidades_pendientes" :key="no_conformidad_pendiente.id">

        <v-col cols="12">
          <span class="font-weight-bold">{{ idx + 1 }}. No Conformidad Pendiente - {{ no_conformidad_pendiente.codigo }}:</span>
          {{ no_conformidad_pendiente.titulo }}
        </v-col>

        <v-col cols="12">
          <div>
            <span class="font-weight-bold">Respuesta:</span>
            {{ no_conformidad_pendiente.respuesta }}
          </div>
          <div>
            <span class="font-weight-bold">No Conformidad:</span>
            <br>
            <span v-html="no_conformidad_pendiente.no_conformidad"></span>
          </div>
          <div>
            <span class="font-weight-bold">Observaciones:</span>
            <br>
            <span v-html="no_conformidad_pendiente.observaciones"></span>
          </div>
        </v-col>

      </v-row>
    </template>

  </div>
</template>

<style scoped>
  table {
    border-collapse: collapse;
  }

  table, th, td {
    border: 0.5px solid black;
  }
</style>

<script>
import moment from 'moment'

export default {
  props: {
    datos: { type: Object, default: () => {} }
  },
  data: () => ({
    tecnico: {}
  }),
  computed: {
    no_conformidades () {
      return (this.datos && this.datos.planificacion && this.datos.planificacion.no_conformidades) || 1
    },
    no_conformidades_pendientes () {
      return (this.datos && this.datos.planificacion && this.datos.planificacion.no_conformidades_pendientes) || 1
    },
    solicitud_data () {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach(element => {
          data[element.keyname] = element.value
        })
        return data
      }
      else return {}
    },
    site_data () {
      if (this.datos) {
        if (this.datos.isCliente) return this.datos.isCliente
        else if (this.datos.sites) {
          let data = {}
          let tipos = []

          for (let i=0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
          }

          for (let i=0; i < tipos.length; i++) {
            const element = tipos[i]
            data[element] = []
          }

          for (let i=0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            let obj = {}
            obj.uuid = element.uuid
            for (let k=0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
            data[element.tipo].push(obj)
          }

          return data
        }
      }
      else return {}
    },
  },
  methods: {
    formatDate (date) {
      return this.$moment(date).format('DD/MM/YYYY')
    },
  }
}
</script>

