<template>
  <div>
    <template v-if="!editing">
      <div v-if="editorData" v-html="editorData"></div>
      <template v-else>
        <div v-for="(informe, idx) in datos" :key="informe.id" :class="{'page-break-before': idx > 0}">
          <component :id="'informe' + idx" :is="componente" :datos="informe"/>
        </div>
      </template>
    </template>
    <Editor
      v-else
      :api-key="tinyApiKey"
      :initialValue="editorData"
      :init="editorInit"
      @input="$emit('change', $event)"
    />
  </div>
</template>

<style scoped>
table.mainTable {
  width: 100%;
  table-layout: fixed;
}

* {
  font-size: 90%;
}
</style>

<script>
import Editor from '@tinymce/tinymce-vue'
import CoplacaSolicitudesProduccion_integradaInforme from './coplaca/solicitudes/produccion_integrada/informe'
import CoplacaSolicitudesGlobalgap1Informe from './coplaca/solicitudes/unegap/informe'

export default {
  props: {
    editing: {type: Boolean, default: false},
    editorData: {type: String, default: ''},
    datos: {type: Array, default: () => []},
  },
  components: {
    CoplacaSolicitudesProduccion_integradaInforme,
    CoplacaSolicitudesGlobalgap1Informe,
    Editor
  },
  data: () => ({
    editorInit: {
      height: '95vh',
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap',
        'searchreplace visualblocks code fullscreen',
        'print preview anchor insertdatetime media',
        'paste code help wordcount table pagebreak'
      ],
      toolbar:
          'undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright | \
          bullist numlist outdent indent | table pagebreak | help',
      pagebreak_separator: '<div class="page-break-before"></div>'
    }
  }),
  watch: {
    editing(val) {
      if (val && !this.editorData) this.loadEditorData()
    },
  },
  computed: {
    tinyApiKey() {
      return this.$store.state.tinyApiKey
    },
    loading() {
      return this.$store.getters.loading
    },
    plantillaQuery() {
      return this.$route.query.plantilla
    },
    empresa() {
      return this.$auth.user.empresa.codigo
    },
    modulo() {
      return this.$store.state.formatos.modulo
    },
    alcance() {
      return this.$store.state.formatos.alcance
    },
    componentes() {
      return this.$store.state.formatos.componentes
    },
    componente() {
      const empresa = this.$auth.user.empresa.codigo
      const modulo = this.modulo
      const alcance = this.alcance
      const codigo = this.alcance.codigo.replace('_', '-')
      // const componenteGeneral = `${this.empresa}-${this.modulo}-general-${this.plantillaQuery}`
      // return this.componentes.includes(componenteAlcance)
      //   ? componenteAlcance
      //   : this.componentes.includes(componenteGeneral)
      return `${this.empresa}-${this.modulo}-${codigo}-${this.plantillaQuery}`
    }
  },
  methods: {
    loadEditorData() {
      let editorData = ''
      for (let i = 0; i < this.datos.length; i++) {
        if (!this.editorData) {
          const informe = document.getElementById('informe' + i)
          editorData += informe.innerHTML
          if (this.datos.length > 1 && i < this.datos.length - 1) editorData += this.editorInit.pagebreak_separator
        }
      }
      this.$emit('change', editorData)
    }
  },
}
</script>
