/**
 * @project: certiapp-nuxt
 * @file:    plantillas-bloques.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st July 2021
 * Last Modified: Fri Dec 30 2022
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // get()
  // Created on Mon Jul 05 2021
  //+-------------------------------------------------
  async get({ rootState }, params) {
    rootState.loading = true
    //ref=${params.modulo_ref}
    //?codigo=${params.codigo}
    const res = await this.$axios.get(`bloques`, {
      modulo: params.modulo,
      modulo_ref: params.modulo_ref,
    })

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async listByModuloUuid({ rootState }, params) {
    rootState.loading = true
    if (params.auditoria) {
      const res = await this.$axios.get(`bloques?modulo=${params.modulo}&auditoria.uuid=${params.auditoria}`)

      rootState.loading = false
      if (res) return res.data['hydra:member']
    } else if(params.solicitud) {
      const res = await this.$axios.get(`bloques?modulo=${params.modulo}&solicitud.uuid=${params.solicitud}`)

      rootState.loading = false
      if (res) return res.data['hydra:member']
    } else if (params.checklist) {
      const res = await this.$axios.get(`bloques?modulo=${params.modulo}&checklist.uuid=${params.checklist}`)

      rootState.loading = false
      if (res) return res.data['hydra:member']
    }
  },

  //+-------------------------------------------------
  // create()
  // payload:
  // -----
  // Created on Mon Jul 05 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.post(`bloques`, params)
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Tue Jul 06 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    payload.bloque = payload.uuid

    const xhr = await this.$axios.patch(`bloques/${params.uuid}`, payload, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Wed Jul 07 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`bloques/${item.uuid}`)
    rootState.loading = false
  },
}
