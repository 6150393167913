import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({rootState, commit}) {
    rootState.loading = true
    const res = await this.$axios.get('afinidades')
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`afinidades/${uuid}`)
    rootState.loading = false
    if (res) {
      const data = res.data
      data.desde = moment(data.desde).format('YYYY-MM-DD')
      data.hasta = moment(data.hasta).format('YYYY-MM-DD')
      data.fecha_suspension = moment(data.fecha_suspension).format('YYYY-MM-DD')
      data.fecha_retirada = moment(data.fecha_retirada).format('YYYY-MM-DD')
      return data
    }
  },

  async create({rootState}, afinidad) {
    rootState.loading = true
    const body = {
      uuid: uuid.v4(),
      codigo: afinidad.codigo,
      descripcion: afinidad.descripcion,
      nivel_competencia: afinidad.nivel_competencia,
      auditor: afinidad.auditor.uuid,
      alcance: afinidad.alcance.uuid,
      desde: moment(afinidad.desde).format('YYYY-MM-DD HH:mm:ss'),
      hasta: moment(afinidad.hasta).format('YYYY-MM-DD HH:mm:ss'),
      fecha_suspension: moment(afinidad.fecha_suspension).format('YYYY-MM-DD HH:mm:ss'),
      fecha_retirada: moment(afinidad.fecha_retirada).format('YYYY-MM-DD HH:mm:ss'),
      isformador: afinidad.isformador,
      formador: afinidad.formador.uuid,
      formador_empresa_externa: afinidad.formador_empresa_externa
    }
    await this.$axios.post('afinidades', body)
    rootState.loading = false
  },

  async update({rootState}, afinidad) {
    console.info('afinidad', afinidad);
    rootState.loading = true
    const body = {
      afinidad: afinidad.uuid,
      codigo: afinidad.codigo,
      descripcion: afinidad.descripcion,
      nivel_competencia: afinidad.nivel_competencia,
      auditor: afinidad.auditor.uuid,
      alcance: afinidad.alcance.uuid,
      desde: moment(afinidad.desde).format('YYYY-MM-DD HH:mm:ss'),
      hasta: moment(afinidad.hasta).format('YYYY-MM-DD HH:mm:ss'),
      // fecha_suspension: moment(afinidad.fecha_suspension).format('YYYY-MM-DD HH:mm:ss'),
      // fecha_retirada: moment(afinidad.fecha_retirada).format('YYYY-MM-DD HH:mm:ss'),
      // isformador: afinidad.isformador,
      // formador: afinidad.formador.uuid,
      // formador_empresa_externa: afinidad.formador_empresa_externa
    };
    console.info('body', body);
    await this.$axios.patch(`afinidades/${afinidad.uuid}`, body)
    rootState.loading = false
  },

  async delete({rootState}, afinidad) {
    rootState.loading = true
    await this.$axios.delete(`afinidades/${afinidad.uuid}`)
    rootState.loading = false
  }

};
