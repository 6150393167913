<template>
  <div>
    <!-- Header -->
    <table style="width: 100%;padding:10px">
      <tbody>
      <tr>
        <td style="text-align:center;font-weight:700;font-size:30px;width:30%">
          <b>Informe de<br/>auditor&iacute;a</b>
        </td>
        <td style="text-align: center">
          <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px"><br/>
          <span style="font-weight: 700;font-size:40px">araporcei</span>
        </td>
        <td style="text-align:right;font-size:23px;width:30%">
          INF_A_ETG_SERR<br/>
          Rev.0 07.04.16<br/>
          Nº informe:
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Pagina 1 -->
    <table style="width:100%;margin-top:50px;padding:10px">
      <tbody>
      <tr style="text-align:center">
        <td style="text-align:left;width:25%">Tipo de Auditor&iacute;a:</td>
        <td style="width:13%"><input type="checkbox" v-if="this.datos.solicitud.params.tipo_solicitud=='seguimiento'" checked/><input type="checkbox" v-else/><br/> SEGUIMIENTO</td>
        <td style="width:12%"><input type="checkbox" v-if="this.datos.solicitud.params.tipo_solicitud=='inicial'" checked/><input type="checkbox" v-else/><br/> INICIAL</td>
        <td style="width:17%"><input type="checkbox" v-if="this.datos.solicitud.params.tipo_solicitud=='extraordinaria'" checked/><input type="checkbox" v-else/><br/> EXTRAORDINARIA</td>
        <td style="width:17%"><input type="checkbox"
                                     v-if="this.datos.solicitud.params.tipo_solicitud!=='inicial' && this.datos.solicitud.params.tipo_solicitud!=='seguimiento' && this.datos.solicitud.params.tipo_solicitud!=='extraordinaria' && this.datos.solicitud.params.tipo_solicitud!=='renovacion'"
                                     checked/><input type="checkbox" v-else/><br/> MODIF. ALCANCE
        </td>
        <td style="width:16%"><input type="checkbox" v-if="this.datos.solicitud.params.tipo_solicitud=='renovacion'" checked/><input type="checkbox" v-else/><br/> RENOVACION</td>
      </tr>

      <tr>
        <td>Norma base de la auditor&iacute;a</td>
        <td colspan="5">Pliego de Condiciones de la ETG Jamón Serrano según Reglamento (CE) nº 1204/2008 de la Comisión, relativo a la inscripción de determinadas denominaciones en el registro de ETG.s establecido en el
          Reglamento (CE) nº 509/2006.
        </td>
      </tr>

      <tr>
        <td>Fecha de Auditor&iacute;a</td>
        <td colspan="5">{{ formatDate(datos.desde) + ' - ' + formatDate(datos.hasta) }}</td>
      </tr>

      <tr>
        <td>Suministrador/ Cliente</td>
        <td colspan="5">{{ clienteNombreCompleto }}</td>
      </tr>

      <tr>
        <td>CIF</td>
        <td colspan="5">{{ datos.cliente && datos.cliente.contacto.nif }}</td>
      </tr>

      <tr>
        <td>Domicilio Social</td>
        <td colspan="5">{{ datos.cliente && datos.cliente.direccion }}</td>
      </tr>

      <tr>
        <td>Domicilio Industrial</td>
        <td colspan="2">{{ this.datos.cliente.instalaciones[0].direccion }}</td>
        <td style="text-align:center">Nº RGSA</td>
        <td colspan="2">{{ this.datos.cliente.instalaciones[0].rgseaa }}</td>
      </tr>

      <tr>
        <td>Indicar otros centros<br/>(si aplica)</td>
        <td colspan="5"></td>
      </tr>

      <tr>
        <td>Responsable Auditor&iacute;a</td>
        <td colspan="5">{{ datos.responsable.nombre + ' ' + datos.responsable.apellidos }}</td>
      </tr>

      <tr>
        <td>Auditor Jefe</td>
        <td colspan="5">{{ datos.responsable.nombre + ' ' + datos.responsable.apellidos }}</td>
      </tr>

      <tr>
        <td>Documentos que se adjuntan:</td>
        <td colspan="5">
          <input type="checkbox"/> Resumen de los resultados de la auditor&iacute;a<br/>
          <input type="checkbox"/> Listado de no conformidades
        </td>
      </tr>
      </tbody>
    </table>

    <table style="width:100%;margin-top:50px;border:none;padding:10px">
      <tbody>
      <tr>
        <td style="width:10%;border:none"></td>
        <td style="border:none;border-top:1px solid black;width:20%;vertical-align:top">Fecha</td>
        <td style="width:10%;border:none"></td>
        <td style="border:none;border-top:1px solid black;width:20%;vertical-align:top">Firma Auditor Jefe</td>
        <td style="width:10%;border:none"></td>
        <td style="border:none;border-top:1px solid black;width:20%">Firma del Responsable<br/>(Recib&iacute; copia)</td>
        <td style="width:10%;border:none"></td>
      </tr>
      </tbody>
    </table>

    <!-- Pagina 2 -->
    <div class="page-break-before"></div>

    <table style="width:100%">
      <thead>
      <tr>
        <th colspan="5" style="text-align:center;background-color:#eee">Alcance Evaluado</th>
      </tr>
      <tr>
        <th colspan="3" style="text-align:center;background-color:#eee">Alcance de Proceso</th>
        <th colspan="2" style="text-align:center;background-color:#eee">Alcance de Producto</th>
      </tr>
      <tr>
        <th rowspan="2" style="width:25%">Tipo de Operador</th>
        <th colspan="2" style="width:25%">Fases</th>
        <th style="width:25%">Presentaciones Comerciales</th>
        <th rowspan="2" style="width:25%">Marcas Comerciales</th>
      </tr>
      <tr>
        <th>Actividades realizadas</th>
        <th>Actividades subcontratadas</th>
        <td style="text-align:left">
          <input type="checkbox"/> Producto con etiqueta<br/>
          <input type="checkbox"/> Producto sin etiqueta
        </td>
      </tr>
      </thead>
      <tbody>
      <tr style="padding:10px">
        <td>
          <input v-if="isOperadorResposable" type="checkbox" checked/>
          <input v-else type="checkbox"/> Operador responsable de todas las fases<br/>

          <input type="checkbox"/> Por centro de Producción y para toda la producción del centro:<br/>
          <input type="checkbox"/> Por centro de Produccion y para _____ unidades/año
        </td>
        <td>
          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.actividades_realizadas == 'Elaboración') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Elaboraci&oacute;n<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.actividades_realizadas == 'Almacenamiento') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Almacenamiento<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.actividades_realizadas == 'Transformación') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Transformaci&oacute;n<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.actividades_realizadas == 'Comercialización') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Comercializaci&oacute;n
        </td>
        <td>
          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.actividades_subcontratadas == 'Elaboración') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Elaboraci&oacute;n<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.actividades_subcontratadas == 'Almacenamiento') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Almacenamiento<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.actividades_subcontratadas == 'Transformación') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Transformaci&oacute;n
        </td>
        <td>
          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('corte_v_con_pata')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Corte en v con pata<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('corte_v_sin_pata')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Corte en v sin pata<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('corte_redondo_sin_pata')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Corte redondo sin pata<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('corte_redondo_con_pata')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Corte redondo con pata<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('deshuesado_con_piel')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Deshuesado con piel<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('deshuesado_corte_v')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Deshuesado corte en v<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('deshuesado_sin_piel')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Deshuesado sin piel y desengrasado<br/>

          <input v-if="isOperadorResposable && getSiteData(this.datos.sites).find(x => x.nombre.includes('loncheado') || x.nombre.includes('porcionado')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Transformado (loncheado/ troceado)<br/>

          <input type="checkbox"/> Otros (especificar)
        </td>
        <td>
          <template v-if="isOperadorResposable">
            <p v-html="siteData.marcas" v-for="siteData in getSiteData(this.datos.sites).filter(x => x.marcas != undefined)" :key="siteData.id" style="font-weight: 400; text-align: center;">
            </p>
            <p v-html="datos.solicitud.params.alcance_proceso.marcas"></p>
          </template>
        </td>
      </tr>
      <tr style="padding:10px">
        <td>
          <input v-if="isOperadorPrestador" type="checkbox" checked/>
          <input v-else type="checkbox"/> Operador como prestador de servicios (a maquila):<br/>

          <input type="checkbox"/> Por centro de Producción y para toda la producción del centro:<br/>
          <input type="checkbox"/> Por centro de Produccion y para _____ unidades/año
        </td>
        <td colspan="2">
          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.actividades_realizadas == 'Elaboración') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Elaboraci&oacute;n<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.actividades_realizadas == 'Almacenamiento') != undefined" type="checkbox" checked/>
          <input type="checkbox"/> Almacenamiento<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.actividades_realizadas == 'Transformación') != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Transformaci&oacute;n<br/>
        </td>
        <td>
          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.nombre.includes('corte_v_con_pata')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Corte en v con pata<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.nombre.includes('corte_v_sin_pata')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Corte en v sin pata<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.nombre.includes('corte_redondo_sin_pata')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Corte redondo sin pata<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.nombre.includes('deshuesado_con_piel')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Deshuesado con piel<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.nombre.includes('deshuesado_corte_v')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Deshuesado corte en v<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.nombre.includes('deshuesado_sin_piel')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Deshuesado sin piel y desengrasado<br/>

          <input v-if="isOperadorPrestador && getSiteData(this.datos.sites).find(x => x.nombre.includes('loncheado') || x.nombre.includes('porcionado')) != undefined" type="checkbox" checked/>
          <input v-else type="checkbox"/> Transformado (loncheado/ troceado)<br/>

          <input type="checkbox"/> Otros (especificar)
        </td>
        <td></td>
      </tr>
      </tbody>
    </table>

    <!-- Pagina 3 -->
    <div class="page-break-before"></div>

    <p>1.- Actividades y Producto o Proceso incluido en el alcance, el Alcance de Certificacion está recogido en detalle en el Cuestionario de Auditoria y en el Programa de Auditoria.</p>

    <table style="width:100%;margin-bottom:50px">
      <thead>
      <tr style="background-color:#eee;text-align:center">
        <th style="width:35%">Actividades evaluadas</th>
        <th style="width:10%">S/N</th>
        <th style="width:35%">Centro</th>
        <th style="width:20%">Nº Piezas</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Elaboraci&oacute;n</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Comercializaci&oacute;n</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Transformaci&oacute;n<br/>(Deshuesado, Loncheado, fraccionado)</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>

    <p>2.- Realización de la auditoría</p>
    <p>Los resultados de la auditoría se evaluaron según los siguientes criterios de valoración:</p>

    <div style="width:80%;margin-left:auto;margin-right:auto;background-color:#eee;padding:20px 25px">
      <ul>
        <li>
          No conformidades graves:<br/>
          Serán No Conformidades de carácter grave los incumplimientos que afecten a los siguientes aspectos:
          <ul>
            <li>Peso mínimo de la materia prima,</li>
            <li>Periodo mínimo de curación del producto desde el inicio del salado,</li>
            <li>Merma mínima, del producto acabado.</li>
            <li>Características físico-químicas: humedad, salinidad y gradiente de humedad, del producto acabado.</li>
            <li>Características organolépticas del producto acabado.</li>
          </ul>
        </li>
        <li>
          No conformidades leves<br/>
          Serán No Conformidades de carácter leve el resto de los incumplimientos que afecten a los requisitos establecidos para esta certificación de producto:
        </li>
        <li>
          Observaciones:<br/>
          Serán Observaciones aquellos aspectos del sistema que a juicio del equipo auditor pueden dar lugar a No conformidades o en los que existe una evidente posibilidad de mejora. Una observación también puede
          incluir una solicitud realizada por ARAPORCEI a la empresa solicitante auditada para que tome alguna acción concreta
        </li>
      </ul>
    </div>

    <p>Las no conformidades se detallan en el anexo 2 del informe.</p>

    <!-- Pagina 4 -->
    <div class="page-break-before"></div>
    <h5 style="font-weight:400;text-align:center;border:1px solid black;background-color:#eee;width:100%">Resumen de auditor&iacute;a</h5>

    <div style="width:100%">
      <div style="width:33%;float:left">
        <div style="width:25%;float:left">
          <span style="writing-mode:vertical-lr;transform:rotate(180deg);">Si</span><br/>
        </div>
        <div style="width:25%;float:left">
          <span style="writing-mode:vertical-lr;transform:rotate(180deg);">No</span><br/>
        </div>
        <div style="width:25%;float:left">
          <span style="writing-mode:vertical-lr;transform:rotate(180deg);">No aplica</span><br/>
        </div>
        <div style="width:25%;float:left">
          <span style="writing-mode:vertical-lr;transform:rotate(180deg);">No verificado</span><br/>
        </div>
        <div style="clear:both"></div>
      </div>
      <div style="clear:both"></div>
    </div>

    <div style="width:100%">
      <div style="width:33%;float:left">
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="clear:both"></div>
      </div>
      <div style="width:67%;float:left">Se ha evaluado la efectividad de la implantación de las AC</div>
      <div style="clear:both"></div>
    </div>

    <div style="width:100%">
      <div style="width:33%;float:left"></div>
      <div style="width:67%;float:left">Las acciones correctoras no eficaces, dan lugar a:</div>
      <div style="clear:both"></div>
    </div>

    <div style="width:100%">
      <div style="width:33%;float:left">
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="clear:both"></div>
      </div>
      <div style="width:67%;float:left">El etiquetado del producto así como el uso de la marca araporcei es correcto</div>
      <div style="clear:both"></div>
    </div>

    <div style="width:100%">
      <div style="width:33%;float:left">
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="clear:both"></div>
      </div>
      <div style="width:67%;float:left">Se han verificado todos los requisitos que son de aplicación</div>
      <div style="clear:both"></div>
    </div>

    <div style="width:100%">
      <div style="width:33%;float:left">
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="width:25%;float:left">
          <input type="checkbox"/>
        </div>
        <div style="clear:both"></div>
      </div>
      <div style="width:67%;float:left">Se han verificado todas las actividades y fases que se incluyen en el alcance.</div>
      <div style="clear:both"></div>
    </div>


    <!-- Pagina 6 -->
    <div class="page-break-before"></div>
    <h5 style="font-weight:400;text-align:center;border:1px solid black;background-color:#eee;width:100%">Requisitos de la certificación</h5>

    <table style="width:100%; text-align:center;">
      <thead style="background-color:#eee">
      <tr>
        <th style="width:40%" rowspan="2">REQUISITO</th>
        <th rowspan="2" style="font-size:8px;width:7%">evaluación del<br/>requisito (*)</th>
        <th rowspan="2">C</th>
        <th rowspan="2">NC</th>
        <th colspan="2">Desviación</th>
        <th rowspan="2">Observación</th>
      </tr>
      <tr>
        <th>G</th>
        <th>L</th>
      </tr>
      </thead>
      <tbody v-for="checklist in datos.checklists" :key="checklist.uuid">
      <tr>
        <td colspan="9" style="background-color: #fddbc3;">{{ checklist.titulo }}</td>
      </tr>
      <tr v-for="pregunta in checklist.preguntas.filter(x => !x.solo_titulo && (x.informe == null || x.informe== true))" :key="pregunta.uuid">
        <td>{{ pregunta.titulo }}</td>
        <td></td>
        <td>{{ pregunta.respuesta == 'V' ? 'X' : '' }}</td>
        <td>{{ pregunta.respuesta == 'F' ? 'X' : '' }}</td>
        <td>{{ pregunta.peso != 'Menor' ? 'X' : '' }}</td>
        <td>{{ pregunta.peso == 'Menor' ? 'X' : '' }}</td>
        <td>
          <div v-html="pregunta.observaciones"></div>
        </td>
      </tr>
      </tbody>
    </table>
    <p>(*)Indicar si se ha realizado la evaluación del requisito.</p>

    <!-- Pagina 7 -->
    <div class="page-break-before"></div>
    <h5 style="font-weight:400;text-align:center;border:1px solid black;background-color:#eee;width:100%">Toma de muestras de producto acabado</h5>

    <p>Se realiza la correspondiente toma de muestras sobre producto acabado, aplicando la Tabla de <b>Toma de Muestras de producto acabado</b> (Documento MAPA 10-7-2000).</p>
    <p>El número de piezas objeto de certificación producidas al año por la empresa auditada es de, aproximadamente unas _________ piezas. En función de estos datos se realiza la toma de muestras de dos piezas de los
      lotes de producto acabado disponibles en el momento de la auditoria.</p>

    <p><b>1.<u>Resultados de los análisis físico-químicos:</u></b></p>

    <table style="width:100%">
      <thead style="background-color:#eee">
      <tr>
        <th>PRECINTO</th>
        <th>LOTE</th>
        <th>Par&aacute;metro</th>
        <th>Resultados</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="muestra in muestras" :key="muestra.uuid">
        <template v-if="muestra.tipo_muestra=='Físico químico'">
          <td>{{ muestra.precinto }}</td>
          <td>{{ muestra.lote }}</td>
          <td><span v-for="params in muestra.params" :key="params.matriz">{{ params.matriz }}<br></span></td>
          <td>{{ muestra.sustancias_detectadas }}</td>
        </template>
      </tr>
      </tbody>
    </table>

    <p>Se verifica analíticamente que <b>las X muestras</b> analizadas <b><u>cumplen/no cumplen</u></b> (consignar el resultado) las especificaciones físico-químicas establecidas en el Pliego de Condiciones de la ETG
      Jamón Serrano aprobado por el Reglamento CE 1204/2008, para los parámetros físico –químicos analizados.</p>

    <p><b>2.<u>Resultados de los análisis de las características organolépticas:</u></b></p>

    <table style="width:100%">
      <thead style="background-color:#eee">
      <tr>
        <th>PRECINTO</th>
        <th>LOTE</th>
        <th>Resultado</th>
      </tr>
      </thead>
      <tbody>


      <tr v-for="muestra in muestras" :key="muestra.uuid">
        <template v-if="muestra.tipo_muestra=='Organoléptico'">
          <td>{{ muestra.precinto }}</td>
          <td>{{ muestra.lote }}</td>
          <td><span v-for="params in muestra.params" :key="params.matriz">{{ params.matriz }}<br></span></td>
        </template>
      </tr>
      </tbody>
    </table>

    <p>Se verifica analíticamente que las muestras analizadas <b><u>cumplen/no cumplen</u></b> (consignar el resultado) las características organolépticas establecidas en el Pliego de Condiciones de la ETG Jamón Serrano
      aprobado por el Reglamento CE 1204/2008.</p>

    <p><b>Este informe es completado con los resultados de los análisis realizados al producto acabado; editándose la presente revisión 1 y manteniendo el mismo número de informe de auditoría.</b></p>

    <!-- Pagina 8 -->
    <div class="page-break-before"></div>
    <h5 style="font-weight:400;text-align:center;border:1px solid black;background-color:#eee;width:100%">Conclusiones y continuación del proceso de certificación</h5>

    <p>El presente informe corresponde a la auditoria inicial de certificación y recoge una descripción de las evaluaciones, comprobaciones y verificaciones realizadas y los incumplimientos detectados por el equipo
      auditor durante la evaluación.</p>
    <p>La realización de esta auditoría y los resultados encontrados son fruto de una investigación muestral, por lo que no se descarta la aparición de incumplimientos en posteriores auditorias.</p>
    <p>La auditoría se desarrolló según la programación establecida. El personal se mostró colaborador e interesado, lo que ha permitido llevar a cabo la auditoria de una forma eficaz.</p>
    <p>ARAPORCEI ha procedido a la evaluación de los aspectos referidos a la competencia técnica de la empresa para producir y comercializar el producto ETG “Jamón Serrano” y a la evaluación del sistema del autocontrol
      implantado.</p>
    <p>ARAPORCEI ha realizado la auditoria conforme a sus procedimientos de trabajo. Todos los requisitos definidos en los documentos normativos aplicables y de referencia han podido ser evaluados documentalmente y/ o in
      situ de forma adecuada con los procedimientos antes referidos, y las actividades que realiza la empresa auditada pueden ser suficientes para declarar la capacidad técnica para desarrollar las actividades referidas
      en la solicitud de alcance de la certificación de producto.</p>
    <p>Como continuación al proceso de certificación, la empresa deberá remitir a ARAPORCEI, SL en un plazo máximo de 1 mes desde la recepción del presente informe, la documentación que justifique que, para cada uno de
      los incumplimientos identificados, la empresa realiza los siguientes pasos:</p>
    <p>
    <ul>
      <li>Se ha realizado un análisis de las causas que motivaron la aparición de los incumplimientos y se han evaluado los posibles aspectos afectados por las no conformidades, desviaciones y/u observaciones.</li>
      <li>Se ha establecido una <u>acción correctiva y/o preventiva </u> para garantizar que se ha subsanado el incumplimiento o está en vía de solución y que no se repite el incumplimiento.</li>
      <li>Se ha definido un <u>plazo previsto para la resolución de los incumplimientos.</u></li>
      <li>Se <u>eval&uacute;a la eficacia de las acciones implantadas</u></li>
    </ul>
    </p>
    <p>La empresa deberá aportar evidencias que demuestren que ha realizado las acciones solicitadas anteriormente.</p>
    <p>Esta información será estudiada por el equipo auditor para determinar si las acciones propuestas y las evidencias remitidas aportan la suficiente confianza en que los incumplimientos han sido adecuadamente
      resueltos.</p>
    <p>La empresa puede alegar aquellos extremos del informe con los que no esté de acuerdo adjuntando toda la información que justifique su postura.</p>
    <p>El presente informe tiene un plazo de validez de 6 meses desde su emisión. Transcurrido dicho periodo ARAPORCEI se reserva el derecho de realizar una auditoria extraordinaria para comprobar el cumplimiento de los
      criterios de certificación.</p>

    <!-- Pagina 9 -->
    <template v-if="datos.no_conformidades.length">

      <div class="page-break-before"></div>
      <h5 style="font-weight:400;text-align:center;border:1px solid black;background-color:#eee;width:100%">Anexo 1.- Listado de incumplimientos</h5>

      <table style="width:100%">
        <thead style="background-color:#eee">
        <tr>
          <th style="width:15%" rowspan="2">Nº/Código</th>
          <th colspan="2">Tipo</th>
          <th style="width:60%" rowspan="2">DESCRIPCION</th>
          <th colspan="2" style="width:15%">Acci&oacute;n Correctora</th>
        </tr>
        <tr>
          <th>G</th>
          <th>L</th>
          <th>S</th>
          <th>N</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="no_conformidad in this.datos.no_conformidades" :key="">
          <td>{{ no_conformidad.codigo }}</td>
          <td v-if="no_conformidad.peso == 'Mayor'">G</td>
          <td v-else></td>
          <td v-if="no_conformidad.peso == 'Menor'">L</td>
          <td v-else></td>
          <td>{{ no_conformidad.titulo }}</td>
          <td v-if="Object.keys(no_conformidad.accion_correctiva).length !== 0">S</td>
          <td v-else></td>
          <td v-if="Object.keys(no_conformidad.accion_correctiva).length === 0">N</td>
          <td v-else></td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<style scoped>
* {
  padding: 4px;
}

table {
  border-collapse: collapse;
}

table, th, td {
  border: 0.5px solid black;
}

thead {
  display: table-row-group;
}

tr td {
  page-break-inside: avoid;
}
</style>

<script>
import MODELS from "~/models/araporcei/solicitudes"

export default {
  props: {
    datos: {
      type: Object, default: () => {
      }
    }
  },
  data: () => ({
    html: "",
    muestras: [],
    tipoOperador: MODELS.OPERADORES_TIPO,
    tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
    tipoProducto: MODELS.PRODUCTO_TIPO,
    tipoRacial: MODELS.RACIAL_TIPO,
    tipoEstado: MODELS.ESTADOS_TIPO,
    tipoPresentacion: MODELS.PRESENTACION_TIPO,
    tipoCertificacion: MODELS.CERTIFICACION_TIPO,
  }),
  mounted() {
    this.muestras = []
    console.info('auditoria', this.datos);
    this.datos.toma_muestras.forEach(element => {
      element.muestras.forEach(muestra => {
        //console.log("que tal",typeof(muestra.lote))

        if (typeof (muestra.lote) == 'string') {
          this.muestras.push(muestra)
        }
      });

      console.log('muestras', this.muestras);
    });
  },
  computed: {
    clienteNombreCompleto() {
      let nombre = ''
      const cliente = this.datos.cliente
      if (cliente) {
        if (cliente.nombre) nombre += cliente.nombre
        if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
        if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
      }
      return nombre
    },
    isOperadorResposable() {
      return this.datos.solicitud && this.datos.solicitud.params.alcance_proceso.tipo_operador.includes('responsable')
    },
    isOperadorPrestador() {
      return this.datos.solicitud && this.datos.solicitud.params.alcance_proceso.tipo_operador.includes('prestador')
    },
    alcance() {
      return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
    },
    solicitud_data() {
      if (this.datos) {
        let data = {}
        this.datos.grupo_solicitud_data.forEach(element => {
          data[element.keyname] = element.value
        })
        return data
      } else return {}
    },
    site_data() {
      if (this.datos.isCliente) return this.datos.isCliente
      else if (this.datos.sites) {
        let data = {}
        let tipos = []

        for (let i = 0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
        }

        for (let i = 0; i < tipos.length; i++) {
          const element = tipos[i]
          data[element] = []
        }

        for (let i = 0; i < this.datos.sites.length; i++) {
          const element = this.datos.sites[i]
          let obj = {}
          obj.uuid = element.uuid
          for (let k = 0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data[element.tipo].push(obj)
        }

        return data
      } else return {}
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format('DD/MM/YYYY') : ''
    },
    htmlinject(observ) {
      this.html = "";
      this.html = observ;
    },
    nconformidad(nc) {


      return Object.keys(nc).length === 0


    },
    getSiteData(sites) {
      const data = []
      if (sites) {
        for (let i = 0; i < sites.length; i++) {
          const element = sites[i]
          let obj = {}
          obj.uuid = element.uuid
          obj.nombre = element.nombre
          obj.tipo = element.tipo
          for (let k = 0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
          data.push(obj)
        }
      }
      return data
    },
    getAllMuestrasByTipo(tipo) {
      let muestras = []

      this.datos.toma_muestras.forEach(
          muestraGlobal => {
            if (muestraGlobal.muestras != null) {
              if (muestraGlobal.muestras.filter(x => x.tipo_muestra == tipo).length > 0) {
                muestraGlobal.muestras.filter(x => x.tipo_muestra == tipo).forEach(x => muestras.push(x));
              }
            }
          }
      );

      return muestras;
    }
  }
}
</script>

