import format from '~/util/formatHelper'

export const state = () => ({
});

//commit mutations
export const mutations = {
};


//dispatch actions
export const actions = {

  async list({ rootState }, params) {
    rootState.loading = true
    let queryParams = '';
    if(params) {
      const queryString = format.objectToUrlParams(params);
      queryParams = Object.keys(params).length > 0 ? `?${queryString}` : '';
    }

    const res = await this.$axios.get(`estaticos${queryParams}`)
    rootState.loading = false
    if (res && res.data) return res
  },

  async listMember({ rootState }, params) {
    rootState.loading = true
    let queryParams = '';
    if(params) {
      const queryString = format.objectToUrlParams(params);
      queryParams = Object.keys(params).length > 0 ? `?${queryString}` : '';
    }

    const res = await this.$axios.get(`estaticos${queryParams}`)
    rootState.loading = false
    if (res && res.data) return res.data['hydra:member']
  },

  async listPaginated({ rootState }, params) {
    rootState.loading = true
    let queryParams = '';
    if(params) {
      const queryString = format.objectToUrlParams(params);
      queryParams = Object.keys(params).length > 0 ? `?${queryString}` : '';
    }

    const res = await this.$axios.get(`estaticos${queryParams}`)
    rootState.loading = false
    if (res && res.data) return res
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`estaticos/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async getTipo({rootState}, tipo) {
    rootState.loading = true
    const res = await this.$axios.get(`estaticos?codigo=${tipo}`)
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async create({rootState}, item) {
    rootState.loading = true
    const res = await this.$axios.post(`estaticos`, item)
    rootState.loading = false
    if (res) return res.data
  },

  async update({rootState}, item) {
    rootState.loading = true
    const res = await this.$axios.patch(`estaticos/${item.estatico}`, item,{
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    if (res) return res.data
    rootState.loading = false
  },

  async delete({rootState}, item) {
    rootState.loading = true
    await this.$axios.delete(`estaticos/${item.uuid}`)
    rootState.loading = false
  },


  async getEstaticoNiveles({rootState}, codigoNombre) {
    rootState.loading = true

    const res = await this.$axios.get(`estaticos?page=1&itemsPerPage=100&codigo=${codigoNombre}`)
    rootState.loading = false
    if (res) return res.data['hydra:member']
  }
};
