<template>
  <div>

    <loading v-model="$store.getters.loading" text="Espere por favor ..."/>

    <!--    OVERLAY-->
    <v-overlay :value="overlay" class="text-center">
      <h4>Expediente desactivado
        <v-icon>mdi-alert-octagon</v-icon>
      </h4>
      <br>
      <v-btn color="success" @click="updateActivarDesactivar(true)">
        ACTIVAR EXPEDIENTE
      </v-btn>

    </v-overlay>

    <v-tabs v-model="tab" :color="currentColor" :slider-color="currentColor">
      <v-tab v-for="item in tabs" :key="item.id" :href="'#' + item">
        <span class="primary--text">{{ item }}</span>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-row align="center" v-show="tab !== 'comunicaciones'">

      <!-- Year -->
      <v-col cols="2" xl="1" class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              depressed
              v-on="on"
              class="mt-2 mb-2"
            >
              {{ iteracionYearSelected }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group mandatory v-model="iteracionYearSelected">
              <v-list-item v-for="year in iteracionYears" :key="year" :value="year" class="mt-2 mb-2" >
                <v-list-item-title>{{ year }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>

      <!-- Iteraciones -->
      <v-col cols="10" xl="11">
        <template v-for="year in iteracionYears">
          <v-slide-group show-arrows :key="year" v-if="year === iteracionYearSelected">
            <v-slide-item v-for="(iteracion,i) in item.iteraciones" :key="i" class="mr-5">
              <v-btn
                v-if="iteracionMatchYear(i, year)"
                v-model="iteracionSelected"
                :class="{'primary': iteracion.uuid === iteracionSelected.uuid}"
                small
                depressed
                rounded
                @click="updateDatosIteracion(iteracion)"
              >
                <v-icon small class="mr-2">mdi-calendar</v-icon>
                {{iteracion.codigo}}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </template>
      </v-col>

      <v-col cols="12 py-0 px-9" v-if="tab !== 'solicitud'">
        <v-checkbox class="pa-0 ma-0"
          label="Ver todas las iteraciones"
          @click="iteracionAll = !iteracionAll"
        ></v-checkbox>
      </v-col>

    </v-row>

    <v-divider></v-divider>

    <v-tabs-items v-model="tab">

      <v-tab-item :value="'solicitud'">
        <div v-show="tab === 'solicitud'">

          <v-container>
            <v-row no-gutters>

              <v-col cols="9">
                <v-row no-gutters>
                  <v-col cols="3">
                    <v-switch
                      color="success"
                      v-model="item.activo"
                      label="Activo"
                      @change="updateActivarDesactivar"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Estado</v-subheader>
                    <h4 v-if="item.estado" v-text="item.estado" style="float: left;" class="mr-1"></h4>
                    <v-icon color="green" small>mdi-circle</v-icon>
                  </v-col>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Código</v-subheader>
                    <v-text-field
                      v-model="item.codigo"
                      dense
                      outlined
                      hide-details
                      @change="updateCodigo()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Fecha dictamen</v-subheader>
                    <h4>{{ $moment(item.fecha_baja).format('DD/MM/YYYY') }}</h4>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Cliente</v-subheader>
                    <h4 v-if="item.cliente" v-text="item.cliente.nombre"></h4>
                  </v-col>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Alcance</v-subheader>
                    <h4 v-if="item.alcance">
                      {{ item.alcance.nombre }}
                    </h4>
                  </v-col>
                  <v-col cols="3" v-if="iteracionSelected.grupo_solicitud && iteracionSelected.grupo_solicitud.grupo_solicitudes_data.length > 0">
                    <v-subheader class="pl-0 info-expediente">Cultivo</v-subheader>
                    <h4 class="text-capitalize" v-text="iteracionSelected.grupo_solicitud.grupo_solicitudes_data[0].value"></h4>
                  </v-col>
                  <v-col cols="3" v-if="iteracionSelected.bloqueado_hasta">
                    <div>
                      <v-subheader class="pl-0 info-expediente">Auditoria bloqueada hasta</v-subheader>
                    </div>
                    <div>
                      <v-menu
                        close-on-content-click
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <!-- <h4 class="text-capitalize" v-text="formatDate(iteracionSelected.bloqueado_hasta)" v-on="on"></h4> -->
                          <v-text-field
                            :value="formatDate(iteracionSelected.bloqueado_hasta)"
                            readonly
                            dense
                            outlined
                            hide-details
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="iteracionSelected.bloqueado_hasta"
                          color="primary"
                          :first-day-of-week="1"
                          @change="updateFechaBloqueado()"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!-- General Actions -->
              <v-col cols="3">
                <v-row no-gutters justify="end">
                  <v-col cols="12" class="text-right">

                    <!-- Crear auditoria -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mr-2 white--text"
                          color="success"
                          fab
                          small
                          depressed
                          :disabled="existChanges"
                          @click="dialogVisitaAuditoria = true"
                          v-on="on"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Crear Auditoria</span>
                    </v-tooltip>

                    <!-- Discard -->
                    <v-tooltip bottom v-if="existChanges">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mr-2 white--text"
                          color="error"
                          fab
                          small
                          depressed
                          @click="getOne()"
                          v-on="on"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Descartar</span>
                    </v-tooltip>

                    <!-- Save -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="white--text"
                          color="secondary2"
                          fab
                          small
                          depressed
                          @click="saveChanges()"
                          v-on="on"
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <span>Guardar</span>
                    </v-tooltip>
                  </v-col>

                  <!-- Alerts -->
                  <v-expand-transition>
                    <v-col cols="12" class="pt-4 text-right" v-if="!existChanges && noChanges">
                      <span class="px-3 py-2 warning--text" style="background-color: #F7E5E6; border-radius: 4px">
                        <v-icon color="warning" class="mr-4 pb-1">mdi-alert</v-icon>
                        No existen cambios a guardar
                      </span>
                    </v-col>
                  </v-expand-transition>
                  <!-- -->
                  <v-expand-transition>
                    <v-col cols="12" class="pt-4 text-right" v-if="existChanges">
                      <span class="px-3 py-2 error--text" style="background-color: #F7E5E6; border-radius: 4px">
                        <v-icon color="error" class="mr-4 pb-1">mdi-alert</v-icon>
                        Existen cambios sin guardar
                      </span>
                    </v-col>
                  </v-expand-transition>
                </v-row>
              </v-col>

            </v-row>
          </v-container>

          <v-divider></v-divider>

          <expediente-sites
            height="40vh"
            :sites="iteracionSelected.sites"
            :editable="editable_headers"
            :actions="actions"
            :gridOptions="gridOptions"
            :multiple="false"
            @selection="updateSelection($event)"
            @action="executeRowAction($event)"
            @changesGrid="changesGrid = true"
            :refreshCells="component"/>

        </div>
      </v-tab-item>

      <v-tab-item :value="'auditorias'">
        <auditorias-listado :iteracion="iteracionAll ? {} : iteracionSelected" :expediente="item.uuid" v-if="tab === 'auditorias' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'revisiones'">
        <revisiones-listado :iteracion="iteracionAll ? {} : iteracionSelected" :uuid="item.uuid" v-if="tab === 'revisiones' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'dictamenes'">
        <dictamenes-listado :iteracion="iteracionAll ? {} : iteracionSelected" :uuid="item.uuid" v-if="tab === 'dictamenes' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'certificados'">
        <dictamenes-listado :iteracion="iteracionAll ? {} : iteracionSelected" :uuid="item.uuid" v-if="tab === 'certificados' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'comunicaciones'">
        <comunicaciones-externas-listado :expediente="item" :iteracionSelected="iteracionSelected" v-if="tab === 'comunicaciones' && item && iteracionSelected"/>
      </v-tab-item>

    </v-tabs-items>


    <!-- Actions Modal -->
    <actions-modal
      v-model="dialog"
      :item="action"
      :rowIndex="rowIndex"
      @close="closeAction()"
      @changes="actionChanges($event)"
      @discard="getOne()"
      @save="saveAction()"
    />

    <!-- Create Auditoria Dialog Modal -->
    <create-auditoria-modal
      v-model="dialogVisitaAuditoria"
      :cliente="item.cliente"
      :iteracion="iteracionSelected"
      :bloqueado_hasta="iteracionSelected.bloqueado_hasta"
      :alcance="item.alcance"
      :sites="iteracionSelected.sites"
      :gridOptions="gridOptions"
      @close="dialogVisitaAuditoria = false"
      @save-auditoria="saveAuditoria($event)"
    />

  </div>
</template>

<script>
import moment from 'moment'
import AgDatatableSelectable from '~/components/AgDatatableSelectable'
import Loading from '~/components/Loading'
import Confirmation from '~/components/Confirmation'
import COLORS from '~/models/colors'
// Forms
import SelectAlcance from '~/components/forms/SelectAlcances'
import SelectClientes from '~/components/forms/SelectClientes'
import SelectProvincia from '~/components/forms/SelectProvincia'
import FloatingButtonMenu from '~/components/FloatingButtonMenu'
//
import ExpedienteVariables from '~/components/customVariables/CustomVariables'
import ActionsModal from '~/components/expedientes/ActionsModal'
import CreateAuditoriaModal from '~/components/auditorias/CreateAuditoriaModal'
// Listados
import AuditoriasListado from '~/pages/auditorias'
import RevisionesListado from '~/pages/revisiones/index'
import DictamenesListado from '~/pages/dictamenes/index'
import CertificadosListado from '~/pages/certificados/index'
import ComunicacionesExternasListado from '~/pages/comunicaciones_externas/index'
// Actions Multiple
import Sites from '~/components/solicitudes/sites'
import ParcelasActionsMultiple from './ParcelasActionsMultiple'
import ExpedienteSites from '~/components/expedientes/sites'

export default {
  components: {
    ExpedienteSites,
    Sites,
    CreateAuditoriaModal,
    Confirmation,
    Loading,
    AgDatatableSelectable,
    SelectAlcance,
    SelectClientes,
    SelectProvincia,
    FloatingButtonMenu,
    ExpedienteVariables,
    ActionsModal,
    AuditoriasListado,
    RevisionesListado,
    DictamenesListado,
    CertificadosListado,
    ComunicacionesExternasListado,
  },
  data: () => ({
    component: 0,
    dialog: false,
    noChanges: false,
    changesGrid: false,
    colors: COLORS,
    grids: {},
    tabs: ['solicitud', 'auditorias', 'revisiones', 'dictamenes', 'certificados', 'comunicaciones'],
    action: {
      action: '',
      selection: [],
    },
    actions: [
      // { tipo: "productor", action: "ExpedienteCoplacaProductoresActionsCellRenderer" },
      // { tipo: "parcela", action: "ExpedienteCoplacaParcelasActionsCellRenderer", multiple: ParcelasActionsMultiple }
    ],
    editable_headers: [
      {tipo: 'productor', headers: ['nombre_productor', 'cambio_titular']},
      {tipo: 'parcela', headers: ['cod_provincia', 'cod_municipio', 'cambio_titular']},
    ],
    gridOptions: {
      productor: {
        getRowStyle: (params) => {
          if (params.data.cambio_titular) return {background: '#dfdfff'}
        },
      },
      parcela: {
        getRowStyle: (params) => {
          if (params.data.cambio_titular) return {background: '#dfdfff'}
        },
      },
    },
    //
    overlay: false,
    dialogVisitaAuditoria: false,
    rowIndex: [],
    globalgapActions: [
      {name: 'Baja', action: 'baja', tipo_fichero: ['CENTRALES', 'PRODUCTORES']},
      {name: 'Cambio superficie', action: 'cambio_superficie', tipo_fichero: ['PRODUCTOS']},
      {name: 'Cambio cultivo', action: 'cambio_cultivo', tipo_fichero: ['PRODUCTOS']},
      {name: 'Cambio GGN', action: 'cambio_ggn', tipo_fichero: ['PRODUCTORES']},
      {name: 'Cambio GLN', action: 'cambio_gln', tipo_fichero: ['PRODUCTORES']},
      {name: 'Cambio NIF', action: 'cambio_nif', tipo_fichero: ['PRODUCTORES']},
      {name: 'Eliminar', action: 'eliminar', tipo_fichero: ['PRODUCTORES', 'PRODUCTOS']},
    ],
    item: {iteraciones: [], grupo_alcance: {}},
    iteracionYears: [],
    iteracionYearSelected: {},
    iteracionSelected: {},
    iteracionAll: false,
  }),
  beforeMount() {
    this.frameworkComponents = {
      BooleanCellEditor: 'BooleanCellEditor',
      AutocompleteCellEditor: 'AutocompleteCellEditor',
      SelectCellEditor: 'SelectCellEditor',
      TextCellEditor: 'TextCellEditor',
      DateCellEditor: 'DateCellEditor',
    }
    this.excelStyles = [
      {
        id: 'default',
        dataType: 'string',
      },
    ]
  },
  mounted() {
    this.getOne()
  },
  watch: {
    tab(val) {
      if (val === 'solicitud') this.getOne()
    },
  },
  computed: {
    tab: {
      get() {
        return this.$store.state.expedientes.tab
      },
      set(val) {
        this.$store.commit('expedientes/SET_TAB', val)
      },
    },
    changesTable() {
      return this.$store.state.expedientes.changesTable
    },
    existChanges() {
      return this.changesTable.length > 0 || this.changesGrid
    },
    currentColor() {
      const idx = this.tabs.indexOf(this.tab)
      return idx ? this.colors[idx] : 'secondary2'
    },
  },
  methods: {
    updateSelection(selection) {
      this.action.selection = selection
    },
    async updateCodigo() {
      const body = {
        ...this.item,
      }
      await this.$store.dispatch(`expedientes/updateCodigo`, body)
    },
    async updateFechaBloqueado() {
      const body = {
        iteracion_uuid: this.iteracionSelected.uuid,
        expediente_uuid: this.item.uuid,
        bloqueado_hasta: this.iteracionSelected.bloqueado_hasta,
      }
      await this.$store.dispatch(`expedientes/updateFechaBloqueado`, body)
    },
    async updateActivarDesactivar(activate) {
      if (activate) {
        await this.$store.dispatch('expedientes/activar', this.item.uuid)
        this.item.activo = true
        this.overlay = false
      } else {
        const res = await this.$store.dispatch('expedientes/desactivar', this.item.uuid)
        if (res) {
          // this.item.activo = false;
          // this.overlay = true;
          this.$router.push('/expedientes')
          this.$store.commit('notification/show', {
            text: 'Expediente desactivado correctamente',
            color: 'success',
          })
        }
      }
    },
    async saveAuditoria(auditoria) {
      await this.$store.dispatch(`expedientes/createAuditoria`, {
        iteracion_uuid: this.iteracionSelected.uuid,
        expediente_uuid: this.item.uuid,
        auditoria: auditoria,
      })
      this.dialogVisitaAuditoria = false
      this.$store.commit('notification/show', {
        text: 'Auditoria creada correctamente',
        color: 'success',
      })
      this.getOne()
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    async executeRowAction(event) {
      if (event.selection && event.selection.length) {
        this.action = event
        this.dialog = true
      } else {
        this.noSelection = true
        setTimeout((this.noSelection = false), 10000)
      }
    },
    actionChanges(event) {
      const action = this.action.action
      const row = this.action.row
      const selection = this.action.selection
      const uuids = selection.map((element) => element.uuid)
      const field = this.action.field
      const tipo = this.action.tipo
      const newIteration = event.newIteration
      let newValue = event.newValue
      let changes = {
        table: [],
        changes: {},
      }
      let sites = this.iteracionSelected.sites
      for (let i = 0; i < selection.length; i++) {
        const element = selection[i]
        // Change value on grid
        if (action !== 'eliminar') {
          for (let k = 0; k < sites.length; k++) {
            const row = sites[k]
            if (row.uuid === element.uuid) {
              for (let n = 0; n < row.site_data.length; n++) {
                const keyname = row.site_data[n].keyname
                if (keyname === field) row.site_data[n].value = newValue
              }
            }
          }
        }
        this.iteracionSelected.sites = sites
        // Push changes
        changes.table.push({
          newIteration: newIteration,
          tipo: tipo,
          row: row[element.uuid],
          field: field,
          action: this.action.name,
          value: newValue,
        })
      }
      changes.changes = {
        newIteration: newIteration,
        tipo: tipo,
        iteracion: this.iteracionSelected.uuid,
        uuids: uuids,
        action: 'coplaca.' + tipo + '.' + action,
        value: newValue,
      }
      this.closeAction(changes)
    },
    closeAction(changes) {
      if (changes) {
        this.component++
        this.$store.commit('expedientes/updateChangesActions', changes.changes)
        this.$store.commit('expedientes/updateChangesTable', changes.table)
      }
      this.dialog = false
      this.action = {
        action: '',
        selection: [],
      }
      this.rowIndex = []
    },
    saveChanges() {
      if (this.changesTable.length > 0) {
        this.action = {
          action: 'save',
          selection: this.action.selection,
        }
        this.dialog = true
      } else {
        this.noChanges = true
        setTimeout(() => {
          this.noChanges = false
        }, 10000)
      }
    },
    async saveAction() {
      this.dialog = false
      const res = await this.$store.dispatch(
        'expedientes/saveChanges',
        this.$auth.user.empresa.codigo
      )
      if (res && !res.error)
        this.$store.commit('notification/show', {
          text: 'Expediente guardado correctamente',
          color: 'success',
          setTimeout: 3000,
        })
      this.getOne()
    },
    iteracionMatchYear(i, year) {
      return this.$moment(this.item.iteraciones[i].codigo, 'DD/MM/YYYY').format('YYYY') === year
    },
    async getOne() {
      this.submenuItem = 0
      this.changesGrid = false
      this.$store.commit('expedientes/resetQueueChanges')
      const res = await this.$store.dispatch('expedientes/expediente', this.$route.params.uuid)
      if (res) {
        this.item = res
        this.$store.commit('listas_validacion/SET_ALCANCE', this.item.alcance.uuid)
        if (this.item.activo === false) this.overlay = true
        let reverse = this.item.iteraciones.reverse()
        for (let i = 0; i < reverse.length; i++) {
          let date = this.$moment(reverse[i].codigo, 'DD/MM/YYYY').format('YYYY')
          if (!this.iteracionYears.includes(date)) this.iteracionYears.push(date)
        }
        this.iteracionYearSelected = this.$moment(reverse[0].codigo, 'DD/MM/YYYY').format('YYYY')
        this.item.iteraciones = reverse
        // Obtener Dropdowns y Tipos de Ficheros
        await this.$store.dispatch('dropdowns/list', {
          empresa: this.$auth.user.empresa.uuid,
          grupo_alcance: this.item.grupo_alcance.uuid,
        })
        let body = {
          grupo_alcance: this.item.grupo_alcance.uuid,
          empresa: this.$auth.user.empresa.uuid,
        }
        if (this.item.subalcance) body.subalcance = this.item.subalcance.uuid
        const tipos_ficheros = await this.$store.dispatch('tipos_fichero/list', body)
        if (tipos_ficheros) this.tipos_ficheros = tipos_ficheros

        this.updateDatosIteracion(this.item.iteraciones[0])
      }
      this.component++
    },
    async updateDatosIteracion(iteracion) {
      const res = await this.$store.dispatch('expedientes/iteracion', [
        this.item.uuid,
        iteracion.uuid,
      ])
      if (res) {
        const data = res
        if (!data.bloqueado_hasta)
          data.bloqueado_hasta = this.$moment(data.bloqueado_hasta).format('YYYY-MM-DD')
        this.iteracionSelected = data
      }
    },
    // getOptionsColumnSelectByDropdowns(codigo) {
    //   let options = []
    //   try {
    //     if (this.$store.state.dropdowns.list[codigo]) {
    //       this.$store.state.dropdowns.list[codigo].forEach(item => options.push(item.value))
    //       return options
    //     } else return options
    //   } catch (error) {
    //     console.log('[ERROR getOptionsColumnSelectByDropdowns]', error)
    //     return options
    //   }
    // },
    // updateFilter() {
    //   this.grid.api.setQuickFilter(this.search)
    //   this.grid.api.refreshCells()
    // },
  },
}
</script>

<style>
.toolbar-expediente-width-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.09) !important;
  z-index: 1;
  right: 0;
  top: 60px;
  left: 275px;
  position: fixed;
}

.ag-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.toolbar-expediente-no-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 0;
  top: 60px;
  position: fixed;
}

.info-expediente {
  height: 30px;
}
</style>
