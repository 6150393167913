<template>
  <div>
    <v-card>
      <!-- <pre>
        {{ preloaded }}
        --
        {{ currentComponent }}
        {{ preloading }}
      </pre> -->
      <!-- <v-btn @click="nextStepOrFinish">
        NEXT
      </v-btn> -->
      <v-row class="mx-0">
        <v-col cols="8"></v-col>
        <v-col cols="4">
          <v-text-field
            v-model="table.search"
            label="Buscar auditoría"
            placeholder="Nombre, email, dirección..."
            append-icon="mdi-magnify"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        ref="datatable"
        :items="rows"
        :headers="headers"
        :items-per-page="50"

        :search="table.search"
        :class="{'disabled': ui.loading}"
        none-loading="ui.caching">

        <template v-slot:item.status="{ item }">
          <template v-if="item.preloaded.done || item.cached == true">
            <v-chip :disabled="ui.caching" small label outlined
              nope-:color="item.cached == 'conflicted' ? 'warning' : 'green'"
              color="green"
              @click="preload(item)">
              <v-icon v-if="item.cached == 'conflicted'" small class="pr-2" color="warning">mdi-cloud-question</v-icon>
              <v-icon v-else small class="pr-2">mdi-lightning-bolt-circle</v-icon>
              Preparado
            </v-chip>

            <v-btn
              small label outlined dense
              class="px-0 mx-1"
              style="min-width: 30px; height: 24px;"
              color="primary"
              @click="preload(item)"
              nope-loading="item.preloaded.preloading"
              :disabled="ui.caching">
              <v-icon small>mdi-cached</v-icon>
              <!-- <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template> -->
            </v-btn>
          </template>

          <v-btn
            v-else
            small label outlined
            dense
            color="primary"
            @click="preload(item)"
            :loading="item.preloaded.preloading"
            :disabled="ui.caching">
            {{ (item.preloaded.preloading)? 'Cargando...' : 'Precargar' }}
          </v-btn>
        </template>

        <template v-slot:item.preload="{ item }">
          <v-progress-linear
            v-if="item.preloaded.preloading"
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>

          <div v-if="item.preloaded.preloading && preloading.print" style="display: none;">
            <pre>
              uuid. {{ preloading.uuid }}
              itemUUID: {{ preloading.itemUUID }}
            </pre>
            <component
              ref="dynamic"
              :key="preloading.uuid"
              :is="currentComponent"
              :uuid="preloading.uuid"
              :auditoriaUUID="preloading.itemUUID"
              />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    selectorTable.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 16th November 2021
 * Last Modified: Mon Feb 13 2023
 **/

import auditoria from '~/pages/auditorias/_uuid'
import checklist from '~/pages/auditorias/tabs/checklist/_uuid'
import muestras from '~/pages/auditorias/tabs/muestras/_uuid'

export default {
  props: ['enabled', 'items', 'cols', 'howToLoad', 'onInit', 'onInitBody', 'onLoaded'],
  components: {
    auditoria,
    checklist,
    muestras,
  },
  data() {
    return {
      path: [], // steps required to preload an item
      preloaded: {}, // state of all items
      preloading: {
        step: 0, // current step of preloading
        child: 0, // child index in loop requests
        xhr: null,
        uuid: null, // UUID of current preloading item
        itemUUID: null, // first element uuid
        clientUUID: null, // client uuid
        print: false,
      },

      table: {
        search: '',
        headers: [{ text: 'Estado', value: 'status', cellClass: 'subData', width: 100 }],
      },

      ui: {
        caching: false,
        loading: false,
      },
    }
  },

  watch: {
    enabled(val) {
      if (val) {
        this.$store.commit('offline/enableCache', true)
        this.preparePreloadInit()
      } else {
        this.$store.commit('offline/enableCache', false)
      }
    },
  },

  computed: {
    //+-------------------------------------------------
    // addPropCols()
    // Adds new cols given by props to table.headers
    // -----
    // Created on Fri Oct 29 2021
    //+-------------------------------------------------
    headers() {
      let dynamicHeaders = [...this.table.headers]

      for (const el of this.cols) {
        let length = el.text.length * 6
        el.width = length
        el.sortable = false
        el.cellClass = 'subData'

        dynamicHeaders.push(el)
      }

      dynamicHeaders.push({ text: '', value: 'spacer', width: '50', cellClass: 'subData' })
      dynamicHeaders.push({
        text: '',
        align: 'right',
        value: 'preload',
        width: 300,
        sortable: false,
      })

      return dynamicHeaders
    },

    rows() {
      let items = []
      if (!this.enabled) return items

      this.items.forEach((element) => {
        this.preloaded[element.uuid] = {}
        element.preloaded = this.preloaded[element.uuid]
        items.push(element)
      })

      return items
    },

    currentComponent() {
      return this.path[this.preloading.step] ? this.path[this.preloading.step].component : '--'
    },
  },

  methods: {
    //+-------------------------------------------------
    // preparePreloadInit()
    // Performs an initial store call to cache
    // -----
    // Created on Thu Nov 04 2021
    //+-------------------------------------------------
    async preparePreloadInit() {
      if (!this.onInit) return
      for (const key in this.onInit) {
        const xhr = await this.$store.dispatch(this.onInit[key], this.onInitBody[key])
      }
    },

    async preload(item) {
      this.ui.caching = true
      this.preloading.step = 0
      this.preloading.uuid = item.uuid
      this.preloading.itemUUID = item.uuid
      this.preloading.clientUUID = item.cliente?.uuid
      this.preloaded[item.uuid].preloading = true

      await this.doPreload()
      item.cached = true

      this.$refs.datatable.$forceUpdate()
      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // nextStepOrFinish()
    // Increments preloading counters and calls nextStep
    // -----
    // Created on Fri Nov 19 2021
    //+-------------------------------------------------
    nextStepOrFinish() {
      this.preloading.print = false

      if (this.preloading.step < this.path.length - 1) {
        if (this.path[this.preloading.step].done == true) {
          this.preloading.step++
        } else {
          this.preloading.child++
        }

        this.doPreload()
        return true
      }

      // No longer used
      // this.afterLoaded()

      this.preloaded[this.preloading.itemUUID].done = true
      this.preloaded[this.preloading.itemUUID].preloading = false

      this.ui.caching = false
      this.preloading = {
        step: 0,
        child: 0,
        xhr: null,
        uuid: null,
        itemUUID: null,
        print: false,
      }

      this.$forceUpdate()
      this.$refs.datatable.$forceUpdate()
    },

    //+-------------------------------------------------
    // doPreload()
    // Performs the current preloading step
    // This can be performing a request or printing a component
    // -----
    // Created on Fri Nov 19 2021
    // Updated on Fri Mar 04 2022
    //+-------------------------------------------------
    async doPreload() {
      let step = this.preloading.step
      let child = this.preloading.child
      let config = this.path[step]

      console.warn('doPreload', step, child, config)

      if (config.request) {
        await this.doRequest(config)
        this.path[step].done = true
        return this.nextStepOrFinish()
      }

      if (config.action) {
        if (config.action == 'clearCache') {
          let key = config.key.replace('uuid', this.preloading.uuid)
          this.$store.commit('offline/removeItem', key)
        }

        if (config.action == 'lockAuditoria') {
          await this.$store.dispatch('auditorias/toggleLock', {
            uuid: this.preloading.uuid,
            action: 'lock',
          })
        }

        if (config.action == 'downloadClientFiles') {
          if (this.preloading.clientUUID) {
            await this.downloadFiles('clientes/getDocumentos', {
              client: this.preloading.clientUUID,
            })
          }
        }

        this.path[step].done = true
        return this.nextStepOrFinish()
      }

      if (config.component) {
        if (this.preloading.xhr && this.preloading.xhr[child]?.uuid) {
          this.preloading.uuid = this.preloading.xhr[child].uuid
        } else {
          this.preloading.uuid = this.preloading.itemUUID
        }

        this.preloading.print = true
        console.warn('print component', this.currentComponent, this.preloading.uuid)
      }

      if (this.preloading.xhr && child < this.preloading.xhr.length - 1) {
        return
      }

      this.path[step].done = true
      return true
    },

    async doRequest(config) {
      this.preloading.xhr = null
      this.preloading.child = 0

      let uuid = this.preloading[config.uuid] ? this.preloading[config.uuid] : this.preloading.uuid
      console.warn('✈️ doRequest', config.request, uuid, config)
      const xhr = await this.$store.dispatch(config.request, uuid)
      if (xhr) this.preloading.xhr = xhr
      return true
    },

    //+-------------------------------------------------
    // downloadFiles()
    // Get files from store and open
    // a promt with the files to download
    // -----
    // Created on Tue Nov 16 2021
    //+-------------------------------------------------
    async downloadFiles(store, payload) {
      const xhr = await this.$store.dispatch(store, payload)
      this.$nuxt.$emit('documents:openDownloader', {
        files: xhr,
      })
    },

    //+-------------------------------------------------
    // createPath()
    // Get the indications from :howToLoad and create a
    // path of components and props to load
    // -----
    // Created on Thu Nov 18 2021
    //+-------------------------------------------------
    createPath() {
      this.path = this.howToLoad
    },

    init() {
      this.createPath()
    },
  },

  mounted() {
    this.init()

    this.$nuxt.$on('offline:loaded', (payload) => {
      this.nextStepOrFinish(payload)
    })
  },

  beforeDestroy() {
    this.$store.commit('offline/enableCache', false)
    this.$nuxt.$off('offline:loaded')
  },
}
</script>

<style>
.v-data-table.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.subData {
  color: rgb(146, 146, 146);
  font-size: 12px !important;
  white-space: nowrap;
  /* padding: 0 23px !important; */
}
</style>
