import {uuid} from 'vue-uuid'

export const state = () => ({
  list: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  }
};
//dispatch actions
export const actions = {

  async list({rootState, commit}, params) {
    rootState.loading = true
    const res = await this.$axios.get('zonas-visita')
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`zonas-visita/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, item) {
    rootState.loading = true
    item.uuid = uuid.v4()
    await this.$axios.post('zonas-visita', item)
    rootState.loading = false
  },

  async update({rootState}, item) {
    rootState.loading = true
    await this.$axios.patch(`zonas-visita/${item.uuid}`, {
      nombre: item.nombre,
      zona_visita: item.uuid
    })
    rootState.loading = false
  },

  async delete({rootState}, item) {
    rootState.loading = true
    const res = await this.$axios.delete(`zonas-visita/${item.uuid}`)
    rootState.loading = false
  }

};
