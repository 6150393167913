<template>
  <div>
    <loading v-model="loading" text="Espere por favor..." />

    <!-- DATOS GENERALES -->
    <v-row class="pa-3 pt-0 pb-3">
      <v-col cols="12" md="8">
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 5" width="100%">
            <v-card-title>{{ item.nombre }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Auditor"
                    disabled
                    outlined
                    dense
                    v-model="item.auditor.nombreCompleto"></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Emplazamiento"
                    disabled
                    outlined
                    dense
                    v-model="item.emplazamiento.nombre"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="mt-0 pt-0">
                  <v-menu v-model="menuFecha" :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="formatDate(item.fecha)"
                        clearable
                        label="Fecha"
                        readonly
                        outlined
                        dense
                        v-on="on"
                        @click:clear="item.fecha = null"
                        :disabled="isDisabled"></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="item.fecha"
                      @change=";(menuFecha = false), saveChecklist()"
                      no-title
                      scrollable
                      :first-day-of-week="1"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" class="mt-0 pt-0">
                  <v-text-field
                    type="time"
                    outlined
                    dense
                    v-model="item.inicio"
                    @change="saveChecklist()"
                    :disabled="isDisabled"
                    label="Hora inicio"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-0 pt-0">
                  <v-text-field
                    type="time"
                    outlined
                    dense
                    v-model="item.fin"
                    @change="saveChecklist()"
                    :disabled="isDisabled"
                    label="Hora fin"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    outlined
                    auto-grow
                    label="Observacion"
                    v-model="item.observacion"
                    rows="3"
                    :disabled="isDisabled"
                    @blur="saveChecklist()">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <!-- CUMPLIMIENTO -->
      <v-col cols="12" md="4" v-if="!item.checklistsPuntuacion" :key="ui.puntosKey">
        <v-hover v-slot:default="{ hover }">
          <v-card
            :elevation="hover ? 10 : 5"
            width="100%"
            v-if="item && item.preguntaChecklists && cumplimiento.count">
            <v-card-title class="pb-1">
              Completado al {{ ((cumplimiento.answered * 100) / cumplimiento.total).toFixed(0) }}%
              ({{ cumplimiento.answered }} / {{ cumplimiento.total }})
            </v-card-title>
            <v-card-text>
              <v-chip-group mandatory active-class="success" v-model="ui.cumplimiento">
                <v-chip small label value="success"> Cumplimiento </v-chip>
                <v-chip small label value="failed"> No conformidades </v-chip>
              </v-chip-group>

              <v-row class="py-0 my-0">
                <template v-for="(amount, key) in cumplimiento.count">
                  <v-col
                    cols="7"
                    class="title pb-0 mb-0"
                    style="text-transform: capitalize; display: flex; align-items: center"
                    :key="'acumplimiento' + key">
                    <v-chip
                      label
                      small
                      class="mr-1"
                      :color="ui.cumplimiento == 'success' ? 'success' : 'red'"
                      text-color="white">
                      {{ ui.cumplimiento == 'success' ? labelV : labelF }}
                    </v-chip>
                    {{ key }}
                  </v-col>
                  <v-col cols="5" class="pb-0 mb-0" :key="'bcumplimiento' + key">
                    <div class="headline pr-2" v-if="cumplimiento[ui.cumplimiento]">
                      {{ ((cumplimiento[ui.cumplimiento][key] * 100) / amount).toFixed(0) }}%
                      <span class="deep-purple--text px-2" style="font-size: 1.2rem !important">
                        {{ cumplimiento[ui.cumplimiento][key] }}/{{ amount }}
                      </span>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col v-else :key="ui.puntosKey">
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 5" width="100%">
            <v-card-title
              >Puntuación del checklist
              <v-spacer></v-spacer>
              <v-chip
                color="indigo"
                text-color="white"
                style="font-size: 1.2rem !important"
                label
                small>
                <span class="title"> {{ item.checklistsPuntuacion.total }} </span>
              </v-chip>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-select
                    v-model="optionSelected"
                    :items="item.checklistsPuntuacion.options"
                    label="Categoria"
                    outlined
                    dense
                    :disabled="isDisabled"></v-select>
                </v-col>
              </v-row>
              <v-row v-if="optionSelected" no-gutters>
                <v-col cols="12">
                  <v-data-table
                    :headers="optionSelectedHeaders"
                    :items="optionSelectedItems"
                    :items-per-page="5"
                    class="elevation-1"
                    hide-default-footer>
                  </v-data-table>
                </v-col>
                <v-col cols="12" class="mb-2 mt-4">
                  <span class="title"
                    >Total de respuestas en esta categoría:
                    {{ item.checklistsPuntuacion[optionSelected].numero }}
                  </span>
                </v-col>
                <v-col cols="12">
                  <span class="title"
                    >Puntuación total de la categoría:
                    {{ item.checklistsPuntuacion[optionSelected].total }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <!-- FILTROS Y PREGUNTAS -->
    <v-row class="pa-3 pt-0 pb-0">
      <!-- FILTROS -->
      <v-col cols="12" class="mb-0 pb-0">
        <v-card
          width="100%"
          color="grey lighten-3"
          class="pa-5 pt-0"
          style="border-radius: 0px !important">
          <v-row>
            <v-col cols="6" class="pt-4 pb-0">
              <span class="title"> Filtros </span>
            </v-col>

            <v-col cols="6" class="text-right">
              <input
                type="file"
                ref="inputFile"
                style="display: none"
                @change="importFile($event)" />
              <v-btn
                color="secondary"
                class="ml-2"
                dark
                small
                depressed
                @click="confirmarImportFile()">
                <v-icon class="mr-2">mdi-file-import</v-icon>
                Importar Excel
              </v-btn>

              <v-btn color="secondary" class="ml-2" dark small depressed @click="excelExport()">
                <v-icon class="mr-2">mdi-file-export</v-icon>
                Exportar Excel
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="pt-0 pb-0">
              <v-text-field label="Título" v-model="filtro_titulo" clearable />
            </v-col>

            <v-col cols="12" md="3" class="pt-0 pb-0">
              <SelectEstaticos
                v-if="auditoria.subalcance && estaticos && ui.ready"
                v-model="filtro_niveles"
                :subalcace="auditoria.subalcance"
                codigo="nivel-de-checklist"
                multiple
                :estaticos="estaticos" />
            </v-col>

            <v-col cols="12" md="3" class="pt-0 pb-0">
              <v-checkbox
                color="primary"
                class="ml-5"
                label="Incompletas"
                v-model="filtro_incompletas" />
            </v-col>
            <v-col cols="12" class="pt-0 pb-0">
              <v-btn
                v-show="openPanels.length === 0"
                color="primary"
                class="ml-5"
                dark
                @click="openPreguntas"
                style="float: right">
                Desglosar todas las preguntas
              </v-btn>
              <v-btn
                v-if="
                  item.preguntaChecklists &&
                  openPanels.length > 0 &&
                  openPanels.length === filtrarPreguntas.length
                "
                color="primary"
                class="ml-5"
                dark
                @click="closePreguntas"
                style="float: right">
                Ocultar todas las preguntas
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- PREGUNTAS -->
      <v-col cols="12" class="mt-0 pt-0" :key="ui.puntosKey">
        <v-data-iterator
          :items="filtrarPreguntas"
          :page="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :footer-props="{
            'items-per-page-options': pagination.itemsPerPageArray,
          }">
          <template v-slot:default="{ items, isExpanded, expand }">
            <v-expansion-panels v-model="openPanels" multiple class="panel">
              <v-expansion-panel
                v-for="(pregunta, i) in items"
                :key="i"
                :disabled="pregunta.tipoPregunta !== 'requerimiento'">
                <v-expansion-panel-header class="subtitle-1" hide-actions>
                  <v-row>
                    <v-col cols="6" class="titulo-pregunta">
                      <span class="font-weight-bold black--text">
                        {{ pregunta.codigoCalculo }}. <span v-html="pregunta.titulo"></span>
                        <template v-if="pregunta.isUpdatedFromPac">
                          <v-tooltip bottom color="indigo lighten-1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="orange"
                                class="ml-2"
                                style="font-size: 1.2rem !important">
                                mdi-alert-circle
                              </v-icon>
                            </template>
                            <span>Actualizado desde AC</span>
                            <ul>
                              <li>Respuesta: {{ pregunta.previousInfo.opcionRespuesta }}</li>
                              <li>Valoracion: {{ pregunta.previousInfo.valoracion }}</li>
                              <li>Nivel: {{ pregunta.previousInfo.nivel }}</li>
                            </ul>
                          </v-tooltip>
                        </template>
                        <v-tooltip
                          bottom
                          v-if="evidencias[pregunta.uuid] && evidencias[pregunta.uuid].length > 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                              <v-icon color="grey lighten-1">mdi-paperclip</v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Se han adjuntado
                            <strong>{{ evidencias[pregunta.uuid].length }} archivos</strong></span
                          >
                        </v-tooltip>
                      </span>
                    </v-col>

                    <v-col cols="1" md="1" xl="1" style="color: #2196f3">
                      {{ pregunta.relacion }}
                    </v-col>

                    <v-col class="pa-0 text-center d-flex align-center">
                      <v-row>
                        <v-col
                          v-if="
                            pregunta.respuesta &&
                            pregunta.respuesta.opcionRespuestas &&
                            pregunta.respuesta.opcionRespuestas.length < 5
                          ">
                          <v-btn-toggle
                            tile
                            mandatory
                            class="mb-4 d-flex justify-end"
                            v-if="
                              pregunta.respuesta &&
                              pregunta.respuesta.tipo == 'Alfanumérico' &&
                              !pregunta.solo_titulo
                            "
                            v-model="pregunta.opcionRespuesta">
                            <v-btn
                              v-for="response in pregunta.respuesta.opcionRespuestas"
                              :key="response.uuid"
                              :disabled="isDisabled"
                              :id="'btn' + response.uuid + pregunta.uuid"
                              :color="
                                pregunta.opcionRespuesta &&
                                pregunta.opcionRespuesta['@id'] === response['@id']
                                  ? findColor(response.codigo, pregunta.uuid)
                                  : 'grey lighten-1'
                              "
                              :value="response"
                              @click.stop="setRespuesta(pregunta, response)"
                              >{{ response.codigo }}</v-btn
                            >
                          </v-btn-toggle>
                        </v-col>
                        <v-col
                          v-else-if="pregunta.respuesta && pregunta.respuesta.opcionRespuestas"
                          class="d-flex flex-column justify-end align-center">
                          <v-menu v-model="menu[pregunta.uuid]" close-on-content-click offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="menu[pregunta.uuid] = true"
                                class="mr-4"
                                color="indigo lighten-3"
                                label>
                                {{
                                  pregunta.opcionRespuesta
                                    ? pregunta.opcionRespuesta.codigo
                                    : 'Seleccionar respuesta'
                                }}
                              </v-chip>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="option in pregunta.respuesta.opcionRespuestas"
                                :key="option.uuid + pregunta.respuesta.uuid"
                                @click="setRespuesta(pregunta, option)">
                                <v-list-item-content>{{ option.codigo }}</v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>

                        <v-col v-else></v-col>
                        <v-col
                          v-if="
                            !isCoplaca &&
                            pregunta.respuesta &&
                            pregunta.respuesta.opcionRespuestas &&
                            !pregunta.categoria
                          "
                          cols="4">
                          <SelectEstaticos
                            v-if="
                              estaticos &&
                              !pregunta.solo_titulo &&
                              auditoria.subalcance &&
                              ui.ready &&
                              pregunta.tipoPregunta !== 'modulo'
                            "
                            v-model="pregunta.nivel"
                            :subalcance="auditoria.subalcace"
                            codigo="nivel-de-checklist"
                            :itemValue="'valor'"
                            @change="savePregunta(pregunta)"
                            :estaticos="estaticos"
                            :clearable="true"
                            :disabled="isDisabled" />
                        </v-col>
                        <v-col
                          v-if="
                            estaticos &&
                            !pregunta.solo_titulo &&
                            auditoria.subalcance &&
                            ui.ready &&
                            pregunta.tipoPregunta !== 'modulo'
                          "
                          cols="4">
                          <v-chip
                            v-if="pregunta.nivel"
                            color="red lighten-2"
                            dense
                            style="max-width: 100px">
                            {{ pregunta.nivel.charAt(0).toUpperCase() + pregunta.nivel.slice(1) }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="pregunta.relacion && preguntas_relacion[pregunta.relacion]">
                      <ul class="pb-4">
                        <li v-for="item in preguntas_relacion[pregunta.relacion]" :key="item.id">
                          {{
                            'Checklist' +
                            item.checklist.titulo +
                            ' - Código ' +
                            item.pregunta.codigo
                          }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pl-5 pb-0" v-if="openPanels.includes(i)">
                  <v-row no-gutters class="pa-5">
                    <v-col cols="12" v-html="pregunta.textoExplicativo"> </v-col>

                    <v-col cols="12" class="mt-1 mb-6">
                      <v-btn
                        v-if="!auditoriaCerrada || item.is_revision"
                        color="info"
                        depressed
                        :disabled="isDisabled"
                        class="mt-4"
                        style="width: 200px; margin: auto"
                        nope-click="adjuntarEvidencia(pregunta.uuid)"
                        @click="addEvidencia(pregunta.uuid)">
                        Adjuntar evidencias
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="12"
                      style="padding-right: 30px"
                      v-if="evidencias[pregunta.uuid] && evidencias[pregunta.uuid].length > 0">
                      <p class="title">Evidencias</p>
                      <table
                        style="
                          border: 1px solid #ccc;
                          padding: 7px;
                          background: #f9f9f9;
                          border-radius: 3px;
                          box-shadow: 0px 0px 3px 0px #e2e2e2;
                          margin-bottom: 10px;
                          width: 100%;
                        ">
                        <thead>
                          <tr>
                            <th class="text-left">Archivo</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template>
                            <tr v-for="(doc, key) in evidencias[pregunta.uuid]" :key="key">
                              <td style="padding: 3px">{{ doc.nombreArchivo }}</td>
                              <td style="padding: 3px" class="text-right">
                                <v-btn small color="purple" @click="download(doc)">Descargar</v-btn>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </v-col>

                    <v-col cols="12" class="">
                      <v-row
                        v-if="
                          pregunta.opcionRespuesta === undefined ||
                          (pregunta.opcionRespuesta &&
                            (pregunta.opcionRespuesta.cumple === undefined ||
                              pregunta.opcionRespuesta.cumple))
                        ">
                        <v-col cols="12" class="pr-10">
                          <p class="title mb-2">
                            Observacion

                            <v-btn-toggle
                              class="px-5"
                              v-if="templates.observaciones && templates.observaciones.length">
                              <v-btn
                                small
                                text
                                v-if="!isDisabled"
                                @click="
                                  assignTemplate(pregunta, 'observaciones', 'observaciones', 0)
                                ">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu
                                :close-on-content-click="true"
                                v-if="templates.observaciones.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0"> mdi-chevron-down </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(doc, key) in templates.observaciones"
                                    @click="
                                      assignTemplate(
                                        pregunta,
                                        'observaciones',
                                        'observaciones',
                                        key
                                      )
                                    "
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>
                          </p>

                          <v-card
                            outlined
                            v-if="
                              auditoriaCerrada &&
                              !item.is_revision &&
                              !caneEditCheckListFromRevisiones
                            ">
                            <v-card-text v-html="pregunta.observacion"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea
                              :disabled="isDisabled"
                              label="Observacion"
                              v-model="pregunta.observacion"
                              @focus="pregunta.observacion = htmlToPlain(pregunta.observacion)"
                              rows="6"
                              outlined
                              @blur="savePregunta(pregunta)"></v-textarea>
                          </template>
                        </v-col>
                        <v-col cols="12" class="pr-10">
                          <p class="title mb-2">
                            Observaciones detectadas a emitir

                            <v-btn-toggle
                              class="px-5"
                              v-if="templates.comentarios && templates.comentarios.length">
                              <v-btn
                                small
                                text
                                v-if="!isDisabled"
                                @click="assignTemplate(pregunta, 'comentarios', 'comentarios', 0)">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu
                                :close-on-content-click="true"
                                v-if="templates.comentarios.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0"> mdi-chevron-down </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(doc, key) in templates.comentarios"
                                    @click="
                                      assignTemplate(pregunta, 'comentarios', 'comentarios', key)
                                    "
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>
                          </p>

                          <v-card
                            outlined
                            v-if="
                              auditoriaCerrada &&
                              !item.is_revision &&
                              !caneEditCheckListFromRevisiones
                            ">
                            <v-card-text v-html="pregunta.comentarios"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea
                              :disabled="isDisabled"
                              label="Comentarios"
                              v-model="pregunta.comentarios"
                              @focus="pregunta.comentarios = htmlToPlain(pregunta.comentarios)"
                              nope-:readonly="auditoriaCerrada"
                              rows="6"
                              outlined
                              @blur="savePregunta(pregunta)"></v-textarea>
                          </template>
                        </v-col>
                      </v-row>

                      <v-row v-else>
                        <v-col cols="12" md="6" class="pr-10">
                          <p class="title">
                            Observación

                            <v-btn-toggle
                              class="px-5"
                              v-if="templates.observaciones && templates.observaciones.length">
                              <v-btn
                                small
                                text
                                v-if="!isDisabled"
                                @click="
                                  assignTemplate(pregunta, 'observaciones', 'observaciones', 0)
                                ">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu
                                :close-on-content-click="true"
                                v-if="templates.observaciones.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0"> mdi-chevron-down </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(doc, key) in templates.observaciones"
                                    @click="
                                      assignTemplate(
                                        pregunta,
                                        'observaciones',
                                        'observaciones',
                                        key
                                      )
                                    "
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>
                          </p>
                          <v-card
                            outlined
                            v-if="
                              auditoriaCerrada &&
                              !item.is_revision &&
                              !caneEditCheckListFromRevisiones
                            ">
                            <v-card-text v-html="pregunta.observacion"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea
                              :disabled="isDisabled"
                              label="Observacion"
                              v-model="pregunta.observacion"
                              @focus="pregunta.observacion = htmlToPlain(pregunta.observacion)"
                              nope-:readonly="auditoriaCerrada"
                              rows="6"
                              outlined
                              @blur="savePregunta(pregunta)"></v-textarea>
                          </template>
                        </v-col>
                        <v-col cols="12" md="6" class="pr-10">
                          <p class="title">
                            Texto de no conformidad

                            <v-btn-toggle
                              class="px-5"
                              v-if="
                                templates['no-conformidad'] && templates['no-conformidad'].length
                              ">
                              <v-btn
                                small
                                text
                                v-if="!isDisabled"
                                @click="
                                  assignTemplate(
                                    pregunta,
                                    'textoNoConformidad',
                                    'no-conformidad',
                                    0
                                  )
                                ">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu
                                :close-on-content-click="true"
                                v-if="templates['no-conformidad'].length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0"> mdi-chevron-down </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(doc, key) in templates['no-conformidad']"
                                    @click="
                                      assignTemplate(
                                        pregunta,
                                        'textoNoConformidad',
                                        'no-conformidad',
                                        key
                                      )
                                    "
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>
                          </p>
                          <v-card
                            outlined
                            v-if="
                              auditoriaCerrada &&
                              !item.is_revision &&
                              !caneEditCheckListFromRevisiones
                            ">
                            <v-card-text v-html="pregunta.textoNoConformidad"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea
                              :disabled="isDisabled"
                              v-model="pregunta.textoNoConformidad"
                              @focus="
                                pregunta.textoNoConformidad = htmlToPlain(
                                  pregunta.textoNoConformidad
                                )
                              "
                              label="Texto de no conformidad"
                              nope-:readonly="auditoriaCerrada"
                              rows="6"
                              outlined
                              @blur="savePregunta(pregunta)"></v-textarea>

                            <v-text-field
                              outlined
                              dense
                              v-model="pregunta.numero"
                              class="mt-2"
                              :disabled="isDisabled"
                              label="Número"
                              @blur="savePregunta(pregunta)">
                            </v-text-field>
                          </template>
                        </v-col>
                        <v-col cols="12" md="6" class="pr-10" v-if="isCcl">
                          <p class="title">Observaciones detectadas a emitir</p>
                          <v-card
                            outlined
                            v-if="
                              auditoriaCerrada &&
                              !item.is_revision &&
                              !caneEditCheckListFromRevisiones
                            ">
                            <v-card-text v-html="pregunta.comentarios"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea
                              :disabled="isDisabled"
                              label="Comentarios"
                              v-model="pregunta.comentarios"
                              @focus="pregunta.comentarios = htmlToPlain(pregunta.comentarios)"
                              nope-:readonly="auditoriaCerrada"
                              rows="3"
                              outlined
                              @blur="savePregunta(pregunta)"></v-textarea>
                          </template>

                          <p class="title pt-5">Requisito afectado</p>

                          <v-card
                            outlined
                            v-if="
                              auditoriaCerrada &&
                              !item.is_revision &&
                              !caneEditCheckListFromRevisiones
                            ">
                            <v-card-text v-html="pregunta.requisito_afectado"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea
                              :disabled="isDisabled"
                              label="Comentarios"
                              v-model="pregunta.requisito_afectado"
                              @focus="
                                pregunta.requisito_afectado = htmlToPlain(
                                  pregunta.requisito_afectado
                                )
                              "
                              rows="3"
                              outlined
                              @blur="savePregunta(pregunta)"></v-textarea>
                          </template>
                        </v-col>
                      </v-row>

                      <v-row v-if="pregunta.bloques && pregunta.bloques.length > 0">
                        <v-col class="pr-10" v-if="db.bloques.length && ui.refresh > 0">
                          <template v-for="(block, index) in pregunta.bloques">
                            <v-row
                              class="my-3"
                              v-if="preguntaHasBloque(pregunta, block.uuid)"
                              :key="'block-' + index + pregunta.uuid">
                              <v-col cols="8" :alt="index">
                                <p class="title mb-0">{{ block.titulo }}</p>
                              </v-col>

                              <v-col
                                v-if="!isDisabled"
                                class="text-right"
                                cols="4"
                                :key="'block-actions-' + o">
                                <span
                                  v-if="
                                    $refs['blck-' + block.uuid] &&
                                    $refs['blck-' + block.uuid][0] &&
                                    $refs['blck-' + block.uuid][0].item.updatedAt
                                  "
                                  style="
                                    color: rgb(146, 146, 146);
                                    font-size: 12px !important;
                                    margin-right: 10px;
                                  ">
                                  Actualizado
                                  {{
                                    $moment($refs['blck-' + block.uuid][0].item.updatedAt).fromNow()
                                  }}
                                </span>

                                <v-btn-toggle>
                                  <v-btn small text @click="updateBlock(block.uuid, block)">
                                    <span class="hidden-sm-and-down">Guardar</span>
                                    <v-icon right> mdi-content-save </v-icon>
                                  </v-btn>

                                  <v-menu offset-y :close-on-content-click="true">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon v-bind="attrs" v-on="on">
                                        <v-icon right small class="ma-0"> mdi-chevron-down </v-icon>
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <v-list-item @click.stop="cloneBlock(block.uuid, block)">
                                        <v-list-item-title>
                                          Añadir otro como este
                                        </v-list-item-title>
                                        <v-list-item-icon>
                                          <v-icon small> mdi-content-copy </v-icon>
                                        </v-list-item-icon>
                                      </v-list-item>

                                      <v-list-item
                                        v-if="!block.isNew"
                                        color="error darken-3"
                                        @click.stop="resetBlock(block.uuid, block)">
                                        <v-list-item-title> Eliminar datos </v-list-item-title>
                                        <v-list-item-icon>
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon small v-bind="attrs" v-on="on">
                                                mdi-refresh
                                              </v-icon>
                                            </template>
                                            <span
                                              >Reiniciar bloque.<br />Los datos se eliminarán y la
                                              estructura se actualizará desde la plantilla</span
                                            >
                                          </v-tooltip>
                                        </v-list-item-icon>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-btn-toggle>
                              </v-col>

                              <v-col cols="12" class="pt-0 mb-4" :key="'block-embed-' + o">
                                <block-embed
                                  :ref="'blck-' + block.uuid"
                                  :block="block"
                                  :submoduloRef="pregunta.uuid">
                                </block-embed>
                              </v-col>
                            </v-row>
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>

    <Confirmation
      v-model="dialogImport"
      :text="texto_confirmacion"
      textButton="Aceptar"
      colorButton="error"
      @confirm=";(dialogImport = false), $refs.inputFile.click()">
      <v-card-text class="text-center">
        Esta acción reemplazará todos los datos de la checklist.
      </v-card-text>
    </Confirmation>

    <v-dialog eager v-model="dialogAddEvidencia" max-width="40%" hide-overlay persistent>
      <v-card>
        <v-card-title>Adjuntar evidencia</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">
            <v-row class="pl-10 pr-10" v-if="evidencia">
              <v-col cols="12">
                <v-text-field
                  label="Título"
                  required
                  :rules="rules"
                  v-model="evidencia.titulo"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="evidencia">
                <v-file-input
                  required
                  :rules="rules"
                  label="Documento"
                  v-model="evidencia.file"></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pr-3">
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" class="mr-3" text @click="closeDialogAddEvidencia()">
            Cancelar
          </v-btn>
          <v-btn depressed color="primary" @click="addEvidencia()">
            <v-icon left>mdi-upload</v-icon>
            Adjuntar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as UTIL from '~/util'
import Loading from '~/components/Loading'
import Mapa from '~/components/maps/Mapa'
import Editor from '@tinymce/tinymce-vue'
import Estados from '~/models/estados'
import Estado from '~/models/estados'
import blockEmbed from '~/pages/bloques/widgets/blockEmbed'
import Confirmation from '~/components/Confirmation'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import Colors from '~/models/responseColors'

export default {
  props: ['uuid', 'auditoriaUUID'],
  components: {
    Loading,
    Mapa,
    Editor,
    blockEmbed,
    Confirmation,
    SelectEstaticos,
  },
  async created() {
    this.$store.commit('menu/setPageTitle', 'Auditoría checklist')
    await this.checkOffline()
  },
  data: () => ({
    valid: false,
    loading: false,
    dialogAddEvidencia: false,
    dialogModoOnline: false,
    dialogImport: false,
    texto_confirmacion: '',
    menuFecha: false,
    openPanels: [],
    pagination: {
      itemsPerPageArray: [500, -1],
      itemsPerPage: 500,
      page: 1,
    },

    item: {
      auditor: {},
      emplazamiento: {
        nombre: '',
      },
      observaciones: '',
      preguntas: [],
    },

    niveles: Estado.CHECKLISTS_NIVELES,
    estaticos: null,
    filtro_titulo: null,
    filtro_niveles: null,
    filtro_incompletas: null,
    auditoria: {},
    estado: Estados.AUDITORIA_ESTADO,
    estadoRevisiones: Estados.REVISION_ESTADO,
    rules: [(v) => !!v || 'El campo es obligatorio'],
    evidencia: {
      uuid: '',
      titulo: null,
      file: null,
    },
    evidencias: [],

    editorInit: {
      height: '25vh',
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap',
        'searchreplace visualblocks code fullscreen',
        'print preview anchor insertdatetime media',
        'paste code help wordcount table pagebreak',
      ],
      toolbar:
        'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | table pagebreak | help',
      pagebreak_separator: '<div class="page-break-before"></div>',
    },
    preguntas_relacion: {},
    columnDefs: [
      { headerName: 'Código', field: 'codigo' },
      { headerName: 'Relación', field: 'relacion' },
      { headerName: 'Título', field: 'titulo' },
      { headerName: 'Respuesta', field: 'respuesta' },
      { headerName: 'Nivel', field: 'nivel' },
      { headerName: 'Observaciones', field: 'observaciones' },
      { headerName: 'Texto de no conformidad', field: 'textoNoConformidad' },
    ],

    db: {
      bloques: [],
      misBloques: [],
    },

    labels: {
      niveles: {},
    },

    templates: {},
    optionSelected: null,
    auth: {},

    ui: {
      refresh: 0,
      ready: false,
      cumplimiento: false,
      loadingBlocks: false,
      puntosKey: 0,
    },
    menu: {},
    selectedOption: null,
  }),

  watch: {
    actualizarEvidencias(val) {
      if (val) this.getEvidencias()
    },
  },

  computed: {
    optionSelectedHeaders() {
      if (!this.optionSelected) return []
      let headers = [
        {
          text: 'Requierimiento',
          value: 'colname',
        },
      ]
      let filtered = JSON.parse(JSON.stringify(this.item.checklistsPuntuacion[this.optionSelected]))
      //remove total and nombre keys from filtered object
      delete filtered['total']
      delete filtered['nombre']
      delete filtered['numero']
      for (let key in filtered) {
        headers.push({
          text: key,
          value: key,
        })
      }
      return headers
    },
    optionSelectedItems() {
      if (!this.optionSelected) return []
      let colnames = ['Número', 'Puntuacion']
      let items = []
      for (let i = 0; i < colnames.length; i++) {
        let row = {
          colname: colnames[i],
        }
        let colkey = colnames[i] == 'Número' ? 'numero' : 'puntos'
        let filtered = JSON.parse(
          JSON.stringify(this.item.checklistsPuntuacion[this.optionSelected])
        )

        //remove total and nombre keys from filtered object
        delete filtered['total']
        delete filtered['nombre']

        for (let key in filtered) {
          row[key] = filtered[key][colkey]
        }
        items.push(row)
      }

      return items
    },
    isDisabled() {
      if (!this.auth.canEdit) return true

      if (this.auditoriaCerrada) return true
      if (!this.auditoria || !this.auditoria.responsable) return true

      let isAuditor = this.$auth.user.roles.includes('ROLE_AUDITOR')
      let isTheAuditor = this.$auth.user.uuid === this.auditoria.responsable.uuid
      let isAuditorAdmin = this.$auth.user.roles.includes('ROLE_AUDITOR_ADMIN')
      let isAuditorInAuditores = this.auditoria.auditores.some((auditor) => {
        return this.$auth.user.uuid === auditor.uuid
      })

      if (isAuditor && !isTheAuditor && !isAuditorInAuditores && !isAuditorAdmin) return true

      return false
    },

    caneEditCheckListFromRevisiones() {
      if (this.auditoria && this.auditoria.revision && this.auditoria.revision.length > 0) {
        return (
          this.auditoria.revision[0].estado === this.estadoRevisiones.PENDIENTE ||
          this.auditoria.estado === this.estadoRevisiones.KO
        )
      }
      return false
    },
    auditoria_uuid() {
      return this.auditoriaUUID ? this.auditoriaUUID : this.$route.query.auditoria_uuid
      return this.$route.query.auditoria_uuid
    },
    checklist_uuid() {
      return this.uuid ? this.uuid : this.$route.params.uuid
      return this.$route.params.uuid
    },
    tinyApiKey() {
      return this.$store.state.tinyApiKey
    },
    actualizarEvidencias() {
      return this.$store.state.gestor_documental.actualizarEvidencias
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    filtrarPreguntas() {
      if (
        !this.filtro_titulo &&
        (!this.filtro_niveles || this.filtro_niveles.length === 0) &&
        !this.filtro_incompletas
      ) {
        return this.sortPreguntas(this.item.preguntaChecklists) || []
      } else {
        let titulo = this.filtro_titulo ? this.filtro_titulo.toLowerCase() : null
        let niveles = this.filtro_niveles || null
        let incompleta = this.filtro_incompletas || false

        let preguntas = this.item.preguntaChecklists.filter((pregunta) => {
          let matchesTitulo = titulo
            ? pregunta.titulo.toLowerCase().includes(titulo) ||
              pregunta.codigo.toLowerCase().includes(titulo)
            : true
          let matchesNiveles = niveles
            ? niveles.some(
                (nivel) =>
                  pregunta.nivel && this.RemoveAccents(nivel).includes(pregunta.nivel.toLowerCase())
              )
            : true
          let matchesIncompleta = incompleta ? !pregunta.opcionRespuesta : true

          return matchesTitulo && matchesNiveles && matchesIncompleta
        })

        return this.sortPreguntas(preguntas) || []
      }
    },

    numberOfPages() {
      return Math.ceil(this.filtrarPreguntas.length / this.pagination.itemsPerPage)
    },

    auditoriaCerrada() {
      return (
        (this.auditoria && this.auditoria.estado?.toUpperCase() === this.estado.CERRADA) ||
        (this.auditoria && this.auditoria.estado?.toUpperCase() == 'REALIZADA')
      )
    },

    isCcl() {
      return this.$auth.user.empresa.nombre == 'Ccl'
    },

    isCoplaca() {
      return this.$auth.user.empresa?.codigo == 'coplaca'
    },

    isCerticar() {
      return this.$auth.user.empresa.codigo == 'certicar'
    },

    isAraporcei() {
      return this.$auth.user.empresa.codigo == 'araporcei'
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },

    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },

    labelV() {
      if (
        this.isCcl ||
        this.isCerticar ||
        this.isAraporcei ||
        this.isOceGlobal ||
        this.isQuesoManchego
      )
        return 'C'
      else return 'V'
    },

    labelF() {
      if (
        this.isCcl ||
        this.isCerticar ||
        this.isAraporcei ||
        this.isOceGlobal ||
        this.isQuesoManchego
      )
        return 'NC'
      else return 'F'
    },

    labelNA() {
      if (this.isQuesoManchego) return 'O'
      else return 'NA'
    },

    cumplimiento() {
      let cumplimiento = {
        count: {},
        failed: {},
        success: {},

        total: 0,
        answered: 0,
      }

      if (
        !this.item.preguntaChecklists ||
        this.item.preguntaChecklists.length === 0 ||
        this.ui.refresh == 0
      )
        return cumplimiento

      this.item.preguntaChecklists.forEach((pregunta) => {
        if (!pregunta.respuesta) return

        let nivel = pregunta.nivel?.toLowerCase()
        if (!nivel) {
          nivel = 'Sin nivel'
        }

        if (!cumplimiento.count[nivel]) cumplimiento.count[nivel] = 0
        if (!cumplimiento.failed[nivel]) cumplimiento.failed[nivel] = 0
        if (!cumplimiento.success[nivel]) cumplimiento.success[nivel] = 0

        cumplimiento.total++
        cumplimiento.count[nivel]++
        if (pregunta.valoracion) cumplimiento.answered++
        if (
          pregunta.valoracion ==
          (this.normalizarTexto('No Cumple') || this.normalizarTexto('no_cumple'))
        )
          cumplimiento.failed[nivel]++
        if (pregunta.valoracion == this.normalizarTexto('Cumple')) cumplimiento.success[nivel]++
      })

      return cumplimiento
    },
  },

  methods: {
    findColor(respuestaCodigo, preguntaUuid) {
      if (!respuestaCodigo) return
      let pregunta = this.item.preguntaChecklists.find((pregunta) => pregunta.uuid == preguntaUuid)
      if (!pregunta) return
      let opciones = pregunta.respuesta
      if (!opciones) return
      let opcionRespuesta = opciones.opcionRespuestas.find(
        (opcion) => opcion.codigo == respuestaCodigo
      )
      if (!opcionRespuesta) return
      let color = Colors[opcionRespuesta.valoracion]
      return color
    },
    htmlToPlain(html) {
      if (!html) return ''

      var tempDivElement = document.createElement('div')

      tempDivElement.innerHTML = html

      return tempDivElement.textContent || tempDivElement.innerText || ''
    },

    assignTemplate(question, field, template, index) {
      question[field] = this.templates[template]?.[index]?.texto || '-- sin plantilla --'
      this.$forceUpdate()
      this.savePregunta(question)
    },

    async getEstaticos() {
      const body = {
        codigo: ['nivel-de-checklist'],
      }

      const res = await this.$store.dispatch('estaticos/list', body)
      if (res.length == 0) return

      this.estaticos = res.data['hydra:member']

      this.estaticos.forEach((estatico) => {
        estatico.valor.forEach((valor) => {
          this.labels.niveles[valor.valor] = valor.texto || ''
        })
      })
    },

    sortPreguntas(preguntas) {
      if (preguntas == undefined) return []

      let sorted = []
      const codes = preguntas.map(({ codigoCalculo }) => codigoCalculo)
      const sort = codes.sort(this.sortAlphaNum)
      sort.forEach((codigoCalculo) => {
        let item = preguntas.filter((obj) => {
          return obj.codigoCalculo === codigoCalculo
        })[0]
        if (item.opcionRespuestaFinal) {
          item.isUpdatedFromPac = true
          item.previousInfo = {
            opcionRespuesta: item.opcionRespuesta.codigo,
            valoracion: item.valoracion,
            nivel: item.nivel,
          }
          item.opcionRespuesta = item.opcionRespuestaFinal
          item.valoracion = item.opcionRespuestaFinal.valoracion
          item.nivel = item.nivelFinal
        }
        sorted.push(item)
      })

      return sorted
    },

    sortAlphaNum(a, b) {
      return a.localeCompare(b, 'en', { numeric: true })
    },

    async checkOffline() {
      const offlineMode = localStorage.getItem('offlineMode')
      const offlineUuid = localStorage.getItem('offlineUuid')
      if (offlineMode && offlineUuid) {
        this.toggleOffline(offlineUuid)
      } else {
        this.getChecklist()
      }
    },
    async toggleOffline(uuid) {
      this.dialogModoOffline = false
      const auditoria_uuid = uuid ? uuid : this.auditoria_uuid
      await this.$store.commit('auditorias/OFFLINE_MODE', true)
      await this.$store.commit('auditorias/OFFLINE_UUID', auditoria_uuid)
      localStorage.setItem('offlineMode', true)
      localStorage.setItem('offlineUuid', auditoria_uuid)
      this.getChecklist()
    },
    confirmarModoOnline() {
      this.texto_confirmacion = '¿Está seguro de que desea volver al Modo Online?'
      this.dialogModoOnline = true
    },

    async getSnapshot() {
      if (!this.$nuxt.isOffline) return

      const xhr = await this.$store.dispatch('offline/getSnapshot', this.$route.path)
      if (xhr) {
        for (const key in xhr) {
          this[key] = xhr[key]
        }
      }
    },

    async getChecklist() {
      this.preguntas_relacion = {}

      const body = {
        auditoria_uuid: this.auditoria_uuid,
        checklist_uuid: this.checklist_uuid,
      }
      this.offlineMode
        ? await this.$store.dispatch('offlineMode/getChecklist', body)
        : await this.$store.dispatch('checklists/get', body).then(async (res) => {
            if (res) this.item = res
            await this.getSnapshot()

            if (this.item.inicio) this.item.inicio = this.formatHora(this.item.inicio)
            if (this.item.fin) this.item.fin = this.formatHora(this.item.fin)

            const auditoria = await this.$store.dispatch('auditorias/get', this.auditoria_uuid)
            if (auditoria) {
              this.auditoria = auditoria
            }
            await this.getEstaticos()
            await this.getEvidencias()
            this.assignDatesFromAuditoria()
            await this.getPlantillaBloques()
            await this.getTextTemplates()

            $nuxt.$emit('offline:loaded', {
              item: 'checklist',
              uuid: this.item.uuid,
              data: this.item,
            })

            // this.ui.puntosKey++
          })
    },

    async getAllChecklists() {
      this.loading = true
      const planificaciones = this.auditoria.planificaciones.map((e) => {
        const obj = {
          uuid: e.uuid,
          site: e.emplazamiento.uuid,
        }
        if (e.site.parent_uuid) obj.parent_site = e.site.parent_uuid
        return obj
      })

      let currentSite = ''
      for (let i = 0; i < planificaciones.length; i++) {
        const element = planificaciones[i]
        if (this.$route.params.uuid === element.uuid) {
          currentSite = element.site.parent_uuid || element.site.uuid
          break
        }
      }

      const checklists = []
      for (let i = 0; i < planificaciones.length; i++) {
        const element = planificaciones[i]
        if (currentSite === element.parent_uuid || currentSite === element.site)
          checklists.push(element.uuid)
      }

      for (let i = 0; i < checklists.length; i++) {
        const checklist_uuid = checklists[i]
        const body = {
          auditoria_uuid: this.auditoria_uuid,
          checklist_uuid: checklist_uuid,
        }
        const res = this.offlineMode
          ? await this.$store.dispatch('offlineMode/getChecklist', body)
          : await this.$store.dispatch('checklists/get', body)
        if (res) {
          const preguntas = res.preguntas.filter((e) => e.relacion)
          for (let k = 0; k < preguntas.length; k++) {
            const pregunta = preguntas[k]
            const relacion = pregunta.relacion
            const obj = {
              checklist: res,
              pregunta: pregunta,
            }
            delete obj.checklist.preguntas
            if (!this.preguntas_relacion[relacion]) this.preguntas_relacion[relacion] = []
            this.preguntas_relacion[relacion].push(obj)
          }
        }
      }
      this.loading = false
    },
    openPreguntas() {
      this.loading = true
      const openPanels = []
      for (let i = 0; i < this.item.preguntaChecklists.length; i++) {
        if (!this.openPanels.includes(i)) openPanels.push(i)
      }
      this.openPanels = openPanels
      this.loading = false
    },

    closePreguntas() {
      this.loading = true
      this.openPanels = []
      this.loading = false
    },

    async getEvidencias() {
      const submoduloRef = this.filtrarPreguntas.map((element) => element.uuid)
      const body = {
        route: this.$route,
        modulo: 'auditorias',
        moduloRef: this.auditoria_uuid,
        submodulo: 'checklist_pregunta',
        submoduloRef: submoduloRef,
      }
      this.offlineMode
        ? await this.$store.dispatch('offlineMode/listCustomDocument', body)
        : await this.$store.dispatch('gestor_documental/listCustom', body)
    },

    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    formatHora(date) {
      return date ? this.$moment(date).subtract('hour', 1).format('HH:mm') : ''
    },

    normalizarTexto(texto) {
      if (!texto) return ''
      return texto
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    },
    setRespuesta(pregunta, respuesta) {
      if (pregunta.opcionRespuesta && pregunta.opcionRespuesta['@id'] === respuesta['@id']) {
        return
      }

      pregunta.valoracion = respuesta.valoracion
      pregunta.opcionRespuesta = respuesta
      let params = {
        checklist_pregunta_uuid: pregunta.uuid,
        valoracion: respuesta.valoracion,
        opcionRespuesta: respuesta['@id'],
      }
      const valoracionNormalizada = this.normalizarTexto(respuesta.valoracion)
      const descripcionNormalizadaNC = this.normalizarTexto('no cumple')
      const descripcionNormalizadaNC_ = this.normalizarTexto('no_cumple')
      if (
        (pregunta.categoria && valoracionNormalizada === descripcionNormalizadaNC) ||
        valoracionNormalizada === descripcionNormalizadaNC_
      ) {
        let op = pregunta.categoria.opcionesCategoria.find(
          (opcion) => opcion.opcionRespuesta['@id'] === respuesta['@id']
        )
        params.nivel = op.nivel
        pregunta.nivel = op.nivel
      } else {
        params.textoNoConformidad = ''
        pregunta.textoNoConformidad = ''
        params.numero = ''
        pregunta.numero = ''
        params.nivel = ''
        pregunta.nivel = ''
      }
      this.$store.dispatch('auditorias/editRespuesta', params).then((res) => {
        pregunta = res
        this.$forceUpdate()
      })
    },
    async savePregunta(pregunta) {
      const params = {
        checklist_pregunta_uuid: pregunta.uuid,
        observacion: pregunta.observacion,
        textoNoConformidad: pregunta.textoNoConformidad,
        respuesta: pregunta.respuesta['@id'],
        nivel: pregunta.nivel,
        numero: pregunta.numero,
        tipologia: pregunta.tipologia,
        instalacion: pregunta.instalacion,
        comentarios: pregunta.comentarios,
        requisito_afectado: pregunta.requisito_afectado,
        valoracion: pregunta.valoracion,
      }
      await this.$store.dispatch('auditorias/editRespuesta', params)
    },

    async saveChecklist() {
      let inicio = this.item.inicio
      let fin = this.item.fin
      if (inicio === '') inicio = null
      if (fin === '') fin = null

      await this.$store.dispatch('auditorias/saveChecklist', {
        auditoria: this.auditoria['@id'],
        checklist: this.item['@id'],
        checklist_uuid: this.item.uuid,
        observacion: this.item.observacion,
        fecha: this.$moment(this.item.fecha).format('YYYY-MM-DD'),
        inicio: inicio,
        fin: fin,
      })

      this.$forceUpdate()
    },

    async setRespuestaPreguntasRelacion({ relacion, value }) {
      this.loading = true
      const preguntas = this.preguntas_relacion[relacion]
      if (preguntas) {
        for (let i = 0; i < preguntas.length; i++) {
          const element = preguntas[i]
          const pregunta = element.pregunta
          const checklist_uuid = element.checklist.uuid
          await this.setRespuesta(pregunta, value, checklist_uuid)
        }
      }
      this.loading = false
    },
    closeDialogAddEvidencia() {
      this.dialogAddEvidencia = false
      this.evidencia = {
        uuid: '',
        submoduloRef: '',
        titulo: null,
        tipo: { name: 'PDF', accept: 'application/pdf' },
        file: null,
      }
      this.$refs.form.resetValidation()
    },
    async adjuntarEvidencia(pregunta_uuid) {
      if (pregunta_uuid) {
        this.evidencia.submoduloRef = pregunta_uuid
        this.dialogAddEvidencia = true
      } else if (this.$refs.form.validate()) {
        const body = {
          uuid: this.$uuid.v4(),
          modulo: 'auditorias',
          moduloRef: this.auditoria_uuid,
          submodulo: 'checklist_pregunta',
          submoduloRef: this.evidencia.submoduloRef,
          documento: this.evidencia,
        }

        await this.$store.commit('gestor_documental/ADD_CUSTOM', body)

        this.offlineMode
          ? await this.$store.dispatch('offlineMode/createCustomDocument', body)
          : await this.$store.dispatch('gestor_documental/createCustom', body)

        this.closeDialogAddEvidencia()

        this.getEvidencias()
      }
    },

    addEvidencia(preguntaUUID) {
      let config = {
        modulo: 'auditorias',
        submodulo: 'checklist_pregunta',
        payload: preguntaUUID,
      }

      config.moduloRef = this.auditoria.uuid
      config.submoduloRef = preguntaUUID

      config.cliente = this.auditoria.cliente.uuid
      config.cliente_name = this.auditoria.cliente.nombreCompleto

      this.$nuxt.$emit('documents:open', config)
    },

    RemoveAccents(str) {
      let accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
      let accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
      str = str.split('')
      let strLen = str.length
      let i, x
      for (i = 0; i < strLen; i++) {
        if ((x = accents.indexOf(str[i])) != -1) {
          str[i] = accentsOut[x]
        }
      }
      return str.join('').toLowerCase()
    },

    formatDataToExport(data) {
      let preguntas = JSON.parse(JSON.stringify(data))
      preguntas.forEach((e) => {
        if (e.respuesta == 'V') e.respuesta = this.labelV
        if (e.respuesta == 'F') e.respuesta = this.labelF
        if (e.respuesta == 'NA') e.respuesta = this.labelNA
      })

      return preguntas
    },

    excelExport() {
      const columnDefs = this.columnDefs
      let data = this.filtrarPreguntas.filter((e) => !e.solo_titulo)
      let formatted = this.formatDataToExport(data)
      const filename = this.item.titulo
      const ws_name = 'Preguntas'
      UTIL.exportFile(this, filename, ws_name, columnDefs, formatted)
    },

    confirmarImportFile() {
      this.texto_confirmacion = '¿Está seguro de que desea importar las preguntas?'
      this.dialogImport = true
    },
    async importFile(event) {
      this.loading = true
      const allowEmptyCells = true
      const grid = null
      const columnDefs = this.columnDefs

      const body = {
        empresa: this.$auth.user.empresa.uuid,
        alcance: this.auditoria.alcance.uuid,
        codigos: ['checklist_niveles'],
      }

      const res = await this.$store.dispatch('estaticos/list', body)

      const validations = [
        {
          header: 'nivel',
          error: 'no es un nivel valido.',
          items: res[0].valor.map((e) => e.texto),
        },
        {
          header: 'respuesta',
          error: 'no es una respuesta valida.',
          items: ['V', 'F', 'NA'],
        },
      ]
      await UTIL.importFile(
        this,
        event,
        grid,
        columnDefs,
        this.onImportEnd.bind(this),
        validations,
        allowEmptyCells
      )
      this.$refs.inputFile.value = ''
    },

    async onImportEnd(data) {
      let error = false
      const updated_preguntas = []
      const preguntas = this.item.preguntas.filter((e) => !e.solo_titulo)
      for (let i = 0; i < data.length; i++) {
        const pregunta = data[i]
        const index = preguntas.findIndex((e) => e.codigo == pregunta.codigo)
        if (index > -1) {
          const old_pregunta = Object.assign({}, preguntas[index])
          const old_pregunta_keys = Object.keys(old_pregunta)
          const new_pregunta = {}
          for (let k = 0; k < old_pregunta_keys.length; k++) {
            const key = old_pregunta_keys[k]
            new_pregunta[key] = key == 'uuid' ? old_pregunta[key] : pregunta[key] || ''
          }
          updated_preguntas.push(new_pregunta)
        } else {
          error = true
          const alert = {
            text: `El código de pregunta ${pregunta.codigo} no existe.`,
            color: 'error',
            timeout: 4000,
          }
          this.$store.commit('notification/show', alert)
          break
        }
      }
      if (!error) {
        for (let i = 0; i < updated_preguntas.length; i++) {
          const pregunta = updated_preguntas[i]
          await this.savePregunta(pregunta)
        }
      }
      await this.getChecklist()
      this.loading = false
    },

    assignDatesFromAuditoria() {
      let update = false
      this.$moment.locale('es')

      if (this.auditoria.desde && !this.item.fecha) {
        update = true
        this.item.fecha = this.auditoria.desde
      }

      if (this.auditoria.desde_inicio && !this.item.inicio) {
        update = true
        this.item.inicio = this.$moment(this.auditoria.desde_inicio).utc().format('HH:mm')
      }

      if (this.auditoria.desde_fin && !this.item.fin) {
        update = true
        this.item.fin = this.$moment(this.auditoria.desde_fin).utc().format('HH:mm')
      }

      if (update) {
      }
    },

    async getTextTemplates() {
      const body = {
        alcance: this.auditoria?.alcance?.uuid,
        empresa: this.$auth.user.empresa.uuid,
        codigos: ['plantillas-checklist'],
      }

      const res = await this.$store.dispatch('estaticos/list', body)
      if (res && res.length > 0) {
        let templates = {}
        let items = res[0].valor

        for (const element of items) {
          if (templates[element.valor] == undefined) templates[element.valor] = []
          templates[element.valor].push(element)
        }

        this.templates = templates
      }
    },

    async getDocuments() {
      await this.getEvidencias()
      let docs = this.$store.getters['gestor_documental/documentos']
      this.evidencias = []

      for (const doc of docs) {
        let ref = doc.submoduloRef
        if (this.evidencias[ref] == undefined) {
          this.evidencias[ref] = []
        }
        this.evidencias[ref].push(doc)
      }

      this.$forceUpdate()
    },

    async getPlantillaBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const xhr = await this.$store.dispatch('plantillas-bloques/list', {
        modulo: 'checklists',
        subalcance: this.auditoria.subalcance.uuid,
        ref: uuid,
      })

      if (xhr) {
        this.db.bloques = xhr
      }
    },

    async getBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const xhr = await this.$store.dispatch('bloques/get', {
        modulo: 'checklists',
        moduloRef: uuid,
      })

      this.db.misBloques = []
      if (xhr) this.db.misBloques = xhr

      this.ui.refresh = Date.now()
    },

    preguntaHasBloque(pregunta, bloque) {
      let bloques = pregunta.bloques.map((b) => b.uuid)
      if (bloques.includes(bloque)) return true
      return false
    },

    getBlocksForUUID(uuid, submoduloRef) {
      let mine = this.db.misBloques.filter(
        (block) => block.plantilla?.uuid == uuid && block.submoduloRef == submoduloRef
      )
      if (mine.length > 0) return { ...mine }

      let template = this.db.bloques.filter((block) => block.uuid == uuid)
      return JSON.parse(JSON.stringify(template))
    },

    updateBlock(key, block, append = false) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doUpdate(block)
      block.isNew = false
    },

    resetBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doReset(block)

      block.isNew = true
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      block.moduloRef = uuid
    },

    cloneBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doClone(block)
    },

    async download(doc) {
      await this.$store.dispatch('documentos/download', doc)
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        submodule: 'checklists',
        return: 'all',
      })
    },

    async init() {
      this.checkRoles()
      await this.getDocuments()
      await this.getBloques()

      this.ui.ready = true
    },
  },

  mounted() {
    this.init()

    this.$nuxt.$on('bloques:refresh', (payload) => {
      this.getBloques()
    })

    this.$nuxt.$on('documents:uploaded', (body) => {
      this.getDocuments()
    })

    this.$nuxt.$on('offline:snapshot', (payload) => {
      this.$store.commit('offline/snapshotPage', {
        item: this.item,
      })
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('bloques:refresh')
    this.$nuxt.$off('offline:snapshot')
    this.$nuxt.$off('documents:uploaded')
  },
}
</script>
