/**
 * @project: certiapp-nuxt
 * @file:    \models\labels.js
 * @desc:    Labels or text strings for the application
 * -------------------------------------------
 * Created Date: 21st September 2022
 * Last Modified: Thu Dec 15 2022
 **/

export default {
  def: {
    roles: {
      revisor: 'Revisor',
    },

    menu: {
      etiquetas: 'Etiquetas',
    },

    auditorias: {
      valorar: {
        satisfactoria: 'Satisfactoria',
        no_satisfactoria: 'No satisfactoria',
      },
    },

    etiquetas: {
      etiqueta: 'etiqueta',
      etiquetas: 'etiquetas',
    },
  },

  quesomanchego: {
    roles: {
      revisor: 'Director de Certificación',
    },

    menu: {
      etiquetas: 'Contraetiquetas',
    },

    auditorias: {
      valorar: {
        satisfactoria: 'Adecuado',
        no_satisfactoria: 'No adecuado',
      },
    },

    etiquetas: {
      etiqueta: 'contraetiqueta',
      etiquetas: 'contraetiquetas',
    },
  },

  //+-------------------------------------------------
  // _()
  // Returns the label for the given key
  // -----
  // Created on Wed Sep 21 2022
  //+-------------------------------------------------
  _(key) {
    if (!key) return key

    let empresa = 'def'
    if ($nuxt) empresa = $nuxt?.$auth?.user?.empresa?.codigo

    let obj = { ...this.def }
    let _obj = { ...this[empresa] }
    let keys = key.split('.')

    // console.warn(key, empresa)

    for (const _key of keys) {
      // console.warn(_key, JSON.stringify(obj))

      if (_key && _obj.hasOwnProperty(_key)) {
        _obj = _obj[_key]
        if (typeof _obj === 'string') return _obj
      } else if (_key && obj.hasOwnProperty(_key)) {
        obj = obj[_key]
        if (typeof obj === 'string') return obj
      } else {
        return key
      }
    }

    console.warn(obj)
    return obj
  },
}
