<template>
  <div>

    <loading v-model="$store.getters.loading" text="Espere por favor ..."/>

    <!--    OVERLAY-->
    <v-overlay :value="overlay" class="text-center">
      <h4>Expediente desactivado
        <v-icon>mdi-alert-octagon</v-icon>
      </h4>
      <br>
      <v-btn color="success" @click="updateActivarDesactivar(true)">
        ACTIVAR EXPEDIENTE
      </v-btn>

    </v-overlay>

    <v-tabs v-model="tab">
      <v-tab :href="'#solicitud'">Solicitud</v-tab>
      <v-tab :href="'#auditorias'">Auditorias</v-tab>
      <v-tab :href="'#revisiones'">Revisiones</v-tab>
      <v-tab :href="'#dictamenes'">Dictamenes</v-tab>
      <v-tab :href="'#certificados'">Certificados</v-tab>
      <v-tab :href="'#comunicaciones'">Comunicaciones</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-tab-item :value="'solicitud'">
        <div v-show="tab === 'solicitud'">
          <v-row>
            <v-col cols="12">
              <!-- Year -->
              <v-tabs v-model="iteracionYearSelected">
                <v-tab v-for="year in iteracionYears" :key="year">{{ year }}</v-tab>
              </v-tabs>
              <!-- Iteraciones -->
              <v-card>
                <v-card-text>
                  <v-tabs-items v-model="iteracionYearSelected">

                    <v-tab-item v-for="year in iteracionYears" :key="year">
                      <v-slide-group show-arrows>
                        <v-slide-item v-for="(iteracion,i) in item.iteraciones" :key="i" class="mr-5">
                          <v-btn
                            v-if="iteracionMatchYear(i, year)"
                            :class="{'primary':iteracion.uuid == iteracionSelected.uuid}" depressed v-model="iteracionSelected"
                            @click="updateDatosIteracion(iteracion)"
                            rounded>
                            <v-icon small class="mr-2">mdi-calendar</v-icon>
                            {{iteracion.codigo}}
                          </v-btn>
                        </v-slide-item>
                      </v-slide-group>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="8">
              <v-row no-gutters>
                <v-col cols="2">
                  <v-switch
                    color="success"
                    v-model="item.activo"
                    label="Activo"
                    @change="updateActivarDesactivar"
                  />
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pl-0 info-expediente">Código</v-subheader>
                  <h4 v-text="item.codigo"></h4>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pl-0 info-expediente">Estado</v-subheader>
                  <h4 v-if="item.estado" v-text="item.estado" style="float: left;" class="mr-1"></h4>
                  <v-icon color="green" small>mdi-circle</v-icon>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="pl-0 info-expediente">Cliente</v-subheader>
                  <h4 v-if="item.cliente" v-text="item.cliente.nombre"></h4>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="pl-0 info-expediente">Alcance</v-subheader>
                  <h4 v-if="item.alcance" v-text="item.alcance.codigo + ' - ' + item.alcance.nombre"></h4>
                </v-col>
              </v-row>
            </v-col>

            <!-- General Actions -->
            <v-col cols="4">
              <v-row no-gutters justify="end">
                <v-col cols="12" class="text-right">
                  <!-- Discard -->
                  <v-btn tile dark color="error" @click="getOne()" v-if="changesTable.length > 0">
                    <v-icon class="mr-1" size="20">mdi-delete</v-icon>
                    Descartar
                  </v-btn>
                  <!-- Crear auditoria -->
                  <v-btn tile dark color="info" @click="dialogVisitaAuditoria = true">
                    <v-icon class="mr-1" size="20">mdi-file</v-icon>
                    Crear auditoria
                  </v-btn>
                  <!-- Save -->
                  <v-btn tile dark color="warning" @click="saveChangesModal()">
                    <v-icon class="mr-1" size="20">mdi-file</v-icon>
                    Guardar
                  </v-btn>
                </v-col>
                <!-- Alerts -->
                <v-expand-transition>
                  <v-col cols="12" class="pt-4 text-right" v-if="changesTable.length === 0 && noChanges === true">
                  <span class="px-3 py-2 warning--text" style="background-color: #F7E5E6; border-radius: 4px">
                    <v-icon color="warning" class="mr-4 pb-1">mdi-alert</v-icon>
                    No existen cambios a guardar
                  </span>
                  </v-col>
                </v-expand-transition>
                <!-- -->
                <v-expand-transition>
                  <v-col cols="12" class="pt-4 text-right" v-if="changesTable.length > 0">
                  <span class="px-3 py-2 error--text" style="background-color: #F7E5E6; border-radius: 4px">
                    <v-icon color="error" class="mr-4 pb-1">mdi-alert</v-icon>
                    Existen cambios sin guardar
                  </span>
                  </v-col>
                </v-expand-transition>
              </v-row>
            </v-col>

            <!-- Expediente Variables -->
            <v-col cols="12">
              <expediente-variables
                modulo="expedientes"
                :grupo_alcance="item.grupo_alcance.codigo"
                :key="component"
              />
            </v-col>

          </v-row>

          <v-row>
            <!-- Left Menu -->
            <v-col cols="2">

              <v-expansion-panels focusable style="z-index: 0" v-model="panelLeft">
                <!-- Menu Ficheros -->
                <v-expansion-panel v-for="(submenu, title) in menuFicheros" :key="title">
                  <v-expansion-panel-header>{{title}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item-group v-model="submenuItem" color="primary">
                        <v-list-item v-for="(submenu, index) in submenu" :key="index">
                          <v-list-item-content @click="updateGrid(submenu)">
                            <v-list-item-title v-text="submenu"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Menu -->
                <v-expansion-panel v-for="(uuid, title) in menu" :key="title">
                  <v-expansion-panel-header>{{title}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item-group v-model="submenuItem" color="primary">
                        <v-list-item>
                          <v-list-item-content @click="menuGoTo(title, uuid)">
                            <v-list-item-title>Ver {{ title }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </v-col>

            <!-- Ag Grid Table -->
            <v-col cols="10">
              <v-card>
                <v-card-text>
                  <v-row>

                    <v-col cols="10">
                      <!-- Lot Actions Button Menu -->
                      <v-menu v-if="filteredActions.length > 0">
                        <template v-slot:activator="{ on }">
                          <v-btn tile small dark v-on="on">
                            <v-icon class="mr-1" size="20">mdi-menu</v-icon>
                            Acciones múltiples
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-for="item in filteredActions"
                            :key="item.id"
                            @click="controlActionsModal(item.action)"
                          >
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <!-- No Selection Alert -->
                      <v-expand-transition>
                      <span class="px-3 py-2 ml-2 error--text" style="background-color: #F7E5E6; border-radius: 4px" v-if="noSelection === true">
                        <v-icon color="error" class="mr-4 pb-1">mdi-alert</v-icon>
                        Debes seleccionar un elemento
                      </span>
                      </v-expand-transition>
                    </v-col>

                    <!-- BÚSQUEDA RÁPIDA -->
                    <v-col cols="10">
                      <v-text-field
                        @keyup="updateFilter"
                        @input="updateFilter"
                        v-model="search" append-icon="search"
                        label="Búsqueda rápida" single-line hide-details clearable
                        class="pa-0 ma-0"></v-text-field>
                    </v-col>

                    <!-- EXPORTAR -->
                    <v-col cols="2" class="text-right">
                      <v-btn tile small dark color="grey darken-2" @click="excelExport">
                        <v-icon class="mr-1" size="20">mdi-file-excel</v-icon>
                        exportar
                      </v-btn>
                    </v-col>

                  </v-row>

                  <v-row>
                    <v-col>

                      <div style="height: 100%">
                        <div class="ag-container">

                          <ag-grid-vue
                            style="width: 100%; height: 57.5vh; border: 1px solid #eee"
                            :suppressCellSelection="true"
                            :sideBar="sideBar"
                            :animateRows="true"
                            class="ag-theme-material ag-grid"
                            rowGroupPanelShow="always"
                            :pagination="true"
                            :paginationAutoPageSize="true"
                            @grid-ready="onGridReady"
                            :quickFilter="search"
                            :suppressPropertyNamesCheck="true"
                            :rowSelection="grid.rowSelection"
                            :columnDefs="grid.columnDefs"
                            :rowData="grid.rowData"
                            :frameworkComponents="frameworkComponents"
                            :excelStyles="excelStyles"
                            @action="controlActionsModal($event)"
                          ></ag-grid-vue>

                        </div>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>
        </div>
      </v-tab-item>

      <v-tab-item :value="'auditorias'">
        <auditorias-listado :uuid="item.uuid" v-if="tab === 'auditorias' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'revisiones'">
        <revisiones-listado :uuid="item.uuid" v-if="tab === 'revisiones' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'dictamenes'">
        <dictamenes-listado :uuid="item.uuid" v-if="tab === 'dictamenes' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'certificados'">
        <dictamenes-listado :uuid="item.uuid" v-if="tab === 'certificados' && item && iteracionSelected"/>
      </v-tab-item>

      <v-tab-item :value="'comunicaciones'">
        <comunicaciones-externas-listado :expediente="item" :iteracionSelected="iteracionSelected" v-if="tab === 'comunicaciones' && item && iteracionSelected"/>
      </v-tab-item>

    </v-tabs-items>


    <!-- Actions Modal -->
    <actions-modal
      v-model="dialog"
      :component="actionsModalComponent"
      :title="actionsModalTitle"
      :action="selectedAction"
      :selection="selectionRows"
      :headers="selectionHeaders"
      :rowIndex="rowIndex"
      :tipo_fichero="selectedTipoFichero"
      :grupo_alcance_uuid="selectedGrupoAlcance"
      @close="closeActionsModal()"
      @changes="actionChanges($event)"
      @discard="getOne()"
      @save="saveActionChanges()"
    />

    <!-- Create Auditoria Dialog Modal -->
    <create-auditoria-modal v-if="dialogVisitaAuditoria" :cliente="item.cliente" :alcance="item.alcance" v-model="dialogVisitaAuditoria" @save-auditoria="saveAuditoria"/>
  </div>
</template>

<script>

  import SelectAlcance from '~/components/forms/SelectAlcances'
  import SelectClientes from '~/components/forms/SelectClientes'
  import SelectProvincia from '~/components/forms/SelectProvincia'
  import FloatingButtonMenu from "~/components/FloatingButtonMenu"
  import AgDatatableSelectable from "~/components/AgDatatableSelectable";
  import Loading from "~/components/Loading";
  import ExpedienteVariables from "~/components/customVariables/CustomVariables";
  import ActionsModal from "~/components/expedientes/ActionsModal";
  import TiposFicheroColumnas from "~/models/tipos_ficheros_columnas"
  import Confirmation from "~/components/Confirmation";
  import CreateAuditoriaModal from "~/components/auditorias/CreateAuditoriaModal";
  import AuditoriasListado from "~/pages/auditorias"
  import RevisionesListado from "~/pages/revisiones/index"
  import DictamenesListado from "~/pages/dictamenes/index"
  import CertificadosListado from "~/pages/certificados/index"
  import ComunicacionesExternasListado from "~/pages/comunicaciones_externas/index"

  export default {
    components: {
      CreateAuditoriaModal,
      Confirmation,
      Loading,
      AgDatatableSelectable,
      SelectAlcance,
      SelectClientes,
      SelectProvincia,
      FloatingButtonMenu,
      ExpedienteVariables,
      ActionsModal,
      AuditoriasListado,
      RevisionesListado,
      DictamenesListado,
      CertificadosListado,
      ComunicacionesExternasListado
    },
    data: () => ({
      overlay: false,
      dialogVisitaAuditoria: false,
      component: 0,
      search: '',
      noChanges: false,
      noSelection: false,
      date1: null,
      date2: null,
      date3: null,
      date4: null,
      menu1: null,
      menu2: null,
      menu3: null,
      menu4: null,
      dialog: false,
      selectedAction: '',
      selectedGrupoAlcance: '',
      selectedTipoFichero: '',
      selectionRows: [],
      selectionHeaders: [],
      rowIndex: [],
      globalgapActions: [
        {name: 'Baja', action: 'baja', tipo_fichero: ['CENTRALES', 'PRODUCTORES']},
        {name: 'Cambio superficie', action: 'cambio_superficie', tipo_fichero: ['PRODUCTOS']},
        {name: 'Cambio cultivo', action: 'cambio_cultivo', tipo_fichero: ['PRODUCTOS']},
        {name: 'Cambio GGN', action: 'cambio_ggn', tipo_fichero: ['PRODUCTORES']},
        {name: 'Cambio GLN', action: 'cambio_gln', tipo_fichero: ['PRODUCTORES']},
        {name: 'Cambio NIF', action: 'cambio_nif', tipo_fichero: ['PRODUCTORES']},
        {name: 'Eliminar', action: 'eliminar', tipo_fichero: ['PRODUCTORES', 'PRODUCTOS']}
      ],
      submenuItem: 0,
      grid: {api: null, rowSelection: 'multiple', columnDefs: [], rowData: []},
      frameworkComponents: null,
      excelStyles: null,
      TiposFicheroColumnas: TiposFicheroColumnas,
      tipos_ficheros: null,
      sideBar: {toolPanels: ["filters", "columns"]},
      item: {
        iteraciones: [], grupo_alcance: {}
      },
      iteracionYears: [],
      iteracionYearSelected: {},
      iteracionSelected: {},
      menuSelected: {},
      panelLeft: 0,
      menu: {},
      menuFicheros:
        {
          // 'Solicitudes': [],
          // 'Auditoría': ['No conformidades', 'PACS', 'Informes'],
          // 'Certificado': ['Comisiones', 'Certificado']
        }
    }),
    beforeMount() {
      this.frameworkComponents = {
        'BooleanCellEditor': 'BooleanCellEditor',
        'AutocompleteCellEditor': 'AutocompleteCellEditor',
        'SelectCellEditor': 'SelectCellEditor',
        'TextCellEditor': 'TextCellEditor',
        'DateCellEditor': 'DateCellEditor'
      }
      this.excelStyles = [
        {
          id: "default",
          dataType: "string"
        }
      ]
    },
    mounted() {
      this.getOne()
    },
    watch: {
      tab (val) {
        if (val === 'solicitud') this.getOne()
      }
    },
    computed: {
      tab: {
        get () {
          return this.$store.state.expedientes.tab
        },
        set (val) {
          this.$store.commit('expedientes/SET_TAB', val)
        }
      },
      changesTable() {
        return this.$store.state.expedientes.changesTable
      },
      filteredActions() {
        const filtered = this.globalgapActions.filter(element => {
          return element.tipo_fichero.includes(this.selectedTipoFichero)
        })
        return filtered.length > 0 ? filtered : []
      },
      actionsModalComponent() {
        if (this.selectedAction === 'baja' || this.selectedAction === 'eliminar') return ''
        if (this.selectedAction.includes('cambio')) return 'cambio'
        return this.selectedAction
      },
      actionsModalTitle() {
        if (this.selectedAction) {
          if (this.selectedAction === 'baja') return '¿Está seguro de que desea dar de baja?'
          if (this.selectedAction === 'eliminar') return '¿Está seguro de que desea eliminar?'
          if (this.selectedAction === 'cambio_ultivo') return '¿Está seguro de que desea cambiar el cultivo?'
          if (this.selectedAction === 'cambio_superficie') return '¿Está seguro de que desea cambiar la superficie?'
          if (this.selectedAction === 'save') return 'Guardar modificaciones'
        } else {
          return ''
        }
      },
    },
    methods: {
      async updateActivarDesactivar(activate) {
        if (activate) {
          await this.$store.dispatch('expedientes/activar', this.item.uuid);
          this.item.activo = true;
          this.overlay = false;
        } else {
          const res = await this.$store.dispatch('expedientes/desactivar', this.item.uuid);
          if (res) {
            // this.item.activo = false;
            // this.overlay = true;
            this.$router.push('/expedientes')
            this.$store.commit("notification/show", {
              text: 'Expediente desactivado correctamente',
              color: 'success'
            })
          }
        }
      },
      async saveAuditoria(auditoria) {
        // await this.$store.dispatch(`expedientes/createAuditoria`, {iteracion_uuid: this.iteracionSelected.uuid, expediente_uuid: this.item.uuid, auditoria: auditoria})
        this.dialogVisitaAuditoria = false;
        this.$store.commit("notification/show", {text: 'Auditoria creada correctamente', color: 'success'})
        this.getOne();
      },
      formatDate(date) {
        return date ? this.$moment(date).format('DD-MM-YYYY') : ''
      },
      controlActionsModal(action) {
        this.selectionRows = this.grid.api.getSelectedRows()
        this.grid.api.forEachNode(node => {
          if (node.isSelected()) this.rowIndex.push(node.rowIndex)
        })
        // Check rows selected
        if (this.selectionRows.length > 0) {
          this.selectedAction = action
          this.selectedGrupoAlcance = this.item.grupo_alcance.uuid
          this.dialog = true
        } else {
          this.noSelection = true
          setTimeout(() => {
            this.noSelection = false
          }, 10000)
        }
      },
      closeActionsModal(changes) {
        if (changes) {
          this.$store.commit('expedientes/updateChangesActions', changes.changes)
          this.$store.commit('expedientes/updateChangesTable', changes.table)
        }
        this.dialog = false
        this.selectedAction = ''
        this.selectionRows = []
        this.rowIndex = []
      },
      actionChanges(event) {
        const action = event.action
        const newIteration = event.newIteration
        const field = this.fieldName(action)
        const commonActions = ['baja', 'eliminar']
        let newValue = event.newValue
        if (commonActions.includes(action)) newValue = action.toUpperCase()
        let rowsID = []
        let changes = {
          table: [],
          changes: {}
        }
        for (let i = 0; i < this.selectionRows.length; i++) {
          // Get rows uuids
          let uuidRow = this.selectionRows[i].uuid
          rowsID.push(uuidRow)
          // Change value on grid
          if (action !== 'eliminar') {
            this.grid.api.forEachNode((rowNode, index) => {
              if (rowNode.data.uuid === uuidRow) rowNode.setDataValue(field, newValue)
            })
          }
          // Push changes
          changes.table.push({
            newIteration: newIteration,
            tipo_fichero: this.selectedTipoFichero,
            row: this.rowIndex[i],
            field: field,
            action: action,
            value: newValue
          })
        }
        changes.changes = {
          newIteration: newIteration,
          tipo_fichero: this.selectedTipoFichero,
          iteracion: this.iteracionSelected.uuid,
          uuids: rowsID,
          action: 'agrocolor.' + this.selectedTipoFichero.toLowerCase() + '.' + action,
          value: newValue
        }
        this.closeActionsModal(changes)
      },
      gridChanges(params) {
        const value = params.newValue
        const field = params.colDef.field
        const data = params.data
        const codigo = params.colDef.validacion.codigo
        const type = params.colDef.validacion.type
        let changes = {
          table: [{
            uuid: data.uuid,
            row: params.node.rowIndex + 1,
            tipo_fichero: this.selectedTipoFichero,
            action: 'NUEVO VALOR',
            field: field,
            value: value,
            codigo: codigo,
            type: type
          }],
          changes: {
            uuid: data.uuid,
            tipo_fichero: this.selectedTipoFichero,
            iteracion: this.iteracionSelected.uuid,
            row: {
              field: field,
              value: value
            }
          }
        }
        if (params.colDef.editable) {
          this.$store.commit('expedientes/updateChangesTable', changes.table)
          this.$store.commit('expedientes/updateChangesGrid', changes.changes)
        }
        data[field] = value
        // Limpiar LOCALIDAD y PROVINCIA si se cambia PAIS
        if (
          params.colDef.validacion.codigo === 'PAIS'
          && params.oldValue
          && params.oldValue.uuid !== params.newValue.uuid
        ) this.cleanProvincia(params), this.cleanLocalidad(params)
        // Limpiar LOCALIDAD si se cambia la PROVINCIA
        if (
          params.colDef.validacion.codigo === 'PROVINCIA'
          && params.oldValue
          && params.oldValue.uuid !== params.newValue.uuid
        ) this.cleanLocalidad(params)
      },
      cleanProvincia(params) {
        const field = 'Provincia'
        const data = params.data
        const codigo = params.colDef.validacion.codigo
        const type = params.colDef.validacion.type
        let changes = {
          table: [{
            uuid: data.uuid,
            row: params.node.rowIndex + 1,
            tipo_fichero: this.selectedTipoFichero,
            action: 'NUEVO VALOR',
            field: field,
            value: '',
            codigo: codigo,
            type: type
          }],
          changes: {
            uuid: data.uuid,
            tipo_fichero: this.selectedTipoFichero,
            row: {
              field: field,
              value: ''
            }
          }
        }
        if (this.noEditableCell(field)) {
          this.$store.commit('expedientes/updateChangesTable', changes.table)
          this.$store.commit('expedientes/updateChangesGrid', changes.changes)
        }
        // Limpiar LOCALIDAD y PROVINCIA si se cambia PAIS
        if (params.colDef.validacion.codigo === 'PAIS' && params.oldValue.uuid !== params.newValue.uuid) params.data['Localidad'] = params.data['Provincia'] = ''
        // Limpiar LOCALIDAD si se cambia la PROVINCIA
        if (params.colDef.validacion.codigo === 'PROVINCIA' && params.oldValue.uuid !== params.newValue.uuid) params.data['Localidad'] = ''
      },
      cleanLocalidad(params) {
        const field = 'Localidad'
        const data = params.data
        const codigo = params.colDef.validacion.codigo
        const type = params.colDef.validacion.type
        let changes = {
          table: [{
            uuid: data.uuid,
            row: params.node.rowIndex + 1,
            tipo_fichero: this.selectedTipoFichero,
            action: 'NUEVO VALOR',
            field: field,
            value: '',
            codigo: codigo,
            type: type
          }],
          changes: {
            uuid: data.uuid,
            tipo_fichero: this.selectedTipoFichero,
            row: {
              field: field,
              value: ''
            }
          }
        }
        if (this.noEditableCell(field)) {
          this.$store.commit('expedientes/updateChangesTable', changes.table)
          this.$store.commit('expedientes/updateChangesGrid', changes.changes)
        }
        // Limpiar LOCALIDAD y PROVINCIA si se cambia PAIS
        if (params.colDef.validacion.codigo === 'PAIS' && params.oldValue.uuid !== params.newValue.uuid) params.data['Localidad'] = params.data['Provincia'] = ''
        // Limpiar LOCALIDAD si se cambia la PROVINCIA
        if (params.colDef.validacion.codigo === 'PROVINCIA' && params.oldValue.uuid !== params.newValue.uuid) params.data['Localidad'] = ''
      },
      fieldName(action) {
        if (action === 'baja') return 'Tramite'
        if (action === 'eliminar') return 'Fila'
        if (action === 'cambio_cultivo') return 'Cultivo'
        if (action === 'cambio_superficie') return 'Superficie'
        if (action === 'cambio_gln') return 'Gln'
        if (action === 'cambio_nif') return 'Nif_cif'
        return ''
      },
      saveChangesModal() {
        if (this.changesTable.length > 0) {
          this.selectedAction = 'save'
          this.dialog = true
        } else {
          this.noChanges = true
          setTimeout(() => {
            this.noChanges = false
          }, 10000)
        }
      },
      async saveActionChanges() {
        this.dialog = false
        const res = await this.$store.dispatch('expedientes/saveChanges', this.$auth.user.empresa.codigo)
        if (res && !res.error) {
          this.$store.commit("notification/show", { text: 'Expediente guardado correctamente', color: 'success', setTimeout: 3000 });
        }
        this.getOne()
      },
      iteracionMatchYear(i, year) {
        return this.$moment(this.item.iteraciones[i].codigo, "DD/MM/YYYY").format("YYYY") === year
      },
      onGridReady(params) {
        this.grid.api = params.api
        this.grid.api.showLoadingOverlay()
        this.grid.columnApi = params.columnApi
        this.grid.api.sizeColumnsToFit()
        params.api.addEventListener('cellClicked', this.onCellClick)
      },
      // Edición de celdas al pinchar
      onCellClick(params) {
        params.api.startEditingCell({rowIndex: params.rowIndex, colKey: params.column.getId()})
      },
      async getOne() {
        this.submenuItem = 0
        this.$store.commit('expedientes/resetQueueChanges')
        const res = await this.$store.dispatch("expedientes/expediente", this.$route.params.uuid)
        if (res) {
          this.item = res
          if (this.item.activo === false) this.overlay = true;
          let reverse = this.item.iteraciones.reverse()
          for (let i = 0; i < reverse.length; i++) {
            let date = this.$moment(reverse[i].codigo, "DD/MM/YYYY").format("YYYY")
            if (!this.iteracionYears.includes(date)) this.iteracionYears.push(date)
          }
          this.iteracionYearSelected = this.$moment(reverse[0].codigo, "DD/MM/YYYY").format('YYYY')
          this.item.iteraciones = reverse
          // Obtener Dropdowns y Tipos de Ficheros
          await this.$store.dispatch('dropdowns/list', {empresa: this.$auth.user.empresa.uuid, grupo_alcance: this.item.grupo_alcance.uuid})
          let body = {
            grupo_alcance: this.item.grupo_alcance.uuid,
            empresa: this.$auth.user.empresa.uuid
          }
          if (this.item.subalcance) body.subalcance = this.item.subalcance.uuid
          const tipos_ficheros = await this.$store.dispatch("tipos_fichero/list", body)
          if (tipos_ficheros) this.tipos_ficheros = tipos_ficheros

          this.updateDatosIteracion(this.item.iteraciones[0])

        }
        this.component++
      },
      async updateDatosIteracion(iteracion) {
        const res = await this.$store.dispatch('expedientes/iteracion', [this.item.uuid, iteracion.uuid])
        if (res) {
          this.iteracionSelected = res
          this.updateMenuLeft(this.iteracionSelected);
        }
      },
      updateMenuLeft(iteracionSelected) {
        // console.info('iteracionSelected', iteracionSelected);
        const sites = iteracionSelected.sites
        const ficheros = []
        for (let i=0; i < sites.length; i++) {
          const codigo = sites[i].tipo_fichero
          if (!ficheros.includes(codigo)) ficheros.push(codigo)
        }
        this.menuFicheros.Solicitudes = ficheros
        //todo: crear submenu auditorias
        // if (iteracionSelected.auditorias) this.menu['Auditorías'].push(iteracionSelected.auditoria.uuid)
        if (iteracionSelected.revision) this.menu['Revisión'] = iteracionSelected.revision.uuid
        if (iteracionSelected.comision) this.menu['Comisión'] = iteracionSelected.comision.uuid
        if (iteracionSelected.certificado) this.menu['Certificado'] = iteracionSelected.certificado.uuid
        this.updateGrid(this.menuFicheros.Solicitudes[0]);
      },
      updateGrid(tipo_fichero_codigo) {
        const vm = this
        this.selectedTipoFichero = tipo_fichero_codigo
        const sites = vm.iteracionSelected.sites.filter(element => element.tipo_fichero === tipo_fichero_codigo)
        // Tomamos las validaciones de Tipos de Ficheros
        const filteredFichero = this.tipos_ficheros.filter(element => element.tipo_fichero.codigo === tipo_fichero_codigo)
        const fichero = filteredFichero[0].tipo_fichero.validacion
        if (fichero[0] !== '#') fichero.unshift('#');
        if (fichero && fichero.length > 0) {
          vm.grid.columnDefs = fichero.map((columna, index) => {
            if (index === 0) {
              return {
                headerName: "#", sortable: true, filter: true,
                resizable: true,
                editable: false,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                valueGetter: params => params.node.rowIndex + 1
              }
            } else {
              return {
                headerName: vm.capitalizeFirstLetter(columna.codigo),
                field: vm.capitalizeFirstLetter(columna.codigo),
                validacion: columna,
                enableRowGroup: true,
                resizable: true,
                editable: columna.editable,
                filter: true,
                cellRendererFramework: 'CellContentRenderer',
                cellRendererParams: function (params) {
                  if (!params.node.group) {
                    // data exists, so we can access it
                    return {
                      codigo: params.colDef.validacion.codigo,
                      type: params.colDef.validacion.type,
                      pais: params.data['Pais'],
                      provincia: params.data['Provincia']
                    }
                  } else {
                    // when we return null, the grid will display a blank cell
                    return null;
                  }
                },
                valueSetter: function (params) {
                  return vm.gridChanges(params)
                },
                cellEditorSelector: function (params) {
                  if (params.colDef.validacion.options && params.colDef.validacion.type === vm.TiposFicheroColumnas.SELECTOR) {
                    return {
                      component: 'SelectCellEditor',
                      params: {
                        items: vm.getOptionsColumnSelectByDropdowns(params.colDef.validacion.codigo),
                        placeholder: params.colDef.validacion.placeholder,
                        multiple: params.colDef.validacion.multiple
                      }
                    };
                  } else if (params.colDef.validacion.type === vm.TiposFicheroColumnas.AUTOCOMPLETE) {
                    return {
                      component: 'AutocompleteCellEditor',
                      params: {
                        items: vm.getOptionsColumnSelectByDropdowns(params.colDef.validacion.codigo),
                        placeholder: params.colDef.validacion.placeholder,
                        multiple: params.colDef.validacion.multiple,
                        codigo: params.colDef.validacion.codigo,
                        pais: params.data['Pais'],
                        provincia: params.data['Provincia']
                      }
                    };
                  } else if (params.colDef.validacion.type === vm.TiposFicheroColumnas.BOOLEANO) {
                    return {
                      component: 'BooleanCellEditor'
                    };
                  } else if (params.colDef.validacion.type === vm.TiposFicheroColumnas.DATE) {
                    return {
                      component: 'DateCellEditor'
                    };
                  } else {
                    return {
                      component: 'TextCellEditor',
                      params: {
                        type: params.colDef.validacion.type,
                        minlength: params.colDef.validacion.minlength,
                        maxlength: params.colDef.validacion.maxlength
                      }
                    };
                  }
                  return null
                }
              }
            }
          });
        }
        if (this.filteredActions.length > 0) {
          this.grid.columnDefs.push({
            headerName: "Acciones",
            pinned: 'right',
            field: "actions",
            cellRendererFramework: "ExpedienteActionsRenderer",
            cellRendererParams: {
              tipo_fichero: tipo_fichero_codigo,
              globalgapActions: this.globalgapActions,
              controlActionsModal: this.controlActionsModal.bind(this)
            }
          });
        }

        // TODO: AÑADIR A sites los datos del tipo_fichero correspondiente
        let rowData = []
        for (let index = 0; index < sites.length; index++) {
          const site = sites[index]
          const data = site.data
          if (site.tipo_fichero === tipo_fichero_codigo && data.length > 0) {
            let obj = {}
            for (let i=0; i < data.length; i++) {
              const element = data[i]
              obj[vm.capitalizeFirstLetter(element.keyname)] = element.value
              obj.uuid = site.uuid
            }
            rowData.push(obj)
          }
        }

        this.grid.rowData = rowData
        if (this.grid.api){
          this.grid.api.setQuickFilter(this.search)
          this.grid.api.refreshCells()
        }
        // Pasamos el fichero al Actions Modal
        this.selectionHeaders = fichero
      },
      capitalizeFirstLetter(string) {
        if (string) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
        else return ''
      },
      getOptionsColumnSelectByDropdowns(codigo) {
        let options = []
        try {
          if (this.$store.state.dropdowns.list[codigo]) {
            this.$store.state.dropdowns.list[codigo].forEach(item => options.push(item.value))
            return options
          } else return options
        } catch (error) {
          console.log('[ERROR getOptionsColumnSelectByDropdowns]', error)
          return options
        }
      },
      updateFilter() {
        this.grid.api.setQuickFilter(this.search)
        this.grid.api.refreshCells()
      },
      excelExport() {
        const params = {
          processCellCallback: function (params) {
            params.column.colDef.cellClass = "default"
            return params.value
          }
        }
        this.grid.api.exportDataAsExcel(params)
      },
      menuGoTo(name, uuid) {
        let route = this.remove_accents(name.toLowerCase())
        if (route[route.length] === 'n') route = route + 'es'
        else route = route + 's'
        console.info('[Expediente menuGoTo]',`${route}/${uuid}`);
        this.$router.push(`/${route}/${uuid}`)
      },
      remove_accents(str) {
        let map = {
          'a': 'á|à|ã|â|À|Á|Ã|Â',
          'e': 'é|è|ê|É|È|Ê',
          'i': 'í|ì|î|Í|Ì|Î',
          'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
          'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
          'c': 'ç|Ç',
          'n': 'ñ|Ñ'
        };
        str = str.toLowerCase()
        for (let pattern in map) {
          str = str.replace(new RegExp(map[pattern], 'g'), pattern)
        }
        return str
      }
    }
  }
</script>

<style>

  .ag-theme-material .ag-icon-checkbox-checked {
    color: #2e3444 !important;
  }

  .toolbar-expediente-width-menu {
    background: #f5f5f5 !important;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .09) !important;
    z-index: 1;
    right: 0;
    top: 60px;
    left: 275px;
    position: fixed;
  }


  .ag-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .toolbar-expediente-no-menu {
    background: #f5f5f5 !important;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .09) !important;
    color: #727891 !important;
    z-index: 1;
    right: 0;
    left: 0;
    top: 60px;
    position: fixed;
  }

  .info-expediente {
    height: 30px;
  }
</style>
