<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn text icon class="mr-3" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">Auditoría</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn color="success" @click="saveAuditoriaEvent()" :disabled="ui.blockMultipleAuditorias">
            Guardar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-4" style="max-height: 92.5vh; overflow: auto;">
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="ui.isBusy">
                <v-alert dense outlined type="error">
                  <strong>Atención.</strong> Actualmente ya existe otra auditoría en curso de {{ item.alcance.nombre }}
                  para el cliente
                  {{ item.cliente.nombre }}
                </v-alert>
              </v-col>

              <v-col cols="3">
                <v-text-field v-model="item.cliente.nombreCompleto" disabled label="Cliente" dense required
                  :rules="rules" />
              </v-col>

              <v-col cols="3">
                <v-text-field v-model="item.alcance.nombre" disabled label="Alcance" dense required :rules="rules" />
              </v-col>

              <v-col cols="3">
                <v-menu v-model="menuFechaAuditoria1" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field label="Inicio" :value="formatDate(item.desde)" prepend-icon="mdi-calendar"
                      :rules="rules" required clearable dense readonly v-on="on"
                      @click:clear="item.desde = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="item.desde" locale="es" color="primary" :min="bloqueo" :first-day-of-week="1"
                    no-title scrollable
                    @change="menuFechaAuditoria1 = false; item.hasta = item.desde; item.auditores = []"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="3">
                <v-menu v-model="menuFechaAuditoria2" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field label="Fin" :value="formatDate(item.hasta)" prepend-icon="mdi-calendar" :rules="rules"
                      required clearable readonly dense v-on="on" @click:clear="item.hasta = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="item.hasta" locale="es" color="primary" :min="item.desde" :first-day-of-week="1"
                    no-title scrollable
                    @change="menuFechaAuditoria2 = false, checkFechaFinEventoAuditoria()"></v-date-picker>
                </v-menu>
              </v-col>

            </v-row>

            <v-row>


              <v-col cols="2" class="pt-0">
                <v-checkbox dense v-model="selectAllAuditores" color="primary" label="Todos" />
              </v-col>


              <v-col cols="6">
                <SelectAuditores ref="selectAuditores" v-model="item.auditores" title="Auditores" required dense
                  compatibles :desde="item.desde" :alcance="item.alcance.uuid" :cliente="item.cliente.uuid"
                  :select_all="selectAllAuditores" :restrain="restrainAuditores" @clear="selectAllAuditores = false"
                  @all="selectAllAuditores = true" @change="calcularFechaFin(); updateCargos()" />
              </v-col>

              <v-col cols="4">
                <v-autocomplete v-model="item.responsable" label="Responsable"
                  hint="Haga clic en uno de los auditores para marcarlo como responsable" :items="item.auditores"
                  :item-text="nombreCompleto" item-value="uuid" :disabled="!item.auditores.length" :rules="rules" required
                  small-chips dense></v-autocomplete>
              </v-col>

              <v-col cols="12" class="v-data-table theme--light" v-if="item.cargos && item.cargos.length > 0">
                <div class="v-data-table__wrapper">
                  <table style="border: 1px solid #ccc;
                    background: #f9f9f9;
                    border-radius: 3px;
                    box-shadow: 0px 0px 3px 0px #e2e2e2;">
                    <thead>
                      <tr>
                        <th class="text-left">
                          Auditor
                        </th>
                        <th class="text-left">
                          En calidad de
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template>
                        <tr v-for="(aud, key) in item.cargos" :key="key">
                          <td>{{ aud.nombre }} {{ aud.apellidos }}</td>
                          <td>
                            <v-text-field dense hide-details="auto" v-model="aud.cargo">
                              <!-- <template v-slot:append>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on" style="cursor: help">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    Por ej. "Trazabilidad de la partida"
                                  </v-tooltip>
                                </template> -->
                            </v-text-field>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </v-col>

              <v-col cols="12" class="v-data-table theme--light">
                <div class="v-data-table__wrapper">
                  <table style="border: 1px solid #ccc;
                    background: #f9f9f9;
                    border-radius: 3px;
                    box-shadow: 0px 0px 3px 0px #e2e2e2;">
                    <thead>
                      <tr>
                        <th class="text-left">
                          Acompañante
                        </th>
                        <th class="text-left">
                          En calidad de
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="py-5">
                          <v-text-field label="Primer Acompañante" v-model="item.acompanante_1" hide-details="auto"
                            dense></v-text-field>
                        </td>
                        <td class="py-5">
                          <v-text-field v-model="item.cargo_acompanante_1" hide-details="auto" dense></v-text-field>
                        </td>
                      </tr>

                      <tr>
                        <td class="py-5">
                          <v-text-field label="Segundo Acompañante" v-model="item.acompanante_2" hide-details="auto"
                            dense></v-text-field>
                        </td>
                        <td class="py-5">
                          <v-text-field v-model="item.cargo_acompanante_2" hide-details="auto" dense></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>

              <v-col cols="3">
                <SelectEstaticos v-if="alcance" v-model="item.tipo_visita" label="Tipo de auditoría"
                  :alcance="alcance.uuid" :estaticos="estaticos" codigo="tipos_auditorias" multiple required dense />
              </v-col>

              <v-col cols="2">
                <SelectEstaticos v-if="alcance" dense v-model="item.nivel" label="Nivel" :alcance="alcance.uuid"
                  codigo="auditoria-nivel-de-control" required :estaticos="estaticos" />
              </v-col>

              <v-col cols="2">
                <v-select dense :rules="[v => !!v || 'Obligatorio']" :items="[{
                  value: 'PREVISTA',
                  label: 'PREVISTA',
                },
                {
                  value: 'CONFIRMADA',
                  label: 'CONFIRMADA',
                }]" item-text="label" item-value="value" v-model="item.estado" label="Estado de la auditoria"
                  nope-change="change_roles = true"></v-select>
              </v-col>

              <v-col cols="1">
              </v-col>

              <v-col cols="2" class="pt-0">
                <v-checkbox v-model="item.bloq_hasta" dense color="primary" label="Bloqueada" />
              </v-col>

              <v-col cols="2" class="pt-0" v-if="!auditoria">
                <v-checkbox v-model="item.nueva_iteracion" dense color="primary" label="Crear en una iteración nueva" />
              </v-col>

              <v-col cols="12">
                <v-textarea outlined hint="Notas de la planificacion" persistent-hint
                  v-model="item.notas_planificacion" />
              </v-col>

              <v-col cols="12">
                Emplazamientos a visitar {{ sitesCalculation.selected }}/{{ sitesCalculation.total }}
                <span class="error--text" v-if="this.$refs.sitesSelector && this.$refs.sitesSelector.sites_skipped">
                  <br>
                  Hay emplazamientos que no se muestran por no cumplir los criterios de selección
                </span>
              </v-col>

              <v-col cols="12" v-if="dialog && sites && sites.length">
                <expediente-sites ref="sitesSelector" height="40vh" :sites="sites"
                  :instalaciones="db.cliente.instalaciones || []" :gridOptions="gridOptions" protected multiple
                  selectAllSites @selection="onSelectionChange($event)" @tipos="tipos = $event"
                  :refreshCells="component" />
              </v-col>

            </v-row>
          </v-container>
        </v-form>

      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    CreateAuditoriaModal.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: xxx
 * Last Modified: Fri Jul 07 2023
 **/

import ESTADOS from '~/models/estados'
import format from '~/util/formatHelper'

import SelectAuditoresMultiple from '~/components/forms/SelectAuditoresMultiple'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import SolicitudSites from '~/components/solicitudes/sites'
import ExpedienteSites from '~/components/expedientes/sites'
import SelectAuditores from '../forms/SelectAuditoresMultiple'

export default {
  name: 'CreateAuditoriaModal',
  components: {
    SelectAuditores,
    ExpedienteSites,
    SelectAuditoresMultiple,
    SelectEstaticos,
    SolicitudSites,
  },
  created() {
    this.getEstaticos()
  },
  props: {
    value: Boolean,
    bloqueado_hasta: String,
    planificador: { type: Boolean, default: false },
    sites: { type: Array, default: () => [] },
    auditoria: { type: Object, default: () => { } },
    iteracion: { type: Object, default: () => { } },
    interacion: { type: Object, default: () => { } },
    cliente: { type: Object, default: () => { } },
    alcance: { type: Object, default: () => { } },
    gridOptions: { type: Object, default: () => { } },
  },
  data: () => ({
    estaticos: null,
    component: 0,
    search: '',
    dialog: false,
    sitesSelectedLength: 0,
    menuFechaAuditoria1: false,
    menuFechaAuditoria2: false,
    selectAllAuditores: false,
    iteracionSelected: {},
    tipos: [],
    item: {
      cliente: { nombre: '' },
      alcance: { nombre: '' },
      cargos: [],
      auditores: [],
      responsable: null,
      acompanante_1: null,
      cargo_acompanante_1: null,
      acompanante_2: null,
      cargo_acompanante_2: null,
      sites: {},
      tipo: {},
      nivel: 'Normal',
      estado: 'PREVISTA',
      bloq_hasta: false,
    },
    rules: [(v) => !!v || 'Obligatorio'],
    tipos_niveles: ['Normal', 'Reducido', 'Alto'],

    db: {
      cliente: {},
    },

    ui: {
      isBusy: false,
      blockMultipleAuditorias: false,
    },
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val && this.planificador) this.reset()
      else {
        if (this.bloqueado_hasta) this.item.bloq_hasta = true
        this.checkIfIsBusy()
        this.setIteration()
      }
    },
    auditoria(val) {
      if (val) {
        const item = Object.assign({}, val)
        this.item = item
        item.sites = {}
      }
    },
    interacion(val) {
      if (val) this.iteracionSelected = val
    },
    cliente(val) {
      if (val) this.item.cliente = val
      this.getClient()
    },
    alcance(val) {
      if (val) this.item.alcance = val
    },
  },

  computed: {
    bloqueo() {
      if (!this.item.bloq_hasta) return ''

      let diff = this.$moment().diff(this.bloqueado_hasta, 'days')
      return diff == 0 ? '' : this.bloqueado_hasta
    },

    sitesCalculation() {
      const sitesTotal = this.sites.length
      const sitesSelected = this.sitesSelectedLength
      const obj = {
        selected: Math.round(Math.sqrt(sitesSelected)),
        total: Math.round(Math.sqrt(sitesTotal)),
      }
      return obj
    },

    isCoplaca() {
      return this.$auth.user.empresa?.codigo == 'coplaca'
    },

    isAraporcei() {
      return this.$auth.user.empresa.codigo == 'araporcei'
    },

    isCerticalidad() {
      return this.$auth.user.empresa.codigo == 'certicalidad'
    },

    isSAE() {
      return this.alcance.codigo == 'certicalidad_sae'
    },

    solicitud() {
      return this.iteracion?.grupo_solicitud?.grupo_solicitud_params
    },
  },
  methods: {
    //+-------------------------------------------------
    // restrainAuditores()
    // Called by the selectAuditores, to check if the auditor has
    // the same category as the solicitud alcance producto category
    // -----
    // Created on Wed Dec 14 2022
    // Updated on Tue Dec 20 2022
    //+-------------------------------------------------
    async restrainAuditores(item) {
      if (!this.isSAE) return true
      item.afinidades = await this.$store.dispatch('auditores/getCompetencias', item.uuid)

      let categorias = []
      if (this.solicitud.alcance_producto.length) {
        categorias = this.solicitud.alcance_producto
          .map((item) => item.categoria_productos)
          .filter((value, index, self) => self.indexOf(value) === index)
      }

      let valid = true
      for (const categoria of categorias) {
        for (const afinidad of item.afinidades) {
          if (typeof afinidad.categoria == 'string') afinidad.categoria = [afinidad.categoria]
          if (afinidad.categoria && afinidad.categoria.indexOf(categoria) == -1) {
            // console.warn(item.nombre, afinidad.categoria, afinidad.desde, afinidad.hasta)
            valid = false
            break
          }
        }
      }

      // console.warn('valid', valid, item.nombre)
      return valid
    },

    //+-------------------------------------------------
    // updateCargos()
    // Initializes the array of cargos with the selected auditores
    // -----
    // Created on Tue Jul 06 2021
    //+-------------------------------------------------
    updateCargos() {
      this.item.cargos = []
      for (const aud of this.item.auditores) {
        this.item.cargos.push({
          auditor: aud.uuid,
          nombre: aud.nombre,
          apellidos: aud.apellidos,
          cargo: 'Auditor',
        })
      }
      this.$forceUpdate()
    },

    async getEstaticos() {
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        codigos: ['tipos_auditorias', 'auditoria-nivel-de-control'],
      }
      this.estaticos = await this.$store.dispatch('estaticos/list', body)
    },

    async getClient() {
      const res = await this.$store.dispatch('clientes/get', this.item.cliente.uuid)
      this.db.cliente = res
    },

    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },

    nombreCompleto(item) {
      return format.fullName(item.contacto)
    },

    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ''
    },

    close() {
      this.dialog = false
      this.$emit('input', null)
      this.$emit('close')
    },
    reset() {
      this.item = {
        cliente: { nombre: '' },
        alcance: { nombre: '' },
        auditores: [],
        responsable: null,
        sites: {},
        tipo: {},
        estado: 'PREVISTA',
      }
      this.$refs.form.resetValidation()
    },
    checkFechaFinEventoAuditoria() {
      if (this.$moment(this.item.desde).isAfter(this.item.hasta))
        this.auditoria.hasta = '' + this.auditoria.desde
    },
    calcularFechaFin() {
      if (this.$moment(this.item.hasta).diff(this.item.desde, 'days') < 0)
        this.item.hasta = this.$moment(this.item.desde).format('YYYY-MM-DD')
    },
    onSelectionChange(event) {
      this.item.sites.tipos = event.tipos
      this.item.sites[event.tipo] = event.selection
      // Get Sites Selected Length
      let sitesSelectedLength = 0
      for (let i = 0; i < this.tipos.length; i++) {
        const tipo = this.tipos[i]
        if (this.item.sites[tipo]) sitesSelectedLength += this.item.sites[tipo].length
      }
      this.sitesSelectedLength = sitesSelectedLength
    },
    saveAuditoriaEvent() {
      // Check If Sites Selected
      let error = true
      if (this.tipos) {
        for (let i = 0; i < this.tipos.length; i++) {
          const tipo = this.tipos[i]
          if (this.item.sites[tipo] && this.item.sites[tipo].length) {
            error = false
            break
          }
        }
      }
      if (error) {
        const alert = {
          text: `Debes seleccionar al menos 1 ${this.tipos[0]} o ${this.tipos[1]}`,
          color: 'error',
        }
        this.$store.commit('notification/show', alert)
      }

      // Check Form Validation
      if (this.$refs.form.validate() && !error) {
        const body = {
          ...this.item,
        }
        body.cliente = 'api/usuarios/' + body.cliente.uuid
        body.alcance = 'api/alcances/' + body.alcance.uuid
        body.auditores = body.auditores.map((element) => '/api/usuarios/' + element.uuid)
        body.responsable = '/api/usuarios/' + body.responsable
        // Get sites
        const tipos = this.item.sites.tipos
        let sites = []
        if (tipos && tipos.length) {
          for (let i = 0; i < tipos.length; i++) {
            const tipo = tipos[i]
            const selection = this.item.sites[tipo]
            if (selection) {
              let data = []
              if (selection.length) data = selection.map((element) => element)
              sites = sites.concat(data)
            }
          }
          //set emplazamientos
          body.emplazamientos = sites.map((element) => {
            if (element.tipo.includes('instalacion')) {
              console.log('emplazamiento', element)
              return '/api/emplazamientos/' + element.uuid
            }
          })
          body.emplazamientos = body.emplazamientos.filter((item) => item)
          //set productos
          body.productos = sites.map((element) => {
            if (element.tipo.includes('actividad')) {
              return '/api/productos/' + element.uuid
            }
          })
          body.productos = body.productos.filter((item) => item)
          body.tipoVisita = body.tipo_visita.map((element) => element)
          body.tipo = 'tipo'
          //delete unnecesary data of body
          delete body.sites
          delete body.tipo_visita
        }
        this.$emit('save-auditoria', body)
      }
    },

    //+-------------------------------------------------
    // checkIfIsBusy()
    // Check if there are any other open auditorias
    // Function also used in pages\planificador\widgets\editAuditoria.vue
    // -----
    // Created on Tue Nov 02 2021
    // Updated on Wed Jan 04 2023
    //+-------------------------------------------------
    async checkIfIsBusy() {
      if (this.isCoplaca) return

      this.ui.isBusy = false
      this.ui.blockMultipleAuditorias = false

      let alcance = this.item?.alcance
      let cliente = this.item?.cliente
      let estados = ['PENDIENTE', 'PREVISTA', 'ABIERTA', 'CONFIRMADA']

      if (alcance.uuid && cliente.uuid) {
        let xhr = await this.$store.dispatch('auditorias/list', {
          alcance: alcance.uuid,
          cliente: cliente.uuid,
          estados: estados,
        })

        if (xhr && xhr.length > 0) {
          this.ui.isBusy = true
        }
      }

      if (
        this.ui.isBusy &&
        this.$auth.user.empresa?.configuracion?.permitir_multiples_auditorias == false
      ) {
        this.ui.blockMultipleAuditorias = true
      }
    },

    //+-------------------------------------------------
    // setIteration()
    // https://trello.com/c/NxaHjO1a
    // -----
    // Created on Mon Mar 20 2023
    //+-------------------------------------------------
    setIteration() {
      if (!this.isCerticalidad) return
      this.item.nueva_iteracion = true
    },
  },
}
</script>
