<template>
  <v-dialog v-model="ui.dialog" :max-width="800">
    <v-card>
      <v-card-title>
        <v-avatar color="grey lighten-2" size="36" class="mr-2">
          <v-icon color="grey">mdi-link</v-icon>
        </v-avatar>

        Obtener un enlace compartidos

        <v-spacer></v-spacer>
        <v-btn icon text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-7">
        <v-form ref="form" lazy-validation v-model="ui.valid" style="height: 100%">
          <!-- <pre>
            {{ payload }}
            --
            {{ link }}
          </pre> -->
          <p>
            Un enlace compartido te permite otorgar acceso a los contenidos de certiapp en internet.<br>
            Cualquier persona con acceso a este enlace podrá ver esto
          </p>

          <v-row>
            <v-col cols="12">
              <v-text-field
                readonly
                :value="hashedURL"
                outlined dense
                hide-details
                :style="ui.loading ? 'background-color: #e6e6e6' : 'background-color: #d3dcd7'"
                :disabled="ui.loading"
                placeholder="Generando enlace..."
                onClick="this.select();"
                nope-loading="ui.loading"
              >
                <template v-slot:append v-if="ui.loading">
                  <v-progress-circular
                    size="24"
                    color="info"
                    indeterminate
                  ></v-progress-circular>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" class="pt-0" v-if="!ui.loading">
              <v-btn depressed
                :disabled="ui.loading"
                color="blue-grey"
                class="mr-2 white--text"
                target="_blank"
                :href="emailURL"
              >
                E-mail
                <v-icon
                  right
                  dark>
                  mdi-email
                </v-icon>
              </v-btn>

              <v-btn depressed
                :disabled="ui.loading"
                color="#25d366"
                class="mr-2 white--text"
                target="_blank"
                :href="whatsappURL"
              >
                Whatsapp
                <v-icon
                  right
                  dark>
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" v-if="!ui.loading"><v-divider></v-divider></v-col>
            <v-col class="pa-0" v-if="!ui.loading">
              <v-list two-line>

                <v-list-item v-if="!ui.editPassword" @click="ui.editPassword = true">
                  <v-list-item-icon>
                    <v-icon color="gray"> mdi-form-textbox-password</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Proteger con contraseña</v-list-item-title>
                    <v-list-item-subtitle>Establece una contraseña para proteger tus documentos</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action style="flex-direction: row; align-items: center;">
                    <v-chip small color="success" class="ma-2" v-if="link.password">
                      <v-icon small left>
                        mdi-check
                      </v-icon>
                      Protegido
                    </v-chip>

                    <v-btn icon>
                      <v-icon color="grey lighten-1">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item v-if="ui.editPassword">
                  <v-list-item-icon>
                    <v-icon color="gray"> mdi-form-textbox-password</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Proteger con contraseña</v-list-item-title>
                    <v-list-item-subtitle class="row pt-1 align-items-center">
                      <v-col cols="9">
                        <v-text-field
                          outlined dense small
                          hide-details="auto"
                          label=""
                          v-model="link.password"
                          :type="ui.showPassword ? 'text' : 'password'"
                          :append-icon="ui.showPassword ? 'visibility' : 'visibility_off'"
                          @click:append="ui.showPassword = !ui.showPassword"
                        ></v-text-field>
                        <v-btn
                          small text outlined
                          color=""
                          class="mt-3 mr-3"
                          @click.stop="ui.editPassword = false">
                          Cancelar
                        </v-btn>
                        <v-btn
                          small text outlined
                          color="success"
                          class="mt-3 mr-3"
                          @click="submit()">
                          Guardar
                        </v-btn>
                      </v-col>
                      <v-col cols="3">
                        <v-chip small color="success" class="ma-2" v-if="link.password">
                          <v-icon small left>
                            mdi-check
                          </v-icon>
                          Protegido
                        </v-chip>
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item style="cursor: pointer" :disabled="ui.saving" @click.stop="openDateEditor">
                  <v-list-item-icon>
                    <v-icon color="gray"> mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Establecer fecha limite</v-list-item-title>
                    <v-list-item-subtitle>El documento no será accesible después del <strong>{{ $moment(link.valido_hasta).format('D [de] MMMM, YYYY') }}</strong></v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                    <v-chip
                      small label
                      color="green"
                      text-color="white">
                      ✓
                    </v-chip>
                  </v-list-item-action> -->

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon color="grey lighten-1">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions v-if="!ui.loading && !ui.saving">

        <v-spacer></v-spacer>
        <v-btn
          small text outlined
          color="success"
          @click="close()">
          Cerrar ventana
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-card-actions>
    </v-card>

    <v-dialog
      ref="datepicker"
      v-model="ui.showPicker"
      :return-value.sync="payload.date"
      persistent
      width="290px">

      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="d-none"
          v-model="payload.date"
          label="Picker in dialog"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="payload.date"
        scrollable>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="ui.showPicker = false">
          Cancelar
        </v-btn>

        <v-btn
          text
          color="primary"
          @click="syncDate()"
          nopeclick="$refs.datepicker.save(payload.date);">
          Aceptar
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    Share.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: Wed Aug 25 2021
 * Last Modified: Wed Jan 05 2022
 **/

import notifications from '~/util/notificationHelper'
import MODULOS from '~/models/modulos'

export default {
  name: 'Share',

  data: () => ({
    link: {},
    payload: {
      uuid: '',
      link: 'http://certiapp.com/compartir/1DT-gYyaZRCI-Z_1SVHK1K2PhopE6JfTWNyRdAfeS',
      date: new Date().toISOString().substr(0, 10),
      password: false,
      observaciones: '',
    },

    db: {
      modulos: [],
    },

    ui: {
      valid: false,
      dialog: false,
      saving: false,
      loading: true,
      showPicker: false,
      showPassword: false,
      editPassword: false,
    },
  }),

  computed: {
    isClient() {
      return this.$store.getters.isCliente
    },

    hashedURL() {
      if (!this.link?.hash) return ''

      var getUrl = window.location
      return getUrl.protocol + '//' + getUrl.host + '/compartir/' + this.link.hash // + getUrl.pathname.split('/')[1]
    },

    emailURL() {
      let subject = 'Documento de CertiApp adjunto'
      let body = `Estimado cliente, le hacemos llegar el documento mencionado en el asunto.
 Para cualquier duda, pueden contactar con nosotros.%0A
${this.hashedURL}%0A%0A
Atentamente
`
      return `mailto:?subject=${subject}&body=${body}`
    },

    whatsappURL() {
      let text = 'Revisa este enlace de CertiApp: ' + this.hashedURL
      return 'https://wa.me/?text=' + text
    },
  },

  methods: {
    open(payload) {
      this.payload = {
        link: '',
        password: false,
        ...payload,
      }

      // let aYearFromNow = new Date()
      // aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
      // this.payload.date = this.$moment(aYearFromNow).format('YYYY-MM-DD')

      this.ui.dialog = true
    },

    close() {
      this.link = {}
      this.payload = {}

      this.ui.dialog = false
      this.ui.loading = true
      this.ui.showPassword = false
      this.ui.editPassword = false

      this.$refs.form.reset()
    },

    openDateEditor() {
      this.payload.date = this.$moment(this.link.valido_hasta).format('YYYY-MM-DD')
      this.ui.showPicker = true
    },

    syncDate() {
      this.link.valido_hasta = this.$moment(this.payload.date).format('YYYY-MM-DD HH:mm:ss')
      this.ui.showPicker = false
      this.submit()
    },

    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    async submit() {
      let step = 'valid' // false

      // 1 . Form validations
      // if (this.validate()) step = 'valid'

      // 2. Api requests
      if (step === 'valid') {
        await this.store('update')
      }

      // 3. Ui resets
      // this.link.password = ''
      this.ui.saving = false
      this.ui.editPassword = false
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async store(method = 'update') {
      this.ui.saving = true

      const payload = {...this.link}

      try {
        await this.$store.dispatch('compartir/update', payload)
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error subiendo un archivo',
          color: 'error',
          timeout: 3000,
        })
      }
    },

    //+-------------------------------------------------
    // getShareURL()
    // Requests a new url to backend with params
    // modulo, modulo_ref
    // If the backend already has this element, returns
    // -----
    // Created on Fri Sep 17 2021
    //+-------------------------------------------------
    async getShareURL() {
      let payload = {...this.payload}
      if (payload.modulo == 'documentos') payload.documento = payload.modulo_ref

      let xhr = await this.$store.dispatch('compartir/create', payload)

      this.link = xhr
      this.link.valido_hasta = this.$moment(xhr.valido_hasta).format('YYYY-MM-DD HH:mm:ss')
      this.ui.loading = false
    },

    loadModulos() {
      this.db.modulos = MODULOS
    },

    async init() {
      this.loadModulos()

      this.$nuxt.$on('share:open', (payload) => {
        this.open(payload)
        this.getShareURL()
      })

      // this.$nuxt.$on('documents:generated', (payload) => {
      //   window.setTimeout(() => {
      //     this.ui.generated = true
      //     this.open(payload)
      //   }, 500)
      // })

      // this.$nuxt.$on('documents:addedfile', (payload) => {
      //   this.addFile(payload.file)
      // })

      // this.$nuxt.$on('documents:thumbnail', (payload) => {
      //   this.addThumbnail(payload.file, payload.dataURL)
      // })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('share:open')
  },
}
</script>

<style>
.v-dialog.v-dialog--active::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-thumb {
  background-color: #00000069;
  border-radius: 15px;
  border: 1px solid #a5a5a5;
}
</style>
