<template>
  <div>
    <table class="no-border" style="width: 100%">
      <tbody>
        <tr class="no-border">
          <td class="no-border" style="text-align: center">
            <img src="https://araporcei.certiapp.com/araporcei/araporcei_logo.png" height="60px" width="60px">
            <h2 style="font-weight: 700">araporcei</h2>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
    </p>
    <h1 style="text-align: center; color: #F47BA9; font-size: xxx-large; font-weight: light">
      Certificado de Producto
    </h1>
    <div style="text-align: center">
      Concedida a/ Awarded to
    </div>
    <h1 style="text-align: center; font-size: xxx-large">
      <strong>{{ clienteNombreCompleto }}</strong>
    </h1>
    <h2 style="text-align: center">
      {{ datos.cliente.contacto.direccion }}, {{datos.cliente.contacto.localidad.municipio.nombre}} <br>
      {{ datos.cliente.contacto.cp }},
                    {{ datos.cliente.contacto.localidad.provincia.nombre }}
    </h2>
    <p>
      <br />
    </p>
    <p style="text-align: center; font-style: italic;">
      <strong>
        Araporcei* entendidad de certificación de producto certifica que los productos indicados, amparados por las
        marcas comerciales definidos en el alcance,
        han sido auditados y contrados conforme con los requisitos del documento normativo:
      </strong>
    </p>
    <p style="text-align: center; font-style: italic;">
      Araporcei* product certification body certifies that the product indicated, protected by the commercial brands
      defined in the scoped,
      has been audited and found to be in accordance with requirements of standard:
    </p>
    <hr>
    <p></p>
    <p style="text-align: center">
      <strong>R.D 4/2014 de 10 de enero por el que se aprueba la norma de Calidad para {{ productos }}</strong>
    </p>
    <p style="text-align: center">
      <strong>Protocolo de Certificación 08/05/2017. Ministerio de Agricultura y Pesca, Alimentación y Medio
        Ambiente.</strong>
    </p>
    <hr>
    <p></p>
    <p style="text-align: center">
      <strong>Dichos requisitos han sido evaluado en base a lo establido en el procedimiento PE_CER-01 de
        araporcei</strong>
    </p>
    <p style="text-align: center; font-style: italic;">
      Such criteria has been assessed to agreed requirements in accordance with the procedure PE_CER-01 property of
      araporcei
    </p>
    <p style="text-align: center">
      <strong>
        El presente certificado será válido por tres años salvo suspensión, retirada o expiración notificada por
        Araporcei.
        Este certificado no tiene validez sin su anexo técnico correspondiente.
      </strong>
    </p>
    <p style="text-align: center; font-style: italic;">
      This certificate shall be valid for up 3 years, unless suspension, withdrawal or expiration notified by araporcei.
      This certificate is not valid without its corresponding thecnical annex.
    </p>

    <p></p>

    <table class="no-border" style="width: 100%">
      <tbody>
        <tr class="no-border">
          <td class="no-border" style="width: 25%; text-align: center">
            <img src="https://www.enac.es/documents/7020/15646/enac_logo_color.png" height="45px">
          </td>
          <td class="no-border" style="width: 25%; text-align: center">
            <strong>N° de Certificado:</strong>
            <br>
            <span style="font-style: italic;">Ceritifcate Number:</span>
            <br>
            <strong>Fecha de entrada en vigor:</strong>
            <br>
            <span style="font-style: italic;">Original aprroval date:</span>
            <br>
            <strong>Fecha de caducidad:</strong>
            <br>
            <span style="font-style: italic;">Certification expiration date:</span>
          </td>
          <td class="no-border" style="width: 25%; text-align: center">
            <p style="font-style: italic;">{{ datos.codigo }}</p>
            <p style="font-style: italic;">{{ formatDate(datos.fecha_certificado) }}</p>
            <p style="font-style: italic;">{{ formatDate(datos.hasta) }}</p>
          </td>
          <td class="no-border" style="width: 25%; text-align: center">
            <p>
              <br>
              <br>
              <br>
            </p>
            Fdo. Teresa López Rivas
            <br>
            Directora Técnica
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagina 2 -->
    <div class="page-break-before "></div>
    <h1 style="text-align: center; color: #F47BA9; font-size: xx-large; font-weight: light">
      Anexo técnico al Certificado de Producto
    </h1>

    <p></p>

    <table class="no-border" style="width: 100%">
      <tbody>
        <tr class="no-border">
          <td class="no-border" style="width: 25%; text-align: center">
          </td>
          <td class="no-border" style="width: 25%; text-align: center">
            <img src="https://www.enac.es/documents/7020/15646/enac_logo_color.png" height="45px">
          </td>
          <td class="no-border" style="width: 25%; text-align: center">
            <strong>N° de Certificado:</strong>
            <br>
            <span style="font-style: italic;">Ceritifcate Number:</span>
            <br>
            <strong>Fecha de entrada en vigor:</strong>
            <br>
            <span style="font-style: italic;">Original aprroval date:</span>
            <br>
            <strong>Fecha de caducidad:</strong>
            <br>
            <span style="font-style: italic;">Certification expiration date:</span>
          </td>
          <td class="no-border" style="width: 25%; text-align: center">
            <p style="font-style: italic;">{{ datos.codigo }}</p>
            <p style="font-style: italic;">{{ formatDate(datos.fecha_certificado) }}</p>
            <p style="font-style: italic;">{{ formatDate(datos.fecha_certificado) }}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <p></p>

    <div style="text-align: center">
      Concedida a/ Awarded to
    </div>

    <h1 style="text-align: center; font-size: xxx-large">
      <strong>{{ clienteNombreCompleto }}</strong>
    </h1>

    <p></p>

    <p>
      <strong>El alcance de certificación se estable para el producto, las actividades y el proceso evaluado.</strong>
    </p>
    <p>
      Scope of certification:
      <span style="display: initial; font-weight:700">
        {{ datos.alcance.nombre }}
      </span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:21.3pt;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <span style="font-size:14px;line-height:107%;">&nbsp;</span></p>
    <div align="center"
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
      <table style="border-collapse:collapse;border:none;">
        <tbody>
          <tr>
            <td
              style="width:181.7pt;border:solid #BFBFBF 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:8.15pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:13px;background:#EDEDED;">Direcci&oacute;n de la instalaci&oacute;n
                  propia&nbsp;</span></p>
            </td>
            <td
              style="width:256.05pt;border:solid #BFBFBF 1.0pt;border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:8.15pt;">
              <p v-for="instalacion in instalaciones" :key="instalacion.id"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:13px;">C/ xxx, 0 C.P.0000 xxxx (xxxx)</span>

                <span style="font-size:13px;">{{ instalacion.direccion }}</span>
                <span style="font-size:13px;" v-if="instalacion.comunidad">{{ instalacion.comunidad.nombre }}</span>
                <span style="font-size:13px;"
                  v-if="instalacion.provincia">{{ ', ' + instalacion.provincia.nombre }}</span>
                <span style="font-size:13px;"
                  v-if="instalacion.municipio">{{ ', ' + instalacion.municipio.nombre }}</span>
                <span style="font-size:13px;" v-if="instalacion.cp">{{ instalacion.cp }}</span>
              </p>
            </td>
            <td
              style="width:79.7pt;border:solid #BFBFBF 1.0pt;border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:8.15pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:16px;font-family:"Calibri",sans-serif;text-align:right;'>
                <span style="font-size:13px;" v-for="instalacion in instalaciones" :key="instalacion.id">RGSEAA:
                  {{instalacion.rgseaa}}</span></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p
      style='margin-top:4.0pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:35.45pt;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <span style="font-size:4px;line-height:107%;">&nbsp;</span></p>
    <div align="center"
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
      <table style="width: 5.2e+2pt;border-collapse:collapse;border:none;">
        <tbody>
          <tr>
            <td rowspan="2"
              style="width:53.1pt;border:solid #A6A6A6 1.0pt;border-top:solid #BFBFBF 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:107%;">Tipo de Operador</span></strong></p>
            </td>
            <td rowspan="2"
              style="width:63.8pt;border-top:solid #BFBFBF 1.0pt;border-left:none;border-bottom:solid #A6A6A6 1.0pt;border-right:solid #BFBFBF 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Actividades realizadas&nbsp;</span></strong></p>
            </td>
            <td rowspan="2"
              style="width:77.95pt;border-top:solid #BFBFBF 1.0pt;border-left:none;border-bottom:solid #A6A6A6 1.0pt;border-right:solid #BFBFBF 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Actividades subcontratadas</span></strong></p>
            </td>
            <td colspan="6"
              style="width:320.55pt;border-top:solid #BFBFBF 1.0pt;border-left:none;border-bottom:solid gray 1.0pt;border-right:solid #A6A6A6 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Denominaci&oacute;n de venta del
                    Producto</span></strong></p>
            </td>
          </tr>
          <tr>
            <td
              style="width:49.65pt;border-top:none;border-left:none;border-bottom:solid #A6A6A6 1.0pt;border-right:solid #A6A6A6 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Tipo de Producto</span></strong></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #A6A6A6 1.0pt;border-right:solid #A6A6A6 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Alimentaci&oacute;n</span></strong></p>
            </td>
            <td
              style="width:49.6pt;border-top:none;border-left:none;border-bottom:  solid #A6A6A6 1.0pt;border-right:solid #BFBFBF 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Tipo racial</span></strong></p>
            </td>
            <td
              style="width:42.5pt;border-top:none;border-left:none;border-bottom:  solid #A6A6A6 1.0pt;border-right:solid #A6A6A6 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Estado</span></strong></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:  107%;">Presentaci&oacute;n Comercial</span></strong>
              </p>
            </td>
            <td
              style="width:65.4pt;border-top:none;border-left:none;border-bottom:  solid #A6A6A6 1.0pt;border-right:solid #BFBFBF 1.0pt;background:#EDEDED;padding:0cm 5.4pt 0cm 5.4pt;height:1.65pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:center;'>
                <strong><span style="font-size:9px;line-height:107%;">Marcas Amparadas</span></strong></p>
            </td>
          </tr>
          <tr>
            <td rowspan="3"
              style="width:53.1pt;border-top:none;border-left:solid #A6A6A6 1.0pt;border-bottom:solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <strong><span style="font-size:9px;line-height:107%;">{{ tipoOperador.responsable }}</span></strong></p>
            </td>
            <td
              style="width:63.8pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('actividades_realizadas','producto','jamon', 'paleta')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:77.95pt;border-top:none;border-left:none;border-bottom:solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('actividades_subcontratadas','producto','jamon', 'paleta')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:49.65pt;border-top:none;border-left:none;border-bottom:solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">Jam&oacute;n</span></p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">Paleta</span></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('alimentacion','producto','jamon', 'paleta')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:49.6pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('racial','producto','jamon', 'paleta')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:42.5pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('estado','producto','jamon', 'paleta')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('presentacion','producto','jamon', 'paleta')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:65.4pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.9pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('marcas','producto','jamon', 'paleta')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
          </tr>
          <tr>
            <td
              style="width:63.8pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('actividades_realizadas','producto','lomo')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:77.95pt;border-top:none;border-left:none;border-bottom:solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('actividades_subcontratadas','producto','lomo')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:49.65pt;border-top:none;border-left:none;border-bottom:solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">Lomo</span></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('alimentacion','producto','lomo')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:49.6pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('racial','producto','lomo')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:42.5pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('estado','producto','lomo')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('presentacion','producto','lomo')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:65.4pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:40.8pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('marcas','producto','lomo')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
          </tr>
          <tr>
            <td
              style="width:63.8pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('actividades_realizadas','producto','carnes_frescas')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:77.95pt;border-top:none;border-left:none;border-bottom:solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('actividades_subcontratadas','producto','carnes_frescas')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:49.65pt;border-top:none;border-left:none;border-bottom:solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">Carnes Frescas</span></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('alimentacion','producto','carnes_frescas')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:49.6pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('racial','producto','carnes_frescas')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:42.5pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('estado','producto','carnes_frescas')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:2.0cm;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #A6A6A6 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('presentacion','producto','carnes_frescas')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
            <td
              style="width:65.4pt;border-top:none;border-left:none;border-bottom:  solid #BFBFBF 1.0pt;border-right:solid #BFBFBF 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:4.75pt;">
              <p v-for="actividad in findDistionsElementsByPropertyByKeyValue('marcas','producto','carnes_frescas')"
                :key="actividad"
                style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;'>
                <span style="font-size:9px;line-height:  107%;">{{actividad}}</span></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:35.45pt;line-height:107%;font-size:16px;font-family:"Calibri",sans-serif;text-align:justify;'>
      <span style="font-size:9px;line-height:107%;">&nbsp;</span></p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:18.0pt;line-height:normal;font-size:16px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:1px;">&nbsp;</span></p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:18.0pt;line-height:normal;font-size:16px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:8px;">(*)Menciones Obligatorias: 50% Raza Ib&eacute;rica y/o 75% Raza
        Ib&eacute;rica&nbsp;</span></p>


    <!-- Instalaciones Subcontratadas -->
    <!-- <table style="width: 100%" v-if="datos.solicitud && datos.solicitud.params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="10" style="text-align: center"><strong>Subcontratadas</strong></td>
        </tr>
        <tr>
          <td colspan="3" style="text-align: center; font-weight: 700">Proveedor del Servicio</td>
        </tr>
        <tr style="text-align: center" v-if="datos.solicitud.params.actividades_proceso.subcontratadas.length">
          <td style="font-weight: 700">Nombre</td>
          <td style="font-weight: 700">Dirección de la Instalación</td>
          <td style="font-weight: 700">N° RGSEAA</td>
          <td style="font-weight: 700">Actividad</td>
          <td style="font-weight: 700">Volumen de animales/canales/piezas/kg de carne</td>
          <td style="font-weight: 700">Actividad Subcontratada Certificada</td>
          <td style="font-weight: 700">Entidad de Certificación</td>
          <td style="font-weight: 700">Certificado de Conformidad y Anexo Técnico</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="datos.solicitud.params.actividades_proceso.subcontratadas.length">
          <tr style="text-align: center" v-for="item in datos.solicitud.params.actividades_proceso.subcontratadas" :key="item.id">
            <td>{{ item.nombre }}</td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.rgseaa }}</td>
            <td>{{ item.actividad }}</td>
            <td>{{ item.volumen }}</td>
            <td>{{ (item.certificada ? 'SI' : 'NO') }}</td>
            <td>{{ item.entidad }}</td>
            <td>{{ item.conformidad_anexo }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="8" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table>
    <p>
      <br />
      <br />
    </p> -->
    <!-- Instalaciones Alquiladas -->
    <!-- <table style="width: 100%" v-if="datos.solicitud && datos.solicitud.params.actividades_proceso">
      <thead>
        <tr>
          <td colspan="10" style="text-align: center">Alquiladas</td>
        </tr>
        <tr style="text-align: center" v-if="datos.solicitud.params.actividades_proceso.alquiladas.length">
          <td style="font-weight: 700">N° RGSEAA</td>
          <td style="font-weight: 700">Dirección de la Instalación</td>
          <td style="font-weight: 700">Actividad</td>
          <td style="font-weight: 700">Volumen de animales/canales/piezas/kg de carne</td>
          <td style="font-weight: 700">Solicita Certificación de Araporcei</td>
        </tr>
      </thead>
      <tbody>
        <template v-if="datos.solicitud.params.actividades_proceso.alquiladas.length">
          <tr style="text-align: center" v-for="item in datos.solicitud.params.actividades_proceso.alquiladas" :key="item.id">
            <td>{{ item.rgseaa }}</td>
            <td>{{ item.direccion }}</td>
            <td>{{ item.actividad }}</td>
            <td>{{ item.volumen }}</td>
            <td>{{ (item.solicitda_certificacion ? 'SI' : 'NO') }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" style="text-align: center">NO APLICA</td>
        </tr>
      </tbody>
    </table> -->
    <p>
      <br />
      <br />
    </p>
    <p style="text-align: center">
      <strong>El presente anexo técnico solo es válido si se acompaña del certificado de producto N°
        {{ datos.codigo }}</strong>
    </p>
    <p style="text-align: center; font-weight: italic;">
      This technical annex is only valid if it is accompanied by the product certificate N° {{ datos.codigo }}
    </p>


    <div class="footer">
      <p>(*) ARAPORCEI: ARAPORC ENTIDAD DE INSPECCIÓN, S.L.C.I.F.: B-91421685 Gabriel Miró 3, Edificio Wertice 2a
        planta, Oficina 1 - 41704 Dos Hermanas (Sevilla). Tel. 954.64.74.23 Fax 954.64.44.16 •
        www.araporcei.es</p>
    </div>
  </div>
</template>

<style scoped>
  @media screen {
    div.footer {
      display: none;
    }
  }

  @media print {
    div.footer {
      border-top: 2px solid #F47BA9;
      margin-top: 10px;
      position: fixed;
      bottom: 0;
    }

    div.footer p {
      text-align: center;
    }
  }

  table {
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 0.5px solid black;
  }

  thead {
    display: table-row-group;
  }

  tr td {
    page-break-inside: avoid;
  }

  .no-border {
    border: none !important;
  }

  p {
    font-size: 14px;
  }
</style>

<script>
  import MODELS from "~/models/araporcei/solicitudes"

  export default {
    props: {
      datos: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      tipoOperador: MODELS.OPERADORES_TIPO,
      tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
      tipoProducto: MODELS.PRODUCTO_TIPO,
      tipoRacial: MODELS.RACIAL_TIPO,
      tipoEstado: MODELS.ESTADOS_TIPO,
      tipoPresentacion: MODELS.PRESENTACION_TIPO,
      tipoCertificacion: MODELS.CERTIFICACION_TIPO,
    }),
    computed: {
      isOperadorResposable() {
        return this.datos.solicitud && this.datos.solicitud.params.alcance_proceso.tipo_operador.includes('responsable')
      },
      clienteNombreCompleto() {
        let nombre = ''
        const cliente = this.datos.cliente
        if (cliente) {
          if (cliente.nombre) nombre += cliente.nombre
          if (cliente.primer_apellido) nombre += ' ' + cliente.primer_apellido
          if (cliente.segundo_apellido) nombre += ' ' + cliente.segundo_apellido
        }
        return nombre
      },
      sites() {
        const planificacionesSites = this.datos.auditoria.planificaciones.map(e => e.site.uuid)

        const sites = this.datos.auditoria.sites.filter(e => e.certificado)

        const parentSites = sites.filter(e => planificacionesSites.includes(e.uuid))
        const parentSitesUuids = parentSites.map(e => e.uuid)

        const childrenSites = sites.filter(e => parentSitesUuids.includes(e.parent_uuid))

        return {
          instalaciones: this.getSiteData(parentSites),
          actividades: this.getSiteData(childrenSites)
        }
      },
      instalaciones() {
        const instalacionesNombre = this.sites.instalaciones.map(e => e.nombre)
        return this.datos.cliente.instalaciones.filter(element => {
          return instalacionesNombre.some(e => e.includes(element.rgseaa))
        })
      },
      actividades() {
        return this.sites.actividades
      },
      productos() {
        const productos = []
        const alcance_producto = this.datos.solicitud.params.alcance_producto
        if (alcance_producto) {
          for (let i = 0; i < alcance_producto.length; i++) {
            const element = alcance_producto[i]
            const tipo_producto = this.tipoProducto[element.producto]
            if (!productos.includes(tipo_producto)) productos.push(tipo_producto)
          }
        }
        if (productos.length) {
          const start = productos.slice(0, productos.length - 1)
          const end = productos[productos.length - 1]
          return start.join(', ') + ' y ' + end
        } else return ''
      },
      alcance() {
        return this.datos && this.datos.solicitudes ? this.datos.solicitudes[0].alcance : ''
      },
      solicitud_data() {
        if (this.datos) {
          let data = {}
          this.datos.grupo_solicitud_data.forEach(element => {
            data[element.keyname] = element.value
          })
          return data
        } else return {}
      },
      site_data() {
        if (this.datos.isCliente) return this.datos.isCliente
        else if (this.datos.sites) {
          let data = {}
          let tipos = []

          for (let i = 0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
          }

          for (let i = 0; i < tipos.length; i++) {
            const element = tipos[i]
            data[element] = []
          }

          for (let i = 0; i < this.datos.sites.length; i++) {
            const element = this.datos.sites[i]
            let obj = {}
            obj.uuid = element.uuid
            for (let k = 0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
            data[element.tipo].push(obj)
          }

          return data
        } else return {}
      },
    },
    methods: {
      formatDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : ''
      },
      formatDateEnd(date) {
        return date ? this.$moment(date).add(3, 'y').format('DD/MM/YYYY') : ''
      },
      getSiteData(sites) {
        const data = []
        if (sites) {
          for (let i = 0; i < sites.length; i++) {
            const element = sites[i]
            let obj = {}
            obj.uuid = element.uuid
            obj.nombre = element.nombre
            obj.tipo = element.tipo
            for (let k = 0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
            data.push(obj)
          }
        }
        return data
      },
      findDistionsElementsByPropertyByKeyValue(property, key, value, value2) {
        const elements = [];

        this.sites.actividades
          .filter(x => x[key] == value || (value2 != null && x[key] == value2))
          .forEach(x => {
            if (x != undefined && x[property] != undefined && !elements.includes(x[property])) {
              elements.push(x[property]);
            }
          })

        return elements;
      }
    }
  }
</script>
