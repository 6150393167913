/**
 * @project: certiapp-nuxt
 * @file:    models/menu.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Wed Oct 26 2022
 **/

import ROLE from './role'

export default [
  // Array of menu items
  // Every item has the name, type, icon and role and link
  // role is required, and used to filter menu options
  // modulo is required, and used to filter menu options
  // notFor and onlyFor are optional
  // intent is optional, used to define page intention and filter options
  // - intent can be: 'list', 'create', 'edit',
  // {
  //   name: {
  //     es_ES: 'dummy modulo',
  //   },
  //   modulo: 'dummy',
  //   type: 'link',
  //   link: 'formatos/plantillas',
  //   icon: 'mdi-file-word-box',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  // },

  // {
  //   name: {
  //     es_ES: 'dummy onlyfor antonio',
  //   },
  //   modulo: 'ANTONIIO',
  //   type: 'link',
  //   link: 'formatos/plantillas',
  //   icon: 'mdi-file-word-box',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   onlyFor: ['ccl'],
  // },

  // {
  //   name: {
  //     es_ES: 'dummy onlyfor',
  //   },
  //   modulo: 'dummy',
  //   type: 'link',
  //   link: 'formatos/plantillas',
  //   icon: 'mdi-file-word-box',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   onlyFor: ['ccl'],
  // },

  // {
  //   name: {
  //     es_ES: 'dummy notfor',
  //   },
  //   modulo: 'dummy',
  //   type: 'link',
  //   link: 'formatos/plantillas',
  //   icon: 'mdi-file-word-box',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   notFor: ['ccl'],
  // },

  // {
  //   name: {
  //     es_ES: 'dummy intent',
  //   },
  //   modulo: 'dummy',
  //   type: 'link',
  //   link: 'formatos/plantillas',
  //   icon: 'mdi-file-word-box',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   intent: ['list'],
  // },

  {
    name: {
      es_ES: 'Configuracion',
      en_US: 'Configuration',
    },
    modulo: 'configuracion',
    type: 'menu',
    link: null,
    icon: 'mdi-book-lock',
    role: ROLE.ROLE_AUDITOR_ADMIN,
    items: [
      {
        name: {
          es_ES: 'Registro de cambios',
        },
        submodulo: 'logs',
        type: 'link',
        path: 'rlogs',
        link: 'rlogs',
        icon: 'mdi-paper',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },

      {
        name: {
          es_ES: 'Mensajes',
          en_US: 'Mensajes',
        },
        submodulo: 'mensajes',
        // path: 'mensajes',
        link: 'mensajes',
        icon: 'mdi-message',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },

      {
        name: {
          es_ES: 'Permisos',
        },
        submodulo: 'permisos',
        link: 'permisos',
        icon: 'mdi-format-list-checkbox',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },

      {
        name: {
          es_ES: 'Listas de validación',
        },
        submodulo: 'listas-de-validacion',
        link: 'listas_validacion',
        icon: 'mdi-format-list-checkbox',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },
      // {
      //   name: {
      //     es_ES: 'Plantillas de solicitud',
      //     en_US: 'Request templates'
      //   },
      //   type: 'link',
      //   link: 'plantillas',
      //   icon: 'mdi-file-hidden',
      //   role: ROLE.ROLE_AUDITOR_ADMIN,
      // },
      // {
      //   name: {
      //     es_ES: 'Generador de formatos',
      //     en_US: 'Template generator',
      //   },
      //   submodulo: 'generador-de-plantillas',
      //   type: 'link',
      //   link: 'generador_formatos',
      //   icon: 'mdi-text-box-multiple-outline',
      //   role: ROLE.ROLE_AUDITOR_ADMIN,
      // },

      {
        name: {
          es_ES: 'Plantillas de formato',
          en_US: 'Template generator',
        },
        submodulo: 'generador-de-plantillas',
        type: 'link',
        path: 'formatos',
        link: 'formatos/plantillas',
        icon: 'mdi-pencil-ruler',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },

      {
        name: {
          es_ES: 'Selectores',
          en_US: 'Selectores',
        },
        type: 'link',
        submodulo: 'selectores',
        link: 'selectores',
        icon: 'mdi-playlist-edit',
        role: ROLE.ROLE_AUDITOR_FREELANCE,
      },

      {
        name: {
          es_ES: 'Estaticos',
        },
        submodulo: 'estaticos',
        link: 'estaticos',
        icon: 'mdi-playlist-edit',
        role: ROLE.ROLE_AUDITOR,
      },

      {
        name: {
          es_ES: 'Com. Internas',
        },
        type: 'link',
        link: 'comunicaciones',
        submodulo: 'com-internas',
        icon: 'mdi-file-hidden',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },

      {
        name: {
          es_ES: 'Com. Externas',
        },
        type: 'link',
        link: 'comunicaciones_externas',
        submodulo: 'com-externas',
        icon: 'mdi-file-certificate-outline',
        role: ROLE.ROLE_AUDITOR_FREELANCE,
      },

      {
        name: {
          es_ES: 'Mantenimiento',
        },
        type: 'link',
        link: 'metadata',
        submodulo: 'estaticos',
        icon: 'mdi-ab-testing',
        role: ROLE.ROLE_AUDITOR,
      },

      {
        name: {
          es_ES: 'Localidades',
        },
        type: 'link',
        link: 'localidades',
        submodulo: 'estaticos',
        icon: 'mdi-map-marker-multiple',
        role: ROLE.ROLE_AUDITOR,
      },

      {
        name: {
          es_ES: 'Bloques',
        },
        type: 'link',
        link: 'bloques',
        submodulo: 'estaticos',
        icon: 'mdi-google-circles-extended',
        role: ROLE.ROLE_AUDITOR,
      },

      {
        name: {
          es_ES: 'Enlaces compartidos',
        },
        type: 'link',
        link: 'compartir',
        submodulo: 'estaticos',
        icon: 'mdi-link-variant',
        role: ROLE.ROLE_AUDITOR,
      },

      {
        name: {
          es_ES: 'Copias de seguridad',
        },
        type: 'link',
        link: 'backups',
        submodulo: 'estaticos',
        icon: 'mdi-database-arrow-right-outline',
        role: ROLE.ROLE_AUDITOR_REVISOR,
      },
    ],
  },

  {
    name: {
      es_ES: 'Admin',
      en_US: 'Admin',
    },
    modulo: 'admin',
    type: 'menu',
    link: null,
    icon: 'mdi-book-lock',
    role: ROLE.ROLE_ROOT,
    items: [
      {
        name: {
          es_ES: 'Componentes',
        },
        submodulo: 'componentes',
        link: 'componentes',
        icon: 'mdi-widgets',
        role: ROLE.ROLE_ROOT,
      },
      {
        name: {
          es_ES: 'Tipos de Ficheros',
        },
        submodulo: 'tipos-de-fichero',
        link: 'tipos_fichero',
        icon: 'mdi-adobe-acrobat',
        role: ROLE.ROLE_ROOT,
      },
      {
        name: {
          es_ES: 'Trámites',
        },
        submodulo: 'tramites',
        link: 'tramites',
        icon: 'mdi-table-edit',
        role: ROLE.ROLE_ROOT,
      },
      {
        name: {
          es_ES: 'Dropdowns',
        },
        submodulo: 'dropdowns',
        link: 'dropdowns',
        icon: 'mdi-table-edit',
        role: ROLE.ROLE_ROOT,
      },
    ],
  },

  {
    name: {
      es_ES: 'Entidades',
      en_US: 'Entidades',
    },
    modulo: 'personal',
    type: 'menu',
    link: null,
    icon: 'mdi-account-box',
    role: ROLE.ROLE_TECNICO,
    items: [
      {
        name: {
          es_ES: 'Contactos',
          en_US: 'Contacts',
        },
        submodulo: 'personal',
        path: 'personal',
        link: 'personal/agenda',
        icon: 'mdi-account-search',
      },
      {
        name: {
          es_ES: 'Usuarios',
          en_US: 'Contacts',
        },
        submodulo: 'personal',
        path: 'personal',
        link: 'personal/usuarios',
        icon: 'mdi-account-settings',
      },
      {
        name: {
          es_ES: 'Clientes',
        },
        submodulo: 'personal',
        path: 'personal',
        link: 'personal/clientes',
        icon: 'mdi-clipboard-account',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },
      {
        name: {
          es_ES: 'Auditores',
        },
        submodulo: 'personal',
        path: 'personal',
        link: 'personal/auditores',
        icon: 'mdi-account-tie',
      },
      {
        name: {
          es_ES: 'Personal interno',
          en_US: 'Personal interno',
        },
        submodulo: 'personal',
        path: 'personal',
        link: 'personal/personal-interno',
        icon: 'mdi-tooltip-account',
      },

      {
        name: {
          es_ES: 'Grupos de contactos',
          en_US: 'Grupos de contactos',
        },
        submodulo: 'personal',
        path: 'personal',
        link: 'personal/grupos',
        icon: 'mdi-account-box-multiple',
      },
    ],
  },

  {
    name: {
      es_ES: 'Gestor documental',
      en_US: 'Documents',
    },
    modulo: 'personal',
    type: 'link',
    link: 'documentos',
    icon: 'mdi-cloud-outline',
    role: ROLE.ROLE_AUDITOR_ADMIN,
  },

  /**
   * COOPERATIVAS
   */
  {
    name: {
      es_ES: 'Cooperativas',
    },
    modulo: 'cooperativas',
    type: 'link',
    link: 'cooperativas',
    icon: 'mdi-account-group-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
  },

  /**
   * CARGAS
   */
  {
    name: {
      es_ES: 'Cargas',
      en_US: 'Loads',
    },
    modulo: 'cargas',
    type: 'link',
    link: 'cargas',
    icon: 'mdi-file-upload-outline',
    role: ROLE.ROLE_AUDITOR_ADMIN,
  },

  // /**
  //  * EMPLAZAMIENTOS
  //  */
  // {
  //   name: {
  //     es_ES: 'Emplazamientos',
  //     en_US: 'Sites'
  //   },
  //   type: 'link',
  //   link: 'emplazamientos',
  //   icon: 'mdi-map-search-outline',
  //   role: ROLE.ROLE_ROOT
  // },
  // /**
  //  * GRUPOS
  //  */
  // {
  //   name: {
  //     es_ES: 'Cooperativas',
  //     en_US: 'Cooperatives'
  //   },
  //   type: 'link',
  //   link: 'grupos',
  //   icon: 'mdi-home-group',
  //   role: ROLE.ROLE_AUDITOR_FREELANCE
  // },

  /**
   * DELEGACIONES
   */
  {
    name: {
      es_ES: 'Delegaciones',
      en_US: 'Delegations',
    },
    modulo: 'delegaciones',
    type: 'link',
    link: 'delegaciones',
    icon: 'mdi-domain',
    role: ROLE.ROLE_AUDITOR_ADMIN,
  },
  // /**
  //  * ZONAS E VISITA
  //  */
  // {
  //   name: {
  //     es_ES: 'Zonas de visita',
  //     en_US: 'Visit areas'
  //   },
  //   type: 'link',
  //   link: 'visitas',
  //   icon: 'mdi-map',
  //   role: ROLE.ROLE_AUDITOR_FREELANCE
  // },

  /**
   * ALCANCES
   */
  {
    name: {
      es_ES: 'Alcances',
      en_US: 'Alcances',
    },
    modulo: 'alcances',
    type: 'link',
    link: 'alcances',
    icon: 'mdi-folder-lock',
    role: ROLE.ROLE_AUDITOR_ADMIN,
  },
  /**
   * SOLICITUDES
   */
  {
    name: {
      es_ES: 'Solicitudes',
      en_US: 'Requests',
    },
    modulo: 'solicitudes',
    type: 'menu',
    link: null,
    icon: 'mdi-text-box-check-outline',
    role: ROLE.ROLE_AUDITOR,
    items: [
      {
        name: {
          es_ES: 'Nueva Solicitud',
        },
        submodulo: 'nueva-solicitud',
        link: 'grupo_solicitudes/nueva',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_AUDITOR,
      },
      {
        name: {
          es_ES: 'Solicitudes',
        },
        submodulo: 'solicitudes',
        link: 'grupo_solicitudes',
        icon: 'mdi-text-box-outline',
        role: ROLE.ROLE_AUDITOR,
      },
    ],
  },

  /**
   * EXPEDIENTES
   */
  {
    name: {
      es_ES: 'Expedientes',
      en_US: 'Files',
    },
    modulo: 'expedientes',
    type: 'link',
    link: 'expedientes',
    icon: 'mdi-folder-text-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
  },
  /**
   * EXPEDIENTES - ITERACIONES
   */
  {
    name: {
      es_ES: 'Expediente - Iteración',
      en_US: 'Expedient',
    },
    type: 'hidden',
    link: 'expedientes/iteracion',
    icon: 'mdi-folder-text-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
  },
  /**
   * PLANIFICADOR
   */
  {
    name: {
      es_ES: 'Planificador',
      en_US: 'Planning',
    },
    modulo: 'planificador',
    type: 'link',
    link: 'planificador',
    icon: 'mdi-calendar-month',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
  },
  /**
   * AUDITORIAS
   */
  {
    name: {
      es_ES: 'Auditorias',
      en_US: 'Audits',
    },
    type: 'menu',
    link: null,
    icon: 'mdi-ballot-outline',
    role: ROLE.ROLE_AUDITOR_FREELANCE,
    modulo: 'auditorias',
    offline: true,
    items: [
      {
        name: {
          es_ES: 'Auditorias',
        },
        link: 'auditorias',
        icon: 'mdi-ballot-outline',
        role: ROLE.ROLE_AUDITOR_FREELANCE,
        offline: true,
        submodulo: 'auditorias',
      },

      /* {
        name: {
          es_ES: 'Previstas',
        },
        submodulo: 'previstas',
        link: 'auditorias/previstas',
        icon: 'mdi-calendar-clock',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },*/

      {
        name: {
          es_ES: 'Checklists',
        },
        submodulo: 'checklists',
        link: 'auditorias/checklists',
        icon: 'mdi-file-hidden',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },

      // {
      //   name: {
      //     es_ES: 'Informes',
      //   },
      //   submodulo: 'informes',
      //   link: 'auditorias/informes',
      //   icon: 'mdi-clock-outline',
      //   role: ROLE.ROLE_AUDITOR_ADMIN,
      // },
    ],
  },

  /**
   * REVISIONES
   */
  // {
  //   name: {
  //     es_ES: 'Revisiones',
  //   },
  //   modulo: 'revisiones',
  //   type: 'link',
  //   link: 'revisiones',
  //   icon: 'mdi-checkbox-multiple-marked-outline',
  //   role: ROLE.ROLE_AUDITOR,
  // },

  {
    name: {
      es_ES: 'Revisiones',
    },
    modulo: 'revisiones',
    type: 'menu',
    link: null,
    icon: 'mdi-checkbox-multiple-marked-outline',
    role: ROLE.ROLE_AUDITOR,
    items: [
      {
        name: {
          es_ES: 'Revisiones',
        },
        submodulo: 'revisiones',
        link: 'revisiones',
        icon: 'mdi-checkbox-multiple-marked-outline',
        role: ROLE.ROLE_AUDITOR,
      },

      {
        name: {
          es_ES: 'Lotes',
        },
        submodulo: 'revisiones',
        link: 'revisiones/lotes',
        icon: 'mdi-select-group',
        role: ROLE.ROLE_AUDITOR,
      },
    ],
  },

  /**
   * DICTAMENES
   */

  // {
  //   name: {
  //     es_ES: 'Dictámenes',
  //   },
  //   modulo: 'dictamenes',
  //   type: 'link',
  //   link: 'dictamenes',
  //   icon: 'mdi-gavel',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  // },

  {
    name: {
      es_ES: 'Dictámenes',
    },
    modulo: 'dictamenes',
    type: 'menu',
    link: null,
    icon: 'mdi-gavel',
    role: ROLE.ROLE_AUDITOR_ADMIN,
    items: [
      {
        name: {
          es_ES: 'Dictámenes',
        },
        submodulo: 'dictamenes',
        link: 'dictamenes',
        icon: 'mdi-gavel',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },

      {
        name: {
          es_ES: 'Lotes',
        },
        submodulo: 'dictamenes',
        link: 'dictamenes/lotes',
        icon: 'mdi-select-group',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },
    ],
  },

  /**
   * CERTIFICADOS
   */
  {
    name: {
      es_ES: 'Certificados',
    },
    modulo: 'certificados',
    type: 'link',
    link: 'certificados',
    icon: 'mdi-file-certificate-outline',
    role: ROLE.ROLE_AUDITOR_ADMIN,
  },
  /**
   * FORMACION COPLACA
   */
  {
    name: {
      es_ES: 'Formación',
    },
    modulo: 'formacion_coplaca',
    type: 'menu',
    link: null,
    icon: 'mdi-ballot-outline',
    role: ROLE.ROLE_AUDITOR_ADMIN,
    onlyFor: ['coplaca'],
    items: [
      {
        name: {
          es_ES: 'Formaciones',
        },

        submodulo: 'formaciones',
        link: 'formacion_coplaca',
        icon: 'mdi-file-certificate-outline',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },
      {
        name: {
          es_ES: 'Categorias',
        },
        submodulo: 'categorias',
        link: 'formacion_coplaca/categorias',
        icon: 'mdi-file-hidden',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },
      {
        name: {
          es_ES: 'Situación',
        },
        submodulo: 'situacion',
        link: 'formacion_coplaca/situacion',
        icon: 'mdi-clock-outline',
        role: ROLE.ROLE_AUDITOR_ADMIN,
      },
    ],
  },

  // {
  //   name: {
  //     es_ES: 'Informes',
  //   },
  //   modulo: 'informes',
  //   type: 'menu',
  //   link: null,
  //   icon: 'mdi-ballot-outline',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   items: [
  //     {
  //       name: {
  //         es_ES: 'Informes Operadores',
  //       },
  //       submodulo: 'informes-operadores1',
  //       link: 'informes',
  //       icon: 'mdi-file-certificate-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Informes Operadores',
  //       },
  //       submodulo: 'informes-operadores',
  //       link: 'informes/ccl/informe_operadores',
  //       icon: 'mdi-file-certificate-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Informes Industrias',
  //       },
  //       submodulo: 'informes-industrias',
  //       link: 'informes/ccl/informe_industrias',
  //       icon: 'mdi-file-hidden',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Informes Superficies',
  //       },
  //       submodulo: 'informes-superficies',
  //       link: 'informes/ccl/informe_superficies',
  //       icon: 'mdi-clock-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //   ],
  // },

  {
    name: {
      es_ES: 'Informes',
    },
    modulo: 'personal',
    type: 'link',
    link: 'informes',
    icon: 'mdi-card-bulleted-outline',
    role: ROLE.ROLE_AUDITOR_ADMIN,
  },

  /**
   * Control de etiquetas
   */

  {
    name: {
      es_ES: 'Etiquetas',
      code: 'menu.etiquetas',
    },
    modulo: 'etiquetas',
    type: 'link',
    link: 'etiquetas',
    icon: 'mdi-texture-box',
    role: ROLE.ROLE_AUDITOR,
  },

  // {
  //   name: {
  //     es_ES: 'Informes',
  //   },
  //   modulo: 'personal',
  //   type: 'menu',
  //   link: null,
  //   icon: 'mdi-ballot-outline',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   items: [

  // {
  //   name: {
  //     es_ES: 'Informes',
  //   },
  //   submodulo: 'informes',
  //   link: 'dictamenes/informes',
  //   icon: 'mdi-clock-outline',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  // },

  // {
  //   name: {
  //     es_ES: 'Informes Operadores',
  //   },
  //   submodulo: 'informes-operadores1',
  //   link: 'informes',
  //   icon: 'mdi-file-certificate-outline',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  // },
  // {
  //   name: {
  //     es_ES: 'Informes Operadores',
  //   },
  //   submodulo: 'informes-operadores',
  //   link: 'informes/ccl/informe_operadores',
  //   icon: 'mdi-file-certificate-outline',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  // },
  // {
  //   name: {
  //     es_ES: 'Informes Industrias',
  //   },
  //   submodulo: 'informes-industrias',
  //   link: 'informes/ccl/informe_industrias',
  //   icon: 'mdi-file-hidden',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  // },
  // {
  //   name: {
  //     es_ES: 'Informes Superficies',
  //   },
  //   submodulo: 'informes-superficies',
  //   link: 'informes/ccl/informe_superficies',
  //   icon: 'mdi-clock-outline',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  // },

  //     {
  //       name: {
  //         es_ES: 'Contactos',
  //         en_US: 'Contacts',
  //       },
  //       submodulo: 'personal',
  //       path: 'personal',
  //       link: 'personal/agenda',
  //       icon: 'mdi-account-search',
  //     },
  //   ],
  // },

  /**
   * CLIENTES
   */
  // {
  //   name: {
  //     es_ES: 'Clientes',
  //     en_US: 'Clients',
  //   },
  //   modulo: 'clientes',
  //   type: 'link',
  //   link: 'clientes',
  //   icon: 'mdi-account-multiple-outline',
  //   role: ROLE.ROLE_AUDITOR,
  // },

  // {
  //   name: {
  //     es_ES: 'Auditores',
  //     en_US: 'Auditors',
  //   },
  //   modulo: 'auditores',
  //   type: 'menu',
  //   link: null,
  //   icon: 'mdi-account-hard-hat',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   items: [
  //     {
  //       name: {
  //         es_ES: 'Auditores',
  //       },
  //       link: 'auditores',
  //       submodulo: 'auditores',
  //       icon: 'mdi-account-tie-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Zonas de visita',
  //       },
  //       link: 'zonas_visita',
  //       submodulo: 'zonas-de-visita',
  //       icon: 'mdi-map-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Competencias',
  //       },
  //       submodulo: 'competencias',
  //       link: 'competencias',
  //       icon: 'mdi-clipboard-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Incompatibilidades',
  //       },
  //       submodulo: 'incompatibilidades',
  //       link: 'incompatibilidades',
  //       icon: 'mdi-clipboard-alert-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //   ],
  // },

  /**
   * AUDITORES
   */
  // {
  //   name: {
  //     es_ES: 'Auditores',
  //     en_US: 'Auditors'
  //   },
  //   type: 'link',
  //   link: 'auditores',
  //   icon: 'mdi-account-tie-outline',
  //   role: ROLE.ROLE_AUDITOR_FREELANCE
  // },

  // {
  //   name: {
  //     es_ES: 'Auditores',
  //     en_US: 'Auditors',
  //   },
  //   modulo: 'auditores',
  //   type: 'menu',
  //   link: null,
  //   icon: 'mdi-account-hard-hat',
  //   role: ROLE.ROLE_AUDITOR_ADMIN,
  //   items: [
  //     {
  //       name: {
  //         es_ES: 'Auditores',
  //       },
  //       link: 'auditores',
  //       submodulo: 'auditores',
  //       icon: 'mdi-account-tie-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Zonas de visita',
  //       },
  //       link: 'zonas_visita',
  //       submodulo: 'zonas-de-visita',
  //       icon: 'mdi-map-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Competencias',
  //       },
  //       submodulo: 'competencias',
  //       link: 'competencias',
  //       icon: 'mdi-clipboard-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //     {
  //       name: {
  //         es_ES: 'Incompatibilidades',
  //       },
  //       submodulo: 'incompatibilidades',
  //       link: 'incompatibilidades',
  //       icon: 'mdi-clipboard-alert-outline',
  //       role: ROLE.ROLE_AUDITOR_ADMIN,
  //     },
  //   ],
  // },
]
