<template>
  <div>
    <v-autocomplete ref="auditor" v-model="selected" :items="items" :item-text="nombreCompleto" item-value="uuid"
      :rules="rules" :required="required" :disabled="disabled" :dense="dense" :label="title" :multiple="multiple"
      :hide-details="show_hint" clearable outlined dense return-object @change="emitChange()" @click:clear="clear()"
      :key="component">
      <!-- <template v-slot:item="{ item, on, attrs }" v-if="compatibles">
        <v-list-item v-on="on" v-bind="attrs" :disabled="isIncompatible(item).incompatible || isIncompatible(item).no_afinidad">
          <v-list-item-action v-if="!isIncompatible(item).incompatible && !isIncompatible(item).no_afinidad">
            <v-checkbox
              :value="selected.some(e => e.uuid == item.uuid)"
              :ripple="false"
              readonly
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            {{ nombreCompleto(item) }}
            <v-list-item-subtitle v-if="isIncompatible(item).incompatible || isIncompatible(item).no_afinidad">

              <div v-if="isIncompatible(item).no_afinidad" class="warning--text">
                No tiene afinidad con
                <span v-if="isIncompatible(item).no_afinidad_alcance"> el alcance</span>
                <span v-if="isIncompatible(item).no_afinidad_fecha">
                  <span v-if="isIncompatible(item).no_afinidad_alcance"> y </span>
                  la fecha seleccionada
                </span>
              </div>

              <div v-if="isIncompatible(item).incompatible" class="error--text">
                Tiene incompatibilidad con
                <span v-if="isIncompatible(item).cliente"> el cliente</span>
                <span v-if="isIncompatible(item).alcance">
                  <span v-if="isIncompatible(item).cliente">, </span>
                  el alcance
                </span>
                <span v-if="isIncompatible(item).fecha">
                  <span v-if="isIncompatible(item).cliente || isIncompatible(item).alcance"> y </span>
                  la fecha seleccionada
                </span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </template> -->
    </v-autocomplete>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    SelectAuditoresMultiple.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Dec 20 2022
 **/

import moment from 'moment'
import format from '~/util/formatHelper'

export default {
  name: 'SelectAuditores',
  props: {
    value: Array,
    title: { type: String, default: 'Auditor' },
    desde: { type: String, default: '' },
    subalcance: { type: String, default: '' },
    cliente: { type: String, default: '' },
    dense: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
    select_all: { type: Boolean, default: false },
    compatibles: { type: Boolean, default: false },
    restrain: Function,
  },
  data(ctx) {
    return {
      component: 0,
      selected: ctx.value,
      auditores: [],
    }
  },
  computed: {
    show_hint() {
      return this.cliente && this.subalcance ? true : false
    },
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
    items() {
      if (this.auditores.length === 0) return []
      return this.auditores.filter((item) => {
        if (!item.activo) return false
        if (this.restrain) {
          if (!this.restrain(item)) return false
        }

        if (this.desde !== '') {
          const incompatible = this.isIncompatible(item).incompatible
          const no_afinidad = this.isIncompatible(item).no_afinidad
          return this.compatibles ? !incompatible && !no_afinidad : true
        }

        return []
      })
    },
  },
  watch: {
    value(val) {
      if (!val || val === undefined) this.$refs.auditor.reset()
      this.selected = val
      this.selected.length !== this.items.length ? this.clear() : this.$emit('all')
    },
    desde() {
      this.component++
    },
    select_all(val) {
      if (val) {
        this.selected = this.items
        this.emitChange()
      }
    },
  },
  methods: {
    nombreCompleto(item) {
      return format.fullName(item, { is: 'auditor' })

      if (item) {
        let nombre = item.nombre
        if (item.apellido1) nombre += ' ' + item.apellido1
        if (item.apellido2) nombre += ' ' + item.apellido2
        if (item.primer_apellido) nombre += ' ' + item.primer_apellido
        if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido
        if (item.apellidos) nombre += ' ' + item.apellidos
        return nombre
      } else return ''
    },
    clear() {
      this.$emit('clear')
    },
    emitChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
      if (this.selected.length !== this.items.length) this.clear()
      else this.$emit('all')
    },
    async init() {
      let pagination = { page: 1, perPage: 1000 }
      const res = await this.$store.dispatch('auditores/list', pagination)
      if (res) this.auditores = res
      console.warn(this.selected, this.items)
      this.selected.length !== this.items.length ? this.clear() : this.$emit('all')
    },
    async isIncompatible(item) {
      const obj = {
        cliente: false,
        subalcance: false,
        fecha: false,
        no_afinidad_subalcance: true,
        no_afinidad_fecha: true,
      }

      item.afinidades = await this.$store.dispatch('auditores/getCompetencias', item.uuid)
      item.incompatibilidades = await this.$store.dispatch(
        'auditores/getIncompatibilidades',
        item.uuid
      )

      console.log('afinidades', item.afinidades)
      console.log('incompatibilidades', item.incompatibilidades)

      for (let i = 0; i < item.afinidades.length; i++) {
        const afinidad = item.afinidades[i]

        if (afinidad.subalcance == this.subalcance) obj.no_afinidad_subalcance = false

        const desde = moment(afinidad.desde).format('YYYY-MM-DD')
        const hasta = moment(afinidad.hasta).format('YYYY-MM-DD')

        const isSameOrBefore = moment(this.desde).isSameOrBefore(hasta)
        const isSameOrAfter = moment(this.desde).isSameOrAfter(desde)

        if (isSameOrBefore && isSameOrAfter) obj.no_afinidad_fecha = false
      }

      for (let i = 0; i < item.incompatibilidades.length; i++) {
        const incompatibilidad = item.incompatibilidades[i]

        if (incompatibilidad.cliente === this.cliente) obj.cliente = true
        if (incompatibilidad.subalcance === this.subalcance) obj.subalcance = true

        const desde = moment(incompatibilidad.desde).format('YYYY-MM-DD')
        const hasta = moment(incompatibilidad.hasta).format('YYYY-MM-DD')

        const isSameOrBefore = moment(this.desde).isSameOrBefore(hasta)
        const isSameOrAfter = moment(this.desde).isSameOrAfter(desde)

        if (isSameOrBefore && isSameOrAfter) obj.fecha = true
      }

      obj.incompatible = obj.cliente && obj.subalcance && obj.fecha
      obj.no_afinidad = obj.no_afinidad_subalcance || obj.no_afinidad_fecha

      return obj
    },
  },
  created() {
    this.init()
  },
}
</script>
