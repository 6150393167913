<template>
  <div>
    <v-select
      ref="select"
      :items="items"
      v-model="value"

      :item-text="params.item_text || false"
      :item-value="params.item_value || false"

      :placeholder="placeholder"
      :multiple="multiple"
      :clearable="multiple || clearable"

      @change="doCallback();"
      @keydown.enter="multiple ? params.api.stopEditing() : null"
    ></v-select>
    <!--
      Removed the following line as it was causing the dropdown to close
      @change="getValue(), multiple ? null : params.api.stopEditing();"
    -->
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    SelectCellEditor.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Jul 26 2022
 **/

export default {
  data: () => ({
    value: null,
    placeholder: 'Seleccionar',
    multiple: false,
    clearable: false,
    items: [],
  }),
  methods: {
    getValue() {
      return this.value
    },

    getSelectedItem() {
      if (this.$refs.select === undefined) return null
      return this.$refs.select.selectedItems
      return this.items.filter((item) => item[this.params.item_value] === this.value)
    },

    //+-------------------------------------------------
    // retry()
    // Sometimes, this.$refs is undefined, give it time to load
    // -----
    // Created on Tue Jul 26 2022
    //+-------------------------------------------------
    retry() {
      console.warn('retrying', this.$refs.select)
      setTimeout(() => {
        this.value = null
        // this.doCallback()
      }, 1000)
    },

    //+-------------------------------------------------
    // doCallback()
    // Do callback function given by parent table params
    // -----
    // Created on Wed Jan 05 2022
    // Updated on Thu Jun 23 2022
    //+-------------------------------------------------
    doCallback() {
      setTimeout(() => {
        let selected = this.getSelectedItem()
        if (selected == null) {
          this.retry()
          return
        }
        console.warn(this.value, selected, this.$refs.select)

        if (this.params.callback) {
          this.params.callback(
            this.value,
            selected,
            this.params.callback_payload,
            this.params.rowIndex
          )
        }
      }, 200)
    },
  },

  created() {
    this.value = this.params.value
    if (this.params.items && this.params.items.length > 0) this.items = this.params.items
    if (this.params.placeholder) this.placeholder = this.params.placeholder
    if (this.params.multiple) this.multiple = this.params.multiple
    if (this.params.clearable) this.clearable = this.params.clearable
  },
}
</script>

<style scoped>
.v-text-field {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
